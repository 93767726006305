import { Menu } from 'semantic-ui-react'

const FilterMenuMobileAuto = (props) => {
  const { ComponentMap, formData } = props

  return (
    <>
      <Menu.Item>
        {ComponentMap.policyTerm}
      </Menu.Item>
      {(formData.policyType !== 'thirdParty') && (
        <Menu.Item>
          {ComponentMap.idv}
        </Menu.Item>
      )}
      <Menu.Item>
        {ComponentMap.policyType}
      </Menu.Item>
    </>
  )
}

export default FilterMenuMobileAuto
