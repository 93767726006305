import { connect } from 'react-redux'

import EnrolledMessage from './EnrolledMessage'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    getSumAssuredAndCountOfPolicies: models.policies.getSumAssuredAndCountOfPolicies
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    ...ownProps,
    ...selectorData
  }
}

const EnrolledMessageContainer = connect(mapStateToProps)(EnrolledMessage)
export default EnrolledMessageContainer
