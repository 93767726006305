import { useState } from 'react'
import './landing.scss'
import { Button } from 'semantic-ui-react'
import { isEmpty } from 'utils/lib/basic'
import Loading from 'components/Loading'
import LandingHeader from './LandingHeader'
import LandingModal from './LandingModal'
import { buildDistributorImagePath } from 'components/buildImagePath'

/**
 * Landing Component to list the types of insurances
 */
const Landing = (props) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [insuranceType, setInsuranceType] = useState()
  const { availableProducts, getEnquiry } = props

  const handleModalOpen = (insuranceType) => {
    setInsuranceType(insuranceType)
    getEnquiry({ insuranceType, enquiryId: props.enquiryId })
    setModalOpen(true)
  }
  const handleModalClose = () => {
    setModalOpen(false)
  }

  return (
    <>
      <LandingHeader />
      <div className='main4'>
        <div className='form-container'>
          <div className='landing_container'>
            <div className='landing_container_row'>
              {(availableProducts || []).map((product, idx) => (
                <div key={idx} className='insurance_types_section'>
                  <Button
                    id={product}
                    basic
                    className='insurance_type_btn'
                    onClick={() => handleModalOpen(product)}
                  >
                    <span id={product + '-insurance'} className='insurance_type_text'>{product}</span>
                    <img id={product + '-img'} alt={product} src={buildDistributorImagePath(`${product}.png`)} style={{ height: '5rem', width: '5.5rem' }} />
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {modalOpen && isEmpty(props.formData) && <Loading open={isEmpty(props.formData)} />}
      {modalOpen && !isEmpty(props.formData) && (
        <LandingModal
          {...props}
          insuranceType={insuranceType}
          handleModalClose={handleModalClose}
          modalOpen={modalOpen}
        />
      )}
    </>
  )
}

export default Landing
