import React, { useEffect } from 'react'

import { WOW } from 'wowjs'
import { annuityContent, bikeContent, carContent, termContent, healthContent } from '../../../mockData'
import { AutoOnboardingForm } from 'components/auto'
import { TermOnboardingForm } from 'components/term'
import { HealthOnboardingForm } from 'components/health'
import { AnnuityOnboardingForm } from 'components/annuity'
import OnboardHeader from './OnboardHeader'
import OnboardCardSection from './OnboardCardSection'
import OnboardFaq from './OnboardFaq'
import './onboard.scss'
import style from 'assets/styles/variables.scss'
const formMapper = {
  car: AutoOnboardingForm,
  bike: AutoOnboardingForm,
  term: TermOnboardingForm,
  annuity: AnnuityOnboardingForm,
  health: HealthOnboardingForm
}
const content = {
  term: termContent,
  car: carContent,
  bike: bikeContent,
  annuity: annuityContent,
  health: healthContent
}

const componentMapper = {
  header: OnboardHeader,
  form: (insuranceType) => formMapper[insuranceType],
  card: OnboardCardSection,
  faq: OnboardFaq
}

const e = React.createElement

const OnboardPage = (compProps) => {
  const { insuranceType, configure } = compProps
  const pageContent = configure.onboard.content
  const onboardContent = pageContent ?? content[insuranceType]

  useEffect(() => {
    const wow = new WOW({ live: false })
    let scrolled = false
    window.addEventListener('scroll', function () {
      if (!scrolled) {
        scrolled = true
        wow.init()
      }
    })
  }, [])
  const renderContent = (content, key) => {
    let comp = componentMapper[key]
    if (key === 'header') {
      const textColor = content.textColor ?? style.white
      return e(comp, { key, textColor, ...compProps, ...content })
    }
    if (key === 'form') {
      const color = content.bgColor ?? style.primaryDark
      comp = comp(insuranceType)
      return e(comp, { key, bgColor: color })
    }
    if (key === 'card') {
      return content.map((c, i) => {
        let color = '#fff'
        if (i % 2) {
          color = 'rgba(var(--secondaryLight), 0.05)'
        }
        color = c.bgColor ?? color
        return e(comp, { key: key + i, style: { backgroundColor: color }, insuranceType, ...c })
      })
    }
    return e(comp, { key, ...content })
  }

  const renderComponent = () => {
    const maps = Object.keys(componentMapper)
    return Object.keys(onboardContent).map((cont) => {
      if (maps.includes(cont)) {
        return renderContent(onboardContent[cont], cont)
      } return null
    })
  }
  return renderComponent()
}

export default OnboardPage
