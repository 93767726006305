import { useState, useEffect, useRef } from 'react'
import produce from 'immer'
import Loading from 'components/Loading'
import {
  DataAccessContext,
  dataAccessInitialState
} from 'components/context'
import FetchDataLoader from 'components/FetchDataLoader'
import { useInitialDataLoader, useSetFormData } from 'components/customHooks'
import { isNotDefined } from 'utils'
import { isNotEmpty } from 'utils/lib/basic'
import { Navigate } from 'react-router-dom'
import OnboardPage from './OnboardPage'
import { sendDropOffLink } from 'smartcovr_api/src/insuranceApi'

const path = {
  term: 'select-product',
  car: 'select-product',
  bike: 'select-product',
  annuity: 'select-product',
  health: 'select-product'
}
const Onboard = props => {
  const { insuranceType, setDropOff, device, formData, isLoggedIn, productData, distributorId, iamId } = props
  const componentRef = useRef({ redirect: false, dropOffFired: false })
  const { loadingConfigure, configure } = useInitialDataLoader(
    insuranceType,
    props.enquiryId,
    props.distributorId,
    true
  )

  useEffect(() => {
  }, [])

  useEffect(() => {
    const eventHandler = (ev) => {
      ev.preventDefault()
      if (isNotEmpty(formData) && !componentRef.current.dropOffFired) {
        componentRef.current.dropOffFired = true
        sendDropOffLink({
          applicationFormData: { ...formData, iamId, insuranceType },
          path: 'onboard',
          distributorId
        })
      }
      return null
    }
    window.addEventListener('unload', eventHandler)
    return () => window.removeEventListener('unload', eventHandler)
  },
  // eslint-disable-next-line
  [ formData ])

  const onSubmit = data => {
    // In onboard it is more convenient to get coverTerm from formData for following reasons:
    // 1. form validation in the underlying components.
    // 2. coverTerm value is not used in Onboard, it is only set

    // This is added so that we can send dropOff value for agent_dashboard

    setDropOff(insuranceType, 'select-product')
    const newFormData = { ...data, insuranceType }
    delete newFormData.coverTerm
    let productDataFragment = {}
    if (['car', 'bike'].includes(insuranceType) && !isNotDefined(data.businessOption) && data.businessOption === 'renewal') {
      productDataFragment = { ...data.productDataFragment }
      delete newFormData.productDataFragment
    }
    if (!isNotDefined(data.coverTerm)) {
      productDataFragment.coverTerm = data.coverTerm
    }
    setFormData({
      formData: newFormData,
      all: true,
      productDataFragment,
      shouldCalculateRecommendations: true
    })
    const newDataAccess = produce(dataAccess, draft => {
      draft.formData = data
    })
    setDataAccess(newDataAccess)
  }

  const getUpdatedDataAccess = prevDataAccess =>
    produce(prevDataAccess, draft => {
      draft.device = device
      draft.insuranceType = insuranceType
      draft.formData = formData
      draft.productData = productData
      draft.configure = configure
      draft.onSubmit = onSubmit
      draft.redirectToLanding = props.redirectToPath
      draft.countries = props.countries
    })

  const [dataAccess, setDataAccess] = useState(
    getUpdatedDataAccess(dataAccessInitialState)
  )
  const [
    { loadingProductsWithQuotes, loadingSetFormData },
    setFormData
  ] = useSetFormData(insuranceType)

  useEffect(() => {
    if (loadingProductsWithQuotes && loadingSetFormData) {
      if (['car', 'bike'].includes(insuranceType) && !isNotDefined(formData.businessOption) && formData.businessOption === 'renewal') {
        props.redirectToPath('renew-policy')
      } else {
        props.redirectToPath(path[insuranceType])
      }
    }
  },
  // eslint-disable-next-line
  [loadingProductsWithQuotes])

  // following to be removed soon
  useEffect(() => {
    setDataAccess(getUpdatedDataAccess(dataAccess))
  },
  // eslint-disable-next-line
  [insuranceType, device, configure, formData])

  if (loadingConfigure) {
    return <Loading id='loading_onboard_config' open />
  } else if (loadingSetFormData) {
    return (
      <FetchDataLoader
        id='fetching_product'
        message='Fetching Suitable Products for your search...'
      />
    )
  }
  // Redirect the user to Landing if the mobiel is not set.
  if (['car', 'bike', 'health'].includes(insuranceType) && !props.formDataLoading && !isNotEmpty(formData)) {
    return <Navigate to='/' />
  }

  return (
    <DataAccessContext.Provider value={dataAccess}>
      <OnboardPage {...{ isLoggedIn, redirectToPath: props.redirectToPath, insuranceType, configure, device }} />
    </DataAccessContext.Provider>
  )
}
export default Onboard
