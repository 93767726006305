
import { connect } from 'react-redux'
import { ProcessPayment } from './components'
import { getUiConfig } from 'smartcovr_api/src/insuranceApi'
import { isNotEmpty } from 'utils'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    chosenProductMetaData: models.insuranceEnquiry.getChosenProductMetadata,
    distributorId: models.configure.getDistributorId
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  let bookingConfigLoaded = false
  if (isNotEmpty(selectorData.chosenProductMetaData)) {
    const config = state.insuranceConfigure[insuranceType]
    bookingConfigLoaded = config && config.booking && isNotEmpty(config.booking[selectorData.chosenProductMetaData.institutionId])
  }
  return {
    ...ownProps,
    configureLoading: state.loading.models.insuranceConfigure,
    chosenProductLoading: state.loading.effects.insuranceEnquiry.setChosenProduct,
    enquiry: state.insuranceEnquiry[insuranceType],
    bookingConfigLoaded,
    ...selectorData
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { insuranceType } = ownProps
  return {
    setChosenProduct: ({ productOptionId, productId, institutionId }) => {
      dispatch.insuranceEnquiry.setChosenProduct({
        insuranceType,
        productOptionId,
        productId,
        institutionId,
        quoteType: 'listing',
        reset: true
      })
    },
    resetBookingIndices: ({ institutionId }) => {
      return dispatch.insuranceEnquiry.setApplicationFormData({
        insuranceType,
        currentStep: 0,
        institutionId,
        bookingStepIndex: 0
      })
    },
    getUiConfig: (distributorId) => {
      const query = {
        distributorId,
        insuranceType
      }
      return getUiConfig(query)
    },
    setUiConfig: ({ uiConfig, enquiryId }) => {
      const query = {
        insuranceType,
        uiConfig,
        enquiryId
      }
      dispatch.insuranceConfigure.setUiConfig(query)
    }
  }
}
const ProcessPaymentContainer = connect(mapStateToProps, mapDispatchToProps)(ProcessPayment)
ProcessPaymentContainer.path = 'process-payment'
export default ProcessPaymentContainer
