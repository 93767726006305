import { useMemo } from 'react'
import { Dropdown, Input } from 'semantic-ui-react'

function MobileNumberWidget (props) {
  const { value, formContext: { countries }, placeholder } = props
  const valueObject = props.value && JSON.parse(value)
  const code = valueObject?.code ?? '+91'
  const number = valueObject?.number ?? ''
  const country = useMemo(() => countries?.find(country => country.code === code), [countries, code])
  const maxLength = country?.max ?? 15
  function dropdownOptions () {
    return countries?.map(country => ({
      key: country.countryName,
      text: country.code,
      value: country.code,
      flag: country.countryShortCode.toLowerCase()
    }))
  }

  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    const fieldValue = event.target.value
    if (!/\d+/.test(keyValue) || fieldValue.length >= maxLength) {
      event.preventDefault()
    }
  }

  function handleInputChange (_event, data) {
    props.onChange(JSON.stringify({ code, number: data.value }))
  }

  function handleDropDownChange (_e, data) {
    props.onChange(JSON.stringify({ code: data.value, number: '' }))
  }

  return (
    <Input
      onChange={handleInputChange}
      onKeyPress={onKeyPress}
      value={number}
      required
      placeholder={placeholder}
      type='number'
      autoComplete='off'
      label={(
        <Dropdown
          id='mobileNumberDropDown'
          basic
          search
          compact
          value={code}
          options={dropdownOptions()}
          onChange={handleDropDownChange}
          icon='dropdown'
        />
        )}
      labelPosition='left'
    />
  )
}

export default MobileNumberWidget
