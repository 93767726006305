import { v4 } from 'uuid'
import queryString from 'query-string'
import {
  isNotDefined,
  isNotEmpty
} from 'utils'
import Fingerprint2 from 'fingerprintjs2'
import axios from 'axios'
import { updateAuthTokenWithRefreshToken, getAuthTokenObject } from './setUpStore'
import { getStore } from './store'
const env = process.env.NODE_ENV

/**
 * Gets all the query parameters available in the url of the page
 *
 * @returns object
 */
const getQueryParams = () => {
  const locationParts = window.location.href.split('?')
  let searchQuery = {}
  if (locationParts.length > 1) {
    // FIXME: we have to also check if the domain on redirect is smartcovr.io
    searchQuery = queryString.parse(locationParts[1])
  }
  return searchQuery
}

/**
 * This function gets the enquiry id from query parameter or if it's not present in url then it
 * checks the cookie for enquiry id. If enquiry id is not found there then it creates a new id
 * using uuid function.
 * uuid function belongs to a library called uuid.
 * Then it calls setCookie function to to set the enquiry id in the cookie
 *
 * @returns string
 */
const getEnquiryId = () => {
  const { configure } = getStore().getState()
  const { scCookie } = configure // config.get('scCookie')
  const {
    enquiryId
  } = getQueryParams()
  let currentEnquiryId = getCookie(scCookie.name)
  if (!isNotDefined(enquiryId)) {
    currentEnquiryId = enquiryId
  } else if (isNotDefined(currentEnquiryId)) {
    currentEnquiryId = v4()
  }
  setCookie(scCookie.name, currentEnquiryId, scCookie.maxAge, scCookie.path, true)
  return currentEnquiryId
}

/**
 * Gets the redirectPath from the cookie where redirect path is saved
 *
 * @returns string
 */
const getRedirectPath = () => {
  // const config = new Config()
  // const redirectCookieName = config.get('redirectCookie.name')
  const { configure } = getStore().getState()
  const { redirectCookie } = configure
  const redirectPath = getCookie(redirectCookie.name)
  return redirectPath
}

/**
 * Sets the cookie with the value and maxAge and path
 *
 * @returns
 */
const setCookie = (cookieName, value, maxAge, path, secure = false) => {
  let updatedCookie = `${cookieName}=${encodeURIComponent(value)}`
  const secureValue = secure ? 'secure' : ''
  updatedCookie = `${updatedCookie}; maxAge=${maxAge}; path=${path}; ${secureValue}`
  document.cookie = updatedCookie
}

/**
 * Gets the cookie matching the regex pattern
 *
 * @param {*} name
 * @returns object
 */
const getCookie = (name) => {
  const matches = document.cookie.match(
    new RegExp(
      '(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'
    )
  )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

/**
 * Gets redirectTo value from localstate
 *
 * @param {*} queryParams
 * @returns string
 */
const getRedirectFromLocalState = (queryParams) => {
  if (isNotDefined(queryParams) || !isNotEmpty(queryParams)) {
    return ''
  }
  const state = queryParams.state
  // There is a state to check due to redirect.
  if (!isNotDefined(state)) {
    const stateString = window.localStorage.getItem('state')
    let localState = {}
    if (isNotEmpty(stateString)) {
      localState = JSON.parse(stateString)
      // encode state and send
      const {
        code,
        redirectTo
      } = localState
      if (code !== state) {
        throw new Error('Echoed state doesnt match local storage') // FIXME: throw custom error
      } else {
        return redirectTo
      }
    } else {
      return ''
    }
  }
}

/**
 * This functions working is based on the fingerprintjs2 library
 * It gets the fingerprint data browser
 * @returns object
 */
const getFingerPrint = async () => {
  const fpOptions = {
    fonts: {
      extendedJsFonts: false
    },
    excludes: {
      webgl: true
    },
    NOT_AVAILABLE: 'NA',
    ERROR: 'ERR',
    EXCLUDED: 'EXCL'
  }
  const components = await Fingerprint2.getPromise(fpOptions)
  const fingerPrint = Fingerprint2.x64hash128(
    components.map((component) => component.value).join('~~~'),
    31
  )
  return {
    fingerPrint,
    components
  }
}

/**
 * Converts query string to json object
 *
 * @returns object
 */
const queryStringToJSON = () => {
  const searchString = window.location.search
  const result = {}
  if (isNotEmpty(searchString)) {
    const pairs = searchString.split('?')[1].split('&')
    pairs.forEach(function (pair) {
      pair = pair.split('=')
      result[pair[0]] = decodeURIComponent(pair[1] || '')
    })
  }
  return result
}

/**
 * Encodes the query parameters
 *
 * @param {*} params
 * @returns object
 */
const encodeQueryParams = (params) => {
  let encodedQueryString
  Object.keys(params).forEach((element, index) => {
    if (index === 0) {
      encodedQueryString = `${element}=${encodeURIComponent(params[element])}`
    } else {
      encodedQueryString = `${encodedQueryString}&${element}=${encodeURIComponent(
        params[element]
      )}`
    }
  })
  return encodedQueryString
}

/**
 * This function set the state and its value in the local storage and returns the queryStrong and code
 * In state, code, and redirectTo data is saved
 *
 * @param {*} params
 * @returns object
 */
const getEncodedRouteParams = (params) => {
  const {
    redirectToPath,
    configType,
    ...rest
  } = params
  const code = Math.random().toString(36).substring(2, 15)
  const redirectTo =
    redirectToPath || window.location.hash.split('#/')[1].split('?')[0]
  const stateJson = queryStringToJSON()
  stateJson.redirectTo = redirectTo
  stateJson.code = code
  window.localStorage.setItem('state', JSON.stringify(stateJson))
  // state code stored in local is compared with the one echoed after redirect
  return {
    queryString: `state=${code}&${encodeQueryParams(rest)}&configType=${configType}`,
    state: code
  }
}

/**
 * gets parameter string from webiste url
 *
 * @returns string
 */
const getParamsStringFromUrl = () => {
  const locationParts = window.location.href.split('?')
  if (locationParts.length > 1) {
    return locationParts[1]
  } else {
    return ''
  }
}

/**
 * NOTE: NOT SURE WHAT IT DOES AS FETCH IS NOT IMPORTED ANYWHERE
 *
 * @returns blob
 */
const getFileBlob = async (url) => {
  const response = await window.fetch(url)
  const blob = await response.blob()
  return blob
}

// Returns current date as dd-mm-yyyy format
const getCurrentDate = () => {
  let today = new Date()
  let dd = today.getDate()

  let mm = today.getMonth() + 1
  const yyyy = today.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  today = dd + '-' + mm + '-' + yyyy
  return today
}

// Returns file name with extension
const getFileNameWithExtension = (fileName, fileExtension) => {
  return fileName + '_' + getCurrentDate() + '.' + fileExtension
}

// Downloads the file from blob
const downloadFileFromBlob = async (baseUrl, data, fileNameWithExtension, distributorId, insuranceType) => {
  const { configure } = getStore().getState()
  const { clientId, apiman } = configure
  const options = {
    headers: {
      client: clientId,
      distributorid: distributorId
    },
    responseType: 'blob'
  }
  const apiKey = apiman.apikey

  if (env === 'production') {
    await updateAuthTokenWithRefreshToken(getAuthTokenObject())
    const authTokenObject = getAuthTokenObject()
    const authorizationheadervalue = authTokenObject.access_token && `Bearer ${authTokenObject.access_token}`
    if (authorizationheadervalue) {
      options.headers.Authorization = authorizationheadervalue
    }
  }

  return axios.post(`${baseUrl}?insuranceType=${insuranceType}&apikey=${apiKey}`, data, options)
    .then((resp) => {
      const url = window.URL.createObjectURL(new window.Blob([resp.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute(
        'download',
        `${fileNameWithExtension}`
      )
      document.body.appendChild(link)
      link.click()
      // Fixes "webkit blob resource error 1"
      setTimeout(function () {
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }, 200)
    })
}
export {
  getRedirectFromLocalState,
  setCookie,
  getCookie,
  getFingerPrint,
  getRedirectPath,
  getEnquiryId,
  getEncodedRouteParams,
  getQueryParams,
  getParamsStringFromUrl,
  encodeQueryParams,
  getFileBlob,
  getCurrentDate,
  getFileNameWithExtension,
  downloadFileFromBlob
}
