import { useState, useEffect } from 'react'

const CountdownTimer = ({ onTimerEnd, isTimerActive }) => {
  const [time, setTime] = useState(60)

  useEffect(() => {
    if (isTimerActive && time > 0) {
      const countdownTimeout = setTimeout(() => {
        setTime(time - 1)
      }, 1000)

      return () => {
        clearTimeout(countdownTimeout)
      }
    } else if (time === 0) {
      onTimerEnd()
    }
  }, [time, isTimerActive, onTimerEnd])

  useEffect(() => {
    if (!isTimerActive) {
      // Reset the timer when isTimerActive changes to false
      setTime(60)
    }
  }, [isTimerActive])

  return (
    <p style={{ float: 'right' }}>Valid For: {time} seconds</p>
  )
}

export default CountdownTimer
