import { unescape } from 'lodash'
import { buildDistributorAndInsuranceTypeImagePath } from 'components/buildImagePath'
import DisplayHtmlString from 'components/DisplayHtmlString'

const Title = ({ title }) => <h3 className='cardContentTitle'>{unescape(title)}</h3>
const Image = ({ image, title }) => {
  return (
    <div className='cardContentImageContainer'>
      <img
        className='img-fluid cardContentImage'
        alt={title}
        src={buildDistributorAndInsuranceTypeImagePath(image)}
      />
    </div>
  )
}
const Content = ({ description }) => <DisplayHtmlString className='cardContentDescription' htmlContent={description} />

const contentMapper = {
  title: Title,
  image: Image,
  description: Content
}

const OnboardContentCard = ({ className, wowDelay, order, ...restProps }) => {
  const renderComponent = () => {
    return order.map(ord => {
      const Component = contentMapper[ord]
      return <Component key={ord} {...restProps} />
    })
  }
  return (
    <div className={`${className} cardContent col-sm-12 col-xs-12 wow zoomIn`} data-wow-delay={wowDelay}>
      {renderComponent()}
    </div>
  )
}

export default OnboardContentCard
