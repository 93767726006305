
import TooltipComponent from 'components/TooltipComponent'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { Body2 } from 'components/Header'

const LabelField = (props) => {
  const { className, title, label, info, style } = props
  return (
    <>
      <label className={className} style={style}>
        {label ?? ''}{title}
        {info && (
          <TooltipComponent>
            <Body2>
              <DisplayHtmlString htmlContent={info} />
            </Body2>
          </TooltipComponent>
        )}
      </label>
    </>
  )
}
export default LabelField
