import { useEffect, useState } from 'react'
import { Button, Modal, Icon } from 'semantic-ui-react'
import { JsonSchemaOnboardForm, JsonSchemaValidation, JsonSchemaHelper } from 'components/jsonSchemaForms'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'
import { isNotEmpty } from 'utils'
import schema from './schema.json'
import uiSchema from './uiSchema.json'

const LandingModal = (props) => {
  const { insuranceType, modalOpen, handleModalClose, countries } = props
  const [formData, setFormData] = useState()

  useEffect(() => {
    if (props.formData) {
      const { fullName, gender, dob, mobile, email, pincode, ...rest } = props.formData
      setFormData({ fullName, gender, dob, mobile, email, pincode })
      window.localStorage.setItem(insuranceType, JSON.stringify(rest))
    }
  }, [props.formData]) // eslint-disable-line

  const handleSubmit = (e) => {
    const enqDefaults = JSON.parse(window.localStorage.getItem(insuranceType))
    props.setEnquiry({ ...e.formData, ...enqDefaults }, insuranceType)
    props.redirectToPath(`${insuranceType}/onboard`)
  }

  const onValidate = (formData, errors) => {
    const { validateDOB, validateEmail, validateMobileNumber, validateFullName, validateMobileDndError } = JsonSchemaValidation
    const dateOfBirthError = validateDOB(formData, schema, 'dob')
    if (dateOfBirthError) {
      errors.dob.addError(dateOfBirthError)
    }
    const mobileError = validateMobileNumber(formData.mobile, countries)
    if (mobileError) {
      errors.mobile.addError(mobileError)
    }
    const emailError = validateEmail(formData, 'email')
    if (emailError) {
      errors.email.addError(emailError)
    }
    const fullNameError = validateFullName(formData, 'fullName')
    if (fullNameError) {
      errors.fullName.addError(fullNameError)
    }
    const mobileDndError = validateMobileDndError(formData, 'mobileDnd')
    if (mobileDndError) {
      errors.mobileDnd.addError(mobileDndError)
    }
    return errors
  }
  const transformErrors = (errors) => {
    if (isNotEmpty(errors)) {
      const newErrors = errors.filter(error => !['oneOf', 'enum'].includes(error.name))
      newErrors.forEach((error) => {
        const currentProperty = error.params?.missingProperty || error.property.replace('.', '')
        let selectedSchema
        if (currentProperty) {
          selectedSchema = JsonSchemaHelper.extractNestedObject(schema, currentProperty, currentProperty)
        }
        let title = 'This'
        if (selectedSchema && selectedSchema[currentProperty]?.title && selectedSchema[currentProperty]?.title.trim().length !== 0) {
          title = selectedSchema[currentProperty].title
        }
        error.message = getCustomErrorMessage(title, error)
      })
      return newErrors
    }
    return errors
  }
  return (
    <Modal className='landing_modal' open={modalOpen}>
      <Modal.Header className='modal_close_btn'>
        <Button basic icon onClick={handleModalClose} title='Close'>
          <Icon name='close' size='small' />
        </Button>
      </Modal.Header>
      <Modal.Content>
        <JsonSchemaOnboardForm
          className='landing_modal_form'
          schema={schema}
          uiSchema={uiSchema}
          rules={[]}
          formContext={{ countries }}
          formData={formData}
          liveValidate={false}
          id='landing-modal'
          onSubmit={handleSubmit}
          validate={onValidate}
          transformErrors={transformErrors}
        >
          <Button secondary content='Submit' className='mt-3' />
        </JsonSchemaOnboardForm>
      </Modal.Content>
    </Modal>
  )
}

export default LandingModal
