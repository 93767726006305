import { FeathersError } from '@feathersjs/errors'
class CustomError extends FeathersError {
  constructor (message, data) {
    super(message, data)
    this.errorType = 'CustomError'
  }
}
class RuleEngineError extends CustomError {
  constructor (message, data) {
    const errorMessage = message || 'General error'
    super(errorMessage, 'RuleEngineError', 500, 'general-error', data)
  }
}

export default {
  RuleEngineError
}
