import { getAllApplications, updateEnquiryToDb, getApplicationStatus } from '../services/commonService'
import { getProductDataById, getFeaturesForInsuranceType } from '../services/insuranceService'
import { loadFeaturesForInsuranceType, reduceRootStateDraft, checkAndFinishDraft, reduceRootStateUpdated } from './insurance/modelHelpers'
import { getBenefits } from './insurance/helpers/productHelper'
import { isNotEmpty, isNotDefined } from 'utils'

/**
   * Gets all applications and features for insurance type and applies map on all applications to
   * create a new object of selected values, which includes rider, feature and product details.
   * This new object is the modified applications which is then passed in reducer
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadApplications = async (dispatch, { insuranceType, skip, limit }, draftRootState) => {
  try {
    const distributorId = draftRootState.configure.distributorId
    const allApplications = await getAllApplications({
      iamId: draftRootState.customer.customer.iamId,
      distributorId,
      insuranceType,
      skip,
      limit
    })
    const featureResponse = await getFeaturesForInsuranceType({
      distributorId,
      insuranceType
    })
    const products = []
    await allApplications.applications.reduce(async (promise, application) => {
      await promise
      if (products.length > 0) {
        const productExist = products.findIndex(ele => ele.insurerId === application.productInsurerId) > -1
        if (productExist) {
          return
        }
      }
      const product = await getProductDataById({
        productInsurerId: application.productInsurerId,
        distributorId,
        insuranceType
      })
      products.push(product)
    }, Promise.resolve())

    const modifiedApplications = await Promise.all(allApplications.applications.map(async application => {
      const product = products.find(ele => ele.insurerId === application.productInsurerId)
      const productOption = product.productOptions.find(po => po.insurerId === application.productOptionInsurerId)
      const modifiedRiders = application.ApplicationRiders.map(rider => {
        const mainRider = productOption.riders.find(ele => ele.insurerId === rider.insurerId)
        return {
          ...rider,
          riderName: mainRider.riderName,
          riderShortName: mainRider.riderShortName,
          riderDescription: mainRider.productDescription
        }
      })
      const features = []
      productOption.riderIds && productOption.riderIds.forEach(rider => {
        if (rider.pricing === 'free') {
          const featureDetails = featureResponse.featureContent[`CATID_${rider.categoryId}`]
          features.push({
            heading: featureDetails.heading,
            description: featureDetails.descriptions.descriptionStatic,
            type: 'Inbuilt'
          })
        } else if (rider.pricing === 'available') {
          const featureDetails = featureResponse.featureContent[`CATID_${rider.categoryId}`]
          if (isNotEmpty(application.productData) && isNotDefined(rider.schemaKey) && application.productData[rider.schemaKey].include) {
            features.push({
              heading: featureDetails.heading,
              description: featureDetails.descriptions.descriptionStatic,
              type: 'Available'
            })
          }
        } else if (rider.pricing === 'included') {
          const featureDetails = featureResponse.featureContent[`CATID_${rider.categoryId}`]
          features.push({
            heading: featureDetails.heading,
            description: featureDetails.descriptions.descriptionStatic,
            type: 'Included'
          })
        }
      })
      const benefits = getBenefits(product)
      let expiringIn
      const currentDate = new Date()
      const endDate = new Date(application.applicationEndDate)
      const daysRemainingToExpire = Math.round((endDate - currentDate) / (1000 * 3600 * 24))
      if (daysRemainingToExpire <= 30) {
        expiringIn = daysRemainingToExpire
      }
      return {
        ...application,
        ApplicationRiders: modifiedRiders,
        productData: application.productData,
        chosenProduct: application.applicationFormData.selectedProduct,
        applicationFormData: {
          ...application.applicationFormData,
          completedSteps: application.applicationFormData.completedSteps || []
        },
        formData: application.formData,
        productDetails: {
          productName: product.productName,
          productDescription: product.productDescription,
          shortName: product.shortName,
          productOptionName: productOption.productOptionBasics.productOptionName,
          productOptionDescription: productOption.productOptionBasics.productOptionDescription,
          imageUrl: product.imageUrl,
          benefits,
          features,
          allRiders: productOption.riders,
          productBrochureUrl: product.productBrochureUrl,
          productUrl: product.productUrl
        },
        paymentFrequency: application.paymentFrequency,
        totalPremium: application.totalPremium,
        totalPremiumWithGst: application.totalPremiumWithGst,
        completedSteps: application.applicationFormData.completedSteps || [],
        expiringIn
      }
    }))
    dispatch.applications.storeApplicationsData({ modifiedApplications, count: allApplications.count, insuranceType })
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
   * gets all the features by insurance type and passes the response in the dispatch
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadFeatures = async (dispatch, { insuranceType }, draftRootState) => {
  try {
    const distributorId = draftRootState.configure.distributorId
    const { features } = await loadFeaturesForInsuranceType({
      distributorId,
      insuranceType
    })
    dispatch.applications.storeFeaturesData({ features, insuranceType })
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
   * This function recreates the enquiry object using the data stored in applications. The new enquiry
   * object is then stored in the db using updateEnquiryToDb function. Then reducer is called to
   * set the enquiry in state and then resetBookingConfig reducer is called to reset the booking
   * config values
   *
   * @param {*} dispatch
   * @param {*} { insuranceType, applicationNumber }
   * @param {*} draftRootState
   * @returns
   */
const buildEnquiry = async (dispatch, { insuranceType, applicationNumber }, draftRootState) => {
  try {
    const mutatedRootState = reduceRootStateDraft(draftRootState, insuranceType)
    const { insuranceEnquiryDraft, applicationsDraft } = mutatedRootState
    const selectedApplication = applicationsDraft.applications.find(ele => ele.applicationNumber === applicationNumber)
    insuranceEnquiryDraft.productData = selectedApplication.productData
    insuranceEnquiryDraft.chosenProduct = selectedApplication.chosenProduct
    if (isNotDefined(insuranceEnquiryDraft.application[selectedApplication.chosenProduct.institutionId])) {
      insuranceEnquiryDraft.application[selectedApplication.chosenProduct.institutionId] = {}
    }
    insuranceEnquiryDraft.application[selectedApplication.chosenProduct.institutionId].applicationFormData = selectedApplication.applicationFormData
    let pincode = ''
    if (!isNotDefined(selectedApplication.applicationFormData.permanentAddress) && !isNotDefined(selectedApplication.applicationFormData.permanentAddress.csp)) {
      pincode = selectedApplication.applicationFormData.permanentAddress.csp.pincode
    }
    if (!isNotDefined(selectedApplication.applicationFormData.propPermanentAddress) && !isNotDefined(selectedApplication.applicationFormData.propPermanentAddress.csp)) {
      pincode = selectedApplication.applicationFormData.propPermanentAddress.csp.pincode
    }
    insuranceEnquiryDraft.formData = {
      ...selectedApplication.formData,
      pincode: pincode ?? ''
    }
    const updatedRootState = checkAndFinishDraft(draftRootState)
    const { insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
    await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
    await dispatch.insuranceEnquiry.updateEnquiry({ insuranceType, insuranceEnquiry: insuranceEnquiryUpdated })
    await dispatch.insuranceConfigure.resetBookingConfig({ insuranceType })
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
   * This Function is used to update the application status when the user clicks on the
   * Get Status button.It calls getApplicationStatus api and then updates the application status
   * by calling the updateApplicationStatus reducer to update the application
   * status in the state.
   *
   * @param {*} dispatch
   * @param {*} { insuranceType, applicationNumber }
   * @param {*} draftRootState
   * @returns
   */
const getStatus = async (dispatch, { insuranceType, applicationNumber }, draftRootState) => {
  try {
    const mutatedRootState = reduceRootStateDraft(draftRootState, insuranceType)
    const { applicationsDraft } = mutatedRootState
    const selectedApplication = applicationsDraft.applications.find(ele => ele.applicationNumber === applicationNumber)
    const applicationData = await getApplicationStatus({
      insuranceType,
      applicationNumber,
      getStatus: true
    })
    selectedApplication.ApplicationStatuses = applicationData.ApplicationStatuses
    const updatedRootState = checkAndFinishDraft(draftRootState)
    const { applicationsUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
    await dispatch.applications.updateApplicationStatus({ applicationsUpdated, insuranceType })
  } catch (error) {
    console.log(error)
    throw error
  }
}
export {
  loadApplications,
  loadFeatures,
  buildEnquiry,
  getStatus
}
