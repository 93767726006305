import { Header } from 'components/Header'

const H3 = ({ children, ...restProps }) => {
  return (
    <Header as='h3' {...restProps}>
      {children}
    </Header>
  )
}

export default H3
