import { Form, Menu } from 'semantic-ui-react'

const FilterMenuMobileAnnuity = (props) => {
  const { ComponentMap } = props
  return (
    <>
      <Menu.Item>
        <Form>
          {ComponentMap.purchasePriceAnnuityPayoutFilter}
        </Form>
      </Menu.Item>
    </>
  )
}

export default FilterMenuMobileAnnuity
