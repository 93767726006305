import InputMask from 'react-input-mask'

const PanWidget = (props) => {
  const {
    id,
    value,
    disabled,
    onBlur,
    onFocus,
    onChange,
    placeholder,
    className
  } = props
  const inputProps = {
    maskChar: ' ',
    placeholder,
    value,
    onChange: (event) => onChange(event.target.value.toUpperCase()),
    onBlur: onBlur && ((event) => onBlur(id, event.target.value)),
    onFocus: onFocus && ((event) => onFocus(id, event.target.value)),
    id: (!id) ? 'panWidget' : id,
    disabled,
    className
  }
  return (
    <InputMask
      mask='aaaaa9999a'
      {...inputProps}
    />
  )
}

export default PanWidget
