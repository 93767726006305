import { useLayoutEffect, useRef } from 'react'
import HeaderMenu from 'components/HeaderMenu'
import config from '../config'
import 'components/css/headerMenu.scss'

const HeaderWrapper = (props) => {
  const { device, id, redirectToLogin, loggedIn, onLogout, redirectToPath } = props
  const headerRef = useRef()
  const redirectToCustomerHandler = () => {
    redirectToLogin({ configType: config.configTypeForHsecLogin, redirectionHandlerName: config.redirectHandlerForHsecLogin })
  }
  const redirectToDistributorHandler = () => {
    redirectToLogin({ configType: config.configTypeForKeyCloakLogin, redirectionHandlerName: config.redirectHandlerForKeyCloakLogin })
  }

  useLayoutEffect(() => {
    if (!['customer-holdings', 'dashboard'].includes(id) && headerRef.current) {
      const marginTop = headerRef.current.offsetHeight
      document.querySelector('.smartcovrUiDisplay').style = 'margin-top: ' + marginTop + 'px'
    } else {
      document.querySelector('.smartcovrUiDisplay').style = ''
    }
  })

  const headerProps = {
    screenId: id,
    redirectToCustomerHandler,
    redirectToDistributorHandler,
    onLogout,
    redirectToPath,
    loggedIn,
    device
  }

  if (['customer-holdings', 'dashboard'].includes(id)) {
    return null
  }
  return (
    <HeaderMenu headerRef={headerRef} {...headerProps} />
  )
}

export default HeaderWrapper
