import { AppBar, Box, Toolbar } from '@mui/material'
import { Image, Menu } from 'semantic-ui-react'
import { H6, Subtitle1 } from 'components/Header'
import { buildDistributorImagePath } from 'components/buildImagePath'
import HeaderLoginMenu from './HeaderLoginMenu'
import variable from 'assets/styles/variables.scss'

const HeaderMenu = ({ screenId, redirectToCustomerHandler, redirectToDistributorHandler, onLogout, redirectToPath, loggedIn, headerRef }) => {
  const holdingsMenuItem = (loggedIn && screenId !== 'customer-holdings') && (
    <>
      <Menu.Item id='holdings' onClick={() => redirectToPath('customer-holdings')}>
        <Subtitle1 color='white'>Holdings</Subtitle1>
      </Menu.Item>
      <H6 color='white'>|</H6>
    </>
  )

  const claimsMenuItem = (
    <>
      <Menu.Item id='claims' onClick={() => redirectToPath('claim-landing')}>
        <Subtitle1 color='white'>Claims</Subtitle1>
      </Menu.Item>
    <H6 color='white' >|</H6>
    </>
  )

  const logoutButton = loggedIn && (
    <Menu.Item id='logout' onClick={onLogout}>
      <Subtitle1 color='white'>Logout</Subtitle1>
    </Menu.Item>
  )

  const headerLogo = (
    <Menu.Item>
      <Image
        id='headerLogo'
        className='headerLogo'
        src={buildDistributorImagePath('smartcovr-logo.png')}
      />
    </Menu.Item>
  )

  return (
    <AppBar ref={headerRef} position='fixed' component='nav' sx={{ backgroundColor: variable.primary, zIndex: 20 }}>
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          {headerLogo}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
          {claimsMenuItem}
          {holdingsMenuItem}
          {logoutButton}
        </Box>
        {!loggedIn && <HeaderLoginMenu {...{ redirectToCustomerHandler, redirectToDistributorHandler }} />}
      </Toolbar>
    </AppBar>
  )
}

export default HeaderMenu
