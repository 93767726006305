import { useEffect, useState } from 'react'
import Loading from 'components/Loading'
import { connect } from 'react-redux'
import { getCookie } from 'smartcovr_api/src/commonUtils'
import { useInitialDataLoader, useSetFormData, useSetChosenProduct, useLoadBookingConfig } from 'components/customHooks'

/*
this route get called after the success of tptloginredirect api endpoint
*/
const TptLoginRedirectHandler = (props) => {
  const bookingType = 'prePaymentSteps'
  const [shouldCallBookingCreate, setShouldCallBookingCreate] = useState(false)
  const { setChosenProductForBookingLoading, isLoggedIn } = props
  const { loadingConfigure } = useInitialDataLoader(props.insuranceType, props.enquiryId, props.distributorId)
  const [{ loadingSetFormData }, setFormData] = useSetFormData(props.insuranceType, loadingConfigure)
  const [, setChosenProduct] = useSetChosenProduct(props.insuranceType, loadingConfigure, loadingSetFormData)
  const [, bookingConfigLoaded] = useLoadBookingConfig(props.insuranceType, props.distributorId, null, bookingType, true, shouldCallBookingCreate)

  useEffect(() => {
    if (!isLoggedIn) {
      props.initialize({ Modernizr: window.Modernizr, reIntialize: true }) //  here we pass reIntialize because the authentication effect already get called we need call it again
    }
    const redirectTo = getCookie('redirectTo') // this redirectTo set in cookie from the tptLoginRedirectResponse
    if (redirectTo.slice(redirectTo.indexOf('#') + 1) === `/${props.insuranceType}/booking/pre-payment`) { // if path is bookingprepay then only set form data and chosen data
      setFormData({ formData: {} }, true)
      setChosenProduct({})
    }
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    const redirectTo = getCookie('redirectTo') // this redirectTo set in cookie from the tptLoginRedirectResponse
    if (redirectTo.slice(redirectTo.indexOf('#') + 1) === `/${props.insuranceType}/booking/pre-payment`) {
      setShouldCallBookingCreate(true) // if redirectto path is booking prepay that means we are calling booking create from product config at that time we need call create booking end point we added this to excute custom hook
      if (bookingConfigLoaded && setChosenProductForBookingLoading) {
        props.redirectToPath(redirectTo.slice(redirectTo.indexOf('#') + 1), '/tptloginredirect-handler')
        setShouldCallBookingCreate(false)
      } else if (bookingConfigLoaded) {
        props.setChosenProductForBooking()
      }
    } else {
      props.redirectToPath(redirectTo.slice(redirectTo.indexOf('#') + 1), '/tptloginredirect-handler')
    }
  },
  // eslint-disable-next-line
  [isLoggedIn, setChosenProductForBookingLoading, bookingConfigLoaded, shouldCallBookingCreate])
  return <Loading open />
}

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    isLoggedIn: models.customer.isCustomerLoggedIn,
    distributorId: models.configure.getDistributorId,
    chosenProduct: models.insuranceProducts.getChosenProduct
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...selectorData,
    routeProps: ownProps,
    initialChosenProductLoading: state.loading.effects.insuranceProducts.setChosenProduct,
    setChosenProductForBookingLoading: state.loading.effects.insuranceProducts.setChosenProductForBooking
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { insuranceType } = ownProps
  return {
    setChosenProductForBooking: () => {
      return dispatch.insuranceProducts.setChosenProductForBooking({
        insuranceType
      })
    },

    setChosenProduct: ({ productOptionId, productId, institutionId }) => {
      return dispatch.insuranceProducts.setChosenProduct({
        insuranceType,
        productOptionId,
        productId,
        institutionId,
        quoteType: 'listing'
      })
    },

    initialize: dispatch.customer.initialize
  }
}

const TptRedirectSuccessHandlerContainer = connect(mapStateToProps, mapDispatchToProps)(TptLoginRedirectHandler)
TptRedirectSuccessHandlerContainer.path = 'tptloginredirect-handler'
export default TptRedirectSuccessHandlerContainer
