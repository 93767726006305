
import Gender from './Gender'
import Income from './Income'
import SmokeDeclare from './SmokeDeclare'
import Recommender from './Recommender'
import DateOfBirth from './DateOfBirth'
import CoverTerm from './CoverTerm'
import SumAssured from './SumAssured'
import IndianMobileNumber from './IndianMobileNumber'
import StepHeader from './StepHeader'
import StepDescription from './StepDescription'
import ValidationErrors from './ValidationErrors'
import CoverTermTillAge from './CoverTermTillAge'
import { isNotDefined } from 'utils'
import { validateFormData } from 'smartcovr_api/src/insuranceApi'
import 'components/css/onboardElements.scss'
import PincodeField from './PincodeField'
import IdvFilter from './IdvFilter'
import PolicyTypeFilter from './PolicyTypeFilter'
import PolicyTerm from './PolicyTerm'
import PurchasePriceAnnuityPayoutFilter from './PurchasePriceAnnuityPayoutFilter'
import PlanType from './PlanType'
import CoverTermHealth from './CoverTermHealth'
import MoreFilters from './MoreFilters'
import HealthSumAssured from './HealthSumAssured'
const validateStep = (insuranceType) => {
  return (formData) => validateFormData({
    formData,
    insuranceType
  })
}
export const onboardComposer = (props) => {
  const { handleChange, updateChange, steps, formType, validationErrors, hdfcLandingPage, formData, stepIndex, id, showError, className, configure, smartConfigure, disabled, getErrorMessageByKey, device, insuranceType, schema, errors, productData, onboardSchema, errHandler, onFilterChange, filterData } = props
  const { income, dateOfBirth, gender, cType, mobile, sumAssured, age, mobileDnd, pincode, purchasePrice, annuityPayout } = formData
  const { minTerm, maxTerm, maxMatAge, saMin, saMax, eligibleCoverUpto, fixedTerm, isProductConfigHandle } = props
  const coverTerm = productData && productData.coverTerm
  const planType = productData && productData.planType
  return ({
    dateOfBirth: (
      <DateOfBirth
        hdfcLandingPage={hdfcLandingPage}
        onboardSchema={schema}
        dateOfBirth={dateOfBirth}
      // label={steps[findIndex(steps, (s) => s.stepName === 'dateOfBirth')].description}
        handleChange={handleChange}
        id={id}
        formType={formType}
        validationErrors={validationErrors}
        getErrorMessageByKey={getErrorMessageByKey}
        device={device}
      />
    ),
    mobile: (
      <IndianMobileNumber
        hdfcLandingPage={hdfcLandingPage}
        mobile={mobile}
        handleChange={handleChange}
        id={id}
        formType={formType}
        validationErrors={validationErrors}
        getErrorMessageByKey={getErrorMessageByKey}
        device={device}
        mobileDnd={mobileDnd}
      />
    ),
    gender: (
      <Gender
        hdfcLandingPage={hdfcLandingPage}
        gender={gender}
      // label={steps[findIndex(steps, (s) => s.stepName === 'gender')].description}
        handleChange={handleChange}
        id={id}
        formType={formType}
        validationErrors={validationErrors}
        getErrorMessageByKey={getErrorMessageByKey}
        device={device}
      />
    ),
    income: (
      <Income
        hdfcLandingPage={hdfcLandingPage}
        income={income}
      // label={steps[findIndex(steps, (s) => s.stepName === 'income')].description}
        handleChange={handleChange}
        id={id}
        formType={formType}
        validationErrors={validationErrors}
        getErrorMessageByKey={getErrorMessageByKey}
        device={device}
      />
    ),
    cType: (
      <SmokeDeclare
        hdfcLandingPage={hdfcLandingPage}
        cType={cType}
      // label={steps[findIndex(steps, (s) => s.stepName === 'cType')].description}
        handleChange={handleChange}
        id={id}
        formType={formType}
        validationErrors={validationErrors}
        getErrorMessageByKey={getErrorMessageByKey}
        device={device}
      />
    ),
    coverTerm: (
      <CoverTerm
        coverTerm={coverTerm}
        errors={errors}
        handleChange={handleChange}
        className={className}
        id='coverTerm'
        formType={formType}
        validateStep={validateStep(insuranceType)}
        disabled={disabled}
      />
    ),
    coverTermTillAge: (
      <CoverTermTillAge
        age={age}
        coverTerm={coverTerm}
        eligibleCoverUpto={eligibleCoverUpto}
        fixedTerm={fixedTerm}
        showError={showError}
        handleChange={handleChange}
        className={className}
        id='coverTerm'
        formType={formType}
        validateStep={validateStep(insuranceType)}
        disabled={disabled}
        minTerm={minTerm}
        maxTerm={maxTerm}
        maxMatAge={maxMatAge}
      />
    ),
    sumAssured: (
      <SumAssured
        sumAssured={sumAssured}
        errors={errors}
        handleChange={handleChange}
        updateChange={updateChange}
        className={className}
        configure={configure}
        id='sumAssured'
        formType={formType}
        validateStep={validateStep(insuranceType)}
        disabled={disabled}
        saMin={saMin}
        saMax={saMax}
      />
    ),
    pincode: (
      <PincodeField
        hdfcLandingPage={hdfcLandingPage}
        pincode={pincode}
        handleChange={handleChange}
        id={id}
        formType={formType}
        validationErrors={validationErrors}
        getErrorMessageByKey={getErrorMessageByKey}
        device={device}
      />
    ),
    StepHeader: (
      <StepHeader
        content={!isNotDefined(stepIndex) ? steps[stepIndex].title : undefined}
      />
    ),
    StepDescription: (
      <StepDescription
        content={!isNotDefined(stepIndex) ? steps[stepIndex].help.descriptionText : undefined}
      />
    ),
    ValidationErrors: (
      <ValidationErrors
        errors={validationErrors}
      />
    ),
    Recommender: (
      <Recommender
        recoContent={!isNotDefined(stepIndex) ? steps[stepIndex].help.recommendationText : undefined}
      />
    ),
    idv: (
      <IdvFilter
        errors={errors}
        handleChange={handleChange}
        updateChange={updateChange}
        className={className}
        configure={configure}
        id='idv'
        isProductConfigHandle={!!isProductConfigHandle}
        formType={formType}
        disabled={disabled}
        formData={formData}
        productData={productData}
        pptOptions={props.pptOptions}
      />
    ),
    policyType: (
      <PolicyTypeFilter
        errors={errors}
        formData={formData}
        productData={productData}
        className={className}
        configure={configure}
        id='policyType'
        formType={formType}
        insuranceType={insuranceType}
        disabled={disabled}
        onboardSchema={onboardSchema}
      />
    ),
    policyTerm: (
      <PolicyTerm
        id='policyTerm'
        formData={formData}
        productData={productData}
        disabled={disabled}
        insuranceType={insuranceType}
        handleChange={handleChange}
        isProductConfigHandle={isProductConfigHandle}
        pptOptions={props.pptOptions}
      />
    ),
    purchasePriceAnnuityPayoutFilter: (
      <PurchasePriceAnnuityPayoutFilter
        purchasePrice={purchasePrice}
        annuityPayout={annuityPayout}
        errors={errors}
        handleChange={handleChange}
        updateChange={updateChange}
        className={className}
        configure={configure}
        formType={formType}
        validateStep={validateStep(insuranceType)}
        disabled={disabled}
        productData={productData}
        errHandler={errHandler}
      />
    ),
    planType: (
      <PlanType
        handleChange={handleChange}
        id='annuityPlans'
        planType={planType}
      />
    ),
    coverTermHealth: (
      <CoverTermHealth
        coverTerm={coverTerm}
        errors={errors}
        handleChange={handleChange}
        className={className}
        configure={configure}
        id='healthCoverTerm'
        formType={formType}
      />
    ),
    healthSumAssured: (
      <HealthSumAssured
        formData={formData}
        sumAssured={sumAssured}
        errors={errors}
        handleChange={handleChange}
        updateChange={updateChange}
        className={className}
        configure={configure}
        id='healthSumAssured'
      />
    ),
    moreFilters: (
      <MoreFilters
        errors={errors}
        handleChange={handleChange}
        updateChange={updateChange}
        className={className}
        configure={configure}
        smartConfigure={smartConfigure}
        filterData={filterData}
        id='moreFilters'
        formType={formType}
        onFilterChange={onFilterChange}
        device={device}
      />
    )
  })
}
