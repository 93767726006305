import { useState, useEffect, useRef } from 'react'
import { Grid, Button } from 'semantic-ui-react'
import { H5, Body2 } from 'components/Header'
import PolicySummary from './booking/PolicySummary'
import DataLoader from 'components/DataLoader'
import { productComposer } from './product'
import { getDisplayData } from '../helper/productHelper'
import FetchDataLoader from 'components/FetchDataLoader'
import DisplayTabs from 'components/DisplayTabs'
import SecondaryStickyHeader from 'components/SecondaryStickyHeader'
import Loading from 'components/Loading'
import { onboardComposer } from 'components/onboardElements'
import FilterMenuWeb from './listing/FilterMenuWeb'
import { useInitialDataLoader, useSetFormData, useSetChosenProduct, useLoadProposerRelations, useLoadBookingConfig } from 'components/customHooks'
import 'components/css/productConfig.scss'
import { isNotEmpty, isNotDefined } from 'utils'
import produce from 'immer'
import { sendDropOffLink, validateFormData } from 'smartcovr_api/src/insuranceApi'
import IsAlreadyUser from 'components/isAlreadyUserCheck'
import config from '../../config'
import TermProductOptions from './product/TermProductOptions'
import AnnuityProductOptions from './product/AnnuityProductOptions'
import PaymentFrequency from './listing/PaymentFrequncy'

const ProductConfig = (props) => {
  const [editRiderId, setEditRiderId] = useState('')
  const [editPayoutId, setEditPayoutId] = useState('')
  const [stateFormData, setStateFormData] = useState(props.formData)
  const [stateProductData, setStateProductData] = useState(props.productData)
  const [errors, setErrors] = useState('')
  // FIXME: remove this
  const [proceed, setProceed] = useState(false)
  const [shouldCallBookingCreate, setShouldCallBookingCreate] = useState(false)
  const [mobileInsuredScreen, setMobileInsuredScreen] = useState(true)
  const componentRef = useRef({ dropOffFired: false })
  const [err, setErr] = useState()
  const errHandler = (err) => {
    setErr(err)
  }

  const {
    insuranceType,
    formData,
    productData,
    setChosenProductForBookingLoading,
    distributorId,
    iamId,
    filters
  } = props
  const { loadingConfigure, configure } = useInitialDataLoader(insuranceType, props.enquiryId, distributorId)
  const [{ loadingSetFormData }, setFormData] = useSetFormData(insuranceType, loadingConfigure, 'configure')
  const [, setChosenProduct, chosenProductMetadata] = useSetChosenProduct(insuranceType, loadingConfigure, loadingSetFormData)
  const { proposerRelations } = useLoadProposerRelations(insuranceType, chosenProductMetadata)
  const [, bookingConfigLoaded] = useLoadBookingConfig(insuranceType, distributorId, null, props.bookingType, true, shouldCallBookingCreate)
  const { resetBookingConfig } = props
  const objectTemplate = (props) => {
    return (
      <div className='mainTemp'>
        {props.properties.map((element, idx) => <div key={idx} className='property-wrapper childTemp'>{element.content}</div>)}
      </div>
    )
  }

  useEffect(() => {
    setFormData({ formData: {} }, true)
    setChosenProduct({})
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    setStateFormData(formData)
  }, [formData])

  useEffect(() => {
    setStateProductData(productData)
  }, [productData])

  useEffect(() => {
    const eventHandler = (ev) => {
      ev.preventDefault()
      if (isNotEmpty(formData) && isNotEmpty(chosenProductMetadata) && !componentRef.current.dropOffFired) {
        componentRef.current.dropOffFired = true
        sendDropOffLink({
          applicationFormData: {
            ...formData,
            selectedProduct: chosenProductMetadata,
            iamId
          },
          path: 'product-config',
          distributorId
        })
      }
      return null
    }
    window.addEventListener('unload', eventHandler)
    return () => window.removeEventListener('unload', eventHandler)
  }, [formData, chosenProductMetadata, distributorId, iamId])

  const handleChange = (product, data, id) => {
    const { productInsurerId, institutionId, insurerId } = product
    if (componentRef.current.typingTimeout) {
      clearTimeout(componentRef.current.typingTimeout)
    }
    if (id === 'coverTerm' || !isNotDefined(data.coverTerm)) {
      const newProductData = produce(stateProductData, draft => {
        Object.assign(draft, data)
      })
      setStateProductData(newProductData) // to store data in local state
      props.setProductData({
        productOptionInsurerId: insurerId,
        institutionId,
        insurerId: productInsurerId,
        productData: newProductData
      })
    } else if (id === 'healthSumAssured') {
      const newFormData = produce(stateFormData, draft => {
        Object.assign(draft, data)
      })
      setStateFormData(newFormData) // to store data in local state
      props.setProductData({
        productOptionInsurerId: insurerId,
        institutionId,
        insurerId: productInsurerId,
        formData: newFormData,
        productData: stateProductData
      })
    } else if (id === 'policyTerm' || !isNotDefined(data.formData)) {
      const newFormData = produce(stateFormData, draft => {
        Object.assign(draft, data.formData)
      })
      const newProductData = produce(stateProductData, draft => {
        Object.assign(draft, data.productData)
      })
      setStateFormData(newFormData) // to store data in local state
      props.setProductData({
        productOptionInsurerId: insurerId,
        institutionId,
        insurerId: productInsurerId,
        formData: newFormData,
        productData: newProductData
      })
    } else if (id === 'payoutFrequency' || !isNotDefined(data.payoutFrequency)) {
      const newProductData = produce(stateProductData, draft => {
        Object.assign(draft, data)
      })
      setStateProductData(newProductData) // to store data in local state
      props.setProductData({
        productOptionInsurerId: insurerId,
        institutionId,
        insurerId: productInsurerId,
        productData: newProductData
      })
    } else {
      // check for errors
      const newFormData = produce(stateFormData, draft => {
        Object.assign(draft, data)
      })
      if (insuranceType === 'annuity') {
        const { errors } = validateFormData({
          insuranceType,
          formData: {
            ...newFormData,
            ...data
          }
        })
        setErrors(errors)
      }
      setStateFormData(newFormData) // to store data in local state
    }
  }
  const updateChange = (id) => {
    let error
    if (id !== 'idv' && insuranceType === 'term') {
      const { errors } = product.validateSumAssured(stateFormData)
      error = errors
    }
    if (id === 'purchasePrice') {
      const { errors } = product.validatePurchasePrice(stateFormData)
      error = errors
    }
    if (id === 'annuityPayout') {
      const { errors } = product.validateAnnuityPayout(stateFormData)
      error = errors
    }
    if (!isNotDefined(error) && error.length > 0) {
      setErrors(error)
    } else {
      setErrors(null)
      let newFormData
      if (id === 'purchasePrice') {
        newFormData = produce(stateFormData, draft => {
          draft.annuityPayout = 0
        })
        const { productInsurerId, institutionId, insurerId } = product
        props.setProductData({
          productOptionInsurerId: insurerId,
          institutionId,
          insurerId: productInsurerId,
          formData: newFormData
        })
      } else if (id === 'annuityPayout') {
        newFormData = produce(stateFormData, draft => {
          draft.purchasePrice = 0
        })
        const { productInsurerId, institutionId, insurerId } = product
        props.setProductData({
          productOptionInsurerId: insurerId,
          institutionId,
          insurerId: productInsurerId,
          formData: newFormData
        })
      } else {
        const { productInsurerId, institutionId, insurerId } = product
        props.setProductData({
          productOptionInsurerId: insurerId,
          institutionId,
          insurerId: productInsurerId,
          formData: stateFormData
        })
      }
    }
  }

  const { authPaths } = config
  useEffect(() => {
    if (!props.isLoggedIn && (authPaths.indexOf('booking/pre-payment') > -1)) { // User is not loggedIn and booking prepay is not included in authPath
      if (proceed && !setChosenProductForBookingLoading) {
        props.redirectToPath('booking/pre-payment')
        setProceed(false)
      }
    } else {
      if (proceed) {
        setShouldCallBookingCreate(true)
        if (!setChosenProductForBookingLoading && bookingConfigLoaded) {
          props.redirectToPath('booking/pre-payment')
          setProceed(false)
          setShouldCallBookingCreate(false)
        }
      }
    }
  },
  // eslint-disable-next-line
  [proceed, setChosenProductForBookingLoading, bookingConfigLoaded, shouldCallBookingCreate])
  const onBook = async () => {
    props.setDropOff(insuranceType, 'booking/pre-payment')
    // FIXME: need to call setEnquiryData to verify selected product
    if (insuranceType === 'annuity') {
      if (isNaN(stateFormData.annuityPayout) || isNaN(stateFormData.purchasePrice) || !isNotDefined(err)) {
        return
      }
    }
    resetBookingConfig()
    await props.setChosenProductForBooking()
    setProceed(true)
  }

  const addRider = (riderId) => {
    setEditRiderId(riderId)
  }
  const addPayout = (payoutType) => {
    setEditPayoutId(payoutType)
  }
  const onRiderChange = (data) => {
    const { setProductData } = props
    setEditRiderId('')
    if (isNotEmpty(data)) {
      const { change, insurerId, institutionId } = data
      setProductData({
        productData: change,
        insurerId,
        institutionId
      })
    }
  }
  const onPayoutChange = ({ change, insurerId, institutionId, schemaKey }) => {
    setEditPayoutId(-1)
    const { setProductData } = props
    if (isNotEmpty(change)) {
      setProductData({
        schemaKey,
        productData: change,
        insurerId,
        institutionId
      })
    }
  }
  const closeRiderEdit = () => {
    setEditRiderId('')
  }
  const closePayoutEdit = () => {
    setEditPayoutId('')
  }
  const onPaymentChange = (product, formInfo) => {
    const data = formInfo.formData
    const { setProductData } = props
    const newProductData = produce(stateProductData, draft => {
      if (data.paymentOption !== 'SP' && data.paymentFrequency === 'S') {
        // Reset payment frequency
        draft.paymentFrequency = 'A'
      } else {
        draft.paymentFrequency = data.paymentFrequency
      }
      if (data.paymentOption === 'RP' && !product.wholeLife) {
        draft.ppt = stateProductData.coverTerm
      } else if (data.paymentOption === 'SP') {
        draft.paymentFrequency = 'S'
        draft.ppt = 1
      } else if (data.paymentOption.startsWith('TA')) {
        const tillAge = parseInt(data.paymentOption.replace('TA', ''))
        draft.ppt = tillAge - stateFormData.age
      } else if (data.paymentOption.startsWith('L')) {
        draft.ppt = parseInt(data.paymentOption.replace('L', ''))
      }
      draft.paymentOption = data.paymentOption
    })
    const { productInsurerId, institutionId, insurerId } = product
    setProductData({
      productOptionInsurerId: insurerId,
      institutionId,
      insurerId: productInsurerId,
      productData: newProductData
    })
  }

  const {
    chosenProduct,
    device,
    applicationData: { applicationFormData },
    paymentOptionSchema,
    changeChosenProductLoading
  } = props
  if (proceed) {
    return <Loading id='loading_prepay_booking_config' open />
  }
  // FIXME: Condition to check is if configure loading has ended as well
  if (loadingConfigure || !isNotEmpty(stateFormData) || !isNotEmpty(chosenProduct) || !isNotEmpty(proposerRelations)) {
    return <FetchDataLoader message='Fetching Configurations for your Product...' />
  }
  const product = getDisplayData({
    product: chosenProduct,
    formData: stateFormData,
    productData: stateProductData,
    paymentFrequencies: configure.common.paymentFrequencies,
    paymentOptions: configure.productListing.paymentOptionsPriority,
    payoutFrequencies: configure.common.payoutFrequencies
  })
  const { minTerm, maxTerm, maxMatAge, saMin, saMax, eligibleCoverUpto, fixedTerm } = product
  const onboardElementsProps = {
    handleChange: (data, id) => handleChange(product, data, id),
    updateChange,
    errors,
    formType: 'filter',
    configure,
    formData: stateFormData,
    productData: stateProductData,
    insuranceType,
    minTerm,
    maxTerm,
    maxMatAge,
    saMin,
    saMax,
    fixedTerm,
    eligibleCoverUpto,
    errHandler,
    isProductConfigHandle: true, // to handle updateChange and handleChange based on the page we are on
    chosenProduct,
    pptOptions: product.pptOptions // added, to be passed to filterMenu in auto insurance
  }
  const OnboardComponentMap = onboardComposer(onboardElementsProps)
  const { productOptionId } = product
  // FIXME: If proposerRelationsForInstitution is empty, throw error here. Something went wrong
  const { termPolicySummaryDisplay } = configure.booking
  const { gstDisclaimer } = configure.common.paymentSummaryInfo
  const listingDisplay = `${insuranceType}ListingDisplay`
  const { view } = configure.productListing[listingDisplay]
  const {
    onReview,
    loading,
    productSelectionDetails,
    setProductData,
    changeChosenProduct,
    productDataLoading
  } = props
  const itemProps = {
    product,
    onReview,
    onReviewBack: () => {
      if (['car', 'bike'].includes(insuranceType) && !isNotDefined(stateFormData.businessOption) && stateFormData.businessOption === 'renewal') {
        props.redirectToPath('renew-policy')
      } else {
        props.redirectToPath('select-product')
      }
    },
    formData: stateFormData,
    view,
    device,
    actualProduct: chosenProduct,
    productData: stateProductData,
    insuranceType
  }
  const summaryProps = {
    product,
    insuranceType,
    Loader: DataLoader,
    termPolicySummaryDisplay,
    gstDisclaimer,
    ...itemProps,
    contentType: 'review'
  }

  const ComponentMap = productComposer({
    ...itemProps,
    loading,
    closeRiderEdit,
    index: 0, // FIXME:
    editRiderId,
    onRiderChange,
    addRider,
    onPayoutChange,
    closePayoutEdit,
    objectTemplate,
    addPayout,
    editPayoutId,
    proposerRelations,
    contentType: 'review',
    onBook,
    id: productOptionId,
    applicationFormData,
    onPaymentChange: (data) => {
      onPaymentChange(product, data)
    },
    paymentOptionSchema,
    insuranceType,
    setProductData
  })

  const isExistingCustomerHandleChange = (value) => {
    const { productInsurerId, institutionId, insurerId } = product
    const data = {
      hlec: {
        isExistingCustomer: value
      }
    }
    const newProductData = produce(stateProductData, draft => {
      Object.assign(draft, data)
    })
    setStateProductData(newProductData) // to store data in local state
    props.setProductData({
      productOptionInsurerId: insurerId,
      institutionId,
      insurerId: productInsurerId,
      productData: newProductData
    })
  }

  const onFilterChange = (data) => {
    if (insuranceType === 'annuity') {
      props.setFilterData(data)
      setStateProductData({ ...stateProductData, payoutFrequency: data.value[0].value })
    }
  }

  const { title, description } = configure.productConfig
  // const device = this.props.routeProps.device
  // FIXME: add check in case selected product is empty, should show loading and in componentDidUpdate add check to see if its empty and redirect to listing
  if (device === 'mobile') {
    const isInsuredScreen = proposerRelations.length > 1 && mobileInsuredScreen
    const availableAddonsCount = product.features.filter(feature => feature.pricing === 'available')
    let currentPayoutFrequency = productData.payoutFrequency
    if (filters && !isNotDefined(filters.filterData.payoutFrequency)) {
      currentPayoutFrequency = filters.filterData.payoutFrequency[0].value
    }
    const tabList = []
    if (availableAddonsCount.length > 0) {
      tabList.push({
        title: 'Available addons',
        display: ComponentMap.AvailableAddonsMobile
      })
    }
    insuranceType !== 'annuity' && tabList.push({
      title: 'Riders',
      display: ComponentMap.FeaturesAndRiders
    })
    if (productSelectionDetails.length > 1) {
      insuranceType === 'annuity' && tabList.push({
        title: 'Plan Option',
        display: <AnnuityProductOptions productSelectionDetails={productSelectionDetails} changeChosenProduct={changeChosenProduct} product={product} device={device} />
      })
    }
    insuranceType === 'term' && tabList.push({
      title: 'Payout',
      display: ComponentMap.PayoutTerms
    })
    insuranceType === 'annuity' && tabList.push({
      title: 'Payout',
      display: <>{ComponentMap.PayoutTerms} <div><PaymentFrequency onFilterChange={onFilterChange} configure={configure} currentFrequency={currentPayoutFrequency} device={device} /></div></>
    })
    tabList.push({
      title: 'Payment option',
      display: ComponentMap.PaymentOption
    })
    return (
      <div className='productConfig'>
        {(productDataLoading || changeChosenProductLoading) && <Loading id='loading_product_detail' open />}
        <div className='flexContainer primaryDetails'>
          <div className='leftColumn'>
            {ComponentMap.InsurerWithLogoInline}
          </div>
          <div className='rightColumn flexContainer'>
            {insuranceType === 'annuity' ? (ComponentMap.PurchasePrice) : (ComponentMap.Premium)}
          </div>
        </div>
        {isInsuredScreen && (
          <div className='insuredMobile'>
            {ComponentMap.Insured}
            <div className='buttonGroup'>
              <Button
                secondary
                content='CONFIGURE PRODUCT'
                onClick={() => setMobileInsuredScreen(false)}
              />
              {ComponentMap.BackButton}
            </div>
          </div>
        )}
        {!isInsuredScreen && (
          <DisplayTabs
            tabList={tabList}
            bookButton={ComponentMap.Book}
            backButton={(proposerRelations.length > 1) ? () => setMobileInsuredScreen(true) : ComponentMap.BackButton}
          />
        )}
      </div>
    )
  }
  const ExistingCustomerFeature = product.features.find(feature => feature.categoryId === 256)
  // convert the following to list
  return (
    <SecondaryStickyHeader
      id='product-config-header'
      stickyContent={
        <FilterMenuWeb
          ComponentMap={OnboardComponentMap}
          wholeLife={product.wholeLife}
          disableEdit
          formData={stateFormData}
          productData={stateProductData}
          insuranceType={insuranceType}
          device={device}
          configure={configure}
          smartConfigure={props.smartConfigure}
          onFilterChange={onFilterChange}
        />
      }
    >
      <div className='productConfig' id={`${productOptionId}_product_config`}>
        {(productDataLoading || changeChosenProductLoading) && <Loading id='loading_product_detail' open />}
        <Grid>
          <Grid.Row>
            <Grid.Column width='11' className='leftColumn'>
              <div className='productConfigDescription'>
                {ExistingCustomerFeature !== undefined &&
                  <IsAlreadyUser
                    text={ExistingCustomerFeature.input.title} radioValue={productData.hlec.isExistingCustomer} onChange={(value) => {
                      isExistingCustomerHandleChange(value)
                    }}
                  />}
                <H5 color='primaryLight'>
                  {title}
                </H5>
                <Body2 color='grey'>
                  {description}
                </Body2>
              </div>
              {productSelectionDetails.length > 1 && (
                insuranceType !== 'annuity' ? (<TermProductOptions productSelectionDetails={productSelectionDetails} changeChosenProduct={changeChosenProduct} product={product} />) : (<AnnuityProductOptions productSelectionDetails={productSelectionDetails} changeChosenProduct={changeChosenProduct} product={product} device={device} />)
              )}
              {ComponentMap.Insured}
              {ComponentMap.FeaturesAndRiders}
              {(insuranceType === 'term' || insuranceType === 'annuity') && ComponentMap.PayoutTerms}
              {ComponentMap.PaymentOption}
              <div className='configButtons'>
                {ComponentMap.Book}
                {ComponentMap.BackButton}
              </div>
            </Grid.Column>
            <Grid.Column width='5' className='rightColumn'>
              <PolicySummary
                {...summaryProps}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    </SecondaryStickyHeader>
  )
}
export default ProductConfig
