import { isNotEmpty, isNotDefined } from 'utils'
import { getBenefits } from './insurance/helpers/productHelper'

import { getProductDataById, getAllPolicies, getFeaturesForInsuranceType } from '../services/insuranceService'
import { getAllUpcomingPremiums, getAllPaidPremiums } from '../services/commonService'
import { reduceRootStateDraft, checkAndFinishDraft, reduceRootStateUpdated } from './insurance/modelHelpers'

/**
   * Gets all policies and features for insurance type and applies map on all policies to
   * create a new object of selected values, which includes product and policy details.
   * This new object is the modified policies which is then passed in reducer
   * Along with this allUpcoming premiums data is also created and passed in the reducer.
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadPolicies = async (dispatch, { insuranceType, skip, limit }, draftRootState) => {
  try {
    const { distributorId, premiumPaymentUrls } = draftRootState.configure
    const allPolicies = await getAllPolicies({
      iamId: draftRootState.customer.customer.iamId,
      distributorId,
      insuranceType,
      skip,
      limit
    })
    const products = []
    await allPolicies.policies.reduce(async (promise, policy) => {
      await promise
      if (products.length > 0) {
        const productExist = products.findIndex(ele => ele.insurerId === policy.Product.insurerId) > -1
        if (productExist) {
          return
        }
      }
      const product = await getProductDataById({
        productInsurerId: policy.Product.insurerId,
        distributorId,
        insuranceType
      })
      products.push(product)
    }, Promise.resolve())

    const allUpcomingPremiums = []
    const modifiedPolicies = []
    for (const policy of allPolicies.policies) {
      if (isNotEmpty(policy.application.ApplicationStatuses)) {
        const product = products.find(ele => ele.insurerId === policy.Product.insurerId)
        const productOption = product.productOptions.find(po => po.insurerId === policy.Product.productOptionInsurerId)
        const currentDate = new Date()
        if (isNotEmpty(policy?.paymentSchedule)) {
          let count = 0
          for (const payment of policy.paymentSchedule) {
            const upcomingDate = new Date(payment.dueDate)
            if (upcomingDate > currentDate) {
              if (count < 4) {
                count++
                const daysRemaining = Math.round((upcomingDate - currentDate) / (1000 * 3600 * 24))
                let color = 'blue'
                if (daysRemaining <= 7) {
                  color = 'yellow'
                }
                allUpcomingPremiums.push({
                  productName: product.productName,
                  productOptionName: productOption.productOptionBasics.productOptionName,
                  dueDate: payment.dueDate,
                  numberOfDaysRemaining: daysRemaining,
                  policyNumber: policy.policyNumber,
                  premiumAmount: payment.amount,
                  color,
                  premiumPaymentUrl: premiumPaymentUrls[product.finanalyticsId]
                })
              } else {
                break
              }
            }
          }
        }
        let expiringIn
        const endDate = new Date(policy.policyEndDate)
        const daysRemainingToExpire = Math.round((endDate - currentDate) / (1000 * 3600 * 24))
        if (daysRemainingToExpire <= 30) {
          expiringIn = daysRemainingToExpire
        }
        modifiedPolicies.push({
          application: policy.application,
          policyNumber: policy.policyNumber,
          insuranceType: policy.insuranceType,
          sumAssured: policy?.sumAssured,
          idv: policy?.idv,
          AnnuityApplication: policy?.application.AnnuityApplication,
          productData: policy?.application.productData,
          policyStartDate: policy.policyStartDate,
          policyEndDate: policy.policyEndDate,
          policyIssuanceDate: policy.policyDetail.policyIssuanceDate || '',
          premium: policy.premium,
          paymentFrequency: policy.paymentFrequency,
          coverterm: policy.coverterm,
          ppt: policy.ppt,
          productDetails: {
            productName: product.productName,
            productDescription: product.productDescription,
            shortName: product.shortName,
            productOptionName: productOption.productOptionBasics.productOptionName,
            productOptionDescription: productOption.productOptionBasics.productOptionDescription,
            imageUrl: product.imageUrl,
            productBrochureUrl: product.productBrochureUrl,
            productUrl: product.productUrl,
            productInsurerId: policy.Product.insurerId,
            productOptionInsurerId: policy.Product.productOptionInsurerId
          },
          paymentSchedule: policy.paymentSchedule,
          expiringIn
        })
      }
    }
    dispatch.policies.storePoliciesData({ modifiedPolicies, count: allPolicies.count, insuranceType })
    if (allUpcomingPremiums.length > 0) {
      dispatch.policies.storeAllUpcomongPremiumsData({ allUpcomingPremiums, insuranceType })
    }
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
   * Gets the selected policy and appends more details to it like riders, features, benefits,
   * upcomingPremiums and previous premiums. Then reducer is called to store the values in state
   *
   * @param {*} dispatch
   * @param {*} { insuranceType, id }
   * @param {*} draftRootState
   * @returns
   */
const loadPolicyDetails = async (dispatch, { insuranceType, id }, draftRootState) => {
  try {
    const { policiesDraft } = reduceRootStateDraft(draftRootState, insuranceType)
    const { distributorId, premiumPaymentUrls } = draftRootState.configure
    const policyPos = policiesDraft.policies.findIndex(ele => ele.application.applicationNumber === id)
    const product = await getProductDataById({
      productInsurerId: policiesDraft.policies[policyPos].productDetails.productInsurerId,
      distributorId,
      insuranceType
    })
    const featureResponse = await getFeaturesForInsuranceType({
      distributorId,
      insuranceType
    })
    const productOption = product.productOptions.find(po => po.insurerId === policiesDraft.policies[policyPos].productDetails.productOptionInsurerId)
    const features = []
    productOption.riderIds && productOption.riderIds.forEach(rider => {
      if (rider.pricing === 'free') {
        const featureDetails = featureResponse.featureContent[`CATID_${rider.categoryId}`]
        features.push({
          heading: featureDetails.heading,
          description: featureDetails.descriptions.descriptionStatic,
          type: 'Inbuilt'
        })
      } else if (rider.pricing === 'available') {
        const featureDetails = featureResponse.featureContent[`CATID_${rider.categoryId}`]
        if (isNotEmpty(policiesDraft.policies[policyPos].application.productData) && isNotDefined(rider.schemaKey) && policiesDraft.policies[policyPos].application.productData[rider.schemaKey].include) {
          features.push({
            heading: featureDetails.heading,
            description: featureDetails.descriptions.descriptionStatic,
            type: 'Available'
          })
        }
      } else if (rider.pricing === 'included') {
        const featureDetails = featureResponse.featureContent[`CATID_${rider.categoryId}`]
        features.push({
          heading: featureDetails.heading,
          description: featureDetails.descriptions.descriptionStatic,
          type: 'Included'
        })
      }
    })
    const benefits = getBenefits(product)
    const { ApplicationRiders, finanalyticsId } = policiesDraft.policies[policyPos].application
    const modifiedRiders = ApplicationRiders.map(rider => {
      const mainRider = productOption.riders.find(ele => ele.insurerId === rider.insurerId)
      return {
        ...rider,
        riderName: mainRider.riderName,
        riderShortName: mainRider.riderShortName,
        riderDescription: mainRider.productDescription
      }
    })
    const upcomingPremiums = []
    const previousPremiums = []
    const currentDate = new Date()
    if (policiesDraft.policies[policyPos]?.paymentSchedule) {
      policiesDraft.policies[policyPos].paymentSchedule.forEach(ele => {
        if (new Date(ele.dueDate) < currentDate) {
          previousPremiums.unshift(ele)
        } else {
          upcomingPremiums.push({
            ...ele,
            premiumPaymentUrl: premiumPaymentUrls[finanalyticsId]
          })
        }
      })
    }
    policiesDraft.policies[policyPos] = {
      ...policiesDraft.policies[policyPos],
      productDetails: {
        ...policiesDraft.policies[policyPos].productDetails,
        features,
        benefits
      },
      application: {
        ...policiesDraft.policies[policyPos].application,
        ApplicationRiders: modifiedRiders
      },
      upcomingPremiums,
      previousPremiums
    }
    const updatedRootState = checkAndFinishDraft(draftRootState)
    const { policiesUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
    dispatch.policies.storePoliciesData({ insuranceType, modifiedPolicies: policiesUpdated.policies })
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
   * Gets all upcoming premiums and passes the response in the reducer
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadUpcomingPremiums = async (dispatch, { insuranceType }, draftRootState) => {
  try {
    const distributorId = draftRootState.configure.distributorId
    const upcomingPremiums = await getAllUpcomingPremiums({
      distributorId,
      insuranceType
    })
    dispatch.policies.storeUpcomongPremiumsData({ upcomingPremiums, insuranceType })
  } catch (error) {
    console.log(error)
    throw error
  }
}

/**
   * Gets all paid premiums and passes the response in the reducer
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadPaidPremiums = async (dispatch, { insuranceType }, draftRootState) => {
  try {
    const distributorId = draftRootState.configure.distributorId
    const paidPremiums = await getAllPaidPremiums({
      distributorId,
      insuranceType
    })
    dispatch.policies.storePaidPremiumsData({ paidPremiums, insuranceType })
  } catch (error) {
    console.log(error)
    throw error
  }
}

export {
  loadPolicies,
  loadPolicyDetails,
  loadUpcomingPremiums,
  loadPaidPremiums
}
