import { PureComponent } from 'react'
import { List, Icon } from 'semantic-ui-react'
import CheckBoxListItem from 'components/CheckBoxListItem'
import EditAddOns from '../../EditAddOns'
import { rupeeFormatter } from 'utils'
import { isNotDefined } from 'utils/lib/basic'

class ListingAvailableAddons extends PureComponent {
  render () {
    const {
      schema,
      uiSchema,
      featureFormData,
      rules,
      productOptionId,
      checked,
      heading,
      description,
      shortName,
      points,
      childKey,
      device,
      isEdit,
      onSubmit,
      onCancel,
      insuranceType,
      updatePopUp,
      feature,
      hasEdit,
      rider
    } = this.props

    let displayAmount
    if (!isNotDefined(rider) && !isNotDefined(rider.currentPremium)) {
      if (isNaN(rider.currentPremium?.premium)) {
        displayAmount = ''
      } else {
        displayAmount = (
          <>
            <Icon name='rupee' color='green' size='small' />
            {`${rupeeFormatter(rider.currentPremium.premium)}`}
          </>
        )
      }
    } else {
      displayAmount = 'Available'
    }
    // FIXME: Move the popupContent to seperate class.
    const popUpContent = (
      <List.Content className='riderEdit'>
        <EditAddOns
          schema={schema}
          uiSchema={uiSchema}
          formData={featureFormData}
          rules={rules || []}
          onSubmit={onSubmit}
          submitText='UPDATE ADDON'
          onCancel={onCancel}
          size='medium'
        />
      </List.Content>
    )
    const listItemPopUp = {
      isEdit,
      editable: hasEdit,
      onCheckBoxClick: updatePopUp,
      onHeaderClick: updatePopUp,
      heading,
      description,
      shortName,
      points,
      displayAmount,
      id: feature.schemaKey,
      productOptionId,
      checked,
      popUpContent,
      childKey,
      device,
      insuranceType
    }
    return (
      <CheckBoxListItem {...listItemPopUp} />
    )
  }
}
export default ListingAvailableAddons
