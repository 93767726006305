import { isNotDefined } from 'utils'
import { Header, Form, Menu } from 'semantic-ui-react'
import PersonalDetailsHeader from './PersonalDetailsHeader'

const FilterMenuWebTerm = (props) => {
  const { insuranceType, ComponentMap, onFilterChange, formData, productData, configure, filters, onboardSchema, wholeLife, disableEdit } = props
  // const hasFilter = isNotEmpty(filters.filterData) // to show remove all when some filters are applied
  let currentPaymentFrequency = productData.paymentFrequency
  if (filters && !isNotDefined(filters.filterData.paymentFrequency)) {
    currentPaymentFrequency = filters.filterData.paymentFrequency[0].value
  }
  // eslint-disable-next-line
  function filterChangeHandler (data, config, oldValue) {
    const value = [{ value: data.value }]
    if (oldValue === data.value) {
      return
    }
    onFilterChange({ key: config.key, value }) // in case of single checkbox - pass direct data
  }

  return (
    <Menu fluid>
      {!wholeLife &&
        <Menu.Item>
          <Form>
            <Header sub className='mt-0'>Cover Upto</Header>
            {ComponentMap.coverTermTillAge}
          </Form>
        </Menu.Item>}
      <Menu.Item>
        <Form>
          <Header sub className='mt-0'>Sum Assured</Header>
          {ComponentMap.sumAssured}
        </Form>
      </Menu.Item>
      {!disableEdit &&
        <PersonalDetailsHeader
          id='personal_details_header'
          formData={formData}
          onFilterChange={onFilterChange}
          currentPaymentFrequency={currentPaymentFrequency}
          configure={configure}
          onboardSchema={onboardSchema}
          insuranceType={insuranceType}
          productData={productData}
        />}
    </Menu>
  )
}

export default FilterMenuWebTerm

/** FILTER MENU CODE COMMENTED !!! NOT USED
 *
    filterOrder.map((filterKey, index) => {
      const config = filterMenuConfig[filterKey]
      let defaultValue
      let value
      const dataValue = (filters.filterData[config.key] && filters.filterData[config.key][0].value)
      let options = config.values.map((cfKey) => {
        return {
          key: cfKey.value,
          text: <Header sub>{cfKey.display}</Header>,
          value: cfKey.value
        }
      })
      if (config.key === 'paymentOption') {
        defaultValue = productData.paymentOption
      }
      value = dataValue || defaultValue
      return (
        <div
          key={`${index}select`}
          className='dropDownContainer flexContainer'
        >
          <Dropdown
            selectOnBlur={false}
            disabled={disabled}
            placeholder={config.title}
            id={config.key}
            options={options}
            onChange={(e, data) => this.onFilterChange(data, config, value)}
            value={value}
          />
          <div className='labelHolder'>
            {dataValue && <Label
              color='secondary'
              size='mini'
              className='changeLabel'
              onClick={() => resetFilter(filterKey)}
            >
              {'REMOVE'}
            </Label>}
          </div>
        </div>
      )
    })
  }
  <Menu.Item className='removeAll' id='remove_all'>
    <Header
      id='removeFilters'
      sub
      onClick={() => resetFilter('all')}
      color={(hasFilter) ? 'accent' : 'grey'}
    >
      {'Remove All'}
    </Header>
  </Menu.Item>
***/
