import { useEffect, useState } from 'react'
import { Input } from 'semantic-ui-react'
import { Caption, Body1 } from 'components/Header'
import CustomFieldWrapper from '../components/CustomFieldWrapper'
import PanWidget from '../widgets/PanWidget'
import { verifyPanNumber } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'
import { capitalize, isNotEmpty } from 'utils'
import Loading from 'components/Loading'

const ErrorComponent = ({ message, color, show }) => {
  if (!show) return null
  return (
    <Caption color={color}>
      {message}
    </Caption>
  )
}

const PanDetails = ({ completeName, response, handlePanNameUpdate }) => {
  if (response && response.panStatus && !response.panCorrect) {
    const fullName = `${completeName.firstName} ${completeName.middleName ?? ''} ${completeName.lastName}`
    const nameOnPan = `${response.firstName} ${response.middleName ?? ''} ${response.lastName}`
    return (
      <Caption>
        Name provided: {fullName}
        <br />
        Name on PAN: {nameOnPan}
        <br />
        Change PAN Number or &nbsp;
        <span className='clickableLink' onClick={() => handlePanNameUpdate(response)}>
          Update Name with name on Pan Card
        </span>
      </Caption>
    )
  }
  return null
}

const PanField = (props) => {
  const { schema, uiSchema, errorSchema, disabled, formData: { panNo, verifiedDetails }, onChange } = props
  const panVerified = isNotEmpty(panNo) && isNotEmpty(verifiedDetails) && verifiedDetails.panStatus
  const [state, setState] = useState({ loading: false, message: 'Kindly verify your PAN Details.', color: 'red', show: !panVerified })
  const [panResponse, setPanResponse] = useState(null)
  const [updateClicked, setUpdateClicked] = useState(false)
  const placeholder = uiSchema['ui:placeholder']
  const { completeName } = props.getGlobalFormData()

  useEffect(() => {
    if (updateClicked) {
      setUpdateClicked(false)
      handleVerifyPan()
    }
  }, [updateClicked]) // eslint-disable-line
  const handlePanNoChange = (value) => {
    onChange({ panNo: value })
    setState({ loading: false, message: 'Kindly verify your PAN Details.', color: 'red', show: true })
    setUpdateClicked(false)
  }

  const handlePanNameUpdate = (response) => {
    const updatedFormData = produce(props.getGlobalFormData(), (draft) => {
      draft.completeName.firstName = capitalize(response.firstName)
      draft.completeName.middleName = capitalize(response.middleName)
      draft.completeName.lastName = capitalize(response.lastName)
    })

    props.onFormDataChange(updatedFormData, {}, 'completeName')
    setState({ ...state, message: '', show: false })
    setUpdateClicked(true)
  }

  const getPanDetails = (fullName) => {
    return verifyPanNumber({
      panNo,
      firstName: fullName.firstName,
      middleName: fullName.middleName,
      lastName: fullName.lastName
    })
  }

  const handleVerifyPan = async () => {
    setState({ ...state, loading: true, message: 'Verifying Pan details ...', color: 'blue', show: true })
    const response = await getPanDetails(completeName)
    setPanResponse(response)
    if (response.panStatus) {
      setState({ ...state, loading: false, message: '', color: 'green', show: true })

      if (response.panCorrect) {
        setState({ ...state, loading: false, message: 'Pan verfied successfully', color: 'green', show: true })
        onChange({
          panNo,
          verifiedDetails: response
        })
      } else {
        setState({ ...state, loading: false, message: 'Complete name does not match that on the Card.', color: 'red', show: true })
      }
    } else {
      setState({ ...state, loading: false, message: 'Pan Number cannot be verified.', color: 'red', show: true })
    }
  }

  return (
    <CustomFieldWrapper
      schema={schema}
      uiSchema={uiSchema}
      errorSchema={errorSchema}
    >
      <div className='flexContainer align-items-center gap-1'>
        {state.loading && <Loading id='pan_loading' open />}
        <PanWidget
          disabled={disabled || panVerified}
          as={Input}
          type='number'
          placeholder={placeholder}
          onChange={handlePanNoChange}
          value={panNo ?? ''}
          {...uiSchema.panNo}
        />
        {panVerified && (
          <Body1
            id='deletePan'
            className='verifyLink'
            color='red'
            onClick={() => handlePanNoChange('')}
          >
            Delete
          </Body1>
        )}
        {!panVerified && (
          <Body1
            id='verifyPanLink'
            className='verifyLink'
            color='blue'
            onClick={handleVerifyPan}
          >
            Verify
          </Body1>)}
      </div>
      <ErrorComponent message={state.message} color={state.color} show={state.show} />
      <PanDetails completeName={completeName} response={panResponse} handlePanNameUpdate={handlePanNameUpdate} />
    </CustomFieldWrapper>
  )
}

export default PanField
