import { createMedia } from '@artsy/fresnel'

const screenMapper = {
  mobile: { size: 320, screen: 'mobile', device: 'mobile' },
  tablet: { size: 768, screen: 'tablet', device: 'web' },
  computer: { size: 992, screen: 'computer', device: 'web' },
  largeScreen: { size: 1200, screen: 'largeScreen', device: 'web' },
  wideScreen: { size: 1920, screen: 'wideScreen', device: 'web' }
}

const createBreakpoints = () => {
  const breakpoints = {}
  Object.keys(screenMapper).forEach(key => {
    const { size } = screenMapper[key]
    breakpoints[key] = size
  })
  return breakpoints
}

const AppMedia = createMedia({
  breakpoints: createBreakpoints()
})

const { createMediaStyle, Media, MediaContextProvider } = AppMedia
const mediaStyles = createMediaStyle()

const Responsive = ({ children }) => {
  const renderScreens = () => {
    return Object.keys(screenMapper).map(key => {
      const { screen, device } = screenMapper[key]
      return (
        <Media key={key} at={screen}>
          {children({ device, screen })}
        </Media>
      )
    })
  }
  return (
    <>
      <style>{mediaStyles}</style>
      <MediaContextProvider>
        {renderScreens()}
      </MediaContextProvider>
    </>
  )
}

export default Responsive
