import { useState } from 'react'
import { rupeeFormatter } from 'utils'
import { Button } from 'semantic-ui-react'
import { H6, Subtitle2 } from 'components/Header'
import PolicyPremiumContent from './PolicyPremiumContent'
// import { mockUpcomingPremiums } from '../DummyData'
import './policyDetailsMainPage.scss'

const PayButtonBody = ({ premiumData, index }) => {
  return (
    <div className='payButton'>
      <Button
        className='buttonStyle'
        id={`payPremium${index}`}
        onClick={() => window.open(premiumData.premiumPaymentUrl)}
        type='submit'
        content={`PAY ₹${rupeeFormatter(Math.round(premiumData.amount))}`}
        secondary
      />
    </div>
  )
}

export const UpcomingPremiumsForPolicy = (props) => {
  const { upcomingPremiums } = props
  const [viewMore, setViewMore] = useState(false)
  const handleViewMoreDetails = () => {
    setViewMore(!viewMore)
  }
  const upcomingPremiumData = []
  const moreUpcomingPremiumData = []
  upcomingPremiums.forEach((premiumData, index) => {
    if (index < 3) {
      upcomingPremiumData.push(
        <div key={`upcomingPremiumData-${index}`} className='premiumDataContent'>
          <PolicyPremiumContent data={premiumData} />
          <PayButtonBody {...{ premiumData, index }} />
        </div>
      )
    } else {
      moreUpcomingPremiumData.push(
        <div key={`moreUpcomingPremiumData-${index}`} className='premiumDataContentVm'>
          <PolicyPremiumContent data={premiumData} />
          <PayButtonBody {...{ premiumData, index }} />
        </div>
      )
    }
  })
  let content
  if (upcomingPremiumData.length === 0) {
    content = (
      <div className='noOngoingPolicies'>
        <Subtitle2 color='secondary'>No Upcoming Premiums</Subtitle2>
      </div>
    )
  } else {
    content = (
      <>
        {upcomingPremiumData}
        {viewMore && moreUpcomingPremiumData}
        {!viewMore && moreUpcomingPremiumData.length > 0 && (
          <div className='row dataButton premiumDataContent'>
            <span onClick={handleViewMoreDetails}>+ View More Premiums</span>
          </div>
        )}
        {viewMore && moreUpcomingPremiumData.length > 0 && (
          <div className='row dataButton premiumDataContent'>
            <span onClick={handleViewMoreDetails}>- View Less Premiums</span>
          </div>
        )}
      </>
    )
  }
  return (
    <div className='upcomingPremiumsForPolicy'>
      <H6 color='primaryLight'>Upcoming Premiums</H6>
      {content}
    </div>
  )
}
