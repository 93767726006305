import { connect } from 'react-redux'
import { dashboardDrawerItemMapper } from './distributorDashboardHelper'
import { DashboardItemComponent } from './DashboardItemComponent'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, selectedItemKey } = ownProps
  const selectorItemData = dashboardDrawerItemMapper[insuranceType][selectedItemKey].selector()
  const selection = ownProps.store.select((models) => ({
    distributorId: models.configure.getDistributorId
  }))
  const selectorData = selection(state, {
    insuranceType
  })

  return {
    ...ownProps,
    ...selectorData,
    selectorItemData,
    routePropsLoading: ownProps,
    insuranceType
  }
}

const DashboardItemsCommonContainer = connect(mapStateToProps)(DashboardItemComponent)
export default DashboardItemsCommonContainer
