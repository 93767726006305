
import { H6 } from 'components/Header'
export const FormHeader = (props) => {
  return (
    <H6 color='grey' className='formHeader'>
      {props.children}
    </H6>
  )
}
export default FormHeader
