import { isNotEmpty, isNotNumber } from 'utils'

/**
   * checks for string field, whether it exists or not
   *
   * @param {*} value
   * @returns boolean
   */
const stringField = (value) => {
  if (typeof value === 'string' || value instanceof String) {
    return true
  }
  return false
}

/**
   * checks for enquiry, whether it exists or not
   *
   * @param {*} value
   * @returns boolean
   */
const enquiry = (value) => {
  if (!isNotEmpty(value)) {
    return false
  }
  // other validations for enquiry
  return true
}

/**
   * checks for number field, whether it exists or not
   *
   * @param {*} value
   * @returns boolean
   */
const numberField = (value) => {
  if (isNotNumber(value)) {
    return false
  }
  return true
}
export default {
  insuranceType: stringField,
  distributorId: stringField,
  enquiryId: stringField,
  institutionId: stringField,
  enquiry,
  formData: isNotEmpty,
  uiConfig: isNotEmpty,
  productOptionId: stringField,
  productId: numberField
}
