import { normalize } from 'normalizr'
import { productSchema } from '../productsSchema'
import { isUndefined } from 'utils'
const mergeAndCategoriseProducts = ({ products, config }) => {
  const categorisedProducts = config.listing.categories.map((c) => {
    const subCategories = c.subCategories.map((subCat) => {
      const subCategoryProducts = products.filter((p) => p.sub_category_key === subCat.key).sort((subCat1, subCat2) => subCat1.rank - subCat2.rank)
      return {
        id: subCat.key,
        title: subCat.title,
        description: subCat.description,
        products: subCategoryProducts
      }
    })
    return {
      id: c.key,
      title: c.title,
      description: c.description,
      subCategories
    }
  })
  return normalize(categorisedProducts, productSchema)
}
const validateAddToBuy = (scheme, payload) => {
  const { amount } = payload
  const errors = []
  // Step 1: Check if purchase is allowed
  if (!scheme.purchase_allowed) {
    errors.push('PURCHASE_NOT_ALLOWED')
  }
  // Step 2 validate if amount is greater than minimum order
  if (amount < scheme.minimum_purchase_amount) {
    errors.push('AMOUNT_TOO_SMALL')
  }
  return errors
}
const validateAndMergeOnTimeOrder = (globalState, data) => {
  const oneTimeOrders = []
  data.transactionData.forEach(td => {
    const item = globalState.buy.cart.find(ele => ele.itemId === td.itemId)
    const product = globalState.products.categories.entities.products[item.schemeId]
    if (item.investmentType === 'ONETIME') {
      oneTimeOrders.push({
        amount: td.amount,
        scheme_code: product.scheme_code
      })
    }
  })
  return {
    uak_id: data.uak_id,
    payment_success_link: data.payment_success_link,
    transactions_data: oneTimeOrders
  }
}
const validateAndMergeSipOrder = (globalState, data) => {
  const errors = []
  const sipOrders = []
  data.transactionData.every(td => {
    const item = globalState.buy.cart.find(ele => ele.itemId === td.itemId)
    const product = globalState.products.categories.entities.products[item.schemeId]
    if (item.investmentType === 'SIP') {
      if (!isUndefined(td.mandateId)) {
        const mandate = globalState.user[data.uak_id].bank_mandates.mandates_data.find(ele => ele.id === td.mandateId)
        let mandateExists = false
        if ([2, 3, 4].indexOf(mandate.status) > -1) {
          mandateExists = true
          if (td.sp_first_order_today !== 1) {
            errors.push('FIRST ORDER TODAY')
            return false
          }
        } else if (mandate.status === 5) {
          mandateExists = true
        }
        if (mandateExists) {
          sipOrders.push({
            amount: td.amount,
            day_of_month: td.day_of_month,
            first_order_today: td.sp_first_order_today,
            scheme_code: product.scheme_code
          })
        } else {
          errors.push('MANDATE DOES NOT EXIST')
          return false
        }
      } else {
        errors.push('NO MANDATE')
        return false
      }
    }
  })
  // if no mandate for any of the sips, returns errors
  if (errors.length > 0) {
    return {
      errors
    }
  } else {
    return {
      uak_id: data.uak_id,
      payment_success_link: data.payment_success_link,
      order_data_list: sipOrders
    }
  }
}
export {
  mergeAndCategoriseProducts,
  validateAddToBuy,
  validateAndMergeOnTimeOrder,
  validateAndMergeSipOrder
}
