import { useContext } from 'react'
import { Button } from 'semantic-ui-react'
import { DataAccessContext } from 'components/context'
import { JsonSchemaOnboardForm, JsonSchemaValidation, JsonSchemaHelper } from 'components/jsonSchemaForms'
import { isNotEmpty } from 'utils'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'

import './annuityOnboarding.scss'

const AnnuityOnboardingForm = (props) => {
  const {
    configure,
    insuranceType,
    onSubmit: parentOnSubmit,
    countries,
    ...restProps
  } = useContext(DataAccessContext)

  const {
    onboard: {
      onboardSchema: { schema, uiSchema, rules }
    }
  } = configure

  const handleSubmit = (e) => {
    const newFormData = { ...restProps.formData, ...e.formData }
    const pAge = JsonSchemaValidation.calculateAge(newFormData.pDob)
    const sAge = JsonSchemaValidation.calculateAge(newFormData.sDob)
    if (newFormData?.investFor === 'self') {
      newFormData.applicationType = 'sl'
      newFormData.pAge = pAge
      delete newFormData.sAge
    } else {
      newFormData.applicationType = 'jl'
      newFormData.pAge = pAge
      newFormData.sAge = sAge
    }
    newFormData.insuranceType = insuranceType
    const omittedFormData = JsonSchemaHelper.omitExtraData(newFormData, schema.dependencies)

    if (newFormData.mobileDnd === true) {
      parentOnSubmit(omittedFormData)
    }
  }

  const onValidate = (validateformData, errors) => {
    const mobileError = JsonSchemaValidation.validateMobileNumber(validateformData.mobile, countries)
    if (mobileError) {
      errors.mobile.addError(mobileError)
    }
    return errors
  }

  const transformErrors = (errors) => {
    if (isNotEmpty(errors)) {
      const newErrors = errors.filter(error => !['oneOf', 'enum'].includes(error.name))
      newErrors.forEach((error) => {
        const currentProperty = error.params?.missingProperty || error.property.replace('.', '')
        let selectedSchema
        if (currentProperty) {
          selectedSchema = JsonSchemaHelper.extractNestedObject(schema, currentProperty, currentProperty)
        }
        let title = 'This'
        if (selectedSchema && selectedSchema[currentProperty]?.title && selectedSchema[currentProperty]?.title.trim().length !== 0) {
          title = selectedSchema[currentProperty].title
          if (title === 'Secondary Annuitant:') {
            title = 'Date of Birth'
          }
        }
        error.message = getCustomErrorMessage(title, error)
      })
      return newErrors
    }
    return errors
  }

  return (
    <div className='main4' style={{ backgroundColor: props.bgColor }}>
      <div className='form-container'>
        <div className='bg_annuity_onboard_form_card container shadow-lg rounded'>
          <JsonSchemaOnboardForm
            className='onboardingForm'
            schema={schema}
            uiSchema={uiSchema}
            rules={rules}
            formData={restProps.formData}
            liveValidate={false}
            id='annuity'
            onSubmit={handleSubmit}
            validate={onValidate}
            transformErrors={transformErrors}
            formContext={{
              countries
            }}
          >
            <Button
              id='getQuotes_button'
              className='get_button'
              secondary
              content='Get Quotes'
            />
          </JsonSchemaOnboardForm>
        </div>
      </div>
    </div>
  )
}

export default AnnuityOnboardingForm
