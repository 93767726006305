import dayjs from 'dayjs'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'
import { isNotDefined } from 'utils'
import 'react-datepicker/dist/react-datepicker.css'
import './customDateWidget.scss'

const type = {
  m: 'month',
  d: 'day',
  y: 'year'
}
function calculateDateRange (option, optionType, givenDate = new Date()) {
  const newDate = givenDate ? new Date(givenDate) : new Date()
  if (isNotDefined(option)) return newDate.setDate(newDate.getDate() + undefined)
  let date
  const value = parseInt(option)
  const key = option.toString().slice(-1)
  if (!isNotDefined(type[key])) {
    date = dayjs(newDate).add(value, type[key]).valueOf()
  } else if (optionType === 'min') {
    date = newDate.setDate(newDate.getDate() - option)
  } else if (optionType === 'max') {
    date = newDate.setDate(newDate.getDate() + option)
  }
  return date
}

/**
 * in ui schema pass ui:options: {min, max } property
 * where min and max can be: number of days ex: (10) (without any +/- sign)
 * or a string ex: (10m) (10d) (10y) (with +/- sign)
 */
const CustomDateWidget = props => {
  const {
    id,
    value,
    options,
    placeholder,
    ...inputProps
  } = props
  inputProps.label = null
  let minDate = new Date().setFullYear(new Date().getFullYear() - 100)
  let maxDate = new Date().setFullYear(new Date().getFullYear() + 100)
  if (!isNotDefined(options.isMinor) && options.isMinor) {
    const currentDate = new Date()
    currentDate.setFullYear(currentDate.getFullYear() - 18)
    maxDate = new Date()
    minDate = currentDate
  } else if (!isNotDefined(options.min) && !isNotDefined(options.max)) {
    minDate = calculateDateRange(options.min, 'min')
    maxDate = calculateDateRange(options.max, 'max')
  }
  return (
    <DatePicker
      id={!id ? 'customDateWidget' : id}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      dateFormat='dd-MM-yyyy'
      placeholderText={placeholder}
      selected={value ? new Date(value) : undefined}
      onChange={date => {
        props.onChange(new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      }}
      wrapperClassName='calendar-input-wrapper'
      minDate={minDate}
      maxDate={maxDate}
      customInput={
        <InputMask
          mask='99-99-9999'
          id={!id ? 'customDateWidget' : id}
        />
      }
    />
  )
}

export default CustomDateWidget
