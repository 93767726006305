import AgeWidget from './AgeWidget'
import TextareaWidget from './TextareaWidget'
import DateWidget from './DateWidget'
import InputWidget from './InputWidget'
import AmountWidget from './AmountWidget'
import CreditCardWidget from './CreditCardWidget'
import EmailWidget from './EmailWidget'
// import IndiaMobileWidget from './IndiaMobileWidget'
import RTEditorWidget from './RTEditorWidget'
import SelectButtonWidget from './SelectButtonWidget'
import RadioWidget from './RadioWidget'
import InlineRadioWidget from './InlineRadioWidget'
import FileWidget from './FilePondWidget'
import AadharWidget from './AadharWidget'
import EianWidget from './EianWidget'
import CkycWidget from './CkycWidget'
import PercentWidget from './PercentWidget'
import PeriodWidget from './PeriodWidget'
import QuestionGroup from './QuestionGroup'
import IncomeWidget from './IncomeWidget'
import OTPWidget from './OTPWidget'
import UnitConversionWidget from './UnitConversionWidget'
import PanWidget from './PanWidget'
import InputMaskWidget from './InputMaskWidget'
import SelectOptionButton from './SelectOptionButton'
import NumberFormatWidget from './NumberFormatWidget'
import CityWidget from './CityWidget'
import SecondLevelWidget from './SecondLevelWidget'
import TNCWidget from './TNCWidget'
import CustomDateWidget from './CustomDateWidget'
import RegistrationNumberWidget from './RegistrationNumberWidget'
import RegistrationNumberWithLocationWidget from './RegistrationNumberWithLocationWidget'
import RegistrationCityWidget from './RegistrationCityWidget'
import ButtonWidget from './ButtonWidget'
import MakeModelWidget from './MakeModelWidget'
import ChassisNumberWidget from './ChassisNumberWidget'
import EngineNumberWidget from './EngineNumberWidget'
import AddressWidget from './AddressWidget'
import ModalCalendarWidget from './ModalCalendarWidget'
import AlphaNumericInputWidget from './AlphaNumericInputWidget'
import PincodeWidget from './PincodeWidget'
import InsuranceCompanyWidget from './InsuranceCompanyWidget'
import CardWidget from './CardWidget'
import InternationalMobileWidget from './InternationalMobileWidget'
import PincodeWidgetAnnuity from './PincodeWidgetAnnuity'
import VehicleDateWidget from './VehicleDateWidget'
import DiseaseWidget from './DiseaseWidget'
import InspectionMethodWidget from './InspectionMethodWidget'
import MobileNumberWidget from './MobileNumberWidget'
import CountryWidget from './CountryWidget'
import FileUploadWidget from './FileUploadWidget'

const widgets = {
  DateWidget,
  TextareaWidget,
  InputWidget,
  AgeWidget,
  AmountWidget,
  CreditCardWidget,
  EmailWidget,
  MobileNumberWidget,
  RTEditorWidget,
  SelectButtonWidget,
  RadioWidget,
  InlineRadioWidget,
  FileWidget,
  AadharWidget,
  EianWidget,
  CkycWidget,
  PercentWidget,
  PeriodWidget,
  QuestionGroup,
  IncomeWidget,
  OTPWidget,
  UnitConversionWidget,
  PanWidget,
  InputMaskWidget,
  BooleanWidget: RadioWidget,
  SelectOptionButton,
  NumberFormatWidget,
  CityWidget,
  SecondLevelWidget,
  TNCWidget,
  CustomDateWidget,
  RegistrationNumberWidget,
  RegistrationNumberWithLocationWidget,
  RegistrationCityWidget,
  ButtonWidget,
  MakeModelWidget, // not used
  ChassisNumberWidget,
  EngineNumberWidget,
  AddressWidget,
  ModalCalendarWidget,
  AlphaNumericInputWidget,
  PincodeWidget,
  InternationalMobileWidget,
  PincodeWidgetAnnuity,
  InsuranceCompanyWidget,
  CardWidget,
  VehicleDateWidget,
  DiseaseWidget,
  InspectionMethodWidget,
  CountryWidget,
  FileUploadWidget
}

export default widgets
