import React from 'react'
import { unescape } from 'lodash'
import OnboardContentCard from './OnboardContentCard'
import OnboardCardDivider from './OnboardCardDivider'
import { grid } from './gridMapper'
import { isNotDefined } from 'utils/lib/basic'

const OnboardCardSection = ({ title, style, seperator, childrens, card, insuranceType }) => {
  let wowDelay = 500
  const renderChildren = () => {
    if (!childrens) return null
    return childrens.map((child, i) => {
      const length = childrens.length
      let clx = grid[length]
      if (seperator) {
        clx = grid[length + 1]
      }
      wowDelay += (i * 100)
      return (
        <React.Fragment key={`content-${i}`}>
          <OnboardContentCard {...child} className={clx} wowDelay={`${wowDelay}ms`} insuranceType={insuranceType} />
          {seperator && i < length - 1 && <OnboardCardDivider wowDelay={`${wowDelay + 100}ms`} insuranceType={insuranceType} />}
        </React.Fragment>
      )
    })
  }
  return (
    <section className='main cardSection' style={style}>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 mb-4 mt-2 text-center'>
            <h2>{unescape(title)}</h2>
          </div>
        </div>
      </div>
      <div className='container'>
        {(!isNotDefined(card) && card)
          ? (
            <div className='col-md-12 col-sm-12 col-xs-12'>
              <div className='shadow-card'>
                <div className='row justify-content-center'>
                  {renderChildren()}
                </div>
              </div>
            </div>)
          : (
            <div className='row justify-content-center'>
              {renderChildren()}
            </div>
            )}
      </div>
    </section>
  )
}

export default OnboardCardSection
