import DisplayHtmlString from 'components/DisplayHtmlString'
import { Button } from 'semantic-ui-react'
import { Caption, Subtitle1 } from 'components/Header'

function TermProductOptions ({ productSelectionDetails, product, changeChosenProduct }) {
  return (
    <div className='productSelectionCards flexContainer'>
      {productSelectionDetails.map((po, index) => {
        const { productOptionInsurerId, productOptionDescription, productOptionName, available } = po
        return (
          <Button
            disabled={!available}
            color='white'
            className={`chosenProductCard ${productOptionInsurerId === product.insurerId && 'active'}`}
            key={`key${index}`}
            onClick={() => {
              if (product.productOptionId !== po.productOptionId) {
                changeChosenProduct(productOptionInsurerId)
              }
            }}
            id={productOptionInsurerId}
          >
            <Subtitle1 color={`${productOptionInsurerId === product.insurerId ? 'primaryLight' : 'black'}`}>
              {productOptionName}
            </Subtitle1>
            <Caption className='desc'>
              {!available && 'This product is not available for the given configuration'}
              {available && <DisplayHtmlString htmlContent={productOptionDescription} />}
            </Caption>
          </Button>
        )
      })}
    </div>
  )
}

export default TermProductOptions
