export const claimsSchema = {
  claimApplicationForm: {
    schema: {
      type: 'object',
      properties: {
        claimIntimatedBy: {
          title: 'Claim Intimated By',
          type: 'string',
          enum: ['Proposer', 'Non-Proposer'],
          enumNames: ['Proposer', 'Non-Proposer']
        },
        insuranceCompany: {
          title: 'Insurance Company',
          type: 'string',
          enum: ['HDFC Standard Life', 'Max Life Insurance', 'ICICI Prudential Life', 'Bajaj Allianz Life', 'SBI Life'],
          enumNames: ['HDFC Standard Life', 'Max Life Insurances', 'ICICI Prudential Life', 'Bajaj Allianz Life', 'SBI Life']
        },
        intimatorDetails: {
          type: 'object',
          title: 'Intimator Details',
          properties: {
            verificaitonThrough: {
              title: 'Verification Through',
              type: 'string',
              enum: ['DOB ', 'Policy Number'],
              enumNames: ['DOB', 'Policy Number']
            },
            intimatorName: {
              title: 'Intimator Name',
              maxLength: 125,
              minLength: 3,
              type: 'string'
            },
            intimatorContactNumber: {
              title: 'Intimator Contact Number',
              type: 'string'
            },
            intimatorEmailId: {
              title: 'Intimator Email Id',
              type: 'string'
            }
          },
          required: ['intimatorName', 'intimatorEmailId', 'intimatorContactNumber', 'verificaitonThrough']
        },
        PolicyNumberOrRegisteredMobileNumber: {
          type: 'string',
          title: 'Policy Number or Registered Mobile Number',
          enum: ['Policy Number', 'Verify With Registered Mobile Number']
        },
        verifyPolicyNo: {
          title: 'Policy Number',
          type: 'string'
        },
        verifyRegisteredMobileNo: {
          title: 'Verify With Registered Mobile Number',
          maxLength: 10,
          minLength: 10,
          pattern: '^[0-9]+$',
          type: 'string'
        },
        authenticationRequired: {
          type: 'object',
          title: 'Authentication Required',
          properties: {
            otp: {
              title: 'Enter otp',
              type: 'number'
            }
          }
        },
        claimIntimatationDetail: {
          type: 'object',
          title: 'Claim Intimation Details',
          description: 'Policy Information',
          properties: {
            insuranceCompany: {
              title: 'Insurance Company',
              type: 'string',
              enum: ['HDFC Standard Life', 'Max Life Insurance', 'ICICI Prudential Life', 'Bajaj Allianz Life', 'SBI Life'],
              enumNames: ['HDFC Standard Life', 'Max Life Insurances', 'ICICI Prudential Life', 'Bajaj Allianz Life', 'SBI Life']
            },
            policyNumber: {
              title: 'Policy Number',
              type: 'string',
              maxLength: 25,
              minLength: 3,
              pattern: '^[a-zA-Z0-9]+$'
            },
            nameOfLifeAssured: {
              title: 'Name Of Life Assured',
              type: 'string',
              maxLength: 125,
              pattern: '^[a-zA-Z]+$'
            },
            nameOfProposer: {
              title: 'Name Of Proposer',
              type: 'string',
              maxLength: 125,
              pattern: '^[a-zA-Z]+$'
            },
            claimReportingPersonName: {
              title: 'Claim Reporting Person Name',
              type: 'string',
              maxLength: 125,
              pattern: '^[a-zA-Z]+$'
            },
            claimType: {
              title: 'Claim Type',
              type: 'string',
              enum: ['Death Claim', 'Rider Claim'],
              enumNames: ['Death Claim', 'Rider Claim']
            },
            dateOfBirthOfLifeAssured: {
              title: 'Date of Birth of Life Assured',
              type: 'string'
            },
            dateOfEvent: {
              title: 'Date of Event',
              type: 'string'
            },
            causeOfEvent: {
              title: 'Cause of Event',
              type: 'string',
              pattern: '^[a-zA-Z ]*$',
              maxLength: 500
            },
            nameOfNominee: {
              title: 'Name of Nominee',
              type: 'string',
              pattern: '^[a-zA-Z ]*$',
              maxLength: 100
            },
            nameOfclaimant: {
              title: 'Name Of Claimant',
              type: 'string',
              pattern: '^[a-zA-Z ]*$',
              maxLength: 100
            },
            relationshipWithLifeAssured: {
              title: 'Relationship with Life Assured',
              type: 'string',
              pattern: '^[a-zA-Z ]*$',
              maxLength: 100,
              minLength: 3
            },
            emailIdOfClaimant: {
              title: 'Email id of Claimant',
              type: 'string'
            },
            mobileNoOfClaimant: {
              title: 'Mobile No. Of Claimant',
              type: 'string'
            },
            addressOfClaimant: {
              title: 'Address Of Claimant',
              type: 'string',
              pattern: '^[A-Za-z0-9\\s.,\'-]+$',
              maxLength: 300
            },
            csp: {
              type: 'object',
              properties: {
                city: {
                  title: 'City',
                  type: 'string',
                  maxLength: 25
                },
                pincode: {
                  title: 'Pin Code',
                  type: 'string',
                  maxLength: 6,
                  minLength: 6
                },
                state: {
                  title: 'State',
                  type: 'string'
                }
              },
              required: ['city', 'state', 'pincode']
            },
            district: {
              title: 'District',
              type: 'string',
              pattern: '^[a-zA-Z ]*$'
            },
            country: {
              title: 'Country',
              type: 'string'
            }
          }
        },
        claimRegistration: {
          type: 'object',
          title: 'Claim Registration',
          properties: {
            claimType: {
              title: 'Claim Type',
              type: 'string',
              enum: ['Death Claim', 'Rider Claim'],
              enumNames: ['Death Claim', 'Rider Claim']
            },
            claimReason: {
              title: 'Claim Reason',
              type: 'string',
              enum: [
                'Maturity Claim',
                'Hospital and Surgical Care',
                'Disability Claim due to Accident',
                'Critical & Terminal Illness Claim',
                'Accident/Murder/Suicide/Natural Disaster or Calamities',
                'Natural Death'
              ],
              enumNames: [
                'Maturity Claim',
                'Hospital and Surgical Care',
                'Disability Claim due to Accident',
                'Critical & Terminal Illness Claim',
                'Accident/Murder/Suicide/Natural Disaster or Calamities',
                'Natural Death'
              ]
            }
          }
        },
        originalPolicyDocument: {
          title: 'Original Policy Document',
          type: 'string',
          format: 'data-url'
        },
        deathCertificate: {
          title: 'Death Certificate',
          type: 'string',
          format: 'data-url'
        },
        copyOfCancelledChequeOrBankAccountPassbook: {
          title: 'Copy of cancelled cheque or bank account passbook',
          type: 'string',
          format: 'data-url'
        },
        nomineeClaimantPhotoIdentityAddressProof: {
          title: "Nominee's/Claimant photo identity & address proof",
          type: 'string',
          format: 'data-url'
        },
        deathInHospital: {
          title: 'Death in Hospital ?',
          type: 'string',
          enum: ['Yes', 'No'],
          enumNames: ['Yes', 'No']
        },
        copyOfMedicalRecords: {
          title: 'Copy of Medical Records (Admission Notes, Discharge/ Death Summary, Test Reports etc.)',
          type: 'string',
          format: 'data-url'
        },
        attendingPhysiciansStatement: {
          title: 'Attending Physician’s statement (Form)',
          type: 'string',
          format: 'data-url'
        },
        employerCertificate: {
          title: 'Employer certificate',
          type: 'string',
          format: 'data-url'
        },
        FIROrPanchnamaPoliceComplaint: {
          title: 'FIR or Panchnama / Police complaint',
          type: 'string',
          format: 'data-url'
        },
        postMortemReport: {
          title: 'Post Mortem Report',
          type: 'string',
          format: 'data-url'
        },
        finalPoliceInvestigationReport: {
          title: 'Final Police Investigation Report (if any)',
          type: 'string',
          format: 'data-url'
        },
        criticalIllnessClaimForm: {
          title: 'Critical illness Claim form',
          type: 'string',
          format: 'data-url'
        },
        attestedCopiesOfMedicalRecordsIndoorPapersOfTheHospital: {
          title: 'Attested copies of medical records/ indoor papers of the hospital',
          type: 'string',
          format: 'data-url'
        },
        hospitalDischargeSummary: {
          title: 'Hospital discharge summary',
          type: 'string',
          format: 'data-url'
        },
        hospitalBills: {
          title: 'Hospital bills',
          type: 'string',
          format: 'data-url'
        },
        disabilityBenefitClaimForm: {
          title: 'Disability Benefit Claim form',
          type: 'string',
          format: 'data-url'
        },
        firstInformationReportFIRInCase: {
          title: 'First Information Report (FIR), in case',
          type: 'string',
          format: 'data-url'
        },
        hospitalAndSurgicalCareClaimForm: {
          title: 'Hospital and Surgical Care Claim form',
          type: 'string',
          format: 'data-url'
        },
        maturityDischargeVoucherInTheFormatSharedByTheInsurerAlongWithIntimation: {
          title: 'Maturity Discharge Voucher in the format shared by the Insurer along with intimation',
          type: 'string',
          format: 'data-url'
        }
      },
      required: [
        'claimIntimatedBy',
        'insuranceCompany',
        'intimatorDetails',
        'PolicyNumberOrRegisteredMobileNumber',
        'verifyPolicyNo',
        'verifyRigisteredMobileNo',
        'authenticationRequired',
        'claimIntimatationDetail',
        'claimRegistration',
        'originalPolicyDocument',
        'deathCertificate',
        'copyOfCancelledChequeOrBankAccountPassbook',
        'nomineeClaimantPhotoIdentityAddressProof',
        'deathInHospital',
        'copyOfMedicalRecords',
        'attendingPhysiciansStatement',
        'employerCertificate',
        'FIROrPanchnamaPoliceComplaint',
        'postMortemReport',
        'finalPoliceInvestigationReport',
        'criticalIllnessClaimForm',
        'attestedCopiesOfMedicalRecordsIndoorPapersOfTheHospital',
        'hospitalDischargeSummary',
        'hospitalBills',
        'disabilityBenefitClaimForm',
        'firstInformationReportFIRInCase',
        'hospitalAndSurgicalCareClaimForm',
        'maturityDischargeVoucherInTheFormatSharedByTheInsurerAlongWithIntimation'
      ]
    },
    uiSchema: {
      // 'ui:order': [
      //   'claimIntimatedBy',
      //   'insuranceCompany',
      //   'intimatorDetails',
      //   'PolicyNumberOrRegisteredMobileNumber',
      //   'verifyPolicyNo',
      //   'verifyRigisteredMobileNo',
      //   'authenticationRequired',
      //   'claimIntimatationDetail',
      //   'claimRegistration',
      //   'originalPolicyDocument',
      //   'deathCertificate',
      //   'copyOfCancelledChequeOrBankAccountPassbook',
      //   'nomineeClaimantPhotoIdentityAddressProof',
      //   'deathInHospital',
      //   'copyOfMedicalRecords',
      //   'attendingPhysiciansStatement',
      //   'employerCertificate',
      //   'FIROrPanchnamaPoliceComplaint',
      //   'postMortemReport',
      //   'finalPoliceInvestigationReport',
      //   'criticalIllnessClaimForm',
      //   'attestedCopiesOfMedicalRecordsIndoorPapersOfTheHospital',
      //   'hospitalDischargeSummary',
      //   'hospitalBills',
      //   'disabilityBenefitClaimForm',
      //   'firstInformationReportFIRInCase',
      //   'hospitalAndSurgicalCareClaimForm',
      //   'maturityDischargeVoucherInTheFormatSharedByTheInsurerAlongWithIntimation'
      // ],
      claimIntimatedBy: {
        'ui:widget': 'radio'
      },
      insuranceCompany: {
        'ui:widget': 'select'
      },
      intimatorDetails: {
        verificaitonThrough: {
          'ui:widget': 'select'
        },
        intimatorName: {
          'ui:widget': 'text'
        },
        intimatorContactNumber: {
          'ui:widget': 'text'
        },
        intimatorEmailId: {
          'ui:widget': 'email'
        }
      },
      PolicyNumberOrRegisteredMobileNumber: {
        'ui:widget': 'radio'
      },
      verifyPolicyNo: {
        'ui:widget': 'text'
      },
      verifyRegisteredMobileNo: {
        'ui:widget': 'text',
        'ui:placeholder': 'Enter Registered Mobile Number'
      },
      authenticationRequired: {
        otp: {
          'ui:widget': 'text'
        }
      },
      claimIntimatationDetail: {
        insuranceCompany: {
          'ui:widget': 'select'
        },
        policyNumber: {
          'ui:widget': 'text'
        },
        nameOfLifeAssured: {
          'ui:widget': 'text'
        },
        nameOfProposer: {
          'ui:widget': 'text'
        },
        claimReportingPersonName: {
          'ui:widget': 'text'
        },
        claimtype: {
          'ui:widget': 'select'
        },
        dateOfBirthOfLifeAssured: {
          'ui:widget': 'date'
        },
        dateOfEvent: {
          'ui:widget': 'date'
        },
        causeOfEvent: {
          'ui:widget': 'text'
        },
        nameOfNominee: {
          'ui:widget': 'text'
        },
        nameOfclaimant: {
          'ui:widget': 'text'
        },
        relationshipWithLifeAssured: {
          'ui:widget': 'text'
        },
        emailIdOfClaimant: {
          'ui:widget': 'email'
        },
        mobileNoOfClaimant: {
          'ui:widget': 'text'
        },
        addressOfClaimant: {
          'ui:widget': 'text'
        },
        csp: {
          'ui:title': 'Pin Code',
          'ui:field': 'PincodeField',
          pincode: {
            'ui:widget': 'text'
          },
          city: {
            'ui:widget': 'select'
          },
          state: {
            'ui:widget': 'select'
          }
        },
        district: {
          'ui:widget': 'text'
        },
        country: {
          'ui:widget': 'select'
        }
      },
      claimRegistration: {
        claimType: {
          'ui:widget': 'select'
        },
        claimReason: {
          'ui:widget': 'select'
        }
      },
      deathInHospital: {
        'ui:widget': 'radio',
        'ui:options': {
          inline: true
        }
      }
    },
    rules: [
      {
        conditions: {
          claimIntimatedBy: {
            is: 'Proposer'
          }
        },
        event: {
          type: 'remove',
          params: {
            field: 'intimatorDetails'
          }
        }
      },
      {
        conditions: {
          claimIntimatedBy: 'empty'
        },
        event: {
          type: 'remove',
          params: {
            field: 'intimatorDetails'
          }
        }
      },
      {
        conditions: {
          deathInHospital: {
            is: 'No'
          }
        },
        event: {
          type: 'remove',
          params: {
            field: ['copyOfMedicalRecords', 'attendingPhysiciansStatement', 'employerCertificate']
          }
        }
      },
      {
        conditions: {
          deathInHospital: 'empty'
        },
        event: {
          type: 'remove',
          params: {
            field: ['copyOfMedicalRecords', 'attendingPhysiciansStatement', 'employerCertificate']
          }
        }
      }
    ]
  }
}

export const dependency = {
  PolicyNumberOrRegisteredMobileNumber: {
    oneOf: [
      {
        properties: {
          PolicyNumberOrRegisteredMobileNumber: {
            enum: ['Policy Number']
          },
          verifyPolicyNo: {
            title: 'Verify Policy No',
            type: 'string',
            maxLength: 125,
            minLength: 3,
            pattern: '^[a-zA-Z0-9]+$'
          }
        },
        required: ['verifyPolicyNo']
      },
      {
        properties: {
          PolicyNumberOrRegisteredMobileNumber: {
            enum: ['Verify With Registered Mobile Number']
          },
          verifyRegisteredMobileNo: {
            title: 'Verify With Registered Mobile Number',
            type: 'string'
            // maxLength: 10,
            // minLength: 10,
            // pattern: '^[0-9]+$'
          }
        },
        required: ['verifyRegisteredMobileNo']
      }

    ]

  }
}
