import React, { PureComponent } from 'react'
import { findIndex, isNotEmpty, isNotDefined } from 'utils'

import { calculateRiderPremium, getResetValueForKey } from 'smartcovr_api/src/insuranceApi'
import { LoadingContext } from 'components/context'
import ReviewRider from './ReviewRider'
import ListingRider from './ListingRider'
import DetailRider from './DetailRider'
import produce from 'immer'
/* eslint no-useless-catch: "off" */

class Rider extends PureComponent {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.addRemoveRider = this.addRemoveRider.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onError = this.onError.bind(this)
    this.premiumRef = React.createRef()
    this.state = {
      errors: [],
      reviewEdit: false,
      currentPremium: props.rider.currentPremium,
      riderSumAssured: props.rider.riderFormData.riderSumAssured
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.setState(produce(this.state, draft => {
      if (newProps.rider.riderFormData.riderSumAssured !== this.props.rider.riderFormData.riderSumAssured) {
        draft.riderSumAssured = newProps.rider.riderFormData.riderSumAssured
      }
      if (newProps.rider.currentPremium !== this.props.rider.currentPremium) {
        draft.currentPremium = newProps.rider.currentPremium
      }
    }))
  }

  addRemoveRider (data) {
    const { rider: { riderId, riderSchemaKey }, onRiderChange, addRider, feature, product, insuranceType } = this.props
    let productCharacteristicId
    if (feature.productCharacteristics) {
      productCharacteristicId = feature.productCharacteristics[0].productCharacteristicId
    }

    let resetValue = getResetValueForKey(riderSchemaKey, insuranceType)
    const isRenewal = !isNotDefined(product.businessOption) && product.businessOption === 'renewal'
    if (((insuranceType === 'bike' && riderSchemaKey === 126) || (insuranceType === 'car' && [136, 137, 138].includes(riderSchemaKey))) && isRenewal) {
      resetValue = this.props.productData[riderSchemaKey]
    }
    const { checked } = data
    if (!isNotDefined(checked)) {
      if (!checked) {
        this.setState(produce(this.state, draft => {
          draft.riderSumAssured = 0
        }), () => onRiderChange({
          change: {
            [riderSchemaKey]: resetValue
          },
          riderId,
          productCharacteristicId,
          insurerId: product.productInsurerId,
          productOptionIsurerId: product.insurerId,
          institutionId: product.institutionId
        })
        )
      } else {
        addRider(riderId)
      }
    } else {
      addRider(riderId)
    }
  }

  onError (errors) {
    this.setState({ errors })
  }

  async onCancel (formData, riderId) {
    const { rider } = this.props

    if (!rider.riderValid) {
      const { rider: { riderId, riderFormData }, insuranceType, feature, productOptionId, productData } = this.props
      const dataForRider = Object.assign({}, riderFormData, formData)
      dataForRider.percentOfBase = Math.round(dataForRider.riderSumAssured * 100 / dataForRider.sumAssured, 2)
      let currentPremium
      if (insuranceType === 'term') {
        try {
          currentPremium = await calculateRiderPremium({
            insuranceType,
            riderId,
            feature,
            dataForRider,
            productOptionId,
            formData: this.props.formData,
            productData
          })
        } catch (err) {
          throw err
        }
        this.setState(produce(this.state, draft => {
          draft.currentPremium = currentPremium
        }))
      }
    }
    this.props.closeRiderEdit(riderId)
  }

  async onSubmit (formData) {
    const { rider: { riderId, riderFormData, riderSchemaKey }, insuranceType, feature, productOptionId, product, productData } = this.props
    const dataForRider = Object.assign({}, riderFormData, formData)
    dataForRider.percentOfBase = Math.round(dataForRider.riderSumAssured * 100 / dataForRider.sumAssured, 2)
    let productCharacteristicId
    if (feature.productCharacteristics) {
      productCharacteristicId = feature.productCharacteristics[0].productCharacteristicId
    }
    if ((Object.prototype.hasOwnProperty.call(formData, 'include'))) {
      formData = { ...formData, include: true }
    }
    const isRenewal = !isNotDefined(product.businessOption) && product.businessOption === 'renewal'
    if (insuranceType === 'car' && [136, 137, 138].includes(riderSchemaKey) && isRenewal) {
      formData = productData[riderSchemaKey]
    }
    const isFamilyFloaterMultiRider = this.props.formData.policyType === 'familyFloater' && ['pa', 'icmi', 'ci'].includes(riderSchemaKey)
    if (insuranceType === 'health' && (this.props.formData.policyType === 'multiIndividual' || isFamilyFloaterMultiRider)) {
      let newProductData = {}
      Object.keys(formData).filter(ky => typeof formData[ky] === 'object' || ky === 'include').forEach(ky => {
        newProductData = {
          ...newProductData,
          [ky]: formData[ky]
        }
      })
      formData = newProductData
    }
    let currentPremium
    if (insuranceType === 'term') {
      try {
        currentPremium = await calculateRiderPremium({
          insuranceType,
          riderId,
          feature,
          dataForRider,
          productOptionId,
          formData: this.props.formData,
          productData,
          updateEnquiry: true
        })
      } catch (err) {
        throw err
      }
      this.setState(produce(this.state, draft => {
        draft.currentPremium = currentPremium
      }))
    }
    this.props.onRiderChange({
      change: {
        [riderSchemaKey]: formData
      },
      riderId,
      productCharacteristicId,
      insurerId: product.productInsurerId,
      productOptionIsurerId: product.insurerId,
      institutionId: product.institutionId
    })
  }

  render () {
    const {
      editRiderId,
      contentType,
      productData,
      feature,
      rider,
      productOptionId,
      device,
      childKey,
      onRiderChange,
      formData,
      insuranceType,
      product
    } = this.props
    const { shortName, description, points, heading, descriptionPiped, riderBrochureUrl, riderValid } = rider
    const { currentPremium } = this.state
    let riderFormData = rider.riderFormData
    const isPoReview = contentType === 'poreview'
    const isEdit = (rider.riderId === editRiderId)
    const { schema, uiSchema, rules, customValidationMessage } = rider.riderSchema
    // const checked = (!isNotDefined(currentPremium.premium) && currentPremium.premium !== 0)
    const hasSumAssured = riderFormData.riderSumAssured && riderFormData.riderSumAssured > 0
    const loading = this.context
    let riderLoading = false
    if (isNotEmpty(loading)) {
      if (loading.rider.status) {
        riderLoading = findIndex(feature.productCharacteristics, productCharacteristic => productCharacteristic.productCharacteristicId === loading.rider.productCharacteristicId) > -1
      } else if (!loading.payout.status) {
        riderLoading = loading.status
      }
    }
    // If not rider sum assured, reset 0 values to undefined so that json form does not crib
    if (!hasSumAssured) {
      riderFormData = produce(riderFormData, draft => {
        Object.keys(draft).forEach(ky => {
          if (draft[ky] === 0) {
            draft[ky] = undefined
          }
        })
      })
    }
    if (contentType === 'review') {
      const reviewRiderProps = {
        customValidationMessage,
        currentPremium,
        rider,
        schema,
        uiSchema,
        formData,
        riderFormData,
        contentType,
        feature,
        rules,
        productOptionId,
        checked: riderValid,
        heading,
        description,
        shortName,
        points,
        descriptionPiped,
        riderBrochureUrl,
        childKey,
        device,
        isEdit,
        onRiderChange,
        onSubmit: this.onSubmit,
        onCancel: this.onCancel,
        addRemoveRider: this.addRemoveRider,
        insuranceType,
        productData,
        product
      }
      return (
        <ReviewRider {...reviewRiderProps} />
      )
    } else if (contentType === 'detail') {
      return (
        <DetailRider rider={rider} />
      )
    } else if (isPoReview) {
      // eslint-disable-next-line
    } else {
      const listingRiderProps = {
        hasSumAssured,
        currentPremium,
        rider,
        schema,
        uiSchema,
        formData,
        riderFormData,
        feature,
        rules,
        productOptionId,
        checked: riderValid,
        heading,
        description,
        shortName,
        points,
        riderLoading,
        childKey,
        device,
        isEdit,
        onRiderChange,
        onSubmit: this.onSubmit,
        onCancel: this.onCancel,
        addRemoveRider: this.addRemoveRider,
        insuranceType,
        productData,
        product
      }
      return (
        <ListingRider
          {...listingRiderProps}
        />
      )
    }
  }
}
Rider.contextType = LoadingContext
export default Rider
