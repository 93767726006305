import { useState, useEffect, useContext } from 'react'
import { StoreContext } from 'components/context'

export function useGlobalState (mapState) {
  const store = useContext(StoreContext)
  const [state, setState] = useState(mapState(store.getState()))
  useEffect(() => store.subscribe(() => {
    // should return global state by default, if no function passed
    const newState = mapState(store.getState())
    if (state !== newState) {
      setState(newState)
    }
  })
  )
  return state
}
// mapDispatchToProps is a function which gets diapatch as an argument
export function useAction (mapDispatchToProps) {
  const store = useContext(StoreContext)
  return mapDispatchToProps(store.dispatch)
}
// selectorFunction gets (select, state) as an arguments
// for subscribing to selector use combination of useSelector and useGlobalState
export function useSelector (selectorFunction) {
  const store = useContext(StoreContext)
  return selectorFunction(store.select, store.getState())
}
