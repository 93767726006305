import { Component } from 'react'
import assign from 'lodash/assign'
import applyAllRules, { deepEquals } from 'json-schemaform-rules'
export default function applyRules (
  schema,
  uiSchema,
  rules,
  Engine, extraActions = {}
) {
  const runRules = applyAllRules(schema,
    uiSchema,
    rules,
    Engine,
    extraActions)
  return FormComponent => {
    class FormWithConditionals extends Component {
      constructor (props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.updateConf = this.updateConf.bind(this)
        const { formData = {} } = this.props

        this.shouldUpdate = false
        this.state = { schema, uiSchema }
        this.updateConf(formData)
      }

      UNSAFE_componentWillReceiveProps (nextProps) {
        const formDataChanged =
          nextProps.formData && !deepEquals(nextProps.formData, this.formData)
        if (formDataChanged) {
          this.updateConf(nextProps.formData)
          this.shouldUpdate = true
        } else {
          this.shouldUpdate =
            this.shouldUpdate ||
            !deepEquals(
              nextProps,
              Object.assign({}, this.props, { formData: nextProps.formData })
            )
        }
      }

      updateConf (formData) {
        this.formData = formData
        return runRules(formData)
          .then(conf => {
            const dataChanged = !deepEquals(this.formData, conf.formData)
            this.formData = conf.formData

            const newState = { schema: conf.schema, uiSchema: conf.uiSchema }
            const confChanged = !deepEquals(newState, this.state)
            if (dataChanged || confChanged) {
              this.shouldUpdate = true
              this.setState(newState)
            }
            return conf
          })
      }

      handleChange (change) {
        const { formData } = change
        const updTask = this.updateConf(formData)

        const { onChange } = this.props
        if (onChange) {
          updTask.then(conf => {
            const updChange = Object.assign({}, change, conf)
            onChange(updChange)
          })
        }
      }

      shouldComponentUpdate () {
        if (this.shouldUpdate) {
          this.shouldUpdate = false
          return true
        }
        return false
      }

      render () {
        // Assignment order is important
        const formConf = assign({}, this.props, this.state, {
          onChange: this.handleChange,
          formData: this.formData
        })
        return <FormComponent {...formConf} />
      }
    }

    return FormWithConditionals
  }
}
