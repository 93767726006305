import { getStore } from 'smartcovr_api/src/store'
import config from '../config'

const { cdnPath } = config
const getStoreData = () => {
  const store = getStore()
  if (!store) {
    throw new Error('Store not found')
  }
  const { configure, insuranceEnquiry } = store.getState()
  const { distributorId } = configure
  const { currentInsuranceType } = insuranceEnquiry
  return { distributorId, currentInsuranceType }
}

export const buildCommonImagePath = (path) => {
  return `${cdnPath}/images/common/${path}`
}

export const buildProductImagePath = (path) => {
  return `${cdnPath}/images/products/${path}`
}

export const buildDistributorImagePath = (path) => {
  const { distributorId } = getStoreData()
  return `${cdnPath}/images/${distributorId}/${path}`
}

export const buildDistributorAndInsuranceTypeImagePath = (path) => {
  const { distributorId, currentInsuranceType } = getStoreData()
  return `${cdnPath}/images/${distributorId}/${currentInsuranceType}/${path}`
}
