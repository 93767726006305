import { Form, Menu } from 'semantic-ui-react'
import { isNotDefined } from 'utils'
import PersonalDetailsHeaderAnnuity from './PersonalDetailsHeaderAnnuity'
import AnnuitantType from './AnnuitantType'
import PaymentFrequency from './PaymentFrequncy'

const FilterMenuWebAnnuity = (props) => {
  const { insuranceType, ComponentMap, onFilterChange, formData, productData, configure, filters, onboardSchema, disableEdit } = props
  let currentPayoutFrequency = productData.payoutFrequency
  if (filters && !isNotDefined(filters.filterData.payoutFrequency)) {
    currentPayoutFrequency = filters.filterData.payoutFrequency[0].value
  }

  return (
    <>
      <Menu fluid>
        <Menu.Item className='purchasePricePriceAnnuityPayout'>
          <Form>
            {ComponentMap.purchasePriceAnnuityPayoutFilter}
          </Form>
        </Menu.Item>
        {!disableEdit &&
          <PersonalDetailsHeaderAnnuity
            formData={formData}
            onFilterChange={onFilterChange}
            currentPayoutFrequency={currentPayoutFrequency}
            configure={configure}
            onboardSchema={onboardSchema}
            insuranceType={insuranceType}
            productData={productData}
            listing
          />}
        <Menu.Item className='annuityPayoutFilter'>
          <PaymentFrequency onFilterChange={onFilterChange} configure={configure} currentFrequency={currentPayoutFrequency} insuranceType={insuranceType} />
        </Menu.Item>
        {!disableEdit &&
          <Menu.Item className='annuityPlanType'>
            {ComponentMap.planType}
          </Menu.Item>}
      </Menu>
      {!disableEdit &&
        <AnnuitantType {...props} />}
    </>
  )
}

export default FilterMenuWebAnnuity
