export const grid = {
  1: 'col-lg-12 col-md-12',
  2: 'col-lg-6 col-md-6',
  3: 'col-lg-4 col-md-4',
  4: 'col-lg-3 col-md-3',
  5: 'col-lg-2 col-md-2',
  6: 'col-lg-2 col-md-2',
  7: 'col-lg-2 col-md-2',
  8: 'col-lg-2 col-md-2',
  9: 'col-lg-2 col-md-2',
  10: 'col-lg-2 col-md-2',
  11: 'col-lg-2 col-md-2',
  12: 'col-lg-2 col-md-2'
}
