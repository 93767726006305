
import { capitalize } from 'utils'
import { Grid } from 'semantic-ui-react'
import { Subtitle2 } from 'components/Header'
import FormHintInline from './FormHintInline'
const QuestionField = (props) => {
  const { label, children, questionNumber, id, showerror } = props
  const ucLabel = capitalize(label)
  return (
    <Grid stackable columns='equal'>
      <Grid.Row className=''>
        <Grid.Column
          textAlign='left'
          stretched
        >
          <Subtitle2 style={{ display: 'flex' }}>
            <div style={{ flex: '0.05' }}>
              {questionNumber}.
            </div>
            <div style={{ flex: '0.6' }}>
              {ucLabel}
            </div>
            <div style={{ flex: '0.3', marginLeft: 'auto' }}>
              {children}
            </div>
          </Subtitle2>
        </Grid.Column>
        <FormHintInline
          group={false}
          id={id}
          showerror={showerror}
          rawErrors={props.rawErrors}
          // ref={(ref) => this.setHintRef(ref, id)}
        />
      </Grid.Row>
    </Grid>
  )
}
export default QuestionField
