import { Button } from 'semantic-ui-react'

const DashboardDownload = (props) => {
  return (
    <Button
      onClick={() => { props.onClick(props.buttonVal, props.skipValue) }}
    >
      {props.buttonTitle}
    </Button>
  )
}
export default DashboardDownload
