import { getTotalLeadsReport, getFormSubmissionPendingReport, getSubmittedApplicationReport, getPendingApplicationReport, getEmailDeatilsReport, getSmsDeatilsReport, getDropOffReport, getConversionReport } from '../services/reportService'
// import { convertDateIntoISODateFormat } from './distributorHelper'
import { checkForBadRequest } from '../errors'
import { isNotEmpty } from 'utils'

const loadReportForTotalLeads = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    // const filterValues = convertDateIntoISODateFormat(payload.filterValues)
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getTotalLeadsReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeTotalLeadsReport({ totalLeads: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForCompletedPaymentButformSubmissionPending = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getFormSubmissionPendingReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeReportOfCompletedPaymentButFormSubmissionPending({ completedPaymentbutFormSubmissionPending: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForSubmittedApplication = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getSubmittedApplicationReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeReportOfSubmittedApplication({ submittedApplication: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForPendingApplication = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getPendingApplicationReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeReportOfPendingApplication({ pendingApplication: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForMailingDetails = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getEmailDeatilsReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeReportOfMailingDetails({ mailingDetails: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForSmsDetails = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getSmsDeatilsReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeReportOfSmsDetails({ smsDetails: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForDropOffStageReport = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getDropOffReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeReportOfDropOffStage({ dropOffStage: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
const loadReportForConversionReport = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType'], payload)
    const { insuranceType } = payload
    const filterValues = payload.filterValues
    const distributorId = draftRootState.configure.distributorId
    const reports = await getConversionReport(filterValues, distributorId, insuranceType)
    if (!isNotEmpty(filterValues.fileType)) {
      const updatedReportData = reports
      updatedReportData.filterValues = filterValues
      await dispatch.distributorReport.storeConversionReport({ conversionReport: updatedReportData, insuranceType })
    }
  } catch (error) {
    throw error
  }
}
export {
  loadReportForTotalLeads,
  loadReportForCompletedPaymentButformSubmissionPending,
  loadReportForSubmittedApplication,
  loadReportForPendingApplication,
  loadReportForMailingDetails,
  loadReportForSmsDetails,
  loadReportForDropOffStageReport,
  loadReportForConversionReport
}
