import { connect } from 'react-redux'
import { DistributorDashboard } from './components/distributorDashboard/DistributorDashboard'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    distributorId: models.configure.getDistributorId,
    availableProducts: models.configure.getAvailableProducts
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...ownProps,
    ...selectorData,
    insuranceType,
    store: ownProps.store
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    insuranceType,
    store: { select, getState }
  } = ownProps
  return {
    keycloakLogout: () => {
      return dispatch.customer.keycloakLogout()
    },
    // effects
    loadReportForTotalLeads: ({ filterValues }) => dispatch.distributorReport.loadReportForTotalLeads({ filterValues, insuranceType }),
    loadReportForCompletedPaymentButformSubmissionPending: ({ filterValues }) => dispatch.distributorReport.loadReportForCompletedPaymentButformSubmissionPending({ filterValues, insuranceType }),
    loadReportForSubmittedApplication: ({ filterValues }) => dispatch.distributorReport.loadReportForSubmittedApplication({ filterValues, insuranceType }),
    loadReportForPendingApplication: ({ filterValues }) => dispatch.distributorReport.loadReportForPendingApplication({ filterValues, insuranceType }),
    loadReportForMailingDetails: ({ filterValues }) => dispatch.distributorReport.loadReportForMailingDetails({ filterValues, insuranceType }),
    loadReportForSmsDetails: ({ filterValues }) => dispatch.distributorReport.loadReportForSmsDetails({ filterValues, insuranceType }),
    loadReportForDropOffStageReport: ({ filterValues }) => dispatch.distributorReport.loadReportForDropOffStageReport({ filterValues, insuranceType }),
    loadReportForConversionReport: ({ filterValues }) => dispatch.distributorReport.loadReportForConversionReport({ filterValues, insuranceType }),

    // selectors
    getReportForTotalLeads: () => select.distributorReport.getReportForTotalLeads(getState(), { insuranceType }),
    getReportForCompletedPaymentButformSubmissionPending: () => select.distributorReport.getReportForCompletedPaymentButformSubmissionPending(getState(), { insuranceType }),
    getReportForSubmittedApplication: () => select.distributorReport.getReportForSubmittedApplication(getState(), { insuranceType }),
    getReportForPendingApplication: () => select.distributorReport.getReportForPendingApplication(getState(), { insuranceType }),
    getReportForMailingDetails: () => select.distributorReport.getReportForMailingDetails(getState(), { insuranceType }),
    getReportForSmsDetails: () => select.distributorReport.getReportForSmsDetails(getState(), { insuranceType }),
    getReportForDropOffStageReport: () => select.distributorReport.getReportForDropOffStageReport(getState(), { insuranceType }),
    getReportForConversionReport: () => select.distributorReport.getReportForConversionReport(getState(), { insuranceType })
  }
}

const DistributorDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DistributorDashboard)
DistributorDashboardContainer.path = 'dashboard'
export default DistributorDashboardContainer
