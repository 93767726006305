import produce, { createDraft, finishDraft, isDraft } from 'immer'
import { getQuotesByInstitutionId, getQuoteByProductId, getFeaturesForInsuranceType, getAllProductDataByInsuranceType, getProductDataById } from '../../services/insuranceService'
import { Engine } from 'json-rules-engine'

import {
  flattenProducts,
  configureForDisplay,
  convertProductToProductOptions,
  insertQuoteIntoProducts,
  chosenProductOptionRules,
  checkIfProductValidByPptOption,
  checkIfProductValidByPptOptionGeneral,
  checkIfProductValidByPptOptionHealth,
  checkIfProductValidByPptOptionAnnuity
} from './helpers/productHelper'
import { isNotDefined, isNotEmpty, isObject, expandRules, has, findIndex, uniq, isArray } from 'utils'
import { populateDefaults, validateEnquiry, populateMaxMinDates } from './helpers/enquiryHelper'
import { transform } from 'lodash'
import { isEqual } from 'lodash/fp'
import { getResetValueForKey } from './plainapi/clientApi'

/**
   * This function splits the state array into slices (first lever keys) and return those
   * split keys (are also object, basically models) grouped in an object
   *
   * @param {*} rootState
   * @param {*} insuranceType
   * @param {*} postfixForKey
   * @returns object
   */
const reduceRootState = (rootState, insuranceType, postfixForKey) => {
  return Object.keys(rootState).reduce((obj, key) => {
    const keyString = `${key}${typeof postfixForKey === 'string' ? postfixForKey : ''}`
    if (Object.prototype.hasOwnProperty.call(rootState[key], insuranceType)) {
      obj[keyString] = rootState[key][insuranceType]
      return obj
    } else {
      obj[keyString] = rootState[key]
      return obj
    }
  }, {})
}

/**
   * Calls reduceRootState function
   *
   * @param {*} rootState
   * @param {*} insuranceType
   * @returns object
   */
const reduceRootStateDraft = (rootState, insuranceType) => reduceRootState(rootState, insuranceType, 'Draft')

/**
   * Calls reduceRootState function
   *
   * @param {*} rootState
   * @param {*} insuranceType
   * @returns object
   */
const reduceRootStateUpdated = (rootState, insuranceType) => reduceRootState(rootState, insuranceType, 'Updated')

/**
   * Calls isDraft function.
   * isDraft function belongs to immer library. Refer to documentation of immer for more details
   *
   * @param {*} draft
   * @returns object
   */
const isDraftState = (draft) => {
  try {
    return isDraft(draft)
  } catch (err) {
    return false
  }
}

/**
   * Calls finsihDraft function. This one converts the draft into immutable object.
   * Finish draft function belongs to a library called immer. Check it's documentation for
   * more details.
   *
   * @param {*} draft
   * @returns object
   */
const checkAndFinishDraft = (draft) => {
  if (isDraftState(draft)) {
    const updated = finishDraft(draft)
    return updated
  } else {
    return draft
  }
}
/**
 * all effects to be wrapped inside effectsHandler with params: dispatch, effectImpl
 * caution!!! - initialRootState should never be modified! only use for external api calls, since we cannot pass draft object
 *
 * @param {object} dispatch
 * @param {object} effectImpl: effect implementation function which recieves following arguments:
 * dispatch, payload, draftRootState, initialRootState
 * @returns void
 */
const effectsHandler = (dispatch, effectImpl, currentModel) => async (payload, rootState) => {
  try {
    const draftRootState = createDraft(rootState)
    const currentStateModifier = async (implementationFunc) => {
      try {
        if (!isNotDefined(currentModel)) {
          const currentStateDraft = createDraft(rootState[currentModel])
          await implementationFunc(currentStateDraft)
          if (isDraftState(currentStateDraft)) {
            // finishDraft(currentStateDraft)
          }
        }
      } catch (err) {
        console.log(err)
        throw err
      }
    }
    await effectImpl(dispatch, payload, draftRootState, currentStateModifier)
    if (isDraftState(draftRootState)) {
      // finishDraft(draftRootState)
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}
/**
 * Gets product quotes from database, merges with the existing product data
 * Structure of data will be {
 *  [
 *  "insurerId": "productInsurerId"
 * "GST": ""
 * ]
 * }
 * @param {string} insuranceType: Type of insurance
 * @param {string} typeOfQuote: Type of quote
 * @param {string} institutionId: institutionId for which quotes have to be obtained
 * @param {Object} { insuranceEnquiry, insuranceFilters, insuranceConfigure }
 * @returns flattenedProducts with quotes
 */
const getQuoteForInstitutionProducts = async (insuranceType, typeOfQuote, institutionId, { insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft }) => {
  const filterConfig = insuranceConfigureDraft.productConfig.filterConfig
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  const { products, features, payouts, featureContent } = insuranceProductsDraft
  const { formData, productData } = insuranceEnquiryDraft
  try {
    const data = await getQuotesByInstitutionId(insuranceEnquiryDraft, institutionId, insuranceType, typeOfQuote)
    // FIXME: Even though the productEnquiry is same as insuranceEnquiry in root state,
    // There can be cases where the productEnquiry is inconsistent with rootState.insuranceEnquiry
    // merge our product with the premium data.
    // Also duplicate whole life limited to whole life regular
    const productsWithPreium = []
    data.forEach(premiums => {
      const populatedProducts = insertQuoteIntoProducts(
        products[institutionId],
        premiums,
        formData,
        productData,
        features,
        payouts,
        featureContent
      )
      populatedProducts.forEach(product => {
        if (!product.notEligible) {
          productsWithPreium.push(product)
        }
      })
    })
    const flattenedProducts = flattenProducts(productsWithPreium, formData, productData, insuranceFiltersDraft, filterConfig, paymentOptions)
    for (let r = 0; r < flattenedProducts.length; r++) {
      try {
        flattenedProducts[r] = await configureForDisplay(
          flattenedProducts[r],
          formData,
          productData,
          paymentOptions
        )
      } catch (err) {
        // CODE: configureDisplayError
        console.log(err)
        throw err
      }
    }

    return flattenedProducts
  } catch (err) {
    console.log(err)
    throw err
  }
}

/**
   * This function gets features for insurance type from an API then creates an object containing
   * all the features, payouts and featureContent
   *
   * @param {*} { insuranceType, distributorId }
   * @returns
   */
const loadFeaturesForInsuranceType = async ({ insuranceType, distributorId }) => {
  const { featureCategories, featureContent } = await getFeaturesForInsuranceType({ insuranceType, distributorId })
  const { features, payouts } = featureCategories.reduce((obj, feature) => {
    if (feature.payoutOption) {
      obj.payouts.push(feature)
    } else {
      obj.features.push(feature)
    }
    return obj
  }, { payouts: [], features: [] })
  return {
    features, payouts, featureContent
  }
}

/**
   * This function gets all products for insurance type from an API then modifies the response
   * array by changing the structure from product structure to product option structure by calling
   * convertProductToProductOptions function
   *
   * @param {*} { insuranceType, distributorId }
   * @returns array
   */
const loadProductForInsuranceType = async ({ insuranceType, distributorId }) => {
  try {
    const products = await getAllProductDataByInsuranceType({
      insuranceType,
      distributorId
    })
    return products.reduce((prodArray, product) => {
      prodArray = prodArray.concat(convertProductToProductOptions(product))
      return prodArray
    }, [])
  } catch (e) {
    console.log(e)
    throw e
  }
}

/**
   * This function gets all products for insurance type from an API then modifies the response
   * array by changing the structure from product structure to product option structure by calling
   * convertProductToProductOptions function
   *
   * @param {*} { insuranceType, distributorId, productInsurerId }
   * @returns array
   */
const loadProductById = async ({ insuranceType, distributorId, productInsurerId }) => {
  try {
    const product = await getProductDataById({
      insuranceType,
      distributorId,
      productInsurerId
    })
    return [product].reduce((prodArray, product) => {
      prodArray = prodArray.concat(convertProductToProductOptions(product))
      return prodArray
    }, [])
  } catch (e) {
    console.log(e)
    throw e
  }
}

/**
 * Get validated flattened products (i.e. product options for only one product).
 * This does not return product for display. Configure for display to be called after this.
 * This function fetches quotes from server, and validates it for all pptOptions
 * @param {string} insuranceType
 * @param {string} typeOfQuote
 * @param {string} institutionId
 * @param {string} insurerId
 * @param {object} { insuranceEnquiry, insuranceFilters, insuranceConfigure }
 * @returns
 */
const getValidatedQuoteForProduct = async (insuranceType, typeOfQuote, institutionId, insurerId, { insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft }, productOptionInsurerId) => {
  const { formData, productData } = insuranceEnquiryDraft
  const { products, features, payouts, featureContent } = insuranceProductsDraft
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  const filterConfig = insuranceConfigureDraft.productConfig.filterConfig
  let flattenedProducts = []
  try {
    let premiums = await getQuoteByProductId(insurerId, insuranceEnquiryDraft, insuranceType, typeOfQuote, institutionId)
    const { modals, GST, riderModals } = premiums
    if (productOptionInsurerId) {
      premiums = {
        modals,
        riderModals,
        insurerId: premiums.insurerId,
        GST,
        [productOptionInsurerId]: premiums[productOptionInsurerId],
        payoutModals: premiums.payoutModals
      }
    }
    const productsWithPremium = insertQuoteIntoProducts(
      products[institutionId],
      premiums,
      formData,
      productData,
      features,
      payouts,
      featureContent
    )
    flattenedProducts = flattenProducts(productsWithPremium, formData, productData, insuranceFiltersDraft, filterConfig, paymentOptions)
  } catch (err) {
    console.log(err)
    throw err
  }
  return flattenedProducts
}
/**
 * Get for only one product for display.
 * @param {string} insuranceType
 * @param {string} typeOfQuote
 * @param {string} institutionId
 * @param {string} insurerId
 * @param {object} { insuranceEnquiry, insuranceFilters, insuranceConfigure }
 * @returns
 */
const getQuoteForProduct = async (insuranceType, typeOfQuote, institutionId, insurerId, { insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft }, productOptionInsurerId) => {
  const { formData, productData, chosenProduct } = insuranceEnquiryDraft
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  let flattenedProducts = []

  try {
    flattenedProducts = await getValidatedQuoteForProduct(insuranceType, typeOfQuote, institutionId, insurerId, { insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft }, productOptionInsurerId)
    // in case of booking or when we have a chosen product, load only that product
    const checkIfProductValidByPptOptionFunc = {
      term: checkIfProductValidByPptOption,
      car: checkIfProductValidByPptOptionGeneral,
      bike: checkIfProductValidByPptOptionGeneral,
      annuity: checkIfProductValidByPptOptionAnnuity,
      health: checkIfProductValidByPptOptionHealth
    }
    if (isNotEmpty(chosenProduct)) {
      flattenedProducts = flattenedProducts.filter((fp) => {
        // if (fp.productOption.productOptionId !== chosenProduct.productOptionInsurerId) {
        //   return false
        // }
        const checkIfProductValidByPptOptionFuncCall = checkIfProductValidByPptOptionFunc[insuranceType]
        return checkIfProductValidByPptOptionFuncCall(fp, formData, productData, productData.paymentOption, paymentOptions)
      })
    }
    for (let r = 0; r < flattenedProducts.length; r++) {
      try {
        flattenedProducts[r] = await configureForDisplay(
          flattenedProducts[r],
          formData,
          productData,
          paymentOptions
        )
      } catch (err) {
        // CODE: configureDisplayError
        console.log(err)
        throw err
      }
    }
  } catch (err) {
    console.log(err)
    throw err
  }
  return flattenedProducts
}

/**
 * Get for only one product for display, which is valid for the currently selected ppt option.
 * @param {string} insuranceType
 * @param {string} typeOfQuote
 * @param {string} institutionId
 * @param {string} insurerId
 * @param {object} { insuranceEnquiry, insuranceFilters, insuranceConfigure }
 * @returns
 */
const getQuoteForProductValidatedByPptOption = async (insuranceType, typeOfQuote, institutionId, insurerId, { insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft }, productOptionInsurerId) => {
  const { formData, productData } = insuranceEnquiryDraft
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  let flattenedProducts = []

  try {
    flattenedProducts = await getValidatedQuoteForProduct(insuranceType, typeOfQuote, institutionId, insurerId, { insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft }, productOptionInsurerId)
    // now validate against chosen ppt option
    const checkIfProductValidByPptOptionFunc = {
      term: checkIfProductValidByPptOption,
      car: checkIfProductValidByPptOptionGeneral,
      bike: checkIfProductValidByPptOptionGeneral,
      annuity: checkIfProductValidByPptOptionAnnuity,
      health: checkIfProductValidByPptOptionHealth
    }
    const checkIfProductValidByPptOptionFuncCall = checkIfProductValidByPptOptionFunc[formData.insuranceType]
    // const checkIfProductValidByPptOptionFunc = formData.insuranceType === 'term' ? checkIfProductValidByPptOption : checkIfProductValidByPptOptionGeneral

    flattenedProducts = flattenedProducts.filter(fp => checkIfProductValidByPptOptionFuncCall(fp, formData, productData, productData.paymentOption, paymentOptions))

    for (let r = 0; r < flattenedProducts.length; r++) {
      try {
        flattenedProducts[r] = await configureForDisplay(
          flattenedProducts[r],
          formData,
          productData,
          paymentOptions
        )
      } catch (err) {
        // CODE: configureDisplayError
        console.log(err)
        throw err
      }
    }
  } catch (err) {
    console.log(err)
    throw err
  }
  return flattenedProducts
}

/**
   * Returns event types based of the rules. This is computed using the rules engine
   *
   * @param {*} rules
   * @param {*} factData
   * @returns
   */
const processRefreshRules = async (rules, factData) => {
  const options = {
    allowUndefinedFacts: true
  }
  const engine = new Engine(expandRules(rules), options)
  try {
    const events = await engine.run(factData)
    // Eventually we will get an event if its server run
    return (events.length > 0) ? events[0].type : 'client'
  } catch (err) {
    console.log(err.message)
    return undefined
  }
}

/**
   * Modifies the filter object. If the value of filter data exists in recentFilters then it's removed
   * recentFilters
   *
   * @param {*} currentFilter
   * @param {*} filter
   * @returns
   */
const modifyFilters = (currentFilter, filter) => {
  Object.keys(filter.filterData).forEach(ky => {
    const values = filter.filterData[ky].filter(v => !isNotDefined(v))
    if (isNotEmpty(values)) {
      currentFilter.filterData[ky] = values
    } else {
      delete filter.filterData[ky]
      const index = filter.recentFilters.indexOf(ky)
      if (index > -1) {
        filter.recentFilters = filter.recentFilters.splice(index, 1)
      }
    }
  })
  filter.recentFilters = Object.assign([], uniq(filter.recentFilters))
  return filter
}

function difference (object, base) {
  function changes (object, base) {
    return transform(object, function (result, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] = (isObject(value) && isObject(base[key])) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

function removeOldFormData (insuranceEnquiryDraft, diffFormData) {
  for (const key in diffFormData) {
    delete insuranceEnquiryDraft.formData[key]
  }
}

const resetProductData = (productData, formData, productDataFragment) => {
  const { insuranceType, policyOption, customerType, policyType } = formData
  const newProductData = { ...productData }
  if (!isNotDefined(productData)) {
    let cpaYear
    Object.keys(productData).forEach(key => {
      if (typeof productData[key] === 'object') {
        let keyData = getResetValueForKey(key, insuranceType)
        if (insuranceType === 'car') { // to make sure personal accident owner driver is defaulted to 3, when user changes policyType, since it is a mandatory cover
          cpaYear = 3
        } else if (insuranceType === 'bike') {
          cpaYear = 5
        }
        if (policyOption === 'renewExistingPolicy' && formData.businessOption === 'rollover') {
          cpaYear = 1
        }
        if (key === '126') {
          if (!isNotDefined(productDataFragment['126']) && productDataFragment['126'].cpaYear !== cpaYear) {
            keyData = productDataFragment[key]
          } else if (customerType === 'corporate' || policyType === 'standalone') {
            keyData = { cpaYear, include: false }
          } else {
            keyData = { cpaYear, include: true }
          }
        }
        Object.assign(newProductData, { [key]: keyData })
      }
    })
  }
  return newProductData
}

const updateEnquiryForAuto = (enquiry, formData, productDataFragment) => {
  const { policyOption, insuranceType, policyType } = formData
  let policyTerm
  if (policyOption === 'buyNewPolicy') {
    if (insuranceType === 'car') {
      policyTerm = 3
    } else if (insuranceType === 'bike') {
      policyTerm = 5
    }
    if (['comprehensive', 'standalone'].includes(policyType)) {
      policyTerm = 1
    }
  } else if (policyOption === 'renewExistingPolicy') {
    policyTerm = 1
    if (!isNotDefined(formData.policyTerm) && formData.policyTerm !== policyTerm) {
      policyTerm = formData.policyTerm
    }
  }
  let newProductData = enquiry.productData
  if (policyOption === 'buyNewPolicy' || (!isNotDefined(formData.businessOption) && formData.businessOption === 'rollover')) {
    newProductData = resetProductData(enquiry.productData, formData, productDataFragment)
  }
  enquiry.formData.policyTerm = policyTerm
  enquiry.productData = newProductData
}

const updateEnquiryForHealth = (enquiry, formData, productDataFragment) => {
  let newProductData = enquiry.productData
  if (formData.policyOption === 'buyNewPolicy') {
    newProductData = resetProductData(enquiry.productData, formData, productDataFragment)
  }
  enquiry.productData = newProductData
}

const updateHealthProductData = (productDataFragment, insuranceEnquiryDraft, key) => {
  const newProductData = {}
  const isFamilyFloaterMultiRider = insuranceEnquiryDraft.formData.policyType === 'familyFloater' && ['pa', 'icmi', 'ci'].includes(key)
  if (insuranceEnquiryDraft.formData.policyType === 'multiIndividual' || isFamilyFloaterMultiRider) {
    Object.keys(productDataFragment[key]).filter(ky => typeof productDataFragment[key][ky] === 'object' || ky === 'include').forEach(ky => {
      newProductData[key] = {
        ...newProductData[key],
        [ky]: productDataFragment[key][ky]
      }
    })
    insuranceEnquiryDraft.productData[key] = newProductData[key]
  } else {
    Object.keys(productDataFragment[key]).filter(ky => typeof productDataFragment[key][ky] !== 'object' || ky === 'include').forEach(ky => {
      newProductData[key] = {
        ...newProductData[key],
        [ky]: productDataFragment[key][ky]
      }
    })
    insuranceEnquiryDraft.productData[key] = newProductData[key]
  }
}
/**
   * This function creates the enquiry object, filter object and validation errors.
   * If productDataFragment is not empty then enquiry will be appended with productData and riderSumAssured
   *
   * If formData exists then it's also added to enquiry
   *
   * @param {*} { formData, insuranceType, productDataFragment }
   * @param {*} { insuranceEnquiryDraft, insuranceConfigureDraft, insuranceFiltersDraft }
   * @returns object
   */
const prepareEnquiry = ({ formData, filterData, insuranceType, productDataFragment }, { insuranceEnquiryDraft, insuranceConfigureDraft, insuranceFiltersDraft }) => {
  let enquirySchema = {}
  let enquiryValidations = {}
  if (isObject(productDataFragment) && isNotEmpty(productDataFragment)) {
    Object.keys(productDataFragment).forEach(key => {
      if (has(insuranceEnquiryDraft.productData, key) && isObject(productDataFragment[key])) {
        if (insuranceType === 'health') {
          updateHealthProductData(productDataFragment, insuranceEnquiryDraft, key)
        } else {
          insuranceEnquiryDraft.productData[key] = Object.assign({}, insuranceEnquiryDraft.productData[key], productDataFragment[key])
        }
      } else {
        insuranceEnquiryDraft.productData[key] = productDataFragment[key]
      }
      if (productDataFragment[key] && !isNotDefined(insuranceEnquiryDraft.riderSumAssured) && !isNotDefined(productDataFragment[key]?.riderSumAssured)) {
        const riderSumAssuredId = findIndex(insuranceEnquiryDraft.riderSumAssured, (r) => r.riderId === productDataFragment.riderId)
        if (riderSumAssuredId === -1) {
          insuranceEnquiryDraft.riderSumAssured.push({
            riderId: productDataFragment.riderId,
            value: productDataFragment[key].riderSumAssured
          })
        } else {
          insuranceEnquiryDraft.riderSumAssured[riderSumAssuredId].value = productDataFragment[key].riderSumAssured
        }
      }
    })
  }
  if (isObject(formData)) {
    if (isNotEmpty(insuranceEnquiryDraft.formData) && isNotEmpty(formData)) {
      const differenceObj = difference(insuranceEnquiryDraft.formData, formData)
      removeOldFormData(insuranceEnquiryDraft, differenceObj)
      insuranceEnquiryDraft.formData = Object.assign({}, formData)
      if (['car', 'bike'].includes(insuranceType)) {
        updateEnquiryForAuto(insuranceEnquiryDraft, formData, productDataFragment)
      }
      if (insuranceType === 'health') {
        updateEnquiryForHealth(insuranceEnquiryDraft, formData, productDataFragment)
      }
    } else {
      insuranceEnquiryDraft.formData = Object.assign({}, insuranceEnquiryDraft.formData, formData)
    }
    // Updating the insuranceEnquiry with changes in filterFormData ( form data changes by filter if any)
    populateDefaults(insuranceEnquiryDraft)
    // begin for writing insuranceEnquiry to Db.
    const rootEnquiry = Object.assign({}, insuranceEnquiryDraft)
    rootEnquiry[insuranceType] = insuranceEnquiryDraft
    enquirySchema = produce(insuranceConfigureDraft.productConfig.validation.schema, (draftSchema) => {
      const age = insuranceConfigureDraft.productConfig.validation?.age
      return insuranceType === 'term' ? populateMaxMinDates(draftSchema, age.min, age.max) : draftSchema
    })
    enquiryValidations = insuranceType === 'term' ? validateEnquiry(enquirySchema, insuranceEnquiryDraft.formData) : { valid: true }
  } else {
    // FIXME:  THis seems to be a hack. No formData means no validations. Force validation to be true
    enquiryValidations.valid = true
  }
  // end - for writing insuranceEnquiry to db
  insuranceEnquiryDraft.filterData = Object.assign({}, insuranceEnquiryDraft.filterData, filterData)
  return { insuranceEnquiryDraft, insuranceFiltersDraft, enquiryValidations }
}

/**
   * Creates insuranceFilter object with filterData and recent filters
   *
   * @param {*} { insuranceType, filterChoices }
   * @param {*} { insuranceEnquiry, insuranceFilters }
   * @returns object
   */
const prepareFilters = ({ filterChoices }, { insuranceEnquiry, insuranceFilters }) => {
  const filterProductData = {} // Contains productData changes because of insuranceFilters
  if (isObject(filterChoices) && isNotEmpty(filterChoices)) {
    const { paymentFrequency, paymentOption } = insuranceEnquiry.productData
    const { filterData } = insuranceFilters
    if (filterChoices.key === 'paymentOption') {
      filterChoices.value.forEach(element => {
        // If the payment frequrency is Single, reset it
        if (!isNotDefined(element)) {
          if ((!isNotDefined(filterData.paymentFrequency) && filterData.paymentFrequency[0].value === 'S') || paymentFrequency === 'S') {
            // Reset it to default
            insuranceFilters.filterData.paymentFrequency = [{ value: 'M' }]
          }
        }
      })
    } else if (filterChoices.key === 'paymentFrequency') {
      filterChoices.value.forEach(element => {
        if (!isNotDefined(element)) {
          if (element.value === 'S') {
            if (!isNotDefined(filterData.paymentOption)) {
              // remove all payment option insuranceFilters
              delete filterData.paymentOption
            }
            // set the payment Option to single
            insuranceFilters.filterData.paymentOption = [{ value: 'SP' }]
          } else if (paymentOption === 'SP') {
            // Set to default payment option which is regular
            insuranceFilters.filterData.paymentOption = [{ value: 'RP' }]
          }
        }
      })
    } else if (filterChoices.key === 'payoutFrequency') {
      filterChoices.value.forEach(element => {
        if (!isNotDefined(element)) {
          if (element.value === 'S') {
            if (!isNotDefined(filterData.paymentOption)) {
              // remove all payment option insuranceFilters
              delete filterData.paymentOption
            }
            // set the payment Option to single
            insuranceFilters.filterData.paymentOption = [{ value: 'SP' }]
          }
          // else if (paymentOption === 'SP') {
          //   // Set to default payment option which is regular
          //   insuranceFilters.filterData.paymentOption = [{ value: 'RP' }]
          // }
        }
      })
    }
    insuranceFilters.filterData[filterChoices.key] = filterChoices.value
    insuranceFilters.recentFilters.push(filterChoices.key)
    Object.keys(insuranceFilters.filterData).forEach((key) => {
      if (key === 'paymentFrequency' || key === 'paymentOption' || key === 'payoutFrequency') {
        filterProductData[key] = insuranceFilters.filterData[key][0].value
      }
    })
  }
  insuranceEnquiry.productData = Object.assign({}, insuranceEnquiry.productData, filterProductData)
  return { insuranceEnquiry, insuranceFilters }
}

// if there is an existing product of the same option, then replace it with new. Append the rest
/**
   * Modifies mergedArray array by adding products from newProducts selected by checking its existance
   * in merged array which is created by existing products
   *
   * @param {*} existingProducts
   * @param {*} newProducts
   * @param {*} productInsurerId
   * @returns
   */
const appendProductsToList = (existingProducts, newProducts, productInsurerId) => {
  let mergedArray = existingProducts
  if (isArray(newProducts)) {
    if (!isNotDefined(productInsurerId)) {
      // remove all products with productInsurerId and append new products
      mergedArray = mergedArray
        .filter((product) => product.insurerId !== productInsurerId)
        .concat(newProducts)
    } else {
      // incase we need to preserve order.
      newProducts.forEach(product => {
        const existingIndex = findIndex(mergedArray, (e) => e.productOption.insurerId === product.productOption.insurerId)
        if (existingIndex > -1) {
          mergedArray[existingIndex] = product
        } else {
          mergedArray.push(product)
        }
      })
    }
  }
  return mergedArray
}

/**
   * displayOrder is modified with productLoaded, normalisedProducts and choice.
   *
   * @param {*} displayOrder
   * @param {*} products
   * @param {*} institutionId
   * @param {*} normalizedProducts
   * @returns
   */
const buildToTemplate = (displayOrder, products, institutionId, normalizedProducts) => {
  // here display order is iterated and additional fields inside items ( eg. productLoaded) is retained from normalized products.
  // rest is reset.
  const draftTemplate = JSON.parse(JSON.stringify(displayOrder)) // is it for cloning ?
  Object.keys(draftTemplate).forEach(key => {
    const displayTemplate = draftTemplate[key]
    displayTemplate.items.forEach((item) => {
      const { choices } = item
      if (isNotEmpty(normalizedProducts)) {
        // here only keys at the root level in each item, are retained.
        const itemIndexInNormalizedProducts = findIndex(normalizedProducts[key].items, element => {
          const draftItemChoices = item.choices
          const draftelementchoices = element.choices
          const itemfound = draftItemChoices.find(choice => {
            return draftelementchoices.find(eleChoice => {
              return choice.productOptionInsurerId === eleChoice.productOptionInsurerId
            })
          })
          return !isNotDefined(itemfound)
        })

        Object.assign(item, normalizedProducts[key].items[itemIndexInNormalizedProducts])
      }
      if (institutionId === item.institutionId) {
        item.productLoaded = true
      }
      // reverse the order of choices.
      // FIXME: Are er maintaining the order? if yes, then ask why are we storing reverse order.
      const orderOfChoices = choices.reverse()
      orderOfChoices.forEach(choice => {
        const { insurerId, productOptionInsurerId } = choice
        // hack. At some places productOptionId and productOptionInsurerId are used interchangebly
        choice.productOptionId = productOptionInsurerId
        const product = products.find(pr => {
          return pr.insurerId === insurerId && pr.productOption.insurerId === productOptionInsurerId
        }
        )
        if (!isNotDefined(product)) {
          if (item.choices[0].productOptionInsurerId !== productOptionInsurerId) {
            Object.assign(item.choices[0], choice)
          }
          Object.assign(item, choice)
        }
      })
    })
  })
  return draftTemplate
}
/**
   * Modifies the chosenProduct with actions, productOptionInsurerId and nextProductOptionInsurerId
   *
   * @param {*} chosenProduct
   * @param {*} rules
   * @returns
   */
const runChosenProductRules = async (chosenProduct, rules) => {
  const response = await chosenProductOptionRules({ chosenProduct }, rules)
  const { nextAction, chosenProductOption, askForRiders } = response
  chosenProduct.actions = {
    nextAction,
    askForRiders
  }
  if (!isNotDefined(chosenProductOption)) {
    const { productOptionInsurerId, nextProductOptionInsurerId } = chosenProductOption
    chosenProduct.productOptionInsurerId = productOptionInsurerId
    chosenProduct.nextProductOptionInsurerId = nextProductOptionInsurerId
  }
}

const getMatchingPaymentOption = (product, currentPaymentOption, paymentOptions) => {
  try {
    let matchingPaymentOption
    const limitedPaymentOptions = []
    matchingPaymentOption = Object.keys(product.productOption.pptOptions).find((pptoption) => {
      return pptoption === currentPaymentOption
    })
    if (isNotEmpty(matchingPaymentOption)) {
      return matchingPaymentOption
    }
    // to get list of limited payment option numbers
    Object.keys(product.productOption.pptOptions).forEach((pptoption) => {
      if (pptoption.slice(0, 1) === 'L') {
        limitedPaymentOptions.push(Number(pptoption.slice(1, pptoption.length)))
      }
    })

    matchingPaymentOption = paymentOptions.find((paymentOption) => {
      // eslint-disable-next-line
      return Object.keys(product.productOption.pptOptions).find((pptoption) => {
        if (paymentOption === pptoption.slice(0, 2)) {
          return paymentOption
        } else if (pptoption.slice(0, 1) === 'L' && paymentOption === 'LP') {
          return paymentOption
        }
      })
    })
    if (matchingPaymentOption === 'RP' || matchingPaymentOption === 'SP') {
      return matchingPaymentOption
    } else if (matchingPaymentOption === 'LP') {
      return 'L' + Math.max(...limitedPaymentOptions)
    }
  } catch (err) {
    console.log(err)
    throw err
  }
}

export {
  getMatchingPaymentOption,
  getQuoteForInstitutionProducts,
  modifyFilters,
  prepareEnquiry,
  processRefreshRules,
  getQuoteForProduct,
  appendProductsToList,
  prepareFilters,
  loadProductForInsuranceType,
  loadProductById,
  loadFeaturesForInsuranceType,
  buildToTemplate,
  reduceRootState,
  runChosenProductRules,
  effectsHandler,
  reduceRootStateDraft,
  reduceRootStateUpdated,
  getQuoteForProductValidatedByPptOption,
  checkAndFinishDraft,
  resetProductData
}
