import { Card, Statistic, List } from 'semantic-ui-react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { Header, Subtitle2, Caption } from 'components/Header'
import 'react-loading-skeleton/dist/skeleton.css'

const CardViewSkeletonMobile = (_props) => {
  return (
    <Card
      className='cardViewMobile'
    >
      <div className='basicDetails'>
        <SkeletonTheme highlightColor='#dedfe0'>
          <div
            className='insuredwithlogo'
          >
            <div style={{ marginBottom: '0.35em' }}>
              <Skeleton height={20} width={48} />
            </div>
            <Header.Content color='primaryLight'>
              <Skeleton width={120} />
              <Header.Subheader>
                <Skeleton width={120} />
              </Header.Subheader>
            </Header.Content>
          </div>
          <div className='mobileGrid flexContainer'>
            <div className='leftColumn'>
              <Subtitle2>
                <Skeleton width={60} />
              </Subtitle2>
              <List>
                <List.Item>
                  {[1, 2].map((idx) => {
                    return (
                      <Header.Content key={`mobile-skeleton-${idx}`} className='riderContent' style={{ maxWidth: '75%' }}>
                        <Caption className='riderCaption' style={{ maxWidth: 'unset' }}>
                          <Skeleton circle width={20} />
                          &nbsp;
                          <Skeleton width={200} />
                        </Caption>
                      </Header.Content>
                    )
                  })}
                </List.Item>
              </List>
            </div>
            <div className='rightColumn flexContainer'>

              <Statistic textAlign='right' color='green' className='alignLeft'>
                <Header sub color='black'>
                  <Skeleton width={64} />
                </Header>
                <Statistic.Value>
                  <Skeleton width={32} />
                </Statistic.Value>
              </Statistic>
              {/* {ComponentMap.Review} */}
            </div>
          </div>
        </SkeletonTheme>
      </div>
    </Card>
  )
}
export default CardViewSkeletonMobile
