import { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import { Subtitle2 } from 'components/Header'
import FormDropdown from 'components/FormDropdown'
import { isNotEmpty } from 'utils/lib/basic'
import './Dropdown.scss'

const RopPercent = (props) => {
  const { id, className, disabled, product: { ropRange }, productData: { rop }, handleChange } = props

  const options = useMemo(() => {
    if (isNotEmpty(ropRange)) {
      return ropRange.map(rop => {
        return {
          key: rop,
          text: `${rop}`,
          value: rop
        }
      })
    }
  },
  // eslint-disable-next-line
  [rop.percent])

  if (!isNotEmpty(ropRange)) {
    return null
  }
  function onClick (event) {
    event.preventDefault()
  }
  return (
    <>
      <Subtitle2>Choose your return of purchase price</Subtitle2>
      <Form.Field>
        <FormDropdown
          id={`${id}Dropdown`}
          className={className}
          icon='dropdown'
          options={options}
          value={rop.percent}
          disabled={disabled}
          fluid
          onChange={(_e, data) => handleChange({ rop: parseFloat(data.value) }, id)}
          onClick={(event) => onClick(event)}
        />
      </Form.Field>
    </>
  )
}
export default RopPercent
