import JsonSchemaEasyForm from 'components/jsonSchemaForms/JsonSchemaEasyForm'
import { Button } from 'semantic-ui-react'
import { isNotDefined } from 'utils'
import { getPaymentOptionSchema } from 'smartcovr_api/src/insuranceApi'
const PaymentOption = (props) => {
  const { onPaymentChange, device, formData, productData, pptOptions, ...formProps } = props
  const paymentOptionSchema = getPaymentOptionSchema(pptOptions, formData, productData) || {}
  const { schema, uiSchema, paymentFormData } = paymentOptionSchema
  if (isNotDefined(paymentOptionSchema)) {
    return (<div />)
  }
  return (
    <div className={`outer ${device} last`}>
      <div className='inner'>
        <JsonSchemaEasyForm
          {...formProps}
          schema={schema}
          uiSchema={uiSchema}
          formData={paymentFormData}
          onChange={onPaymentChange}
          rules={[]}
        >
          <div style={{ display: 'none' }}>
            <Button
              id='save'
              type='submit'
              content='PROCEED'
              primary
            />
          </div>
        </JsonSchemaEasyForm>
      </div>
    </div>
  )
}
export default PaymentOption
