import { getService } from './index'

const trackClaims = async ({ query }) => {
  let response
  try {
    const { trackClaims } = getService()
    response = await trackClaims.find({ query })
  } catch (err) {
    if (err) {
      throw err
    }
  }
  return response
}
const getClaimsSchema = async ({ query }) => {
  let schema
  try {
    const { getSchema } = getService()
    schema = await getSchema.find({ query })
  } catch (err) {
    if (err) throw err
  }
  return schema
}

const postIntimation = async (data) => {
  // const queryParams = { insuranceType: 'term', action: 'intimation' }
  let response
  const { enquiryData, queryParams } = data
  try {
    const { postClaimIntimation } = getService()
    response = await postClaimIntimation.create(enquiryData, { query: queryParams })
    return response
  } catch (err) {
    if (err) throw err
  }
}
const postRegistration = async (data) => {
  let response
  const { enquiryData, queryParams } = data
  try {
    const { postClaimRegistration } = getService()
    response = await postClaimRegistration.create(enquiryData, { query: queryParams })
    return response
  } catch (err) {
    if (err) throw err
  }
}
const getClaimHoldings = async (query) => {
  let response
  try {
    const { getHoldings } = getService()
    response = await getHoldings.find({ query })
    return response
  } catch (err) {
    if (err) throw err
  }
}

const generateOtp = async (data) => {
  let response
  const { enquiryData, queryParams } = data
  try {
    const { otpGeneration } = getService()
    response = await otpGeneration.create(enquiryData, { query: queryParams })
    return response
  } catch (err) {
    if (err) throw err
  }
}
const validateOtp = async (data) => {
  let response
  const { enquiryData, queryParams } = data
  try {
    const { otpValidation } = getService()
    response = await otpValidation.update(null, enquiryData, { query: queryParams })
    return response
  } catch (err) {
    if (err) throw err
  }
}
export { trackClaims, getClaimsSchema, postIntimation, postRegistration, getClaimHoldings, generateOtp, validateOtp }
