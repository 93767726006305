import { Message, Image } from 'semantic-ui-react'
import { Body2 } from 'components/Header'
import { buildCommonImagePath } from 'components/buildImagePath'
const InfoMessageBlock = (props) => {
  const { hidden, messages, heading, image, textColor } = props
  return (
    <Message hidden={hidden}>
      <Message.Header style={{ color: textColor }}>{heading} </Message.Header>
      {messages.map((message, index) => {
        return (
          <Body2 style={{ color: textColor }} key={index}>
            <Image className='size16' src={buildCommonImagePath(image)} />
            {message}
          </Body2>
        )
      })}
    </Message>
  )
}
export default InfoMessageBlock
