import { PureComponent } from 'react'
import { Body2 } from 'components/Header'
import AvailableAddons from './AvailableAddons'
class AvailableAddonsMobile extends PureComponent { // make stateless
  render () {
    const { view, product, contentType, loading, Loader, device, insuranceType, ...riderProps } = this.props
    const { features, productOptionId } = product
    const availableAddons = features.filter(feature => feature.pricing === 'available')
      .map((feature, index) => {
        return (
          <AvailableAddons
            {...riderProps}
            key={feature.schemaKey + index}
            feature={feature}
            view={view}
            contentType={contentType}
            productOptionId={productOptionId}
            loading={loading}
            Loader={Loader}
            device={device}
            insuranceType={insuranceType}
            product={product}
          />
        )
      })
    return (
      <>
        <Body2 className='tabDesc'>
          Following addons are available for the product
        </Body2>
        {availableAddons}
      </>
    )
  }
}
export default AvailableAddonsMobile
