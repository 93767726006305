import { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { DataAccessContext } from 'components/context'
import { JsonSchemaOnboardForm, JsonSchemaValidation, JsonSchemaHelper } from 'components/jsonSchemaForms'
import { GenPincodeField, MakeModelField, RegistrationNumberWithLocationField, PreviousPolicyStatusWithDateField } from 'components/jsonSchemaForms/fields'
import { isNotDefined, isNotEmpty } from 'utils/lib/basic'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'
import { getRenewPolicy } from 'smartcovr_api/src/insuranceApi'
import { omit } from 'lodash'
import { useAction } from 'components/customHooks'
import './autoOnboarding.scss'

const AutoOnboardingForm = (props) => {
  const {
    device,
    configure,
    insuranceType,
    onSubmit: parentOnSubmit,
    redirectToLanding,
    ...restProps
  } = useContext(DataAccessContext)
  const {
    onboard: {
      onboardSchema: { schema, uiSchema },
      insuranceSteps
    }
  } = configure

  const [formData, setFormData] = useState(restProps.formData)
  const [initialData, setInitialData] = useState({})
  const [currentStep, setCurrentStep] = useState(-1)
  const [selectedPath, setSelectedPath] = useState(undefined)
  const [backHistory, setBackHisytory] = useState([])
  const [childStep, setChildStep] = useState(0)
  const customFocus = useRef(null)
  const setHooksError = useAction(dispatch => dispatch.errors.setHooksError)

  let currentStepDependency = {}
  useEffect(() => {
    const { mobile, customerType, policyType, idv, policyTerm } = restProps.formData
    setInitialData({ mobile, customerType, insuranceType, policyType, idv, policyTerm })
    // convert registration details to object
    let updatedFormData = { ...formData }
    if (formData.registrationLocation && formData.vehicleRegistrationNumber) {
      const vehicleDetails = { registrationLocation: formData.registrationLocation, vehicleRegistrationNumber: formData.vehicleRegistrationNumber }
      updatedFormData = { ...updatedFormData, vehicleDetails }
    }
    if (formData.previousPolicyStatus && formData.previousPolicyEndDate) {
      const previousPolicyDetails = { previousPolicyStatus: formData.previousPolicyStatus, previousPolicyEndDate: formData.previousPolicyEndDate }
      updatedFormData = { ...updatedFormData, previousPolicyDetails }
    }
    setFormData(updatedFormData)
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    if (customFocus.current) {
      customFocus.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [formData])

  useLayoutEffect(() => {
    // Setting the min-width to fit content for the cardWidget, else it will be very slim
    if (document.querySelectorAll('.cardWidget').length > 0) {
      customFocus.current.style = 'min-width: fit-content'
    } else {
      customFocus.current.style = ''
    }
  })

  const getSchemaForCurrentStep = () => {
    if (currentStep > -1) {
      let dependencies = {}
      let properties = {}
      const title = insuranceSteps[selectedPath]?.steps[childStep]?.title ?? ''
      const fields = insuranceSteps[selectedPath]?.steps[childStep]?.fields ?? []
      fields.forEach((field) => {
        const property = JsonSchemaHelper.getNestedArrayOfObject(schema, field)
        const fieldSchema = property.find((pr) => Object.keys(pr)[0] === field)
        properties = Object.assign(properties, fieldSchema)
        property.forEach((pr) => {
          if ((Object.prototype.hasOwnProperty.call(pr[field], 'anyOf') || Object.prototype.hasOwnProperty.call(pr[field], 'oneOf')) && Object.keys(pr)[0] === field) {
            dependencies = Object.assign(dependencies, pr)
          }
        })
      })
      currentStepDependency = { ...dependencies }
      return {
        type: 'object',
        title,
        properties,
        dependencies,
        required: Object.keys(properties).map((key) => key)
      }
    }
    const defaultSchema = schema.properties[insuranceType]
    return defaultSchema
  }

  const getUISchemaForCurrentStep = () => {
    let extractedFields = []
    if (isNotEmpty(currentStepDependency)) {
      const property = JsonSchemaHelper.getNestedArrayOfObject(currentStepDependency, 'properties')
      extractedFields = JsonSchemaHelper.extractKeys(property, 'properties')
    }
    if (currentStep > -1) {
      let stepFields = insuranceSteps[selectedPath]?.steps[childStep]?.fields ?? []
      stepFields = [...stepFields, ...extractedFields]
      return stepFields?.reduce((uiSc, field) => {
        uiSc[field] = uiSchema[field]
        uiSc['ui:rootFieldId'] = `root__${insuranceType}_${selectedPath}_${childStep}`
        uiSc[field]['ui:idSchema'] = field
        return uiSc
      }, {})
    }
    const defaultUiSchema = uiSchema[insuranceType]
    return defaultUiSchema
  }

  const saveFormData = (field, value, userFormData) => {
    // Reset Form Data with initial enquiry formData
    if ((formData[field] !== undefined && formData[field] !== value) || (!isNotDefined(formData.businessOption) && formData.businessOption === 'renewal')) {
      setFormData({ ...initialData, policyOption: userFormData.policyOption, [field]: value })
    } else {
      setFormData({ ...formData, ...userFormData })
    }
  }

  const handleSubmit = async ({ formData: userFormData }) => {
    const totalLength = insuranceSteps[selectedPath]?.steps.length
    const newFormData = { ...formData, ...userFormData }
    let omittedFormData = JsonSchemaHelper.omitExtraData(newFormData, currentStepDependency)
    if (childStep === totalLength - 1) {
      const { registrationLocation, vehicleRegistrationNumber } = JsonSchemaHelper.extractRegistrationDetails(omittedFormData)
      if (!isNotDefined(registrationLocation) && !isNotDefined(vehicleRegistrationNumber)) {
        omittedFormData.vehicleRegistrationNumber = vehicleRegistrationNumber
        omittedFormData.registrationLocation = registrationLocation
        delete omittedFormData.vehicleDetails
      }
      const { previousPolicyStatus, previousPolicyEndDate } = JsonSchemaHelper.extractPreviousPolicyDetails(omittedFormData)
      if (!isNotDefined(previousPolicyStatus) && !isNotDefined(previousPolicyEndDate)) {
        omittedFormData.previousPolicyEndDate = previousPolicyEndDate
        omittedFormData.previousPolicyStatus = previousPolicyStatus
        if (!isNotDefined(omittedFormData.previousPolicyType) && omittedFormData.previousPolicyType === 'thirdParty' && omittedFormData.policyType === 'standalone') {
          omittedFormData.previousPolicyEndDate = omittedFormData.thirdPartyPolicyEndDate
        }
        delete omittedFormData.previousPolicyDetails
      }
      // Convert makeModel to JSON string
      const makeModelData = JsonSchemaHelper.stringifyData(omittedFormData.makeModel)
      omittedFormData.makeModel = makeModelData
      if (omittedFormData.businessOption && omittedFormData.businessOption === 'renewal') {
        const enquiry = {
          formData: { ...omit(omittedFormData, ['policyType', 'idv']) },
          productData: restProps.productData
        }
        try {
          const product = await getRenewPolicy({ id: 'renew', enquiry, insuranceType, typeOfQuote: 'listing', institutionId: omittedFormData.insuranceCompany })
          omittedFormData = { ...omittedFormData, ...product.formData, productDataFragment: product.productData }
        } catch (err) {
          setHooksError(err)
          return
        }
      }
      if (omittedFormData.policyType === 'thirdParty') {
        omittedFormData = { ...omittedFormData, idv: 0 }
      }
      parentOnSubmit(omittedFormData)
    } else {
      setChildStep(childStep + 1)
      setFormData(omittedFormData)
    }
  }

  const handleChange = (e) => {
    const isButtonWidget = JsonSchemaHelper.checkButtonWidget(getUISchemaForCurrentStep())
    if (isButtonWidget) {
      const { field, value } = JsonSchemaHelper.extractFieldValue(e.formData, e.key)
      setBackHisytory([...backHistory, value])
      setSelectedPath(value)
      saveFormData(field, value, e.formData)
      setCurrentStep(currentStep + 1)
    }
  }

  const handleBack = (e) => {
    e.preventDefault()
    const newHistory = backHistory
    if (childStep <= 0) {
      setChildStep(0)
      let prevScreen = newHistory.pop()
      if (prevScreen === selectedPath) {
        prevScreen = newHistory.pop() ?? formData.policyOption
      }
      setCurrentStep(currentStep - 1)
      setSelectedPath(prevScreen)
      setBackHisytory(newHistory)
    } else if (backHistory.length === 0) {
      setCurrentStep(-1)
    } else {
      setChildStep(childStep - 1)
    }
  }

  const bottomButtons = () => {
    const isButtonWidget = JsonSchemaHelper.checkButtonWidget(getUISchemaForCurrentStep())
    if (!isButtonWidget) {
      const totalLength = insuranceSteps[selectedPath]?.steps.length
      const isLastStep = childStep === totalLength - 1
      const label = isLastStep ? 'buy now' : 'proceed'
      const id = label.split(' ').join('-') + '-btn'
      return (
        <div className='ms-3 mb-3 mt-4'>
          <Button id={id} secondary content={label} />
          <Button id='bottom-back-btn' onClick={handleBack} basic>
            BACK
          </Button>
        </div>
      )
    } else {
      return <></>
    }
  }

  const topBackButton = () => {
    const isButtonWidget = JsonSchemaHelper.checkButtonWidget(getUISchemaForCurrentStep())
    const totalLength = insuranceSteps[selectedPath]?.steps.length
    const isLastStep = childStep === totalLength - 1
    if ((currentStep > -1 && (isButtonWidget || isLastStep))) {
      return (
        <Button id='top-back-btn' className='d-flex align-items-center' onClick={handleBack} basic>
          <i style={{ fontSize: '20px' }} className='fa fa-angle-left' />
          &nbsp; Back
        </Button>
      )
    } else if (currentStep === -1) {
      return (
        <Button id='top-back-btn' className='d-flex align-items-center' onClick={() => redirectToLanding('/')} basic>
          <i style={{ fontSize: '20px' }} className='fa fa-angle-left' />
          &nbsp; Back
        </Button>
      )
    }
    return null
  }

  const handleModalClose = () => {
    // delete policyType and thirdPartyPolicyEndDate
    const newFormData = { ...formData }
    delete newFormData.policyType
    delete newFormData.thirdPartyPolicyEndDate
    setFormData(newFormData)
  }

  const onValidate = (formData, errors) => {
    const { validateRegistrationDetails, validatePreviousPolicyEndDate, validateMakeModelDetails } = JsonSchemaValidation
    const vehicleRegistrationNumberError = validateRegistrationDetails(formData, 'vehicleRegistrationNumber')
    const registrationLocationError = validateRegistrationDetails(formData, 'registrationLocation')
    const previousPolicyEndDateError = validatePreviousPolicyEndDate(formData, 'previousPolicyDetails')
    const makeModelError = validateMakeModelDetails(formData, 'makeModel', schema.properties?.makeModel)
    if (vehicleRegistrationNumberError) {
      if (errors.vehicleDetails) {
        errors.vehicleDetails.vehicleRegistrationNumber.addError(vehicleRegistrationNumberError)
      } else if (errors.vehicleRegistrationNumber) {
        errors.vehicleRegistrationNumber.addError(vehicleRegistrationNumberError)
      }
    }
    if (registrationLocationError) {
      if (errors.vehicleDetails) {
        errors.vehicleDetails.registrationLocation.addError(registrationLocationError)
      } else if (errors.registrationLocationError) {
        errors.registrationLocation.addError(registrationLocationError)
      }
    }

    if (previousPolicyEndDateError) {
      if (errors.previousPolicyDetails) {
        errors.previousPolicyDetails.previousPolicyEndDate.addError(previousPolicyEndDateError)
      }
    }

    if (makeModelError) {
      if (errors.makeModel) {
        Object.keys(makeModelError).forEach((key) => {
          errors.makeModel[key].addError(makeModelError[key])
        })
      }
    }
    return errors
  }

  const transformErrors = (errors) => {
    if (isNotEmpty(errors)) {
      const newErrors = errors.filter(error => error.name === 'required')
      newErrors.forEach((error) => {
        const currentProperty = error.params?.missingProperty
        let selectedSchema
        if (currentProperty) {
          selectedSchema = JsonSchemaHelper.extractNestedObject(schema, currentProperty, currentProperty)
        }
        let title = 'This'
        if (selectedSchema && selectedSchema[currentProperty]?.title && selectedSchema[currentProperty]?.title.trim().length !== 0) {
          title = selectedSchema[currentProperty].title
        }
        error.message = getCustomErrorMessage(title, error)
      })
      return newErrors
    }
    return errors
  }

  return (
    <div className='main4' style={{ backgroundColor: props.bgColor }}>
      <div className='form-container'>
        <div ref={customFocus} className='bg_onboard_form_card_auto rounded'>
          {topBackButton()}
          <JsonSchemaOnboardForm
            className='onboardingForm'
            schema={getSchemaForCurrentStep()}
            uiSchema={getUISchemaForCurrentStep()}
            onSubmit={handleSubmit}
            onChange={handleChange}
            formData={formData}
            fields={{ GenPincodeField, MakeModelField, RegistrationNumberWithLocationField, PreviousPolicyStatusWithDateField }}
            rules={[]}
            liveValidate={false}
            validate={onValidate}
            transformErrors={transformErrors}
            formContext={{
              device,
              insuranceType,
              modalOpen: true, // for thirdPartyPolicyEndDate
              handleModalClose, // for thirdPartyPolicyEndDate
              vehiclePurchaseDate: formData?.vehiclePurchaseDate,
              policyType: restProps.formData?.policyType,
              policyTypeDetails: configure.onboard?.policyTypeDetails,
              policyOption: formData?.policyOption,
              previousPolicyType: formData?.previousPolicyType,
              previousPolicyDetails: formData?.previousPolicyDetails,
              thirdPartyPolicyEndDate: formData?.thirdPartyPolicyEndDate,
              rtos: configure.onboard.onboardMetaData?.rto,
              makes: configure.onboard.onboardMetaData?.makeModel,
              fuelTypes: configure.onboard.onboardMetaData?.fuelType,
              variants: configure.onboard.onboardMetaData?.variant,
              insurerNames: configure.onboard.onboardMetaData?.insurerNames
            }}
          >
            {bottomButtons()}
          </JsonSchemaOnboardForm>
        </div>
      </div>
    </div>
  )
}
export default AutoOnboardingForm
