import { useState, useEffect } from 'react'
import { Form, Icon } from 'semantic-ui-react'
import { Caption, Subtitle2 } from 'components/Header'
import StyledInput from './StyledInput'
import { isNotNumber, scaleBackAmount, formatAmount, getErrorsById, isNotEmpty } from 'utils'

const getPurchasePriceOrAnnuityPayout = (sum) => {
  if (isNotNumber(sum) || (sum === 0) || sum === null) {
    return {
      amount: 0,
      formattedAmount: 0,
      amtDisplay: 0,
      amtLabelDisplay: 'Lakhs',
      amtLabel: 'l'
    }
  } else {
    return formatAmount(sum)
  }
}

const PurchasePriceAnnuityPayoutFilter = (props) => {
  const { formType, label, handleChange, updateChange, configure, errors, className, purchasePrice, annuityPayout, disabled, productData: { apMin }, errHandler } = props
  const purchasePriceDisplay = getPurchasePriceOrAnnuityPayout(purchasePrice)
  const annuityPayoutDisplay = getPurchasePriceOrAnnuityPayout(annuityPayout)
  const purchasePriceDisplayValue = purchasePriceDisplay && purchasePriceDisplay.amtDisplay
  const purchasePriceDisplayLabel = purchasePriceDisplay && purchasePriceDisplay.amtLabel
  const annuityPayoutDisplayValue = annuityPayoutDisplay && annuityPayoutDisplay.amtDisplay
  const annuityPayoutDisplayLabel = annuityPayoutDisplay && annuityPayoutDisplay.amtLabel
  const options = configure.common.amountLabelOptions
  const [filterId, setfilterId] = useState()
  const [disableFieldAnnuity, setDisableFieldAnnuity] = useState(false)
  const [disableFieldPurchase, setDisableFieldPurchase] = useState(false)
  let validationMessage
  let showError
  let validationErrors

  const onPurchaseChange = (amountDisplay, amtLabel, id, handleChange) => {
    const trimmedAmount = parseFloat(amountDisplay.toString().substring(0, 4))
    let amount = scaleBackAmount(trimmedAmount, amtLabel)
    if (amount === 0) {
      amount = undefined
    }
    setfilterId(id)
    handleChange({ purchasePrice: parseFloat(amount), annuityPayout: 0 }, id, false)
  }

  const onAnnuityChange = (amountDisplay, amtLabel, id, handleChange) => {
    const trimmedAmount = parseFloat(amountDisplay.toString().substring(0, 4))
    let amount = scaleBackAmount(trimmedAmount, amtLabel)
    if (amount === 0) {
      amount = undefined
    }
    setfilterId(id)
    handleChange({ annuityPayout: parseFloat(amount), purchasePrice: 0 }, id, false)
  }

  useEffect(() => {
    errHandler(validationMessage)
  }, [errHandler, validationMessage])

  // for enabling the field if the other field value is 0
  useEffect(() => {
    if (purchasePriceDisplayValue === 0 && annuityPayoutDisplayValue === 0) {
      setDisableFieldAnnuity(false)
      setDisableFieldPurchase(false)
    } else if (Number(purchasePriceDisplayValue) > 0) {
      setDisableFieldAnnuity(true)
      setDisableFieldPurchase(false)
    } else if (Number(annuityPayoutDisplayValue) > 0) {
      setDisableFieldAnnuity(false)
      setDisableFieldPurchase(true)
    }
  }, [purchasePriceDisplayValue, annuityPayoutDisplayValue])
  if (formType === 'filter') {
    if (isNotEmpty(errors)) {
      if (errors[0].dataPath === '.purchasePrice') {
        validationErrors = getErrorsById(errors, 'purchasePrice')
      }
      if (errors[0].dataPath === '.annuityPayout') {
        validationErrors = getErrorsById(errors, 'annuityPayout')
      }
      if (isNotEmpty(validationErrors)) {
        showError = true
        validationMessage = 'Enter Purchase Price or Annuity Payout'
      }
    }
    if (annuityPayout > 0) {
      if (annuityPayout < apMin) {
        showError = true
        validationMessage = `Annuity Payout  should be minimum ${apMin}`
      }
    }
    const updateFormDataHandler = () => {
      if (annuityPayoutDisplayValue || purchasePriceDisplayValue) {
        if (validationMessage && showError === true) return
        updateChange(filterId)
      }
    }
    const styleSelector = className ? `styledcontainer ${className}` : 'styledcontainer'
    return (
      <>
        <div className='flexContainer align-items-center gap-2'>
          <div className='sumAssured inputElement filter'>
            <Subtitle2 color='primaryLight'>Purchase Price</Subtitle2>
            <Form.Field>
              <StyledInput
                handleChange={(value) => onPurchaseChange(value, purchasePriceDisplayLabel, 'purchasePrice', handleChange)}
                id='purchasePrice'
                value={purchasePriceDisplayValue || ''}
                label={label}
                incLabel={purchasePriceDisplayLabel}
                options={options.slice(0, 2)}
                handleLabelChange={(amtLabel) => onPurchaseChange(purchasePriceDisplayValue, amtLabel, 'purchasePrice', handleChange)}
                dropdown='true'
                styleSelector={styleSelector}
                includeDecimal
                disabled={disableFieldPurchase}
              />
            </Form.Field>

          </div>
          <div className='px-2 mt-4'>OR</div>
          <div className='sumAssured inputElement filter'>
            <Subtitle2 color='primaryLight'>Annual Annuity Payout</Subtitle2>
            <Form.Field>
              <StyledInput
                handleChange={(value) => onAnnuityChange(value, annuityPayoutDisplayLabel, 'annuityPayout', handleChange)}
                id='annuityPayout'
                value={annuityPayoutDisplayValue || ''}
                label={label}
                incLabel={annuityPayoutDisplayLabel}
                options={options}
                handleLabelChange={(amtLabel) => onAnnuityChange(annuityPayoutDisplayValue, amtLabel, 'annuityPayout', handleChange)}
                dropdown='true'
                styleSelector={styleSelector}
                includeDecimal
                disabled={disableFieldAnnuity}
              />
            </Form.Field>
          </div>
          <Icon
            id='purchasePrice-annuityPayout-filter'
            disabled={disabled}
            className='updateIcon mt-4 secondary'
            circular
            name='check'
            onClick={updateFormDataHandler}
          />
        </div>
        {
          (showError && validationMessage) && (
            <Caption
              id='purchasePriceErrorLabel'
              color='red'
              align='center'
            >
              {validationMessage}
            </Caption>)
        }
      </>
    )
  }
  return null
}

export default PurchasePriceAnnuityPayoutFilter
