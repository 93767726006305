
import { useState, useEffect } from 'react'
import { Search, Input } from 'semantic-ui-react'
import { Caption, WrapContent } from 'components/Header'
import { isNotDefined, isNotEmpty } from 'utils'

import LabelField from '../components/LabelField'
import debounce from 'lodash/debounce'
import CustomFieldWrapper from '../components/CustomFieldWrapper'
import PropTypes from 'prop-types'
import { getPincodeData } from 'smartcovr_api/src/insuranceApi'
const PincodeField = (props) => {
  const [results, setResults] = useState([])
  const [showNoResults, setShowNoResults] = useState(false)
  // initialize state with the initial pincode
  const { schema, formData, uiSchema, errorSchema } = props
  const [value, setValue] = useState(formData && formData.pincode)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await getPincodeData(value)
        if (isNotEmpty(data)) {
          setResults(data.map((item, ix) => {
            return {
              title: value,
              description: JSON.stringify({ city: item.city, state: item.state }),
              data: item,
              id: item.pincode,
              key: `${item.pincode}_${ix}`
            }
          }))
        } else {
          setResults([])
          setShowNoResults(true)
        }
      } catch (err) {
        // show error message
        setResults([])
        setError(true)
        setShowNoResults(true)
      }
    }
    // call api only if formdata doesn't have pincode and complete pincode is filled
    if (!(formData && formData.pincode) && value && value.length === 6) {
      fetchResults()
    }
  }, [formData, value])

  function handleSearchChange (e, { value }) {
    if (value.length <= 6) {
      setValue(value.toString())
      setResults([])
      setShowNoResults(false)
      setError(false)
      props.onChange({})
    }
  }
  const placeholder = uiSchema['ui:placeholder']

  function handleResultSelect (_e, { result }) {
    setValue(result.data.pincode.toString())
    const formData = {
      pincode: result.data.pincode.toString(),
      city: result.data.cityCode,
      cityDisplay: result.data.city,
      state: result.data.stateCode,
      stateDisplay: result.data.state
    }
    props.onChange(formData)
  }

  function displayFields () {
    return (
      (!isNotDefined(formData.pincode))
        ? (
          <WrapContent>
            <Caption floated='left'>
              <LabelField
                className='control-label secondary'
                title={schema.properties.city.title}
              />
              {formData.cityDisplay || formData.city}
            </Caption>
            <Caption floated='right'>
              <LabelField
                className='control-label secondary'
                title={schema.properties.state.title}
              />
              {formData.stateDisplay || formData.state}
            </Caption>
          </WrapContent>
          )
        : null
    )
  }

  const renderer = ({ title, description }) => {
    let descriptionEl = ''
    if (description) {
      const { city, state } = JSON.parse(description)
      descriptionEl = (
        <div className='description'>
          City: {city} <br />
          State: {state}
        </div>
      )
    }
    return (
      <div className='content'>
        {title && <div className='title'>Pincode: {title}</div>}
        {descriptionEl}
      </div>
    )
  }
  return (
    <CustomFieldWrapper
      schema={schema}
      uiSchema={uiSchema}
      errorSchema={errorSchema}
    >
      <Search
        id='root_pincode'
        as={Input}
        type='number'
        resultRenderer={renderer}
        placeholder={placeholder}
        showNoResults={showNoResults}
        onResultSelect={handleResultSelect}
        onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
        results={results}
        value={value}
        noResultsMessage={error ? 'Oops! Enter pincode again.' : 'Invalid pincode'}
      />
      {(isNotEmpty(formData))
        ? displayFields()
        : null}
    </CustomFieldWrapper>
  )
}

PincodeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  uiSchema: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired
}

export default PincodeField
