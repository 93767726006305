import { PureComponent } from 'react'
import CardView from './CardView'
import { Card } from 'semantic-ui-react'
import CardViewSkeleton from './CardViewSkeleton'
import CardViewSkeletonMobile from './CardViewSkeletonMobile'
class AllProductsDisplay extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      displayTitle: true
    }
  }

  updateHideShowTitle (showTitle) {
    this.setState({
      displayTitle: showTitle
    })
  }

  render () {
    const {
      products,
      expandedProduct,
      loading,
      device,
      insuranceType
    } = this.props
    const SkeletonComponent = (device === 'mobile')
      ? CardViewSkeletonMobile
      : CardViewSkeleton

    return (
      <Card.Group stackable centered className='productCards'>
        {
          products.map((product, index) => {
            if (loading) {
              return <SkeletonComponent key={index} insuranceType={insuranceType} />
            }
            if (expandedProduct === -1 || expandedProduct === product.productOptionId) {
              return (
                <CardView
                  {...this.props}
                  key={`cardview_${index}`}
                  index={index}
                  updateHideShowTitle={this.updateHideShowTitle}
                  productOptionId={product.productOptionId}
                  expandedProduct={expandedProduct}
                />
              )
            } else {
              return null
            }
          })
          }
      </Card.Group>
    )
  }
}

export default AllProductsDisplay
