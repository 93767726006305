import { useInitialDataLoader } from 'components/customHooks'
import Loading from 'components/Loading'
import { FormDropdown } from 'semantic-ui-react'
import { useState } from 'react'
import { capitalize } from 'utils/lib/basic'
import MUIDrawer from '../../../components/MUIDrawer'
import DashboardItemsCommonContainer from './DashboardItemsCommonContainer'
import { dashboardDrawerItemMapper, populateEffects, populateSelectors } from './distributorDashboardHelper'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'

export const DistributorDashboard = (props) => {
  const { loadingConfigure, configure } = useInitialDataLoader(
    props.insuranceType,
    props.enquiryId,
    props.distributorId,
    true
  )
  const {
    loadReportForTotalLeads,
    loadReportForCompletedPaymentButformSubmissionPending,
    loadReportForSubmittedApplication,
    loadReportForPendingApplication,
    loadReportForMailingDetails,
    loadReportForSmsDetails,
    loadReportForDropOffStageReport,
    loadReportForConversionReport,
    insuranceType,
    availableProducts
  } = props

  const {
    getReportForTotalLeads,
    getReportForCompletedPaymentButformSubmissionPending,
    getReportForSubmittedApplication,
    getReportForPendingApplication,
    getReportForMailingDetails,
    getReportForSmsDetails,
    getReportForDropOffStageReport,
    getReportForConversionReport
  } = props

  populateEffects({
    loadReportForTotalLeads,
    loadReportForCompletedPaymentButformSubmissionPending,
    loadReportForSubmittedApplication,
    loadReportForPendingApplication,
    loadReportForMailingDetails,
    loadReportForSmsDetails,
    loadReportForDropOffStageReport,
    loadReportForConversionReport
  }, insuranceType)

  populateSelectors({
    getReportForTotalLeads,
    getReportForCompletedPaymentButformSubmissionPending,
    getReportForSubmittedApplication,
    getReportForPendingApplication,
    getReportForMailingDetails,
    getReportForSmsDetails,
    getReportForDropOffStageReport,
    getReportForConversionReport
  }, insuranceType)

  const onItemChangedListener = (itemKey) => {
    setSelectedItemKey(itemKey)
  }

  const onLogout = () => {
    props.keycloakLogout()
    props.redirectToPath('/')
  }

  const buildInsuranceOptions = () => {
    return availableProducts.map((key) => ({
      key,
      value: key,
      text: `${capitalize(key)} Reports`
    }))
  }
  const headerChildren = () => {
    return (
      <FormDropdown
        id='insuranceType-Dropdown'
        icon='dropdown'
        placeholder={insuranceType}
        options={buildInsuranceOptions()}
        value={insuranceType}
        onChange={(_e, { value }) => {
          setSelectedItemKey('totalLeads')
          props.redirectToPath(`/${value}/dashboard`)
        }}
      />
    )
  }

  const labelExtractor = (item) => {
    const { text } = dashboardDrawerItemMapper[insuranceType][item]
    return text
  }
  const [selectedItemKey, setSelectedItemKey] = useState('totalLeads')
  const theme = createTheme()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {loadingConfigure
          ? <Loading open />
          : (
            <>
              <MUIDrawer
                onLogout={onLogout}
                onItemChangedListener={onItemChangedListener}
                selectedItem={selectedItemKey}
                device={props.device}
                headerChildren={headerChildren()}
                listMapper={Object.keys(dashboardDrawerItemMapper[insuranceType])}
                labelExtractor={labelExtractor}
              />
              <div id='distributorDashboardContainer'>
                <DashboardItemsCommonContainer
                  selectedItemKey={selectedItemKey}
                  loadingConfigure={loadingConfigure}
                  configure={configure}
                  {...props}
                />
              </div>
            </>
            )}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
