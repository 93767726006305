import { rupeeFormatter, isNotDefined } from 'utils'
import { Statistic, Icon } from 'semantic-ui-react'
import DataLoader from 'components/DataLoader'

const StatisticAmount = (props) => {
  const { className, textAlign, label, data, loading, id, labelColor } = props
  if (!isNotDefined(loading)) {
    return (
      <Statistic color='green' textAlign={textAlign} className={className}>
        <Statistic.Label color={labelColor}>{label}</Statistic.Label>
        <DataLoader
          loading={loading}
        >
          <Statistic.Value id={id}>
            <Icon size='small' name='icon-rupees' />
            {rupeeFormatter(Math.round(data))}
          </Statistic.Value>
        </DataLoader>
      </Statistic>
    )
  }
  return (
    <Statistic color='green' textAlign={textAlign} className={className}>
      <Statistic.Label color={labelColor}>{label}</Statistic.Label>
      <Statistic.Value id={id}>
        <Icon size='small' name='icon-rupees' />
        {rupeeFormatter(Math.round(data))}
      </Statistic.Value>
    </Statistic>
  )
}
export default StatisticAmount
