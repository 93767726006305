import { useState } from 'react'
import { Form, Modal } from 'semantic-ui-react'
import AnnuityInsuranceSingleForm from 'components/annuity/AnnuityInsuranceSingleForm'
import { adopt } from 'react-adopt'
import { UpdateContext } from 'components/context'
import { isNaN } from 'utils/lib/basic'
import produce from 'immer'
const componentStyle = {
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
    gap: '0.5rem'

  }
}
function AnnuitantType (props) {
  const [showModal, setShowModal] = useState(false)
  const [selectedOption, setSelectedOption] = useState(props.formData.applicationType)

  // based on the selected option, updating the form data(insuranceType, investFor)
  const onChangeHandler = (e) => {
    if (isNaN(props.formData.purchasePrice) && isNaN(props.formData.annuityPayout)) {
      return
    }
    if (isNaN(props.formData.purchasePrice) || isNaN(props.formData.annuityPayout)) {
      return
    }
    setSelectedOption(e.target.value)
    if (e.target.value === 'jl') {
      setShowModal(true)
    } else if (e.target.value === 'sl') {
      const { sAge, sGender, sDob } = props.formData
      if (sAge && sGender && sDob) {
        const newFormData = produce(props.formData, draft => {
          delete draft.sAge
          delete draft.sGender
          delete draft.sDob
        })
        props.setFormData({
          ...newFormData,
          applicationType: e.target.value,
          investFor: 'self'
        })
      }
      setShowModal(false)
    }
  }
  const updateFormData = (newFormData) => {
    setShowModal(false)
    props.setFormData({
      ...props.formData,
      ...newFormData,
      applicationType: 'jl',
      investFor: 'joint'
    })
  }

  const onBack = () => {
    setShowModal(false)
    if (props.formData.sAge || props.formData.sGender || props.formData.sDob) {
      delete props.formData.sAge
      delete props.formData.sGender
      delete props.formData.sDob
      props.setFormData({
        ...props.formData,
        applicationType: 'sl',
        investFor: 'self'
      })
    }
    setSelectedOption('sl')
  }
  return (
    <>
      <Form.Group>
        <div style={componentStyle.wrapper}>
          <input type='radio' name='applicationType' value='sl' checked={selectedOption === 'sl'} onChange={(e) => onChangeHandler(e)} id='singleLife' />
          <label>
            Single Life
          </label>
          <input type='radio' name='applicationType' value='jl' checked={selectedOption === 'jl'} onChange={(e) => onChangeHandler(e)} id='jointLife' />
          <label>
            Joint Life
          </label>
        </div>
      </Form.Group>
      <Modal
        className='editUserDetailsModal'
        open={showModal}
      >
        <AnnuityInsuranceSingleForm
          id='listing_SecondaryDetails'
          {...props}
          listing
          onBack={onBack}
          setFormData={updateFormData}
        />
      </Modal>
    </>
  )
}

const WithContext = adopt({
  update: <UpdateContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ update }) => {
        return (
          <AnnuitantType
            {...props}
            setFormData={update.setOnboardFormData}
          />
        )
      }
    }
  </WithContext>
)
export default WrapperComponent
