import { Grid } from 'semantic-ui-react'
import ErrorSegment from './ErrorSegment'
const style = {
  segmentGroupOuter: {
    border: 0,
    borderRadius: 0,
    background: '#ffffff'
  },
  segmentGroup: {
    border: 0,
    borderRadius: 0
  },
  fieldRow: {
    paddingRight: 0
  }
}
const FieldRow = (props) => {
  const { className, children, errors, showerror, errorId } = props
  return (
    <Grid.Column
      textAlign='left'
      stretched
      style={style.fieldRow}
      className={className}
    >
      {children}
      {(showerror) ? <ErrorSegment id={errorId}>{errors}</ErrorSegment> : null}
    </Grid.Column>
  )
}
export default FieldRow
