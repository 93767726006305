import Tooltip from 'rc-tooltip'
import { Image } from 'semantic-ui-react'
import 'rc-tooltip/assets/bootstrap_white.css'
import { buildCommonImagePath } from 'components/buildImagePath'

const styles = {
  overlayStyle: {
    width: '16em',
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)',
    fontSize: '0.874em'
  },
  image: {
    cursor: 'pointer',
    display: 'unset',
    marginLeft: '0.5em',
    width: 12,
    height: 12
  }
}
const TooltipComponent = (props) => {
  const { disable, children, customIconStyle, contentStyle, id, placement, trigger, icon } = props
  const tooltipIcon = icon ?? buildCommonImagePath('more-info-icon.png')

  return (
    <Tooltip
      id={`${id}-tooltip`}
      disable={disable}
      placement={placement ?? 'right'}
      overlay={children}
      trigger={trigger ?? ['hover']}
      overlayStyle={{ ...styles.overlayStyle, ...contentStyle }}
      arrowContent={<div className='rc-tooltip-arrow-inner' />}
    >
      <Image
        id={`${id}-tooltip-icon`}
        style={{ ...styles.image, ...customIconStyle }}
        src={tooltipIcon}
      />
    </Tooltip>
  )
}
export default TooltipComponent
