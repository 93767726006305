import { feathersApp } from '../feathers'
import { getStore } from '../store'
const getService = () => {
  const env = process.env.NODE_ENV
  const store = getStore()
  const { configure } = store.getState()
  const app = feathersApp(configure)
  const { apiVersion } = configure
  const orderService = (env === 'production') ? app.service(`paymentflow-order/${apiVersion}`) : app.service('paymentflow/order')
  const paymentService = (env === 'production') ? app.service(`paymentflow-payment/${apiVersion}`) : app.service('paymentflow/payment')
  return { orderService, paymentService }
}
const createOrder = async ({ enquiry, insuranceType, totalPremiumWithGst }) => {
  const { orderService } = getService()
  try {
    const newOrder = await orderService.create({ ...enquiry, totalPremiumWithGst }, { query: { insuranceType } })
    return newOrder
  } catch (err) {
    console.log('err', err)
    throw err
  }
}

const capturePayment = async ({ data, insuranceType, institutionId }) => {
  const { paymentService } = getService()
  try {
    const newPayment = await paymentService.create(data, { query: { event: 'capture', insuranceType, institutionId } })
    return newPayment
  } catch (err) {
    console.log('err', err)
    throw err
  }
}

export {
  createOrder,
  capturePayment
}
