import { formatAmount } from 'utils/lib/calc'
import { rupeeFormatter } from 'utils'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { Icon } from 'semantic-ui-react'
import { Body1, Body2, Caption } from 'components/Header'

import TData from '../TData'

const ApplicationDetailsContent = ({ application }) => {
  const { applicationNumber, sumAssured, coverTerm, insuranceType } = application
  const { idv, policyTerm, premiumWithGst } = application.AutoApplication || application
  let { amtDisplay, amtLabelDisplay } = formatAmount(sumAssured ?? idv)
  const { productName, productOptionName } = application.productDetails
  const { totalPremiumWithGst, paymentFrequency } = application
  const { purchasePrice, annuityPayout } = insuranceType === 'annuity' && application.AnnuityApplication
  const { payoutFrequency } = insuranceType === 'annuity' && application.productData
  const productDescription = (<DisplayHtmlString htmlContent={application.productDetails.productDescription} />)
  const status = application.ApplicationStatuses[0] !== undefined ? application.ApplicationStatuses[0].SmartcovrStatus.smartcovrDisplayText : 'No Status'
  if (amtDisplay === 0 || amtDisplay === null) {
    amtDisplay = 'N/A'
    amtLabelDisplay = ''
  } else {
    amtDisplay = amtDisplay.toFixed(2)
  }
  const paymentFrequencyType = (frequency) => {
    switch (frequency) {
      case 'A':
        return 'Annual'
      case 'H':
        return 'Half Yearly'
      case 'Q':
        return 'Quaterly'
      case 'M':
        return 'Monthly'
      case 'S':
        return 'Base'
      default:
        return ''
    }
  }
  const amountWithLabelInitials = function amountWithLabels (amount) {
    const crore = 10000000
    const lakh = 100000
    const tho = 1000

    let label
    if (payoutFrequency === 'A' || payoutFrequency === 'S') {
      label = ' / Year'
    } else if (payoutFrequency === 'H') {
      label = ' / Half Year'
    } else if (payoutFrequency === 'Q') {
      label = ' / Quarter'
    } else if (payoutFrequency === 'M') {
      label = ' / Month'
    }

    if (amount > crore) {
      return {
        amount: (amount / crore).toFixed(2),
        label: ' C' + label
      }
    } else if (amount >= lakh) {
      return {
        amount: (amount / lakh).toFixed(2),
        label: ' L' + label
      }
    } else {
      return {
        amount: (amount / tho).toFixed(0),
        label: ' K' + label
      }
    }
  }
  const amountWithLabels = function amountWithLabels (amount) {
    const crore = 10000000
    const lakh = 100000
    const tho = 1000
    if (amount > crore) {
      return {
        amount: (amount / crore).toFixed(2),
        label: ' C'
      }
    } else if (amount >= lakh) {
      return {
        amount: (amount / lakh).toFixed(2),
        label: ' L'
      }
    } else {
      return {
        amount: (amount / tho).toFixed(0),
        label: ' K'
      }
    }
  }

  const amountWithIcon = (...args) => (
    <Body1 color='green' className='amountWithIcon'>
      <div>
        <Icon size='small' name='rupee' />
        {`${args[0]} ${args[1]}`}
      </div>
    </Body1>
  )
  const amountWithIconForAnnuity = (args) => (
    <Body1 color='green' className='amountWithIcon'>
      <div>
        <Icon size='small' name='rupee' />
        {`${args.amount} ${args.label}`}
      </div>
    </Body1>
  )

  const data = [
    // {
    //   TitleName: 'Product Name',
    //   TitleValue: productName,
    //   showhr: true
    // },
    {
      TitleName: 'Application Number',
      TitleValue: applicationNumber,
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'annuity', 'health']
    },
    {
      TitleName: ['term', 'health'].includes(insuranceType) ? 'Sum Assured' : 'IDV',
      TitleValue: amountWithIcon(amtDisplay, amtLabelDisplay),
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'health']
    },
    {
      TitleName: 'Annuity Payout',
      TitleValue: amountWithIconForAnnuity(amountWithLabelInitials(annuityPayout)),
      showhr: true,
      insuranceTypes: ['annuity']
    },
    {
      TitleName: 'Cover Upto',
      // eslint-disable-next-line
      TitleValue: coverTerm === 1 ? `${coverTerm ?? policyTerm} Year` : `${coverTerm ?? policyTerm} Years`,
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'health']
    },
    {
      TitleName: `${paymentFrequencyType(paymentFrequency)} Premium`,
      TitleValue: totalPremiumWithGst ?? premiumWithGst != null ? amountWithIcon(rupeeFormatter(totalPremiumWithGst ?? premiumWithGst), '') : 'Quote finalization pending',
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'health']
    },
    {
      TitleName: 'Purchase Price',
      TitleValue: amountWithIconForAnnuity(amountWithLabels(purchasePrice)),
      showhr: true,
      insuranceTypes: ['annuity']
    },
    {
      TitleName: 'Status',
      TitleValue: status,
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'annuity', 'health']
    }
    // {
    //   TitleName: 'Product Description',
    //   TitleValue: productDescription,
    //   showhr: true
    // }
  ]

  const renderData = () => {
    return data.map((content, i) => {
      if (content.insuranceTypes.includes(insuranceType)) {
        return (
          <div className='columnData' key={i}>
            <TData Title={content.TitleName} Value={content.TitleValue} showhr={content.showhr} />
          </div>
        )
      }
      return null
    })
  }

  return (
    <div className='row'>
      <Body1>
        {productName}
      </Body1>
      <div>
        <Body2>
          {productOptionName}
        </Body2>
        <Caption className='caption'>
          {productDescription}
        </Caption>
      </div>
      {renderData()}
    </div>

  )
}

export default ApplicationDetailsContent
