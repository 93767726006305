import { connect } from 'react-redux'

import AllUpcomingPremiums from './AllUpcomingPremiums'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = ownProps.store.select(models => ({
    allUpcomingPremiums: models.policies.getAllUpcomingPremiums,
    selectedApplicationNumber: models.applications.getSelectedApplicationNumber,
    selectedApplicationNumberForPolicy: models.policies.getSelectedPolicy
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  const { selectedApplicationNumber, selectedApplicationNumberForPolicy } = selectorData
  const { getApplicationById } = select.applications
  const { getPolicyById } = select.policies
  const selectedApplication = getApplicationById(state, {
    insuranceType,
    applicationNumber: selectedApplicationNumber
  })
  const selectedPolicyData = getPolicyById(state, {
    insuranceType,
    id: selectedApplicationNumberForPolicy
  })
  return {
    ...ownProps,
    ...selectorData,
    loadingPolicies: state.loading.effects.policies.loadPolicies,
    selectedApplication,
    selectedPolicyData
  }
}

const AllUpcomingPremiumsContainer = connect(mapStateToProps)(AllUpcomingPremiums)
export default AllUpcomingPremiumsContainer
