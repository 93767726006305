import React, { PureComponent } from 'react'
import ReviewIncludedAddons from './ReviewIncludedAddons'
import ListingIncludedAddons from './ListingIncludedAddons'
import produce from 'immer'
class IncludedAddons extends PureComponent {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onError = this.onError.bind(this)
    this.updatePopUp = this.updatePopUp.bind(this)
    this.premiumRef = React.createRef()
    this.state = {
      errors: [],
      reviewEdit: false
    }
  }

  onError (errors) {
    this.setState({ errors })
  }

  onCancel (riderId) {
    this.props.closeRiderEdit(riderId)
  }

  async onSubmit (formData) {
    const { rider: { riderId, riderSchemaKey }, feature, product } = this.props
    const productCharacteristicId = feature.productCharacteristics[0].productCharacteristicId
    this.props.onRiderChange({
      change: {
        [riderSchemaKey]: formData
      },
      riderId,
      productCharacteristicId,
      insurerId: product.productInsurerId,
      institutionId: product.institutionId
    })
  }

  updatePopUp (_data) {
    const { rider: { riderId }, addRider } = this.props
    addRider(riderId)
  }

  render () {
    const {
      editRiderId,
      contentType,
      feature,
      rider,
      productOptionId,
      device,
      childKey,
      onRiderChange,
      formData,
      insuranceType
    } = this.props
    const { shortName, description, points, heading, descriptionPiped, riderBrochureUrl } = rider
    let riderFormData = rider.riderFormData
    const isPoReview = contentType === 'poreview'
    const isEdit = (rider.riderId === editRiderId)
    const { schema, uiSchema, rules } = rider.riderSchema
    const hasSumAssured = riderFormData.riderSumAssured && riderFormData.riderSumAssured > 0
    // If not rider sum assured, reset 0 values to undefined so that json form does not crib
    if (!hasSumAssured) {
      riderFormData = produce(riderFormData, draft => {
        Object.keys(draft).forEach(ky => {
          if (draft[ky] === 0) {
            draft[ky] = undefined
          }
        })
      })
    }
    if (contentType === 'review') {
      const reviewRiderProps = {
        rider,
        schema,
        uiSchema,
        formData,
        riderFormData,
        feature,
        rules,
        productOptionId,
        checked: true,
        heading,
        description,
        shortName,
        points,
        descriptionPiped,
        riderBrochureUrl,
        childKey,
        device,
        isEdit,
        onRiderChange,
        onSubmit: this.onSubmit,
        onCancel: this.onCancel,
        insuranceType,
        updatePopUp: this.updatePopUp
      }
      return (
        <ReviewIncludedAddons {...reviewRiderProps} />
      )
    } else if (isPoReview) {
      return null
    } else {
      const listingRiderProps = Object.assign({}, {
        hasSumAssured,
        rider,
        schema,
        uiSchema,
        formData,
        riderFormData,
        feature,
        rules,
        productOptionId,
        checked: true,
        heading,
        description,
        shortName,
        points,
        childKey,
        device,
        isEdit,
        onRiderChange,
        onSubmit: this.onSubmit,
        onCancel: this.onCancel,
        insuranceType,
        updatePopUp: this.updatePopUp
      })
      return (
        <ListingIncludedAddons
          {...listingRiderProps}
        />
      )
    }
  }
}
export default IncludedAddons
