import NameField from './NameField'
import produce from 'immer'
const CompleteNameField = (props) => {
  const onChange = (data) => {
    props.onChange(data)
    // following code is to remove verifiedDetails, so that
    const globalFormData = props.getGlobalFormData()
    if (!globalFormData.verifiedFields || !globalFormData.verifiedFields.includes('panDetails')) {
      if (globalFormData.panDetails && globalFormData.panDetails.verifiedDetails) {
        props.onFormDataChange(produce(globalFormData, (draft) => {
          delete draft.panDetails
          draft.completeName = data
        }), {})
      }
    }
  }
  return (
    <NameField
      {...props}
      panDetails={props.getGlobalFormData().panDetails || null}
      onChange={onChange}
    />
  )
}
export default CompleteNameField
