import Radio from 'components/jsonSchemaForms/widgets/RadioWidget'
import { H6 } from 'components/Header'

function IsAlreadyUserCheck (props) {
  const { text, onChange, radioValue } = props
  return (
    <div>
      <H6 color='primaryLight'>{text}</H6>
      <Radio
        onChange={(e) => {
          onChange(e)
        }}
        options={{
          enumOptions: [
            { label: 'Yes', value: true },
            { label: 'No', value: false }
          ]
        }}
        value={radioValue}
        required
        disabled={false}
        readonly={false}
        id='radioButtonforExisting'
        autoFocus={false}
        dStyle='radioButtonsDiv' // for styling the div to show display items in flex
        checked={false}
      />

    </div>
  )
}

export default IsAlreadyUserCheck
