
import { PureComponent } from 'react'
import { Statistic } from 'semantic-ui-react'
import { isNotEmpty } from 'utils'
import DataLoader from 'components/DataLoader'
import { LoadingContext } from 'components/context'
import { isNotDefined } from 'utils/lib/basic'

const getPolicyTerm = (policyTermArr, policyTerm) => {
  const label = 'Policy Tenure'
  let value

  if (Array.isArray(policyTermArr)) {
    const policyTermObj = policyTermArr.find((term) => Object.values(term)[0] === policyTerm)
    const policyTermValue = Object.values(policyTermObj).pop()
    value = Object.keys(policyTermObj).reduce((arr, key) => arr.concat(`${policyTermObj[key]}${key.toUpperCase()}`), []).join(' + ')
    value += (policyTermValue > 0 && policyTermValue < 2) ? ' Year' : ' Years'
  } else {
    value = (!isNaN(policyTermArr) && Number(policyTermArr) > 1) || isNaN(policyTermArr) ? `${policyTermArr} Years` : `${policyTermArr} Year`
  }
  return { label, value }
}

class CoverPeriodTillAge extends PureComponent {
  render () {
    const { formData, productData, product: { wholeLife, insurerId, eligibleCoverUpto, policyTerm } } = this.props

    const loading = this.context
    let coverTermLoading = false
    if (isNotEmpty(loading)) {
      if (loading.formData.status && ['coverTerm', 'sumAssured'].indexOf(loading.formData.key) > -1) {
        coverTermLoading = true
      }
    }
    let finalCoverUpto
    let coverUpto
    let coverUptoLabel
    if (!isNotDefined(productData?.coverTerm) && !isNotDefined(formData?.age)) {
      const { age } = formData
      const { coverTerm } = productData
      finalCoverUpto = coverTerm + age
      if (eligibleCoverUpto !== undefined && isNotEmpty(eligibleCoverUpto)) {
        const minCoverTerm = eligibleCoverUpto[0]
        const maxCoverterm = eligibleCoverUpto[eligibleCoverUpto.length - 1]
        if (finalCoverUpto <= minCoverTerm) {
          finalCoverUpto = minCoverTerm
        } else if (finalCoverUpto >= maxCoverterm) {
          finalCoverUpto = maxCoverterm
        } else if (eligibleCoverUpto.includes(finalCoverUpto)) {
          finalCoverUpto = eligibleCoverUpto.find(cp => cp === finalCoverUpto)
        } else {
          for (let i = 0; i < eligibleCoverUpto.length; i++) {
            if (finalCoverUpto < eligibleCoverUpto[i]) {
              finalCoverUpto = eligibleCoverUpto[i - 1]
              break
            }
          }
        }
      }
    } else {
      finalCoverUpto = productData?.coverTerm
    }
    if (wholeLife) {
      coverUptoLabel = 'Cover Upto'
      coverUpto = 'Entire life'
    } else if (!isNotDefined(productData?.coverTerm)) {
      coverUpto = `${finalCoverUpto} years`
      coverUptoLabel = 'Cover Upto'
    } else if (!isNotDefined(policyTerm)) {
      const { label, value } = getPolicyTerm(policyTerm, formData.policyTerm)
      coverUptoLabel = label
      coverUpto = value
    } else {
      coverUptoLabel = 'Term'
      coverUpto = '0 Year'
    }

    return (
      <Statistic color='green' className='alignLeft'>
        <Statistic.Label>{coverUptoLabel}</Statistic.Label>
        <DataLoader
          loading={coverTermLoading}
        >
          <Statistic.Value id={`${insurerId}-coverTerm`}>
            {coverUpto}
          </Statistic.Value>
        </DataLoader>
      </Statistic>
    )
  }
}
CoverPeriodTillAge.contextType = LoadingContext
export default CoverPeriodTillAge
