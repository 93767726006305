import { PureComponent } from 'react'
import { List, Checkbox, Divider } from 'semantic-ui-react'
import { Header, Caption, Body2 } from 'components/Header'
import DisplayDescription from 'components/DisplayDescription'
import DataLoader from 'components/DataLoader'
import TooltipComponent from './TooltipComponent'

const styles = {
  overlayStyle: {
    width: 250,
    fontSize: 14,
    opacity: 1,
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
  },
  description: {
    paddingBottom: 24
  }
}
class CheckBoxListItem extends PureComponent {
  render () {
    const { isPayout, isEdit, editable, onCheckBoxClick, onHeaderClick, heading, description, shortName, points, displayAmount, id, productOptionId, checked, loading, popUpContent, childKey, device } = this.props
    return (
      <List.Item
        key={childKey}
        className={`riderListItemCard ${(isEdit) ? 'listItemStyle' : ''}`}
        id={`${productOptionId}-${id}-riderName`}
      >
        <div className='flexContainer align-items-center'>
          <Checkbox
            size='small'
            id={`${productOptionId}-${id}-riderCheckbox`}
            checked={isEdit || checked}
            onChange={(_e, data) => onCheckBoxClick && onCheckBoxClick(data)}
          />
          <Header.Content className={isPayout ? 'payoutContent' : 'riderContent'}>
            <Caption
              className={`riderCaption ${editable ? 'hoverLink' : ''}`}
              onClick={isEdit ? undefined : onHeaderClick}
            >
              {heading}
            </Caption>
          </Header.Content>
          <div className='tooltipIcon'>
            <TooltipComponent disable={device === 'mobile'} id={`${productOptionId}-${id}`}>
              <strong>{shortName}</strong>
              <div style={styles.description}>
                <DisplayDescription
                  description={description}
                  points={points}
                />
              </div>
            </TooltipComponent>
          </div>
          <div className='floatRight'>
            <Body2 color='green' floated='right'>
              <DataLoader loading={loading}>
                <span className='d-flex align-items-center' id={`${productOptionId}-${id}-riderAmount`}>
                  {displayAmount}
                </span>
              </DataLoader>
            </Body2>
          </div>
        </div>
        {isEdit ? <> <Divider />{popUpContent} </> : null}
      </List.Item>
    )
  }
}
export default CheckBoxListItem
