import { useState, useEffect, useRef } from 'react'
import OTPWidget from 'components/jsonSchemaForms/widgets/OTPWidget'
import { Button, Modal } from 'semantic-ui-react'
import { Subtitle2, Body2, Caption, H6 } from 'components/Header'
import { requestApplicationOtp, verifyOtp } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'
import Loading from 'components/Loading'

// move to scss
const style = {
  position: {
    width: '30%',
    minWidth: 300
  },
  modalContent: { textAlign: 'center' },
  image: {
    margin: 'auto'
  },
  body: {
    marginBottom: 40
  },
  header: {
    marginBottom: 20
  },
  button: {
    marginLeft: 10
  },
  okBtn: {
    marginBottom: 5
  }
}

const OtpScreen = (props) => {
  const [otp, setOtp] = useState()
  // eslint-disable-next-line no-unused-vars
  const [enableResend, setEnableResend] = useState(false)
  const [verificationError, setVerificationError] = useState(false)
  const [generateError, setGenerateError] = useState(false)
  const [loading, setLoading] = useState(false)
  const componentRef = useRef({ })
  const { distributorId, formData, nextBookingStep } = props
  const updateBookingLoading = false

  // const { applicableStep: { title, displayText, digits, otpTimeout }, updateBookingLoading, nextBookingStep } = props
  const otpTimeout = 180000 //  otp expiration time is 3 minutes here it is converted into milliseconds
  const title = 'Enter One Time Password (OTP)'
  const subTitle = 'OTP is valid for 3 minutes'
  const displayText = 'One Time Password has been sent to your mobile {mobile}, please enter the same here.'
  const digits = 6

  const sendOtp = async () => {
    try {
      // FIXME: use reducer
      setGenerateError(false)
      setVerificationError(false)
      setLoading(true)
      setOtp(undefined)
      const { otpKey, otpGenDate, otpSentDate } = await requestApplicationOtp({
        distributorId,
        applicationFormData: formData
      })
      componentRef.current.otpKey = otpKey // set to null if error occurs
      componentRef.current.otpGenDate = otpGenDate
      componentRef.current.otpSentDate = otpSentDate
      if (componentRef.current.timeout) {
        clearTimeout(componentRef.current.timeout)
      }
      setEnableResend(false) // useReducer instead
      componentRef.current.timeout = setTimeout(() => {
        setEnableResend(true)
      }, otpTimeout)
      setLoading(false)
    } catch (err) {
      setGenerateError(true)
      setLoading(false)
      setOtp(undefined)
    }
  }

  useEffect(() => {
    if (!updateBookingLoading) {
      sendOtp()
    }
    return () => {
    // eslint-disable-next-line
      clearTimeout(componentRef.current.timeout);
    }
  },
  // eslint-disable-next-line
  [ updateBookingLoading ])

  const verify = async (_event) => {
    // FIXME: use reducer
    try {
      setLoading(true)
      const response = await verifyOtp({
        otp,
        otpKey: componentRef.current.otpKey,
        otpGenDate: componentRef.current.otpGenDate,
        otpSentDate: componentRef.current.otpSentDate,
        distributorId,
        applicationFormData: formData
      })
      setLoading(false)
      if (!response.otpVerified) {
        setVerificationError(true)
        setOtp(undefined)
      } else {
        let updatedFormData = produce(formData, draft => {
          draft.otp = otp
          draft.editTimeStamp = new Date()
        })
        updatedFormData = Object.assign({}, updatedFormData, response)
        nextBookingStep(updatedFormData)
      }
    } catch (err) {
      setVerificationError(true)
      setLoading(false)
      setOtp(undefined)
    }
  }
  if (updateBookingLoading) {
    return null
  }
  return (
    <>
      {verificationError && (
        <Modal style={style.position} open>
          <Modal.Content style={style.modalContent}>
            <H6 color='red'>
              Verification Error
            </H6>
            <Body2 style={style.body} color='grey'>
              {enableResend ? 'OTP expired, please regenerate the OTP' : 'Incorrect OTP Retry'}
            </Body2>
            <Button
              style={style.okBtn}
              primary
              onClick={() => {
                setVerificationError(false)
                setOtp(undefined)
              }}
            >
              Ok
            </Button>
          </Modal.Content>
        </Modal>
      )}
      {generateError && (
        <Modal style={style.position} open>
          <Modal.Content style={style.modalContent}>
            <Body2 style={style.body} color='grey'>
              Failed to send otp
            </Body2>
            <Button primary onClick={sendOtp}>
              Resend otp
            </Button>
          </Modal.Content>
        </Modal>
      )}
      <Loading open={loading} />
      <div>
        <H6 style={style.header}>
          {subTitle}
        </H6>
        <Subtitle2>
          {title}
        </Subtitle2>
        <Body2>
          {displayText.format(formData)}
        </Body2>
        <div className='otpWidgetHolder'>
          <OTPWidget
            value={otp}
            onChange={setOtp}
            options={{
              digits
            }}
          />
        </div>
        <Caption
          color={enableResend && 'accent'}
          onClick={enableResend && sendOtp}
        />
        <Button
          disabled={!(otp && otp.length === digits)}
          content='VERIFY'
          primary
          onClick={verify}
        />
        <Button
          disabled={!enableResend}
          content='RESEND'
          primary
          onClick={sendOtp}
          style={style.button}
        />
      </div>
    </>
  )
}

export default OtpScreen
