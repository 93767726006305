
import { useState, useEffect } from 'react'
import { createOrder, capturePayment } from 'smartcovr_api/src/paymentApi'
import Loading from 'components/Loading'
import { buildDistributorImagePath } from 'components/buildImagePath'
const Payment = (props) => {
  const [capturePaymentLoading, setCapturePaymentLoading] = useState(false)
  const {
    insuranceType
  } = props

  const paymentResponseHandler = async (response) => {
    console.log('payment response :- ', response)
    const { product: { totalPremiumWithGst, institutionId }, selectedProductDetails: { applicationNumber } } = props
    const amount = totalPremiumWithGst * 100
    if (!response.error) {
      // success response
      // now capture the payment
      setCapturePaymentLoading(true)
      const captureResponse = await capturePayment({
        data: {
          paymentId: response.razorpay_payment_id,
          amount,
          appNumber: applicationNumber,
          paymentStatus: 'Y'
        },
        insuranceType,
        institutionId
      })

      console.log('payment capture response :- ', captureResponse)
      window.location.href = captureResponse
    } else {
      // failure response , handle this
    }
  }

  const defaultPaymentOptions = {
    key: 'rzp_test_gmTRy2bpBqI8m2',
    currency: 'INR',
    handler: paymentResponseHandler,
    theme: {
      color: '#0270f6'
    }
  }

  const paymentHandler = async (totalPremiumWithGst) => {
    try {
      const { enquiry, insuranceType } = props
      const orderResponse = await createOrder({ enquiry, insuranceType, totalPremiumWithGst })
      console.log('order created :- ', orderResponse)

      const {
        mobile
      } = props.formData

      // now initiate Payment
      const options = Object.assign({}, defaultPaymentOptions, {
        name: 'HDFC Securities',
        description: 'HDFC Securities (HSL)',
        image: buildDistributorImagePath('smartcovr-logo.png'),
        order_id: orderResponse.id,
        prefill: {
          name: 'Gaurav Kumar', // FIXME :
          email: 'gaurav.kumar@example.com', // FIXME :
          contact: mobile
        },
        notes: {
          address: 'Kanakia Rd'
        }
      })

      const rzp = new window.Razorpay(options)
      rzp.open()
    } catch (err) {
      console.log('error in create order :- ', err)
    }
  }

  useEffect(() => {
    const { selectedProductDetails: { pgType, paymentUrl }, product: { totalPremiumWithGst } } = props
    if (pgType === 'ownPg') {
      paymentHandler(totalPremiumWithGst)
    } else {
      document.getElementById('paymentRedirectFormContainer').innerHTML = paymentUrl
      const element = document.getElementById('payment-form-id')
      if (element) {
        element.submit()
      }
    }
  },
  // eslint-disable-next-line
  [])

  if (capturePaymentLoading) {
    return <Loading open />
  }
  return (
    <div id='paymentRedirectFormContainer' style={{ display: 'none' }} />
  )
}

export default Payment
