/*
cartShape
[
  {
    schemeId: , // id of the scheme
    itemId: , // internal sequenct id
    productType:
    amount: 12121,
    investmentType: SIP,
    buyStartDate: '',
    buyEndDate: '',
    frequency: [S, M, Q, W, etc]
    asOfDate: // date on which added to cart.
  }
]
*/
import service from '../../services/mfService'
import { findIndex, isUndefined } from 'utils'
import { validateAddToBuy, validateAndMergeSipOrder, validateAndMergeOnTimeOrder } from './helpers/productHelper'
const mfBuy = {
  name: 'mfBuy',
  state: {
    cart: [],
    pendingOrders: {
    }
  },
  selectors: (slice, createSelector) => ({
    cart: (models) => {
      return createSelector(
        rootState => rootState.products,
        rootState => rootState.mfBuy,
        (products, mfBuy) => {
          const total = mfBuy.cart.reduce((total, cv) => cv.amount + total, 0)
          return {
            ...mfBuy.cart.map(item => {
              return {
                ...item,
                ...models.products.getScheme({ products }, { schemeId: item.schemeId })
              }
            }),
            total
          }
        }
      )
    },
    sipItems: (models) => {
      return createSelector(
        rootState => rootState.products,
        rootState => rootState.mfBuy,
        (products, mfBuy) => {
          const sipCartItems = mfBuy.cart.filter(item => item.investmentType === 'SIP')
          const total = sipCartItems.reduce((total, cv) => total + cv.amount, 0)
          return {
            ...sipCartItems.map(item => {
              return {
                ...item,
                ...models.products.getScheme({ products }, { schemeId: item.schemeId })
              }
            }),
            total
          }
        }
      )
    },
    oneTimeItems: (models) => {
      return createSelector(
        rootState => rootState.products,
        rootState => rootState.mfBuy,
        (products, mfBuy) => {
          const oneTimeCartItems = mfBuy.cart.filter(item => item.investmentType === 'ONETIME')
          const total = oneTimeCartItems.reduce((total, cv) => total + cv.amount, 0)
          return {
            ...oneTimeCartItems.map(item => {
              return {
                ...item,
                ...models.products.getScheme({ products }, { schemeId: item.schemeId })
              }
            }),
            total
          }
        }
      )
    },
    // Get all the orders that are grouped for first time payment
    orders: (models) => {
      return createSelector(
        slice,
        (state, props) => models.user.mandates({ user: state.user }, props),
        (state) => models.mfBuy.oneTimeItems(state),
        (state) => models.mfBuy.sipItems(state),
        (state) => models.configure.mfBooking({ configure: state.configure }),
        (mfBuy, mandates, oneTimeItems, sipItems, mfBooking) => {
          // 1. First check if the user has valid mandate
          const validSingleMandates = mandates.filter(mand => mand.amount >= sipItems.total)
          // 2. See if there is a combiend mandate that can help
          const validCombinedMandates = []
          // 3. Find approprate dates for SIP
          // FIXME: Need to fix this as soon as suresh gives apis.
          // There might be different cutoff times for each MF and hence we need to split it
          // accordingly. For now, i am choosing a day that fits all MF's.
          const sipDates = mfBooking.sipDates.filter(date => {
            const today = new Date().getDate()
            if (new Date().getHours() > 14) { // before 2 pm, include today as well
              return date > today
            } else {
              return date >= today
            }
          })
          return {
            oneTime: oneTimeItems,
            sips: [{
              ...sipItems,
              mandate: validSingleMandates,
              sipDates,
              firstTime: mandates.length === 0,
              sipId: 1
            }]
          }
        }
      )
    }
  }),
  reducers: {
    addItem: (state, payload) => {
      payload.asOfDate = new Date()
      payload.itemId = state.cart.length + 1
      payload.ordered = false
      state.cart.push(payload)
      return state
    },
    removeItem: (state, { itemId, schemeId }) => {
      let index = -1
      if (!isUndefined(itemId) && itemId > 0) {
        index = findIndex(state.cart, st => st.itemId === itemId)
      } else if (!isUndefined(schemeId) && schemeId > 0) {
        index = findIndex(state.cart, st => st.schemeId === schemeId)
      }
      if (index > -1) {
        state.cart.splice(index, 1)
      }
      return state
    },
    removeAll: (state) => {
      state.cart = []
      return state
    },
    createOrder: (state) => {
      return state.map(st => {
        st.odered = true
        return st
      })
    },
    updatePendingOrders: (state, payload) => {
      Object.assign(state.pendingOrders, payload)
      return state
    }
  },
  effects: (dispatch) => ({
    // Need to write the cart action to db for verification
    // {itemId: 123, schemeId: 212121, }
    addToBuy (payload, globalState) {
      const product = globalState.products.categories.entities.products[payload.schemeId]
      const errors = validateAddToBuy(product, payload)
      if (isUndefined(errors) || errors.length === 0) {
        return dispatch.mfBuy.addItem(payload)
      } else {
        throw errors
      }
    },
    /*
    payload = {
      transactionData: [
        {
          itemId: 1,
          amount: 20000,
          mandateId: , //only for SIP
          day_of_month: 1, //only for SIP
          sp_first_order_today: 1 //only for SIP; if new mandate

        }
      ],
      uak_id: ,
      payment_success_link: ''
    }
    */
    async placeSipOrders (payload, globalState) {
      const validatedAndMergedOrderData = validateAndMergeSipOrder(globalState, payload)
      if (isUndefined(validatedAndMergedOrderData.errors)) {
        const response = await service.createMultipleSipOrders(validatedAndMergedOrderData)
        dispatch.mfBuy.updatePendingOrders({ sipOrder: response })
      }
    },
    async placeOneTimeOrders (payload, globalState) {
      const validatedAndMergedOrderData = validateAndMergeOnTimeOrder(globalState, payload)
      const response = await service.createMultiplePurchaseTransactionsBse(validatedAndMergedOrderData)
      dispatch.mfBuy.updatePendingOrders({ oneTimeOrder: response })
    }

  })
}
export default mfBuy
