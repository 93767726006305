import PropTypes from 'prop-types'
import DescriptionField from '../fields/DescriptionField.js'
import { Checkbox } from 'semantic-ui-react'

function CheckboxWidget (props) {
  const {
    schema,
    id,
    value,
    required,
    disabled,
    readonly,
    label,
    autofocus,
    onChange
  } = props

  const labelEl = (
    label && (
      <label style={{ fontSize: '1rem', color: '#555' }}>{label}</label>
    )
  )
  return (
    <div className={`checkbox pb-3 ${disabled || readonly ? 'disabled' : ''}`}>
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <Checkbox
        id={id}
        checked={typeof value === 'undefined' ? false : value}
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        onChange={(event, data) => onChange(data.checked)}
        label={labelEl}
      />
    </div>
  )
}

CheckboxWidget.defaultProps = {
  autofocus: false
}

if (process.env.NODE_ENV !== 'production') {
  CheckboxWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func
  }
}

export default CheckboxWidget
