import React, { PureComponent } from 'react'
import ReviewAvailableAddons from './ReviewAvailableAddons'
import ListingAvailableAddons from './ListingAvailableAddons'
import { isNotEmpty, isNotDefined } from 'utils'
class AvailableAddons extends PureComponent {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onError = this.onError.bind(this)
    this.updatePopUp = this.updatePopUp.bind(this)
    this.premiumRef = React.createRef()
    this.state = {
      errors: [],
      reviewEdit: false
    }
  }

  onError (errors) {
    this.setState({ errors })
  }

  onCancel (riderId) {
    this.props.closeRiderEdit(riderId)
  }

  async onSubmit (formData) {
    const { feature: { schemaKey }, feature, product } = this.props
    let productCharacteristicId
    if (feature.productCharacteristics) {
      productCharacteristicId = feature.productCharacteristics[0].productCharacteristicId
    }
    this.props.onRiderChange({
      change: {
        [schemaKey]: {
          ...formData,
          include: true
        }
      },
      productCharacteristicId,
      insurerId: product.productInsurerId,
      institutionId: product.institutionId
    })
  }

  updatePopUp (data) {
    const { feature: { schemaKey, input }, feature, addRider, product, productData } = this.props
    let productCharacteristicId
    if (feature.productCharacteristics) {
      productCharacteristicId = feature.productCharacteristics[0].productCharacteristicId
    }
    const { checked } = data
    // checked is undefined in case of onChange. checked is true when checkbox is checked
    if (isNotDefined(checked) || checked) {
      if (input && !isNotDefined(input.schema) && !isNotDefined(input.schema.properties) && isNotEmpty(input.schema)) {
        addRider(schemaKey)
      } else {
        this.props.onRiderChange({
          change: {
            [schemaKey]: {
              include: true
            }
          },
          productCharacteristicId,
          insurerId: product.productInsurerId,
          institutionId: product.institutionId
        })
      }
    } else {
      const temp = productData
      this.props.onRiderChange({
        change: {
          [schemaKey]: {
            include: false
          },
          ci: {
            ...temp.ci,
            riderSumAssured: 0
          },
          ibad: {
            ...temp.ibad,
            riderSumAssured: 0
          }
        },
        productCharacteristicId,
        insurerId: product.productInsurerId,
        institutionId: product.institutionId
      })
    }
  }

  render () {
    const {
      editRiderId,
      contentType,
      feature,
      productOptionId,
      device,
      childKey,
      onRiderChange,
      formData,
      insuranceType,
      productData,
      rider
    } = this.props
    const { shortName, description, points, heading, descriptionPiped, schemaKey, input } = feature
    const featureFormData = productData[schemaKey]
    const isPoReview = contentType === 'poreview'
    const shortNameForFeature = (!isNotDefined(rider) && !isNotDefined(rider.hasRiderPaidWithAlreadyCalculatedPremium) && rider.hasRiderPaidWithAlreadyCalculatedPremium) ? rider.shortName : shortName
    const isEdit = (!isNotDefined(rider) && !isNotDefined(rider.hasRiderPaidWithAlreadyCalculatedPremium) && rider.hasRiderPaidWithAlreadyCalculatedPremium) ? false : (schemaKey === editRiderId)
    const { schema, uiSchema, rules } = input
    let checked = false
    if (featureFormData) {
      checked = featureFormData.include
    }
    const hasEdit = isNotEmpty(schema) && !isNotDefined(schema.properties)
    if (contentType === 'review') {
      const reviewRiderProps = {
        schema,
        uiSchema,
        formData,
        featureFormData,
        feature,
        rules,
        productOptionId,
        checked,
        heading,
        description,
        shortName: shortNameForFeature,
        points,
        descriptionPiped,
        childKey,
        device,
        isEdit,
        onRiderChange,
        onSubmit: this.onSubmit,
        onCancel: this.onCancel,
        insuranceType,
        updatePopUp: this.updatePopUp,
        hasEdit,
        contentType,
        rider
      }
      return (
        <ReviewAvailableAddons {...reviewRiderProps} />
      )
    } else if (isPoReview) {
      return null
    } else {
      const listingRiderProps = Object.assign({}, {
        schema,
        uiSchema,
        formData,
        featureFormData,
        feature,
        rules,
        productOptionId,
        checked,
        heading,
        description,
        shortName: shortNameForFeature,
        points,
        childKey,
        device,
        isEdit,
        onRiderChange,
        onSubmit: this.onSubmit,
        onCancel: this.onCancel,
        insuranceType,
        updatePopUp: this.updatePopUp,
        hasEdit,
        contentType,
        rider
      })
      return (
        <ListingAvailableAddons
          {...listingRiderProps}
        />
      )
    }
  }
}
export default AvailableAddons
