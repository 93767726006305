import { Icon } from 'semantic-ui-react'
import { Body1, Body2, Caption } from 'components/Header'
import DisplayHtmlString from 'components/DisplayHtmlString'
// import { isNotDefined } from 'utils'

import Features from '../Features'
// import { FreeFeatures, MockProductBenefits, MockRiders } from '../DummyData'
import ProductBenefits from '../ProductBenefits'
import RiderDetailContent from '../RiderDetailContent'
import TData from '../TData'
import { isNotDefined } from 'utils/lib/basic'

const policyTermDisplay = (policy) => {
  let coverUpto
  if (!isNotDefined(policy.coverterm)) {
    coverUpto = parseInt(policy.coverterm) <= 1 ? `${policy.coverterm} Year` : `${policy.coverterm} Years`
  } else if (!isNotDefined(policy.policyTerm)) {
    const { policyTerm } = policy
    let policyTermText = 'Year'
    if ((!isNaN(policyTerm) && Number(policyTerm) > 1) || isNaN(policyTerm)) { // for 3/5 || "1OD + 3TP"/"1OD + 5TP"
      policyTermText = 'Years'
    }
    coverUpto = `${policyTerm} ${policyTermText}`
  } else {
    coverUpto = '0 Year'
  }
  return coverUpto
}

const amountWithLabelInitials = function amountWithLabels (amount, payoutFrequency) {
  const crore = 10000000
  const lakh = 100000
  const tho = 1000
  let label
  if (payoutFrequency === 'A' || payoutFrequency === 'S') {
    label = ' / Year'
  } else if (payoutFrequency === 'H') {
    label = ' / Half Year'
  } else if (payoutFrequency === 'Q') {
    label = ' / Quarter'
  } else if (payoutFrequency === 'M') {
    label = ' / Month'
  }

  if (amount > crore) {
    return {
      amount: (amount / crore).toFixed(2),
      label: ' C' + label
    }
  } else if (amount >= lakh) {
    return {
      amount: (amount / lakh).toFixed(2),
      label: ' L' + label
    }
  } else {
    return {
      amount: (amount / tho).toFixed(0),
      label: ' K' + label
    }
  }
}
const amountWithLabels = function amountWithLabels (amount) {
  const crore = 10000000
  const lakh = 100000
  const tho = 1000
  if (amount > crore) {
    return {
      amount: (amount / crore).toFixed(2),
      label: ' C'
    }
  } else if (amount >= lakh) {
    return {
      amount: (amount / lakh).toFixed(2),
      label: ' L'
    }
  } else {
    return {
      amount: (amount / tho).toFixed(0),
      label: ' K'
    }
  }
}

const amountWithIconForAnnuity = (args) => (
  <Body1 color='green' className='amountWithIcon'>
    <div>
      <Icon size='small' name='rupee' />
      {`${args.amount} ${args.label}`}
    </div>
  </Body1>
)

export const PolicyDetails = (props) => {
  const { policy, device } = props
  const { payoutFrequency, annuityPayout, purchasePrice } = policy.insuranceType === 'annuity' && policy.AnnuityApplication
  const data = [
    {
      TitleName: 'Application Number',
      TitleValue: policy.application.applicationNumber,
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'annuity', 'health']
    },
    {
      TitleName: 'Policy Number',
      TitleValue: policy.policyNumber || 'Not Available',
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'annuity', 'health']
    },
    {
      TitleName: 'Policy Term',
      TitleValue: policyTermDisplay(policy),
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'health']
    },
    {
      TitleName: 'Premium',
      TitleValue: policy.premium,
      showhr: true,
      insuranceTypes: ['car', 'bike', 'term', 'health']
    },
    {
      TitleName: 'Purchase Price',
      TitleValue: amountWithIconForAnnuity(amountWithLabels(purchasePrice, payoutFrequency)),
      showhr: true,
      insuranceTypes: ['annuity']
    },
    {
      TitleName: 'Annuity Payout',
      TitleValue: amountWithIconForAnnuity(amountWithLabelInitials(annuityPayout, payoutFrequency)),
      showhr: true,
      insuranceTypes: ['annuity']
    }
  ]

  const renderData = () => {
    return data.map((content, i) => {
      if (content.insuranceTypes.includes(policy.insuranceType)) {
        return (
          <div className='columnData' key={i}>
            <TData Title={content.TitleName} Value={content.TitleValue} showhr={content.showhr} />
          </div>
        )
      }
      return null
    })
  }
  return (
    <div className='policyDetailsTabContent'>
      <div className='productData'>
        <Body1>
          {policy.productDetails.productName}
        </Body1>
        <div>
          <Body2>
            {policy.productDetails.productOptionName}
          </Body2>
          <Caption className='caption'>
            <DisplayHtmlString htmlContent={policy.productDetails.productDescription} />
          </Caption>
        </div>
      </div>
      <div className='row'>
        {renderData()}
      </div>
      <div className='riders'>
        <RiderDetailContent rider={policy.application.ApplicationRiders} device={device} />
      </div>
      <div className='features'>
        <Features features={policy.productDetails.features} device={device} />
      </div>
      <div className='benefits'>
        <ProductBenefits productBenefit={policy.productDetails.benefits} />
      </div>
    </div>
  )
}
