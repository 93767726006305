import { upperFirst } from 'lodash'
import { useLoading } from './useLoading'
const dashboardDrawerItemMapper = {
  term: {
    totalLeads: {
      text: 'Leads',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off Stage', field: 'dropOffStage' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'Income', field: 'income' },
        { title: 'City', field: 'city' }
      ]
    },
    pendingApplication: {
      text: 'Pending Application',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        // { title: 'Status', field: 'status' }, // _BRD missing : Status
        { title: 'Gender', field: 'gender' },
        { title: 'Drop Off Stage', field: 'dropOffStage' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'Income', field: 'income' },
        { title: 'City', field: 'city' }
      ]
    },
    completedPaymentButformSubmissionPending: {
      text: 'Completed Payment But Form Submission Pending',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        // { title: 'Status', field: 'status' }, // _BRD missing : Status
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'Income', field: 'income' },
        { title: 'City', field: 'city' }
      ]
    },
    submittedApplication: {
      text: 'Submitted Application',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Application Submitted Date', field: 'applicationSubmittedDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        // { title: 'Status', field: 'status' },  // _BRD missing : Status
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'Income', field: 'income' },
        { title: 'City', field: 'city' }
      ]
    },
    mailingDetails: {
      text: 'Mailing Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off Mailer Sent', field: 'message' }
      ]
    },
    smsDetails: {
      text: 'SMS Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off SMS Sent', field: 'message' }
      ]
    },
    dropOffStageReport: {
      text: 'Drop Off Stage Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Drop Off Stage', field: 'dropOffStage' },
        { title: 'Insurer', field: 'insurer' },
        { title: 'Product Selected', field: 'productName' },
        { title: 'Premium', field: 'totalPremium' },
        { title: 'Frequency', field: 'paymentFrequency' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email Id', field: 'email' },
        { title: 'Type Of Customer', field: 'customerType' },
        { title: 'Trading Id / Client Id', field: 'clientId' },
        { title: 'City', field: 'city' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'Income', field: 'income' },
        { title: 'Smoker/Non-Smoker', field: 'cType' }
      ]
    },
    conversionReport: {
      text: 'Conversion Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Application Start Date', field: 'applicationStartDate' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Policy Number', field: 'policyNumber' },
        { title: 'Contract Owner Name', field: 'insured' },
        { title: 'Application Submission Date', field: 'applicationSubmissionDate' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Product Name', field: 'productName' },
        { title: 'Basic Sum Assured', field: 'baseSumAssured' },
        { title: 'Sum Assured', field: 'totalSumAssured' },
        { title: 'Premium Without Tax', field: 'totalPremium' },
        { title: 'Premium With Tax', field: 'totalPremiumWithGst' },
        { title: 'Policy Term', field: 'policyTerm' },
        { title: 'Premium Paying Term', field: 'premiumPayingTerm' },
        { title: 'Premium Due Date', field: 'premiumDueDate' },
        { title: 'LG Code', field: 'LG_Code' }
      ]
    }
  },
  car: {
    totalLeads: {
      text: 'Leads',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Make And Model Of Vehicle ', field: 'makeModel' },
        { title: 'RTO Location', field: 'rtoLocation' },
        { title: 'Status', field: 'applicationStatus' },
        { title: 'Drop of Timestamp and Date', field: 'dropoffDate' },
        { title: 'IDV / Previous IDV', field: 'idv' }
      ]
    },
    mailingDetails: {
      text: 'Mailing Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Application Status', field: 'applicationStatus' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off Mailer Sent', field: 'dropoffMailSent' }
      ]
    },
    smsDetails: {
      text: 'SMS Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Application Status', field: 'applicationStatus' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off SMS Sent', field: 'dropoffSmsSent' }
      ]
    },
    dropOffStageReport: {
      text: 'Drop Off Stage Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop of Timestamp and Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email Id', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'RTO Location', field: 'rtoLocation' },
        { title: 'Insurer', field: 'insurer' },
        { title: 'Product Selected', field: 'productName' },
        { title: 'Make And Model Of Vehicle ', field: 'makeModel' },
        { title: 'IDV', field: 'idv' },
        { title: 'Premium With GST', field: 'premiumWithGst' },
        { title: 'Type Of Customer', field: 'customerType' },
        { title: 'Trading Id / Client Id', field: 'clientId' },
        { title: 'Previous Policy Number / Third Party Policy Number ', field: 'thirdPartyPolicyNumber' },
        { title: 'Previous Policy End Date / Third Party Policy End Date  ', field: 'thirdPartyPolicyEndDate' },
        { title: 'Previous Insurer Name / Third Party Policy Insurer Name ', field: 'thirdPartyInsurerName' }
      ]
    },
    conversionReport: {
      text: 'Conversion Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Quote Number', field: 'quoteId' },
        { title: 'Policy Number', field: 'policyNumber' },
        { title: 'Policy Issue Date', field: 'applicationStartDate' },
        { title: 'Contract Owner Name', field: 'name' },
        { title: 'Product Name', field: 'productName' },
        { title: 'Premium Without Tax', field: 'totalPremium' },
        { title: 'Premium With Tax', field: 'totalPremiumWithGst' },
        { title: 'Policy Term', field: 'policyTerm' }
      ]
    }
  },
  bike: {
    totalLeads: {
      text: 'Leads',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Make And Model Of Vehicle ', field: 'makeModel' },
        { title: 'RTO Location', field: 'rtoLocation' },
        { title: 'Status', field: 'applicationStatus' },
        { title: 'Drop of Timestamp and Date', field: 'dropoffDate' },
        { title: 'IDV / Previous IDV', field: 'idv' }
      ]
    },
    mailingDetails: {
      text: 'Mailing Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Application Status', field: 'applicationStatus' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off Mailer Sent', field: 'dropoffMailSent' }
      ]
    },
    smsDetails: {
      text: 'SMS Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Application Status', field: 'applicationStatus' },
        { title: 'Status', field: 'status' },
        { title: 'Drop Off SMS Sent', field: 'dropoffSmsSent' }
      ]
    },
    dropOffStageReport: {
      text: 'Drop Off Stage Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop of Timestamp and Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email Id', field: 'email' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'RTO Location', field: 'rtoLocation' },
        { title: 'Insurer', field: 'insurer' },
        { title: 'Product Selected', field: 'productName' },
        { title: 'Make And Model Of Vehicle ', field: 'makeModel' },
        { title: 'IDV', field: 'idv' },
        { title: 'Premium With GST', field: 'premiumWithGst' },
        { title: 'Type Of Customer', field: 'customerType' },
        { title: 'Trading Id / Client Id', field: 'clientId' },
        { title: 'Previous Policy Number / Third Party Policy Number ', field: 'thirdPartyPolicyNumber' },
        { title: 'Previous Policy End Date / Third Party Policy End Date  ', field: 'thirdPartyPolicyEndDate' },
        { title: 'Previous Insurer Name / Third Party Policy Insurer Name ', field: 'thirdPartyInsurerName' }
      ]
    },
    conversionReport: {
      text: 'Conversion Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Quote Number', field: 'quoteId' },
        { title: 'Policy Number', field: 'policyNumber' },
        { title: 'Policy Issue Date', field: 'applicationStartDate' },
        { title: 'Contract Owner Name', field: 'name' },
        { title: 'Product Name', field: 'productName' },
        { title: 'Premium Without Tax', field: 'totalPremium' },
        { title: 'Premium With Tax', field: 'totalPremiumWithGst' },
        { title: 'Policy Term', field: 'policyTerm' }
      ]
    }
  },
  health: {
    totalLeads: {
      text: 'Leads',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop of Timestamp and Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Gender', field: 'gender' },
        { title: 'City', field: 'city' },
        { title: 'Date of Birth', field: 'dateOfBirth' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Trading Id / Client ID', field: 'clientId' },
        { title: 'Status', field: 'status' }
      ]
    },
    pendingApplication: {
      text: 'Pending Application',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile ', field: 'mobile' },
        { title: 'Gender', field: 'gender' },
        { title: 'City', field: 'city' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Inquiry For', field: 'inquiryFor' },
        { title: 'Status', field: 'status' }
      ]
    },
    completedPaymentButformSubmissionPending: {
      text: 'Completed Payment But Document Upload Pending',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile ', field: 'mobile' },
        { title: 'Gender', field: 'gender' },
        { title: 'City', field: 'city' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Inquiry For', field: 'inquiryFor' },
        { title: 'Status', field: 'status' }
      ]
    },
    submittedApplication: {
      text: 'Submitted Application',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Application Submitted Date', field: 'applicationSubmittedDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile ', field: 'mobile' },
        { title: 'Gender', field: 'gender' },
        { title: 'Date Of Birth', field: 'dateOfBirth' },
        { title: 'City', field: 'city' }
      ]
    },
    mailingDetails: {
      text: 'Mailing Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile ', field: 'mobile' },
        { title: 'Gender', field: 'gender' },
        { title: 'City', field: 'city' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Inquiry For', field: 'inquiryFor' },
        { title: 'Status', field: 'status' }
      ]
    },
    smsDetails: {
      text: 'SMS Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Email ID', field: 'email' },
        { title: 'Mobile ', field: 'mobile' },
        { title: 'City', field: 'city' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Drop Off SMS Sent', field: 'message' }
      ]
    },
    dropOffStageReport: {
      text: 'Drop Off Stage Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Drop Off Stage', field: 'dropOffStage' },
        { title: 'Insurer', field: 'insurer' },
        { title: 'Product Selected / Product Option', field: 'productName' },
        { title: 'Premuim', field: 'totalPremium' },
        { title: 'Frequency', field: 'paymentFrequency' },
        { title: 'Type Of Customer', field: 'customerType' },
        { title: 'Trading Id / Client Id', field: 'clientId' },
        { title: 'Email Id', field: 'email' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Gender', field: 'gender' },
        { title: 'City', field: 'city' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Drop Off SMS Sent', field: 'status' }
      ]
    },
    conversionReport: {
      text: 'Conversion Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Application Start Date', field: 'applicationStartDate' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Policy Number', field: 'policyNumber' },
        { title: 'Contract Owner Name', field: 'insured' },
        { title: 'Application Submission Date', field: 'applicationSubmissionDate' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Product Name / Product Option', field: 'productName' },
        { title: 'Sum Assured', field: 'sumAssured' },
        { title: 'Policy Term', field: 'policyTerm' },
        { title: 'Premuim Without Tax', field: 'premiumWithoutTax' },
        { title: 'Premuim With Tax', field: 'premiumWithTax' },
        { title: 'Next Premium Due Date', field: 'premiumDueDate' }
      ]
    }
  },
  annuity: {
    totalLeads: {
      text: 'Leads',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'City', field: 'city' },
        { title: 'Drop Off Stage', field: 'dropOffStage' },
        { title: 'Status', field: 'status' }
      ]
    },
    pendingApplication: {
      text: 'Pending Application',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'City', field: 'city' },
        { title: 'Drop Off Stage', field: 'dropOffStage' }
      ]
    },
    completedPaymentButformSubmissionPending: {
      text: 'Completed Payment But Form Submission Pending',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'City', field: 'city' }
      ]
    },
    submittedApplication: {
      text: 'Submitted Application',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Application Submitted Date', field: 'applicationSubmittedDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'City', field: 'city' }
      ]
    },
    mailingDetails: {
      text: 'Mailing Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Drop Off Mailer Sent', field: 'message' },
        { title: 'Status', field: 'status' }
      ]
    },
    smsDetails: {
      text: 'SMS Details',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email ID', field: 'email' },
        { title: 'Drop Off SMS Sent', field: 'message' },
        { title: 'Status', field: 'status' }
      ]
    },
    dropOffStageReport: {
      text: 'Drop Off Stage Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Drop-off Date', field: 'dropoffDate' },
        { title: 'Name', field: 'name' },
        { title: 'Mobile Number', field: 'mobile' },
        { title: 'Email Id', field: 'email' },
        { title: 'Gender', field: 'gender' },
        { title: 'DOB', field: 'dateOfBirth' },
        { title: 'City', field: 'city' },
        { title: 'Type Of Customer', field: 'customerType' },
        { title: 'Trading Id / Client Id', field: 'clientId' },
        { title: 'Drop Off Stage', field: 'dropOffStage' },
        { title: 'Insurer', field: 'insurer' },
        { title: 'Application Type(SL,JL)', field: 'applicationType' },
        { title: 'Product Selected / Product Option', field: 'productName' },
        { title: 'Purchase Price', field: 'purchasePrice' },
        { title: 'Payment Frequency', field: 'paymentFrequency' },
        { title: 'Annuity Payout', field: 'annuityPayout' },
        { title: 'Payout Frequency', field: 'payoutFrequency' }
      ]
    },
    conversionReport: {
      text: 'Conversion Report',
      selector: () => undefined,
      effect: () => undefined,
      columns: [
        { title: 'Sr. No.', field: 'srNo' },
        { title: 'Application Start Date', field: 'applicationStartDate' },
        { title: 'Application Submission Date', field: 'applicationSubmissionDate' },
        { title: 'Contract Owner Name', field: 'insured' },
        { title: 'Product Name / Product Option', field: 'productName' },
        { title: 'Application Number', field: 'applicationNumber' },
        { title: 'Payment Date', field: 'paymentDate' },
        { title: 'Policy Number', field: 'policyNumber' },
        { title: 'Purchase Price', field: 'purchasePrice' },
        { title: 'Total Purchase Price', field: 'totalPurchasePrice' },
        { title: 'Total Purchase Price GST', field: 'totalPurchasePriceGST' },
        { title: 'Premium', field: 'premium' },
        { title: 'Total Premium Price', field: 'totalPremiumPrice' },
        { title: 'Total Premium Price GST', field: 'totalPremiumPriceGST' },
        { title: 'Payment Frequency', field: 'paymentFrequency' },
        { title: 'Premium Paying Term', field: 'premiumPayingTerm' },
        { title: 'Premium Due Date', field: 'premiumDueDate' },
        { title: 'Deferred Period (3,5,7)', field: 'deferredPeriod' },
        { title: 'Payout Option', field: 'payoutOption' },
        { title: 'Payout Frequency', field: 'payoutFrequency' },
        { title: 'Annuity Payout', field: 'AnnuityPayout' }
      ]
    }
  }
}
const createMapper = (fields, functions, prefix) => {
  return Object.keys(fields).map(field => {
    const functionName = `${prefix}${upperFirst(field)}`
    const fn = functions[functionName]
    if (fn.name === functionName) {
      return {
        field,
        func: fn
      }
    }
    console.log(`${functionName} is not a function`)
    return {
      field,
      func: () => undefined
    }
  })
}

const populateEffects = (effects, insuranceType) => {
  const effectsMapper = createMapper(dashboardDrawerItemMapper[insuranceType], effects, 'loadReportFor')
  effectsMapper.forEach(fieldObj => {
    const { field, func } = fieldObj
    dashboardDrawerItemMapper[insuranceType][field].effect = func
  })
}

const populateSelectors = (selector, insuranceType) => {
  const selectorMapper = createMapper(dashboardDrawerItemMapper[insuranceType], selector, 'getReportFor')
  selectorMapper.forEach(fieldObj => {
    const { field, func } = fieldObj
    dashboardDrawerItemMapper[insuranceType][field].selector = func
  })
}

const validateDateRange = (dateFrom, dateTo) => {
  const from = new Date(dateFrom).getTime()
  const to = new Date(dateTo).getTime()
  return from > to
}

const useDashboardLoading = (selectedItemKey) => {
  const allLoadings = useLoading()
  switch (selectedItemKey) {
    case 'totalLeads':
      return allLoadings.reportForTotalLeadsLoading
    case 'completedPaymentButformSubmissionPending':
      return allLoadings.reportForCompletedPaymentButFormSubmissionPendingLoading
    case 'submittedApplication':
      return allLoadings.reportForSubmittedApplicationLoading
    case 'pendingApplication':
      return allLoadings.reportForPendingApplicationLoading
    case 'mailingDetails':
      return allLoadings.reportForMailingDetailsLoading
    case 'smsDetails':
      return allLoadings.reportForSmsDetailsLoading
    case 'dropOffStageReport':
      return allLoadings.reportForDropOffStageLoading
    case 'conversionReport':
      return allLoadings.conversionReportLoading
    default:
      return false
  }
}
export { dashboardDrawerItemMapper, populateEffects, populateSelectors, validateDateRange, useDashboardLoading }
