
import OtpInput from 'components/OtpInput'

const OTPWidget = (props) => {
  const {
    id,
    value,
    options
  } = props
  const otp = value && value.toString()
  // Remove labels
  return (
    <OtpInput
      id={(!id) ? 'otpWidget' : id}
      onChange={(value) => {
        props.onChange(value)
      }}
      otp={otp}
      numInputs={options.digits}
      separator={<span>&nbsp;</span>}
    />
  )
}
export default OTPWidget
