import { PureComponent } from 'react'
import { Image } from 'semantic-ui-react'
import { H6, Subtitle1, Subtitle2, Caption, Body2 } from 'components/Header'
import DisplayHtmlString from 'components/DisplayHtmlString'
import EditAddons from '../../EditAddOns'
import StatisticAmount from 'components/statistic/StatisticAmount'
import StatisticPeriod from 'components/statistic/StatisticPeriod'
import DividedSegmentGreySeperator from 'components/DividedSegmentGreySeperator'
import { buildCommonImagePath } from 'components/buildImagePath'

// During productConfig, features can have input types enabling users to select some features for change in
// product option
// FIXME: add markComm desc in config
class ConfigFeature extends PureComponent {
  onSubmit = (formData) => {
    const { onFeatureChange, feature } = this.props
    const schemaKey = feature.input.schema.key
    onFeatureChange(schemaKey, formData)
  }

  // onChange = ({ formData }) => {
  //   const { onFeatureChange, feature } = this.props
  //   const schemaKey = feature.input.schema.key
  //   onFeatureChange(schemaKey, formData)
  // }
  onCancel = () => {
    const { onFeatureChange, feature } = this.props
    const schemaKey = feature.input.schema.key
    onFeatureChange(schemaKey, {})
  }

  render () {
    const {
      feature: {
        heading,
        description,
        input: { schema, uiSchema }
      },
      productData: { coverTerm },
      featureAmount,
      productData
    } = this.props
    const schemaKey = schema.key
    const defaultData = Object.assign({}, productData[schemaKey])
    return (
      <DividedSegmentGreySeperator
        leftSegment={(
          <>
            <H6 color='primaryLight'>
              {heading}
            </H6>
            <Subtitle1 color='grey'>
              <DisplayHtmlString htmlContent={description} />
            </Subtitle1>
            <div className='riderDetails'>
              <StatisticAmount
                data={featureAmount}
                label='Additional Premium'
                className='flexItem'
              />
              <StatisticPeriod
                data={coverTerm}
                label='Feature Term'
                className='flexItem'
              />
            </div>
            <EditAddons
              schema={schema}
              uiSchema={uiSchema}
              rules={[]}
              onSubmit={this.onSubmit}
              formData={defaultData}
              submitText='Save'
              onCancel={this.onCancel}
              size='medium'
            />
          </>
        )}
        rightSegment={(
          <div className='markComm'>
            <Subtitle2 color='primaryLight'>
              <Image size='size16' src={buildCommonImagePath('help-icon.png')} />
              Useful Information
            </Subtitle2>
            <div className='content'>
              <Body2 content='Marketing communication title' />
              <Caption color='grey' content='This is a placeholder for marketing communication description on the said feature.' />
              <Caption className='highlight' color='secondary'>
                <Image size='size14' src={buildCommonImagePath('sc-star-icon.png')} />
                Useful highlight for the user.
              </Caption>
            </div>
          </div>
        )}
      />

    )
  }
}

export default ConfigFeature
