import { PureComponent } from 'react'
import { Button } from 'semantic-ui-react'
import { adopt } from 'react-adopt'
import { DataAccessContext } from 'components/context'
import PropTypes from 'prop-types'
import { isNotEmpty, isNotDefined } from 'utils'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'
import { JsonSchemaOnboardForm, JsonSchemaHelper, JsonSchemaValidation } from 'components/jsonSchemaForms'

class SingleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      formData: this.props.formData,
      uiSchema: this.props.configure.onboard.onboardSchema.uiSchema
    }
    this.handleOnClick = this.handleOnClick.bind(this)
    this.handleChangeData = this.handleChangeData.bind(this)
    this.transformErrors = this.transformErrors.bind(this)
    this.handleOnValidate = this.handleOnValidate.bind(this)
  }

  calculateAge (dateOfBirth) {
    const diffMs = Date.now() - new Date(dateOfBirth).getTime()
    const ageDt = new Date(diffMs)
    return Math.abs(ageDt.getUTCFullYear() - 1970)
  }

  /**
    * validate form data on submit
    *
    * @memberof SingleForms
    */
  handleOnClick ({ formData }) {
    const { setFormData } = this.props
    if (!isNotDefined(formData.members)) {
      formData.members.forEach(data => {
        data.age = this.calculateAge(data.dateOfBirth)
      })
    }
    setFormData(formData, 'basicDetails')
  }

  static getDerivedStateFromProps (props, state) {
    const newSchema = state.uiSchema
    if (state.formData.members.length > 2) {
      newSchema.members['ui:options'].removable = true
    } else {
      newSchema.members['ui:options'].removable = false
    }
    return {
      uiSchema: newSchema
    }
  }

  handleOnValidate (formData, errors) {
    const membersErrors = JsonSchemaValidation.validateMemberDetails(formData, 'members')
    membersErrors.forEach((key, value) => {
      if (key !== '') {
        errors.members[value].dateOfBirth.addError(key)
      }
    })
    return errors
  }

  handleChangeData (e) {
    const { formData, uiSchema, key } = e
    if (key === 'members') {
      if (formData.members.length > 2) {
        const newSchema = uiSchema
        newSchema.members['ui:options'].removable = true
        this.setState({ uiSchema: newSchema })
      } else {
        const newSchema = uiSchema
        newSchema.members['ui:options'].removable = false
        this.setState({ uiSchema })
      }
      this.setState({ formData })
    }
  }

  transformErrors (errors) {
    if (isNotEmpty(errors)) {
      const newErrors = errors.filter(error => error.name === 'required')
      newErrors.forEach((error) => {
        const currentProperty = error.params?.missingProperty
        let selectedSchema
        if (currentProperty) {
          selectedSchema = JsonSchemaHelper.extractNestedObject(this.props.schema, currentProperty, currentProperty)
        }
        let title = 'This'
        if (selectedSchema && selectedSchema[currentProperty]?.title && selectedSchema[currentProperty]?.title.trim().length !== 0) {
          title = selectedSchema[currentProperty].title
        }
        error.message = getCustomErrorMessage(title, error)
      })
      return newErrors
    }
    return errors
  }

  render () {
    const { listing, schema } = this.props
    return (
      <JsonSchemaOnboardForm
        schema={schema}
        uiSchema={this.state.uiSchema}
        onSubmit={this.handleOnClick}
        onCancel={this.props.onBack}
        formData={this.state.formData}
        onValidate={this.handleOnValidate}
        onChange={this.handleChangeData}
        rules={[]}
        liveValidate={false}
        transformErrors={this.transformErrors}
      >
        <div className='submitButtons'>
          <Button id='viewProductsButton' secondary content={listing ? 'UPDATE' : 'PROCEED'} />
          {listing && <Button basic onClick={this.props.onBack}> BACK </Button>}
        </div>
      </JsonSchemaOnboardForm>

    )
  }
}

const WithContext = adopt({
  dataAccess: <DataAccessContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ dataAccess }) => {
        return (
          <SingleForm
            {...props}
            formType={dataAccess.formType}
            device={dataAccess.device}
            setFormData={props.setFormData}
          />
        )
      }
    }
  </WithContext>
)
WrapperComponent.propTypes = {
  setFormData: PropTypes.func.isRequired,
  onBack: PropTypes.func
}
export default WrapperComponent
