import { connect } from 'react-redux'

import DashboardBackButton from './DashboardBackButton'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = ownProps.store.select(models => ({
    selectedApplicationNumber: models.applications.getSelectedApplicationNumber,
    selectedApplicationNumberForPolicy: models.policies.getSelectedPolicy
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  const { selectedApplicationNumber, selectedApplicationNumberForPolicy } = selectorData
  const { getApplicationById } = select.applications
  const { getPolicyById } = select.policies
  const selectedApplication = getApplicationById(state, {
    insuranceType,
    applicationNumber: selectedApplicationNumber
  })
  const selectedPolicyData = getPolicyById(state, {
    insuranceType,
    id: selectedApplicationNumberForPolicy
  })
  return {
    ...ownProps,
    ...selectorData,
    selectedApplication,
    selectedPolicyData
  }
}

const mapDistpatchProps = (dispatch, ownProps) => {
  const {
    insuranceType
  } = ownProps
  return {
    updateSelectedApplicationNumber: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.applications.updateSelectedApplicationNumber(query)
    },
    updateSelectedPolicy: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.policies.updateSelectedPolicy(query)
    }
  }
}

const DashboardBackButtonContainer = connect(
  mapStateToProps,
  mapDistpatchProps
)(DashboardBackButton)
export default DashboardBackButtonContainer
