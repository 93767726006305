import { Form, Menu } from 'semantic-ui-react'
import { Header } from 'components/Header'

const FilterMenuMobileHealth = (props) => {
  const { ComponentMap } = props
  return (
    <>
      <Menu.Item>
        <Form>
          <Header color='black' sub className='mt-0'>Cover Upto</Header>
          {ComponentMap.coverTermHealth}
        </Form>
      </Menu.Item>
      <Menu.Item>
        <Form>
          <Header color='black' sub className='mt-0'>Sum Assured</Header>
          {ComponentMap.healthSumAssured}
        </Form>
      </Menu.Item>
      <Menu.Item>
        <Form>
          {ComponentMap.moreFilters}
        </Form>
      </Menu.Item>
    </>
  )
}

export default FilterMenuMobileHealth
