import DisplayHtmlString from 'components/DisplayHtmlString'
import { Checkbox } from 'semantic-ui-react'

const TNCWidget = props => {
  const {
    value,

    formContext,

    onChange
  } = props
  const termsAndConditions = formContext.termsAndConditions || ''
  return (
    <>
      <DisplayHtmlString
        htmlContent={termsAndConditions}
        style={{
          'margin-bottom': '1em'
        }}
      />
      <Checkbox
        material
        size='small'
        checked={value}
        onChange={(e, data) => {
          e.preventDefault()
          const { checked } = data
          if (!checked) {
            onChange(undefined)
          } else {
            onChange(true)
          }
        }}
        label='I agree to the Terms and Conditions'
      />
    </>
  )
}
export default TNCWidget
