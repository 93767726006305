import { useEffect, useState } from 'react'
import Loading from 'components/Loading'
import { useInitialDataLoader } from 'components/customHooks'
import Dashboard from './customerHoldings/DashBoard'
import 'components/css/customerHoldings.scss'
import MUIDrawer from 'components/MUIDrawer'
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { capitalize } from 'lodash'

const CustomerHoldings = props => {
  const {
    insuranceType,
    loadingProductData,
    isLoggedIn,
    customerDetails,
    store,
    location,
    screen,
    device,
    onLogout,
    availableProducts
  } = props
  const { loadingConfigure, configure } = useInitialDataLoader(
    insuranceType,
    props.enquiryId,
    props.distributorId
  )

  const [continueForm, setContinueForm] = useState(false)

  // On Load checks If not logged in redirected to the onboard page
  useEffect(() => {
    if (!isLoggedIn) {
      props.redirectToPath('/')
    }
  },
  // eslint-disable-next-line
  [isLoggedIn])

  if (continueForm) {
    return <Loading id='loading_prepay_booking_config' open />
  }
  if (loadingConfigure) {
    return <Loading id='loading_customer_holding_configure' open />
  }
  if (loadingProductData) {
    return <Loading id='product_data_loading' open />
  }
  if (props.holdingsLoading) {
    return <Loading id='loading_customer_holding' open />
  }
  if (props.loadingSetFormData) {
    return <Loading id='loadingSetFormData' open />
  }

  // destructed values from customerHoldings
  const {
    welcomeText: { title }
  } = configure.customerHoldings

  const labelExtractor = (item) => {
    return `${capitalize(item)} Insurance`
  }

  const logoutHandler = () => {
    onLogout()
    props.redirectToPath('/')
  }
  const theme = createTheme()

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <MUIDrawer
          onLogout={logoutHandler}
          listMapper={availableProducts}
          labelExtractor={labelExtractor}
          device={device}
          selectedItem={insuranceType}
          onItemChangedListener={(item) => props.redirectToPath(`/${item}/customer-holdings`)}
        />
        <Dashboard
          insuranceType={insuranceType}
          title={title}
          customerDetails={customerDetails}
          setContinueForm={setContinueForm}
          store={store}
          redirectToPath={props.redirectToPath}
          location={location}
          screen={screen}
          device={device}
        />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
export default CustomerHoldings
