import { connect } from 'react-redux'
import { TrackClaims } from './components/claims'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    distributorId: models.configure.getDistributorId,
    customerDetails: models.customer.getCustomerDetails,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    availableProducts: models.configure.getAvailableProducts,
    claimStatus: models.claimsService.getClaimStatus
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...ownProps,
    ...selectorData,
    insuranceType,
    claimStatusLoading: state.loading.effects.claimsService.trackClaim,
    store: ownProps.store
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // effects go here
    // selectors go here
    getClaimStatus: (query) => {
      return dispatch.claimsService.trackClaim(query)
    }

  }
}

const TrackClaimsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TrackClaims)
TrackClaimsContainer.path = 'track-claims'
export default TrackClaimsContainer
