import { Box, Button, Container, Grid, IconButton, Modal, Paper, Typography } from '@mui/material'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import { currentClaimData } from './dummyData'
import { useRef, useState } from 'react'

const modalStyle = {
  backgroundColor: 'white',
  border: '2px solid #000',
  borderRadius: '8px',
  boxShadow: 24,
  padding: '16px',
  minWidth: '300px',
  textAlign: 'center'
}

export default function DashBoard (props) {
  const { setFormStep, claimType, claimStatus } = props
  const fileInputRef = useRef(null)
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const handleIconClick = () => fileInputRef.current.click()
  const hanldeFileChange = (e) => {
    const file = e.target.files[0]
    file && setSelectedFile(file)
  }
  const onClickFileUpload = () => {
    setModalOpen(true)
  }

  const configureDate = (date) => {
    const dateObject = new Date(date)
    const day = dateObject.getUTCDate().toString().padStart(2, '0')
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0')
    const year = dateObject.getUTCFullYear()
    return `${day}/${month}/${year}`
  }

  return (
    <>
      <Container>
        <Typography style={{ padding: '1rem 0 1.5rem 1.5rem' }} variant='h4'>{`${claimType}  Claim Status`}</Typography>
        {claimStatus.length > 0 && claimStatus.map((claim, idx) => (
          <Container key={idx} style={{ marginBottom: '2rem' }}>
            <Paper elevation={3}>
              <Box p={2}>
                <Typography variant="h5" fontWeight={'bold'} style={{ paddingBottom: '1rem' }} >Policy Information</Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight={'500'}>Claim Registration Number :  {claim.registrationNo}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight={'500'}>Policy Number: {claim.policyNumber}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                  <Typography fontWeight={'500'}>Claim Intimation Date: {configureDate(claim.createdAt)}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography fontWeight={'500'}>Claim Submission/Registration Date: {configureDate(claim.claimRegistrationDate)}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontWeight={'500'}>Claim Status: {claim.claimStatus}</Typography>
                  </Grid>
                  {claimType === 'Current' && <> <Grid item xs={12}>
                    <div>
                      <Typography fontWeight={'500'}>Document Required: {currentClaimData.documentMessage}</Typography>
                      <IconButton onClick={handleIconClick} color="primary">
                        <FileUploadIcon />
                      </IconButton>
                      <input
                        type="file"
                        ref={fileInputRef}
                        onChange={hanldeFileChange}
                        style={{ display: 'none' }}
                      />
                    </div>
                    {selectedFile && (
                      <Typography fontWeight={'500'}>Selected File: {selectedFile.name}</Typography>
                    )}
                  </Grid>
                    <Grid item xs={12}>
                      <Button variant="contained" onClick={onClickFileUpload}>
                        Submit
                      </Button>
                    </Grid>
                  </>}
                  {claimType === 'Old' && <Grid item xs={12}>
                    <Typography fontWeight={'500'}>Claim Settled/Rejected Date: {configureDate(claim.settledOrRejectedDate)}</Typography>
                  </Grid>}
                </Grid>
              </Box>
            </Paper>
          </Container>
        ))}
        <Button variant="contained" style={{ margin: '0 0 0 1.5rem' }} onClick={() => setFormStep(1)}>Back</Button>
      </Container>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={modalStyle}
        >
          <Typography variant="h6" gutterBottom>
            Documents Uploaded Successfully!
          </Typography>
          <Button onClick={() => setModalOpen(false)} color="primary" variant="contained">
            OK
          </Button>
        </Box>
      </Modal>

    </>
  )
}
