import { PureComponent } from 'react'
import { Statistic, Icon, Header } from 'semantic-ui-react'
import { rupeeFormatter, isNotEmpty } from 'utils'
import DataLoader from 'components/DataLoader'
import { LoadingContext } from 'components/context'

class AnnuityPayout extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { index: 0 }
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange (e, data) {
    this.setState({ index: data.value })
  }

  render () {
    const { product, id, device, className, productData: { payoutFrequency }, expanded } = this.props
    const loading = this.context
    let premiumLoading = false
    if (isNotEmpty(loading)) {
      premiumLoading = loading.status
    }
    let label
    if (payoutFrequency === 'A' || payoutFrequency === 'S') {
      label = ' / Year'
    } else if (payoutFrequency === 'H') {
      label = ' / Half Year'
    } else if (payoutFrequency === 'Q') {
      label = ' / Quarter'
    } else if (payoutFrequency === 'M') {
      label = ' / Month'
    }

    const amountWithLabels = function amountWithLabels (amount) {
      const crore = 10000000
      const lakh = 100000
      const tho = 1000
      if (amount > crore) {
        return {
          amount: (amount / crore).toFixed(2),
          label: ' C'
        }
      } else if (amount >= lakh) {
        return {
          amount: (amount / lakh).toFixed(2),
          label: ' L'
        }
      } else {
        return {
          amount: (amount / tho).toFixed(0),
          label: ' K'
        }
      }
    }
    const annuityPayout = amountWithLabels(product.annuityPayout)
    const annuityAmount = annuityPayout.amount
    const annuityLabel = annuityPayout.label

    if (device === 'mobile') {
      return (
        <Statistic textAlign='right' color='green' className={`alignLeft ${className}`}>
          <Header sub color='black'>Annuity Payout</Header>
          <DataLoader
            loading={premiumLoading}
          >
            <Statistic.Value id={`${id}-premiumAmount`}>
              <Icon size='small' name='rupee' />
              {rupeeFormatter(annuityAmount)}{annuityLabel}{!expanded && label}
            </Statistic.Value>
          </DataLoader>
        </Statistic>
      )
    }
    return (
      <Statistic color='green' className={`alignLeft productPremium ${className}`}>
        <Statistic.Label>Annuity Payout</Statistic.Label>
        <DataLoader
          loading={premiumLoading}
        >
          <Statistic.Value id={`${id}-premiumAmount`}>
            <Icon size='small' name='rupee' />
            {rupeeFormatter(annuityAmount)}{annuityLabel}{label}
          </Statistic.Value>
        </DataLoader>
      </Statistic>
    )
  }
}
AnnuityPayout.contextType = LoadingContext
export default AnnuityPayout
