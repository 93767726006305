import service from '../../services/mfService'
import { mergeAndCategoriseProducts } from './helpers/productHelper'
/*
mfProducts structure
{
  "entities": {
    "products": {
      "product_id": {
        ...data
      }
    },
    "subCategories": {
        "subCategory_id": {
            ...data,
          "products": [
            "product_id"
          ]
        }
    },
    "categories": {
      "category_id": {
        ...data,
        "subCategories": [
          "subCategory_id"
        ]
      },
    },
  },
  "result": [
      "category_id"
  ]
}
*/

const mfProducts = {
  name: 'mfProducts',
  state: {
  },
  selectors: (slice, createSelector, hasProps) => ({
    getRiskPortfolios: (models) => {
      return createSelector(
        slice,
        rootState => rootState.configure,
        (state, props) => props,
        (mfProducts, configure, { portfolioId }) => {
          return models.configure.mfPortfolioConfig({ configure })
            .filter(pc => pc.portfilioId === portfolioId)
            .schemeIds
            .map(schemeId => mfProducts[schemeId])
        }
      )
    },
    getFundsByCategory: () => {
      return createSelector(
        slice,
        (state, props) => ({ mfConfigure: state.mfConfigure, categoryId: props.categoryId }),
        (mfProducts, { mfConfigure, categoryId }) => {
          const categorizedProducts = mergeAndCategoriseProducts(mfProducts, mfConfigure.listing.productCategories)
          const category = categorizedProducts.entities.categories[categoryId]
          return {
            ...category,
            subCategories: category.subCategories.map((subCategoryId) => mfProducts.categories.entities.subCategories[subCategoryId])
          }
        }
      )
    },
    getScheme: () => {
      return createSelector(
        slice,
        (state, props) => props.schemeId,
        (mfProducts, schemeId) => mfProducts.categories.entities.mfProducts[schemeId]
      )
    }
  }),
  reducers: {
    addFundScheme: (state, payload) => {

    },
    updateFundScheme: (state, payload) => {

    },
    updateProducts (state, payload) {
      const newState = state
      newState.categories = payload
      return newState
    }
  },
  effects: (dispatch) => ({
    async getAllProducts (payload, rootState) {
      const products = await service.getAllProducts(payload.partnerId)
      dispatch.mfProducts.updateProducts(products)
    }
  })
}
export default mfProducts
