import { JsonSchemaHelper } from 'components/jsonSchemaForms'
import { PincodeField } from 'components/jsonSchemaForms/fields'
import CountryWidget from 'components/jsonSchemaForms/widgets/CountryWidget'
import CustomDateWidget from 'components/jsonSchemaForms/widgets/CustomDateWidget'
import MobileNumberWidget from 'components/jsonSchemaForms/widgets/MobileNumberWidget'
import OtpVerificationWidget from 'components/jsonSchemaForms/widgets/OtpVerificationWidget/OtpVerificationWidget'
import { isNotEmpty } from 'utils/lib/basic'
import { dependency } from './schema'

let currentStepDependency
export const getSchemaForCurrentStep = (schema, Steps, selectedPath, currentStep) => {
  const stepInfo = Steps[selectedPath]?.steps[currentStep]
  const fieldsForStepInfo = stepInfo?.fields || []

  const properties = {}
  const requiredFields = []

  if (stepInfo && currentStep === 0) {
    fieldsForStepInfo.forEach((field) => {
      properties[field] = schema.properties[field]
    })

    requiredFields.push(...Object.keys(properties))
    if (selectedPath === 'Proposer') {
      const dependencies = JSON.parse(JSON.stringify(dependency))
      currentStepDependency = { ...dependencies }
      return {
        type: 'object',
        properties,
        dependencies,
        required: [...requiredFields]
      }
    } else if (selectedPath === 'Non-Proposer') {
      return {
        type: 'object',
        properties,
        required: [...requiredFields]
      }
    }
  } else if (currentStep === 1) {
    properties.authenticationRequired = schema.properties.authenticationRequired
    requiredFields.push(...Object.keys(properties))

    return {
      type: 'object',
      // title: schema.properties.authenticationRequired.title,
      properties,
      required: [...requiredFields]
    }
  } else if (currentStep === 2) {
    properties.claimIntimatationDetail = schema.properties.claimIntimatationDetail
    requiredFields.push(...Object.keys(properties.claimIntimatationDetail.properties))
    properties.claimIntimatationDetail.required = requiredFields

    return {
      type: 'object',
      properties
    }
  }

  // default claimIntimatedBy property
  properties.claimIntimatedBy = schema.properties.claimIntimatedBy

  return {
    type: 'object',
    title: stepInfo?.title || 'Claim Intimation',
    properties,
    required: ['claimIntimatedBy', ...requiredFields]
  }
}

export const getUISchemaForCurrentStep = (UiSchema, Steps, selectedPath, currentStep) => {
  const stepInfo = Steps[selectedPath]?.steps[currentStep]
  const fieldsForStepInfo = stepInfo?.fields || []
  let extractedFields = []
  const uiSchema = {}
  if (isNotEmpty(currentStepDependency)) {
    const property = JsonSchemaHelper.getNestedArrayOfObject(currentStepDependency, 'properties')
    extractedFields = JsonSchemaHelper.extractKeys(property, 'properties')
  }

  const stepFields = [...new Set([...fieldsForStepInfo, ...extractedFields])]

  if (stepInfo) {
    stepFields.forEach((field) => {
      if (field === 'claimIntimatationDetail') {
        uiSchema[field] = UiSchema[field]
        uiSchema[field].dateOfBirthOfLifeAssured['ui:widget'] = CustomDateWidget
        uiSchema[field].dateOfEvent['ui:widget'] = CustomDateWidget
        uiSchema[field].mobileNoOfClaimant['ui:widget'] = MobileNumberWidget
        uiSchema[field].csp['ui:field'] = PincodeField
        uiSchema[field].country['ui:widget'] = CountryWidget
      } else if (field === 'intimatorDetails') {
        uiSchema[field] = UiSchema[field]
        if (uiSchema[field].intimatorContactNumber) {
          uiSchema[field].intimatorContactNumber['ui:widget'] = MobileNumberWidget
        }
      } else if (field === 'verifyRegisteredMobileNo') {
        uiSchema[field] = UiSchema[field]
        uiSchema[field]['ui:widget'] = MobileNumberWidget
      } else if (field === 'authenticationRequired') {
        uiSchema[field] = UiSchema[field]
        uiSchema[field].otp['ui:widget'] = OtpVerificationWidget
      } else {
        uiSchema[field] = UiSchema[field]
      }
    })
  } else {
    // Handle the default claimIntimatedBy field here
    uiSchema.claimIntimatedBy = UiSchema.claimIntimatedBy
  }

  return uiSchema
}
