import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { adopt } from 'react-adopt'
import { DataAccessContext } from 'components/context'
import { Form, Button } from 'semantic-ui-react'
import { H5, Subtitle1 } from 'components/Header'
import { onboardComposer } from 'components/onboardElements'
import { calculateRecommendations, isNotEmpty, find, isNotDefined, getErrorsById, getCustomErrorMessage } from 'utils'
import { buildCommonImagePath } from 'components/buildImagePath'
import { validateFormData } from 'smartcovr_api/src/insuranceApi'
import MobileErrorDisplay from 'components/MobileErrorDisplay'
// import 'components/css/inlineForm.scss'
import produce from 'immer'
const TermInsuranceFormInline = (props) => {
  const {
    device,
    formType,
    configure,
    insuranceType
  } = props
  const [formData, setFormData] = useState(props.formData)
  const [errors, setErrors] = useState([])

  useEffect(() => {
    if (formData !== props.formData) {
      setFormData(props.formData)
    }
  },
  // eslint-disable-next-line
  [props.formData])

  const handleChange = (change) => {
    setFormData(produce(formData, draft => {
      draft = Object.assign(draft, change)
      if (isNotEmpty(errors)) {
        const validationErrors = validateFormData({
          insuranceType,
          formData: draft
        })
        setErrors(validationErrors.errors)
      }
    }))
  }

  const getErrorMessageByKey = (key) => {
    const { steps } = props.configure.onboard.inlineForm
    const config = find(steps, step => step.key === key)
    let validationErrors = []
    let title
    if (!isNotDefined(config) && !isNotDefined(config.errorDisplay)) {
      title = config.errorDisplay.title
      const { secondaryField } = config.errorDisplay
      validationErrors = getErrorsById(errors, key)
      if (isNotEmpty(secondaryField) && isNotEmpty(validationErrors) && validationErrors[0].keyword !== 'required') {
        validationErrors = getErrorsById(errors, secondaryField.key)
        title = secondaryField.title
      }
    }
    if (isNotEmpty(validationErrors)) {
      return getCustomErrorMessage(title, validationErrors[0])
    }
    return null
  }

  const onSubmit = () => {
    const { onSubmit, configure, insuranceType } = props
    const onboardConfig = configure.onboard
    const { steps } = onboardConfig.termInsuranceSteps
    // FIXME: to remove calculate reco from here
    const recommendedFormData = calculateRecommendations(formData, steps)
    const { sumAssured, coverTerm } = recommendedFormData
    const newFormData = {
      ...formData,
      coverTerm,
      sumAssured: Math.round(sumAssured)
    }
    const validationErrors = validateFormData({
      insuranceType,
      formData: newFormData
    })
    const arrayOfErrors = validationErrors.errors
    if (isNotEmpty(arrayOfErrors)) {
      setErrors(arrayOfErrors)
    } else {
      onSubmit(newFormData)
    }
  }

  const onboardConfig = configure.onboard
  const { steps } = onboardConfig.termInsuranceSteps
  const schema = onboardConfig.onboardSchema.schema
  const { title, description } = onboardConfig.inlineForm
  const inlineSteps = onboardConfig.inlineForm.steps
  const ComponentMap = onboardComposer({
    steps,
    handleChange,
    formType,
    schema,
    formData,
    validationErrors: errors,
    id: 'inline_form',
    getErrorMessageByKey,
    device,
    insuranceType,
    productData: props.productData
  })

  const formContent = (
    <div className='formWrapper'>
      <Form noValidate>
        {inlineSteps.map((step, index) => {
          const { key, displayText } = step
          let element = null
          if (displayText) {
            const displayStrings = step.displayText.split(`{${key}}`)
            element = (
              <H5 color='white'>
                {displayStrings[0]}
                {ComponentMap[key]}
                {displayStrings[1]}
              </H5>
            )
          } else {
            element = ComponentMap[key]
          }
          return (
            <React.Fragment
              key={index}
            >
              {element}
              {device === 'mobile' && (
                <MobileErrorDisplay
                  errorMessage={getErrorMessageByKey(key)}
                  id={key}
                />
              )}
            </React.Fragment>
          )
        })}
        <Button
          id='proceed_button'
          onClick={() => onSubmit(formData)}
          primary
          content='GET QUOTES'
        />
      </Form>
    </div>
  )

  return (
    <div id='onboardInlineDiv' className={`inlineform-outer ${device}`}>
      <div id='inlineform' className={`inlineform ${device}`}>
        <H5 color='white' id='title'> {title} </H5>
        <Subtitle1 color='white' id='description'> {description} </Subtitle1>
        {(device === 'mobile') && formContent}
        {(device !== 'mobile') && (
          <div className='content flexContainer'>
            {formContent}
            <div className='backgroundImage'>
              <img src={buildCommonImagePath('sc-family-bg.png')} alt='' />
            </div>
          </div>)}
      </div>
    </div>
  )
}
TermInsuranceFormInline.propTypes = {
  device: PropTypes.string,
  configure: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}
const WithContext = adopt({
  dataAccess: <DataAccessContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ dataAccess: { onSubmit, ...restDataAccess } }) => (
        <TermInsuranceFormInline
          {...props}
          onSubmit={onSubmit}
          formType={restDataAccess.formType}
          device={restDataAccess.device}
          formData={restDataAccess.formData}
          configure={restDataAccess.configure}
          insuranceType={restDataAccess.insuranceType}
        />
      )
    }
  </WithContext>
)
export default WrapperComponent
