import DisplayHtmlString from 'components/DisplayHtmlString'
import { Body2 } from 'components/Header'
// used in step form
const style = {
  mainDiv: {
    maxWidth: 300,
    margin: 'auto',
    marginBottom: 40
  }
}
export const StepDescription = (props) => {
  return (
    <div style={style.mainDiv}>
      <Body2 color='grey' textAlign='center'>
        <DisplayHtmlString htmlContent={props.content} />
      </Body2>
    </div>
  )
}
export default StepDescription
