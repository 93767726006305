import axios from 'axios'
import format from 'string-format'
// import countries from './mockData/countries.json'

const env = process.env.NODE_ENV
const appEnv = process.env.REACT_APP_BUILD_TYPE
const envMode = env === 'development' ? env : appEnv

const configMapper = {
  development: {
    insuranceConfigService: 'insurance/configure',
    smartcovrConfig: {
      apiKey: 'dummy',
      client: 'one_access_admin',
      configType: 'config',
      configService: 'configure',
      env: envMode,
      host: 'uat.smartcovr.io',
      serverUrl: 'http://localhost:3032',
      getHeaders: function () {
        return {} // applicable only on non-development servers
      }
    }
  },
  uat: {
    insuranceConfigService: 'insurance-configure/1.0',
    smartcovrConfig: {
      apiKey: 'a6a53c92-60cd-46dc-956f-ce83252b6ff5',
      configService: 'configure/1.0',
      configType: 'config',
      clientId: 'Finclusion',
      clientSecret: 'a',
      client: 'one_access_admin',
      env: envMode,
      host: window.location.host.includes('localhost') ? 'uat.smartcovr.io' : window.location.host,
      serverUrl: 'https://apigateway.smartcovr.io/apiman-gateway/smartcovr',
      getHeaders: function () {
        return { Authorization: 'Basic ' + window.btoa(`${this.clientId}:${this.clientSecret}`) }
      }
    }
  }
}

const baseConfig = envMode === 'development' ? configMapper.development : configMapper.uat

export const fetchConfig = async () => {
  const url = '{serverUrl}/{configService}/distributor?configType={configType}&host={host}&apikey={apiKey}&client={client}&env={env}'
  const { getHeaders, ...restConfig } = baseConfig.smartcovrConfig
  const formattedUrl = format(url, restConfig)
  try {
    const { data: config } = await axios.get(formattedUrl, {
      headers: getHeaders.call(baseConfig.smartcovrConfig)
    })

    const { apiVersion, clientId, clientSecret, realm, apiKeyWithOAuth, authApiKey, serverUrl, fileUrl, uploadUrl, distributorId, distributorName, theme, countries, ownCustomerLogin } = config
    const uploadUrlformatter = {
      institutionId: '{institutionId}',
      iamId: '{iamId}',
      category: '{category}',
      natureOfFile: '{natureOfFile}',
      distributorId: '{distributorId}',
      authApiKey,
      serverUrl
    }
    const trudeskIp = '' // Used in agentService
    const truedeskPort = '' // Used in agentService
    return {
      apiVersion,
      clientId,
      uploadUrl: format(uploadUrl, uploadUrlformatter),
      fileUrl: format(fileUrl, { serverUrl, fileUuid: '{fileUuid}', authApiKey }),
      serverUrl,
      distributorId,
      distributorName,
      host: window.location.host,
      theme,
      countries,
      verifyServiceUrl: (env === 'development')
        ? `${serverUrl}/verify`
        : `${serverUrl}/verify/${apiVersion}?apikey=${apiKeyWithOAuth}`,
      apiman: {
        grantType: 'client_credentials',
        clientId,
        clientSecret,
        apikey: apiKeyWithOAuth,
        iamUrl: `https://iam2${appEnv === 'staging' ? 'stage' : ''}${appEnv === 'production' ? 'prod' : ''}.smartcovr.io/realms/{realm}/protocol/openid-connect/token`,
        realm
      },
      ownCustomerLogin,
      ownLogin: {
        realm: 'oneaccess',
        clientId,
        authPath: '/auth'
      },
      tptLogin: {
        clientId,
        tptLoginUrl: (env === 'development') ? `${serverUrl}/tptlogin?apikey=${authApiKey}` : `${serverUrl}/tptlogin/${apiVersion}?apikey=${authApiKey}`,
        tptcustomerUrl: `${serverUrl}/tptcustomer?apiKey=${authApiKey}`,
        tptLoginRedirect: (env === 'development') ? `http://localhost:3027/tptloginredirect?apikey=${apiKeyWithOAuth}` : `${window.location.origin}/tptloginredirect?apikey=${apiKeyWithOAuth}`
      },
      tptCustomerLogin: {
        clientId,
        tptLoginUrl: (env === 'development') ? `${serverUrl}/tptlogin?apikey=${authApiKey}` : `${serverUrl}/tptlogin/${apiVersion}?apikey=${authApiKey}`,
        tptLoginRedirect: (env === 'development') ? `http://localhost:3027/tptcustomertoken?apikey=${apiKeyWithOAuth}` : `${serverUrl}/tptcustomertoken?apikey=${apiKeyWithOAuth}`
      },
      keycloakTptLogin: {
        clientId: 'agent',
        tptLoginUrl: (env === 'development') ? `${serverUrl}/tptlogin?apikey=${authApiKey}` : `${serverUrl}/tptlogin/${apiVersion}?apikey=${authApiKey}`,
        keycloakTptLoginRedirect: (env === 'development') ? `${serverUrl}/tpttoken?apikey=${apiKeyWithOAuth}` : `${window.location.origin}/tpttoken?apikey=${apiKeyWithOAuth}`
      },
      authUrl: serverUrl, // only for development (although the development server for redirectToLogin is not used, but code exists for it)
      schemaDisplay: true,
      filterDisplay: 'all', // set to 'inSchema' only products in schema has to be displayed.
      employerId: 'IN-9999800', // Used in agentService
      trudeskIp, // Used in agentService
      truedeskPort, // Used in agentService
      taskSystemEndpoint: `http://${trudeskIp}:${truedeskPort}/api/v1`,
      dummyIamId: '2a514f7d-848c-4678-9ab2-3fe141b500e6', // used in agentService
      scCookie: {
        name: 'saCookie',
        path: '/',
        maxAge: 2592000 // in seconds
      },
      redirectCookie: {
        name: 'redirect',
        path: '/',
        maxAge: 120 // in seconds
      },
      premiumPaymentUrls: {
        'IN-9839': 'https://lifeinsurance.adityabirlacapital.com/',
        'IN-15472': 'https://onlineinsurance.hdfclife.com/',
        'IN-15658': 'https://www.tataaia.com/'
      }
    }
  } catch (e) {
    console.log('Error Fetching Distributor config')
    console.log(e)
  }
}

/**
 * Get Distributor Based Product types i.e 'car' 'bike', 'term' etc...
 * @param {*} config
 * @param {*} accessToken
 * @param {*} distributorId
 * @returns
 */
export const getDistributorProductTypes = async (config, accessToken, distributorId) => {
  let headers = { client: config.apiman.clientId }
  const serverUrl = config.serverUrl
  const { insuranceConfigService } = baseConfig
  const url = `${serverUrl}/${insuranceConfigService}/productList`
  if (env === 'production') {
    headers = {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${accessToken}`,
      ...headers
    }
  }
  try {
    const response = await axios({
      url,
      method: 'GET',
      headers,
      params: {
        distributorId,
        apikey: config.apiman.apikey
      }
    })
    return response.data
  } catch (err) {
    console.log(err.message)
    throw err
  }
}
