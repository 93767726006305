import { Icon } from 'semantic-ui-react'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { Body1, Caption, Subtitle2 } from 'components/Header'
import { rupeeFormatter } from 'utils'

const RiderDetailContent = ({ rider, device }) => {
  if (device === 'mobile') {
    return (
      <>
        {rider && rider.length !== 0 && (
          <div>
            <Body1 color='primaryLight' className='titleHeader'>
              Rider Details
            </Body1>
            <div className='riderData'>
              {rider.map((rider, index) => (
                <div key={index} className='riderContentBody'>
                  <div className='riderNameAndDescription'>
                    <Subtitle2>
                      {rider.riderName}
                    </Subtitle2>
                    <Caption>
                      <DisplayHtmlString htmlContent={rider.riderDescription} />
                    </Caption>
                  </div>
                  <div className='sumAssuredAndPremiumDiv'>
                    {(![null, 0].includes(rider.riderSumAssured)) &&
                      <div className='riderValues'>
                        <Caption>
                          Sum Assured
                        </Caption>
                        <Body1 color='green' className='iconAndAmount'>
                          <Icon size='small' name='rupee' />
                          <span className='amount'>{rupeeFormatter(rider.riderSumAssured)}</span>
                        </Body1>
                      </div>}
                    {(![null, 0].includes(rider.riderSumAssured)) &&
                      <div className='riderValues'>
                        <Caption>
                          Premium
                        </Caption>
                        <Body1 color='green' className='iconAndAmount'>
                          <Icon size='small' name='rupee' />
                          <span className='amount'>{rupeeFormatter(rider.premiumWithGst ?? rider.premium)}</span>
                        </Body1>
                      </div>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {rider && rider.length !== 0 && (
        <div>
          <Body1 color='primaryLight' className='titleHeader'>
            Rider Details
          </Body1>
          <div className='riderData'>
            {rider.map((rider, index) => (
              <div key={index} className='riderContentBody'>
                <div className='riderNameAndDescription'>
                  <Subtitle2>
                    {rider.riderName}
                  </Subtitle2>
                  <Caption>
                    <DisplayHtmlString htmlContent={rider.riderDescription} />
                  </Caption>
                </div>
                {(![null, 0].includes(rider.riderSumAssured)) &&
                  <div className='riderValues'>
                    <Caption>
                      Sum Assured
                    </Caption>
                    <Body1 color='green' className='iconAndAmount'>
                      <Icon size='small' name='rupee' />
                      <span className='amount'>{rupeeFormatter(rider.riderSumAssured)}</span>
                    </Body1>
                  </div>}
                {(![null, 0].includes(rider.premium)) &&
                  <div className='riderValues'>
                    <Caption>
                      Premium
                    </Caption>
                    <Body1 color='green' className='iconAndAmount'>
                      <Icon size='small' name='rupee' />
                      <span className='amount'>{rupeeFormatter(rider.premiumWithGst ?? rider.premium)}</span>
                    </Body1>
                  </div>}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )
}
export default RiderDetailContent
