import { useState } from 'react'
import { Icon } from 'semantic-ui-react'
import { Body1, H6, Subtitle2 } from 'components/Header'
import { rupeeFormatter } from 'utils'

// import { mockPreviousPremiums } from '../DummyData'
import PolicyPremiumContent from './PolicyPremiumContent'

import './policyDetailsMainPage.scss'

const PayAmountBody = ({ amount }) => {
  return (
    <div className='payAmount'>
      <Body1 color='green' className='body1'>
        <Icon size='small' name='rupee' />
        <H6 color='green'>{rupeeFormatter(Math.round(amount))}</H6>
      </Body1>
    </div>
  )
}

export const PreviousPremiumsForPolicy = (props) => {
  const { previousPremiums } = props
  const [viewMore, setViewMore] = useState(false)
  const handleViewMoreDetails = () => {
    setViewMore(!viewMore)
  }
  const previousPremiumData = []
  const morePreviousPremiumData = []
  previousPremiums.forEach((premiumData, index) => {
    if (index < 3) {
      previousPremiumData.push(
        <div key={`previousPremiumData-${index}`} className='premiumDataContent'>
          <PolicyPremiumContent data={premiumData} />
          <PayAmountBody amount={premiumData.amount} />
        </div>
      )
    } else {
      morePreviousPremiumData.push(
        <div key={`morePreviousPremiumData-${index}`} className='premiumDataContentVm'>
          <PolicyPremiumContent data={premiumData} />
          <PayAmountBody amount={premiumData.amount} />
        </div>
      )
    }
  })
  let content
  if (previousPremiumData.length === 0) {
    content = (
      <div className='noOngoingPolicies'>
        <Subtitle2 color='secondary'>No Previous Premiums</Subtitle2>
      </div>
    )
  } else {
    content = (
      <>
        {previousPremiumData}
        {viewMore && morePreviousPremiumData}
        {!viewMore && morePreviousPremiumData.length > 0 && (
          <div className='row dataButton premiumDataContent'>
            <span onClick={handleViewMoreDetails}>+ View More Premiums</span>
          </div>
        )}
        {viewMore && morePreviousPremiumData.length > 0 && (
          <div className='row dataButton premiumDataContent'>
            <span onClick={handleViewMoreDetails}>- View Less Premiums</span>
          </div>
        )}
      </>
    )
  }
  return (
    <div className='previousPremiumForPolicy'>
      <H6 color='primaryLight'>Previous Premiums</H6>
      {content}
    </div>
  )
}
