import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material'

function ApplicationDetails (props) {
  const { applications, setApplicationStatus } = props
  const application = [applications]
  return (
    <Container>
    <Typography style={{ padding: '1rem 0 1.5rem 1.5rem' }} variant='h4'>{'Application Details'}</Typography>
    {application.map((claim, idx) => (
      <Container key={idx} style={{ marginBottom: '2rem' }}>
        <Paper elevation={3}>
          <Box p={2}>
            <Typography variant="h5" fontWeight={'bold'} style={{ paddingBottom: '1rem' }} >Policy Information</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={'500'}>Claim Policy Number :  {claim.policyNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={'500'}>Policy Number: {claim.policyNumber}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={'500'}>Claim Intimation Date: {claim.claim}</Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <Typography fontWeight={'500'}>Claim Submission/Registration Date: {configureDate(claim.claimRegistrationDate)}</Typography> */}
              </Grid>
              <Grid item xs={12}>
                <Typography fontWeight={'500'}>Claim Status: {claim.claimStatus}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Container>
    ))}
      <Button variant="contained" style={{ margin: '0 0 0 1.5rem' }} onClick={() => setApplicationStatus(false)}>Back</Button>
    </Container>
  )
}

export default ApplicationDetails
