import { Checkbox } from 'semantic-ui-react'

function selectValue (value, selected, all) {
  const at = all.indexOf(value)
  const updated = selected.slice(0, at).concat(value, selected.slice(at))
  return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b)).filter(v => v !== 'none')
}

function deselectAllValues (value, selected) {
  return selected.filter(v => v === value).length === 0 ? [value] : []
}

function deselectValue (value, selected) {
  return selected.filter(v => v !== value)
}

const defaultStyle = {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '1rem'
}

function DiseaseWidget (props) {
  const { id, disabled, options, value, autofocus, readonly, onChange, formContext } = props
  const { enumOptions } = options
  const isWeb = formContext.device === 'web'
  return (
    <div className='checkboxes' id={id} style={isWeb ? defaultStyle : {}}>
      {enumOptions.map((option, index) => {
        const checked = value.indexOf(option.value) !== -1
        const disabledCls = disabled || readonly ? 'disabled' : ''
        const checkbox = (
          <Checkbox
            id={`${id}_${index}`}
            checked={checked}
            disabled={disabled || readonly}
            autoFocus={autofocus && index === 0}
            onChange={(event, { checked }) => {
              const all = enumOptions.map(({ value }) => value)
              if (checked && option.value !== 'none') {
                onChange(selectValue(option.value, value, all))
              } else if (checked && option.value === 'none') {
                onChange(deselectAllValues(option.value, value))
              } else {
                onChange(deselectValue(option.value, value))
              }
            }}
            label={option.label}
          />
        )
        return isWeb
          ? (
            <label key={index} className={`checkbox-inline ${disabledCls}`}>
              {checkbox}
            </label>
            )
          : (
            <div key={index} className={`checkbox ${disabledCls}`}>
              <label>{checkbox}</label>
            </div>
            )
      })}
    </div>
  )
}

export default DiseaseWidget
