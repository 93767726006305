import UAParser from 'ua-parser-js'
const userAgent = {
  name: 'userAgent',
  state: {},
  selectors: (slice) => ({

    /**
     * Returns components from state
     *
     * @returns
     */
    getComponents () {
      return slice(userAgent => userAgent.components)
    },

    /**
     * Returns fingerPrint from state
     *
     * @returns
     */
    getFingerPrint () {
      return slice((userAgent) => userAgent.fingerPrint)
    },

    /**
     * Returns browser from state
     *
     * @returns
     */
    getBrowser () {
      return slice((userAgent) => userAgent.browser)
    }
  }),
  reducers: {

    /**
     * Sets browser, fingerPrint, Modernizr and components in the state
     *
     * @param {*} state
     * @param {*} { fingerPrint, components, Modernizr, userAgentString }
     * @returns object
     */
    'customer/initializeCustomer' (state, { fingerPrint, components, Modernizr, userAgentString }) {
      const parser = new UAParser(userAgentString)
      state.browser = parser.getBrowser()
      state.fingerPrint = fingerPrint
      state.components = components
      state.Modernizr = Modernizr
      return state
    },

    /**
     * Sets Modernizr in the state
     *
     * @param {*} state
     * @param {*} { Modernizr }
     * @returns object
     */
    setModernizr (state, { Modernizr }) {
      state.Modernizr = Modernizr
      return state
    }
  },
  effects: () => ({
    updateUserAgentData () {
      // Dont know what we will do here. Probably some modernizr details perhaps.
      // If we use geolocation and other details, probably populate here.
    }
  })

}
export default userAgent
