import produce, { finishDraft } from 'immer'
import { isNotEmpty, isNotDefined, findIndex, cloneDeep } from 'utils'
import { updateEnquiryToDb } from '../../services/commonService'
import {
  reduceRootStateDraft,
  reduceRootStateUpdated,
  getQuoteForInstitutionProducts,
  getQuoteForProduct,
  loadProductForInsuranceType,
  loadProductById,
  loadFeaturesForInsuranceType,
  runChosenProductRules,
  getQuoteForProductValidatedByPptOption,
  checkAndFinishDraft,
  getMatchingPaymentOption
} from './modelHelpers'
import c2ppRules from '../../templates/c2ppRules.json'
import srRules from '../../templates/srRules.json'
import digishieldRules from '../../templates/digiShieldRules'
import { checkForBadRequest } from '../../errors'
import { configureFilters } from './helpers/filterHelper'
import {
  configureForDisplay,
  isProductFixedTerm,
  isProductWholeLife
} from './helpers/productHelper'
import chosenProductMap from '../../templates/chosenProductMap.json'
const rulesMap = {
  'IN-15472': c2ppRules,
  'IN-9839': digishieldRules,
  'IN-15658': srRules
}
/* eslint no-prototype-builtins: 0 */
/* eslint no-unused-vars: 0 */
/**
   * Runs the rules on chosen product by calling runChosenProductRules function
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setFeatureChoiceForChosenProduct = async (dispatch, payload, draftRootState) => {
  // FIXME: incorporate new chosenProduct structure ! ! !
  checkForBadRequest(['schemaKey', 'data', 'productId', 'productOptionId', 'insuranceType'], payload)
  const { schemaKey, data, productId, productOptionId, insuranceType } = payload
  const { insuranceProductsDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  const { chosenProduct } = insuranceProductsDraft
  if (!isNotEmpty(chosenProduct)) {
    // FIXME: Log Error here
    // FIXME: dispatch error
    dispatch.insuranceProducts.updateChosenProduct({ insuranceType, chosenProduct: {} })
  }
  if (chosenProduct.productId !== productId || chosenProduct.productOptionId !== productOptionId) {
    // FIXME: Error chosenProduct and product have diverged
    // FIXME: Dispatch error.
    dispatch.insuranceProducts.updateChosenProduct({ insuranceType, chosenProduct: {} })
  } else {
    delete chosenProduct.actions
    const { spProductData } = chosenProduct
    if (spProductData.hasOwnProperty(schemaKey)) {
      if (isNotEmpty(data)) {
        spProductData[schemaKey] = Object.assign({}, chosenProduct[schemaKey], data)
      }
    }
    const { institutionId } = chosenProduct
    const rules = rulesMap[institutionId]
    await runChosenProductRules(chosenProduct, rules)
    const updatedRootState = checkAndFinishDraft(draftRootState)
    const { insuranceProductsUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
    dispatch.insuranceProducts.updateChosenProduct({ insuranceType, chosenProduct: insuranceProductsUpdated.chosenProduct })
  }
}

/**
   * Sets chosenProduct in enquiry object. The enquiry is then saved in db and passed in reducer
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setChosenProduct = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'productOptionId', 'institutionId'], payload)
  const { insuranceType, productOptionId, insurerId, institutionId, bookingReload } = payload
  const { insuranceProductsDraft, insuranceEnquiryDraft, insuranceConfigureDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  const { paymentOption } = insuranceEnquiryDraft.productData
  const { policyTerm } = insuranceEnquiryDraft.formData
  const mapper = chosenProductMap // list of productOptions to be shown on product config page
  const productOptions = mapper[insurerId]
  // FIXME: temporary hack !
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority

  delete insuranceEnquiryDraft.productData.payoutType
  // chosenProduct for insuranceProducts
  // FIXME: merging formData - eventually to be done in the backend
  // If product is life long, then we need to manage ppt ( it will be like limited pay in the guise of regular pay) for selection.
  insuranceProductsDraft.chosenProduct = {
    chosenProductFormData: insuranceEnquiryDraft.formData,
    chosenProductInsurerId: insurerId,
    chosenProductOptionsDetails: [],
    product: {}
  }
  // FIXME: following is a hack !!
  const bookingDefaults = insuranceConfigureDraft.productConfig.bookingDefaults[institutionId]
  if (isNotEmpty(bookingDefaults) && !bookingReload) {
    insuranceProductsDraft.chosenProduct.chosenProductFormData.insured = bookingDefaults.insured
  }
  let enquiryChosenProduct = {} // selected product for insuranceEnquiry
  const findAndSetChosenProductDetails = (id) => {
    const flattenedProduct = insuranceProductsDraft.plainProducts.find(sp => sp.productOption.insurerId === id)
    if (!isNotDefined(flattenedProduct)) {
      const { finanalyticsId, productId, productOption: { productName, productOptionBasics: { productOptionDescription, productOptionName } } } = flattenedProduct
      const productDetails = {
        productOptionInsurerId: flattenedProduct.productOption.insurerId,
        productOptionId: flattenedProduct.productOption.insurerId,
        productId,
        institutionId: finanalyticsId,
        productOptionDescription,
        productOptionName,
        productInsurerId: flattenedProduct.insurerId,
        available: true
      }
      if (insurerId === id) {
        // setting chosenProduct in enquiry draft. same object to be updated to insuranceEnquiry
        enquiryChosenProduct = {
          productOptionInsurerId: id,
          productOptionId,
          productId,
          institutionId,
          productInsurerId: flattenedProduct.insurerId,
          productName
        }
        insuranceProductsDraft.chosenProduct.product = flattenedProduct
      }
      const chosenProductOptionsDetails = insuranceProductsDraft.chosenProduct.chosenProductOptionsDetails
      chosenProductOptionsDetails.push(productDetails)
      // FIXME: hack right here!! removed clone deep!!
      chosenProductOptionsDetails[chosenProductOptionsDetails.length - 1].spProductData = cloneDeep(insuranceEnquiryDraft.productData)
      const spProductData = chosenProductOptionsDetails[chosenProductOptionsDetails.length - 1].spProductData
      const matchedPaymentOption = getMatchingPaymentOption(flattenedProduct, paymentOption, paymentOptions)
      if (matchedPaymentOption !== insuranceEnquiryDraft.productData.paymentOption) {
        insuranceEnquiryDraft.productData.paymentOption = matchedPaymentOption
        spProductData.paymentOption = matchedPaymentOption
      }
      if (insuranceType === 'term') {
        spProductData.payoutType = flattenedProduct.productOption.pptOptions[insuranceEnquiryDraft.productData.paymentOption].defaultPayoutTerm

        // following code is to set incomePayout fields for default payout
        const defaultPayoutTerm = flattenedProduct.productOption.payoutTerms.find((pt) => pt.categoryId === spProductData.payoutType)
        if (defaultPayoutTerm && isNotEmpty(defaultPayoutTerm.dynamicFields)) {
          Object.assign(spProductData.incomePayout, defaultPayoutTerm.dynamicFields) // FIXME: to be done in the backend. Also has redundant values
        }
        if (isProductFixedTerm(flattenedProduct)) { // for fixed term product, coverterm is set to calculatedCoverTerm
          try {
            spProductData.ppt = flattenedProduct.productOption.pptOptions[spProductData.paymentOption || 'RP'].ppt.maxPpt
            spProductData.coverTerm = flattenedProduct.productOption.calculatedCoverTerm
          } catch (error) {
            console.log('ppt option not found !')
          }
        }
        if (isProductWholeLife(flattenedProduct)) {
          // for whole life products, ppt is set to ppt.maxPpt of the product
          try {
            spProductData.ppt = flattenedProduct.productOption.pptOptions[spProductData.paymentOption || 'RP'].ppt.maxPpt
            spProductData.coverTerm = 99
          } catch (error) {
            console.log('ppt option not found !')
          }
        }
      } else if (insuranceType === 'annuity') {
        spProductData.payoutType = flattenedProduct.productOption.pptOptions[insuranceEnquiryDraft.productData.paymentOption].defaultPayoutTerm
        spProductData.ppt = flattenedProduct.productOption.pptOptions[spProductData.paymentOption || 'SP'].ppt.maxPpt
      } else if (insuranceType === 'car') {
        const pptOptions = flattenedProduct.productOption.pptOptions[spProductData.paymentOption || 'SP']
        spProductData.ppt = pptOptions.ppt.maxPpt
        spProductData.policyTerm = policyTerm
        // Note: In case of car flow, if the vehicle needs inspection,
        // then we extract isBreakIn flag from the getQuotes response and add it in enquiry formData.
        // FIXME: following is a hack to add isBreakIn flag in enquiry formData, using the setChosenProduct function,
        //  need to find other place to update it to avoid complexity
        if (!isNotDefined(pptOptions.isBreakIn)) {
          insuranceEnquiryDraft.formData.isBreakIn = pptOptions.isBreakIn
        }
      } else {
        spProductData.ppt = flattenedProduct.productOption.pptOptions[spProductData.paymentOption || 'SP'].ppt.maxPpt
        spProductData.policyTerm = policyTerm
      }
    }
  }
  if (!isNotDefined(productOptions)) {
    productOptions.forEach((poInsurerId) => {
      findAndSetChosenProductDetails(poInsurerId)
    })
  } else {
    findAndSetChosenProductDetails(insurerId)
  }
  insuranceEnquiryDraft.chosenProduct = enquiryChosenProduct
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceProductsUpdated, insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  // We need to get all eligible features for this product.
  dispatch.insuranceProducts.updateChosenProduct({ insuranceType, chosenProduct: insuranceProductsUpdated.chosenProduct, enquiryChosenProduct })
}

/**
   * Updates the chosen product in enquiry with the updates values. available value is then set to true
   * or false. If quotes contains the product option for chosen product then available is set to true or
   * else false
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const updateChosenProductData = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'insurerId', 'institutionId', 'productOptionInsurerId'], payload)
  const { insuranceType, insurerId, institutionId, formData, schemaKey } = payload
  const { insuranceProductsDraft, insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  const productData = produce(payload.productData, (draft) => {
    // in case of payout term incomePayout - incomePeriodYear is a combo of two keys. it hs to be set back to those keys
    if (schemaKey === 'incomePayout') {
      const incomePeriodYear = draft.incomePayout.incomePeriodYear
      if (!isNotDefined(incomePeriodYear)) {
        const obj = JSON.parse(incomePeriodYear)
        draft.incomePayout.incomePeriod = obj.period
        draft.incomePayout.percentOfSumAssured = obj.percentOfSumAssured
      }
    }
  })
  // replace insuranceEnquiryDraft productData with chosenProduct productData and make api call
  const { chosenProduct: { chosenProductOptionsDetails, chosenProductInsurerId, chosenProductFormData } } = insuranceProductsDraft

  // if coverTerm is updated, then update it across all products.
  if (payload.productData && !isNotDefined(payload.productData.coverTerm)) {
    chosenProductOptionsDetails.forEach((cp) => {
      cp.spProductData.coverTerm = payload.productData.coverTerm
    })
  }
  // to check if insurerId is same as that of selected product !!
  const chosenProductOptionDetailsIndex = findIndex(chosenProductOptionsDetails, po => po.productOptionInsurerId === chosenProductInsurerId)
  const chosenProductOptionDetail = chosenProductOptionsDetails[chosenProductOptionDetailsIndex]
  if (isNotDefined(chosenProductOptionDetail)) {
    // FIXME: throw custom error !
    throw new Error('ERROR: Selected product option not found')
  }
  if (isNotEmpty(productData)) {
    insuranceEnquiryDraft.productData = Object.assign(chosenProductOptionDetail.spProductData, productData)
  } else {
    insuranceEnquiryDraft.productData = chosenProductOptionDetail.spProductData
  }
  if (isNotEmpty(formData)) {
    insuranceEnquiryDraft.formData = Object.assign(chosenProductFormData, formData)
  } else {
    insuranceEnquiryDraft.formData = chosenProductFormData
  }
  let flattenedProduct
  try {
    const flattenedProducts = await getQuoteForProductValidatedByPptOption(insuranceType, 'configure', institutionId, insurerId, {
      insuranceEnquiryDraft,
      insuranceFiltersDraft,
      insuranceConfigureDraft,
      insuranceProductsDraft
    })
    chosenProductOptionsDetails.forEach(poDetail => {
      if (poDetail.productOptionInsurerId === chosenProductInsurerId) {
        flattenedProduct = flattenedProducts.find(po => po.productOption.insurerId === chosenProductInsurerId)

        if (isNotDefined(flattenedProduct)) {
          // FIXME: throw custom error !
          throw new Error('Failed to load product option.')
        }
      } else {
        const currentPo = flattenedProducts.find(po => po.productOption.insurerId === poDetail.productOptionInsurerId)
        if (isNotDefined(currentPo)) {
          poDetail.available = false
        } else {
          poDetail.available = true
        }
      }
    })
    flattenedProduct = flattenedProducts.find(po => po.productOption.insurerId === chosenProductInsurerId)
  } catch (err) {
    console.log(err)
    throw err
  }

  // for paymentOpt disappearing bug
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  chosenProductOptionDetail.spProductData.paymentOption = getMatchingPaymentOption(flattenedProduct, insuranceEnquiryDraft.productData.paymentOption, paymentOptions)
  if (chosenProductOptionDetail.spProductData.paymentOption === 'RP') {
    chosenProductOptionDetail.spProductData.ppt = chosenProductOptionDetail.spProductData.coverTerm
    insuranceEnquiryDraft.productData = chosenProductOptionDetail.spProductData
  }

  insuranceProductsDraft.chosenProduct.chosenProductOptionsDetails[chosenProductOptionDetailsIndex].spProductData = insuranceEnquiryDraft.productData

  insuranceProductsDraft.chosenProduct.chosenProductFormData = insuranceEnquiryDraft.formData
  insuranceProductsDraft.chosenProduct.product = flattenedProduct
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceProductsUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  dispatch.insuranceProducts.updateChosenProduct({ insuranceType, chosenProduct: insuranceProductsUpdated.chosenProduct })
}

/**
   * Changes the data of chosenProduct in enquiry with new values from payload and
   * quotes response for that product
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const changeChosenProduct = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'insurerId'], payload)
  const { insuranceType, insurerId } = payload
  const { insuranceProductsDraft, insuranceProductsDraft: { chosenProduct }, insuranceEnquiryDraft, insuranceFiltersDraft, insuranceConfigureDraft } = reduceRootStateDraft(draftRootState, insuranceType)

  chosenProduct.chosenProductInsurerId = insurerId
  const newChosenProductOptionDetail = chosenProduct.chosenProductOptionsDetails.find(po => po.productOptionInsurerId === insurerId)
  insuranceEnquiryDraft.productData = newChosenProductOptionDetail.spProductData
  insuranceEnquiryDraft.formData = chosenProduct.chosenProductFormData

  insuranceEnquiryDraft.chosenProduct = {
    productOptionInsurerId: newChosenProductOptionDetail.productOptionInsurerId,
    productOptionId: newChosenProductOptionDetail.productInsurerId,
    productId: newChosenProductOptionDetail.productId,
    institutionId: newChosenProductOptionDetail.institutionId,
    ...newChosenProductOptionDetail
  }

  let flattenedProduct
  try {
    const flattenedProducts = await getQuoteForProduct(insuranceType, 'listing', newChosenProductOptionDetail.institutionId, newChosenProductOptionDetail.productInsurerId, {
      insuranceEnquiryDraft,
      insuranceFiltersDraft,
      insuranceConfigureDraft,
      insuranceProductsDraft
    }, insurerId)
    flattenedProduct = flattenedProducts.find(po => po.productOption.insurerId === insurerId)
    if (isNotDefined(flattenedProduct)) {
      flattenedProduct = {}
    }
  } catch (err) {
    console.log(err)
    throw err
  }
  chosenProduct.product = flattenedProduct
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceProductsUpdated, insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  // We need to get all eligible features for this product.
  dispatch.insuranceProducts.updateChosenProduct({ insuranceType, chosenProduct: insuranceProductsUpdated.chosenProduct, enquiryChosenProduct: insuranceEnquiryUpdated.chosenProduct })
}

/**
   * groups the array of products based on the institution id inside an object
   *
   * @param {*} productsArray
   * @returns object
   */
const groupProductsByInstitutionId = (productsArray) => {
  const productsObject = productsArray.reduce((obj, product) => {
    const { finanalyticsId } = product
    if (!obj.hasOwnProperty(finanalyticsId)) {
      obj[finanalyticsId] = []
    }
    obj[finanalyticsId].push(product)
    return obj
  }, {})
  return productsObject
}

/**
   * Gets product for insurance type and passes the response in groupProductsByInstitutionId function
   * then the response of that function is passed into storeProductsData reducer.
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadProducts = async (dispatch, { insuranceType }, draftRootState) => {
  const distributorId = draftRootState.configure.distributorId
  const allProducts = await loadProductForInsuranceType({
    distributorId,
    insuranceType
  })
  const products = groupProductsByInstitutionId(allProducts)
  dispatch.insuranceProducts.storeProductsData({ products, insuranceType })
}

/**
   * Gets the product by id and passed in the groupProductsByInstitutionId function the response of that
   * function is them passed to store product data reducer.
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const loadChosenProducts = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'productInsurerId'], payload)
  const distributorId = draftRootState.configure.distributorId
  const { insuranceType, productInsurerId } = payload
  // error is handled in the called function
  const chosenProducts = await loadProductById({
    productInsurerId,
    distributorId,
    insuranceType
  })
  const products = groupProductsByInstitutionId(chosenProducts)
  dispatch.insuranceProducts.storeProductsData({ products, insuranceType })
}

/**
   * Calls loadFeaturesForInsuranceType API to get payouts, features, featureContent and passes all
   * the data in storeFeaturesData reducer.
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const loadFeatures = async (dispatch, { insuranceType }, draftRootState) => {
  const distributorId = draftRootState.configure.distributorId
  const { payouts, features, featureContent } = await loadFeaturesForInsuranceType({
    distributorId,
    insuranceType
  })
  dispatch.insuranceProducts.storeFeaturesData({ payouts, features, insuranceType, featureContent })
}

/**
   * This function fetches the quote by product id or insutution id based on the availability
   * of chosenProductMetaData and then creates a flattened products
   *
   * @param {*} dispatch
   * @param {*} { insuranceType, currentFormData, all }
   * @param {*} draftRootState
   * @param {*} currentStateModifier
   * @returns
   */
const updateProductsWithQuotes = async (dispatch, { insuranceType, currentFormData, all, typeOfQuote }, draftRootState, currentStateModifier) => {
  // all - when set to true, all products are loaded irrespetive of the fact that chosenProduct is there in enquiry

  const { insuranceFiltersDraft, insuranceConfigureDraft, insuranceProductsDraft, insuranceEnquiryDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  // We need to set enquiry and dispatch enquiry and fetch quotes
  const {
    productConfig: { filterConfig, insurers },
    issuerProductConfig
  } = insuranceConfigureDraft
  const { plainProducts } = insuranceProductsDraft
  // to move the below two functions out if required
  const getAndSetFlattenedProductsById = async (institutionId, productInsurerId) => {
    return new Promise(async (resolve) => {
      try {
        // If there are products, then there might be a case of changes formData or riders
        // that can be calculated inline or go to server.
        const { quoteRefreshRules } = issuerProductConfig[institutionId]
        // Prepare data for rules to run.
        const newFormData = Object.assign({}, insuranceEnquiryDraft.formData, {
          featureChange: false // insuranceFilters does not influence feature change
        })
        const quoteData = {
          currentFormData,
          newFormData,
          insurerId: productInsurerId
        }
        // const refreshProcess = await processRefreshRules(quoteRefreshRules.formData, quoteData)
        // refreshProcess === 'server'
        if (true) {
          // FIXME: Ideally can get from server for only this product
          // but this will take time to refresh.
          await currentStateModifier(async (currentStateDraft) => {
            const productsDraft = currentStateDraft[insuranceType]
            try {
              productsDraft.flattenedProducts = await getQuoteForProduct(insuranceType, typeOfQuote, institutionId, productInsurerId, {
                insuranceEnquiryDraft,
                insuranceFiltersDraft,
                insuranceConfigureDraft,
                insuranceProductsDraft: productsDraft
              })
            } catch (err) {
              if (err.name === 'TokenExpiredError') {
                dispatch.errors.setHooksError(err)
              }
              productsDraft.flattenedProducts = []
              // FIXME: handle this !!!
              // Also, something to be done to handle errors else products shows loading all the time
              // throw err
              // FIXME: change mechanism for logging errors
              console.log('Failed to get products for Institution Id:', institutionId, 'and productInsurerId:', productInsurerId)
            }
            const updatedCurrentState = checkAndFinishDraft(currentStateDraft)
            dispatch.insuranceProducts.loadQuotedProducts({
              insuranceType,
              flattenedProducts: updatedCurrentState[insuranceType].flattenedProducts,
              insurerId: productInsurerId,
              institutionId,
              formData: insuranceEnquiryDraft.formData
            })
          })
        } else {
          // No new product from db. We are only changing existing.
          // So produce not needed i guess as this will be pushed into an array.
          await currentStateModifier(async (currentStateDraft) => {
            const productsDraft = currentStateDraft[insuranceType]
            productsDraft.flattenedProducts = []
            const products = productsDraft.plainProducts.filter(fp => fp.finanalyticsId === institutionId)
            for (let prod = 0; prod < products.length; prod++) {
              const product = products[prod]
              const configuredProduct = configureFilters(product, insuranceFiltersDraft.filterData, filterConfig.filterPriority)
              try {
                const flatProduct = await configureForDisplay(
                  configuredProduct,
                  insuranceEnquiryDraft.formData,
                  insuranceEnquiryDraft.productData
                )
                productsDraft.flattenedProducts.push(flatProduct)
              } catch (err) {
                throw err
              }
            }
            const updatedCurrentState = finishDraft(currentStateDraft)
            dispatch.insuranceProducts.loadQuotedProducts({
              insuranceType,
              flattenedProducts: updatedCurrentState[insuranceType].flattenedProducts,
              institutionId
            })
          })
        }
      } finally {
        resolve()
      }
    })
  }

  const getAndSetFlattenedProductsByInstitution = (institutionId) => {
    return new Promise(async (resolve) => {
      try {
        // First time, when there is no data, then go fetch from server
        if (plainProducts.length === 0) {
          await currentStateModifier(async (currentStateDraft) => {
            const productsDraft = currentStateDraft[insuranceType]
            try {
              productsDraft.flattenedProducts = await getQuoteForInstitutionProducts(insuranceType, typeOfQuote, institutionId, {
                insuranceEnquiryDraft,
                insuranceFiltersDraft,
                insuranceConfigureDraft,
                insuranceProductsDraft: productsDraft
              })
            } catch (err) {
              if (err.name === 'TokenExpiredError') {
                dispatch.errors.setHooksError(err)
              }
              // FIXME: change mechanism for logging errors
              console.log('Failed to get products for Institution Id', institutionId)
              // throw err
            }
            const updatedCurrentState = checkAndFinishDraft(currentStateDraft)
            dispatch.insuranceProducts.loadQuotedProducts({
              insuranceType,
              flattenedProducts: updatedCurrentState[insuranceType].flattenedProducts,
              institutionId,
              formData: insuranceEnquiryDraft.formData
            })
          })
        } else {
          const institutionProducts = insurers[institutionId]
          const promisesArray = []
          for (let instProd = 0; instProd < institutionProducts.length; instProd++) {
            // FIXME: make use of async.
            const { insurerId } = institutionProducts[instProd]
            promisesArray.push(getAndSetFlattenedProductsById(institutionId, insurerId))
            try {
              await Promise.all(promisesArray)
            } catch (err) {
              console.log(err)
            }
          }
        }
      } finally {
        resolve()
      }
    })
  }
  const chosenProductMetaData = insuranceEnquiryDraft.chosenProduct

  // all - when set to true, all products are loaded irrespetive of the fact that chosenProduct is there in enquiry
  if (!all && isNotEmpty(chosenProductMetaData)) {
    const { institutionId, productInsurerId } = chosenProductMetaData
    await getAndSetFlattenedProductsById(institutionId, productInsurerId)
  } else {
    const institutions = Object.keys(insurers)
    const promisesArray = []
    for (let ins = 0; ins < institutions.length; ins++) {
      const institutionId = institutions[ins]
      promisesArray.push(getAndSetFlattenedProductsByInstitution(institutionId))
    }
    try {
      await Promise.all(promisesArray)
    } catch (err) {
      console.log(err)
    }
  }
}

/**
   * This function resets the chosenProduct by setting it to empty object and then calls the API
   * to update it in the mongodDB and then calls the reducer
   *
   * @param {*} dispatch
   * @param {*} { insuranceType }
   * @param {*} draftRootState
   * @returns
   */
const resetChosenProduct = async (dispatch, { insuranceType }, draftRootState) => {
  const { insuranceEnquiryDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  insuranceEnquiryDraft.chosenProduct = {}
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // root state ends here
  const { insuranceEnquiry: { enquiryId }, insuranceEnquiry } = updatedRootState
  if (isNotEmpty(enquiryId)) {
    await updateEnquiryToDb(insuranceEnquiry[insuranceType], insuranceType, enquiryId)
  }
  dispatch.insuranceProducts.removeChosenProduct({ insuranceType })
}

/**
   * This function sets the productData, formdata, currentStep, bookingStepIndex in applicationData in the
   * enquiry
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setChosenProductForBooking = async (dispatch, payload, draftRootState) => {
  // FIXME: Remove this soon! deal with this in loadBookingConfiguration
  checkForBadRequest(['insuranceType'], payload)
  const { insuranceType } = payload
  const { insuranceProductsDraft, insuranceEnquiryDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  const { chosenProductInsurerId, chosenProductOptionsDetails, chosenProductFormData } = insuranceProductsDraft.chosenProduct
  const chosenProductOption = chosenProductOptionsDetails.find(po => po.productOptionInsurerId === chosenProductInsurerId)
  if (!isNotDefined(chosenProductOption)) {
    insuranceEnquiryDraft.productData = chosenProductOption.spProductData
    insuranceEnquiryDraft.formData = chosenProductFormData
  }
  const applicationData = insuranceEnquiryDraft.application[chosenProductOption.institutionId]
  if (isNotEmpty(applicationData)) {
    applicationData.currentStep = 0
    applicationData.bookingStepIndex = 0
  }
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  try {
    await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  } catch (err) {
    throw err // suitable error thrown from the called function
  }
  dispatch.insuranceEnquiry.updateEnquiry({ insuranceType, insuranceEnquiry: insuranceEnquiryUpdated })
}
export {
  setFeatureChoiceForChosenProduct,
  setChosenProduct,
  loadProducts,
  loadChosenProducts,
  updateProductsWithQuotes,
  resetChosenProduct,
  loadFeatures,
  updateChosenProductData,
  changeChosenProduct,
  setChosenProductForBooking
}
