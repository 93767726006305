import { useEffect } from 'react'
import {
  useGlobalState,
  useAction,
  useSelector
} from './useReduxFunctions'
import { isNotEmpty, isNotDefined } from 'utils'

export default function useLoadBookingConfig (insuranceType, distributorId, status, bookingType, beforeBooking, execute, device) {
  const bookingConfigLoading = useGlobalState(state => state.loading.effects.insuranceConfigure.loadBookingConfiguration)
  const loadBookingConfiguration = useAction(dispatch => dispatch.insuranceConfigure.loadBookingConfiguration)
  const chosenProductMetadata = useSelector((select, state) => select.insuranceEnquiry.getChosenProductMetadata(state, {
    insuranceType
  }))
  const chosenProduct = useSelector((select, state) => select.insuranceProducts.getChosenProduct(state, {
    insuranceType
  }))
  const dataMismatch = useSelector((select, state) => select.insuranceEnquiry.getUserMismatchError(state, {
    insuranceType
  }))
  const setHooksError = useAction(dispatch => dispatch.errors.setHooksError)

  // FIXME: need some other way to check if booking Config has loaded!
  const bookingConfigLoaded = useGlobalState(state => {
    if (isNotEmpty(chosenProductMetadata)) {
      const config = state.insuranceConfigure[insuranceType]
      return config && config.booking && isNotEmpty(config.booking[chosenProductMetadata.institutionId])
    }
    return false
  })

  useEffect(() => {
    try {
      if (execute && isNotEmpty(chosenProduct) && !isNotEmpty(dataMismatch) && !bookingConfigLoading && !bookingConfigLoaded && (isNotDefined(status) || status)) {
        const { institutionId, productInsurerId } = chosenProductMetadata
        let resetSteps = false
        // following used in case of post payment
        if (status) {
          resetSteps = status === 'success'
        }
        loadBookingConfiguration({
          insuranceType,
          distributorId,
          institutionId,
          productInsurerId,
          resetSteps,
          bookingType,
          beforeBooking,
          device
        })
      }
    } catch (err) {
      setHooksError(err)
    }
  },
  // eslint-disable-next-line
  [ chosenProduct, bookingConfigLoading, bookingConfigLoaded, status, execute ])
  return [bookingConfigLoading, bookingConfigLoaded]
}
