import { connect } from 'react-redux'
import { PaymentSummary } from './components'
import { generateSiS, getPaymentRedirectionUrl, getFile } from 'smartcovr_api/src/insuranceApi'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = select(models => ({
    chosenProductMetaData: models.insuranceEnquiry.getChosenProductMetadata,
    distributorId: models.configure.getDistributorId,
    chosenProduct: models.insuranceProducts.getChosenProduct,
    formData: models.insuranceEnquiry.getFormData,
    productData: models.insuranceEnquiry.getProductData
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    ...selectorData,
    ...ownProps,
    enquiry: state.insuranceEnquiry[insuranceType],
    insuranceType
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { insuranceType } = ownProps
  return {
    updateChosenProduct: (chosenProductParams) => {
      dispatch.insuranceEnquiry.setChosenProductWithApplicationData({
        insuranceType,
        chosenProductParams
      })
    },
    getFile,
    generateSiS,
    getPaymentRedirectionUrl
  }
}
const PaymentSummaryContainer = connect(mapStateToProps, mapDispatchToProps)(PaymentSummary)
PaymentSummaryContainer.path = 'payment-summary'
export default PaymentSummaryContainer
