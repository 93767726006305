import { Image } from 'semantic-ui-react'
import { Body1, Caption } from 'components/Header'
import { buildProductImagePath } from 'components/buildImagePath'

const ProductImageAndNameWithDescription = ({ data }) => {
  return (
    <div className='productImageAndNameWithProductOptionName'>
      <div className='outerdiv'>
        <div className='productImage'>
          <Image className='productImg' src={buildProductImagePath('hdfc-life.png')} />
        </div>
        <div className='productNameAndDesc'>
          <Body1>
            Claim Registration Number:
          </Body1>
          <Caption>
            {data.registrationNo}
          </Caption>
        </div>
      </div>
    </div>
  )
}

export default ProductImageAndNameWithDescription
