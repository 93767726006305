
import Insurer from './Insurer'
import InsurerWithLogo from './InsurerWithLogo'
import Cover from './Cover'
import CoverPeriod from './CoverPeriod'
import { Feature } from './featuresAndRiders/features'
import { Rider } from './featuresAndRiders/riders'
import Premium from './Premium'
import PdfDocument from './PdfDocument'
import BookButton from './BookButton'
import LogoImage from './LogoImage'
import SecondButton from './SecondButton'
import DataLoader from 'components/DataLoader'
import { PaymentOptionConfigure } from './paymentOption'
import { PayoutTermsConfigure } from './payoutTerms'
export const productOptionComposer = (props) => {
  const {
    product,
    formData,
    view, // Dont know
    device,
    contentType,
    onNext,
    onBack,
    selectedFeature,
    selectedRider,
    onFeatureChange,
    onReviewBack,
    productData,
    spProductData,
    className,
    featureAmount,
    onPaymentChange,
    paymentOptionSchema,
    onReview,
    config,
    insuranceType
  } = props
  const id = product.productOptionId
  const loading = {}
  const riderProps = {
    // closeRiderEdit,
    // index,
    // editRiderId,
    // addRider,
    // onRiderChange
  }
  return ({
    Cover: <Cover
      product={product}
      view={view}
      loading={loading}
      Loader={DataLoader}
      formData={formData}
      id={id}
      className={className}
    />,
    Insurer: <Insurer
      product={product}
      view={view}
      id={id}
    />,
    InsurerWithLogo: <InsurerWithLogo
      product={product}
      view={view}
      id={id}
      inline
      className={className}
    />,
    CoverPeriod: <CoverPeriod
      product={product}
      view={view}
      loading={loading}
      Loader={DataLoader}
      formData={formData}
      id={id}
    />,
    Feature: <Feature
      key={selectedFeature.categoryId + 'features'}
      onFeatureChange={onFeatureChange}
      feature={selectedFeature}
      view={view}
      contentType={contentType}
      productOptionId={id}
      id={id}
      loading={loading}
      Loader={DataLoader}
      device={device}
      productData={productData}
      spProductData={spProductData}
      className={className}
      featureAmount={featureAmount}
      formData={formData}
    />,
    Rider: <Rider
      rider={selectedRider}
      feature={selectedFeature}
      onFeatureChange={onFeatureChange}
      contentType={contentType}
      view={view}
      productOptionId={id}
      loading={loading}
      Loader={DataLoader}
      {...riderProps}
      id={id}
    />,
    Premium: <Premium
      product={product}
      view={view}
      loading={loading}
      Loader={DataLoader}
      id={id}
      className={className}
    />,
    PaymentOption: <PaymentOptionConfigure
      onReviewBack={onReviewBack}
      onPaymentChange={onPaymentChange}
      paymentOptionSchema={paymentOptionSchema}
      paymentOptionSubmit={onReview}
      config={config.paymentOption}
    />,
    PayoutTerms: <PayoutTermsConfigure
      // {...payoutProps}
      product={product}
      contentType={contentType}
      view={view}
      loading={loading}
      Loader={DataLoader}
      device={device}
      id={id}
      productData={productData}
      insuranceType={insuranceType}
    />,
    ProductBrochure: <PdfDocument
      product={product}
      view={'detailedModal'}
      heading={'Plan Brochure'}
      type={'plan'}
      id={id}
      className={className}
    />,
    Book: <BookButton
      fluid
      onClick={onNext}
      view={view}
      product={product}
      actionText={'NEXT'}
      loading={loading}
      Loader={DataLoader}
      id={id}
    />,
    LogoImage: <LogoImage
      view={view}
      product={product}
      id={id}
    />,
    BackButton: <SecondButton
      onClick={onBack}
      view={view}
      index={0} // Dont know this
      actionText={'BACK'}
      product={product}
      id={id}
    />
  })
}
