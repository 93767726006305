import { isEmpty } from 'utils/lib/basic'
import { extractNestedObject } from './JsonSchema.helper'
import dayjs from 'dayjs'

export const validateMobileNumber = (mobile, countries) => {
  let error
  try {
    const parsedData = JSON.parse(mobile)
    const { number, code } = parsedData
    const country = countries.find(country => country.code === code)
    const min = country?.min
    const max = country?.max
    if (isEmpty(number) || isEmpty(country)) {
      error = 'Mobile number is a required field'
    } else if (number.length < min || number.length > max) {
      if (min === max) {
        error = `Mobile number should be of ${min} digits`
      } else {
        error = `Mobile number should be between ${min} and ${max} digits`
      }
    }
  } catch (e) {
    if (isEmpty(mobile)) {
      error = 'Mobile number is a required field'
    } else if (mobile.length < 10) {
      error = 'Mobile number should be of 10 digits'
    }
  }

  return error
}
export const validateMemberDetails = (formData, property) => {
  const result = extractNestedObject(formData, property)
  const members = result[property]
  const errors = []
  members.forEach(member => {
    const age = calculateAge(member.dateOfBirth)
    const relation = member.relation
    if (['Son', 'Daughter'].includes(relation)) {
      (age < 5 || age > 25) ? errors.push('Age should be between 5 and 25') : errors.push('')
    } else {
      (age < 18 || age > 100) ? errors.push('Age should be between 18 and 100') : errors.push('')
    }
  })
  return errors
}

export const validateFullName = (formData, key) => {
  let error
  const result = extractNestedObject(formData, key)
  const value = result[key]
  if (value === '') {
    error = `${key} is a required field`
  } else if (!(/^[a-zA-Z ]*$/.test(value))) {
    error = `${key} should contain only alphabets`
  }
  return error
}

export const calculateAge = dob => {
  const dobStr = dayjs(dob)
  const today = dayjs().startOf('day')
  const age = today.diff(dobStr, 'year')
  return age
}

export const validateDateOfBirth = (formData, schema, property) => {
  const { dateOfBirth } = extractNestedObject(schema, property)
  const result = extractNestedObject(formData, property)
  const value = result[property]
  let error
  if (isEmpty(value)) {
    error = 'Date of birth is a required field'
  } else if (dateOfBirth && dateOfBirth.minAge && dateOfBirth.maxAge) {
    const age = calculateAge(value)
    if (age < dateOfBirth.minAge || age > dateOfBirth.maxAge) {
      error = `Age should be between ${dateOfBirth.minAge} and ${dateOfBirth.maxAge} years`
    }
  }
  return error
}

export const validateDOB = (formData, schema, property) => {
  const { dob } = extractNestedObject(schema, property)
  const result = extractNestedObject(formData, property)
  const value = result[property]
  let error
  if (isEmpty(value)) {
    error = 'Date of birth is a required field'
  } else if (dob && dob.minAge && dob.maxAge) {
    const age = calculateAge(value)
    if (age < dob.minAge || age > dob.maxAge) {
      error = `Age should be between ${dob.minAge} and ${dob.maxAge} years`
    }
  }
  return error
}

export const validatePincode = (formData, key) => {
  const result = extractNestedObject(formData, key)
  const value = result[key]
  let error
  if (isEmpty(value)) {
    error = 'Pincode is a required field'
  } else if (value.length < 6) {
    error = 'Pincode should be 6 digits'
  } else if (!(/^([1-9]\d{5})$/.test(value))) {
    error = 'Pincode is not valid'
  }
  return error
}

export const validateEmail = (formData, key) => {
  let error
  const result = extractNestedObject(formData, key)
  const field = result[key]
  if (isEmpty(field)) {
    error = 'Email cannot be empty'
  } else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(field))) {
    error = 'Invalid Email ID format'
  }
  return error
}

export const validateRegistrationDetails = (formData, key) => {
  const result = extractNestedObject(formData, key)
  const field = result[key]
  let error
  if (key === 'vehicleRegistrationNumber') {
    if (isEmpty(field)) {
      error = 'Registration Number is a required field'
    } else if (!(/^([a-zA-Z]{2})-(\d{1,2})-([a-zA-Z]{1,3})-(\d{3,4})$/g.test(field))) {
      error = 'Invalid Registration Number'
    }
  }
  if (key === 'registrationLocation') {
    if (isNaN(parseInt(field))) {
      error = 'Registration Location is a required field'
    }
  }
  return error
}

export const setDate = (noOfDays) => {
  const date = new Date().setDate(new Date().getDate() + noOfDays)
  return new Date(date)
}

export const validatePreviousPolicyEndDate = (formData, key) => {
  const result = extractNestedObject(formData, key)
  const field = result[key]
  let error
  if (field?.previousPolicyStatus && field?.previousPolicyEndDate) {
    const { previousPolicyStatus, previousPolicyEndDate } = field
    let minDate, maxDate
    if (previousPolicyStatus === 'expiredLessThan45Days') {
      minDate = setDate(-45)
      maxDate = setDate(0)
      error = previousPolicyEndDate < minDate || previousPolicyEndDate > maxDate ? 'Previous Policy End Date should be between 45 days and today' : ''
    } else if (previousPolicyStatus === 'expiredBetween45To90Days') {
      minDate = setDate(-90)
      maxDate = setDate(-45)
      error = previousPolicyEndDate < minDate || previousPolicyEndDate > maxDate ? 'Previous Policy End Date should be between 45 days and 90 days' : ''
    } else if (previousPolicyStatus === 'expiredMoreThan90Days') {
      minDate = setDate(undefined)
      maxDate = setDate(-90)
      error = previousPolicyEndDate < minDate || previousPolicyEndDate > maxDate ? 'Previous Policy End Date should be of before 3 months' : ''
    }
  }
  return error
}

export const validatePincodeDetails = (formData, key) => {
  let error
  if (formData?.insurerAddress) {
    const result = extractNestedObject(formData, key)
    const field = result[key]

    if (isEmpty(field.city) || isEmpty(field.state) || isEmpty(field.pincode)) {
      error = 'Pincode is a required field'
    }
  }
  return error
}

export const validateMobileDndError = (formData, key) => {
  let error
  const result = extractNestedObject(formData, key)
  const value = result[key]
  if (!value) {
    error = 'Please Accept the Terms and Conditions'
  }
  return error
}

export const validateMakeModelDetails = (formData, key, schema) => {
  const errors = {}
  if (formData?.makeModel) {
    const result = extractNestedObject(formData, key)
    const fields = result[key]
    Object.keys(fields).forEach(k => {
      const title = schema.properties?.[k]?.title || k
      if (fields[k] === -1) {
        errors[k] = `${title} is a required field`
      }
    })
  }
  return errors
}

export const validateOTP = (otp) => {
  let error
  if (!otp) {
    error = 'OTP cannot be empty'
  } else if (!/^\d{6}$/.test(otp)) {
    error = 'Invalid OTP format. OTP must be 6 digits.'
  }
  return error
}

export const validateDobOfLifeAssured = (formData, schema, property) => {
  const { dateOfBirthOfLifeAssured } = extractNestedObject(schema, property)
  const result = extractNestedObject(formData, property)
  const value = result[property]
  let error
  if (isEmpty(value)) {
    error = 'Date of birth is a required field'
  } else if (dateOfBirthOfLifeAssured && dateOfBirthOfLifeAssured.minAge && dateOfBirthOfLifeAssured.maxAge) {
    const age = calculateAge(value)
    if (age < dateOfBirthOfLifeAssured.minAge || age > dateOfBirthOfLifeAssured.maxAge) {
      error = `Age should be between ${dateOfBirthOfLifeAssured.minAge} and ${dateOfBirthOfLifeAssured.maxAge} years`
    }
  }
  return error
}

export const validateRelationshipWithLifeAssured = (formData, schema, property) => {
  const { relationshipWithLifeAssured } = extractNestedObject(schema, property)
  const result = extractNestedObject(formData, property)
  const value = result[property]
  let error

  if (isEmpty(value)) {
    error = 'Relationship with life assured is a required field'
  } else if (!/^[A-Za-z]+$/.test(value)) {
    error = 'Relationship with life assured should contain only alphabets'
  } else if (relationshipWithLifeAssured.minLength < 3 || relationshipWithLifeAssured.maxLength > 100) {
    error = 'Relationship with life assured should be between 3 and 100 characters'
  }

  return error
}
