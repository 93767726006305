import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SingleForm from './SingleForm'
import FormHeader from 'components/onboardElements/FormHeader'
import { adopt } from 'react-adopt'
import { DataAccessContext } from 'components/context'
import DividedSegments from 'components/onboardElements/DividedSegments'
import { Image } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import { buildCommonImagePath } from 'components/buildImagePath'
import 'components/css/singleForm.scss'

class HealthInsuranceSingleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    const { listing, device, title } = this.props
    if (device === 'mobile' || listing) {
      return (
        <div className={`healthSingleStepForm mobile rightSegment flexContainer ${listing ? 'listing' : ''}`}>
          {listing && (
            <H6 color='white' textAlign='center' className='headerBlock'>
              <>
                <Image id='header-img' size='mini' src={buildCommonImagePath('user-icon.png')} />
                {this.props.formData.policyType === 'individual' ? 'Edit Member Details' : 'Edit/Add/Remove Members Details'}
              </>
            </H6>)}
          {!listing && <FormHeader>{title}</FormHeader>}
          <div className='innerDiv'>
            <SingleForm {...this.props} />
          </div>
        </div>
      )
    }
    return (
      <div className='singleStepOuter'>
        <div id='singleStepForm' className='singleStepForm flexContainer'>
          <DividedSegments
            rightSegment={
              <>
                <FormHeader>{title}</FormHeader>
                <div className='outer flexContainer'>
                  <div className='inner'>
                    <SingleForm {...this.props} />
                  </div>
                </div>
              </>
            }
          />
        </div>
      </div>
    )
  }
}
HealthInsuranceSingleForm.propTypes = {
  device: PropTypes.string.isRequired,
  configure: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  onBack: PropTypes.func
}
const WithContext = adopt({
  display: <DataAccessContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ display }) => (
        <HealthInsuranceSingleForm
          {...props}
          formType={display.formType}
          device={display.device}
        />
      )
    }
  </WithContext>
)
export default WrapperComponent
