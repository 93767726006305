import upperCase from 'lodash/upperCase'
import { rupeeFormatter, amountWithLabels } from 'utils'
import { Header, Icon, Statistic, List, Segment, Button } from 'semantic-ui-react'
import { downloadFile } from '../../../uiCommonUtils'
import 'components/css/applicationConfirmationPage.scss'

const ApplicationConfirmationPage = (props) => {
  const { product, selectedProductDetails: { applicationFileId, payment }, getFileUrl } = props
  const { shortName, productOptionName } = product.productName
  const { frequencyDisplay } = product.productPremium
  let amtText = { amount: '0', label: 'Thousands' }
  let label
  if (product.sumAssured) {
    label = 'Cover Amount'
    amtText = amountWithLabels(product.sumAssured)
  } else if (product.idv && product.idv > 0) {
    label = 'Covered IDV'
    amtText = amountWithLabels(product.idv)
  }
  return (
    <Segment>
      <List>
        <List.Item>
          <Header size='large' color='secondary'>
            <p>{shortName}</p>
            <Header.Subheader>
              {productOptionName}
            </Header.Subheader>
          </Header>
        </List.Item>
        {label && (
          <List.Item>
            <Header sub>
              {label}
            </Header>
            <Statistic color='green' horizontal>
              <Statistic.Value>
                <Icon size='tiny' name='rupee' />
                {`${amtText.amount} ${amtText.label}`}
              </Statistic.Value>
            </Statistic>
          </List.Item>
        )}
        <List.Item>
          <Header sub>
            Total Premium Paid
          </Header>
          <Statistic color='green' horizontal>
            <Statistic.Value>
              <Icon size='tiny' name='rupee' />
              {rupeeFormatter(Math.round(payment.totalPremiumWithGst))}
            </Statistic.Value>
            <Statistic.Label>{upperCase(frequencyDisplay)}</Statistic.Label>
          </Statistic>
        </List.Item>
      </List>
      <Header className='caption' sub as='a' onClick={() => { downloadFile(getFileUrl(applicationFileId), applicationFileId + '.pdf') }}>
        <Icon name='file pdf outline' />
        Download Application Form
      </Header>
      <div className='navigate_btn'>
        <Button primary onClick={() => { props.redirectToProductListing() }}>
          Continue buying products
        </Button>
      </div>
    </Segment>
  )
}
export default ApplicationConfirmationPage
