import { useEffect, useState } from 'react'
import { Input } from 'semantic-ui-react'
import { Caption, Body1 } from 'components/Header'
import CustomFieldWrapper from '../components/CustomFieldWrapper'
import PanWidget from '../widgets/PanWidget'
import { verifyPanNumber } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'
import { capitalize, isNotEmpty } from 'utils'
import Loading from 'components/Loading'

const ErrorComponent = ({ message, color, show }) => {
  if (!show) return null
  return (
    <Caption color={color}>
      {message}
    </Caption>
  )
}

const PanDetails = ({ companyDetails, response, handlePanNameUpdate }) => {
  if (response && response.panStatus && !response.panCorrect) {
    return (
      <Caption>
        Company Name provided: {companyDetails.companyName}
        <br />
        Company Name on PAN: {response.fullName}
        <br />
        Change PAN Number or &nbsp;
        <span className='clickableLink' onClick={() => handlePanNameUpdate(response)}>
          Update Company Name with name on Pan Card
        </span>
      </Caption>
    )
  }
  return null
}

const CompanyPanField = (props) => {
  const { schema, uiSchema, errorSchema, disabled, formData: { panNo, verifiedDetails }, onChange } = props
  const panVerified = isNotEmpty(panNo) && isNotEmpty(verifiedDetails) && verifiedDetails.panStatus
  const [state, setState] = useState({ loading: false, message: 'Kindly verify your PAN Details.', color: 'red', show: !panVerified })
  const [panResponse, setPanResponse] = useState(null)
  const [updateClicked, setUpdateClicked] = useState(false)
  const placeholder = uiSchema['ui:placeholder']
  const { companyDetails } = props.getGlobalFormData()

  useEffect(() => {
    if (updateClicked) {
      setUpdateClicked(false)
      handleVerifyPan()
    }
  }, [updateClicked]) // eslint-disable-line
  const handlePanNoChange = (value) => {
    onChange({ panNo: value })
    setState({ loading: false, message: 'Kindly verify your PAN Details.', color: 'red', show: true })
    setUpdateClicked(false)
  }

  const handlePanNameUpdate = (response) => {
    const updatedFormData = produce(props.getGlobalFormData(), (draft) => {
      draft.companyDetails.companyName = capitalize(response.fullName)
    })
    console.log('updatedFormData', JSON.parse(JSON.stringify(updatedFormData.companyDetails)))
    console.log('response', response)
    props.onFormDataChange(updatedFormData, {}, 'companyDetails')
    setState({ ...state, message: '', show: false })
    setUpdateClicked(true)
  }

  const getPanDetails = () => {
    return verifyPanNumber({
      panNo
    })
  }

  const handleVerifyPan = async () => {
    setState({ ...state, loading: true, message: 'Verifying Pan details ...', color: 'blue', show: true })
    const response = await getPanDetails()
    setPanResponse(response)
    if (response.panStatus) {
      setState({ ...state, loading: false, message: '', color: 'green', show: true })

      if (response.panCorrect) {
        setState({ ...state, loading: false, message: 'Pan verfied successfully', color: 'green', show: true })
        onChange({
          panNo,
          verifiedDetails: response
        })
      } else {
        setState({ ...state, loading: false, message: 'Company name does not match that on the Card.', color: 'red', show: true })
      }
    } else {
      setState({ ...state, loading: false, message: 'Pan Number cannot be verified.', color: 'red', show: true })
    }
  }

  return (
    <CustomFieldWrapper
      schema={schema}
      uiSchema={uiSchema}
      errorSchema={errorSchema}
    >
      <div className='flexContainer align-items-center gap-1'>
        {state.loading && <Loading id='pan_loading' open />}
        <PanWidget
          disabled={disabled || panVerified}
          as={Input}
          type='number'
          placeholder={placeholder}
          onChange={handlePanNoChange}
          value={panNo ?? ''}
          {...uiSchema.panNo}
        />
        {panVerified && (
          <Body1
            id='deletePan'
            className='verifyLink'
            color='red'
            onClick={() => handlePanNoChange('')}
          >
            Delete
          </Body1>
        )}
        {!panVerified && (
          <Body1
            id='verifyPanLink'
            className='verifyLink'
            color='blue'
            onClick={handleVerifyPan}
          >
            Verify
          </Body1>)}
      </div>
      <ErrorComponent message={state.message} color={state.color} show={state.show} />
      <PanDetails companyDetails={companyDetails} response={panResponse} handlePanNameUpdate={handlePanNameUpdate} />
    </CustomFieldWrapper>
  )
}

export default CompanyPanField
