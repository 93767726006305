import HeadAndBody from './HeadAndBody'

const ProductBenefits = ({ productBenefit }) => {
  return (
    <>
      { productBenefit.length !== 0 && (
        <HeadAndBody head={'Product Benefits'} body={productBenefit} />
      )
      }
    </>
  )
}

export default ProductBenefits
