import { Header } from 'components/Header'

const H4 = ({ children, ...restProps }) => {
  return (
    <Header as='h4' {...restProps}>
      {children}
    </Header>
  )
}

export default H4
