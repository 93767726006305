import { buildDistributorImagePath } from 'components/buildImagePath'
import './landing.scss'
import { useState } from 'react'
import { Button, Paper, Typography } from '@mui/material'

const LandingHeader = () => {
  return (
    <header className='welcomeHeader'>
        <div className='jumbotron'>
            <div className='rowCenter container row'>
                <div className='w-auto'>
                    <h1 className='text-center'>Welcome back</h1>
                </div>
            </div>
        </div>
    </header>
  )
}
const ClaimOptions = ({ redirectToPath, insuranceType, onClickBack }) => {
  return (
    <div style={{ position: 'absolute', top: '70%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center', zIndex: 1 }}>
      <Paper elevation={3} style={{ padding: '24px', borderRadius: '8px', maxWidth: '600px', width: '90%', height: 'auto' }}>
        <Button variant="" style={{ position: 'absolute', top: '8px', left: '8px', padding: '8px 12px', background: '#fff' }} onClick={onClickBack}>
         <i style={{ fontSize: '20px', marginRight: '10px' }} className='fa fa-angle-left' />
          Back
        </Button>
        <Typography variant="h5" gutterBottom>
          Select an Option
        </Typography>
        <Button fullWidth variant="outlined" onClick={() => redirectToPath(`/${insuranceType}/claim-intimation`)} style={{ marginBottom: '12px', background: '#fff' }}>
          File a Claim Now
        </Button>
        <Button fullWidth variant="outlined" onClick={() => redirectToPath(`/${insuranceType}/track-claims`)} style={{ marginBottom: '12px', background: '#fff' }}>
          Track Existing Claim
        </Button>
        <Button fullWidth variant="outlined" onClick={() => redirectToPath(`/${insuranceType}/registered-claims`)} style={{ background: '#fff' }}>
          Claim Holdings
        </Button>
      </Paper>
    </div>
  )
}

export default function Landing (props) {
  const { redirectToPath, availableProducts } = props
  const [insuranceType, setInsuranceType] = useState(null)
  const onClickBack = () => setInsuranceType(null)

  return (
    <>
    <LandingHeader />
      {insuranceType === null && (<div className='landing_container'>
        <div className='landing_container_row'>
          {(availableProducts || []).map((product, idx) => (
            <div key={idx} className='insurance_types_section'>
              <Button
                id={product}
                // basic
                className='insurance_type_btn'
                onClick={() => setInsuranceType(product)}
              >
                <span id={product + '-insurance'} className='insurance_type_text'>{product}</span>
                <img id={product + '-img'} alt={product} src={buildDistributorImagePath(`${product}.png`)} style={{ height: '5rem', width: '5.5rem' }} />
              </Button>
            </div>
          ))}
        </div>
      </div>)}
      {insuranceType && <ClaimOptions redirectToPath={redirectToPath} insuranceType={insuranceType} onClickBack={onClickBack} />}
    </>
  )
}
