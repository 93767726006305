import { hexToRgb } from '@mui/material'
const hexCodeToNumeric = (hexCode) => {
  const rgbColor = hexToRgb(hexCode) // '#2196f3'
  const numericCode = rgbColor.replace('rgb(', '').replace(')', '').split(',').join(',')
  return numericCode
}

const useTheme = () => {
  const setTheme = (theme) => {
    // set the root variables
    for (const key in theme) {
      document.documentElement.style.setProperty(`--${key}`, hexCodeToNumeric(theme[key]))
    }
  }

  return { setTheme }
}

export default useTheme
