
import { Component } from 'react'
import { Form } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import FormDropdown from 'components/FormDropdown'
import { isNotDefined } from 'utils'

class Insured extends Component {
  // componentDidMount () {
  //   const { proposerRelations, product, setProductData, applicationFormData } = this.props
  //   const { productInsurerId, institutionId, insurerId } = product
  //   let insured
  //   if (!isNotDefined(applicationFormData)) {
  //     insured = applicationFormData.insured
  //   }
  //   if (isNotDefined(insured) && proposerRelations && proposerRelations.length === 1) {
  //     setProductData({
  //       productOptionInsurerId: insurerId,
  //       institutionId,
  //       insurerId: productInsurerId,
  //       formData: {
  //         insured: proposerRelations[0].value
  //       }
  //     })
  //   }
  // }
  onChange (data) {
    const { product: { productInsurerId, institutionId, insurerId }, setProductData } = this.props
    setProductData({
      productOptionInsurerId: insurerId,
      institutionId,
      insurerId: productInsurerId,
      formData: {
        insured: data.value
      }
    })
  }

  render () {
    const { formData, proposerRelations, id, device } = this.props
    let insured
    if (!isNotDefined(formData)) {
      insured = formData.insured
    }
    const options = proposerRelations.map(option => ({
      key: option.value,
      text: option.display,
      value: option.value
    }))
    // FIXME: See how to set form data. either on final submit or on change pf this component itself
    if (options.length <= 1) {
      return null
    }
    return (
      <div className={`outer ${device}`}>
        <div className='inner'>
          <Form>
            <H6 color='primaryLight'>
              Who are you buying this insurance for ?
            </H6>
            <div className='insuredDropDowm'>
              <FormDropdown
                id={`${id}_insured`}
                placeholder='Select Insured'
                value={insured}
                options={options}
                onChange={(e, data) => this.onChange(data)}
              />
            </div>
          </Form>
        </div>
      </div>
    )
  }
}

export default Insured
