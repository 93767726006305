import { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import FormDropdown from 'components/FormDropdown'
import { range } from 'utils'

const CoverTermHealth = (props) => {
  const { id, handleChange, coverTerm, className, disabled, configure: { common: { coverTermRange: { min, max } } } } = props

  const options = useMemo(() => {
    const numberRange = range(min, max + 1)
    return numberRange.map((num) => {
      return {
        key: num,
        text: `${num} Years`,
        value: num
      }
    })
  },
  // eslint-disable-next-line
   [coverTerm])
  function onClick (event) {
    event.preventDefault()
  }
  return (
    <div className='coverTerm inputElement filter'>
      <Form.Field>
        <FormDropdown
          id={`${id}Dropdown`}
          className={className}
          icon='dropdown'
           // placeholder={placeholder}
          options={options}
          value={coverTerm}
          disabled={disabled}
          fluid
          onChange={(e, data) => handleChange({ coverTerm: parseFloat(data.value) }, id, true)}
          onClick={(event) => onClick(event)}
        />
      </Form.Field>
    </div>
  )
}
export default CoverTermHealth
