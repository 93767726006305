import { Header as SUIHeader } from 'semantic-ui-react'

const UICOLORS = ['red', 'orange', 'yellow', 'olive', 'green', 'teal', 'blue', 'violet', 'purple', 'pink', 'brown', 'grey', 'black']

const Header = ({ children, color, className, ...restProps }) => {
  const isColor = UICOLORS.includes(color)
  const prop = isColor ? { color, className } : { className: `${color ?? ''} ${className ?? ''}` }
  return (
    <SUIHeader {...prop} {...restProps}>
      {children}
    </SUIHeader>
  )
}

Header.Content = SUIHeader.Content
Header.Subheader = SUIHeader.Subheader
export default Header
