import { Caption, Body1 } from 'components/Header'
import { isNotDefined } from 'utils'

const PolicyNature = ({ data }) => {
  let policyNature
  if (!isNotDefined(data.application)) {
    policyNature = data.application.HealthApplication.policyOption
  } else {
    policyNature = data.HealthApplication.policyOption
  }
  policyNature = policyNature[0].toUpperCase() + policyNature.slice(1)
  return (
    <div className='secondHalf'>
      <div className='Status'>
        <Caption>
          Policy Nature
        </Caption>
        <Body1 color='yellow'>
          {policyNature}
        </Body1>
      </div>
    </div>
  )
}

export default PolicyNature
