import { PureComponent } from 'react'
import { rupeeFormatter } from 'utils'
import { Icon } from 'semantic-ui-react'
import RiderPopUp from './RiderPopUp'
import ExpandableListItem from 'components/ExpandableListItem'
import { LoadingContext } from 'components/context'
class ReviewRider extends PureComponent {
  render () {
    const {
      customValidationMessage,
      currentPremium,
      rider,
      schema,
      uiSchema,
      contentType,
      formData,
      riderFormData,
      feature,
      rules,
      productOptionId,
      checked,
      heading,
      description,
      shortName,
      points,
      descriptionPiped,
      riderBrochureUrl,
      key,
      device,
      isEdit,
      onRiderChange,
      onSubmit,
      onCancel,
      addRemoveRider,
      insuranceType,
      productData,
      setProductData,
      product
    } = this.props
    let displayAmount
    const temp = 0

    if (isNaN(currentPremium.premium) && insuranceType === 'health') {
      displayAmount = ''
    } else if (isNaN(currentPremium.premium)) {
      displayAmount = (
        <>
          <Icon name='rupee' color='green' size='small' />
          {rupeeFormatter(temp)}
        </>
      )
    } else {
      displayAmount = (
        <>
          <Icon name='rupee' color='green' size='small' />
          {rupeeFormatter(currentPremium.premium)}
        </>
      )
    }
    const popUpContent = (
      <RiderPopUp
        customValidationMessage={customValidationMessage}
        rider={rider}
        feature={feature}
        schema={schema}
        uiSchema={uiSchema}
        riderFormData={riderFormData}
        formData={formData}
        productData={productData}
        rules={rules}
        onSubmit={onSubmit}
        currentPremium={currentPremium}
        productOptionId={productOptionId}
        onCancel={onCancel}
        onRiderChange={onRiderChange}
        contentType={contentType}
        checked={checked}
        insuranceType={insuranceType}
        setProductData={setProductData}
        product={product}
      />
    )
    const listItemProps = {
      key,
      id: rider.riderId,
      productOptionId,
      checked,
      editable: true,
      isEdit,
      editableContent: popUpContent,
      heading,
      description,
      shortName,
      insuranceType,
      points,
      descriptionPiped,
      displayAmount,
      onCheckBoxClick: addRemoveRider,
      onChangeClick: addRemoveRider,
      brochureUrl: riderBrochureUrl,
      device
    }
    return (
      <ExpandableListItem {...listItemProps} />
    )
  }
}
ReviewRider.contextType = LoadingContext
export default ReviewRider
