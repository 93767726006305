
import { connect } from 'react-redux'
import { AutoRenewDetails } from './components'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps

  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    formData: models.insuranceEnquiry.getFormData,
    productData: models.insuranceEnquiry.getProductData,
    chosenProduct: models.insuranceProducts.getChosenProduct,
    distributorId: models.configure.getDistributorId,
    customerDetails: models.customer.getCustomerDetails,
    products: models.insuranceProducts.getAllProducts,
    isPartialLoadComplete: models.insuranceProducts.isPartialLoadComplete
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    configureLoading: state.loading.models.insuranceConfigure,
    enquiryLoading: state.loading.models.insuranceEnquiry,
    iamId: selectorData.customerDetails.iamId,
    setChosenProductForBookingLoading: state.loading.effects.insuranceProducts.setChosenProductForBooking,
    productsLoading: state.loading.models.insuranceProducts,
    productDataLoading: state.loading.effects.insuranceProducts.loadProducts,
    configureDataLoading: state.loading.effects.insuranceConfigure.setUiConfig,
    ...selectorData,
    ...ownProps
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { insuranceType } = ownProps
  return {
    setChosenProduct: ({ productOptionId, productId, institutionId }) => {
      return dispatch.insuranceProducts.setChosenProduct({
        insuranceType,
        productOptionId,
        productId,
        institutionId,
        quoteType: 'listing',
        reset: true
      })
    },
    resetChosenProduct: () => {
      return dispatch.insuranceProducts.resetChosenProduct({
        insuranceType
      })
    },
    setChosenProductData: ({ productData, formData, insurerId, institutionId, productOptionInsurerId, schemaKey }) => {
      return dispatch.insuranceProducts.updateChosenProductData({
        insuranceType,
        insurerId,
        institutionId,
        productData,
        productOptionInsurerId,
        formData,
        schemaKey
      })
    },
    setChosenProductForBooking: () => {
      return dispatch.insuranceProducts.setChosenProductForBooking({
        insuranceType
      })
    }
  }
}
const AutoRenewDetailsContainer = connect(mapStateToProps, mapDispatchToProps)(AutoRenewDetails)
AutoRenewDetailsContainer.path = 'renew-policy'
export default AutoRenewDetailsContainer
