import React from 'react'
import { isNotDefined, isNotEmpty } from 'utils'
import LabelField from '../components/LabelField'
import InputWidget from './InputWidget'

class RegistrationNumberWithLocationWidget extends React.Component {
  constructor (props) {
    super(props)
    const { vehicleRegistrationNumber, registrationLocation } = this.initialise()
    this.state = {
      vehicleRegistrationNumber,
      registrationLocation
    }
    this.regLocPlaceholder = (
      <span style={{ color: 'lightgray' }}>
        MH01-MUMBAI(Maharashtra)
      </span>
    )
  }

  initialise () {
    const { value, formContext } = this.props
    if (isNotEmpty(value)) {
      const { rtos } = formContext
      try {
        // if value is a JSON string
        const data = JSON.parse(value)
        const response = rtos.find(ele => ele.rtoId === data.registrationLocation)
        return { vehicleRegistrationNumber: data.vehicleRegistrationNumber, registrationLocation: response.name }
      } catch (e) {
        // if value is a string
        let [state, code] = value.split('-')
        code = code?.padStart(2, '0')
        const rgnCd = state + code
        const response = rtos.find(ele => ele.rgnCd === rgnCd)
        return { registrationLocation: response.name, vehicleRegistrationNumber: value }
      }
    } else {
      return { vehicleRegistrationNumber: '', registrationLocation: '' }
    }
  }

  onRegistrationNumberChange = (maskValue) => {
    const withoutMaskedValue = maskValue.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
    if (!isNotDefined(withoutMaskedValue) && withoutMaskedValue.length > 6 && withoutMaskedValue.length <= 11) {
      const maskedValue = maskValue.toUpperCase().replace(/^([a-zA-Z]{2})(\d{1,2})([a-zA-Z]{1,3})(\d{3,4})$/g, '$1-$2-$3-$4')
      const { formContext } = this.props
      const { rtos } = formContext
      let [state, code] = maskedValue.split('-')
      code = code?.padStart(2, '0')
      const rgnCd = state + code
      let rtoResponse
      if (isNotEmpty(rtos)) {
        rtoResponse = rtos.find(ele => ele.rgnCd === rgnCd)
      }
      if (isNotDefined(rtoResponse)) {
        this.setState({ registrationLocation: '', vehicleRegistrationNumber: maskedValue })
        return this.props.onChange(JSON.stringify({ vehicleRegistrationNumber: maskedValue, registrationLocation: '' }))
      } else {
        this.setState({ registrationLocation: rtoResponse.name, vehicleRegistrationNumber: maskedValue })
        return this.props.onChange(JSON.stringify({ vehicleRegistrationNumber: maskedValue, registrationLocation: rtoResponse.rtoId }))
      }
    }
    this.setState({ registrationLocation: '', vehicleRegistrationNumber: withoutMaskedValue })
    return this.props.onChange(JSON.stringify({ vehicleRegistrationNumber: withoutMaskedValue, registrationLocation: '' }))
  }

  render () {
    const {
      placeholder
    } = this.props
    const { vehicleRegistrationNumber, registrationLocation } = this.state
    return (
      <>
        <InputWidget
          {...this.props}
          value={(isNotDefined(vehicleRegistrationNumber)) ? '' : vehicleRegistrationNumber}
          onChange={(text) => {
            this.onRegistrationNumberChange(text)
          }}
          maxLength={14}
          placeholder={placeholder}
        />
        <LabelField
          className='control-label mt-2'
          htmlFor='label'
          title='Registration Location'
        />
        <LabelField
          className='control-label mt-2 pl-3 text-secondary d-block'
          htmlFor='RegistrationLocation'
          title={registrationLocation || this.regLocPlaceholder}
        />
      </>
    )
  }
}

export default RegistrationNumberWithLocationWidget
