import GeneralInfoScreen from 'components/infoScreens/GeneralInfoScreen'
const ErrorScreen = (props) => {
  return (
    <GeneralInfoScreen
      className='errorScreen'
      {...props}
    />
  )
}
export default ErrorScreen
