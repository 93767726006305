import { PureComponent } from 'react'
import { Card, Modal } from 'semantic-ui-react'
import { Caption } from 'components/Header'
import MobileKnowMore from './MobileKnowMore'

/* eslint react/jsx-handler-names: "off" */
class CardViewMobile extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      expanded: false
    }
    this.toggleExpanded = this.toggleExpanded.bind(this)
  }

  toggleExpanded () {
    this.setState((prevState, _props) => ({ expanded: !prevState.expanded }))
  }

  render () {
    const { ComponentMap, id, className, itemProps } = this.props
    const { insuranceType } = itemProps
    const { expanded } = this.state

    const dynamicFields = () => {
      if (insuranceType === 'annuity') {
        return (
          <>
            {ComponentMap.DeferredPeriod}
            {ComponentMap.RopPercent}
            {ComponentMap.AcpPeriod}
            {ComponentMap.IaPercent}
          </>
        )
      }
    }
    return (
      <Card className={`${className} cardViewMobile`} id={id}>
        <div className='basicDetails'>
          {ComponentMap.InsurerWithLogo}
          {dynamicFields()}
          <div className='mobileGrid flexContainer'>
            <div className='leftColumn'>
              {insuranceType !== 'annuity' ? ComponentMap.Benefits : ComponentMap.PurchasePrice}
            </div>
            <div className='rightColumn flexContainer'>
              {insuranceType !== 'annuity' ? ComponentMap.Premium : ComponentMap.AnnuityPayout}
              {ComponentMap.Review}
            </div>
          </div>
        </div>
        <Caption
          className='knowMoreButtonMobile'
          content='MORE DETAILS'
          onClick={this.toggleExpanded}
        />
        <Modal
          className='mobileKnowMoreModal'
          open={expanded}
        >
          <MobileKnowMore
            ComponentMap={ComponentMap}
            itemProps={itemProps}
            onBack={this.toggleExpanded}
            expanded={expanded}
          />
        </Modal>
      </Card>
    )
  }
}
export default CardViewMobile
