import { PureComponent } from 'react'
import { Statistic, Icon, Header } from 'semantic-ui-react'
import { rupeeFormatter, isNotEmpty, isNotDefined } from 'utils'
import DataLoader from 'components/DataLoader'
import { LoadingContext } from 'components/context'

const getPremium = (product, applicationData) => {
  if (!isNotDefined(applicationData) && !isNotDefined(applicationData.applicationFormData)) {
    if (!isNotDefined(applicationData.applicationFormData.selectedProduct.totalPremium)) {
      return applicationData.applicationFormData.selectedProduct.totalPremium
    } else {
      return product.totalPremium
    }
  } else {
    return product.totalPremium
  }
}
class Premium extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { index: 0 }
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange (e, data) {
    this.setState({ index: data.value })
  }

  render () {
    const { product, device, className, id } = this.props
    const loading = this.context
    const { paymentFrequency } = product
    let premiumLoading = false
    if (isNotEmpty(loading)) {
      premiumLoading = loading.status
    }
    const premium = getPremium(product, this.props.applicationData)
    if (device === 'mobile') {
      return (
        <Statistic color='green' className={`alignLeft ${className ?? ''}`}>
          <Header sub color='black'>
            <Statistic.Label>{`${paymentFrequency.display} Premium`}</Statistic.Label>
            {/* {paymentFrequency.display} */}
          </Header>
          <DataLoader
            loading={premiumLoading}
          >
            <Statistic.Value id={`${id}-premiumAmount`}>
              <Icon size='small' name='rupee' />
              {rupeeFormatter(Math.round(premium))}
            </Statistic.Value>
          </DataLoader>
        </Statistic>
      )
    }
    return (
      <Statistic color='green' className={`alignLeft productPremium ${className ?? ''}`}>
        <Statistic.Label>{`${paymentFrequency.display} Premium`}</Statistic.Label>
        <DataLoader
          loading={premiumLoading}
        >
          <Statistic.Value id={`${id}-premiumAmount`}>
            <Icon size='small' name='rupee' />
            {rupeeFormatter(Math.round(premium))}
          </Statistic.Value>
        </DataLoader>
      </Statistic>
    )
  }
}
Premium.contextType = LoadingContext
export default Premium
