import { useEffect } from 'react'
import {
  useGlobalState,
  useAction,
  useSelector
} from './useReduxFunctions'
import { getUiConfig, getSmartcovrConfig } from 'smartcovr_api/src/insuranceApi'
import { isNotEmpty } from 'utils'

// here useInitialDataLoader is a custom hook which sets initial data and returns appropriate loading.
export default function useInitialDataLoader (insuranceType, enquiryId, distributorId, reset = false) {
  // reset - when set to true, all products are loaded irrespetive of the fact that chosenProduct is there in enquiry

  const loading = useGlobalState(state => state.loading.effects.insuranceConfigure.setUiConfig)
  // used to check if config has loaded, as in the beginning config loading is false.
  const configure = useSelector((select, state) => select.insuranceConfigure.getUiConfig(state, {
    insuranceType
  }))

  const setUiConfig = useAction(dispatch => dispatch.insuranceConfigure.setUiConfig)
  const setHooksError = useAction(dispatch => dispatch.errors.setHooksError)
  useEffect(() => {
    if (!isNotEmpty(configure)) {
      (async () => {
        try {
          let smartCovrConfig = {}
          const uiConfig = await getUiConfig({
            distributorId,
            insuranceType
          })
          // for getting smartcovr config. (need to be remove the health check, once it is implemented for other insurance types(dev1))
          if (insuranceType === 'health') {
            smartCovrConfig = await getSmartcovrConfig({
              insuranceType
            })
          }
          setUiConfig({
            insuranceType,
            uiConfig,
            smartCovrConfig,
            enquiryId,
            reset
          })
        } catch (err) {
          setHooksError(err)
        }
      })()
    }
  },
  // eslint-disable-next-line
  [configure])

  // In the start config is empty and loading is false. therefore this condition returns true initially.
  return { loadingConfigure: !isNotEmpty(configure) || loading, configure }
}
