import { Body1, Caption } from 'components/Header'

import './applicationDetails.scss'

const TData = props => {
  const { Title, Value, showhr } = props
  return (
    <div className='td'>
      {/* <Icon size='big' color='black' name='record' /> */}
      <div style={{ width: '100%' }}>
        <Caption>
          <span className='TDataTitle'>{Title}</span>
        </Caption>
        <Body1 color={Title === 'Status' ? 'yellow' : 'grey'}>
          <span className='TDataValue'>{Value}</span>
        </Body1>
        <div className='hrtag'>
          <hr hidden={!showhr} style={{ backgroundColor: 'rgba(34, 36, 38, 0.15)', flex: 1, height: 1 }} />
        </div>
      </div>
    </div>
  )
}

export default TData
