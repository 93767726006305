import InputWidget from './InputWidget'
import SelectButtonWidget from './SelectButtonWidget'
import { isNotDefined } from 'utils'

const CityWidget = (props) => {
  const {
    options,
    formContext
  } = props
  const { cities } = formContext
  const { compact } = options
  if (isNotDefined(cities)) {
    return (
      <InputWidget
        {...props}
        placeholder='Enter city'
      />
    )
  }
  const dropdownOptions = cities.map(({ value, display }) => (
    { value, label: display }
  ))
  return (
    <SelectButtonWidget
      {...props}
      placeholder='Select city'
      options={{
        compact,
        enumOptions: dropdownOptions
      }}
    />
  )
}

export default CityWidget
