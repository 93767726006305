import Radio from 'components/jsonSchemaForms/widgets/RadioWidget'

function PlanType (props) {
  const { handleChange, id, planType } = props

  return (
    <Radio
      onChange={(data) => {
        handleChange(data, id, true)
      }}
      options={{
        enumOptions: [
          { label: 'Annuity Plan', value: 'annuity' },
          { label: 'Market Linked Plan', value: 'marketLinkedPlan' },
          { label: '100 % Guaranteed Return Plan', value: 'guaranteed' }
        ],
        enumDisabled: ['marketLinkedPlan', 'guaranteed']
      }}
      value={planType}
      required
      disabled={false}
      readonly={false}
      id='radioButtonforExisting'
      autoFocus={false}
      dStyle='radioButtonsFiltermenu' // for styling the div to show display items in flex
      checked={false}
    />
  )
}

export default PlanType
