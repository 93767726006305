import { useState } from 'react'
import { Image } from 'semantic-ui-react'
import { Menu, MenuItem, Button, Box } from '@mui/material'
import { buildCommonImagePath } from './buildImagePath'

const HeaderLoginMenu = ({ redirectToCustomerHandler, redirectToDistributorHandler }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const menuItems = [
    { title: 'Customer Login', id: 'customer', handleClick: redirectToCustomerHandler },
    { title: 'Distributor Login', id: 'distributor', handleClick: redirectToDistributorHandler }
  ]
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const loginIcon = (
    <Button
      onClick={handleOpen}
      id='loginIconBtn'
    >
      <Image
        id='loginIconBtnAvatar'
        size='mini'
        avatar
        src={buildCommonImagePath('login-option.png')}
      />
    </Button>
  )
  return (
    <Box sx={{ flexGrow: 0 }}>
      {loginIcon}
      <Menu
        sx={{ mt: '45px' }}
        id='simple-menu'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems.map((menuItem) => (
          <MenuItem dense className='menu-item' key={menuItem.title} id={menuItem.id} onClick={menuItem.handleClick}> {menuItem.title} </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export default HeaderLoginMenu
