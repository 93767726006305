import { PureComponent } from 'react'
import { rupeeFormatter } from 'utils'
import { Icon, Divider } from 'semantic-ui-react'
import { Caption } from 'components/Header'
import RiderPopUp from './RiderPopUp'
import CheckBoxListItem from 'components/CheckBoxListItem'
import { LoadingContext } from 'components/context'
class ListingRider extends PureComponent {
  render () {
    const {
      currentPremium,
      rider,
      schema,
      uiSchema,
      formData,
      riderFormData,
      feature,
      rules,
      productOptionId,
      checked,
      heading,
      description,
      shortName,
      points,
      riderLoading,
      childKey,
      device,
      isEdit,
      onRiderChange,
      onSubmit,
      onCancel,
      addRemoveRider,
      insuranceType,
      productData,
      setProductData,
      product
    } = this.props
    const temp = 0
    let displayAmount
    if (isNaN(currentPremium.premium) && insuranceType === 'health') {
      displayAmount = ''
    } else if (isNaN(currentPremium.premium)) {
      displayAmount = (
        <>
          <Icon name='rupee' color='green' size='small' />
          {rupeeFormatter(temp)}
        </>
      )
    } else {
      displayAmount = (
        <>
          <Icon name='rupee' color='green' size='small' />
          {rupeeFormatter(currentPremium.premium)}
        </>
      )
    }
    // FIXME: Move the popupContent to seperate class.
    const popUpContent = (
      <>
        {/* <div className='group-clear'>
        <Caption color='green' floated='right' >
          <div>
            <Icon name='rupee' size={'small'} />
            <span>{`${Math.round(currentPremium.perThousand * 100) / 100} per Rs. 1000`}</span>
          </div>
        </Caption>
        <Caption color='primaryLight'>
          {`Rider ${currentPremium.frequencyDisplay} Cost: `}
        </Caption>
      </div> */}

        {!rider.hasRiderSchemaUpdated && !rider.hasPremiumWillNotShown && (
          <div className='group-clear'>
            <Caption floated='right'>
              <div>
                <span>{`${rider.riderTerm} Years`}</span>
              </div>
            </Caption>
            <Caption color='primaryLight'>
              Rider Term
            </Caption>
          </div>
        )}

        {!rider.hasRiderSchemaUpdated && !rider.hasPremiumWillNotShown && <Divider fitted />}

        <RiderPopUp
          rider={rider}
          feature={feature}
          schema={schema}
          uiSchema={uiSchema}
          riderFormData={riderFormData}
          formData={formData}
          productData={productData}
          rules={rules}
          onSubmit={onSubmit}
          currentPremium={currentPremium}
          productOptionId={productOptionId}
          onCancel={onCancel}
          onRiderChange={onRiderChange}
          contentType='cardView'
          checked={checked}
          insuranceType={insuranceType}
          setProductData={setProductData}
          product={product}
        />
      </>
    )
    const listItemPopUp = {
      isEdit,
      editable: true,
      onCheckBoxClick: (data) => addRemoveRider(data),
      onHeaderClick: addRemoveRider,
      heading,
      description,
      shortName,
      points,
      displayAmount,
      id: rider.riderId,
      productOptionId,
      checked,
      loading: riderLoading,
      popUpContent,
      childKey,
      device,
      insuranceType
    }
    return (
      <CheckBoxListItem {...listItemPopUp} />
    )
  }
}
ListingRider.contextType = LoadingContext
export default ListingRider
