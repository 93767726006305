
import NumberFormat from 'react-number-format'
import { Form, Input, Checkbox } from 'semantic-ui-react'
import { H6, Body1 } from 'components/Header'
import ValidationErrorPopUp from 'components/ValidationErrorPopUp'
import { isNotEmpty } from 'utils'
import InputMask from 'react-input-mask'

const IndianMobileNumber = (props) => {
  const { mobile, handleChange, id, getErrorMessageByKey, device, mobileDnd, formType, hdfcLandingPage } = props
  const errorMessage = getErrorMessageByKey('mobile')
  const error = isNotEmpty(errorMessage)
  if (hdfcLandingPage) {
    return (
      <div className={`form-group col-lg-3 col-md-6 ${error && 'has-error'}`}>
        {error && (
          <div className='help-block with-errors'>
            <ul className='list-unstyled'>
              <li>{errorMessage}</li>
            </ul>
          </div>
        )}
        <InputMask
          className='form-control'
          id='mobile'
          placeholder='Phone number'
          mask={'+\\919999999999'}
          maskChar='_'
          value={mobile ?? ''}
          onChange={(event) => {
            handleChange({ mobile: event.target.value }, event.target.value.length)
          }}
          autoComplete='off'
        >
          {(inputProps) => (
            <input
              {...inputProps}
              className='form-control'
              type='tel'
            />
          )}
        </InputMask>
        {/* <input onChange={(event) => {
          if (event.target.value.length <= 10) {
            handleChange({ mobile: event.target.value }, 'mobile')
          }
        }} value={mobile} name='phone_number' className='form-control' id='phone_number' placeholder='Phone number' type='number' pattern='(?:\(\d{3}\)|\d{3})[- ]?\d{3}[- ]?\d{4}' maxLength='10' autoComplete='off' required='required' data-error='Please enter your phone number' /> */}
      </div>
    )
  }
  return (
    <div className='mobileGroup'>
      <H6 className='mobile'>
        <Form.Field id={id}>
          <NumberFormat
            centered
            className={`${error ? 'error' : ''}`}
            error={error}
            id='mobile'
            placeholder='Your contact number'
            format='+91 ##### #####'
            mask='_'
            value={mobile}
            customInput={Input}
            onValueChange={({ value }) => handleChange({ mobile: value }, value.length)}
          />
        </Form.Field>
        {error && (device !== 'mobile') && <ValidationErrorPopUp errorMessage={errorMessage} />}
      </H6>
      <Checkbox
        color={formType === 'hdfcForm' ? '' : 'white'}
        // material
        id='mobileDnd'
        name={`${id}_radioGroup`}
        value
        checked={mobileDnd}
        onChange={(e, data) => {
          if (data.value === mobileDnd) {
            handleChange({ mobileDnd: false })
          } else {
            handleChange({ mobileDnd: data.value })
          }
        }}
      />
      <Body1 className='dndLabel' color={formType === 'hdfcForm' ? '' : 'white'}>
        {/* FIXME: take from config!!! */}
        I authorize HDFC Securities to call me. This will override your existing registry in DNCR.
      </Body1>
    </div>
  )
}
export default IndianMobileNumber
