import { Input } from 'semantic-ui-react'
import { isEqual, omit } from 'lodash'

function MoreFilterComponents (props) {
  const { activeFilter, filter, setFilters } = props
  const { label, type, key, options } = activeFilter
  const onChangeHandler = (data) => {
    const { key, value } = data
    if (type === 'checkbox') {
      const newFilter = { ...filter, [JSON.parse(value)]: { include: !filter[JSON.parse(value)].include } }
      setFilters(newFilter)
    } else {
      setFilters({
        ...filter,
        [key]: { ...filter[key], ...JSON.parse(value) }
      })
    }
  }
  return (
    <div>
      {
        label && (
          <div className='filter-item'>
            <div className='filter-item-content'>
              {
                options.map((item, index) => {
                  return (
                    <div key={index} className='filter-item-content-item'>
                      <Input type={type} name={key} id={`${key}${index}`} value={JSON.stringify(item?.value)} checked={type === 'radio' ? isEqual(omit(filter[key], 'searchKey'), item.value) : filter[item.value]?.include} onChange={(e) => onChangeHandler({ key, value: e.target.value, checked: e.target.checked, label: item.label }, 'moreFilters')} />
                      <label htmlFor={`${key}${index}`}>{item.label}</label>
                    </div>
                  )
                })
              }
            </div>
          </div>
        )
      }
    </div>
  )
}

export default MoreFilterComponents
