
import { Component } from 'react'
import { Input } from 'semantic-ui-react'
import { Caption, Body1 } from 'components/Header'
import CustomFieldWrapper from '../components/CustomFieldWrapper'
import PanWidget from '../widgets/PanWidget'
import { verifyPanNumber } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'
import { isNotDefined, capitalize, isNotEmpty } from 'utils'
import Loading from 'components/Loading'

/* eslint react/jsx-handler-names: 'off' */
class JointPanField extends Component {
  constructor (props) {
    super(props)
    const { formData: { panNo, verifiedDetails } } = props
    const panVerified = isNotEmpty(panNo) && isNotEmpty(verifiedDetails) && verifiedDetails.panStatus
    this.state = {
      errorComponent: !panVerified
        ? (<Caption color='red'>Kindly verify your PAN Details. </Caption>)
        : null,
      loading: false
    }
    this.onPanNoChange = this.onPanNoChange.bind(this)
    this.onVerify = this.onVerify.bind(this)
  }

  onPanNoChange (value) {
    this.setState({
      errorComponent: (
        <Caption color='red'>
          Kindly verify your PAN Details.
        </Caption>
      )
    }, () => {
      this.props.onChange({
        panNo: value
      })
    })
  }

  async onVerify () {
    // loading set to true
    this.setState({
      loading: true
    })

    const { jointCompleteName } = this.props.getGlobalFormData()
    this.setState({
      errorComponent: (
        <Caption color='secondary'>
          Verifying Pan details ...
        </Caption>
      )
    })
    const response = await verifyPanNumber({
      panNo: this.props.formData.panNo,
      firstName: jointCompleteName.firstName,
      middleName: jointCompleteName.middleName,
      lastName: jointCompleteName.lastName
    })
    if (response.panStatus) {
      // make loading  false
      this.setState({
        loading: false
      })

      if (response.panCorrect) {
        this.setState({
          errorComponent: (
            <Caption color='green'>
              Pan verified successfully.
            </Caption>
          )
        }, () => {
          this.props.onChange({
            panNo: this.props.formData.panNo,
            verifiedDetails: response
          })
        })
      } else {
        this.setState({
          errorComponent: (
            <>
              <Caption color='red'>
                Complete name does not match that on the Card.
              </Caption>
              <Caption>
                {`Name provided: ${jointCompleteName.firstName} ${isNotDefined(jointCompleteName.middleName) ? '' : jointCompleteName.middleName} ${jointCompleteName.lastName}`}
                <br />
                {`Name on PAN Card: ${response.firstName} ${response.middleName && response.middleName} ${response.lastName}`}
                <br />
                {'Change PAN Number or '}
                <span
                  className='clickableLink' onClick={() => {
                    this.props.onFormDataChange(produce(this.props.getGlobalFormData(), (draft) => {
                      draft.jointCompleteName.firstName = capitalize(response.firstName)
                      draft.jointCompleteName.middleName = capitalize(response.middleName)
                      draft.jointCompleteName.lastName = capitalize(response.lastName)
                    }), {}, 'jointCompleteName')
                    this.setState({ errorComponent: null }, this.onVerify)
                  }}
                >
                  Update Name with name on Pan Card
                </span>
              </Caption>
            </>
          )
        })
      }
    } else {
      // make loading  false
      this.setState({
        loading: false
      })
      this.setState({
        errorComponent: (
          <Caption color='red'>
            Pan Number cannot be verified.
          </Caption>
        )
      })
    }
  }

  render () {
    const { errorComponent } = this.state
    const { schema, uiSchema, errorSchema, formData: { panNo, verifiedDetails }, disabled } = this.props
    const placeholder = uiSchema['ui:placeholder']
    const panVerified = isNotEmpty(panNo) && isNotEmpty(verifiedDetails) && verifiedDetails.panStatus
    return (

      <CustomFieldWrapper
        schema={schema}
        uiSchema={uiSchema}
        errorSchema={errorSchema}
      >
        <div className='flexContainer align-items-center gap-1'>
          {this.state.loading && <Loading id='pan_loading' open />}
          <PanWidget
            disabled={disabled || panVerified}
            as={Input}
            type='number'
            placeholder={placeholder}
            onChange={this.onPanNoChange}
            value={panNo}
            {...uiSchema.panNo}
          />
          {panVerified && (
            <Body1
              id='deletePan'
              className='verifyLink'
              color='red'
              onClick={() => this.onPanNoChange('')}
            >
              Delete
            </Body1>
          )}
          {!panVerified && (
            <Body1
              id='verifyPanLink'
              className='verifyLink'
              color='secondary'
              onClick={this.onVerify}
            >
              Verify
            </Body1>
          )}
        </div>
        {errorComponent}
      </CustomFieldWrapper>
    )
  }
}

export default JointPanField
