import GeneralInfoScreen from './GeneralInfoScreen'

const NoProductsForUser = (props) => {
  return (
    <GeneralInfoScreen
      className='noProductsForUser'
      {...props}
    />
  )
}
export default NoProductsForUser
