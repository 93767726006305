
import { useMemo, useRef } from 'react'
import FormDropdown from 'components/FormDropdown'
import InputWidget from './InputWidget'
import { isNotDefined, isNotEmpty } from 'utils'
function getValue (data, multiple) {
  if (multiple && data !== undefined && !(data.value instanceof Array)) {
    return [data.value]
  }

  return data.value
}

const SecondLevelWidget = (props) => {
  const componentRef = useRef({ disabled: true, applicable: false })
  const {
    id,
    value,
    required,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    className,
    formContext,
    formData,
    fieldKey,
    options
  } = props
  const { compact } = options
  const dataOptions = formContext[fieldKey]
  const dropdownOptions = []
  useMemo(() => {
    if (isNotEmpty(dataOptions)) {
      // given that the structure of all elements is same, from the first element we can identify what fields to look for.
      // eslint-disable-next-line
      const formDataKeys = Object.keys(formData).filter((element) => {
        if (!isNotDefined(formData[element]) && !isNotDefined(dataOptions[0][element])) {
          return true
        }
      })
      const applicable = Object.keys(dataOptions[0]).some((_element) => {
        return (dataOptions[0] !== 'display' && dataOptions[0] !== 'value')
      })
      if (applicable) {
        componentRef.current.applicable = true
      }
      dataOptions.forEach((item) => {
        let check = !applicable
        if (isNotEmpty(formDataKeys)) {
          componentRef.current.disabled = false
          check = formDataKeys.every((element) => item[element] === formData[element])
        }
        if (check) {
          dropdownOptions.push({
            key: item.value,
            text: item.display,
            value: item.value
          })
        }
      })
    }
  },
  // eslint-disable-next-line
  [ dataOptions, formData ])

  if (!componentRef.current.applicable && !isNotEmpty(dropdownOptions)) {
    return (
      <InputWidget
        {...props}
      />
    )
  }
  return (
    <FormDropdown
      id={(!id) ? 'selectButtonWidget' : id}
      className={className}
      compact={compact || false}
      placeholder={placeholder}
      options={dropdownOptions}
      multiple={multiple}
      defaultValue={value}
      disabled={componentRef.current.applicable ? componentRef.current.disabled : false}
      required={required}
      autoFocus={autofocus}
      readOnly={readonly}
      fluid={!compact}
      onBlur={
        onBlur &&
        ((event, self) => {
          const newValue = getValue(self, multiple)
          onBlur(id, newValue)
        })
      }
      onFocus={
        onFocus &&
        ((event, self) => {
          const newValue = getValue(self, multiple)
          onFocus(id, newValue)
        })
      }
      onChange={(event, self) => {
        const newValue = getValue(self, multiple)
        onChange(newValue)
      }}
      onLabelClick={(event, self) => {
        const newValue = getValue(self, multiple)
        onChange(newValue)
      }}
    />
  )
}

export default SecondLevelWidget
