import { Header, Form, Menu } from 'semantic-ui-react'

const FilterMenuMobileTerm = (props) => {
  const { ComponentMap } = props
  return (
    <>
      <Menu.Item>
        <Form>
          <Header color='black' sub>Cover Upto</Header>
          {ComponentMap.coverTermTillAge}
        </Form>
      </Menu.Item>
      <Menu.Item>
        <Form>
          <Header color='black' sub>Sum Assured</Header>
          {ComponentMap.sumAssured}
        </Form>
      </Menu.Item>
    </>
  )
}

export default FilterMenuMobileTerm

/* Below is filter code */
/* <Subtitle1 color='dullGrey' className='sectionTitle'>
  {'Cover Upto & Sum Assured'}
</Subtitle1> */
/* <Divider />
{
  filterOrder.map((k, index) => {
    const config = filterMenuConfig[k]
    return (
      <React.Fragment key={index}>
        <Subtitle1 color='dullGrey' className='sectionTitle'>
          {config.title}
        </Subtitle1>
        <Menu.Item
          id={k}
          key={`${index}mobile_menu`}
        >
          <SingleCheckBox
            config={config}
            filterData={filters.filterData}
            onFilterChange={this.onFilterChange}
            currentFilterData={currentFilterData}
            filterMenu
          />
        </Menu.Item>
        {(index < filterOrder.length - 1) && <Divider />}
      </React.Fragment>
    )
  })
} */
