/**
   * Gets the values of resetValues based on 'key'
   *
   * @param {*} key
   * @returns object
   */
const resetProductDataKeys = (key, insuranceType) => {
  const resetValues = {
    term: {
      incomePayout: {
        percentOfSumAssured: 0,
        income: 0,
        incomePeriod: 0,
        incomeFrequency: 'M',
        incomeIncreasePercentage: 0,
        lumpsumPercentage: 0,
        remainingPercentage: 0
      },
      rop: {
        addRop: false,
        percentOfPremumPaid: 0
      },
      wop: {
        adWop: false,
        ciWop: false,
        tiWop: false
      },
      isa: {
        addSaTopup: false,
        isaPercent: 0
      },
      lsc: {
        addLifeStageCover: false
      },
      whl: {
        addWholeLife: false
      },
      partner: {
        addPartner: false,
        partnerSumAssured: 0
      },
      ti: {
        percentForTi: 0,
        addTiPayout: false
      },
      tpd: {
        percentForTpd: 0,
        addTpdPayout: false
      },
      adb: {
        riderSumAssured: 0,
        percentOfBase: 0
      },
      adbi: {
        riderSumAssured: 0,
        percentOfBase: 0,
        incomeFrequency: 'M',
        incomePeriod: 10
      },
      adbrop: {
        riderSumAssured: 0
      },
      ci: {
        riderSumAssured: 0,
        percentOfBase: 0,
        riderPpt: 0,
        riderPt: 0
      },
      hc: {
        dailyCashLimit: 0
      },
      hsc: {
        benefitAmount: 0
      },
      ibad: {
        riderSumAssured: 0,
        percentOfBase: 0,
        incomeFrequency: 'M',
        incomePeriod: 10,
        riderPpt: 0,
        riderPt: 0
      }
    },
    car: {
      125: {
        include: false
      },
      126: {
        cpaYear: 0,
        include: false
      },
      127: {
        include: false
      },
      128: {
        include: false
      },
      129: {
        include: false
      },
      130: {
        include: false
      },
      131: {
        include: false
      },
      132: {
        include: false
      },
      133: {
        include: false
      },
      134: {
        include: false
      },
      135: {
        include: false
      },
      136: {
        idvForEA: 0,
        include: false
      },
      137: {
        idvForNonEA: 0,
        include: false
      },
      138: {
        idvforCL: 0,
        selectedFuelType: 'CNG',
        include: false
      },
      139: {
        include: false
      },
      140: {
        include: false
      },
      141: {
        include: false
      },
      142: {
        include: false
      },
      143: {
        include: false
      },
      144: {
        include: false
      },
      145: {
        include: false
      },
      147: {
        include: false
      },
      155: {
        noOfPassengersToBeCovered: 0,
        riderSumAssured: 0,
        include: false
      },
      156: {
        riderSumAssured: 0,
        include: false
      },
      157: {
        noOfPaidDrivers: 0,
        include: false
      },
      158: {
        noOfEmployees: 0,
        include: false
      },
      159: {
        include: false
      },
      160: {
        include: false
      },
      167: {
        include: false
      },
      168: {
        include: false
      },
      paymentOption: 'SP',
      paymentFrequency: 'S',
      ppt: 1
    },
    bike: {
      126: {
        cpaYear: 0,
        include: false
      },
      127: {
        include: false
      },
      129: {
        include: false
      },
      131: {
        include: false
      },
      133: {
        include: false
      },
      134: {
        include: false
      },
      141: {
        include: false
      },
      142: {
        include: false
      },
      144: {
        include: false
      },
      155: {
        riderSumAssured: 0,
        include: false
      },
      157: {
        include: false
      },
      159: {
        include: false
      },
      166: {
        include: false
      },
      168: {
        include: false
      },
      paymentOption: 'SP',
      paymentFrequency: 'S',
      ppt: 1
    },
    health: {
      ru: {
        include: false,
        diamond: true
      },
      ursi: {
        include: false,
        diamond: true
      },
      sncb: {
        include: false,
        diamond: true
      },
      ci: {
        include: false,
        riderSumAssured: 0
      },
      hc: {
        include: false,
        riderSumAssured: 0
      },
      icmi: {
        include: false,
        riderSumAssured: 0
      },
      pa: {
        include: false,
        riderSumAssured: 0
      },
      cd: {
        include: false,
        hypertension: false,
        diabetes: false,
        asthma: false,
        hyperlipidemia: false
      },
      wc: {
        include: false
      },
      paymentOption: 'RP',
      ppt: 1,
      sumAssuredRange: {
        min: 500000,
        max: 600000
      },
      paymentFrequency: 'A',
      coverTerm: 1
    },
    annuity: {}
  }
  return resetValues[insuranceType][key]
}

export default resetProductDataKeys
