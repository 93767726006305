import DataLoader from 'components/DataLoader'
import { Button, Card, Grid, GridColumn, GridRow, List, Modal, Segment } from 'semantic-ui-react'
import { Body1, Body2, Header } from 'components/Header'
import { getStore } from 'smartcovr_api/src/store'
import { isString, startCase } from 'lodash'
import { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { getDisplayData } from 'screens/helper/productHelper'
import { isNotDefined } from 'utils/lib/basic'
import BookButton from '../product/BookButton'

const styles = {
  contentStyle: {
    width: 250,
    fontSize: 14,
    opacity: 1,
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
  },
  position: {
    width: '30%',
    minWidth: '18rem'
  },
  image: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    display: 'unset'
  }
}

const getMakeModel = (formMakeModel = {}, makeModel = [], fuelTypes = [], variants = []) => {
  const { make, model, fuelType, variant } = formMakeModel
  const { makeName, modelName } = makeModel.find(vehicle => vehicle.makeId === make && vehicle.modelId === model) ?? {}
  const variantName = variants.find(varData => varData.variantId === variant)?.variantName ?? ''
  const fuelTypeName = fuelTypes.find(fuel => fuel.fuelTypeId === fuelType)?.display ?? ''
  return [makeName, modelName, fuelTypeName, variantName].join(' ')
}

const renderListItem = (title, content) => {
  return (
    <List.Item className='my-1'>
      <List.Header as={Body2}>{title}</List.Header>
      <List.Content as='small'>{content ?? 'N/A'}</List.Content>
    </List.Item>
  )
}

const formatDate = (date) => {
  return date && new Date(date).toLocaleDateString('en-GB').replace(/\//g, '-')
}

const InspectionMaodal = ({ inspectionModal, setInspectionModal }) => {
  return (
    <Modal style={styles.position} open={inspectionModal}>
      <Modal.Header>Note</Modal.Header>
      <Modal.Content style={styles.modalContent}>
        <Body2 style={styles.body} color='grey'>
          Your policy has been upgraded; vehicle inspection is required.
        </Body2>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => setInspectionModal(false)}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const AutoModifyCover = (props) => {
  const { handleBuyNow, product, device, formData, productData, configure } = props
  const { onboard, common, productListing } = configure
  const { onboardMetaData: { makeModel, fuelType, variant } } = onboard
  const formMakeModel = isString(formData.makeModel) ? JSON.parse(formData.makeModel) : formData.makeModel
  const vehicleName = getMakeModel(formMakeModel, makeModel, fuelType, variant)
  const manufacturinngYear = formMakeModel?.manufacturingYear
  const updatedProduct = getDisplayData({
    product,
    formData,
    productData,
    paymentFrequencies: common.paymentFrequencies,
    paymentOptions: productListing.paymentOptionsPriority
  })
  const { idv } = updatedProduct
  const [inspectionModal, setInspectionModal] = useState(false)

  useEffect(() => {
    if (!isNotDefined(updatedProduct.isBreakIn) && updatedProduct.isBreakIn) {
      setInspectionModal(true)
    }
  }, [updatedProduct.isBreakIn])

  const renderModal = () => {
    if (!isNotDefined(updatedProduct.isBreakIn) && updatedProduct.isBreakIn) {
      return (
        <InspectionMaodal {...{ inspectionModal, setInspectionModal }} />
      )
    }
  }
  return (
    <Card>
      <Card.Header className='px-3 py-2'>
        <Body1 color='primaryLight'>Policy Details: <span>{startCase(formData.policyType)}</span></Body1>
      </Card.Header>
      {formData.insuranceType === 'car' && renderModal()}
      <Card.Content>
        <Grid stackable>
          <GridRow columns='2'>
            <GridColumn>
              <Header as={Body1} attached='top'>
                Vehicle Details
              </Header>
              <Segment attached>
                <List>
                  {renderListItem('Vehicle Name', vehicleName)}
                  {renderListItem('Manufacturing Year', manufacturinngYear)}
                  {renderListItem('Vehicle Registration Number', formData.vehicleRegistrationNumber)}
                  {renderListItem('Engine Number', formData.engineNumber)}
                  {renderListItem('Chassis Number', formData.chassisNumber)}
                </List>
              </Segment>
            </GridColumn>
            <GridColumn>
              <Header as={Body1} attached='top'>
                Previous Policy Details
              </Header>
              <Segment attached>
                <List>
                  {renderListItem('Previous Policy Number', formData.policyNumber)}
                  {renderListItem('Policy Start Date', formatDate(formData.policyStartDate))}
                  {renderListItem('Policy End Date', formatDate(formData.policyEndDate))}
                  {renderListItem('Vehicle IDV', idv > 0 ? idv : undefined)}
                  {renderListItem('Old NCB %', formData.oldNcbPercentage)}
                </List>
              </Segment>

            </GridColumn>
          </GridRow>
        </Grid>
      </Card.Content>
      <Card.Content extra className='align-self-center'>
        <BookButton
          fluid
          onClick={handleBuyNow}
          product={updatedProduct}
          actionText='Modify Cover'
          Loader={DataLoader}
          id='buy-now-btn'
          device={device}
          className='mt-3'
        />
      </Card.Content>
    </Card>
  )
}
export default connect((state, props) => {
  const { insuranceType, formData } = props
  const store = getStore()
  const selection = store.select(models => ({
    product: models.insuranceProducts.getProductById
  }))
  const selectordata = selection(state, { productOptionId: formData.productOptionId, insuranceType })
  return {
    ...props,
    ...selectordata
  }
})(AutoModifyCover)
