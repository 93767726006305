import DisplayHtmlString from 'components/DisplayHtmlString'
import { Caption } from 'components/Header'

const styles = {
  fontSize: '11.5px'
}

function ProductOption (props) {
  const { productSelectionDetails, changeChosenProduct, device } = props
  return (
    <>
      {productSelectionDetails.map((po, index) => {
        const { productOptionInsurerId, productOptionDescription, productOptionName, available } = po
        return (
          <div key={`key${index}`}>
            <div>
              <input
                type='radio'
                name='productOption'
                value={productOptionInsurerId}
                checked={productOptionInsurerId === props.product.insurerId}
                onChange={(e) => { changeChosenProduct(e.target.value) }}
                id={productOptionInsurerId}
              />
              {device === 'mobile'
                ? (<label style={styles} htmlFor={productOptionInsurerId}>{productOptionName}</label>)
                : (<label htmlFor={productOptionInsurerId}>{productOptionName}</label>)}
            </div>
            <Caption className='desc'>
              {!available && 'This product is not available for the given configuration'}
              {available && <DisplayHtmlString htmlContent={productOptionDescription} />}
            </Caption>
          </div>
        )
      })}
    </>
  )
}

export default ProductOption
