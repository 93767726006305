import { forwardRef } from 'react'
import AddBox from '@mui/icons-material/AddBox'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import Remove from '@mui/icons-material/Remove'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'
import MaterialTable from '@material-table/core'
import { TablePagination } from '@mui/material'
import './tableComponent.scss'

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}
const TableComponent = (props) => {
  const { selectorItemData: { data, count }, setPageRow, setPageChange, setSkipPage, pageRow, pageChange, columns } = props

  columns && columns.forEach(item => {
    item.headerStyle = { paddingBottom: '1rem', borderRight: '1px solid #b2bec3' }
    item.cellStyle = {
      textAlign: 'center', borderRight: '1px solid #b2bec3', wordWrap: 'break-word'
    }
  })

  return (
    <div className='reportTableDiv'>
      <MaterialTable
        // title={customTitle}
        icons={tableIcons}
        columns={columns}
        data={data}
        // localization={{
        //   toolbar: {
        //     exportTitle: 'Download',
        //   }
        // }}
        components={{
          Pagination: paginationProps => (
            <TablePagination
              {...paginationProps}
              rowsPerPageOptions={[10, 25, 100]}
              rowsPerPage={pageRow}
              count={count}
              page={pageChange > (count / pageRow) ? 0 : pageChange}
              onPageChange={(_e, page) => {
                setPageChange(page)
                setSkipPage(page * pageRow)
              }}
              onRowsPerPageChange={event => {
                setPageRow(parseInt(event.target.value))
                setPageChange(0)
                setSkipPage(0)
              }}
            />
          )
        }}
        options={{
          search: false,
          toolbar: false,
          // exportButton: true,
          // toolbarButtonAlignment: 'left',
          emptyRowsWhenPaging: false,
          headerStyle: { backgroundColor: '#999999', textAlign: 'center', whiteSpace: 'nowrap', position: 'sticky', top: 0, zIndex: 1 },
          rowStyle: {
            fontSize: 13,
            whiteSpace: 'nowrap'
          },
          // exportFileName: customeFileNameWithCurrentDate(text),
          maxBodyHeight: '16rem',
          pageSize: pageRow,
          // pageSizeOptions: [10, 25, 100],
          pagination: true
        }}
      // onChangeRowsPerPage={(rows) => { setPageRow(rows) }}
      // onChangePage={(page) => { setPageChange(page) }}
      />
    </div>
  )
}
export default TableComponent
