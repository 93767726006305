import { useState } from 'react'
import { isNotDefined, isNotEmpty } from 'utils'
import InputWidget from '../widgets/InputWidget'
import LabelField from '../components/LabelField'
import FormHint from '../components/FormHint'

const getError = (key, errorSchema) => {
  return errorSchema[key]?.__errors
}

const RegistrationNumberWithLocationField = (props) => {
  const { formData, schema, uiSchema, formContext: { rtos }, errorSchema, idSchema } = props
  const [state, setState] = useState(initialize())

  function initialize () {
    if (formData.vehicleRegistrationNumber) {
      let [state, code] = formData.vehicleRegistrationNumber.split('-')
      code = code?.padStart(2, '0')
      const rgnCd = state + code
      const response = rtos.find(ele => ele.rgnCd === rgnCd)
      return { registrationLocation: response?.name, vehicleRegistrationNumber: formData.vehicleRegistrationNumber }
    }
    return { vehicleRegistrationNumber: '', registrationLocation: '' }
  }

  const onRegistrationNumberChange = (maskValue) => {
    const withoutMaskedValue = maskValue.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
    if (!isNotDefined(withoutMaskedValue) && withoutMaskedValue.length > 6 && withoutMaskedValue.length <= 11) {
      const maskedValue = maskValue.toUpperCase().replace(/^([a-zA-Z]{2})(\d{1,2})([a-zA-Z]{1,3})(\d{3,4})$/g, '$1-$2-$3-$4')
      let [state, code] = maskedValue.split('-')
      code = code?.padStart(2, '0')
      const rgnCd = state + code
      let rtoResponse
      if (isNotEmpty(rtos)) {
        rtoResponse = rtos.find(ele => ele.rgnCd === rgnCd)
      }
      if (isNotDefined(rtoResponse)) {
        setState({ registrationLocation: '', vehicleRegistrationNumber: maskedValue })
        return props.onChange({ vehicleRegistrationNumber: maskedValue, registrationLocation: '' })
      } else {
        setState({ registrationLocation: rtoResponse.name, vehicleRegistrationNumber: maskedValue })
        return props.onChange({ vehicleRegistrationNumber: maskedValue, registrationLocation: rtoResponse.rtoId })
      }
    }
    setState({ registrationLocation: '', vehicleRegistrationNumber: withoutMaskedValue })
    return props.onChange({ vehicleRegistrationNumber: withoutMaskedValue, registrationLocation: '' })
  }

  const { properties } = schema
  const { vehicleRegistrationNumber, registrationLocation } = uiSchema
  const regLocPlaceholder = (
    <span style={{ color: 'rgba(191, 191, 191, 0.87)', fontWeight: 'lighter', userSelect: 'none' }}>
      {registrationLocation?.['ui:placeholder']}
    </span>
  )
  return (
    <>
      <div>
        <InputWidget
          id={idSchema.vehicleRegistrationNumber.$id}
          options={{ inputType: 'text' }}
          value={(isNotDefined(state.vehicleRegistrationNumber)) ? '' : state.vehicleRegistrationNumber}
          onChange={(text) => {
            onRegistrationNumberChange(text)
          }}
          placeholder={vehicleRegistrationNumber?.['ui:placeholder']}
          schema={properties.vehicleRegistrationNumber}
        />
        <FormHint
          id={`${idSchema.vehicleRegistrationNumber.$id}-error`}
          showerror={errorSchema?.vehicleRegistrationNumber?.__errors.length > 0}
          rawErrors={getError('vehicleRegistrationNumber', errorSchema)}
        />
      </div>
      <div>
        <LabelField
          className='control-label mt-2'
          htmlFor='label'
          title={properties.registrationLocation?.title}
        />
        <LabelField
          className='control-label font-weight-normal pl-3 text-secondary d-block'
          htmlFor='RegistrationLocation'
          style={{ border: '1px solid rgba(34, 36, 38, 0.1)', padding: '0.67857143em 1em', borderRadius: '0.28571429rem' }}
          title={state.registrationLocation ? state.registrationLocation : regLocPlaceholder}
        />
        <FormHint
          id={`${idSchema.registrationLocation.$id}-error`}
          showerror={errorSchema?.registrationLocation?.__errors.length > 0}
          rawErrors={getError('registrationLocation', errorSchema)}
        />
      </div>
    </>
  )
}

export default RegistrationNumberWithLocationField
