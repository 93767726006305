import { connect } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { redirectToLogin } from 'smartcovr_api/src/insuranceApi'
import config from './config'

const LoginWrapper = ({ children, id, ...restProps }) => {
  const { loggedIn, isDistributorLoggedIn, location: { pathname }, distributorId, enquiryId, onLogout, device } = restProps
  const params = useParams()
  const navigate = useNavigate()
  const { authPaths } = config
  const { insuranceType } = params
  const hasLogin = authPaths.indexOf(id) > -1

  const redirectToLoginFn = ({ configType, redirectionHandlerName }) => redirectToLogin({
    redirectTo: pathname,
    redirectionHandlerName,
    insuranceType,
    distributorId,
    enquiryId,
    configType
  })

  const redirectToPathFn = (path, backpath, backpathText, onClickOfBackPath) => {
    let route = insuranceType && !path.includes('claim-landing') ? `/${insuranceType}/${path}` : path
    const availableProducts = restProps.availableProducts.some((products) => {
      return path.includes(products)
    })
    if (path.includes('dashboard') || path === '/' || availableProducts) {
      route = path
    } else if (path === 'customer-holdings') {
      route = `/${insuranceType ?? restProps.availableProducts[0]}/${path}`
    } else if (path === 'claim-landing') {
      route = `/${path}`
    }
    // Hard coded route
    if (path.includes('claim-landing')) {
      route = '/claim-landing'
    }
    const backpathRoute = backpath ?? pathname
    navigate(route, {
      state: {
        backpath: backpathRoute,
        backpathText,
        onClickOfBackPath
      }
    })
  }

  const loginProps = {
    hasLogin,
    loggedIn,
    isDistributorLoggedIn,
    redirectToLogin: redirectToLoginFn,
    redirectToPath: redirectToPathFn,
    onLogout
  }

  const screenProps = {
    ...restProps,
    insuranceType,
    redirectToLogin: loginProps.redirectToLogin,
    redirectToPath: redirectToPathFn
  }

  // Passed to Router.js as render props
  return children({ screenProps, loginProps, insuranceType, device })
}

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, enquiryId } = ownProps
  const selection = ownProps.store.select(models => ({
    distributorId: models.configure.getDistributorId,
    availableProducts: models.configure.getAvailableProducts,
    ownCustomerLogin: models.configure.getOwnCustomerLogin
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    ...selectorData,
    insuranceType,
    enquiryId,
    loggedIn: state.customer.loggedIn,
    isDistributorLoggedIn: state.customer.keycloakUserLoggedIn
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: dispatch.customer.logout
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginWrapper)
