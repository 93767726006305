import 'react-loading-skeleton/dist/skeleton.css'
import { Card, List, Statistic } from 'semantic-ui-react'
import { H6, Header, Subtitle2, Caption, WrapContent, Body2 } from 'components/Header'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
const CardViewSkeleton = (props) => {
  return (
    <Card className='productCard'>
      <SkeletonTheme highlightColor='#dedfe0'>
        <Skeleton height={24} width={47} />
        <H6 color='primaryLight'>
          <Skeleton width={160} />
          <Header.Subheader>
            <Skeleton width={160} />
          </Header.Subheader>
        </H6>
        <List>
          <List.Item className='flexItems'>
            <List.Content className='twoItemsFlex'>
              <Card.Description textAlign='center'>
                <Statistic color='green'>
                  <Statistic.Label>
                    <Skeleton width={100} />
                  </Statistic.Label>
                  <Statistic.Value>
                    <Skeleton width={50} />
                  </Statistic.Value>
                </Statistic>
              </Card.Description>
            </List.Content>
            <List.Content className='twoItemsFlex'>
              <Card.Description textAlign='center'>
                <Statistic color='green'>
                  <Statistic.Label>
                    <Skeleton width={100} />
                  </Statistic.Label>
                  <Statistic.Value>
                    <Skeleton width={50} />
                  </Statistic.Value>
                </Statistic>
              </Card.Description>
            </List.Content>
          </List.Item>
        </List>
        <div className='ridersAndFeatures'>
          <Subtitle2 color='secondary'>
            <Skeleton width={120} />
          </Subtitle2>
          <div>
            <List>
              <List.Item>
                {Array.from(Array(2).keys()).map((idx) => (
                  <WrapContent key={`skeleton-${idx}`} className='group'>
                    <Body2 color='green' floated='right'>
                      <Skeleton width={50} />
                    </Body2>
                    <Header.Content className='riderContent' style={{ maxWidth: '75%' }}>
                      <Caption className='riderCaption' style={{ maxWidth: 'unset' }}>
                        <Skeleton width={15} />
                        <Skeleton width={130} />
                        <Skeleton width={200} />
                      </Caption>
                    </Header.Content>
                  </WrapContent>
                ))}
              </List.Item>
            </List>
          </div>
        </div>
        {props.insuranceType === 'term' && (
          <div className='pay outTerms'>
            <Subtitle2 color='secondary'>
              <Skeleton width={120} />
            </Subtitle2>
            <Caption>
              <Skeleton />
            </Caption>
          </div>
        )}
        <div className='pegToBottom1 d-flex'>
          <Skeleton width={80} height={30} />
          <Skeleton width={80} height={30} />
        </div>
      </SkeletonTheme>
    </Card>
  )
}
export default CardViewSkeleton
