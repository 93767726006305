import { PureComponent } from 'react'
import Login from 'components/Login'
import config from './config.js'

class LoginComponent extends PureComponent {
  render () {
    const { loggedIn, isDistributorLoggedIn, redirectToLogin, hasLogin, path } = this.props
    if (!hasLogin) {
      return (
        this.props.children
      )
    }
    if (!isDistributorLoggedIn && path === 'dashboard') {
      return (
        <Login
          redirectToLogin={redirectToLogin}
          configType={config.configTypeForKeyCloakLogin}
          redirectionHandlerName={config.redirectHandlerForKeyCloakLogin}
        />
      )
    }
    if (!loggedIn && path !== 'dashboard') {
      return (
        <Login
          redirectToLogin={redirectToLogin}
          configType={config.configTypeForHsecLogin}
          redirectionHandlerName={config.redirectHandlerForHsecLogin}
        />
      )
    }
    return (
      this.props.children
    )
  }
}
export default LoginComponent
