import { unescape } from 'lodash'
import { buildDistributorAndInsuranceTypeImagePath } from 'components/buildImagePath'

const OnboardHeader = ({ title, background, image, description, isLoggedIn, redirectToPath, device, textColor }) => {
  if (isLoggedIn) {
    return (
      <header className='welcomeHeader'>
        <div className='jumbotron'>
          <div className='rowCenter container row'>
            <div className='w-auto'>
              <h1 className='text-center'>Welcome back</h1>
              <p className='text-center'>
                Check your existing and incomplete policies.
              </p>
              <br />
              <input
                onClick={() => redirectToPath('customer-holdings')}
                type='button'
                className='submit-btn text-center'
                value='Go to holdings'
              />
            </div>
          </div>
        </div>
      </header>
    )
  }
  return (
    <header className='onboardHeader'>
      <div
        className='intro-section' style={{
          backgroundImage: `url(${buildDistributorAndInsuranceTypeImagePath(background)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='jumbotron'>
          <div className='container'>
            <div className='row align-items-center'>
              {/* Section 1 */}
              <div className='col-md-6 col-sm-12 col-xs-12 wow zoomIn' data-wow-delay='300ms'>
                <h1 className='text-center' style={{ color: textColor }}>{unescape(title)}</h1>
                <p className='text-center banner-desc' style={{ color: textColor }}>{unescape(description)}</p>
              </div>
              {/* Section 2 */}
              {device === 'web' && image && (
                <div className='d-flex justify-content-center col-md-6 col-sm-12 col-xs-12 wow zoomIn' data-wow-delay='300ms'>
                  <img className='img-fluid' src={`${buildDistributorAndInsuranceTypeImagePath(image)}`} alt='bannerimage' />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default OnboardHeader
