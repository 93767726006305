
import { Component } from 'react'
import PropTypes from 'prop-types'
import produce from 'immer'
import { isNotDefined } from 'utils'
// import ErrorDisplay from 'components/ErrorDisplay'
import { JsonSchemaMultiPageForm } from 'components/jsonSchemaForms'
import FetchDataLoader from 'components/FetchDataLoader'
import IntermediateScreen from 'components/IntermediateScreen'
import FormReview from 'components/jsonSchemaForms/FormReview'
import PaymentSummary from './PaymentSummary'
import Payment from './Payment'
import ApplicationConfirmationPage from './ApplicationConfirmationPage'
import OtpScreen from './OtpScreen'
import InspectionSummary from './InspectionSummary'
import TermsAndCondition from './TermsAndCondition'

/* eslint react/jsx-handler-names: "off" */

const dividedSegment = {
  width: '40%',
  minWidth: 447,
  margin: 'auto'
}
class Forms extends Component {
  constructor (props) {
    super(props)
    this.state = {
      validateFlags: this.initializeValidateFlags(),
      fileName: undefined
    }
    this.setLiveValidate = this.setLiveValidate.bind(this)
    this.previousBookingStep = this.previousBookingStep.bind(this)
    this.nextBookingStep = this.nextBookingStep.bind(this)
    this.onReviewEdit = this.onReviewEdit.bind(this)
  }

  initializeValidateFlags () {
    return produce(this.props.allSteps, draft => {
      draft.forEach((step, index) => {
        if (step.stepType === 'multi') {
          step.steps.forEach((singleStep, j) => {
            if (isNotDefined(singleStep.liveValidate)) {
              draft[index][j] = false
            } else {
              draft[index][j] = singleStep.liveValidate
            }
          })
        }
        draft[index] = step
      })
    })
  }

  setLiveValidate () {
    const { applicationData: { currentStep, bookingStepIndex } } = this.props
    const { validateFlags } = this.state
    if (validateFlags[bookingStepIndex].stepType === 'multi') {
      if (!validateFlags[bookingStepIndex][currentStep]) {
        const newValidateFlags = produce(validateFlags, draftSteps => {
          draftSteps[bookingStepIndex][currentStep] = true
        })
        this.setState({ validateFlags: newValidateFlags })
      }
    }
  }

  onReviewEdit (formData, stepDetails) {
    const { setApplicationFormData, institutionId } = this.props
    setApplicationFormData({
      applicationFormData: formData,
      institutionId,
      stepDetails
    })
  }

  nextBookingStep (stepDetails, applicationFormData) {
    const { allSteps, applicationData: { bookingStepIndex }, setApplicationFormData, institutionId } = this.props
    if (bookingStepIndex < allSteps.length - 1) {
      const propObjects = {
        institutionId,
        bookingStepIndex: bookingStepIndex + 1,
        currentStep: 0,
        stepDetails
      }
      if (!isNotDefined(applicationFormData)) {
        propObjects.applicationFormData = applicationFormData
      }
      setApplicationFormData(propObjects)
    }
  }

  previousBookingStep (stepDetails) {
    const { applicationData: { bookingStepIndex }, setApplicationFormData, institutionId } = this.props
    if (bookingStepIndex > 0) {
      setApplicationFormData({
        institutionId,
        bookingStepIndex: bookingStepIndex - 1,
        currentStep: 0,
        stepDetails
      })
    } else {
      this.props.redirectToListing()
    }
  }

  render () {
    const { allSteps, productData, updateBookingLoading, enquiry, getFileUrl, insuranceType, setApplicationFormData, device, reviewSteps, hideSummary, sendMail, redirectToListing, multiSteps, applicationData, configure, institutionId, enquiryLoading, bookingType, product, distributorId, redirectToProductListing, redirectToHoldings } = this.props
    const formData = applicationData.applicationFormData
    const { currentStep, bookingStepIndex } = applicationData
    if (this.state.fileName) {
      return (
        <div style={dividedSegment}>
          {this.rightSegment(formData)}
        </div>
      )
    }
    if (this.state.done) {
      return <FetchDataLoader message='Generating Application Form ...' />
    }
    const applicableStep = allSteps[bookingStepIndex]
    const formSteps = applicableStep.steps
    const { title, desc } = applicableStep
    if (applicableStep.stepType === 'otp') {
      return (
        <OtpScreen
          updateBookingLoading={updateBookingLoading}
          applicableStep={applicableStep}
          nextBookingStep={(applicationFormData) => this.nextBookingStep(applicableStep, applicationFormData)}
          distributorId={distributorId}
          formData={formData}
          setApplicationFormData={setApplicationFormData}
          institutionId={institutionId}
        />
      )
    }
    if (applicableStep.stepType === 'confirmation') {
      return (
        <ApplicationConfirmationPage
          product={product}
          getFileUrl={getFileUrl}
          selectedProductDetails={formData.selectedProduct}
          redirectToProductListing={redirectToProductListing}
        />
      )
    }
    if (applicableStep.stepType === 'paymentSummary') {
      return (
        <PaymentSummary
          getFileUrl={getFileUrl}
          device={device}
          formData={formData}
          productData={productData}
          product={product}
          selectedProductDetails={formData.selectedProduct}
          configure={configure}
          nextBookingStep={() => this.nextBookingStep(applicableStep)}
          previousBookingStep={() => this.previousBookingStep(applicableStep)}
        />
      )
    }
    if (applicableStep.stepType === 'inspectionSummary') {
      return (
        <InspectionSummary
          configure={configure}
          product={product}
          inspectionDetails={formData.inspectionDetails}
          selectedProductDetails={formData.selectedProduct}
          redirectToHoldings={redirectToHoldings}
          redirectToProductListing={redirectToProductListing}
        />
      )
    }
    if (applicableStep.stepType === 'termsAndConditions') {
      return (
        <TermsAndCondition
          insuranceType={insuranceType}
          enquiry={enquiry}
          device={device}
          formData={formData}
          product={product}
          configure={configure}
          nextBookingStep={() => this.nextBookingStep(applicableStep)}
          previousBookingStep={() => this.previousBookingStep(applicableStep)}
        />
      )
    }
    if (applicableStep.stepType === 'payment') {
      return (
        <Payment
          insuranceType={insuranceType}
          enquiry={enquiry}
          formData={formData}
          product={product}
          selectedProductDetails={formData.selectedProduct}
          nextBookingStep={() => this.nextBookingStep(applicableStep)}
          previousBookingStep={() => this.previousBookingStep(applicableStep)}
        />
      )
    }
    if (applicableStep.stepType === 'info') {
      const intermediateStepProps = {
        bookingStepIndex,
        multiSteps,
        configure,
        bookingType,
        formData,
        intermediateStepDone: () => this.nextBookingStep(applicableStep),
        onBack: () => this.previousBookingStep(applicableStep),
        applicableStep
      }
      return <IntermediateScreen {...intermediateStepProps} />
    }
    if (applicableStep.stepType === 'multi' && applicableStep.displayKey === 'review') {
      return (
        <FormReview
          onReviewEdit={(formData) => this.onReviewEdit(formData, applicableStep)}
          reviewFormSteps={reviewSteps}
          formData={formData}
          device={device}
          countries={this.props.countries}
          applicableStep={applicableStep}
          nextBookingStep={() => this.nextBookingStep(applicableStep)}
          previousBookingStep={() => this.previousBookingStep(applicableStep)}
        />
      )
    }
    if (applicableStep.stepType === 'multi') {
      return (
        <JsonSchemaMultiPageForm
          stepDetais={{
            title,
            desc
          }}
          formSteps={formSteps}
          formData={formData}
          hideSummary={hideSummary}
          sendMail={sendMail}
          device={device}
          countries={this.props.countries}
          onDone={this.nextBookingStep}
          redirectToListing={redirectToListing}
          setLiveValidate={this.setLiveValidate}
          currentStep={currentStep}
          validateFlags={this.state.validateFlags}
          setApplicationFormData={setApplicationFormData}
          institutionId={institutionId}
          enquiryLoading={enquiryLoading}
          bookingStepIndex={bookingStepIndex}
          multiSteps={multiSteps}
          bookingType={bookingType}
          configure={configure}
          previousBookingStep={() => this.previousBookingStep(applicableStep)}
          nextBookingStep={() => this.nextBookingStep(applicableStep)}
          applicableStep={applicableStep}
          updateBookingLoading={updateBookingLoading}
        />
      )
    }
  }
}

Forms.propTypes = {
  product: PropTypes.object.isRequired,
  // loading: PropTypes.object.isRequired,
  enquiry: PropTypes.object.isRequired,
  device: PropTypes.string.isRequired,
  hideSummary: PropTypes.func.isRequired,
  showSummary: PropTypes.func.isRequired
}

export default Forms
