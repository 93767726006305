import Loading from 'components/Loading'
import TableComponent from 'components/TableComponent'
import { useEffect, useRef, useState } from 'react'
import { isNotDefined, isNotEmpty } from 'utils/lib/basic'
import { DashboardFilter } from './DashboardFilter'
import { dashboardDrawerItemMapper, validateDateRange, useDashboardLoading } from './distributorDashboardHelper'
import DownloadOptions from './DownloadOptions'
import './Dashboard.scss'

export const DashboardItemComponent = (props) => {
  const buttonRef = useRef()
  const { selectedItemKey } = props
  const skipDropoff = selectedItemKey === 'completedPaymentButformSubmissionPending' || selectedItemKey === 'submittedApplication' || selectedItemKey === 'conversionReport'
  const { schema, uiSchema, reports, rules } = props.configure.distributorDashboard
  const { selectorItemData: { count, filterValues }, insuranceType } = props
  const { columns } = dashboardDrawerItemMapper[insuranceType][selectedItemKey]
  const buildSchema = () => {
    return reports[selectedItemKey].fields.reduce((sc, field) => {
      sc.properties[field] = schema[field]
      return sc
    }, { type: 'object', required: reports[selectedItemKey].required, properties: {} })
  }
  const buildUISchema = () => {
    return reports[selectedItemKey].fields.reduce((uiSc, field) => {
      uiSc[field] = uiSchema[field]
      return uiSc
    }, {})
  }
  const loading = useDashboardLoading(selectedItemKey)
  const dropdownOptions = [
    { value: 'asc', text: 'Ascending' },
    { value: 'desc', text: 'Descending' }
  ]
  const [formData, setFormData] = useState({ ...filterValues })
  const [downloadRange, setDownloadRange] = useState([])
  const [pageRow, setPageRow] = useState(10)
  const [pageChange, setPageChange] = useState(0)
  const [skipPage, setSkipPage] = useState(0)
  const [selectedRangeValue, setSelectedRangeValue] = useState('0')

  function reset () {
    setPageRow(10)
    setPageChange(0)
    setSkipPage(0)
    setSelectedRangeValue('0')
  }

  // useEffect to trigger whenever the reports are changed based on the selectedItemKey
  useEffect(() => {
    reset()
    setFormData({ ...filterValues, limit: pageRow, skip: skipPage })
    window.scrollTo(0, 0)
  },
  // eslint-disable-next-line
  [selectedItemKey])

  // for api call on change of dropdowndown value
  useEffect(() => {
    const { dateFrom, dateTo, dropOffStage, order, fileType } = formData
    if (!isNotDefined(dateFrom) &&
      !isNotDefined(dateTo) &&
      (skipDropoff ? true : !isNotDefined(dropOffStage)) &&
      !validateDateRange(dateFrom, dateTo)) {
      onSubmit()
    } else {
      if (!isNotDefined(dateFrom) && !isNotDefined(dateTo) && validateDateRange(dateFrom, dateTo)) {
        buttonRef.current.click()
      } else {
        if ((order !== 'desc') || (isNotEmpty(fileType) || isNotDefined(fileType))) {
          buttonRef.current.click()
        }
      }
    }
  },
  // eslint-disable-next-line
  [formData.order])

  // on submit of json  schema form
  const onSubmit = () => {
    reset()
    dashboardDrawerItemMapper[insuranceType][selectedItemKey].effect({ filterValues: { ...formData, limit: pageRow, skip: skipPage } })
    if (formData.fileType) {
      delete formData.fileType
    }
  }

  // on change of json  schema form
  const onChange = (form) => {
    // setting unchanged values from fordata and updating/adding new values from form.formdata0
    setFormData({ ...formData, ...form.formData, limit: pageRow, skip: skipPage })
  }

  // on change of dropdown for order (Asc/Desc) of the records to be fetched
  const dropDownOnChange = (event) => {
    setFormData({ ...formData, limit: pageRow, skip: skipPage, order: event.target.value })
  }

  // report download options based on the fileType & skip value passed.
  // skip value is for report data range passed via data range selection control
  const downloadClick = (fileType, skip) => {
    dashboardDrawerItemMapper[insuranceType][selectedItemKey].effect({ filterValues: { ...formData, limit: 1000, skip: parseInt(skip), fileType, fileName: dashboardDrawerItemMapper[insuranceType][selectedItemKey].text } })
  }

  // functionality to create an array of the dropdown values to be considered for selection of values based on the count of the records returned
  const rangeArray = (rangeCount) => {
    const rangeTempArr = []
    for (let rangeValue = 0; rangeValue <= rangeCount; rangeValue += 1000) {
      rangeTempArr.push(rangeValue)
    }
    if (rangeCount > (rangeTempArr[rangeTempArr.length - 1])) {
      rangeTempArr.push(rangeTempArr[rangeTempArr.length - 1] + 1000
      )
    }
    setDownloadRange(rangeTempArr)
  }

  // functionality to display the dropdown range values in control for user to select.
  // Object key value pairs are considered to have mapping
  const displayRange = () => {
    const displayValue = downloadRange.map((value, index) => (
      {
        value,
        text: `${value + 1} - ${downloadRange[index + 1]}`
      }
    ))
    return displayValue.slice(0, downloadRange.length - 1)
  }

  // useEffect to trigger the creation of the range array based on count as a dependency
  useEffect(() => {
    if (count !== undefined) { rangeArray(count) }
  }, [count])

  // useEffect to trigger the pagination functionality api call for table report displayed on change of the skip and limit values passed
  useEffect(() => {
    if (count > 0) {
      dashboardDrawerItemMapper[insuranceType][selectedItemKey].effect({ filterValues: { ...formData, limit: pageRow, skip: skipPage } })
    }
  },
  // eslint-disable-next-line
  [pageRow, pageChange])

  const renderTable = () => {
    return count > 0 && (
      <TableComponent
        {...props}
        columns={columns}
        pageRow={pageRow}
        pageChange={pageChange}
        setSkipPage={setSkipPage}
        setPageRow={setPageRow}
        setPageChange={setPageChange}
      />)
  }

  return (
    <>
      {loading
        ? <Loading open />
        : (
          <div className='dashboardContentContainer'>
            <div className='dashboardHeader'>
              <span className='headingtext'>{dashboardDrawerItemMapper[insuranceType][selectedItemKey].text}</span>
            </div>
            <div className='dashboardContent'>
              <DashboardFilter buttonRef={buttonRef} count={count} schema={buildSchema()} uiSchema={buildUISchema()} rules={rules} onChange={onChange} formData={formData} onSubmit={onSubmit} />
              {count > 0 && (
                <div className='dashboardOptions'>
                  <DownloadOptions
                    downloadClick={downloadClick}
                    displayRange={displayRange}
                    device={props.device}
                    selectedRangeValue={selectedRangeValue}
                    setSelectedRangeValue={setSelectedRangeValue}
                    count={count}
                  />
                  <div className='sortFilterContainer'>
                    <label className='sortFilterLabel'>Sort:  </label>
                    <select className='sortFilterDropdown' value={formData.order} onChange={dropDownOnChange}>
                      {dropdownOptions.map(option => (
                        <option key={option.value} value={option.value}>{option.text}</option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
            {renderTable()}
          </div>
          )}
    </>
  )
}
