import DisplayHtmlString from 'components/DisplayHtmlString'
import { Caption, Header, Subtitle2 } from 'components/Header'

const Features = ({ features, device }) => {
  if (device === 'mobile') {
    return (
      <>
        {features.length !== 0 && (
          <div>
            <Header color='primaryLight' className='titleHeader'>
              Features
            </Header>
            {features.map((feature, index) => (
              <div key={index} className='featureContent'>
                <div className='featureHeadingAndDescription'>
                  <Subtitle2>{feature.heading}</Subtitle2>
                  <Caption className='featureDescription'><DisplayHtmlString htmlContent={feature.description} /></Caption>
                </div>
                <div className='featureType'>
                  <Subtitle2 className='featureTypeText'>{feature.type}</Subtitle2>
                </div>
              </div>
            ))}
          </div>
        )}
      </>
    )
  }
  return (
    <>
      {features.length !== 0 && (
        <div>
          <Header color='primaryLight' className='titleHeader'>
            Features
          </Header>
          {features.map((feature, index) => (
            <div key={index} className='featureContent'>
              <div className='featureHeadingAndDescription'>
                <Subtitle2>{feature.heading}</Subtitle2>
                <Caption className='featureDescription'><DisplayHtmlString htmlContent={feature.description} /></Caption>
              </div>
              <div className='featureType'>
                <Subtitle2 className='featureTypeText'>{feature.type}</Subtitle2>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
export default Features
