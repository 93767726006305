import { useState } from 'react'
import { isNotDefined } from 'utils'
import { getEnquiryId } from 'smartcovr_api/src/insuranceApi'

import ApplicationDetails from './ApplicationDetails'
import UserApplicationsListing from './UserApplicationsListing'
import ApplicationDetailsMobileView from './ApplicationDetailsMobileView'
import { Button, Modal } from 'semantic-ui-react'
import { Body2 } from 'components/Header'

const styles = {
  contentStyle: {
    width: 250,
    fontSize: 14,
    opacity: 1,
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
  },
  position: {
    width: '30%',
    minWidth: '18rem'
  },
  image: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    display: 'unset'
  }
}

const RecommendedModal = ({ recommendedModal, setRecommendedModal }) => {
  return (
    <Modal style={styles.position} open={recommendedModal}>
      <Modal.Header>Note</Modal.Header>
      <Modal.Content style={styles.modalContent}>
        <Body2 style={styles.body} color='grey'>
          Please open the payment link received from the HDFC Ergo and kindly make the payment.
        </Body2>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setRecommendedModal(false)}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}
const UserApplications = props => {
  const {
    configure,
    applications,
    loadingApplications,
    loadingStatus,
    loadApplications,
    isLoggedIn,
    getFormStepStatus,
    getSmartCovrStatus,
    selectedApplication,
    selectedPolicyData,
    updateSelectedApplicationNumber,
    buildEnquiry,
    getBookingFormType,
    redirectToPath,
    setContinueForm,
    getStatus,
    count,
    device
  } = props
  const [recommendedModal, setRecommendedModal] = useState(false)

  const continueHandler = async (applicationNumber, finanalyticsId) => {
    setContinueForm(true)
    const enquiryId = getEnquiryId()
    await buildEnquiry({ applicationNumber, finanalyticsId, enquiryId })
    const bookingFormType = getBookingFormType(applicationNumber)
    const smartcovrStatus = getSmartCovrStatus(applicationNumber)
    if (smartcovrStatus === 'SC096') {
      redirectToPath('select-product')
    } else if (bookingFormType === 'pre-payment') {
      redirectToPath('booking/pre-payment')
    } else if (bookingFormType === 'post-payment') {
      redirectToPath('booking/post-payment')
    }
  }

  const getStatusHandler = async (applicationNumber) => {
    getStatus(applicationNumber)
    const smartcovrStatus = getSmartCovrStatus(applicationNumber)
    if (smartcovrStatus === 'SC095') {
      setRecommendedModal(true)
    }
  }

  if (isNotDefined(selectedApplication) && !isNotDefined(selectedPolicyData)) {
    return null
  } else if (!isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)) {
    if (device === 'mobile') {
      return (
        <>
          <ApplicationDetailsMobileView
            getFormStepStatus={getFormStepStatus}
            configure={configure}
            application={selectedApplication}
            continueHandler={continueHandler}
            getStatusHandler={getStatusHandler}
            device={device}
          />
          <RecommendedModal recommendedModal={recommendedModal} setRecommendedModal={setRecommendedModal} />
        </>
      )
    }
    return (
      <>
        <ApplicationDetails
          getFormStepStatus={getFormStepStatus}
          configure={configure}
          application={selectedApplication}
          continueHandler={continueHandler}
          getStatusHandler={getStatusHandler}
          device={device}
        />
        <RecommendedModal recommendedModal={recommendedModal} setRecommendedModal={setRecommendedModal} />
      </>
    )
  } else if (isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)) {
    return (
      <>
        <UserApplicationsListing
          isLoggedIn={isLoggedIn}
          count={count}
          loadApplications={loadApplications}
          loadingApplications={loadingApplications}
          loadingModal={recommendedModal}
          loadingStatus={loadingStatus}
          applications={applications}
          getFormStepStatus={getFormStepStatus}
          getStatusHandler={getStatusHandler}
          continueHandler={continueHandler}
          updateSelectedApplicationNumber={updateSelectedApplicationNumber}
        />
        <RecommendedModal recommendedModal={recommendedModal} setRecommendedModal={setRecommendedModal} />
      </>
    )
  }
}
export default UserApplications
