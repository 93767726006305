import { has, isNotEmpty } from 'utils'
import FieldNotFound from './FieldNotFound'
import validateFields from './validateFields'
import FieldInvalid from './FieldInvalid'

/**
   * This functions checks if the all the values of keys exists in the "data"
   * if any key doesn't exist then error is thrown
   *
   * @param {*} keys
   * @param {*} data
   * @returns
   */
const checkForBadRequest = (keys, data) => {
  if (!isNotEmpty(data)) {
    throw new FieldInvalid.Data()
  }
  keys.forEach((key) => {
    if (!has(data, key)) {
      if (has(FieldNotFound, key)) {
        throw new FieldNotFound[key]()
      } else {
        throw new FieldNotFound.Default(`${key} not provided`)
      }
    }
    if (has(validateFields, key)) {
      if (!validateFields[key](data[key])) {
        if (has(FieldInvalid, key)) {
          throw new FieldInvalid[key]()
        } else {
          throw new FieldInvalid.Default(`${key} is not valid`)
        }
      }
    }
  })
}
export default checkForBadRequest
