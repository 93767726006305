export const oldClaimData = {
  claimRegistrationNumber: '12344567888',
  policyNumber: '252525654654',
  claimIntimationDate: '01/01/2023',
  claimSubmissionDate: '05/05/2023',
  claimSettledDate: '06/06/2023',
  claimStatus: 'Settled partly of 1,52,000/- and consumable claim rejected for 10,000/-'
}

export const currentClaimData = {
  claimRegistrationNumber: '12344567888',
  policyNumber: '252525654654',
  claimIntimationDate: '01/01/2023',
  claimSubmissionDate: '05/05/2023',
  claimSettledDate: '06/06/2023',
  claimStatus: 'Settled partly of 1,52,000/- and consumable claim rejected for 10,000/-',
  documentMessage: 'abc'
}
