import { getResetValueForKey } from 'smartcovr_api/src/insuranceApi'
import { findKey, isNotDefined } from 'utils/lib/basic'

/**
 * @description Reset all Product Data to default based on insuranceType
 * @param {object} productData
 * @param {string} insuranceType
 * @return {object}
 */
export const resetProductData = (productData, insuranceType) => {
  const newProductData = { ...productData }
  if (!isNotDefined(productData)) {
    Object.keys(productData).forEach(key => {
      if (typeof productData[key] === 'object') {
        const keyData = getResetValueForKey(key, insuranceType)
        Object.assign(newProductData, { [key]: keyData })
      }
    })
  }
  return newProductData
}

/**
 * @description On the basis of key get the  parent Object key
 * @param {object} obj
 * @param {string} field
 * @param {string} [parentKey='']
 * @param {Array<object>} [result=[]]
 * @return {Array<object>}
 */
function objectDeepKeys (obj, field, parentKey = '', result = []) {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null && k !== field) {
      parentKey = k
      objectDeepKeys(obj[k], field, parentKey, result)
    } else if (Object.prototype.hasOwnProperty.call(obj, k) && k === field) {
      result.push(parentKey)
    }
  }
  return result
}

/**
 * @description Removes the formData of field on the basis of dependency fields which are not sleected
 * @param {object} formData
 * @param {object} currentStepDependency
 * @return {object}
 */
export const omitExtraData = (formData, currentStepDependency) => {
  const allDepProperites = getNestedArrayOfObject(currentStepDependency, 'properties')
  const newFormData = Object.assign({}, formData)
  const allKeys = allDepProperites.reduce((fieldArr, prop) => {
    const key = findKey(prop.properties, (f) => !Object.prototype.hasOwnProperty.call(f, 'type'))
    fieldArr = [...new Set([...fieldArr, key])]
    return fieldArr
  }, [])
  allKeys.forEach(key => {
    const oneOfArr = getNestedArrayOfObject(currentStepDependency, key).find(obj => Object.prototype.hasOwnProperty.call(obj[key], 'oneOf'))
    const newOneOf = oneOfArr[key].oneOf.find(dep => {
      const obj = dep.properties[key]
      const fieldValue = Object.values(obj)[0]
      return !fieldValue.includes(formData[key])
    })
    const keys = objectDeepKeys(newOneOf, 'type')
    keys.forEach(key => {
      delete newFormData[key]
    })
  })
  return newFormData
}

/**
  * Returns an array of keys(string) extracted from an array of object based on the key passed
  *
  * @param {Array} arr
  * @param {string} key
  * @returns {Array<string>}
  *
  **/
export const extractKeys = (arr, key) => {
  return arr.reduce((newArr, pr) => {
    newArr = [...new Set([...newArr, ...Object.keys(pr[key])])]
    return newArr
  }, [])
}

/**
 * Recursively iterates over the `obj` once the `field` matches with a key in the object param,
 * then pushes it into `result` array
 * @param {object} obj
 * @param {string} field
 * @param {Array<object>} result
 * @return {Array<object>} `result`
 *
 **/
export const getNestedArrayOfObject = (obj, field, result = []) => {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null && k !== field) {
      getNestedArrayOfObject(obj[k], field, result)
    } else if (Object.prototype.hasOwnProperty.call(obj, k) && k === field) {
      result.push({ [k]: obj[k] })
    }
  }
  return result
}

/**
 *
 * @param {object} userFormData
 * @param {string} key
 * @return {object} `field` and `value`
 *
 */
export const extractFieldValue = (userFormData, key) => {
  const keys = Object.keys(userFormData)
  const field = keys.find((k) => k === key)
  const value = userFormData[field]
  return { field, value }
}

/**
 * This function is used in transformErrors to extract from schema, and (else) in validations to check if field is undefined in formData
 *
 * @param {object} obj
 * @param {string} key
 * @param {string | undefined} [id=undefined]
 * @param {object} [result={}]
 * @return {object}
 *
 */
export const extractNestedObject = (obj, key, id = undefined, result = {}) => {
  for (const k in obj) {
    if (typeof obj[k] === 'object' && obj[k] !== null && k !== key) {
      extractNestedObject(obj[k], key, id, result)
    } else if (Object.prototype.hasOwnProperty.call(obj, k) && k === key) {
      if (isNotDefined(id) || Object.prototype.hasOwnProperty.call(obj[k], 'type')) {
        result = Object.assign(result, { [k]: obj[k] })
      }
    }
  }
  return result
}

/**
 *
 * Returns `true` if ButtonWidget is present in current uiSchema, otherwise `false`
 * @param {object} uiSchema
 * @return {boolean}
 *
 **/
export const checkButtonWidget = (uiSchema) => {
  const widget = getNestedArrayOfObject(uiSchema, 'ui:widget')
  return widget.some(w => w['ui:widget'] === 'ButtonWidget')
}

/**
 *
 * Extracts Registration Number and Registration Location
 * @param {*} formData
 * @return {*}
 */
export const extractRegistrationDetails = (formData) => {
  if (formData?.vehicleDetails) {
    const { vehicleRegistrationNumber, registrationLocation } = formData.vehicleDetails
    return { vehicleRegistrationNumber, registrationLocation }
  }
  return { vehicleRegistrationNumber: undefined, registrationLocation: undefined }
}

/**
 *
 * Extracts Previous Policy Status and End Date for roll over flow
 * @param {*} formData
 * @return {*}
 */
export const extractPreviousPolicyDetails = (formData) => {
  if (formData?.previousPolicyDetails) {
    const { previousPolicyStatus, previousPolicyEndDate } = formData.previousPolicyDetails
    return { previousPolicyStatus, previousPolicyEndDate }
  }
  return { previousPolicyStatus: undefined, previousPolicyEndDate: undefined }
}

/**
 * @description Converts Object to JSON string
 * @param {string|object} data
 * @return {string}
 */
export const stringifyData = (data) => {
  let newData = data
  try {
    newData = JSON.parse(data)
  } catch (e) {}
  return JSON.stringify(newData)
}
