import { PureComponent } from 'react'
import { Statistic, Header } from 'semantic-ui-react'
import { isNotEmpty } from 'utils'
import DataLoader from 'components/DataLoader'
import { LoadingContext } from 'components/context'
class PayoutFrequency extends PureComponent {
  render () {
    const { id, device, className, configure, productData } = this.props

    const payoutFrequencies = configure.common.payoutFrequencies
    const loading = this.context
    const { payoutFrequency } = productData
    let premiumLoading = false
    if (isNotEmpty(loading)) {
      premiumLoading = loading.status
    }
    if (device === 'mobile') {
      return (
        <Statistic textAlign='center' color='green' className={`alignCenter ${className}`}>
          <Header sub color='black'>Payout Frequency</Header>
          <DataLoader
            loading={premiumLoading}
          >
            <Statistic.Value id={`${id}-premiumAmount`}>
              {
                payoutFrequencies.map(freq => {
                  if (freq.value === payoutFrequency) {
                    return freq.display
                  }
                  return null
                })
            }
            </Statistic.Value>
          </DataLoader>
        </Statistic>
      )
    }
    return (
      <Statistic color='green' className={`alignLeft productPremium ${className}`}>
        <Statistic.Label>Payout Frequency</Statistic.Label>
        <DataLoader
          loading={premiumLoading}
        >
          <Statistic.Value id={`${id}-premiumAmount`}>
            {
                payoutFrequencies.map(freq => {
                  if (freq.value === payoutFrequency) {
                    return freq.display
                  }
                  return null
                })
            }
          </Statistic.Value>
        </DataLoader>
      </Statistic>
    )
  }
}
PayoutFrequency.contextType = LoadingContext
export default PayoutFrequency
