
import React from 'react'
import { capitalize } from 'utils'
import { Grid } from 'semantic-ui-react'
import { Caption } from 'components/Header'
import FormHintInline from './FormHintInline'

const SubQuestionField = (props) => {
  const { label, children, questionNumber, id, showerror } = props
  const { name } = children.props
  const ucLabel = capitalize(label)
  const subQuestionLevel = questionNumber ? (questionNumber.split('.').length - 1) : 0
  const flex = 0.05 * subQuestionLevel
  return (
    <Grid stackable columns='equal' className='subQuestion'>
      <Grid.Row>
        <Grid.Column
          textAlign='left'
          stretched
        >
          <Caption style={{ display: 'flex', marginTop: 12 }}>
            <div style={{ flex: 0.05 }} />
            {questionNumber && (
              <div style={{ flex, textAlign: 'right' }}>
                {questionNumber}.&nbsp;
              </div>)}
            <div style={{ flex: (0.6 - flex) }} className={`subQuestionLabel${subQuestionLevel}`}>
              {ucLabel}
              <div className={`subQuestionField field${subQuestionLevel}`}>
                {React.Children.map(children, (child) => {
                  return React.cloneElement(child,
                    {
                      idSchema: {
                        $id: `${name}_subQuestion_${questionNumber}`
                      }
                    })
                })}
              </div>
            </div>
          </Caption>
        </Grid.Column>
        <FormHintInline
          group={false}
          id={id}
          showerror={showerror}
          rawErrors={props.rawErrors}
          // ref={(ref) => this.setHintRef(ref, id)}
        />
      </Grid.Row>
    </Grid>
  )
}
export default SubQuestionField
