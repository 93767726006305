import { getRiderSumAssured, calcRiderPremium, formatPayoutTerm, flattenProducts, getPayoutSchema, buildPaymentOptionSchema } from '../helpers/productHelper'
import { validateEnquiry, populateMaxMinDates } from '../helpers/enquiryHelper'
import { isNotDefined, isNotEmpty } from 'utils'
import { getUiConfiguration, processRequestBooking, processPayment, updateApplicationDataToBooking, getPolicyDetails, getSmartCovrConfiguration } from '../../../services/insuranceService'
import { getFileFromFileStore, uploadFile, tptCustomerPost, getDistributorConfigByType, fetchPincodeData, verifyPanDetails, verifyApplicationOtp, sendApplicationOtp, sendDropOffNotification, makeTptLoginRedirectRequest, makeTptTokenRequest, fetchCityAndState, makeTptLoginCustomerRedirectRequest } from '../../../services/commonService'
import { getEncodedRouteParams, getQueryParams, getRedirectFromLocalState } from '../../../commonUtils'
import { getStore } from '../../../store'
import { processRefreshRules, getQuoteForProduct } from '../modelHelpers'
import produce from 'immer'
import { checkForBadRequest, ServerApiError } from '../../../errors'
import resetProductDataKeys from '../helpers/resetProductDataKeys'
import { cloneDeep } from 'lodash'
/**
   * CalculateRiderPremium
   * calls getPayoutSchema function
   *
   * @param {int} productOptionId
   * @param {int} riderId
   * @returns
   */
export function getPayoutTermSchema (payoutTerm, formData) {
  return getPayoutSchema(payoutTerm, formData)
}

/**
 * Calls buildPaymentOptionSchema function
 *
 * @export
 * @param {*} pptOptions
 * @param {*} formData
 * @param {*} productData
 * @returns
 */
export function getPaymentOptionSchema (pptOptions, formData, productData) {
  return buildPaymentOptionSchema(pptOptions, formData, productData)
}

/**
 * This function recalculates the premiums based on the rider data is entered like riderSumAssured.
 *
 * @export
 * @param {*} { insuranceType, productOptionId, riderId, feature, dataForRider, formData, productData, updateEnquiry = false }
 * @returns
 */
export async function calculateRiderPremium ({ insuranceType, productOptionId, riderId, feature, dataForRider, formData, productData, updateEnquiry = false }) {
  const store = getStore()
  if (isNotDefined(store)) {
    console.log('Store Not found')
  }
  const rootState = store.getState()
  // Rules to decide whether calculation is on server or on client
  const { issuerProductConfig } = rootState.insuranceConfigure[insuranceType]
  const paymentOptions = rootState.insuranceConfigure[insuranceType].uiConfig.productListing.paymentOptionsPriority
  // get the current product from productId
  let flattenedProduct = rootState.insuranceProducts[insuranceType].chosenProduct.product
  if (!isNotEmpty(flattenedProduct)) {
    flattenedProduct = rootState.insuranceProducts[insuranceType].plainProducts.find(pp => {
      return pp.productOption.productOptionId === productOptionId
    })
  }
  const { insurerId, finanalyticsId } = flattenedProduct
  const { quoteRefreshRules } = issuerProductConfig[finanalyticsId]
  const newFormData = Object.assign({}, formData, {
    featureChange: true // insuranceFilters does not influence feature change
  })
  // build the quoteData for rules
  const quoteData = Object.assign({}, { currentFormData: formData }, { newFormData }, { insurerId })
  let refreshProcess

  try {
    refreshProcess = await processRefreshRules(quoteRefreshRules.formData, quoteData)
  } catch (err) {
    console.log(err)
    throw err
  }
  // Calculate rider Sum assured based on rider calculations
  const { riderSumAssured, key } = getRiderSumAssured({
    product: flattenedProduct,
    riderInsurerId: riderId,
    feature,
    dataForRider,
    formData,
    productData,
    paymentOptions
  })
  // recreate enquiry with newly calculated rider sum assured
  let newEnquiry
  if (updateEnquiry) {
    newEnquiry = produce(rootState.insuranceEnquiry[insuranceType], (draftEnquiry) => {
      const tempProductData = cloneDeep(productData)
      draftEnquiry.productData = tempProductData
      draftEnquiry.formData = formData
      draftEnquiry.productData[key].riderSumAssured = riderSumAssured
      if (feature.hasRiderSchemaUpdated) {
        draftEnquiry.productData[key].riderPt = dataForRider.riderPt
        draftEnquiry.productData[key].riderPpt = dataForRider.riderPpt
      }
    })
  } else {
    newEnquiry = produce(rootState.insuranceEnquiry[insuranceType], (draftEnquiry) => {
      const tempProductData = cloneDeep(productData)
      draftEnquiry.productData = tempProductData
      draftEnquiry.formData = formData
      draftEnquiry.productData[key].riderSumAssured = 0
    })
  }
  if (refreshProcess === 'server') {
    // get the new rider Premium from server / api
    const insuranceTypeState = Object.keys(rootState).reduce((obj, key) => Object.assign(obj, {
      [key]: rootState[key][insuranceType]
    }), {})
    let flattenedProducts
    try {
      flattenedProducts = await getQuoteForProduct(
        insuranceType,
        'lisitng',
        flattenedProduct.finanalyticsId,
        flattenedProduct.insurerId,
        {
          insuranceEnquiryDraft: newEnquiry,
          insuranceConfigureDraft: insuranceTypeState.insuranceConfigure,
          insuranceFiltersDraft: insuranceTypeState.insuranceFilters,
          insuranceProductsDraft: insuranceTypeState.insuranceProducts
        })
    } catch (err) {
      console.log(err)
      throw err
    }
    flattenedProduct = flattenedProducts.find(pp => {
      return pp.productOption.productOptionId === productOptionId
    })
  }
  // recalculate current premiums and display.
  const premiums = calcRiderPremium(flattenedProduct,
    riderId,
    riderSumAssured,
    newEnquiry.formData,
    newEnquiry.productData,
    dataForRider,
    paymentOptions
  )
  return premiums
}

/**
 * Calls getUiConfiguration API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function getUiConfig (data) {
  checkForBadRequest(['insuranceType', 'distributorId'], data)
  const { insuranceType, distributorId } = data
  let response
  try {
    response = getUiConfiguration({ insuranceType, distributorId })
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}
export async function getSmartcovrConfig (data) {
  checkForBadRequest(['insuranceType'], data)
  const { insuranceType } = data
  let response
  try {
    response = await getSmartCovrConfiguration({ insuranceType })
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

export const getRenewPolicy = async (data) => {
  checkForBadRequest(['id', 'enquiry', 'insuranceType', 'typeOfQuote', 'institutionId'], data)
  const { id, enquiry, insuranceType, typeOfQuote, institutionId } = data
  let response
  try {
    response = await getPolicyDetails(id, enquiry, insuranceType, typeOfQuote, institutionId)
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

/**
 * Calls processRequestBooking API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function generateSiS (data) {
  checkForBadRequest(['enquiry', 'institutionId', 'insuranceType', 'enquiryId', 'distributorId'], data)
  const { enquiry, institutionId, insuranceType, enquiryId, distributorId } = data
  let response
  try {
    response = processRequestBooking({ insuranceType, institutionId, enquiry, requestType: 'sis', enquiryId, distributorId })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.SisGenerationError('smartCovrApi:clientApi:generateSiS', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 * Calls processPayment API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function getPaymentRedirectionUrl (data) {
  checkForBadRequest(['enquiry', 'institutionId', 'insuranceType', 'enquiryId', 'distributorId'], data)
  const { enquiry, institutionId, insuranceType, enquiryId, distributorId } = data
  let response
  try {
    response = processPayment({ insuranceType, institutionId, enquiry, enquiryId, distributorId })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.PaymentRedirectionError('smartCovrApi:clientApi:getPaymentRedirectionUrl', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 * Calls updateApplicationDataToBooking API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function postPaymentAction (data) {
  checkForBadRequest(['enquiry', 'institutionId', 'insuranceType', 'enquiryId', 'distributorId'], data)
  const { enquiry, institutionId, insuranceType, enquiryId, distributorId } = data
  let response
  try {
    response = updateApplicationDataToBooking({ insuranceType, institutionId, enquiry, action: 'postPayment', enquiryId, distributorId })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.PostPaymentActionError('smartCovrApi:clientApi:postPaymentAction', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 * Calls processRequestBooking API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function generateApplicationForm (data) {
  checkForBadRequest(['enquiry', 'institutionId', 'insuranceType', 'enquiryId', 'distributorId'], data)
  const { enquiry, institutionId, insuranceType, enquiryId, distributorId } = data
  let response
  try {
    response = processRequestBooking({ insuranceType, institutionId, enquiry, requestType: 'application', enquiryId, distributorId })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.ApplicationFormGenerationError('smartCovrApi:clientApi:generateApplicationForm', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 * This function first gets the auth config and builds the queryParams and redirects user to new url
 *
 * @export
 * @param {*} { redirectTo, distributorId, insuranceType, enquiryId }
 */
// FIXME:  Redirect to has to go from smartcovr server as 304 and not changeing location
export async function redirectToLogin ({ configType, redirectTo, redirectionHandlerName, distributorId, insuranceType, enquiryId }) {
  try {
    const store = getStore()
    if (isNotDefined(store)) {
      console.log('Store Not found')
    }
    const { configure } = store.getState()
    const authConfig = await getDistributorConfigByType({
      configType,
      distributorId
    })
    if (isNotEmpty(authConfig)) {
      const { authUrl, tptCustomerLogin, tptLogin, keycloakTptLogin, availableProducts, ownCustomerLogin } = configure
      let availableInsuranceType
      if (availableProducts) {
        availableInsuranceType = insuranceType ?? availableProducts[0]
      }
      if (authConfig.type === 'tptlogin') {
        let clientId
        let tptLoginUrl
        if (configType === 'auth' && !ownCustomerLogin) {
          clientId = tptCustomerLogin.clientId
          tptLoginUrl = tptCustomerLogin.tptLoginUrl
        } else if (configType === 'auth') {
          clientId = tptLogin.clientId
          tptLoginUrl = tptLogin.tptLoginUrl
        } else {
          clientId = keycloakTptLogin.clientId
          tptLoginUrl = keycloakTptLogin.tptLoginUrl
        }
        const redirectUrl = `${window.location.origin}/#/${availableInsuranceType}/${redirectionHandlerName}`
        const params = {
          client: clientId,
          redirectToPath: redirectTo,
          redirectTo: redirectUrl,
          distributorId,
          productType: availableInsuranceType,
          enquiryId,
          configType
        }
        const { queryString } = getEncodedRouteParams(params)
        // Below code was commented out for dummy login happening directly from backend for uat and dev
        // if (env === 'development' && configType === 'auth') {
        //   // This part of code is not a part of the flow.
        //   // Ideally, the thirdparty responsible for login, calls tptcustomer end point with customer data
        //   // Since in dev that does not happen, we do that to replicate the flow, with dummy customer data
        //   try {
        //     const { hslUser } = HSLData
        //     const data = {
        //       ...hslUser // replace with 'nonHslUser' for non hsl flow testing on local
        //     }
        //     data.enquiryId = enquiryId
        //     const headers = {
        //       'Content-Type': 'application/json'
        //     }
        //     await axios({
        //       url: `${tptcustomerUrl}&${queryString}`,
        //       method: 'POST',
        //       data,
        //       headers
        //     })
        //   } catch (err) {
        //     console.log('ERROR in tptcustomer')
        //   }
        // }
        window.location.href = `${tptLoginUrl}&${queryString}`
      } else {
        const { authPath, clientId, realm } = configure.ownLogin
        // default case ?
        const { queryString } = getEncodedRouteParams({
          redirectTo,
          redirectToPath: redirectTo,
          distributorId
        })
        window.location.href = `${authUrl}${authPath}/${realm}/${clientId}?${queryString}`
      }
    }
  } catch (err) {
    console.log(err)
    throw err // appropriate error thrown from the called function
  }
}

/**
 * This function builds the queryParams and redirects the screen to the new url for customer keycloak login when ownCustomerLogin key is false
 *
 * @export
 */
export function tptcustomerloginredirect (enquiryId, distributorId, insuranceType) {
  const { configure } = getStore().getState()
  const { tptCustomerLogin, availableProducts } = configure
  const { code } = getQueryParams()
  const { code: state, redirectTo: redirect } = JSON.parse(window.localStorage.getItem('state'))
  let availableInsuranceType
  if (availableProducts) {
    availableInsuranceType = insuranceType ?? availableProducts[0]
  }
  const redirectTo = encodeURIComponent(`${window.location.origin}/#${redirect}`)
  const codeVal = code.substring(0, code.indexOf('#'))
  const redirectUri = `${window.location.origin}/#/${availableInsuranceType}/tpt-handler?distributorId=${distributorId}&client=${tptCustomerLogin.clientId}&productType=${availableInsuranceType}&enquiryId=${enquiryId}&state=${state}&flow=keyclock`
  const queryParams = {
    redirectTo,
    enquiryId,
    client: tptCustomerLogin.clientId,
    distributorId,
    productType: availableInsuranceType,
    configType: 'auth'
  }
  const requestData = {
    code: codeVal,
    redirectTo,
    redirectUri
  }
  makeTptLoginCustomerRedirectRequest(requestData, queryParams)
}
/**
 * This function builds the queryParams and redirects the screen to the new url
 *
 * @export
 */
export function tptloginredirect (redirectToPathFn) {
  const { configure } = getStore().getState()
  const { tptLogin, availableProducts } = configure
  const { enquiryId, distributorId, state, productType } = getQueryParams()
  let availableInsuranceType
  if (availableProducts) {
    availableInsuranceType = productType ?? availableProducts[0]
  }
  const redirectToPath = getRedirectFromLocalState({ state })
  const redirectTo = `${window.location.origin}/#${redirectToPath}`
  const queryParams = {
    redirectTo,
    enquiryId,
    client: tptLogin.clientId,
    distributorId,
    productType: availableInsuranceType
  }
  makeTptLoginRedirectRequest(queryParams, redirectToPathFn) // backend change tptloginredirect endpoint to post that why add it api endpoint using feather
}
/**
 * This function used to call tpttoken request in keycloak login process
 *
 * @export
 */
export function keycloakTptloginredirect ({ enquiryId, distributorId, insuranceType, redirectToPath }) {
  const { code } = getQueryParams()
  const { configure } = getStore().getState()

  const { keycloakTptLogin, availableProducts } = configure
  let availableInsuranceType
  if (availableProducts) {
    availableInsuranceType = insuranceType ?? availableProducts[0]
  }
  const redirectTo = encodeURIComponent(`${window.location.origin}/#/${availableInsuranceType}/${redirectToPath}`)
  const redirectUri = `${window.location.origin}/#/${availableInsuranceType}/keycloak-tpt-handler`
  const codeVal = code.substring(0, code.indexOf('#'))

  const queryParams = {
    enquiryId,
    client: keycloakTptLogin.clientId,
    distributorId,
    productType: availableInsuranceType,
    configType: 'employeeAuth'
  }
  const requestData = {
    code: codeVal,
    redirectTo,
    redirectUri
  }
  makeTptTokenRequest(requestData, queryParams)
}
export async function getFile (fileUrl) {
  let response
  try {
    response = await getFileFromFileStore(fileUrl)
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

/**
 * Calls uploadFile API
 *
 * @export
 * @param {*} data
 * @param {*} params
 * @returns
 */
export async function uploadDocument (data, params) {
  let response
  try {
    response = await uploadFile({ data, params })
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

/**
 * Calls flattenProducts function
 *
 * @export
 * @param {*} product
 * @param {*} formData
 * @param {*} productData
 * @param {*} insuranceFilters
 * @param {*} filterConfig
 * @returns
 */
// FIXME: Will not work
export async function createFinishedProduct (product, formData, productData, insuranceFilters, filterConfig) {
  const flattenedProducts = flattenProducts([product], formData, productData, insuranceFilters, filterConfig)
  return flattenedProducts
}

/**
 * This function prepares the enquirySchema, gets the formData and calls the validateEnquiry function
 *
 * @export
 * @param {*} data
 * @returns
 */
export function validateFormData (data) {
  checkForBadRequest(['formData', 'insuranceType'], data)
  let { formData, insuranceType } = data
  const store = getStore()
  if (isNotDefined(store)) {
    console.log('Store Not found')
  }
  const { insuranceConfigure } = store.getState()
  const { schema, age } = insuranceConfigure[insuranceType].productConfig.validation
  const enquirySchema = produce(schema, (draftSchema) => {
    if (!['car', 'bike', 'health'].includes(insuranceType)) {
      populateMaxMinDates(draftSchema, age?.min ?? 0, age?.max ?? 100)
    }
  })
  if (['car', 'bike'].includes(insuranceType) && !isNotDefined(formData.makeModel)) {
    formData = { ...formData, makeModel: JSON.parse(formData.makeModel) }
  }
  return validateEnquiry(enquirySchema, formData)
}

/**
 * Calls the formatPayoutTerm with newPayoutTerm object
 *
 * @export
 * @param {*} payoutTerm
 * @param {*} productDataFragment
 * @returns object
 */
export async function getFormattedPayoutTerm (payoutTerm, productDataFragment) {
  const newPayoutTerm = {
    ...payoutTerm,
    dynamicFields: {
      ...payoutTerm.dynamicFields,
      ...productDataFragment
    }
  }
  let response
  try {
    response = formatPayoutTerm(newPayoutTerm)
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

/**
 * Calls resetProductDataKeys function
 *
 * @export
 * @param {*} key
 * @returns
 */
export function getResetValueForKey (key, insuranceType) {
  return resetProductDataKeys(key, insuranceType)
}

/**
 * Calls tptCustomerPost API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function tptCustomer (data) {
  checkForBadRequest(['insuranceType'], data)
  const { insuranceType } = data
  const store = getStore()
  if (isNotDefined(store)) {
    console.log('Store Not found')
  }
  const rootState = store.getState()
  const enquiryId = rootState.insuranceEnquiry.enquiryId
  const distributorId = 'IN-9820' // rootState.configure.distributorId
  return tptCustomerPost({ enquiryId, distributorId, insuranceType })
}

/**
 * Calls verifyPanDetails API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function verifyPanNumber (data) {
  return verifyPanDetails(data)
}

/**
 * Calls verifyApplicationOtp API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function verifyOtp (data) {
  return verifyApplicationOtp(data)
}

/**
 * Calls sendApplicationOtp API
 *
 * @export
 * @param {*} data
 * @returns
 */
export async function requestApplicationOtp (data) {
  return sendApplicationOtp(data)
}

/**
 * dropOffType is set as per the value of stepType and then sendDropOffNotification API is called
 *
 * @export
 * @param {*} { bookingType, applicationFormData, stepType, distributorId, path }
 * @returns
 */
export async function sendDropOffLink ({ bookingType, applicationFormData, stepType, distributorId, path }) {
  let dropOffType
  if (stepType === 'paymentSummary') {
    dropOffType = 'paymentSummary'
  } else if (stepType === 'payment') {
    dropOffType = 'atpayment'
  } else if (bookingType === 'prePaymentSteps') {
    dropOffType = 'prepayment'
  } else if (bookingType === 'postPaymentSteps') {
    dropOffType = 'postpayment'
  } else {
    dropOffType = path
  }
  const store = getStore()
  if (isNotDefined(store)) {
    console.log('Store Not found')
  }
  const { userAgent: { browser }, configure, insuranceEnquiry: { enquiryId } } = store.getState()

  return sendDropOffNotification({
    dropOffType,
    applicationFormData,
    distributorId,
    browser,
    enquiryId,
    configure
  })
}

/**
 * Calls the fetchPincodeData API with pincode, insurerId and insuranceType
 *
 * @export
 * @param {*} pincode
 * @returns
 */
export async function getPincodeData (pincode) {
  const store = getStore()
  if (isNotDefined(store)) {
    console.log('Store Not found')
  }
  const rootState = store.getState()

  const { insuranceEnquiry: { currentInsuranceType }, insuranceEnquiry } = rootState
  // get the current product from productId
  const { institutionId } = insuranceEnquiry[currentInsuranceType].chosenProduct
  return fetchPincodeData({
    pincode,
    insurerId: institutionId || 'IN-15472',
    insuranceType: currentInsuranceType
  })
}

/**
 * Calls the fetchCityAndStateData API with pincode and insuranceType
 *
 * @export
 * @param {string} pincode
 * @returns {Promise}
 */
export async function getCityAndState (pincode) {
  const store = getStore()
  if (isNotDefined(store)) {
    console.log('Store Not found')
  }
  const rootState = store.getState()
  const { insuranceEnquiry: { currentInsuranceType } } = rootState
  return fetchCityAndState({
    pincode,
    insuranceType: currentInsuranceType
  })
}
