import { Component } from 'react'

import isEmpty from 'lodash/isEmpty'

import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'

import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'

// import { uploadDocument } from 'smartcovr_api/insuranceApi'

import { adopt } from 'react-adopt'
import { DataAccessContext } from 'components/context'

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImageResize,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform
)

class FileWidget extends Component {
  constructor (props) {
    super(props)

    this.state = {
      files: this.loadInitialFile(props)
    }

    this.server = {
      url: this.props.uploadUrl,
      process: this.processFile,
      revert: this.revertFile,
      load: this.load
    }
  }

  loadInitialFile = (props) => {
    if (props.value) {
      return [{
        source: props.fileUrl.format({ fileUuid: props.value }),
        options: {
          type: 'local'
        }
      }]
    } else {
      return []
    }
  }

  load = (url, load, error, progress, abort, _headers) => {
    const xhr = new window.XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total)
    }
    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 300) {
        load(xhr.response)
      } else {
        error('Something Went Wrong !')
      }
    }
    xhr.send()
    return {
      abort: () => {
        xhr.abort()
        abort()
      }
    }
  }

  processFile = (fieldName, file, metadata, load, error, progress, abort) => {
    const formData = new FormData()
    const extension = file.type.replace(/^image+\/|^application+\//, '')
    formData.append(fieldName, file, file.name)
    const xhr = new XMLHttpRequest()
    const { options: { natureOfFile }, enquiryId, institutionId, iamId, distributorId } = this.props
    // const url = `${this.server.url}&category=identity&natureOfFile=${natureOfFile}&customerId=${enquiryId}&institutionId=${institutionId}`
    const url = this.server.url.format({ institutionId, iamId: iamId || enquiryId, category: 'identity', natureOfFile, distributorId })
    // const url = `${this.server.url}/${institutionId}/${customerId}/${catId}/${filetype}/${distributorId}`
    xhr.open('POST', url, true)

    xhr.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total)
    }

    xhr.onload = function () {
      if (xhr.readyState === 4 && xhr.status >= 200 && xhr.status < 300) {
        load(xhr.responseText)
      } else {
        error('Something Went Wrong !')
      }
    }
    // xhr.setRequestHeader('Access-Control-Allow-Origin', '*')
    const reader = new window.FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = function () {
      const base64data = reader.result
      const strImage = base64data.replace(/^data:[image|application]+\/[a-z]+;base64,/, '')
      const filestoreData = { data: strImage, extension, forceUpload: true }
      const fdata = JSON.stringify(filestoreData)
      xhr.send(fdata)
    }
    // xhr.send({
    //   'data': file,
    //   'extension': 'txt',
    //   'forceUpload': true})
    // xhr.send(formData)
    return {
      abort: () => {
        xhr.abort()
        abort()
      }
    }
  }

  revertFile = (uniqueFileId, load, error) => {
    if (uniqueFileId) {
      const xhr = new XMLHttpRequest()

      const url = `${this.server.url}/upload/${uniqueFileId}`

      xhr.open('DELETE', url, true)

      xhr.onload = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
          load()
        } else {
          error('Something Went Wrong !')
        }
      }
      xhr.send(null)
    }
  }

  handleOnUpdateFiles = (fileItems) => {
    this.setState({
      files: fileItems.map(fileItem => fileItem.file)
    })
  }

  onprocessfile = (err, file) => {
    if (err) {
      throw err
    } else {
      if (!isEmpty(file.serverId)) {
        // console.log('file :- ', file)
        this.props.onChange(file.serverId)
      }
    }
  }

  onRemoveFile = (err, file) => {
    if (err) {
      throw err
    } else {
      console.log(file)
    }
  }

  render () {
    return (
      <div className='App fileWidget'>
        <FilePond
          ref={ref => (this.pond = ref)}
          files={this.state.files}
          allowMultiple={false}
          maxFiles={1}
          server={this.server}
          name='document'
          maxFileSize={'5MB'}
          minFileSize={'10KB'}
          // allowImageTransform
          // allowImageResize
          imageResizeUpscale={false}
          // imageResizeTargetWidth={1000}
          // imageTransformOutputQuality={95}
          onupdatefiles={this.handleOnUpdateFiles}
          onprocessfile={this.onprocessfile}
          onremovefile={this.onRemoveFile}
          acceptedFileTypes={[
            'image/*',
            'application/pdf'
          ]}
        />
      </div>
    )
  }
}

const WithContext = adopt({
  dataAccess: <DataAccessContext.Consumer />

})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ dataAccess }) => {
        const { uploadUrl, fileUrl, institutionId, enquiryId, iamId, distributorId } = dataAccess
        return (
          <FileWidget
            {...props}
            uploadUrl={uploadUrl}
            fileUrl={fileUrl}
            institutionId={institutionId}
            enquiryId={enquiryId}
            iamId={iamId}
            distributorId={distributorId}
          />
        )
      }
    }
  </WithContext>
)
export default WrapperComponent
