import { effectsHandler } from './insurance/modelHelpers'
import { loadApplications, loadFeatures, buildEnquiry, getStatus } from './applicationsEffects'
import { isNotDefined, isNotEmpty } from 'utils'

const insuranceApplications = {
  name: 'applications',
  state: {
    term: {
      applications: [
        // {
        //   applicationNumber: '',
        //   productName: '',
        //   smartcovrStatus: '',
        //   shortName: '',
        //   productOptionName: '',
        //   productId: '',
        //   productDescription: '',
        //   sumAssured: '',
        //   premiumAmount: '',
        //   premiumPayingFrequency: '',
        //   status: '',
        //   riders: [
        //     {
        //       categoryId: ''
        //     }
        //   ],
        //   formStatus: [
        //     {
        //       step: '',
        //       status: ''
        //     }
        //   ]
        // }
      ],
      features: [],
      selectedApplicationNumber: -1
    },
    car: {
      applications: [
      ],
      features: [],
      selectedApplicationNumber: -1
    },
    bike: {
      applications: [
      ],
      features: [],
      selectedApplicationNumber: -1
    },
    health: {
      applications: [
      ],
      features: [],
      selectedApplicationNumber: -1
    },
    annuity: {
      applications: [
      ],
      features: [],
      selectedApplicationNumber: -1
    }
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Return the value of selectedApplicationNumber from the state
     *
     * @returns string
     */
    getSelectedApplicationNumber () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (applications, insuranceType) => applications[insuranceType].selectedApplicationNumber
      )
    },

    /**
     * Returns array of applications
     *
     * @returns array
     */
    getApplications () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (applications, insuranceType) => {
          return applications[insuranceType].applications
        }
      )
    },

    /**
     * Returns of applications count
     *
     * @returns number
     */
    getApplicationsCount () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (applications, insuranceType) => {
          return applications[insuranceType].applicationsCount
        }
      )
    },
    /**
     * Returns single application searched by application number
     *
     * @returns object
     */
    getApplicationById () {
      return createSelector(
        slice,
        (rootState, { insuranceType, applicationNumber }) => ({
          insuranceType,
          applicationNumber
        }),
        (applications, { insuranceType, applicationNumber }) => {
          return applications[insuranceType].applications.find(app => app.applicationNumber === applicationNumber)
        }
      )
    },

    /**
     * Returns array of multisteps and payment steps with a flag completed to identify whether
     * the step is completed or not. Full booking steps are first filtered to get multi and payment step
     * then each step's name is checked in completedSteps array. If it exists completed value becomes true
     * and vice-versa
     *
     * @returns array
     */
    getFormStepStatus () {
      return createSelector(
        slice,
        (state, { insuranceType }) => ({
          insuranceType,
          booking: state.insuranceConfigure[insuranceType].uiConfig.booking,
          bookingDefaults: state.insuranceConfigure[insuranceType].productConfig.bookingDefaults
        }),
        (state, { applicationNumber }) => applicationNumber,
        (applications, { insuranceType, booking, bookingDefaults }, applicationNumber) => {
          const application = applications[insuranceType].applications.find(application => application.applicationNumber === applicationNumber)
          let mainSteps
          if (insuranceType === 'annuity') {
            const { applicationType } = application.applicationFormData
            const annuityStep = `${applicationType}BookingSteps`
            mainSteps = booking.bookingSteps[annuityStep]
          } else {
            mainSteps = booking.bookingSteps
          }

          const requiredSteps = mainSteps.filter(step => {
            if (!isNotDefined(step.proposer) && !step.proposer) return false
            if (!isNotDefined(step.proposer) && step.proposer) {
              let isProposerSelf = true
              const bookingDefaultValues = bookingDefaults[application.finanalyticsId]
              let defaultInsured
              if (isNotEmpty(bookingDefaultValues)) {
                defaultInsured = bookingDefaultValues.insured
              }
              const selectedInsured = application.insured
              if (!isNotDefined(selectedInsured) && defaultInsured !== selectedInsured) {
                isProposerSelf = false
              }
              return (step.stepType === 'multi' || step.stepType === 'payment') && !isProposerSelf
            }
            return step.stepType === 'multi' || step.stepType === 'payment' || step.stepType === 'confirmation'
          })
          return requiredSteps.map(step => {
            if (step.stepType === 'multi') {
              return {
                title: booking.bookingDisplay.multiStepDisplay[step.displayKey].title,
                stepDescription: booking.bookingDisplay.multiStepDisplay[step.displayKey].desc,
                completed: (application.completedSteps) ? application.completedSteps.includes(step.displayKey) : false
              }
            }
            const convertedText = step.displayKey.replace(/([A-Z])/g, ' $1')
            const finalText = convertedText.charAt(0).toUpperCase() + convertedText.slice(1)
            return {
              title: finalText,
              stepDescription: finalText,
              completed: (application.completedSteps) ? application.completedSteps.includes(step.displayKey) : false
            }
          })
        }
      )
    },

    /**
     * Gets array features for an application
     * (Not used anywhere)
     *
     * @returns array
     */
    getFeaturesByApplicationNumber () {
      return createSelector(
        slice,
        (rootState, { insuranceType, applicationNumber }) => ({
          insuranceType,
          applicationNumber
        }),
        (applications, { insuranceType, applicationNumber }) => {
          const application = applications[insuranceType].applications.find(app => app.applicationNumber === applicationNumber)
          return applications[insuranceType].features.filter(feature => {
            const allFeatures = application.riders.map(rider => rider.categoryId)
            return allFeatures.includes(feature.categoryId)
          })
        }
      )
    },

    /**
     * Checks the last completed step and it's category, that is whether it belongs to
     * pre-payment or post-payment and based on that string is returned.
     *
     * @returns string
     */
    getBookingFormType () {
      return createSelector(
        slice,
        (rootState, { insuranceType, applicationNumber }) => ({
          insuranceType,
          applicationNumber,
          booking: rootState.insuranceConfigure[insuranceType].uiConfig.booking
        }),
        (applications, { insuranceType, applicationNumber, booking }) => {
          const application = applications[insuranceType].applications.find(app => app.applicationNumber === applicationNumber)
          const applicationStatus = application.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode
          const lastCompletedStep = application.completedSteps[application.completedSteps.length - 1]
          let mainSteps
          if (insuranceType === 'annuity') {
            const { applicationType } = application.applicationFormData
            const annuityStep = `${applicationType}BookingSteps`
            mainSteps = booking.bookingSteps[annuityStep]
          } else {
            mainSteps = booking.bookingSteps
          }
          let lastCompletedStepPos = mainSteps.findIndex(ele => ele.displayKey === lastCompletedStep)
          if (applicationStatus === 'SC003') {
            lastCompletedStepPos = lastCompletedStepPos - 1
          }
          if (mainSteps[lastCompletedStepPos + 1].prePaymentStep) {
            return 'pre-payment'
          } else if (mainSteps[lastCompletedStepPos + 1].postPaymentStep) {
            return 'post-payment'
          } else {
            return 'done'
          }
        }
      )
    },

    /**
     * Returns the application SmartCovr status code
     *
     * @returns string
     */
    getSmartCovrStatus () {
      return createSelector(
        slice,
        (_rootState, { insuranceType, applicationNumber }) => ({
          insuranceType,
          applicationNumber
        }),
        (applications, { insuranceType, applicationNumber }) => {
          const application = applications[insuranceType].applications.find(app => app.applicationNumber === applicationNumber)
          return application.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode
        }
      )
    }
  }),
  reducers: {

    /**
     * Sets modified applications in state
     *
     * @param {*} state
     * @param {*} { modifiedApplications, insuranceType }
     * @returns object
     */
    storeApplicationsData (state, { modifiedApplications, count, insuranceType }) {
      state[insuranceType].applications = modifiedApplications
      state[insuranceType].applicationsCount = count
      return state
    },

    /**
     * Sets features in state
     *
     * @param {*} state
     * @param {*} { features, insuranceType }
     * @returns object
     */
    storeFeaturesData (state, { features, insuranceType }) {
      state[insuranceType].features = features
      return state
    },

    /**
     * Sets selected application number in state
     *
     * @param {*} state
     * @param {*} { applicationNumber, insuranceType }
     * @returns object
     */
    updateSelectedApplicationNumber (state, { applicationNumber, insuranceType }) {
      state[insuranceType].selectedApplicationNumber = applicationNumber
      return state
    },

    /**
     * Updates selected application status in state
     *
     * @param {*} state
     * @param {*} { applicationsUpdated, insuranceType }
     * @returns object
     */
    updateApplicationStatus (state, { applicationsUpdated, insuranceType }) {
      state[insuranceType] = applicationsUpdated
      return state
    }
  },
  effects: (dispatch) => ({
    loadApplications: effectsHandler(dispatch, loadApplications),
    loadFeatures: effectsHandler(dispatch, loadFeatures),
    buildEnquiry: effectsHandler(dispatch, buildEnquiry),
    getStatus: effectsHandler(dispatch, getStatus)
  })
}

export default insuranceApplications
