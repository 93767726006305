import { useState } from 'react'
import PropTypes from 'prop-types'
import { downloadFile } from '../../../uiCommonUtils'

import { Icon, Button, Statistic, List, Grid, Image, Divider, Checkbox } from 'semantic-ui-react'
import { Header, H6, Subtitle1, Caption, Body2 } from 'components/Header'
import DividedSegment from 'components/DividedSegment'
import DividedSegmentVertical from 'components/DividedSegmentVertical'
import { rupeeFormatter } from 'utils'
import { isNotDefined } from 'utils/lib/basic'
import { productComposer } from '../product/bookingIndex'
import 'components/css/paymentSummary.scss'
import { buildCommonImagePath } from 'components/buildImagePath'

/* eslint react/jsx-pascal-case: 'off' */
/* eslint react/jsx-handler-names: 'off' */

const PaymentSummary = props => {
  const [tnc, setTnc] = useState(false)

  const checkTnc = event => {
    event.preventDefault()
    setTnc(!tnc)
  }
  function displayAmount (id, amount, symbol = '') {
    return (
      <List.Content floated='right'>
        <Statistic horizontal color='green'>
          <Statistic.Value id={id}>
            {symbol}
            <Icon size='small' name='rupee' />
            {rupeeFormatter(Math.round(amount))}
          </Statistic.Value>
        </Statistic>
      </List.Content>
    )
  }
  const displayHeading = heading => <Subtitle1 content={heading} />

  const renderDiscount = (discountedRiders) => {
    const discountsListItem = []
    if (selectedProductDetails.ncbDiscountAmount) {
      const { ncbDiscountAmount, ncbPercentageApplied } = selectedProductDetails
      discountsListItem.push(
        <List.Item key='ncbDiscount'>
          {displayAmount('ncbDiscount', ncbDiscountAmount, '-')}
          {displayHeading(`NCB Discount (${ncbPercentageApplied}%) of OD Premium`)}
        </List.Item>
      )
    }
    discountedRiders.forEach((rider, i) => {
      if (rider) {
        const { riderName, premium } = rider
        discountsListItem.push(
          <List.Item key={`riderDiscount-${i}`}>
            {displayAmount(`riderDiscount-${i}`, premium, '-')}
            {displayHeading(`${riderName}`)}
          </List.Item>
        )
      }
    })
    return discountsListItem
  }

  const renderBuiltInKit = () => {
    const builinKit = selectedProductDetails.builtInLpgCngPremium
    if (isNotDefined(builinKit) || (!isNotDefined(builinKit) && builinKit <= 0)) return null
    return (
      <List.Item key='builtInKit'>
        {displayAmount('builtInKit', builinKit)}
        {displayHeading('Built In LPG/CNG Premium')}
      </List.Item>
    )
  }

  const renderPremium = () => {
    const premiumsListItem = []
    if (['car', 'bike'].includes(props.formData?.insuranceType)) {
      if (selectedProductDetails.payment?.basicODPremium) {
        const { basicODPremium } = selectedProductDetails.payment
        premiumsListItem.push(
          <List.Item key='baseOD'>
            {displayAmount('baseOD', basicODPremium)}
            {displayHeading('Base OD Premium')}
          </List.Item>
        )
      }
      if (selectedProductDetails.payment?.basicTPPremium) {
        const { basicTPPremium } = selectedProductDetails.payment
        premiumsListItem.push(
          <List.Item key='baseTP'>
            {displayAmount('baseTP', basicTPPremium)}
            {displayHeading('Base TP Premium')}
          </List.Item>
        )
      }
    } else if (props.formData?.insuranceType === 'annuity') {
      if (selectedProductDetails.payment?.purchasePrice) {
        const { purchasePrice } = selectedProductDetails.payment
        premiumsListItem.push(
          <>
            <List.Item key='baseP'>
              {displayAmount('basePurchasePrice', purchasePrice)}
              {displayHeading('Base Premium')}
            </List.Item>
            <Divider />
          </>
        )
      }
      if (selectedProductDetails?.totalAnnualPayout) {
        const { totalAnnualPayout } = selectedProductDetails
        premiumsListItem.push(
          <List.Item key='baseTP'>
            {displayAmount('baseTP', totalAnnualPayout)}
            {displayHeading('Total Annuity Payout')}
          </List.Item>
        )
      }
    } else {
      const { premium } = selectedProductDetails.payment
      premiumsListItem.push(
        <List.Item key='baseP'>
          {displayAmount('basePremium', premium)}
          {displayHeading('Base Premium')}
        </List.Item>
      )
    }

    return premiumsListItem
  }

  /**
   * Returns Contents of right segment
   *
   * @param {object} product
   * @param {string} paymentFrequency
   * @param {number} premium
   * @param {number} gstBasic
   * @returns reactObject
   * @memberof PaymentPage
   */
  function rightSegment (product, paymentFrequency, selectedProductDetails) {
    const {
      totalPremiumWithGst,
      riders,
      gst,
      paymentMultiple,
      totalPremium,
      purchasePriceWithGst
    } = selectedProductDetails.payment

    const totalGst = totalPremiumWithGst - totalPremium
    const annuityTotalGst = purchasePriceWithGst - selectedProductDetails.payment?.purchasePrice

    // to filter out riders which have premium = 0
    const filteredRiders = riders && riders.filter(rider => rider.premium !== 0)
    const discountedRiders = filteredRiders && filteredRiders.filter(rider => rider.riderName.includes('Discount'))
    // UI for diaplaying Riders
    const ridersForDisplay = filteredRiders && filteredRiders.map((rider, i) => {
      if (rider.premium !== 0 && !rider.riderName.includes('Discount')) {
        return (
          <List.Item key={`rightSegmentListItem${i}`}>
            <span id={`${rider.riderName}-riderAmt`}>
              {rider.premium
                ? displayAmount(`${rider.riderName}-riderAmount`, rider.premium)
                : null}
            </span>
            <Body2>{rider.riderName}</Body2>
          </List.Item>
        )
      }
      return null
    })
    const showRider = filteredRiders && filteredRiders.length > 0
    const tncText = props.configure.paymentSummary.tnc
    const gstDisplay = gst ? `(${gst}%)` : ''

    return (
      <>
        <div className='paymentSummaryHeader'>
          <H6 color='white'>
            <Image className='size16' src={buildCommonImagePath('document-icon.png')} />
            Premium Summary
          </H6>
        </div>
        <div className='rightSegment'>
          <List>
            {renderPremium()}
            {paymentMultiple && paymentMultiple > 1 && (
              <List.Item>
                {displayHeading(`Premium for ${paymentMultiple} months`)}
              </List.Item>
            )}

            <Divider />
            {showRider && (
              <>
                <List.Item>
                  {displayHeading(props.formData?.insuranceType !== 'health' ? 'Riders' : 'Riders included')}
                  <List>{ridersForDisplay}</List>
                </List.Item>
                <Divider />
              </>
            )}
            {['car', 'bike'].includes(props.formData?.insuranceType) && (
              <>
                {renderBuiltInKit()}
                {renderDiscount(discountedRiders)}
                <List.Item>
                  {displayAmount('totalPremium', totalPremium)}
                  {displayHeading('Base Premium')}
                </List.Item>
                <Divider />
              </>
            )}
            {paymentMultiple && paymentMultiple > 0 && (
              <List.Item>
                {props.formData?.insuranceType === 'annuity' ? displayAmount('totalGst', annuityTotalGst) : displayAmount('totalGst', totalGst)}
                {displayHeading('Total GST ')}
              </List.Item>
            )}
            <Divider />
            <List.Item>
              <span id='rightSideTotalPremium'>
                {props.formData?.insuranceType === 'annuity' ? displayAmount('premiumWithGst', purchasePriceWithGst) : displayAmount('premiumWithGst', totalPremiumWithGst)}
              </span>
              {displayHeading(`Premium inclusive of GST ${gstDisplay}`)}
            </List.Item>
            <Divider />
            <List.Item>
              {displayHeading('All the amounts are rounded to nearest rupee and final payment amount may vary with displayed amount.')}
            </List.Item>
          </List>
          <div className='flexContainer tncCheckBox'>
            <Checkbox checked={tnc} onClick={checkTnc} />
            <Body2
              className='tnc'
              content={tncText.format({
                productName: product.productName.shortName
              })}
            />
          </div>
          <div className='proceedButtons'>
            <Button
              id='paymentButton'
              content='PROCEED TO PAY'
              secondary
              onClick={props.nextBookingStep}
              disabled={!tnc}
            />
          </div>
        </div>
      </>
    )
  }
  /**
   * Returns left segment contents (for web view only)
   *
   * @param {number} total
   * @param {string} frequency
   * @param {object} product
   * @param {string} pdfPath
   * @param {string} sisPath
   * @returns reactObject
   * @memberof PaymentPage
   */
  function leftSegment (
    frequency,
    product,
    pdfPath,
    sisPath,
    _detailedPolicySummaryOrder
  ) {
    const ComponentMap = productComposer({
      product,
      termPolicySummaryDisplay:
        props.configure.booking.termPolicySummaryDisplay,
      formData: props.formData,
      productData: props.productData
    })
    return (
      <div>
        <ComponentMap.insurer />
        <Grid className='leftSegmentGrid'>
          <Grid.Row className='appNumber'>
            <Header sub>
              {`APPLICATION NUMBER: ${props.selectedProductDetails.applicationNumber}`}
            </Header>
          </Grid.Row>
          {props.formData?.insuranceType !== 'annuity' && (
            <Grid.Row columns='equal' className='policySummary'>
              <Grid.Column>
                <ComponentMap.cover />
              </Grid.Column>
              <Grid.Column>
                <ComponentMap.coverPeriod />
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row columns='equal' className='policySummary'>
            <Grid.Column>
              <Header sub>PRODUCT BROCHURE</Header>
              <Caption
                color='secondary'
                as='a'
                href={pdfPath}
                target='_blank'
                id={`${product.productOptionId}-product-brochure`}
              >
                <Icon name='download' />
                Download
              </Caption>
            </Grid.Column>
            {sisPath && (
              <Grid.Column>
                <Header sub>SIS DOCUMENT</Header>
                <Caption
                  className='d-flex caption'
                  color='secondary'
                  as='a'
                  onClick={() => downloadFile(props.getFileUrl(sisPath), sisPath + '.pdf')}
                  id={`${product.productOptionId}-sis-document`}
                >
                  <Icon name='download' />
                  Download
                </Caption>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  /**
   *  Returns Top segment contents (for mobile view)
   *
   * @param {object} product
   * @param {string} pdfPath
   * @param {string} sisPath
   * @returns reactObject
   * @memberof PaymentPage
   */
  function topSegment (product, pdfPath, sisPath) {
    return (
      <div>
        <List verticalAlign='middle'>
          <List.Item className='d-flex justify-content-between'>
            <List.Content>
              <Subtitle1
                color='secondary'
                as='a'
                href={pdfPath}
                target='_blank'
                className='d-flex gap-1'
                id={`${product.productOptionId}-product-brochure`}
              >
                <Icon name='download' />
                PRODUCT BROCHURE
              </Subtitle1>
            </List.Content>
            {sisPath && (
              <List.Content className='flex-grow-1'>
                <Subtitle1
                  color='secondary'
                  as='a'
                  className='d-flex justify-content-end  gap-1'
                  onClick={() => { downloadFile(props.getFileUrl(sisPath), sisPath + '.pdf') }}
                  id={`${product.productOptionId}-sis-document`}
                >
                  <Icon name='download' />
                  SIS DOCUMENT
                </Subtitle1>
              </List.Content>
            )}
          </List.Item>
        </List>
      </div>
    )
  }

  const { device, configure, selectedProductDetails, product } = props
  const {
    booking: { detailedPolicySummaryOrder }
  } = configure
  const { frequencyDisplay, frequency } = product.productPremium
  const pdfPath = product.productBrochureUrl
  const applicationNumber = selectedProductDetails.applicationNumber
  if (device === 'mobile') {
    return (
      <div className='paymentSummary'>
        <Header sub className='mb-md'>{`APPLICATION NUMBER: ${applicationNumber}`}</Header>
        <DividedSegmentVertical
          topSegment={topSegment(
            product,
            pdfPath,
            selectedProductDetails.sisFileId
          )}
          bottomSegment={rightSegment(
            product,
            frequency,
            selectedProductDetails
          )}
        />
      </div>
    )
  }
  return (
    <div className='paymentSummary'>
      <div className='paymentPage' id='paymentPage'>
        <DividedSegment
          leftSegment={leftSegment(
            frequencyDisplay,
            product,
            pdfPath,
            selectedProductDetails.sisFileId,
            detailedPolicySummaryOrder
          )}
          rightSegment={rightSegment(
            product,
            frequency,
            selectedProductDetails
          )}
        />
      </div>
    </div>
  )
}

PaymentSummary.propTypes = {
  formData: PropTypes.object.isRequired,
  // formSteps: PropTypes.array.isRequired,
  // hideSummary: PropTypes.func.isRequired,
  device: PropTypes.string.isRequired
}

export default PaymentSummary
