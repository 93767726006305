import { useGlobalState } from 'components/customHooks'

export const useLoading = () => {
  const reportForTotalLeadsLoading = useGlobalState(
    (state) => state.loading.effects.distributorReport.loadReportForTotalLeads
  )
  const reportForCompletedPaymentButFormSubmissionPendingLoading = useGlobalState(
    (state) =>
      state.loading.effects.distributorReport
        .loadReportForCompletedPaymentButformSubmissionPending
  )
  const reportForSubmittedApplicationLoading = useGlobalState(
    (state) =>
      state.loading.effects.distributorReport.loadReportForSubmittedApplication
  )
  const reportForPendingApplicationLoading = useGlobalState(
    (state) =>
      state.loading.effects.distributorReport.loadReportForPendingApplication
  )
  const reportForMailingDetailsLoading = useGlobalState(
    (state) =>
      state.loading.effects.distributorReport.loadReportForMailingDetails
  )
  const reportForSmsDetailsLoading = useGlobalState(
    (state) => state.loading.effects.distributorReport.loadReportForSmsDetails
  )
  const reportForDropOffStageLoading = useGlobalState(
    (state) => state.loading.effects.distributorReport.loadReportForDropOffStageReport
  )
  const conversionReportLoading = useGlobalState(
    (state) => state.loading.effects.distributorReport.loadReportForConversionReport
  )

  return {
    reportForTotalLeadsLoading,
    reportForCompletedPaymentButFormSubmissionPendingLoading,
    reportForSubmittedApplicationLoading,
    reportForPendingApplicationLoading,
    reportForMailingDetailsLoading,
    reportForSmsDetailsLoading,
    reportForDropOffStageLoading,
    conversionReportLoading
  }
}
