import { getClaimHoldingsEffect, getOtpForIntimation, getSchemaForClaims, postClaimIntimation, trackClaimsEffect, validateOtpForIntimation, postClaimRegistrationEffect } from './claimsConfigureEffects'
import { effectsHandler } from '../insurance/modelHelpers'
import { isNotEmpty } from 'utils'

const claimsService = {
  name: 'claimsService',
  state: {
    term: {
      applications: [],
      selectedApplicationNumber: -1
    },
    health: {
      applications: [],
      selectedApplicationNumber: -1
    },
    annuity: {
      applications: [],
      selectedApplicationNumber: -1
    },
    car: {
      applications: [],
      selectedApplicationNumber: -1
    },
    bike: {
      applications: [],
      selectedApplicationNumber: -1
    },
    trackClaims: {},
    claimIntimation: {},
    claimRegistration: {},
    otp: {}
  },
  selectors: (slice, createSelector, hasProps) => ({
    getClaimStatus () {
      return slice(claimsService => {
        const data = claimsService.trackClaims.claimApplications
        return isNotEmpty(data) ? data : {}
      })
    },
    getApplications () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (applications, insuranceType) => {
          return applications[insuranceType].applications
        }
      )
    },
    getApplicationsCount () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (applications, insuranceType) => {
          return applications[insuranceType].applicationsCount
        }
      )
    },
    getSelectedApplicationNumber () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (applications, insuranceType) => applications[insuranceType].selectedApplicationNumber
      )
    },
    getApplicationById () {
      return createSelector(
        slice,
        (rootState, { insuranceType, applicationNumber }) => ({
          insuranceType,
          applicationNumber
        }),
        (applications, { insuranceType, applicationNumber }) => {
          return applications[insuranceType].applications.find(app => app.intimationNo === applicationNumber)
        }
      )
    }
  }),
  reducers: {
    getDataForClaimStatus (state, payload) {
      state.trackClaims = payload
    },
    getSchema (state, { insuranceType, ...payload }) {
      state[insuranceType].schema = payload
    },
    claimIntimation (state, { insuranceType, ...payload }) {
      state.claimIntimation[insuranceType] = payload
    },
    claimRegistration (state, { insuranceType, ...payload }) {
      state.claimRegistration[insuranceType] = payload
    },
    getHoldingsData (state, { insuranceType, ...payload }) {
      state[insuranceType].applications = payload
    },
    updateSelectedApplicationNumber (state, { applicationNumber, insuranceType }) {
      state[insuranceType].selectedApplicationNumber = applicationNumber
      return state
    },
    storeApplicationsData (state, { modifiedApplications, count, insuranceType }) {
      state[insuranceType].applications = modifiedApplications
      state[insuranceType].applicationsCount = count
      return state
    },
    OtpForIntimation (state, { insuranceType, ...payload }) {
      state.otp[insuranceType] = payload
    }

  },
  effects: (dispatch) => ({
    trackClaim: effectsHandler(dispatch, trackClaimsEffect),
    getSchemaForClaims: effectsHandler(dispatch, getSchemaForClaims),
    postClaimIntimation: effectsHandler(dispatch, postClaimIntimation),
    getClaimHoldings: effectsHandler(dispatch, getClaimHoldingsEffect),
    getOtpForIntimation: effectsHandler(dispatch, getOtpForIntimation),
    validateOtpForIntimation: effectsHandler(dispatch, validateOtpForIntimation),
    postClaimRegistration: effectsHandler(dispatch, postClaimRegistrationEffect)

  })
}

export default claimsService
