import { connect } from 'react-redux'
import { ClaimIntimation } from './components/claims'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    distributorId: models.configure.getDistributorId,
    customerDetails: models.customer.getCustomerDetails,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    availableProducts: models.configure.getAvailableProducts,
    countries: models.configure.getCountriesMaster
    // schemaForClaims: models.claimsService.getSchemaForClaims
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...ownProps,
    ...selectorData,
    insuranceType,
    schema: state.claimsService.term,
    otp: state.claimsService.otp,
    claimIntimation: state.claimsService.claimIntimation,
    intimation: state.loading.effects.claimsService.postClaimIntimation,
    store: ownProps.store
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // effects go here
    // selectors go here
    getSchemaForClaims: (query) => {
      return dispatch.claimsService.getSchemaForClaims(query)
    },
    postIntimation: (data) => {
      return dispatch.claimsService.postClaimIntimation(data)
    },
    getOtp: (data) => {
      return dispatch.claimsService.getOtpForIntimation(data)
    },
    validateOtp: (data) => {
      return dispatch.claimsService.validateOtpForIntimation(data)
    }
  }
}

const ClaimIntimationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimIntimation)
ClaimIntimationContainer.path = 'claim-intimation'
export default ClaimIntimationContainer
