import { Image, Button } from 'semantic-ui-react'
import { H6, Subtitle1 } from 'components/Header'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { buildCommonImagePath } from 'components/buildImagePath'
import 'components/css/infoScreen.scss'
import 'components/css/bounceAnimation.scss'

const GeneralInfoScreen = (props) => {
  const {
    imagePath,
    title,
    description,
    buttonText,
    buttonImgPath,
    onClick,
    fullScreen,
    id,
    animation,
    className
  } = props
  const imageUrl = buildCommonImagePath(imagePath)
  const buttonImgUrl = buildCommonImagePath(buttonImgPath)
  const imgClassName = `infoScreenImage ${animation}`
  return (
    <div
      id={id}
      className={`infoScreen ${fullScreen ? '' : 'filterIncluded'} ${className}`}
    >
      <div className='innerContainer'>
        {imagePath && <Image className={imgClassName} style={{ width: 100 }} src={imageUrl} />}
        <H6 color='black' className='heading'>{title}</H6>
        <Subtitle1 color='grey' className='description'>
          <DisplayHtmlString htmlContent={description} />
        </Subtitle1>
        {onClick && (
          <Button className='image' secondary onClick={onClick}>
            <Image className='size14' src={buttonImgUrl} />
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  )
}
export default GeneralInfoScreen
