import { PureComponent } from 'react'
import { List } from 'semantic-ui-react'
import ExpandableListItem from 'components/ExpandableListItem'
import EditAddOns from '../../EditAddOns'

class ReviewIncludedAddons extends PureComponent {
  render () {
    const {
      rider,
      schema,
      uiSchema,
      riderFormData,
      rules,
      productOptionId,
      checked,
      heading,
      description,
      shortName,
      insuranceType,
      points,
      descriptionPiped,
      riderBrochureUrl,
      key,
      isEdit,
      onSubmit,
      onCancel,
      updatePopUp,
      device
    } = this.props
    const popUpContent = (
      <List.Content className='riderEdit'>
        <EditAddOns
          schema={schema}
          uiSchema={uiSchema}
          formData={riderFormData}
          rules={rules}
          onSubmit={onSubmit}
          submitText='UPDATE ADDON'
          onCancel={onCancel}
          size='medium'
        />
      </List.Content>
    )
    const listItemProps = {
      key,
      id: rider.riderId,
      productOptionId,
      checked,
      editable: true,
      isEdit,
      editableContent: popUpContent,
      heading,
      description,
      shortName,
      insuranceType,
      points,
      descriptionPiped,
      brochureUrl: riderBrochureUrl,
      onChangeClick: updatePopUp,
      device
    }
    return (
      <ExpandableListItem {...listItemProps} />
    )
  }
}
export default ReviewIncludedAddons
