import { feathersApp } from '../feathers'
import { isNotDefined } from 'utils'
import { getStore } from '../store'
const service = (apiVersion, app, serviceName, _insuranceType, type) => {
  const env = process.env.NODE_ENV
  let serviceType = ''
  if (!isNotDefined(type)) {
    serviceType = env === 'production' ? `${type}-` : `${type}/`
  }
  return env === 'production' ? app.service(`${serviceType}${serviceName}/${apiVersion}`) : app.service(`${serviceType}${serviceName}`)
}
export const getService = (insuranceType = 'term') => {
  const store = getStore()
  const { configure } = store.getState()
  const { apiVersion } = configure
  const app = feathersApp(configure)
  const env = process.env.NODE_ENV

  // Insurance Service
  const bokingPrepay = env === 'production' ? 'bookingprepay' : 'booking-prepay'
  const bokingPostpay = env === 'production' ? 'bookingpostpay' : 'booking-postpay'

  const productService = service(apiVersion, app, 'product', insuranceType, 'insurance')
  const insuranceConfigureService = service(apiVersion, app, 'configure', insuranceType, 'insurance')
  const bookingService = service(apiVersion, app, 'booking', insuranceType, 'insurance')
  const paymentService = service(apiVersion, app, 'payment', null, 'insurance')
  const quotesService = service(apiVersion, app, 'quotes', insuranceType, 'insurance')
  const featuresService = service(apiVersion, app, 'features', insuranceType, 'insurance')
  const bookingPrepayService = service(apiVersion, app, bokingPrepay, insuranceType, 'insurance')
  const bookingPostpayService = service(apiVersion, app, bokingPostpay, null, 'inusrance')
  const policyService = service(apiVersion, app, 'policy', insuranceType, 'insurance')

  // Common Services
  const enquiryService = service(apiVersion, app, 'enquiry', insuranceType)
  const configureService = service(apiVersion, app, 'configure', insuranceType)
  const uploadService = service(apiVersion, app, 'uploads')
  const mailerService = service(apiVersion, app, 'mailer')
  const institutionService = service(apiVersion, app, 'institution')
  const tptCustomerService = service(apiVersion, app, 'tptcustomer')
  const customerService = service(apiVersion, app, 'customer')
  const verifyService = service(apiVersion, app, 'verify', insuranceType)
  const metadataService = service(apiVersion, app, 'metadata', insuranceType)
  const applicationService = service(apiVersion, app, 'applications', insuranceType)
  const tptTokenService = service(apiVersion, app, 'tpttoken')
  const tptLoginRedirect = service(apiVersion, app, 'tptloginredirect')
  const tptCustomerTokenService = service(apiVersion, app, 'tptcustomertoken')
  const keycloakService = service(apiVersion, app, 'logout', null, 'keycloak')
  // Reports Service
  const reportType = env === 'production' ? 'report' : 'application/leads'
  const totalLeadsReportService = service(apiVersion, app, 'totalleads', insuranceType, reportType)
  const formSubmissionPendingReportService = service(apiVersion, app, 'formsubmissionpending', insuranceType, reportType)
  const submittedApplicationReportService = service(apiVersion, app, 'submittedapplication', insuranceType, reportType)
  const pendingApplicationReportService = service(apiVersion, app, 'pendingapplications', insuranceType, reportType)
  const smsDetailsReportService = service(apiVersion, app, 'sms', insuranceType, reportType)
  const emailDetailsReportService = service(apiVersion, app, 'email', insuranceType, reportType)
  const dropOffReportService = service(apiVersion, app, 'dropoff', insuranceType, reportType.replace('leads', 'report'))
  const conversionReportService = service(apiVersion, app, 'conversion', insuranceType, reportType.replace('leads', 'report'))

  // Claims Service
  const trackClaims = service(apiVersion, app, 'application', insuranceType, 'claims')
  const getSchema = service(apiVersion, app, 'schema', insuranceType, 'claims')
  const postClaimIntimation = service(apiVersion, app, 'application', insuranceType, 'claims')
  const postClaimRegistration = service(apiVersion, app, 'application', insuranceType, 'claims')
  const getHoldings = service(apiVersion, app, 'application', insuranceType, 'claims')
  const otpGeneration = service(apiVersion, app, 'verify', insuranceType, 'claims')
  const otpValidation = service(apiVersion, app, 'verify', insuranceType, 'claims')

  return { app, productService, insuranceConfigureService, bookingService, paymentService, quotesService, featuresService, bookingPrepayService, bookingPostpayService, policyService, enquiryService, configureService, uploadService, mailerService, institutionService, tptCustomerService, customerService, verifyService, metadataService, applicationService, keycloakService, tptTokenService, tptLoginRedirect, totalLeadsReportService, formSubmissionPendingReportService, submittedApplicationReportService, pendingApplicationReportService, smsDetailsReportService, emailDetailsReportService, dropOffReportService, conversionReportService, tptCustomerTokenService, trackClaims, getSchema, postClaimIntimation, getHoldings, otpGeneration, otpValidation, postClaimRegistration }
}
