import { useState } from 'react'
import { List, Segment, Button, Icon } from 'semantic-ui-react'
import { H6, Body1, Subtitle2 } from 'components/Header'

import ApplicationStepAndFlag from './ApplicationStepAndFlag'
import ApplicationDetailsContent from './ApplicationDetailsContent'
import RiderDetailContent from '../RiderDetailContent'
import ProductBenefits from '../ProductBenefits'
import Features from '../Features'

import '../applicationDetails.scss'

const ApplicationDetails = props => {
  const productBrochure = props.application.productDetails.productBrochureUrl
  const { application, getFormStepStatus, continueHandler, getStatusHandler } = props
  const [readMore, setReadMore] = useState(false)

  const showHideDataHandler = () => {
    setReadMore(!readMore)
  }
  const formStepStatus = getFormStepStatus(application.applicationNumber)
  const applicationCompleted = formStepStatus.find(ele => !ele.completed)
  const moreData = (
    <>
      <div className='row riders'>
        <RiderDetailContent rider={application.ApplicationRiders} insuranceType={application.insuranceType} />
      </div>
      <div className='row features'>
        <Features features={application.productDetails.features} />
        {/* <Features features={FreeFeatures} /> */}
      </div>
      <div className='row benefits'>
        <ProductBenefits productBenefit={application.productDetails.benefits} />
      </div>
      <div className='row dataButton'>
        <span onClick={showHideDataHandler}>- View Less Details</span>
      </div>
    </>
  )

  const continueButton = (
    <Button
      secondary
      disabled={!applicationCompleted}
      onClick={
          async () => {
            return await continueHandler(application.applicationNumber, application.finanalyticsId)
          }
        }
      content='CONTINUE FORM FILLING'
    />
  )

  const getStatusButton = (
    <Button
      secondary
      disabled={!applicationCompleted}
      onClick={
          async () => {
            return getStatusHandler(application.applicationNumber)
          }
        }
      content='GET STATUS'
    />
  )

  return (
    <>
      <Segment className='userApplicationDetails' style={{ minHeight: '20rem' }}>
        <div className='titleAndDownloadBrochure'>
          <H6 className='h6' color='primaryLight' content='Your Application Details' />
          <Subtitle2
            color='secondary'
            as='a'
            href={productBrochure}
            target='_blank'
            className='d-flex'
          >
            <Icon name='download' />
            PRODUCT BROCHURE
          </Subtitle2>
        </div>
        <List>
          <List.Item className='applicationsDetails'>
            <div className='eachRow'>
              <div className='table'>
                <ApplicationDetailsContent application={application} />
                {
                  (application.ApplicationRiders.length !== 0 || application.productDetails.benefits.length !== 0 || application.productDetails.features.length !== 0) && (
                    <div className='row dataButton'>
                      <div>
                        <span hidden={readMore} onClick={showHideDataHandler}> + View More Details</span>
                      </div>
                      <div />
                    </div>
                  )
                }
                {readMore && (
                  moreData
                )}
              </div>
            </div>
          </List.Item>
        </List>
      </Segment>
      <Segment className='userApplicationFormSteps' style={{ minHeight: '20rem' }}>
        <H6 className='h6' color='primaryLight' content='Your Form Status' />
        {formStepStatus.length !== 0
          ? (
            <>
              <div>
                <div className='applicationSteps'>
                  {formStepStatus.map((step, index) =>
                    <ApplicationStepAndFlag key={index} ApplicationStep={step.title} StepDescription={step.stepDescription} Stepflag={step.completed} />
                  )}
                </div>
              </div>
              <div className='continueButton'>
                {['SC092', 'SC093', 'SC094', 'SC095'].includes(application.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode) ? getStatusButton : continueButton}
              </div>
            </>
            )
          : (
            <div className='empty'>
              <Body1>No Pending Steps</Body1>
            </div>
            )}

      </Segment>
    </>
  )
}
export default ApplicationDetails
