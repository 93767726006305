
const Ajv = require('ajv')
const ajv = new Ajv({
  allErrors: true,
  errorDataPath: 'property',
  format: 'full'
})
require('ajv-keywords')(ajv)

// following are custom keywords added for custom validation
// It is recommended to use an application-specific prefix for keywords to avoid current and future name collisions. Hence the name starts with smartcovr

ajv.addKeyword('smartcovr-dateValidation', {
  validate: function (check, data, schema, dataPath) {
    const minAge = schema.minAge
    const maxAge = schema.maxAge
    if (data) {
      const currentDate = new Date(data)
      if (currentDate.toString() === 'Invalid Date') {
        return false
      }
      const diffMs = Date.now() - currentDate.getTime()
      const ageDt = new Date(diffMs)
      const currentAge = Math.abs(ageDt.getUTCFullYear() - 1970)
      if (!isNaN(minAge) && diffMs < 0) {
        return false
      }
      if (!isNaN(minAge) && currentAge < minAge) {
        return false
      }
      if (!isNaN(maxAge) && currentAge > maxAge) {
        return false
      }
      return true
    }
  }
})

ajv.addKeyword('isNotEmpty', {
  validate: function validation (schema, data) {
    validation.errors = []
    return typeof data === 'string' && data.trim() !== ''
  },
  errors: false
})
module.exports = ajv
