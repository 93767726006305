import { useEffect, useState } from 'react'
import {
  useGlobalState,
  useAction
} from './useReduxFunctions'
// This is a custom hook which sets formData in store and returns appropriate loading.
// loadingConfigure should be passed in case of reloading conditions. If not passed, it will fail as it will assume that configuration has already loaded
export default function useSetFormData (insuranceType, loadingConfigure = false, typeOfQuote = 'listing') {
  const loadingProductsWithQuotes = useGlobalState(state => state.loading.effects.insuranceProducts.updateProductsWithQuotes)
  const loadingAllProductData = useGlobalState(state => state.loading.effects.insuranceProducts.loadProducts)
  const loadingChosenProductData = useGlobalState(state => state.loading.effects.insuranceProducts.loadChosenProducts)
  const loadingFeatures = useGlobalState(state => state.loading.effects.insuranceProducts.loadFeatures)
  const loadingProductData = loadingAllProductData || loadingChosenProductData || loadingFeatures
  // productsLoaded - in case of reload, when we load all products only if there are no products in the state. need a better way than using productsLoaded
  // reload flag is passed in setFormData as true - if it is used for reloading purpose only. i.e getting all products when page reloads
  const productsLoaded = useGlobalState(state => state.insuranceProducts[insuranceType].plainProducts.length > 0)

  const setEnquiryData = useAction(dispatch => dispatch.insuranceEnquiry.setEnquiryData)
  const setHooksError = useAction(dispatch => dispatch.errors.setHooksError)

  // loading state is total loading from button click to loading all quotes
  const [loading, setLoading] = useState(false)
  const [dispatchQueued, setDispatchQueued] = useState(false)
  const [data, setData] = useState({})
  const check = !loadingProductData && !loadingConfigure
  // FIXME: flawed and complicated logic. check again
  useEffect(() => {
    if (loading) {
      setLoading(loadingProductsWithQuotes)
    }
  },
  // eslint-disable-next-line
  [ loadingProductsWithQuotes ])

  useEffect(() => {
    try {
      if (dispatchQueued && check) {
        setDispatchQueued(false)
        setEnquiryData({
          ...data,
          insuranceType
        })
      }
    } catch (err) {
      setHooksError(err)
    }
  },
  // eslint-disable-next-line
  [ loadingProductData, loadingConfigure ])

  // setFormData is called if - 1. reload is false. 2. if reload is true, it's called only if products are not already loaded/ loading
  // if check condition is false, i.e products are still loading, dispatch is queued and called once they're loaded
  const setFormData = ({ formData, filterData, productDataFragment, all, shouldCalculateRecommendations }, reload) => {
    if (!(reload && (productsLoaded || loadingProductsWithQuotes))) {
      setLoading(true)
      if (check) {
        setEnquiryData({
          formData,
          filterData,
          typeOfQuote,
          productDataFragment,
          insuranceType,
          all,
          shouldCalculateRecommendations
        })
      } else {
        setDispatchQueued(true)
        setData({ formData, filterData, productDataFragment, typeOfQuote })
      }
    }
  }

  // loadingSetFormData is set only if setFormData from this hook is called whereas, loadingProductsWithQuotes is true even if setEnquiryData called explicitly. can change this if required.
  return [{ loadingProductsWithQuotes, loadingSetFormData: loading }, setFormData]
}
