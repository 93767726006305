import { useEffect, useState } from 'react'
import TrackClaimModal from './TrackClaimModal'
import DashBoard from './DashBoard'
import Loading from 'components/Loading'

const TrackClaims = (props) => {
  const { insuranceType, getClaimStatus, claimStatusLoading, customerDetails, isLoggedIn } = props
  const [claimType, setClaimType] = useState(null)
  const [formstep, setFormStep] = useState(1)

  const queryforClaimStatus = {
    insuranceType,
    iamId: customerDetails.iamId,
    activeClaim: claimType === 'Current',
    id: 'status'
  }
  useEffect(() => {
    claimType && getClaimStatus(queryforClaimStatus)
  }, [claimType])

  // On Load checks If not logged in redirected to the home page
  useEffect(() => {
    if (!isLoggedIn) {
      props.redirectToPath('/')
    }
  },
  // eslint-disable-next-line
    [isLoggedIn])

  if (claimStatusLoading) {
    return (<Loading open/>)
  }
  const claimProps = { ...props, setFormStep, setClaimType, claimType }
  return (
    <>
      {formstep === 1 && <TrackClaimModal {...claimProps} />}
      {formstep === 2 && <DashBoard {...claimProps} />}
    </>
  )
}
export default TrackClaims
