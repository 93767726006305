import React from 'react'
import Transition from 'react-transition-group/Transition'
const TransitionComponent = ({ children, duration, in: inProp, keyId }) => {
  return (
    <Transition
      in={inProp}
      timeout={{
        enter: 0,
        exit: duration
      }}
      key={keyId}
      >
      {
        (status) => {
          if (status === 'exited') {
            return null
          }
          return React.cloneElement(children, {
            className: `poTransition ${status}`
          })
        }
      }
    </Transition>
  )
}
export default TransitionComponent
