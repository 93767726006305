import { connect } from 'react-redux'

import BrowseMoreProductsButton from './BrowseMoreProductsButton'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = ownProps.store.select(models => ({
    selectedApplicationNumber: models.applications.getSelectedApplicationNumber,
    selectedApplicationNumberForPolicy: models.policies.getSelectedPolicy
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  const { selectedApplicationNumber, selectedApplicationNumberForPolicy } = selectorData
  const { getApplicationById } = select.applications
  const { getPolicyById } = select.policies
  const selectedApplication = getApplicationById(state, {
    insuranceType,
    applicationNumber: selectedApplicationNumber
  })
  const selectedPolicyData = getPolicyById(state, {
    insuranceType,
    id: selectedApplicationNumberForPolicy
  })
  return {
    ...ownProps,
    ...selectorData,
    selectedApplication,
    selectedPolicyData,
    insuranceType
  }
}

const mapDistpatchProps = (dispatch, ownProps) => {
  const {
    insuranceType,
    store: { select, getState }
  } = ownProps
  return {
    checkFormDataValidity: () =>
      select.insuranceEnquiry.checkFormDataValidity(getState(), {
        insuranceType
      })
  }
}

const BrowseMoreProductsButtonContainer = connect(
  mapStateToProps,
  mapDistpatchProps
)(BrowseMoreProductsButton)
export default BrowseMoreProductsButtonContainer
