import { useState } from 'react'
import { Button, Checkbox, Header } from 'semantic-ui-react'
import DisplayHtmlString from 'components/DisplayHtmlString'

const styles = {
  contentStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}
const TermsAndCondition = (props) => {
  const { nextBookingStep, configure } = props
  const [tnc, setTnc] = useState(false)
  const checkTnc = event => {
    event.preventDefault()
    setTnc(!tnc)
  }
  return (
    <>
      <Header as='h5' style={styles.contentStyle}>Terms and Conditions</Header>
      <DisplayHtmlString
        htmlContent={configure.booking.termsAndConditions.content}
        style={{
          fontSize: '1.2rem'
        }}
      />
      <Checkbox
        className='flexContainer tncCheckBox'
        style={{ paddingTop: '1rem', fontWeight: 'bold' }}
        material checked={tnc} onClick={checkTnc}
        label='I Agree to Terms and Conditions'
      />
      <div className='submitButtons' style={styles.contentStyle}>
        <Button
          id='submitButton'
          content='Submit'
          primary
          onClick={nextBookingStep}
          disabled={!tnc}
        />
      </div>
    </>
  )
}

export default TermsAndCondition
