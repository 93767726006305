
import { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { createOrder, capturePayment } from 'smartcovr_api/src/paymentApi'

import { Icon, Button, Statistic, List, Grid, Image, Divider, Checkbox } from 'semantic-ui-react'
import { Header, H6, Subtitle1, Caption, Body2 } from 'components/Header'
import DividedSegment from 'components/DividedSegment'
import DividedSegmentVertical from 'components/DividedSegmentVertical'
import OnSubmitModal from 'components/jsonSchemaForms/components/OnSubmitModal'
import { rupeeFormatter, isNull, isNotEmpty } from 'utils'
import { useInitialDataLoader, useSetFormData, useSetChosenProduct } from 'components/customHooks'
import Loading from 'components/Loading'
import { getDisplayData } from '../helper/productHelper'
import { productComposer } from '../components/product/bookingIndex'
import 'components/css/paymentSummary.scss'
import { buildCommonImagePath, buildDistributorImagePath } from 'components/buildImagePath'

// FIXME: get gst from db
const gstPercent = 18
/* eslint react/jsx-pascal-case: 0 */
const PaymentSummary = (props) => {
  const [fileName, setFileName] = useState(null)
  const [fileError, setFileError] = useState(false)
  const [capturePaymentLoading, setCapturePaymentLoading] = useState(false)
  const [tnc, setTnc] = useState(false)
  const componentRef = useRef({
    paymentSubmitted: false,
    paymentForm: ''
  })

  const {
    insuranceType,
    distributorId
  } = props
  const { loadingConfigure, configure } = useInitialDataLoader(insuranceType, props.enquiryId, distributorId)
  const [{ loadingSetFormData }, setFormData] = useSetFormData(insuranceType, loadingConfigure)
  const [, setChosenProduct] = useSetChosenProduct(insuranceType, loadingConfigure, loadingSetFormData)

  useEffect(() => {
    setFormData({ formData: {} }, true)
    setChosenProduct({})
  },
  // eslint-disable-next-line
  [ ])
  const generateSiS = async () => {
    // FIXME: HACK. temp
    // setFileName('dummy-sis-url')s
    const { enquiry, chosenProductMetaData, enquiryId } = props
    const { institutionId } = chosenProductMetaData
    setFileError(false)
    try {
      const response = await props.generateSiS({
        enquiry,
        insuranceType,
        institutionId,
        enquiryId,
        distributorId
      })
      const { applicationNumber, quoteId, quotedt, sisFileUrl, soldBranchCode, soldRmIamId, totPremium } = response.formData.chosenProduct
      setFileName(sisFileUrl)
      const chosenProductParams = {
        sisFileUrl,
        applicationNumber,
        quoteId,
        quotedt,
        soldBranchCode,
        soldRmIamId,
        totPremium
      }
      props.updatechosenProduct(chosenProductParams)
    } catch (err) {
      console.log(err.message)
      setFileError(true)
    }
  }

  useEffect(() => {
    if (isNotEmpty(props.chosenProduct) && !fileName) {
      generateSiS()
    }
  },
  // eslint-disable-next-line
  [props.chosenProduct])

  useEffect(() => {
    const { chosenProductMetaData } = props
    if (chosenProductMetaData.OrderNumber && componentRef.current.paymentSubmitted) {
      if (componentRef.current.pgType === 'ownPg') {
        paymentHandler(componentRef.current.totalPremiumWithGst)
        componentRef.current.paymentSubmitted = false
      } else {
        document.getElementById('paymentRedirectFormContainer').innerHTML = componentRef.current.paymentForm
        const element = document.getElementById('payment-form-id')
        if (element) {
          element.submit()
          componentRef.current.paymentSubmitted = false
        }
      }
    }
  })

  const redirectToPrePayment = () => {
    props.redirectToPath('booking/pre-payment')
  }

  const paymentResponseHandler = async (response) => {
    console.log('payment response :- ', response)

    if (!response.error) {
      // success response
      // now capture the payment
      setCapturePaymentLoading(true)
      const captureResponse = await capturePayment({
        data: {
          paymentId: response.razorpay_payment_id,
          amount: componentRef.current.totalPremiumWithGst * 100
        },
        insuranceType,
        institutionId: props.chosenProductMetaData.institutionId
      })

      console.log('payment capture response :- ', captureResponse)
      window.location.href = captureResponse
      window.location.reload()
      // window.location.replace(captureResponse)
    } else {
      // failure response , handle this
    }
  }

  const defaultPaymentOptions = {
    key: 'rzp_test_gmTRy2bpBqI8m2',
    currency: 'INR',
    handler: paymentResponseHandler,
    theme: {
      color: '#0270f6'
    }
  }

  const paymentHandler = async (totalPremiumWithGst) => {
    try {
      const { enquiry, insuranceType } = props
      const orderResponse = await createOrder({ enquiry, insuranceType, totalPremiumWithGst })
      console.log('order created :- ', orderResponse)

      const {
        mobile
      } = props.formData

      // now initiate Payment
      const options = Object.assign({}, defaultPaymentOptions, {
        name: 'HDFC Securities',
        description: 'HDFC Securities (HSL)',
        image: buildDistributorImagePath('smartcovr-logo.png'),
        order_id: orderResponse.id,
        prefill: {
          name: 'Gaurav Kumar', // FIXME :
          email: 'gaurav.kumar@example.com', // FIXME :
          contact: mobile
        },
        notes: {
          address: 'Kanakia Rd'
        }
      })

      const rzp = new window.Razorpay(options)
      rzp.open()
    } catch (err) {
      console.log('error in create order :- ', err)
    }
  }

  const getPaymentRedirectionUrl = async (totalPremiumWithGst) => {
    // FIXME: Rediect to payment gateway route staightway.
    // props.redirectToPath('payment-gateway')

    // logic below is implementation where, payment redirection form is generated from backend
    const { enquiry, chosenProductMetaData, enquiryId } = props
    const { institutionId } = chosenProductMetaData
    try {
      const response = await props.getPaymentRedirectionUrl({
        enquiry,
        institutionId,
        insuranceType,
        enquiryId,
        distributorId
      })
      const { paymentUrl, formData: { chosenProduct: { OrderNumber } }, pgType } = response
      const chosenProductParams = {
        OrderNumber
      }
      props.updateChosenProduct(chosenProductParams)
      componentRef.current.paymentForm = paymentUrl
      componentRef.current.pgType = pgType
      componentRef.current.totalPremiumWithGst = totalPremiumWithGst
      componentRef.current.paymentSubmitted = true
    } catch (err) {
      console.log(err.message)
    }

    // Razorpay integration
  }

  const checkTnc = (event) => {
    event.preventDefault()
    setTnc(!tnc)
  }
  const listItemPdf = (pdfPath, display) => {
    const location = window.location.origin
    return (
      <Header
        sub
        as='a'
        href={`${location}/${pdfPath}`}
        target='_blank'
      >
        <Icon name='file pdf outline' />
        {display}
      </Header>
    )
  }
  function displayAmount (amount) {
    return (
      <List.Content floated='right'>
        <Statistic horizontal color='green'>
          <Statistic.Value><Icon size='small' name='rupee' />{rupeeFormatter(Math.round(amount))}</Statistic.Value>
        </Statistic>
      </List.Content>
    )
  }
  const displayHeading = (heading) => <Subtitle1 color='white' content={heading} />

  /**
     * Returns Contents of right segment
     *
     * @param {object} product
     * @param {string} paymentFrequency
     * @param {number} premium
     * @param {number} gstBasic
     * @returns reactObject
     * @memberof PaymentPage
     */
  function rightSegment (product, paymentFrequency, premium) {
    // to filter out riders which have premium = 0
    const filteredRiders = product.riders.filter((rider) => {
      let riderPremium
      rider.premiums.forEach((premium) => {
        if (premium.frequency === paymentFrequency) {
          riderPremium = premium.premium
        }
      })
      return riderPremium !== 0
    })

    // UI for diaplaying Riders
    const riders = filteredRiders.map((rider, i) => {
      let riderPremium
      rider.premiums.forEach((premium) => {
        if (premium.frequency === paymentFrequency) {
          riderPremium = premium.premium
        }
      })
      if (riderPremium !== 0) {
        return (
          <List.Item key={`rightSegmentListItem${i}`}>
            <span id={`${rider.riderId}-riderAmount`}>{displayAmount(riderPremium)}</span>
            <Body2 color='white'>{rider.shortName}</Body2>
          </List.Item>
        )
      }
      return null
    })
    const showRider = filteredRiders.length > 0
    const tncText = configure.paymentSummary.tnc
    return (
      <div className='rightSegment'>
        <H6 color='white'>
          <Image size='size24' src={buildCommonImagePath('document-icon.png')} />
          Premium Summary
        </H6>
        <Divider inverted />
        <List>
          <List.Item>
            {displayAmount(premium)}
            {displayHeading('Base Premium')}
          </List.Item>
          <Divider inverted />
          {showRider && (
            <>
              <List.Item>
                {displayHeading('Riders')}
                <List>
                  {riders}
                </List>
              </List.Item>
              <Divider inverted />
            </>
          )}
          <List.Item>
            <span id='rightSideTotalPremium'>{displayAmount(product.totalPremiumWithGst)}</span>
            {displayHeading(`Premium with GST (${gstPercent}%)`)}
          </List.Item>
        </List>
        <div className='flexContainer tncCheckBox'>
          <Checkbox
            material
            color='white'
            checked={tnc}
            onClick={checkTnc}
          />
          <Body2 className='tnc' color='white' content={tncText.format({ productName: product.productName.shortName })} />
        </div>
        <div className=''>
          <Button
            id='paymentButton'
            content='PROCEED TO PAY'
            primary
            inverted
            onClick={() => getPaymentRedirectionUrl(product.totalPremiumWithGst)}
            disabled={!tnc}
          />
          <Button
            color='white'
            id='backButton'
            content='Back'
            transparent
            onClick={redirectToPrePayment}
          />
        </div>
        <div id='paymentRedirectFormContainer' style={{ display: 'none' }} />
      </div>
    )
  }
  /**
     * Returns left segment contents (for web view only)
     *
     * @param {number} total
     * @param {string} frequency
     * @param {object} product
     * @param {string} pdfPath
     * @param {string} sisPath
     * @returns reactObject
     * @memberof PaymentPage
     */
  function leftSegment (frequency, product, pdfPath, sisPath, _detailedPolicySummaryOrder) {
    const ComponentMap = productComposer({
      product,
      insuranceType,
      termPolicySummaryDisplay: configure.booking.termPolicySummaryDisplay,
      formData: props.formData,
      productData: props.productData
    })
    return (
      <div>
        <ComponentMap.insurer />
        <Grid className='leftSegmentGrid'>
          <Grid.Row columns='equal' className='policySummary'>
            <Grid.Column>
              <ComponentMap.cover />
            </Grid.Column>
            <Grid.Column>
              <ComponentMap.coverPeriod />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns='equal' className='policySummary'>
            <Grid.Column>
              <Header sub>
                PRODUCT BROCHURE
              </Header>
              <Caption
                color='secondary'
                as='a'
                href={pdfPath}
                target='_blank'
                id={`${product.productOptionId}-product-brochure`}
              >
                <Icon name='download' />
                Download
              </Caption>
            </Grid.Column>
            <Grid.Column>
              <Header sub>
                SIS DOCUMENT
              </Header>
              <Caption
                color='secondary'
                as='a'
                href={sisPath}
                target='_blank'
                id={`${product.productOptionId}-sis-document`}
              >
                <Icon name='download' />
                Download
              </Caption>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    )
  }

  /**
     *  Returns Top segment contents (for mobile view)
     *
     * @param {object} product
     * @param {string} pdfPath
     * @param {string} sisPath
     * @returns reactObject
     * @memberof PaymentPage
     */
  function topSegment (product, pdfPath, sisPath) {
    return (
      <div>
        <List verticalAlign='middle'>
          <List.Item>
            <List.Content floated='right'>
              {listItemPdf(sisPath, 'SIS DOCUMENT')}
            </List.Content>
            <List.Content>
              {listItemPdf(pdfPath, 'PRODUCT BROCHURE')}
            </List.Content>
          </List.Item>
        </List>
      </div>
    )
  }
  if (capturePaymentLoading) {
    return <Loading open />
  }
  if (loadingConfigure) {
    return <Loading id='loading_payment_summary_config' open />
  }
  if (!isNotEmpty(props.chosenProduct)) {
    return <Loading id='loading_payment_summary_chosenProduct' open />
  } else {
    const {
      formData,
      productData,
      device
    } = props
    const product = getDisplayData({
      product: props.chosenProduct,
      formData,
      productData,
      paymentFrequencies: configure.common.paymentFrequencies,
      paymentOptions: configure.productListing.paymentOptionsPriority
    })
    const { booking: { detailedPolicySummaryOrder } } = configure
    const { frequencyDisplay, frequency, premium } = product.productPremium
    const open = isNull(fileName)
    const pdfPath = product.productBrochureUrl
    const modal = (
      <OnSubmitModal
        open={open}
        formData={formData}
        generateSiS={generateSiS}
        fileName={fileName}
        fileError={fileError}
      />
    )
    if (device === 'mobile') {
      return (
        <div className='paymentSummary'>
          {modal}
          <DividedSegmentVertical
            topSegment={topSegment(product, pdfPath, fileName)}
            bottomSegment={rightSegment(product, frequency, premium)}
          />
        </div>
      )
    }
    return (
      <div className='paymentSummary'>
        {modal}
        <div className='paymentPage' id='paymentPage'>
          <DividedSegment
            leftSegment={leftSegment(frequencyDisplay, product, pdfPath, fileName, detailedPolicySummaryOrder)}
            rightSegment={rightSegment(product, frequency, premium)}
          />
        </div>
      </div>
    )
  }
}

PaymentSummary.propTypes = {
  formData: PropTypes.object.isRequired,
  formSteps: PropTypes.array.isRequired,
  hideSummary: PropTypes.func.isRequired,
  device: PropTypes.string.isRequired
}

export default PaymentSummary
