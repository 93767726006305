import { FeathersError } from '@feathersjs/errors'
class FieldInvalid extends FeathersError {
  constructor (message, data) {
    super(message, data)
    this.errorType = 'FieldInvalid'
  }
}
class InvalidInsuranceType extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'insuranceType is invalid'
    super(errorMessage, 'InvalidInsuranceType', 406, 'not-acceptable', data)
  }
}
class InvalidDistributorId extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'distributorId is invalid'
    super(errorMessage, 'InvalidDistributorId', 406, 'not-acceptable', data)
  }
}
class InvalidEnquiryId extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'distributorId is invalid'
    super(errorMessage, 'InvalidEnquiryId', 406, 'not-acceptable', data)
  }
}
class InvalidEnquiry extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'enquiry is invalid'
    super(errorMessage, 'InvalidEnquiry', 406, 'not-acceptable', data)
  }
}

class InvalidFormData extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'formData is invalid'
    super(errorMessage, 'InvalidFormData', 406, 'not-acceptable', data)
  }
}
class InvalidProductOptionId extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'productOptionId is invalid'
    super(errorMessage, 'InvalidProductOptionId', 406, 'not-acceptable', data)
  }
}
class InvalidProductId extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'productId is invalid'
    super(errorMessage, 'InvalidProductIdNotFound', 406, 'not-acceptable', data)
  }
}
class InstitutionIdNotfound extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'institutionId is invalid'
    super(errorMessage, 'InstitutionIdNotfound', 406, 'not-acceptable', data)
  }
}
class UiConfigNotFound extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'uiConfig is invalid'
    super(errorMessage, 'UiConfigNotFound', 406, 'not-acceptable', data)
  }
}
class InvalidField extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'invalid data provided'
    super(errorMessage, 'InvalidField', 406, 'not-acceptable', data)
  }
}
class InvalidData extends FieldInvalid {
  constructor (message, data) {
    const errorMessage = message || 'data is invalid'
    super(errorMessage, 'InvalidData', 406, 'not-acceptable', data)
  }
}
export default {
  InvalidEnquiry,
  insuranceType: InvalidInsuranceType,
  distributorId: InvalidDistributorId,
  enquiryId: InvalidEnquiryId,
  enquiry: InvalidEnquiry,
  formData: InvalidFormData,
  productOptionId: InvalidProductOptionId,
  productId: InvalidProductId,
  institutionId: InstitutionIdNotfound,
  uiConfig: UiConfigNotFound,
  Default: InvalidField,
  Data: InvalidData
}
