
import { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Header, Grid, List } from 'semantic-ui-react'
import Forms from './booking/Forms'
import FlatSegment from 'components/FlatSegment'
import PolicySummary from './booking/PolicySummary'
import DataLoader from 'components/DataLoader'
import { productComposer } from './product/bookingIndex'
import { getDisplayData } from '../helper/productHelper'
import { isNotEmpty, isNotDefined } from 'utils'
import Loading from 'components/Loading'
import { DataAccessContext, dataAccessInitialState } from 'components/context'
import { UpdateContext } from 'components/context/UpdateContext'
import produce from 'immer'
import { sendDropOffLink } from 'smartcovr_api/src/insuranceApi'

import { useInitialDataLoader, useSetFormData, useSetChosenProduct, useLoadBookingConfig } from 'components/customHooks'
import 'components/css/booking.scss'
import GeneralInfoScreen from 'components/infoScreens/GeneralInfoScreen'

/* eslint react/jsx-pascal-case: 'off' */
const Booking = (props) => {
  const [multiSteps, setMultiSteps] = useState([])
  const [reviewSteps, setReviewSteps] = useState([])
  const [allSteps, setAllSteps] = useState([])
  const [payment, setPayment] = useState(false)
  const componentRef = useRef({ mismatchUpdated: false, dropOffFired: false })
  const {
    device,
    chosenProductMetadata,
    insuranceType,
    uploadUrl,
    fileUrl,
    enquiryId,
    chosenProduct,
    routeProps,
    distributorId,
    updateBookingLoading,
    dataMismatch,
    iamId,
    redirectToPath,
    countries
  } = props
  const { loadingConfigure, configure } = useInitialDataLoader(insuranceType, props.enquiryId, distributorId)
  const [{ loadingSetFormData }, setFormData] = useSetFormData(insuranceType, loadingConfigure)
  const [, setChosenProduct] = useSetChosenProduct(insuranceType, loadingConfigure, loadingSetFormData, true)
  const [, bookingConfigLoaded] = useLoadBookingConfig(insuranceType, distributorId, null, props.bookingType, false, true, device)
  const [continueBuying, setContinueBuying] = useState(false)
  useEffect(() => {
    setFormData({ formData: {} }, true)
    setChosenProduct({})
  },
  // eslint-disable-next-line
  [ ])
  const setInitialContext = () => {
    // Load all config driven display only after products is loaded
    // Unfortunately we dont load config via state. So issue with figuring out.
    return produce(dataAccessInitialState, dia => {
      dia.device = device
      dia.screen = props.screen
      dia.store = props.routeProps.store
      dia.uploadUrl = uploadUrl
      dia.fileUrl = fileUrl
      dia.enquiryId = enquiryId
      dia.insuranceType = insuranceType
      dia.distributorId = distributorId
      dia.iamId = iamId
      if (isNotEmpty(chosenProductMetadata)) {
        dia.institutionId = chosenProductMetadata.institutionId
      }
    })
  }
  const [dataAccess, setDataAccess] = useState(setInitialContext())
  useEffect(() => {
    setDataAccess(produce(dataAccess, draftDA => {
      draftDA.device = device
      draftDA.uploadUrl = uploadUrl
      draftDA.fileUrl = fileUrl
      draftDA.enquiryId = enquiryId
      draftDA.insuranceType = insuranceType
      if (isNotEmpty(chosenProductMetadata)) {
        draftDA.institutionId = chosenProductMetadata.institutionId
      }
    }))
  },
  // eslint-disable-next-line
  [ device, chosenProductMetadata, uploadUrl, fileUrl, enquiryId, insuranceType ])

  useEffect(() => {
    if (bookingConfigLoaded && isNotEmpty(chosenProduct)) {
      const { finanalyticsId } = chosenProduct
      const allStepsBookingSteps = props.getFormSteps(finanalyticsId)
      if (allStepsBookingSteps !== allSteps) {
        const reviewSteps = props.getReviewSteps(finanalyticsId)
        setMultiSteps(allStepsBookingSteps.filter((step) => step.stepType === 'multi'))
        setAllSteps(allStepsBookingSteps)
        setReviewSteps(reviewSteps)
      }
    }
  },
  // eslint-disable-next-line
  [ chosenProduct, bookingConfigLoaded, updateBookingLoading ])
  // redirecting to listing after clicking on continue buying products
  useEffect(() => {
    if (continueBuying) {
      const backPath = window.location.href
      props.resetChosenProduct()
      setContinueBuying(false)
      const index = backPath.indexOf('#')
      const newBackPath = backPath.substring(0, index + 1)
      if (!isNotDefined(props.enquiry.formData.businessOption) && props.enquiry.formData.businessOption === 'renewal') {
        window.location.assign(newBackPath + `/${insuranceType}/onboard`)
      } else {
        window.location.assign(newBackPath + `/${insuranceType}/select-product`)
      }
    }
  },
  // eslint-disable-next-line
  [continueBuying])

  // Redirect to listing after updating data
  useEffect(() => {
    if (componentRef.current.mismatchUpdated && !loadingSetFormData) {
      redirectToPath('select-product')
    }
  },
  // eslint-disable-next-line
  [loadingSetFormData])

  const hideSummary = () => {
    setPayment(true)
  }
  const showSummary = () => {
    setPayment(false)
  }
  useEffect(() => {
    const eventHandler = (ev) => {
      ev.preventDefault()
      if (isNotEmpty(chosenProduct) && isNotEmpty(allSteps) && !componentRef.current.dropOffFired) {
        componentRef.current.dropOffFired = true
        const { chosenProductMetadata: { institutionId }, bookingType } = props
        const applicationData = getApplicationData(institutionId)
        const stepType = allSteps[applicationData.bookingStepIndex].stepType
        const allowedStepType = ['payment', 'confirmation'].every(ele => ele !== stepType)
        if (allowedStepType) {
          sendDropOffLink({
            bookingType,
            applicationFormData: applicationData.applicationFormData,
            stepType,
            distributorId
          })
        }
      }
      return null
    }
    window.addEventListener('unload', eventHandler)
    return () => window.removeEventListener('unload', eventHandler)
  },
  // eslint-disable-next-line
  [ chosenProduct, allSteps, distributorId ])

  if (isNotEmpty(dataMismatch)) {
    const updateMismatchData = () => {
      componentRef.current.mismatchUpdated = true
      setFormData({
        formData: dataMismatch.data,
        all: true
      })
    }
    let errorDesc = ''
    const keys = Object.keys(dataMismatch.data)
    keys.forEach((ky, ix) => {
      const mapper = {
        dateOfBirth: 'Date of birth',
        age: 'Age',
        gender: 'Gender'
      }
      if (ix > 0 && ix < (keys.length - 1)) {
        errorDesc = errorDesc + ', '
      } else if (ix === (keys.length - 1)) {
        errorDesc = errorDesc + ' and '
      }
      errorDesc = errorDesc + mapper[ky]
    })
    errorDesc = errorDesc + ' do not match with customer record.'
    return (
      <>
        <Loading open={updateBookingLoading || loadingSetFormData} />
        <GeneralInfoScreen
          title='Data Mismatch'
          description={errorDesc}
          onClick={updateMismatchData}
          buttonText='Update and Browse products'
        />
      </>
    )
  }
  const renderDisplayWeb = (summaryProps, formProps) => {
    const hideSummary = (props.bookingType === 'postPaymentSteps') || payment || (['inspectionSummary', 'termsAndConditions', 'paymentSummary', 'payment'].findIndex(stepType => stepType === summaryProps.stepType) > -1)
    return (
      <Grid className='bookingForms' id='webViewBookingPage'>
        <Grid.Row>
          <Grid.Column width={!hideSummary ? 11 : 16} className='leftColumn'>
            <Forms {...formProps} />
          </Grid.Column>
          {!hideSummary && (
            <Grid.Column width={5} className='rightColumn'>
              <PolicySummary {...summaryProps} />
            </Grid.Column>
          )}
        </Grid.Row>
      </Grid>
    )
  }
  const renderDisplayMobile = (summaryProps, formProps) => {
    const { product } = summaryProps
    const { shortName, productOptionName } = product.productName
    const ComponentMap = productComposer({
      ...summaryProps
    })
    const hidePremium = (['inspectionSummary', 'termsAndConditions'].findIndex(stepType => stepType === summaryProps.stepType) > -1)
    const premiumComponent = !hidePremium && <ComponentMap.premium />
    return (
      <FlatSegment background='#fefefe'>
        <List id='mobileViewBookingPage'>
          <List.Item>
            <List.Content floated='right'>
              {/* eslint-disable-next-line */}
             { insuranceType === 'annuity' ? <ComponentMap.annuityPayout/> : premiumComponent } 
            </List.Content>
            <List.Content>
              <Header>
                {shortName}
                <Header.Subheader>
                  {productOptionName}
                </Header.Subheader>
              </Header>
            </List.Content>
          </List.Item>
        </List>
        <Forms {...formProps} />
      </FlatSegment>
    )
  }

  if (!isNotEmpty(chosenProduct) || !isNotEmpty(allSteps) || componentRef.current.mismatchUpdated) {
    return <Loading open />
  }

  const {
    loading,
    formData,
    productData,
    setApplicationFormData,
    enquiryLoading,
    bookingType,
    getApplicationData,
    enquiry,
    getFileUrl,
    chosenProductMetadata: { institutionId }
  } = props
  const applicationData = getApplicationData(institutionId)

  const product = getDisplayData({
    product: chosenProduct,
    formData,
    productData,
    paymentFrequencies: configure.common.paymentFrequencies,
    paymentOptions: configure.productListing.paymentOptionsPriority,
    payoutFrequencies: configure.common.payoutFrequencies
  })
  if (!isNotEmpty(applicationData)) {
    return <Loading open />
  }
  const formProps = {
    product,
    device,
    loading,
    hideSummary,
    showSummary,
    redirectToListing: () => redirectToPath('product-config'),
    redirectToPaymentSummary: () => redirectToPath('payment-summary'),
    redirectToProductListing: () => setContinueBuying(true),
    redirectToHoldings: () => redirectToPath('customer-holdings'),
    applicationData,
    setApplicationFormData,
    institutionId,
    configure,
    multiSteps,
    allSteps,
    routeProps,
    enquiryLoading,
    payment,
    bookingType,
    reviewSteps,
    enquiry,
    insuranceType,
    getFileUrl,
    updateBookingLoading,
    productData,
    distributorId,
    countries
  }
  const stepType = allSteps[applicationData.bookingStepIndex].stepType
  const summaryProps = {
    product,
    insuranceType,
    applicationData,
    Loader: DataLoader,
    termPolicySummaryDisplay: configure.booking.termPolicySummaryDisplay,
    gstDisclaimer: configure.common.paymentSummaryInfo.gstDisclaimer,
    formData: applicationData.applicationFormData,
    productData,
    stepType
  }
  const updateProps = {
    hideSummary,
    showSummary,
    redirectToListing: () => redirectToPath('select-product'),
    setApplicationFormData
  }
  return (
    <UpdateContext.Provider value={updateProps}>
      <DataAccessContext.Provider value={dataAccess}>
        <Loading open={updateBookingLoading} />
        {(device === 'web')
          ? renderDisplayWeb(summaryProps, formProps)
          : renderDisplayMobile(summaryProps, formProps)}
      </DataAccessContext.Provider>
    </UpdateContext.Provider>
  )
}

Booking.propTypes = {
  configure: PropTypes.object.isRequired
  // getFormsStep: PropTypes.func.isRequired,
  // loading: PropTypes.object.isRequired
}
// Booking.contextType = ErrorContext
export default Booking
