import { Component } from 'react'
import JsonSchemaForm from './jsonschema-form-semantic-ui'
import LabelField from './components/LabelField'
import FormHint from './components/FormHint'
import applyRules from 'components/RenderJsonSchemaForms'
import Engine from 'json-rules-engine'
import {
  isNotDefined,
  isNotEmpty,
  getCustomErrorMessage,
  expandRules,
  get
} from 'utils'
import widgets from './widgets'
import './jsonSchemaForm.scss'

// react-jsonschema-form-conditionals - new set of events.
// 1. uiToggle
//

class JsonSchemaEasyForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      liveValidate: props.liveValidate
    }
    this.fieldTemplate = this.fieldTemplate.bind(this)
    this.onValidate = this.onValidate.bind(this)
    this.transformErrors = this.transformErrors.bind(this)
    this.focusElement = null
  }

  onValidate (formData, errors) {
    if (!this.state.liveValidate) {
      this.setState({
        liveValidate: true
      })
    }
    if (this.props.onChangeData) { return this.props.onChangeData(formData, errors) }
    return errors
  }

  transformErrors (errors) {
    const { schema } = this.props
    if (this.props.transformErrors) {
      return this.props.transformErrors(errors)
    }
    if (isNotEmpty(errors)) {
      // eslint-disable-next-line array-callback-return
      errors.map(error => {
        const selectedSchema = get(schema, `properties${error.property}`)
        if (error.name === 'smartcovr-dateValidation') {
          const ageRangeMessage = `Age should be ${
            selectedSchema.minAge
              ? `greater than ${selectedSchema.minAge} years`
              : ''
          } ${selectedSchema.minAge && selectedSchema.maxAge ? 'and ' : ''}${
            selectedSchema.maxAge
              ? `lesser than ${selectedSchema.maxAge} years`
              : ''
          }.`
          if (selectedSchema.minAge && selectedSchema.minAge === 18) {
            error.message = ageRangeMessage
          } else {
            error.message = 'Invalid Date.'
          }
        } else if (error.name === 'maximum' && error.params.limit === 0 && this.props.customValidation) {
          const message = this.props.customValidation()
          if (!isNotDefined(message)) {
            error.message = message
          }
        } else {
          let title = 'This'
          if (selectedSchema) {
            title = selectedSchema.title
          }
          error.message = getCustomErrorMessage(title, error)
        }
      })
    }
    return errors
  }

  fieldTemplate (props) {
    const { id, classNames, label, required, errors, children } = props
    const { noLabel } = this.props
    const className = `${classNames} ${props.uiSchema.className} ${id}`
    if (props.hidden) {
      return null
    }
    let hideTitle = false
    if (className && className.indexOf('no-title') > -1) {
      hideTitle = true
    }
    let showerror = false
    if (!isNotDefined(props.rawErrors)) {
      props.rawErrors.length > 0 ? (showerror = true) : (showerror = false)
    }

    const labelField = !noLabel && label && (
      <LabelField
        className='control-label'
        htmlFor={id}
        title={label}
        required={required}
      />
    )
    if (className.indexOf('field') > -1) {
      return (
        <div className={className}>
          {!hideTitle && labelField}
          {children}
          <FormHint id={id} showerror={showerror} rawErrors={props.rawErrors} />
        </div>
      )
    } else {
      return (
        <div className={className}>
          {!hideTitle && labelField}
          {children}
          {errors}
        </div>
      )
    }
  }

  render () {
    const { liveValidate } = this.state
    const {
      schema,
      formData,
      uiSchema,
      rules,
      onSubmit,
      onChange,
      onFocus,
      onError,
      className,
      objectTemplate,
      ...formProps
    } = this.props
    const expandedRules = expandRules(rules)
    const FormWithConditionals = applyRules(
      schema,
      uiSchema,
      expandedRules,
      Engine
    )(JsonSchemaForm)
    return (
      <FormWithConditionals
        className={`jsonSchemaForm ${className ?? ''}`}
        FieldTemplate={this.fieldTemplate}
        ObjectFieldTemplate={objectTemplate}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        onChange={onChange}
        onError={onError}
        onFocus={onFocus}
        formData={formData}
        widgets={widgets}
        liveValidate={liveValidate}
        noHtml5Validate
        showErrorList={false}
        validate={this.onValidate}
        transformErrors={this.transformErrors}
        {...formProps}
      >
        {this.props.children}
      </FormWithConditionals>
    )
  }
}
export default JsonSchemaEasyForm
