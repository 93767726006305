import { useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { JsonSchemaOnboardForm, JsonSchemaHelper, JsonSchemaValidation } from 'components/jsonSchemaForms'
import { isNotDefined, isNotEmpty, calculateRecommendations } from 'utils'
import { DataAccessContext } from 'components/context'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'
import './termOnboarding.scss'

const TermOnboardingForm = (props) => {
  const {
    device,
    configure,
    onSubmit: parentOnSubmit,
    countries,
    ...restProps
  } = useContext(DataAccessContext)
  const {
    onboard: {
      onboardSchema: { schema, uiSchema }
    }
  } = configure
  const [formData, setFormData] = useState(restProps.formData)
  const [mobileDnd, setMobileDnd] = useState(true)
  const customFocus = useRef(null)

  useEffect(() => {
    if (customFocus.current) {
      customFocus.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [formData])

  const handleSubmit = ({ formData: userFormData }) => {
    const age = JsonSchemaValidation.calculateAge(userFormData.dateOfBirth)
    const recommendedFormData = calculateRecommendations({ ...userFormData, age })
    const { sumAssured, coverTerm } = recommendedFormData
    let mobile
    if (!isNotDefined(userFormData.mobile)) {
      mobile = userFormData.mobile
    }
    const newFormData = {
      ...userFormData,
      mobile,
      age,
      coverTerm,
      sumAssured: Math.round(sumAssured)
    }
    parentOnSubmit(newFormData)
  }
  const handleChange = (e) => {
    if (e.key === 'mobileDnd' && !isNotDefined(e.formData.mobileDnd)) {
      setFormData(e.formData)
      setMobileDnd(e.formData.mobileDnd)
    }
  }
  const onValidate = (formData, errors) => {
    const { validateDateOfBirth, validateMobileNumber, validatePincode } = JsonSchemaValidation
    const mobileNumberError = validateMobileNumber(formData.mobile, countries)
    if (mobileNumberError) {
      errors.mobile.addError(mobileNumberError)
    }

    const pincodeError = validatePincode(formData, 'pincode')
    if (pincodeError) {
      errors.pincode.addError(pincodeError)
    }

    const dateOfBirthError = validateDateOfBirth(formData, schema, 'dateOfBirth')
    if (dateOfBirthError) {
      errors.dateOfBirth.addError(dateOfBirthError)
    }
    return errors
  }
  const transformErrors = (errors) => {
    if (isNotEmpty(errors)) {
      const newErrors = errors.filter(error => error.name === 'required')
      newErrors.forEach((error) => {
        const currentProperty = error.params?.missingProperty
        let selectedSchema
        if (currentProperty) {
          selectedSchema = JsonSchemaHelper.extractNestedObject(schema, currentProperty, currentProperty)
        }
        let title = 'This'
        if (selectedSchema && selectedSchema[currentProperty]?.title && selectedSchema[currentProperty]?.title.trim().length !== 0) {
          title = selectedSchema[currentProperty].title
        }
        error.message = getCustomErrorMessage(title, error)
      })
      return newErrors
    }
    return errors
  }
  return (
    <div className='main4' style={{ backgroundColor: props.bgColor }}>
      <div className='form-container'>
        <div ref={customFocus} id='term-onboard' className='bg_onboard_form_card container d-flex shadow-lg'>
          <JsonSchemaOnboardForm
            className='onboardingForm'
            schema={schema}
            uiSchema={uiSchema}
            onSubmit={handleSubmit}
            onChange={handleChange}
            formData={formData}
            rules={[]}
            validate={onValidate}
            transformErrors={transformErrors}
            formContext={{
              device,
              countries
            }}
          >
            <Button id='getQuotesBtn' disabled={!mobileDnd} className='submit-btn' content='Get Quotes' />
          </JsonSchemaOnboardForm>
        </div>
      </div>
    </div>
  )
}

export default TermOnboardingForm
