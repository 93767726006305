import { useState } from 'react'
import SelectButtonWidget from '../widgets/SelectButtonWidget'
import LabelField from '../components/LabelField'
import '../widgets/SelectWidget.scss'
import FormHint from '../components/FormHint'

const getError = (key, errorSchema) => {
  return errorSchema[key]?.__errors
}

function defaultValues (data) {
  return {
    make: data?.make ?? -1,
    model: data?.model ?? -1,
    variant: data?.variant ?? -1,
    fuelType: data?.fuelType ?? -1,
    manufacturingYear: data?.manufacturingYear ?? -1
  }
}
const MakeModelField = (props) => {
  const [state, setState] = useState(initialize())
  const { formContext: { vehiclePurchaseDate, makes, fuelTypes, variants }, errorSchema } = props
  const makeModelForVariantExists = makes.filter(m => variants.filter(v => v.modelId === m.modelId && v.makeId === m.makeId).length > 0)
  function initialize () {
    try {
      const data = JSON.parse(props.formData)
      return defaultValues(data)
    } catch (e) {
      const data = props.formData
      return defaultValues(data)
    }
  }
  const buildMakeData = () => {
    const makeData = []
    makeModelForVariantExists.forEach(ele => {
      const exist = makeData.findIndex(data => data.id === ele.makeId)
      if (exist === -1) {
        makeData.push({ id: ele.makeId, label: ele.makeName, value: ele.makeId })
      }
    })
    return makeData
  }

  const buildModelData = (make) => {
    const modelData = []
    const allModels = makeModelForVariantExists.filter(ele => ele.makeId === make)
    allModels.forEach(ele => {
      const exist = modelData.findIndex(data => data.id === ele.modelId)
      if (exist === -1) {
        modelData.push({ id: ele.modelId, label: ele.modelName, value: ele.modelId })
      }
    })
    return modelData
  }

  const buildFuelTypeData = (model) => {
    const allVariants = variants.filter(ele => ele.modelId === model)
    const fuelTypeData = []
    const fuelTypeIds = []
    allVariants.forEach(ele => {
      const exist = fuelTypeData.findIndex(ele => ele.id === ele.fuelTypeId)
      if (!fuelTypeIds.includes(ele.fuelTypeId)) {
        fuelTypeIds.push(ele.fuelTypeId)
        if (exist === -1) {
          const fuelType = fuelTypes.find(fuelType => fuelType.fuelTypeId === ele.fuelTypeId)
          fuelTypeData.push({ id: ele.fuelTypeId, label: fuelType.display, value: ele.fuelTypeId })
        }
      }
    })
    return fuelTypeData
  }

  const buildVariantData = (fuelType, model) => {
    const allVariants = variants.filter(ele => ele.modelId === model && ele.fuelTypeId === fuelType)
    return allVariants.map(ele => {
      return { id: ele.variantId, label: ele.variantName, value: ele.variantId }
    })
  }

  const buildManufacturingYearsData = () => {
    const purchaseYear = vehiclePurchaseDate ? parseInt(new Date(vehiclePurchaseDate).getFullYear()) : parseInt(new Date().getFullYear())
    const years = 3
    const manufacturingYears = []
    for (let index = 0; index < years; index++) {
      manufacturingYears.push({ value: purchaseYear - index, label: `${purchaseYear - index}` })
    }
    return manufacturingYears
  }

  const handleOnItemSelect = (data) => {
    const { onChange } = props

    setState({ ...data })
    onChange({ ...data })
  }

  return (
    <div>
      <div className='mb-2'>
        <SelectButtonWidget
          id='make'
          label='make'
          placeholder='Select Make'
          options={{ enumOptions: buildMakeData() }}
          className='selectButtonWidget'
          value={state.make}
          schema={props.schema.properties.make}
          onChange={(value) => handleOnItemSelect({ ...state, make: value, model: -1, variant: -1, fuelType: -1, manufacturingYear: -1 })}
        />
        <FormHint
          id='make'
          showerror={errorSchema?.make?.__errors.length > 0}
          rawErrors={getError('make', errorSchema)}
        />
      </div>
      <div className='mb-2'>
        <LabelField
          className='control-label customLabel'
          htmlFor='model'
          title='Select Model'
        />
        <SelectButtonWidget
          id='model'
          label='model'
          disabled={state.make === -1}
          placeholder='Select Model'
          className='selectButtonWidget'
          options={{ enumOptions: buildModelData(state.make) }}
          value={state.model}
          schema={props.schema.properties.model}
          onChange={(value) => handleOnItemSelect({ ...state, model: value, fuelType: -1, variant: -1, manufacturingYear: -1 })}
        />
        <FormHint
          id='model'
          showerror={errorSchema?.model?.__errors.length > 0}
          rawErrors={getError('model', errorSchema)}
        />
      </div>
      <div className='mb-2'>
        <LabelField
          className='control-label customLabel'
          htmlFor='fuelType'
          title='Select Fuel Type'
        />
        <SelectButtonWidget
          id='fuelType'
          label='fuelType'
          disabled={state.model === -1}
          placeholder='Select a fuel type'
          className='selectButtonWidget'
          options={{ enumOptions: buildFuelTypeData(state.model) }}
          value={state.fuelType}
          schema={props.schema.properties.fuelType}
          onChange={(value) => handleOnItemSelect({ ...state, fuelType: value, variant: -1, manufacturingYear: -1 })}
        />
        <FormHint
          id='fuelType'
          showerror={errorSchema?.fuelType?.__errors.length > 0}
          rawErrors={getError('fuelType', errorSchema)}
        />
      </div>

      <div className='mb-2'>
        <LabelField
          className='control-label customLabel'
          htmlFor='variant'
          title='Select Variant'
        />
        <SelectButtonWidget
          id='variant'
          label='variant'
          disabled={state.fuelType === -1}
          placeholder='Select a variant'
          className='selectButtonWidget'
          options={{ enumOptions: buildVariantData(state.fuelType, state.model) }}
          value={state.variant}
          schema={props.schema.properties.variant}
          onChange={(value) => handleOnItemSelect({ ...state, variant: value, manufacturingYear: -1 })}
        />
        <FormHint
          id='variant'
          showerror={errorSchema?.variant?.__errors.length > 0}
          rawErrors={getError('variant', errorSchema)}
        />
      </div>

      <div className='mb-2'>
        <LabelField
          className='control-label customLabel'
          htmlFor='manufacturingYear'
          title='Select Manufacturing Year'
        />
        <SelectButtonWidget
          id='manufacturingYear'
          label='manufacturingYear'
          disabled={state.variant === -1}
          placeholder='Select a manufacturing year'
          className='selectButtonWidget'
          options={{ enumOptions: buildManufacturingYearsData() }}
          value={state.manufacturingYear}
          schema={props.schema.properties.manufacturingYear}
          onChange={(value) => {
            handleOnItemSelect({ ...state, manufacturingYear: value })
          }}
        />
        <FormHint
          id='manufacturingYear'
          showerror={errorSchema?.manufacturingYear?.__errors.length > 0}
          rawErrors={getError('manufacturingYear', errorSchema)}
        />
      </div>
    </div>
  )
}

export default MakeModelField
