
import { isNotDefined, isNotEmpty, has, expandRules } from 'utils'
import cloneDeep from 'lodash/cloneDeep'
import applyAllRules from 'json-schemaform-rules'
import Engine from 'json-rules-engine'
import { CustomErrors } from '../../../errors'
import produce from 'immer'
const stepDisplayMapper = {
  info: 'infoStepDisplay',
  form: 'singleStepDisplay',
  multi: 'multiStepDisplay'
}
const bookingTypeMapper = {
  prePaymentSteps: 'prePaymentStep',
  postPaymentSteps: 'postPaymentStep'
}

/**
   * This function is used to check whether selected insured is equal to defaultinsured or not
   * if it's equal then this function returns true and it returns false if it not true.
   *
   * @param {*} rootState
   * @param {*} insuranceType
   * @param {*} institutionId
   * @returns boolean value
   * @memberof Forms
   */
const isProposerSelfCheck = (rootState, insuranceType, institutionId) => {
  try {
    let isProposerSelf = true
    const bookingDefaults = rootState.insuranceConfigure[insuranceType].productConfig.bookingDefaults[institutionId]
    let defaultInsured
    if (isNotEmpty(bookingDefaults)) {
      defaultInsured = bookingDefaults.insured
    }
    const { chosenProduct: { chosenProductFormData } } = rootState.insuranceProducts[insuranceType]
    const selectedInsured = chosenProductFormData.insured
    if (!isNotDefined(selectedInsured) && defaultInsured !== selectedInsured) {
      isProposerSelf = false
    }
    return isProposerSelf
  } catch (err) {
    console.log(err)
    return true
  }
}

/**
   * This function builds the single step schema by using the fieldKeys. fieldKeys are mapped with formSchema
   * and the response is saved in the new singleStepSchema object
   *
   * @param {*} formSchema
   * @param {*} stepDisplay
   * @param {*} fieldKeys
   * @param {*} stepIndex
   * @param {*} device
   * @returns
   */
const buldJsonSchemaForStep = (formSchema, stepDisplay, fieldKeys, stepIndex, device, disabledKeys) => {
  if (isNotEmpty(fieldKeys)) {
    const singleStepSchema = {
      schemaName: fieldKeys.stepKey,
      stepName: stepDisplay.stepName,
      title: stepDisplay.stepTitle,
      icon: stepDisplay.icon,
      liveValidate: stepDisplay.liveValidate,
      schema: {
        type: 'object',
        title: stepDisplay.stepTitle,
        description: stepDisplay.stepDescription,
        properties: {},
        required: []
      },
      uiSchema: {
        'ui:order': []
      },
      rules: formSchema.rules, // Apply all Rules to all steps it will resolved in applyRules.js
      formContext: Object.assign(formSchema.formContext, { device }), // Need to fix browser type
      stepIndex
    }
    // FIXME: Need to display only 'Member Details' as title without below condition on review screen.
    if (singleStepSchema.schema.title === 'Member Details 1') {
      singleStepSchema.schema.title = stepDisplay.stepName
    }
    fieldKeys.forEach((propName, propIdx) => {
      if (isNotEmpty(formSchema.schema.properties[propName])) {
        let disabled = false
        if (Array.isArray(disabledKeys)) {
          disabled = disabledKeys.indexOf(propName) > -1
        } else if (formSchema.uiSchema[propName]['ui:disabled'] === true) {
          disabled = true
        }
        singleStepSchema.schema.properties[propName] = produce(formSchema.schema.properties[propName], (draft) => {
          draft.disabled = disabled
        })
      }
      if (isNotEmpty(formSchema.schema.required) && formSchema.schema.required.indexOf(propName) > -1) {
        singleStepSchema.schema.required.push(propName)
      }
      if (isNotEmpty(formSchema.uiSchema[propName])) {
        singleStepSchema.uiSchema[propName] = produce(formSchema.uiSchema[propName], () => {})
        singleStepSchema.uiSchema['ui:order'].push(propName)
      }
    })
    if (!isNotEmpty(singleStepSchema.schema.properties)) {
      return false
    }
    if (Object.keys(singleStepSchema.uiSchema).every((element) => {
      if (element === 'ui:order') {
        return true
      }
      return singleStepSchema.uiSchema[element]['ui:widget'] === 'hidden'
    })) {
      return false
    }
    return singleStepSchema
  }
}

/**
   * This function builds the form steps based on the institution id. These steps are multi steps, in which
   * it contains all the single steps. For each single step schema, fieldkeys and display values are built.
   * This logic to inclide these details are only performed for multi steps. Info steps are created with
   * descriptions along with all the default details in booking config
   *
   * @param {*} bookingConfig
   * @param {*} formSchema
   * @param {*} institutionId
   * @param {*} device
   * @param {*} formData
   * @param {*} bookingType
   * @param {*} isProposerSelf
   * @returns
   */
const buildFormSteps = (bookingConfig, formSchema, institutionId, device, formData, bookingType, isProposerSelf) => {
  const { bookingSteps, fieldsForInsurers, bookingDisplay } = bookingConfig
  const { insuranceType } = formData
  let filteredBookingSteps
  if (insuranceType === 'annuity') {
    const { applicationType } = formData
    const annuityStep = `${applicationType}BookingSteps`
    filteredBookingSteps = bookingSteps[annuityStep].filter(step => {
      return step[bookingTypeMapper[bookingType]] && (!isProposerSelf || !step.proposer)
    })
  } else {
    filteredBookingSteps = bookingSteps.filter(step => {
      return step[bookingTypeMapper[bookingType]] && (!isProposerSelf || !step.proposer)
    })
  }
  if (isNotDefined(formSchema) || !isNotEmpty(formSchema)) {
    return []
  }
  const updatedBookingSteps = filteredBookingSteps.map((step, stepIndex) => {
    const displayObject = bookingDisplay[stepDisplayMapper[step.stepType]]
    let stepDisplay = {}
    if (isNotEmpty(displayObject)) {
      if (step.stepType === 'otp') {
        stepDisplay = displayObject
      } else {
        stepDisplay = displayObject[step.displayKey || step.stepKey] // FIXME: use a common key
      }
    }
    const updatedStep = produce(step, draft => {
      Object.assign(draft, stepDisplay, { stepIndex })
      if (step.stepType === 'multi') {
        const singleSteps = []
        draft.steps[device].forEach((singleStep, stepIdx) => {
          if (singleStep.stepType === 'form') {
            // check if undefined
            const singleStepDisplay = bookingDisplay[stepDisplayMapper[singleStep.stepType]][singleStep.stepKey] || {}
            const stepFieldKeys = fieldsForInsurers[institutionId][singleStep.stepKey]
            const singleStepSchema = buldJsonSchemaForStep(formSchema, singleStepDisplay, stepFieldKeys, stepIdx, device)
            if (singleStepSchema) {
              singleSteps.push(singleStepSchema)
            }
          } else {
            singleSteps.push(singleStep)
          }
        })
        draft.steps = singleSteps
      }
    })
    return updatedStep
  })
  return updatedBookingSteps
}

/**
   * This function builds the json schema for review forms for all the steps which doesn't have proposer or
   * isProposerSelf as true
   *
   * @param {*} bookingConfig
   * @param {*} formSchema
   * @param {*} finanalyticsId
   * @param {*} device
   * @param {*} isProposerSelf
   * @returns
   */
const buildReviewSteps = (bookingConfig, formSchema, finanalyticsId, device, isProposerSelf, formData) => {
  const { reviewSteps, bookingDisplay } = bookingConfig
  const { insuranceType, applicationType } = formData
  if (isNotDefined(formSchema) || !isNotEmpty(formSchema)) {
    return []
  }
  const reviewStepsArray = []
  let stepsArray = []

  if (insuranceType === 'annuity') {
    const { applicationType } = formData
    const applicationTypeSteps = `${applicationType}Steps`
    stepsArray = reviewSteps[applicationTypeSteps]
  } else {
    const { steps } = reviewSteps
    stepsArray = steps
  }

  stepsArray.forEach((step, stepIndex) => {
    if (!isProposerSelf || !step.proposer) {
      const fieldKeys = step.fieldKeys[finanalyticsId]
      const disabledKeys = step.disabledKeys && step.disabledKeys[finanalyticsId]
      const stepDisplay = bookingDisplay[stepDisplayMapper.form][step.displayKey] || {}
      let stepSchema = buldJsonSchemaForStep(formSchema, stepDisplay, fieldKeys, stepIndex, device, disabledKeys)
      if (insuranceType === 'annuity' && applicationType === 'jl') {
        const stepSch = produce((stepSchema), draft => {
          draft.joint = step.joint
        })
        stepSchema = stepSch
      }
      if (stepSchema) {
        reviewStepsArray.push(stepSchema)
      }
    }
  })
  if (insuranceType === 'annuity' && applicationType === 'jl') {
    const secondaryStepSchema = reviewStepsArray.filter((stepSchema) => stepSchema.joint)
    const primaryStepSchema = reviewStepsArray.filter((stepSchema) => !stepSchema.joint)
    const reviewObject = {
      primary: primaryStepSchema,
      secondary: secondaryStepSchema
    }
    return reviewObject
  } else {
    return reviewStepsArray
  }
}

/**
   * This function builds the schema based on the rules using runRules function. runRules function is
   * basically created by passing uiSchema, schema, rules and Engine object to applyAllRules, which is
   * function of json-schema-rules library
   *
   * @param {*} bookingFormSchema
   * @param {*} applicationFormData
   * @returns
   */
const runRulesAndUpdateSchema = async (bookingFormSchema, applicationFormData) => {
  const { schema, uiSchema, rules } = cloneDeep(bookingFormSchema)
  const expandedRules = expandRules(rules)
  const runRules = applyAllRules(schema,
    uiSchema,
    expandedRules,
    Engine,
    {})
  try {
    const result = await runRules(applicationFormData)
    return {
      ...bookingFormSchema,
      schema: result.schema,
      uiSchema: result.uiSchema,
      formData: result.formData
    }
  } catch (err) {
    throw new CustomErrors.RuleEngineError('smartCovrApi:bookingHelper:runRulesAndUpdateSchema' + err.message, {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}

// call to hidePrePopulatedFields() fields commented as it shows blank steps when auto populated
// This function is not used anuywhere
// eslint-disable-next-line no-unused-vars
const hidePrePopulatedFields = (data, formStep) => {
  if (!isNotEmpty(data)) {
    return formStep
  }
  Object.keys(data).forEach((dataKey, dataKeyIdx) => {
    if (has(formStep.uiSchema, dataKey) && isNotEmpty(formStep.uiSchema[dataKey])) {
      formStep.uiSchema[dataKey]['ui:widget'] = 'hidden'
    }
  })
  // following code is in case we want to hide user the step where all feilds are filled
  // if (Object.keys(formStep.uiSchema).every(key => key === 'ui:order' || formStep.uiSchema[key]['ui:widget'] === 'hidden')) {
  //   return false
  // }
  return formStep
}

/**
   * This function creates applicationFormData, currentStep and bookingStepIndex in enquiry
   *
   * @param {*} draftEnquiry
   * @param {*} { institutionId, resetSteps, applicationForm }
   * @returns
   */
const transformFormData = (draftEnquiry, {
  institutionId,
  resetSteps,
  applicationForm
}) => {
  if (!has(draftEnquiry, 'application')) {
    draftEnquiry.application = {}
  }
  if (!has(draftEnquiry.application, institutionId)) {
    draftEnquiry.application[institutionId] = {}
  }
  draftEnquiry.application[institutionId].applicationFormData = applicationForm
  // If reset is true, set the current step to zero
  // FIXME: steps are reset ! NEEd to handle this
  draftEnquiry.application[institutionId].currentStep = 0
  draftEnquiry.application[institutionId].bookingStepIndex = 0
  // if (resetSteps || !has(draftEnquiry.application[institutionId], 'currentStep')) {
  //   draftEnquiry.application[institutionId].currentStep = 0
  // }
  // if (resetSteps || !has(draftEnquiry.application[institutionId], 'bookingStepIndex')) {
  //   draftEnquiry.application[institutionId].bookingStepIndex = 0
  // }
  return draftEnquiry
}
export {
  buildFormSteps,
  transformFormData,
  buildReviewSteps,
  isProposerSelfCheck,
  runRulesAndUpdateSchema
}
