import { useState, useEffect } from 'react'
import { Search, Input, Grid } from 'semantic-ui-react'
import { isNotEmpty } from 'utils'

import LabelField from '../components/LabelField'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { getCityAndState } from 'smartcovr_api/src/insuranceApi'
import FieldRowInline from '../components/FieldRowInline'
const PincodeWidget = (props) => {
  const [selectedPincode, setSelectedPincode] = useState(undefined)
  const [selectedCity, setSelectedCity] = useState(undefined)
  const [selectedState, setSelectedState] = useState(undefined)

  const [results, setResults] = useState([])
  const [showNoResults, setShowNoResults] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await getCityAndState(selectedPincode)
        if (isNotEmpty(data)) {
          setResults(
            data.map((item, ix) => {
              return {
                title: selectedPincode,
                description: JSON.stringify({ city: item.city, state: item.state }),
                data: item,
                id: item.pincode,
                key: `${item.pincode}_${ix}`
              }
            })
          )
        } else {
          setError(true)
          setResults([])
          setShowNoResults(true)
        }
      } catch (err) {
        setResults([])
        setError(true)
        setShowNoResults(true)
      }
    }

    if (
      !error &&
      results.length === 0 &&
      typeof selectedPincode === 'string' &&
      selectedPincode.length === 6
    ) {
      fetchResults()
    }
  },
  // eslint-disable-next-line
  [results])

  function handleSearchChange (_e, { value }) {
    if (value.length <= 6) {
      setSelectedPincode(value.toString())
      setResults([])
      setShowNoResults(false)
      setError(false)
    }
  }

  function handleResultSelect (_e, { result: { data } }) {
    setSelectedPincode(data.pincode)
    setSelectedCity(data.city)
    setSelectedState(data.state)
    const formData = {
      city: data.city,
      state: data.state,
      pincode: data.pincode.toString()
    }
    props.onChange(JSON.stringify(formData))
  }

  function displayFields () {
    return (
      results.length > 0 && (
        <Grid>
          <Grid.Row className='flex-nowrap'>
            <FieldRowInline>
              <LabelField
                className='control-label mt-2 pl-3 secondary'
                htmlFor='city'
                title='City'
              />
              <Input value={selectedCity ?? ''} placeholder='City' readOnly />
            </FieldRowInline>
            <FieldRowInline>
              <LabelField
                className='control-label mt-2 pl-3 secondary'
                htmlFor='state'
                title='State'
              />
              <Input value={selectedState ?? ''} placeholder='State' readOnly />
            </FieldRowInline>
          </Grid.Row>
        </Grid>
      )
    )
  }
  const renderer = ({ title, description }) => {
    let descriptionEl = ''
    if (description) {
      const { city, state } = JSON.parse(description)
      descriptionEl = (
        <div className='description'>
          City: {city} <br />
          State: {state}
        </div>
      )
    }
    return (
      <div className='content'>
        {title && <div className='title'>Pincode: {title}</div>}
        {descriptionEl}
      </div>
    )
  }
  return (
    <>
      <Search
        as={Input}
        placeholder='Pincode'
        resultRenderer={renderer}
        showNoResults={showNoResults}
        onResultSelect={handleResultSelect}
        onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
        results={results}
        value={selectedPincode}
        noResultsMessage={error ? 'Oops! Enter pincode again.' : 'Invalid pincode'}
      />
      {displayFields()}
    </>
  )
}

PincodeWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  schema: PropTypes.object.isRequired
}

export default PincodeWidget

// <CustomFieldWrapper
//   schema={schema}
//   uiSchema={uiSchema}
// >
// </CustomFieldWrapper>
