import { connect } from 'react-redux'
import { Landing } from './components'

const mapStateToProps = (state, ownProps) => {
  const availableProducts = state.configure.availableProducts
  const insuranceType = state.insuranceEnquiry?.currentInsuranceType ?? availableProducts[0]
  const selection = ownProps.store.select(models => ({
    formData: models.insuranceEnquiry.getFormData,
    countries: models.configure.getCountriesMaster
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...selectorData,
    routeProps: ownProps,
    insuranceType,
    availableProducts
  }
}

const mapDispatchToProps = (dispatch, _ownProps) => {
  return {
    getEnquiry: (insuranceType, enquiryId) => {
      return dispatch.insuranceEnquiry.loadEnquiryFromDb(insuranceType, enquiryId)
    },
    setEnquiry: (formData, insuranceType, filterData) => {
      return dispatch.insuranceEnquiry.setEnquiry({
        formData,
        insuranceType,
        filterData
      })
    }
  }
}

const LandingContainer = connect(mapStateToProps, mapDispatchToProps)(Landing)
LandingContainer.path = '/'

export default LandingContainer
