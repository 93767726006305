import { buildDistributorImagePath } from 'components/buildImagePath'

const LandingHeader = () => {
  return (
    <header>
      <div
        className='intro-section'
        style={{
          backgroundImage: `url(${buildDistributorImagePath('banner.jpg')}`,
          backgroundSize: 'cover',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <div className='jumbotron'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-6 col-sm-12 col-xs-12'>
                <h1 className='text-center text-white'>LIFE COVER NOW WITH A PERSONAL TOUCH</h1>
                <p className='text-center banner-desc text-white'>
                  Get affordable Term Plans that covers critical, terminal illness, accidental death cover
                </p>
                <p className='text-center' style={{ visibility: 'hidden' }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default LandingHeader
