
import { isNotEmpty } from 'utils'
import { ServerApiError } from '../errors'
import { getMakeModelService, getVariantService, getCommonFuelTypeService, getRtoService, getInsurerNames } from './autoService'
import { getService } from './index'

// QUOTES
const getQuotesByInstitutionId = async (enquiry, institutionId, insuranceType, typeOfQuote) => {
  const query = {
    id: 'all',
    insuranceType,
    typeOfQuote,
    institutionId
  }
  let response
  try {
    const { quotesService } = getService(insuranceType)
    response = await quotesService.create(enquiry, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductsError('smartCovrApi:insuranceService:getQuotesByInstitutionId', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getQuoteByProductId = async (productId, enquiry, insuranceType, typeOfQuote, institutionId) => {
  const query = {
    id: productId,
    insuranceType,
    typeOfQuote,
    institutionId
  }
  let response
  try {
    const { quotesService } = getService(insuranceType)
    response = await quotesService.create(enquiry, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductError('smartCovrApi:insuranceService:getQuotesByInstitutionId', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 * Get the productData and formData for the renew policy for auto insurance
 * @param {string} policyOption
 * @param {object} enquiry
 * @param {string} insuranceType
 * @param {string} typeOfQuote
 * @param {string} institutionId
 * @returns {object}
 **/
const getPolicyDetails = async (policyOption, enquiry, insuranceType, typeOfQuote, institutionId) => {
  const query = {
    id: policyOption,
    insuranceType,
    typeOfQuote,
    institutionId
  }
  let response
  try {
    const { quotesService } = getService(insuranceType)
    response = await quotesService.create(enquiry, { query }) // we are using the same getQuote request, just the typeOfQuote is 'details'
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductError('smartCovrApi:insuranceService:getPolicyDetails', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
// PRODUCT
const getAllProductDataByInsuranceType = async ({ insuranceType, distributorId }) => {
  let response
  try {
    const { productService } = getService(insuranceType)
    response = await productService.get('all', {
      query: { insuranceType, distributorId }
    })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductDataError('smartCovrApi:insuranceService:getAllProductDataByInsuranceType', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getProductDataById = async ({ productInsurerId, insuranceType, distributorId }) => {
  let response
  try {
    const { productService } = getService(insuranceType)
    response = await productService.get(productInsurerId, {
      query: { insuranceType, distributorId }
    })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductDataError('smartCovrApi:insuranceService:getProductDataById', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getFeaturesForInsuranceType = async ({ insuranceType, distributorId }) => {
  let response
  try {
    const { featuresService } = getService(insuranceType)
    response = await featuresService.get(insuranceType, { query: { distributorId } })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductDataError('smartCovrApi:insuranceService:getFeaturesForInsuranceType', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getUiConfiguration = async ({ insuranceType, distributorId }) => {
  const query = {
    distributorId,
    insuranceType
  }
  let response
  try {
    const { insuranceConfigureService, app } = getService(insuranceType)
    response = await insuranceConfigureService.get('uiConfig', { query })
    if (['car', 'bike'].includes(insuranceType)) {
      response.onboard.onboardMetaData = {
        makeModel: await getMakeModelService(app, 'all', insuranceType),
        fuelType: await getCommonFuelTypeService(app, 'all'),
        rto: await getRtoService(app, 'all'),
        variant: await getVariantService(app, 'all', insuranceType),
        insurerNames: await getInsurerNames(app)
      }
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetUiConfigurationError('smartCovrApi:insuranceService:getUiConfiguration', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 *
 * No Longer Used.
 *
 * @returns Array of Products supported by a distributor
 * ex: [term, car, bike, health]
 */
const getDistributorProducts = async ({ insuranceType, distributorId }) => {
  const query = {
    distributorId
  }
  let response
  try {
    const { insuranceConfigureService } = getService(insuranceType)
    response = await insuranceConfigureService.get('productList', { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetDistributorConfigurationError('smartCovrApi:insuranceService:getDistributorProducts', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getProductConfiguration = async ({ insuranceType, distributorId }) => {
  const query = {
    distributorId,
    insuranceType
  }
  let response
  try {
    const { insuranceConfigureService } = getService(insuranceType)
    response = await insuranceConfigureService.get('productConfig', { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductConfigurationError('smartCovrApi:insuranceService:getProductConfiguration', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getSmartCovrConfiguration = async ({ insuranceType }) => {
  const query = {
    insuranceType
  }
  let response
  try {
    const { insuranceConfigureService } = getService(insuranceType)
    response = await insuranceConfigureService.get('smartcovr', { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductConfigurationError('smartCovrApi:insuranceService:getProductConfiguration', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getBookingConfiguration = async ({ insuranceType, distributorId, institutionId, insurerId, enquiry }) => {
  const query = {
    insuranceType,
    distributorId,
    institutionId,
    insurerId
  }
  let response
  try {
    const { bookingPrepayService } = getService(insuranceType)
    response = await bookingPrepayService.create(enquiry, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetBookingConfigurationError('smartCovrApi:insuranceService:getBookingConfiguration', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  if (!isNotEmpty(response)) {
    throw new ServerApiError.GetBookingConfigurationError('smartCovrApi:insuranceService:getBookingConfiguration', {
      message: 'Empty response'
    })
  }
  return response
}

const fetchBookingConfiguration = async ({ insuranceType, distributorId, institutionId, insurerId, applicationNumber }) => {
  const query = {
    insuranceType,
    distributorId,
    institutionId,
    insurerId,
    applicationNumber
  }
  let response
  try {
    const { bookingService } = getService(insuranceType)
    response = await bookingService.get('applciationDataAndSchema', { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetBookingConfigurationError('smartCovrApi:insuranceService:fetchBookingConfiguration', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  if (!isNotEmpty(response)) {
    throw new ServerApiError.GetBookingConfigurationError('smartCovrApi:insuranceService:fetchBookingConfiguration', {
      message: 'Empty response'
    })
  }
  return response
}

const processRequestBooking = async ({ insuranceType, institutionId, enquiry, requestType, enquiryId, distributorId }) => {
  const newEnquiry = {
    formData: Object.assign({ distributorId }, enquiry.formData)
  }
  const mergedEnquiry = Object.assign({}, enquiry, newEnquiry, { enquiryId })
  const query = {
    requestType,
    institutionId,
    insuranceType
  }
  let response
  try {
    const { bookingService } = getService(insuranceType)
    response = await bookingService.create(mergedEnquiry, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.ProcessRequestBookingError('smartCovrApi:insuranceService:processRequestBooking', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const processPayment = async ({ insuranceType, institutionId, enquiry, enquiryId, distributorId }) => {
  const newEnquiry = {
    formData: Object.assign({ distributorId }, enquiry.formData)
  }
  const mergedEnquiry = Object.assign({}, enquiry, newEnquiry, { enquiryId })
  const query = {
    institutionId,
    insuranceType
  }
  let response
  try {
    const { paymentService } = getService(insuranceType)
    response = await paymentService.create(mergedEnquiry, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.ProcessRequestPaymentError('smartCovrApi:insuranceService:processPayment', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const getProposerRelations = async ({ institutionId, insuranceType, productInsurerId }) => {
  const query = {
    institutionId,
    insuranceType,
    metadataType: 'proposerRelation',
    insurerId: productInsurerId
  }
  let response
  try {
    const { bookingService } = getService(insuranceType)
    response = await bookingService.get('metadata', { query })
    // response = [{ value: 'SAME', display: 'Self' }]
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProposerRelationsError('smartCovrApi:insuranceService:getProposerRelations', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const updateApplicationDataToBooking = async ({ insuranceType, institutionId, insurerId, enquiry, enquiryId, action }) => {
  const query = {
    insuranceType,
    institutionId,
    insurerId,
    action
  }
  let response
  try {
    const { bookingService } = getService(insuranceType)
    response = await bookingService.update(enquiryId, enquiry, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.UpdateBookingError('smartCovrApi:insuranceService:updateApplicationDataToBooking', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

const getCustomerHoldings = async ({ iamId, distributorId, insuranceType }) => {
  const query = {
    distributorId
  }
  try {
    const { policyService } = getService(insuranceType)
    return await policyService.get(iamId, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetCustomerError('smartCovrApi:commonService:getCustomer', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}

const getAllPolicies = async ({ insuranceType, distributorId, iamId, skip, limit }) => {
  let response
  try {
    // To be used to get atleast one policy data
    // const dummyIamId = '46e290b7-db36-432c-8b65-df35b0f7f0c9'
    const { policyService } = getService(insuranceType)
    response = await policyService.get(iamId, {
      query: { insuranceType, distributorId, skip, limit }
    })
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

export {
  getQuotesByInstitutionId,
  getQuoteByProductId,
  getPolicyDetails,
  getProductConfiguration,
  getUiConfiguration,
  getDistributorProducts,
  getProductDataById,
  getBookingConfiguration,
  fetchBookingConfiguration,
  processRequestBooking,
  getProposerRelations,
  processPayment,
  getAllProductDataByInsuranceType,
  getFeaturesForInsuranceType,
  updateApplicationDataToBooking,
  getCustomerHoldings,
  getAllPolicies,
  getSmartCovrConfiguration
}
