import PropTypes from 'prop-types'
import DescriptionField from '../fields/DescriptionField.js'
import { Checkbox } from 'semantic-ui-react'

function MobileDndWidget (props) {
  const {
    schema,
    id,
    value,
    required,
    disabled,
    readonly,
    label,
    autofocus,
    onChange
  } = props
  const currentDistributor = 'HDFC Securities'
  const currentIntermediary = 'Smartcovr'
  const labelEl = (
    label && (
      <label className='grey'>{label.format(currentDistributor, currentIntermediary)}</label>
    )
  )

  return (
    <div className={`checkbox ${disabled || readonly ? 'disabled' : ''}`}>
      {schema.description && (
        <DescriptionField description={schema.description} />
      )}
      <Checkbox
        id={id}
        checked={value ?? false}
        required={required}
        disabled={disabled || readonly}
        autoFocus={autofocus}
        onChange={(_event, data) => onChange(data.checked)}
        label={labelEl}
      />
    </div>
  )
}

MobileDndWidget.defaultProps = {
  autofocus: false
}

if (process.env.NODE_ENV !== 'production') {
  MobileDndWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    value: PropTypes.bool,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    autofocus: PropTypes.bool,
    onChange: PropTypes.func
  }
}

export default MobileDndWidget
