
import { buildFormSteps, buildReviewSteps, isProposerSelfCheck } from './helpers/bookingHelper'
import { isNotDefined, isNotEmpty } from 'utils'
import { mergeFilters } from './helpers/filterHelper'
// import { populateMaxMinDates } from './helpers/enquiryHelper'
import { effectsHandler } from './modelHelpers'
import produce from 'immer'
import { setUiConfig, loadBookingConfiguration, loadProposerRelations } from './insuranceConfigureEffects'

// configure object and its state
const insuranceConfigure = {
  name: 'insuranceConfigure',
  state: {
    term: {},
    car: {},
    bike: {},
    health: {},
    annuity: {}
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Gets filter config form the state
     *
     * @returns
     */
    getFilterConfig () {
      return createSelector(
        slice,
        (state, { insuranceType }) => ({
          insuranceType,
          products: state.insuranceProducts[insuranceType].plainProducts
        }),

        (insuranceConfigure, { insuranceType, products }) => {
          const filterConfig = insuranceConfigure[insuranceType].uiConfig.productListing.filterConfig
          const { filterPriority } = insuranceConfigure[insuranceType].productConfig.filterConfig
          if (products.length > 0) {
            const displayFilterConfig = mergeFilters(products, filterConfig, filterPriority)
            return displayFilterConfig
          } else {
            filterConfig.filterPriority = filterPriority
            return filterConfig
          }
        }
      )
    },

    /**
     * Get schema from productConfig
     *
     * @returns
     */
    getOnboardSchema: () => {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceConfigure, insuranceType) => {
          if (isNotDefined(insuranceConfigure[insuranceType]) || isNotDefined(insuranceConfigure[insuranceType].productConfig)) {
            return {}
          }
          return produce(insuranceConfigure[insuranceType].productConfig.validation.schema, (draftSchema) => {
            const { age } = insuranceConfigure[insuranceType].productConfig.validation
            return draftSchema
            // return populateMaxMinDates(draftSchema, age.min, age.max)
          })
        }
      )
    },

    /**
     * Gets paymentFrequencyData
     *
     * @returns
     */
    getPaymentFrequency: () => {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceConfigure, insuranceType) => insuranceConfigure[insuranceType].uiConfig.common.paymentFrequencies
      )
    },

    /**
     * Gets insurers from productConfig
     *
     * @returns
     */
    // FIXME: Supported institutions will come from Keycloak
    getSupportedInstitutions () {
      createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceConfigure, insuranceType) => {
          return insuranceConfigure[insuranceType].productConfig.insurers
        })
    },

    /**
     * Gets institute order form product config
     *
     * @returns
     */
    getInstituteOrder () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceConfigure, insuranceType) => {
          return insuranceConfigure[insuranceType].productConfig.instituteOrder
        })
    },

    /**
     * gets smartConfig from the state
     *
     * @returns
     */
    getSmartConfig () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceConfigure, insuranceType) => {
          return insuranceConfigure[insuranceType].smartCovrConfig
        }
      )
    },
    /**
     * gets uiConfig from the state
     *
     * @returns
     */
    getUiConfig () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceConfigure, insuranceType) => {
          if (isNotDefined(insuranceConfigure[insuranceType]) || isNotDefined(insuranceConfigure[insuranceType].uiConfig) || isNotDefined(insuranceConfigure[insuranceType].productConfig)) {
            return {}
          }
          insuranceConfigure[insuranceType].uiConfig.onboard.onboardSchema.schema = insuranceConfigure[insuranceType].productConfig.validation.schema
          return insuranceConfigure[insuranceType].uiConfig
        }
      )
    },

    /**
     * Gets bookign review steps
     *
     * @returns
     */
    getReviewSteps () {
      // FIXME: to be changed. Review schema needs to change
      return createSelector(
        slice,
        (rootState, { insuranceType }) => {
          return rootState.insuranceEnquiry[insuranceType]
        },
        (rootState, { insuranceType, institutionId }) => {
          return isProposerSelfCheck(rootState, insuranceType, institutionId)
        },
        (state, { device, insuranceType, bookingType, institutionId }) => {
          return {
            device,
            insuranceType,
            bookingType,
            institutionId
          }
        },
        (insuranceConfigure, enquiry, isProposerSelf, { device, insuranceType, bookingType, institutionId }) => {
          let bookingUiConfig = insuranceConfigure[insuranceType].uiConfig.booking
          const { booking } = insuranceConfigure[insuranceType]
          if (isNotDefined(booking) || isNotDefined(booking[institutionId])) {
            return []
          }
          if (isNotDefined(booking[institutionId].bookingConfig) || !isNotEmpty(booking[institutionId].bookingConfig)) {
            return []
          }
          const { bookingConfig: { formSchema, uiConfig } } = booking[institutionId]
          if (!isNotDefined(uiConfig)) {
            bookingUiConfig = uiConfig.booking
          }
          const { formData } = enquiry
          return buildReviewSteps(bookingUiConfig, formSchema, institutionId, device, isProposerSelf, formData)
        })
    },

    /**
     * Gets all the booking steps for an institutionId
     *
     * @returns
     */
    getBookingFormSteps () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => {
          return rootState.insuranceEnquiry[insuranceType]
        },
        (rootState, { insuranceType, institutionId }) => {
          return isProposerSelfCheck(rootState, insuranceType, institutionId)
        },
        (state, { device, insuranceType, bookingType, institutionId }) => {
          return {
            device,
            insuranceType,
            bookingType,
            institutionId
          }
        },
        (insuranceConfigure, enquiry, isProposerSelf, { device, insuranceType, bookingType, institutionId }) => {
          const formData = enquiry.application[institutionId].applicationFormData
          let bookingUiConfig = insuranceConfigure[insuranceType].uiConfig.booking
          const { booking } = insuranceConfigure[insuranceType]
          if (isNotDefined(booking)) {
            return []
          }
          if (isNotDefined(booking[institutionId])) {
            return []
          }
          if (!isNotEmpty(booking[institutionId].bookingConfig)) {
            return []
          }
          const { bookingConfig: { formSchema, uiConfig } } = booking[institutionId]
          if (!isNotDefined(uiConfig)) {
            bookingUiConfig = uiConfig.booking
          }
          const allSteps = buildFormSteps(bookingUiConfig, formSchema, institutionId, device, formData, bookingType, isProposerSelf)
          return allSteps
        })
    },

    /**
     * Gets proposalRelations for insitutionId
     *
     * @returns
     */
    getProposerRelations () {
      return createSelector(
        slice,
        (state, { insuranceType }) => ({
          insuranceType,
          insuranceEnquiry: state.insuranceEnquiry[insuranceType]
        }),
        (insuranceConfigure, { insuranceType, insuranceEnquiry }) => {
          if (isNotDefined(insuranceConfigure[insuranceType]) || isNotDefined(insuranceConfigure[insuranceType].proposerRelations)) {
            return {}
          }
          if (isNotDefined(insuranceEnquiry)) {
            return {}
          }
          const { chosenProduct } = insuranceEnquiry
          if (!isNotEmpty(chosenProduct)) {
            return {}
          }
          const { productInsurerId, institutionId } = chosenProduct
          if (isNotDefined(insuranceConfigure[insuranceType].proposerRelations[institutionId]) || isNotDefined(insuranceConfigure[insuranceType].proposerRelations[institutionId][productInsurerId])) {
            return {}
          }
          return insuranceConfigure[insuranceType].proposerRelations[institutionId][productInsurerId]
        }
      )
    }
  }),
  reducers: {

    /**
     * Sets productConfig
     *
     * @param {*} state
     * @param {*} { insuranceType, productConfig }
     * @returns
     */
    updateProductConfig (state, { insuranceType, productConfig }) {
      state[insuranceType].productConfig = productConfig
      return state
    },

    /**
     * Sets uiConfig, productConfig and issuerProductConfig
     *
     * @param {*} state
     * @param {*} { uiConfig, productConfig, issuerProductConfig, insuranceType }
     * @returns
     */
    updateConfigData (state, { uiConfig, smartCovrConfig, productConfig, issuerProductConfig, insuranceType }) {
      if (!isNotDefined(insuranceType)) {
        state[insuranceType] = {
          smartCovrConfig,
          uiConfig,
          productConfig,
          issuerProductConfig
        }
      }
      return state
    },

    /**
     * sets proposerRelations
     *
     * @param {*} state
     * @param {*} payload
     * @returns
     */
    updateProposerRelations (state, payload) {
      const { insuranceType, institutionId, proposerRelations, productInsurerId } = payload
      if (!state[insuranceType].hasOwnProperty('proposerRelations')) {
        state[insuranceType].proposerRelations = {}
      }
      if (!state[insuranceType].proposerRelations.hasOwnProperty(institutionId)) {
        state[insuranceType].proposerRelations[institutionId] = {}
      }
      state[insuranceType].proposerRelations[institutionId][productInsurerId] = proposerRelations
      return state
    },

    /**
     * Sets bookingConfig
     *
     * @param {*} state
     * @param {*} { bookingConfig, insuranceType, institutionId }
     * @returns
     */
    'insuranceEnquiry/updateApplicationData' (state, { bookingConfig, insuranceType, institutionId }) {
      if (isNotEmpty(bookingConfig)) {
        if (!state[insuranceType].hasOwnProperty('booking')) {
          state[insuranceType].booking = {
            [institutionId]: {}
          }
        } else {
          state[insuranceType].booking[institutionId] = {}
        }
        state[insuranceType].booking[institutionId].bookingConfig = bookingConfig
      }
      return state
    },

    /**
     * Resets the booking
     *
     * @param {*} state
     * @param {*} { insuranceType }
     * @returns
     */
    resetBookingConfig (state, { insuranceType }) {
      state[insuranceType].booking = {}
      return state
    },

    /**
     * Updates the booking configuration with proposerRelations and new bookingConfig
     *
     * @param {*} state
     * @param {*} { bookingConfig, insuranceType, proposerRelations, institutionId, productInsurerId }
     * @returns
     */
    updateBookingConfiguration (state, { bookingConfig, insuranceType, proposerRelations, institutionId, productInsurerId }) {
      if (isNotEmpty(bookingConfig)) {
        if (!state[insuranceType].hasOwnProperty('booking')) {
          state[insuranceType].booking = {
            [institutionId]: {}
          }
        } else {
          state[insuranceType].booking[institutionId] = {}
        }
        state[insuranceType].booking[institutionId].bookingConfig = bookingConfig
        if (bookingConfig.uiConfig) {
          state[insuranceType].uiConfig.booking = bookingConfig.uiConfig?.booking
        }
      }
      if (!isNotDefined(proposerRelations) && isNotEmpty(proposerRelations)) {
        if (!state[insuranceType].hasOwnProperty('proposerRelations')) {
          state[insuranceType].proposerRelations = {}
        }
        if (!state[insuranceType].proposerRelations.hasOwnProperty(institutionId)) {
          state[insuranceType].proposerRelations[institutionId] = {}
        }
        state[insuranceType].proposerRelations[institutionId][productInsurerId] = proposerRelations
      }
      return state
    }
  },
  effects: (dispatch) => ({
    loadProposerRelations: effectsHandler(dispatch, loadProposerRelations),
    loadBookingConfiguration: effectsHandler(dispatch, loadBookingConfiguration),
    setUiConfig: effectsHandler(dispatch, setUiConfig)
  })
}
export default insuranceConfigure
