import { PureComponent } from 'react'
import { Label, Checkbox } from 'semantic-ui-react'
import { Subtitle1, H6, Caption } from 'components/Header'
import DisplayHtmlString from 'components/DisplayHtmlString'
import DisplayDescription from './DisplayDescription'
import TooltipComponent from 'components/TooltipComponent'
const styles = {
  image: {
    marginRight: 12
  },
  description: {
    paddingBottom: 24
  }
}

class ExpandableListItem extends PureComponent {
  render () {
    const { key, id, productOptionId, checked, editable, isEdit, editableContent, heading, description, descriptionPiped, points, shortName, displayAmount, onCheckBoxClick, onChangeClick, brochureUrl, device, insuranceType } = this.props
    const showEditDisplay = editable && isEdit
    const enableChange = editable && checked && !isEdit
    const changeLabel = enableChange && (
      <Label
        size='mini'
        className='changeLabel secondary-bg white'
        onClick={onChangeClick}
      >
        CHANGE
      </Label>)
    return (
      <div
        key={key}
        className={`riderCells ${showEditDisplay ? 'editDisplay' : ''}`}
        id={`${productOptionId}_${id}`}
      >
        <div style={styles.image}>
          <Checkbox
            id={`${productOptionId}-${id}-riderCheckbox`}
            checked={checked}
            onChange={(_e, data) => onCheckBoxClick && onCheckBoxClick(data)}
          />
        </div>
        <div className='content'>
          <div className='flexContainer'>
            <Subtitle1 color='black'>
              {heading}
            </Subtitle1>
            {['car', 'bike'].includes(insuranceType) && (
              <div className={`tooltipIcon ${device === 'mobile' ? 'd-none' : 'd-block'}`}>
                <TooltipComponent disable={device === 'mobile'}>
                  <strong>{shortName}</strong>
                  <div style={styles.description}>
                    <DisplayDescription
                      description={description}
                      points={points}
                    />
                  </div>
                </TooltipComponent>
              </div>
            )}
          </div>
          <Caption className='desc'>
            <DisplayHtmlString htmlContent={descriptionPiped} />
            {(device !== 'mobile') && changeLabel}
          </Caption>
          {showEditDisplay && brochureUrl && (
            <Caption className='clickableLinks'>
              <b><a href={brochureUrl} target='blank'>Download Brochure</a></b>
            </Caption>
          )}
          {
            showEditDisplay && editableContent
          }
        </div>
        {!isEdit && (
          <div className='amountDisplay'>
            <H6 color='green'>
              {displayAmount}
            </H6>
            {(device === 'mobile') && changeLabel}
          </div>
        )}
      </div>
    )
  }
}
export default ExpandableListItem
