import { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import { Subtitle2 } from 'components/Header'
import FormDropdown from 'components/FormDropdown'
import { isNotEmpty } from 'utils/lib/basic'
import './Dropdown.scss'

const DeferredPeriod = (props) => {
  const { id, className, disabled, product: { defRopRange }, productData: { defRop }, handleChange } = props

  const options = useMemo(() => {
    if (isNotEmpty(defRopRange)) {
      return defRopRange.map(defPeriod => {
        return {
          key: defPeriod,
          text: `${defPeriod} Years`,
          value: defPeriod
        }
      })
    }
  },
  // eslint-disable-next-line
  [defRop.period, defRopRange])

  if (!isNotEmpty(defRopRange)) {
    return null
  }
  function onClick (event) {
    event.preventDefault()
  }
  return (
    <>
      <Subtitle2>Choose your deferrment period</Subtitle2>
      <Form.Field>
        <FormDropdown
          id={`${id}Dropdown`}
          className={className}
          icon='dropdown'
          // placeholder={placeholder}
          options={options}
          value={defRop.period}
          disabled={disabled}
          fluid
          onChange={(e, data) => handleChange({ defRop: parseFloat(data.value) }, id)}
          onClick={(event) => onClick(event)}
        />
      </Form.Field>
    </>
  )
}
export default DeferredPeriod
