
import { useState } from 'react'
import { Form, Input } from 'semantic-ui-react'
import ValidationErrorPopUp from 'components/ValidationErrorPopUp'
import moment from 'moment'
import { isNotEmpty } from 'utils'
import InfiniteCalendarComponent from 'components/InfiniteCalendarComponent'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
// import DOBTextInput from './DOBTextInput'
import InputMask from 'react-input-mask'
dayjs.extend(LocalizedFormat)
const calculateAge = (dob) => {
  const diffMs = Date.now() - new Date(dob).getTime()
  const ageDt = new Date(diffMs)
  return Math.abs(ageDt.getUTCFullYear() - 1970)
}
// FIXME: REMOVE moment and refractor code!!!!!
const DateOfBirth = (props) => {
  const [openDatePicker, setOpenDatePicker] = useState(false)
  const { dateOfBirth, handleChange, id, formType, getErrorMessageByKey, device, onboardSchema: { properties: { age: { maximum, minimum } } } } = props
  const date = new Date()
  const date2 = new Date()
  const maxDate = moment(date.setFullYear(date.getFullYear() - minimum)).format('YYYY-MM-DD')
  const minDate = moment(date2.setFullYear(date2.getFullYear() - maximum)).format('YYYY-MM-DD')
  const dobString = dateOfBirth && dayjs(dateOfBirth).format('ll')
  let errorMessage = getErrorMessageByKey('dateOfBirth')
  const error = isNotEmpty(errorMessage)
  if (error && errorMessage.includes('format')) {
    errorMessage = 'Date of Birth should be a date'
  }
  // FIXME: following code to be uploaded to utils
  const convertToStandardDate = (inputDate) => {
    // date converted from dd/mm/yyyy to yyyy-mm-dd
    if (isNotEmpty(inputDate)) {
      const tempArray = inputDate.split('/')
      const dd = tempArray[0]
      const mm = tempArray[1]
      const yyyy = tempArray[2]
      return `${isNotEmpty(yyyy) ? yyyy : ''}-${isNotEmpty(mm) ? mm : ''}-${isNotEmpty(dd) ? dd : ''}`
    }
    return inputDate
  }

  const convertToHumanizedDate = (formattedDate = '') => {
    // date converted from yyyy-mm-dd to dd/mm/yyyy
    if (isNotEmpty(formattedDate)) {
      const tempArray = formattedDate.split('-')
      const yyyy = tempArray[0]
      const mm = tempArray[1]
      const dd = tempArray[2]
      return `${isNotEmpty(dd) ? dd : ''}${((dd && dd.length === 2) || isNotEmpty(mm)) ? '/' : ''}${isNotEmpty(mm) ? mm : ''}${((mm && mm.length === 2) || isNotEmpty(yyyy)) ? '/' : ''}${isNotEmpty(yyyy) ? yyyy : ''}`
    }
    return formattedDate
  }
  if (props.hdfcLandingPage) {
    return (
      <div className={`form-group col-lg-3 col-md-6 ${error && 'has-error'}`}>
        {error && (
          <div className='help-block with-errors'>
            <ul className='list-unstyled'>
              <li>{errorMessage}</li>
            </ul>
          </div>
        )}
        <InputMask
          id='dateOfBirth'
          mask='99/99/9999'
          value={convertToHumanizedDate(dateOfBirth)}
          placeholder='Date of birth DD/MM/YYYY'
          maskChar={null}
          onChange={(event) => {
            handleChange({ dateOfBirth: convertToStandardDate(event.target.value) }, event.target.value.length)
          }}
        >
          {(inputProps) => (
            <input
              {...inputProps}
              className='form-control'
              type='text'
            />
          )}
        </InputMask>
      </div>
    )
  }
  if (formType === 'steps') {
    return (
      <Form.Field className='fieldWidth dateOfBirth' id={id}>
        <Input
          className='centered'
          id='date_of_birth'
          onChange={(event, data) => {
            handleChange({ dateOfBirth: data.value, age: calculateAge(data.value) }, data.value.length)
          }}
          placeholder='Enter Date Of Birth'
          value={dateOfBirth}
          required
          type='date'
          max={maxDate}
          name={id}
          autoComplete='off'
        />
      </Form.Field>
    )
  }
  if (device === 'mobile') {
    return (
      <>
        <Form.Field className='fieldWidth dateOfBirth' id={id}>
          <Input
            className='centered'
            error={error}
            id='date_of_birth'
            onChange={(event, data) => {
              handleChange({ dateOfBirth: data.value, age: calculateAge(data.value) }, data.value.length)
            }}
            type='date'
            placeholder='date of birth'
            value={dateOfBirth || ''}
            required
            max={maxDate}
            name={id}
            autoComplete='off'
          />
        </Form.Field>
      </>
    )
  }

  return (
    <>
      {openDatePicker && (
        <InfiniteCalendarComponent
          value={dateOfBirth}
          maxDate={maxDate}
          minDate={minDate}
          onChange={(date) => {
            const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`
            setOpenDatePicker(false)
            handleChange({ dateOfBirth: dateString, age: calculateAge(dateString) }, dateString.length)
          }}
        />
      )}
      <Form.Field className='fieldWidth dateOfBirth' id={id}>
        <Input
          className='centered'
          required
          value={dobString || ''}
          placeholder='date of birth'
          onClick={() => setOpenDatePicker(true)}
        />
      </Form.Field>
      {error && <ValidationErrorPopUp errorMessage={errorMessage} />}
    </>
  )
}
export default DateOfBirth
