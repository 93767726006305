import { useEffect, useState } from 'react'
import {
  useGlobalState,
  useAction,
  useSelector
} from './useReduxFunctions'
import { isNotEmpty } from 'utils'
export default function useSetChosenProduct (insuranceType, loadingConfigure = false, loadingSetFormData = false, bookingReload = false) {
  // all - when set to true, all products are loaded irrespetive of the fact that chosenProduct is there in enquiry

  // pass loadingSetFormData if we're supposed to wait till all products are loaded and then call setChosenProduct
  const loading = useGlobalState(state => state.loading.effects.insuranceProducts.setChosenProduct)
  const setChosenProductDispatch = useAction(dispatch => dispatch.insuranceProducts.setChosenProduct)
  const productsLoaded = useGlobalState(state => state.insuranceProducts[insuranceType].plainProducts.length > 0)
  const [dispatchQueued, setDispatchQueued] = useState(false)
  const [payload, setPayload] = useState()
  const chosenProductMetadata = useSelector((select, state) => select.insuranceEnquiry.getChosenProductMetadata(state, {
    insuranceType
  }))
  const chosenProduct = useSelector((select, state) => select.insuranceProducts.getChosenProduct(state, {
    insuranceType
  }))

  const check = !loadingConfigure && !loadingSetFormData && productsLoaded && !isNotEmpty(chosenProduct)
  // FIXME: flawed and complicated logic. check again
  const callSetChosenProduct = (data) => {
    let chosenProductData = data
    if (!isNotEmpty(data)) {
      chosenProductData = chosenProductMetadata
    }
    const { productOptionId, productId, institutionId, productOptionInsurerId } = chosenProductData
    setChosenProductDispatch({
      insurerId: productOptionInsurerId,
      productOptionId,
      productId,
      institutionId,
      insuranceType,
      quoteType: 'listing',
      reset: true,
      bookingReload
    })
  }
  useEffect(() => {
    if (dispatchQueued && check) {
      setDispatchQueued(false)
      callSetChosenProduct(payload)
    }
  },
  // eslint-disable-next-line
  [ loadingConfigure, loadingSetFormData, productsLoaded ])

  const setChosenProduct = (data) => {
    if (check) {
      callSetChosenProduct(data)
    } else {
      setDispatchQueued(true)
      setPayload(data)
    }
  }
  return [loading, setChosenProduct, chosenProductMetadata]
}
