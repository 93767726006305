
import { Component } from 'react'
import { isNotDefined, isNotEmpty } from 'utils'
import widgets from '../widgets'
import LabelField from '../components/LabelField'
import FormHintInline from '../components/FormHintInline'
import { Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import produce from 'immer'
import FieldRow from '../components/FieldRow'

class SecondLevelField extends Component {
  getOptions (options, schema) {
    if (isNotDefined(options)) {
      options = {}
    }
    if (schema.enum) {
      options.enumOptions = schema.enum.map((value, i) => {
        const label = (schema.enumNames && schema.enumNames[i]) || String(value)
        return { label, value }
      })
    }
    return options
  }

  handleChange (key, value) {
    let formData
    if (!isNotDefined(this.props.formData)) {
      formData = produce(this.props.formData, (draft) => {
        draft[key] = value
      })
    } else {
      formData = {
        [key]: value
      }
    }
    this.props.onChange(formData)
  }

  render () {
    const { schema, formData, uiSchema, errorSchema, formContext } = this.props
    const order = [...uiSchema['ui:order']]
    Object.keys(schema.properties).forEach((item) => {
      if (order.indexOf(item) === -1) {
        order.push(item)
      }
    })

    return (
      // <CustomFieldWrapper
      //   schema={schema}
      //   uiSchema={uiSchema}
      //   errorSchema={errorSchema}
      // >
      <>
        {order.map((key) => {
          if (isNotDefined(schema.properties[key])) {
            return null
          }
          let errors = []
          if (isNotEmpty(errorSchema) && isNotEmpty(errorSchema[key])) {
            errors = errorSchema[key].__errors
          }
          const widgetProps = {
            formData: formData || {}, // when formdata is undefined
            key,
            fieldKey: key,
            schema: schema.properties[key],
            id: key,
            className: key,
            onChange: (value) => {
              this.handleChange(key, value)
            },
            placeholder: uiSchema[key]['ui:placeholder'],
            options: this.getOptions(uiSchema[key]['ui:options'], schema.properties[key]),
            value: formData && formData[key], // when formdata is undefined
            multiple: false,
            required: schema.required.indexOf(key) > -1,
            formContext,
            error: isNotEmpty(errors)
          }
          const widget = uiSchema[key]['ui:widget']
          if (widget === 'hidden') {
            return null
          }
          const Element = widgets[widget]
          const className = uiSchema[key].className
          const id = key
          const labelField = (
            <LabelField
              className={className && (className.indexOf('secondary') > -1) && 'secondary'}
              htmlFor={id}
              title={schema.properties[key].title}
              info={uiSchema[key]['ui:info']}
            />
          )
          return (
            <Grid key={key} stackable columns='equal'>
              <Grid.Row className={`serow field secondary ${widgetProps.error && 'error'}`}>
                <FieldRow
                  key={id}
                  id={id}
                  width={16 - this.hintWidth}
                  displayText={schema.properties[key].title}
                  className={className}
                >
                  {schema.properties[key].title && labelField}
                  {<Element {...widgetProps} />}
                </FieldRow>
                <FormHintInline
                  group={false}
                  id={id}
                  showerror={isNotEmpty(errors)}
                  title={schema.properties[key].title}
                  rawErrors={errors}
                  ref={null}
                  device={formContext.device}
                />
              </Grid.Row>
            </Grid>
          )
        })}
      </>
      // </CustomFieldWrapper>
    )
  }
}

SecondLevelField.propTypes = {
  formData: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  uiSchema: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  required: PropTypes.bool
}

export default SecondLevelField
