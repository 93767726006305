
import { useContext } from 'react'
import { Form, FormDropdown } from 'semantic-ui-react'
import { Subtitle2 } from 'components/Header'
import { isNotDefined, isNotEmpty } from 'utils/lib/basic'
import { getProductData } from 'screens/helper/productHelper'
import { LoadingContext } from 'components/context'
import { getResetValueForKey } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'

const resetProductData = (productData, formData, policyTerm) => {
  const { insuranceType, policyOption, customerType, policyType } = formData
  const newProductData = { ...productData }
  if (!isNotDefined(productData)) {
    Object.keys(productData).forEach(key => {
      if (typeof productData[key] === 'object') {
        let keyData = getResetValueForKey(key, insuranceType)
        if (key === '126' && insuranceType === 'car') { // to make sure personal accident owner driver is defaulted to 3, when user changes policyTerm, since it is a mandatory cover
          keyData = { cpaYear: 3, include: true }
        } else if (key === '126' && insuranceType === 'bike') {
          keyData = { cpaYear: 5, include: true }
        }
        if (key === '126' && policyOption === 'renewExistingPolicy') {
          keyData = { cpaYear: policyTerm, include: true }
        }
        if (customerType === 'corporate' || policyType === 'standalone') {
          keyData = { cpaYear: policyTerm, include: false }
        }
        Object.assign(newProductData, { [key]: keyData })
      }
    })
  }
  return newProductData
}

const PolicyTerm = (props) => {
  const { formData, productData, isProductConfigHandle, handleChange: parentHandleChange, disabled, id } = props
  const { policyTerm } = getProductData(props.pptOptions, productData, isProductConfigHandle)
  const loading = useContext(LoadingContext)
  let policyTermLoading = false
  if (isNotEmpty(loading)) {
    if (loading.formData.status && ['policyTerm', 'idv'].indexOf(loading.formData.key) > -1) {
      policyTermLoading = true
    }
  }
  if (isNotDefined(policyTerm) && policyTermLoading) {
    return (
      <div className='flexCol'>
        <Subtitle2 color='primaryLight'>Policy Tenure</Subtitle2>
        <span className='idv-filter-content-amount'>0 Year</span>
      </div>
    )
  }
  let ShowPolicyTerm = PolicyTermWithLabel
  if (Array.isArray(policyTerm)) {
    ShowPolicyTerm = PolicyTermWithDropDown
  }
  return <ShowPolicyTerm {...{ isProductConfigHandle, formData, productData, policyTerm, disabled, id, parentHandleChange }} />
}

// No longer Used
/* eslint no-unused-vars: 'off' */
const PolicyTermWithLabel = (props) => {
  const { policyTerm } = props
  const policyTermLabel = 'Policy Tenure'
  let policyTermValue = '0 Year'
  let policyTermText = 'Year'
  if ((!isNaN(policyTerm) && Number(policyTerm) > 1) || isNaN(policyTerm)) {
    policyTermText = 'Years'
  }
  policyTermValue = `${policyTerm} ${policyTermText}`
  return (
    <div>
      <Subtitle2 color='primaryLight'>{policyTermLabel}</Subtitle2>
      <span className='idv-filter-content-amount'>{policyTermValue}</span>
    </div>
  )
}

const PolicyTermWithDropDown = (props) => {
  const { policyTerm: policyTermArr, disabled, id, parentHandleChange, formData, productData } = props
  const policyTermValue = formData.policyTerm ?? 0
  const buildPolicyTerm = (policyTermArr) => {
    let text = '0 Year'
    return policyTermArr.map((policyTerm, i) => {
      const value = Object.values(policyTerm)[0]
      const textValue = Object.values(policyTerm).pop()
      text = Object.keys(policyTerm).reduce((arr, key) => arr.concat(`${policyTerm[key]}${key.toUpperCase()}`), []).join(' + ')
      text += (textValue > 0 && textValue < 2) ? ' Year' : ' Years'
      return { key: `${id}-${i}`, value, text }
    })
  }

  const handleChange = (e, { value }) => {
    if (policyTermValue === value) return
    const newProductData = resetProductData(productData, formData, value)
    const newFormData = produce(formData, draft => {
      draft.policyTerm = value
    })
    parentHandleChange({ formData: newFormData, productData: newProductData }, id, true)
  }

  return (
    <div>
      <Subtitle2 color='primaryLight'>Change Policy Tenure</Subtitle2>
      <Form.Field>
        <FormDropdown
          id={`${id}Dropdown`}
          className=''
          icon='dropdown'
          options={buildPolicyTerm(policyTermArr)}
          value={policyTermValue}
          disabled={disabled}
          fluid
          selection
          onChange={handleChange}
        />
      </Form.Field>
    </div>
  )
}

export default PolicyTerm
