import { Checkbox, Form } from 'semantic-ui-react'
import 'font-awesome/css/font-awesome.min.css'
import TooltipComponent from 'components/TooltipComponent'
import { isNotEmpty } from 'utils/lib/basic'
import './buttonWidget.scss'

const styles = {
  contentStyle: {
    width: 250,
    fontSize: 14,
    opacity: 1,
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
  }
}

const ButtonWidget = (props) => {
  const {
    id,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    required,
    onChange,
    schema
  } = props
  const { enumOptions } = options
  const radioOptions = enumOptions.map(({ value, label }, i) => (
    { key: i, value, text: label }
  ))
  let disabledValue = disabled
  if (!disabledValue) {
    disabledValue = options.disabled
  }

  const renderLabel = (title, value) => {
    const description = isNotEmpty(schema.desc) && schema.desc[value]
    return (
      <label>
        {description && (
          <TooltipComponent id={value} contentStyle={styles.contentStyle} placement='left' trigger={['click', 'hover']}>
            {description}
          </TooltipComponent>
        )}
        <div id='button-label'>{title}</div>
      </label>
    )
  }

  return (
    <Form.Field id={`${id}-field`} className='buttonWidget'>
      {radioOptions.map((val, index) => (
        <Checkbox
          id={`${id}-${val.value}`}
          key={`checkbox${index}`}
          radio
          label={renderLabel(val.text, val.value)}
          name={`${id}_radioGroup`}
          className='buttonWidgetBtn'
          value={val.value}
          checked={false}
          onChange={(e, { value }) => onChange(value)}
          disabled={(options.enumDisabled || [])?.some(v => v === val.value) || disabledValue}
          readOnly={readonly}
          required={required}
          onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
          onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
        />
      ))}
    </Form.Field>
  )
}
export default ButtonWidget
