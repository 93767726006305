import { useState } from 'react'
import InputMask from 'react-input-mask'

const EngineNumberWidget = (props) => {
  const {
    id,
    placeholder,
    onChange,
    schema
  } = props
  const { minLength, maxLength } = schema
  const [engineNumber, setEngineNumber] = useState(props.value)

  const onEngineNumberChange = (engineNumberValue) => {
    setEngineNumber(engineNumberValue)
    return onChange(engineNumberValue)
  }

  return (
    <InputMask
      id={id}
      value={engineNumber}
      onChange={(event) => {
        event.preventDefault()
        const value = event.target.value.replace(/[^a-z0-9]/gi, '')
        onEngineNumberChange(value.toUpperCase())
      }}
      placeholder={placeholder}
      maxLength={maxLength}
      minLength={minLength}
    />
  )
}

export default EngineNumberWidget
