
import { Grid } from 'semantic-ui-react'
import { Subtitle1, Subtitle2 } from 'components/Header'
import FormHintInline from './FormHintInline'

const QuestionGroupHeader = (props) => {
  const { title, questionNumber, id } = props
  let headerDisplay
  if (questionNumber) {
    headerDisplay = (
      <Subtitle2 style={{ display: 'flex' }}>
        <div style={{ flex: '0.05' }}>
          {questionNumber}.
        </div>
        <div style={{ flex: '0.9' }}>
          {title}
        </div>
      </Subtitle2>
    )
  } else {
    headerDisplay = (
      <Subtitle1 color='primaryLight'>
        {title}
      </Subtitle1>
    )
  }
  return (
    <Grid stackable columns='equal'>
      <Grid.Row className=''>
        <Grid.Column
          textAlign='left'
          stretched
        >
          {headerDisplay}
        </Grid.Column>
        <FormHintInline
          group={false}
          id={id}
          showerror={false}
          rawErrors={props.rawErrors}
          // ref={(ref) => this.setHintRef(ref, id)}
        />
      </Grid.Row>
    </Grid>
  )
}
export default QuestionGroupHeader
