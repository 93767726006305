
import { connect } from 'react-redux'
import { Onboard } from './components'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    formData: models.insuranceEnquiry.getFormData,
    productData: models.insuranceEnquiry.getProductData,
    distributorId: models.configure.getDistributorId,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    customerDetails: models.customer.getCustomerDetails,
    countries: models.configure.getCountriesMaster
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...selectorData,
    formDataLoading: state.loading.models.insuranceEnquiry,
    routeProps: ownProps,
    insuranceType,
    iamId: selectorData.customerDetails.iamId
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    setDropOff: (insuranceType, dropOff) => {
      return dispatch.insuranceEnquiry.setDropOffForAgent({
        insuranceType,
        dropOff
      })
    }
  }
}
const OnboardContainer = connect(mapStateToProps, mapDispatchToProps)(Onboard)
OnboardContainer.path = 'onboard'
export default OnboardContainer
