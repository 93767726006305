
import { Input } from 'semantic-ui-react'
import NumberFormat from 'react-number-format'

const PercentWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    placeholder
  } = props
  const typeOfField = props.schema.type
  // Remove labels to make it look just like Bootstrap.
  return (
    <NumberFormat
      id={id}
      placeholder={placeholder}
      decimalSeparator='.'
      decimalScale={0}
      fixedDecimalScale={typeOfField === 'number'}
      value={value}
      onValueChange={({ value }) => props.onChange(value.length === 0 ? options.emptyValue : parseInt(value))}
      customInput={Input}
      format='###'
      disabled={disabled}
      readOnly={readonly}
      label={{ basic: true, content: '%' }}
      labelPosition='right'
      // id={id}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}

    />
  )
}

export default PercentWidget
