import { Button } from 'semantic-ui-react'
import { isNotDefined } from 'utils'

const BrowseMoreProductsButton = (props) => {
  const {
    selectedApplication,
    selectedPolicyData,
    checkFormDataValidity
  } = props

  const handleOnClick = async () => {
    const valid = checkFormDataValidity()
    if (valid) {
      props.redirectToPath('select-product')
    } else {
      props.redirectToPath('onboard')
    }
  }

  return (
    <div
      onClick={handleOnClick}
      hidden={!(isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData))}
    >
      <Button
        id='browseMoreProducts'
        content='Browse More Products'
        secondary
      />
    </div>
  )
}

export default BrowseMoreProductsButton
