import { FeathersError } from '@feathersjs/errors'
class ServerApiError extends FeathersError {
  constructor (message, name, code, classname, data) {
    super(message, name, code, classname, data)
    this.errorType = 'ServerApiError'
  }
}
class GetProductsError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to get products'
    super(errorMessage, 'GetProductsError', 500, 'general-error', data)
  }
}
class GetProductError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to get product'
    super(errorMessage, 'GetProductError', 500, 'general-error', data)
  }
}
class GetProductDataError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to get product data'
    super(errorMessage, 'GetProductDataError', 500, 'general-error', data)
  }
}
class GetUiConfigurationError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to get UI configuration'
    super(errorMessage, 'GetUiConfigurationError', 500, 'general-error', data)
  }
}
class GetProductConfigurationError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to get product configuration'
    super(errorMessage, 'GetProductConfigurationError', 500, 'general-error', data)
  }
}
class GetBookingConfigurationError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to get booking configuration'
    super(errorMessage, 'GetBookingConfigurationError', 500, 'general-error', data)
  }
}
class GetDistributorConfigurationError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to get distributor configuration'
    super(errorMessage, 'GetDistributorConfigurationError', 500, 'general-error', data)
  }
}
class UpdateBookingError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to update application data to booking'
    super(errorMessage, 'UpdateBookingError', 500, 'general-error', data)
  }
}
class ProcessRequestBookingError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to process booking'
    super(errorMessage, 'ProcessRequestBookingError', 500, 'general-error', data)
  }
}
class ProcessRequestPaymentError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to process payment'
    super(errorMessage, 'ProcessRequestPaymentError', 500, 'general-error', data)
  }
}
class GetProposerRelationsError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to get proposer relations'
    super(errorMessage, 'GetProposerRelationsError', 500, 'general-error', data)
  }
}
class LoadEnquiryError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to load Enquiry'
    super(errorMessage, 'LoadEnquiryError', 500, 'general-error', data)
  }
}
class UpdateEnquiryToDbError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to update enquiry to DB'
    super(errorMessage, 'UpdateEnquiryToDbError', 500, 'general-error', data)
  }
}
class SisGenerationError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to generate SIS'
    super(errorMessage, 'SisGenerationError', 500, 'general-error', data)
  }
}
class PaymentRedirectionError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to get payment redirection Url'
    super(errorMessage, 'PaymentRedirectionError', 500, 'general-error', data)
  }
}
class PostPaymentActionError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Post payment action Failed'
    super(errorMessage, 'PostPaymentActionError', 500, 'general-error', data)
  }
}
class ApplicationFormGenerationError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to generate appplication form'
    super(errorMessage, 'ApplicationFormGenerationError', 500, 'general-error', data)
  }
}
class GetCustomerError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Failed to get customer details'
    super(errorMessage, 'GetCustomerError', 500, 'general-error', data)
  }
}

class TokenExpiredError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Refresh token has expired'
    super(errorMessage, 'TokenExpiredError', 500, 'general-error', data)
  }
}
class GetPincodeDataError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'Refresh token has expired'
    super(errorMessage, 'TokenExpiredError', 500, 'general-error', data)
  }
}
class GetReportsForDistributorError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'failed to get report'
    super(errorMessage, 'GetReportsForDistributorError', 500, 'general-error', data)
  }
}
class LoginError extends ServerApiError {
  constructor (message, data) {
    const errorMessage = message || 'login failed'
    super(errorMessage, 'loginFailedError', 500, 'general-error', data)
  }
}
export default {
  GetProductsError,
  GetProductError,
  GetProductDataError,
  GetUiConfigurationError,
  GetProductConfigurationError,
  GetBookingConfigurationError,
  GetDistributorConfigurationError,
  UpdateBookingError,
  ProcessRequestBookingError,
  ProcessRequestPaymentError,
  GetProposerRelationsError,
  LoadEnquiryError,
  UpdateEnquiryToDbError,
  SisGenerationError,
  PaymentRedirectionError,
  PostPaymentActionError,
  ApplicationFormGenerationError,
  GetCustomerError,
  TokenExpiredError,
  GetPincodeDataError,
  GetReportsForDistributorError,
  LoginError
}
