import { JsonSchemaValidation } from 'components/jsonSchemaForms'
import FormHint from 'components/jsonSchemaForms/components/FormHint'
import { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { validateOtp } from 'smartcovr_api/src/services/claimsService'
import CountdownTimer from './CountdownTimer'

const OtpVerificationWidget = (props) => {
  const [otp, setOtp] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [isTimerRunning, setIsTimerRunning] = useState(true)
  const [errorMessage, setErrorMessage] = useState([])
  const otpValidationFailedMessage = 'Wrong OTP Entered. Please Enter Correct OTP'
  const { setShowResendLink, showResendLink, setVerifyShow, insuranceType, distributorId, formData, getOtp } = props.formContext
  const { validateOTP } = JsonSchemaValidation

  useEffect(() => {
    if (showResendLink === true) {
      setShowErrorMessage(false)
    }
  }, [showResendLink])

  const handleResendOtp = () => {
    setShowErrorMessage(false)
    setIsTimerRunning(true) // Start the timer
    setShowResendLink(false) // Hide the "Resend OTP" link
    const queryParams = { insuranceType, distributorId }
    const objForOtpGeneration = {}
    if (formData.verifyPolicyNo) {
      objForOtpGeneration.policyNumber = formData.verifyPolicyNo
    } else if (formData.verifyRegisteredMobileNo) {
      const mobileNo = JSON.parse(formData.verifyRegisteredMobileNo)
      objForOtpGeneration.mobile = mobileNo.number
    } else if (formData.intimatorDetails.intimatorContactNumber) {
      const contactNumber = JSON.parse(formData.intimatorDetails.intimatorContactNumber)
      objForOtpGeneration.mobile = contactNumber.number
    }
    const otpProps = { enquiryData: objForOtpGeneration, queryParams }
    getOtp(otpProps)
  }

  const handleTimerEnd = () => {
    setShowResendLink(true) // Show the "Resend OTP" link when the timer ends
    setIsTimerRunning(false) // Stop the timer
  }

  const handleOtpChange = (e) => {
    const enteredOtp = e.target.value.replace(/\D/g, '') // Allow only digits
    setOtp(enteredOtp)
    props.onChange(enteredOtp)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    const queryParams = { insuranceType, distributorId }
    const otpValBody = props.formContext.otp[insuranceType]
    otpValBody.otp = otp

    const dataValidate = { enquiryData: otpValBody, queryParams }

    const error = validateOTP(otp)

    if (error) {
      setShowErrorMessage(true)
      setErrorMessage([error])
    } else {
      try {
        const res = await validateOtp(dataValidate)
        if (!res.otpVerified) {
          setErrorMessage([otpValidationFailedMessage])
          setShowErrorMessage(true)
        } else if (res.otpVerified) {
          setVerifyShow(true)
        }
      } catch (error) {
        console.error('Error in OTP Validation:', error)
      }
    }
  }

  return (
    <div>
      <input
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={handleOtpChange}
            maxLength={6}
            minLength={6}
          />
      {showResendLink
        ? (
        <p>
          <span
            style={{
              color: 'blue',
              textDecoration: 'none',
              cursor: 'pointer'
            }}
            onClick={handleResendOtp}
          >
            Resend OTP
          </span>
        </p>
          )
        : (
            isTimerRunning ? <CountdownTimer onTimerEnd={handleTimerEnd} isTimerActive={isTimerRunning} /> : null
          )}
      {showErrorMessage && (
        <FormHint showerror={showErrorMessage} rawErrors={errorMessage} />
      )}
      <Button secondary content={'Verify'} disabled={showResendLink} onClick={handleSubmit} />
    </div>
  )
}

export default OtpVerificationWidget
