import { Icon } from 'semantic-ui-react'
import { Caption, Body1 } from 'components/Header'

const SumAssuredAndStatus = ({ data }) => {
  const status = data.claimStatus
  const amtDisplay = 10
  const amtLabelDisplay = 'lakh'
  return (
    <div className='secondHalf'>
      <div className='sumAssuredValue'>
        <Caption>
          Sum Assured
        </Caption>
        <Body1 color='green' className='body1'>

          {amtDisplay !== 'N/A' && <Icon size='small' name='rupee' />}
          <span>{`${amtDisplay} ${amtLabelDisplay}`}</span>
        </Body1>
      </div>
      <div className='Status'>
        <Caption>
          Status
        </Caption>
        <Body1 color='yellow'>
          {status}
        </Body1>
      </div>
    </div>
  )
}

export default SumAssuredAndStatus
