import { unescape } from 'lodash'
import DisplayHtmlString from 'components/DisplayHtmlString'

const OnboardAccordion = ({ id, title, description }) => {
  return (
    <div className='accordion-item'>
      <h4 className='accordion-header'>
        <button
          className='accordion-button collapsed'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target={`#${id}`}
        >
          {unescape(title)}
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z' /></svg>
        </button>
      </h4>
      <div id={id} className='accordion-collapse collapse' data-bs-parent='#faq-accordion'>
        <div className='accordion-body'>
          <DisplayHtmlString htmlContent={description} />
        </div>
      </div>
    </div>
  )
}

export default OnboardAccordion
