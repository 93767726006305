import { useMemo } from 'react'
import { Form } from 'semantic-ui-react'
import { Subtitle2 } from 'components/Header'
import FormDropdown from 'components/FormDropdown'
import { isNotEmpty } from 'utils/lib/basic'
import './Dropdown.scss'

const AcpPeriod = (props) => {
  const { id, className, disabled, product: { acpRange }, productData: { acp }, handleChange } = props

  const options = useMemo(() => {
    if (isNotEmpty(acpRange)) {
      return acpRange.map(rop => {
        return {
          key: rop,
          text: `${rop}`,
          value: rop
        }
      })
    }
  },
  // eslint-disable-next-line
  [acp.period])

  if (!isNotEmpty(acpRange)) {
    return null
  }
  function onClick (event) {
    event.preventDefault()
  }
  return (
    <>
      <Subtitle2>Choose your annuity for a certain period</Subtitle2>
      <Form.Field>
        <FormDropdown
          id={`${id}Dropdown`}
          className={className}
          icon='dropdown'
          options={options}
          value={acp.period}
          disabled={disabled}
          fluid
          onChange={(e, data) => handleChange({ acp: parseFloat(data.value) }, id)}
          onClick={(event) => onClick(event)}
        />
      </Form.Field>
    </>
  )
}
export default AcpPeriod
