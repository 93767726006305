import axios from 'axios'
import feathers from '@feathersjs/feathers'
import rest from '@feathersjs/rest-client'
import authentication from '@feathersjs/authentication-client'
import { isNotDefined } from 'utils'

const env = process.env.NODE_ENV
let app

/**
   * Configures the feathers instance
   *
   * @returns object (feathers instance)
   */
export const feathersApp = (config) => {
  if (isNotDefined(app)) {
    const url = config.serverUrl
    const restClient = rest(url)
    app = feathers()
    app.configure(restClient.axios(axios, { timeout: 60000 }))
    app.configure(authentication({
      header: 'x-authorization',
      cookie: 'oa-token',
      path: (env === 'development') ? 'authentication' : `authentication/${config.apiVersion}`
    }))
  }
  return app
}

/**
   * Adds hooks to feathers instance
   *
   * @param {*} addAuthHeaders
   * @returns
   */
export const createHookToAddAuthHeaders = (addAuthHeaders) => {
  // params takes object to be set in request header.
  // incase addHeader is called multiple times, hooks execute in order.
  // only to be used if params are not available during initialization
  app.hooks({
    before: {
      all: [
        addAuthHeaders
      ]
    }
  })
}
