import { useEffect, useState } from 'react'
import { Button, Form, Icon, Modal, Radio } from 'semantic-ui-react'
import Slider, { createSliderWithTooltip } from 'rc-slider'
import TooltipComponent from 'components/TooltipComponent'
import { Header, Subtitle2 } from 'components/Header'
import { amountWithLabels, isNotDefined, rupeeFormatter } from 'utils/lib/basic'
import { mean } from 'lodash'
import produce from 'immer'
import { getProductData } from 'screens/helper/productHelper'
import { getResetValueForKey } from 'smartcovr_api/src/insuranceApi'
import 'rc-slider/assets/index.css'
import './IdvFilter.scss'

const SliderWithTooltip = createSliderWithTooltip(Slider)
const IDVToolTip = () => {
  return (
    <TooltipComponent id='idv-filter' contentStyle={{ opacity: 1 }}>
      <div><strong>Insured Declared Value (IDV)</strong>The maximum sum assured fixed by the insurer that is offered in case of theft or total loss of a vehicle.</div>
    </TooltipComponent>
  )
}

const formatAmount = (value) => {
  const { amount, label } = amountWithLabels(value)
  return (
    <>
      <Icon size='small' name='rupee' />
      {`${amount} ${label}`}
    </>
  )
}

const resetProductData = (productData, formData) => {
  const { insuranceType } = formData
  const newProductData = { ...productData }
  if (!isNotDefined(productData)) {
    Object.keys(productData).forEach(key => {
      if (typeof productData[key] === 'object') {
        const keyData = getResetValueForKey(key, insuranceType)
        if (['136', '137', '138'].includes(key)) { // reset the idv accesories if the idv value has changed.
          Object.assign(newProductData, { [key]: keyData })
        }
      }
    })
  }
  return newProductData
}

/**
 * @param {Boolean} props.isProductConfigHandle Will be true only on ProductConfig Page, becuase we will not update the enquiry on ProductConfig Page
 */
const IdvFilter = (props) => {
  const { id, productData, formData, updateChange, handleChange: parentHandleChange, isProductConfigHandle } = props
  const { idv } = formData
  const { idvRange: { idvMin, idvMax } } = getProductData(props.pptOptions, productData, isProductConfigHandle)
  const [isOpen, setOpen] = useState(false)
  const [idvValue, setIdvValue] = useState(idv)
  const step = ((idvMax - idvMin) % 1000) + 1000

  useEffect(() => {
    const newFormData = produce(formData, draft => {
      draft.idv = idvMin
    })
    if (!isProductConfigHandle) {
      parentHandleChange(newFormData, id, true)
      setIdvValue(idvMin)
    }
  }, [idvMin]) // eslint-disable-line

  const marks = {
    [idvMin]: formatAmount(idvMin),
    [idvMax]: formatAmount(idvMax)
  }
  const handleChange = (value) => {
    setIdvValue(value)
    const newFormData = produce(formData, draft => {
      draft.idv = value
    })
    if (isProductConfigHandle) {
      parentHandleChange(newFormData, id, false)
    }
  }

  const handleSubmit = () => {
    if (!isProductConfigHandle) {
      if (idvValue === formData.idv) {
        setOpen(false)
        return
      }
    }
    const newFormData = produce(formData, draft => {
      draft.idv = idvValue
    })
    if (isProductConfigHandle) {
      updateChange(id)
    } else {
      parentHandleChange(newFormData, id, true)
    }
    resetProductData(productData, newFormData)
    setOpen(false)
  }

  return (
    <>
      <div className='idv-filter' color='secondary'>
        <div className='idv-filter-title'>
          <Subtitle2 color='primaryLight'>Insured Declared Value (IDV)</Subtitle2>
          <IDVToolTip />
        </div>
        <div className='idv-filter-content'>
          <span className='idv-filter-content-amount'>{formatAmount(idvValue)}</span>
          <span id='idv-edit-btn' className='edit-idv-btn' onClick={() => setOpen(true)}>EDIT</span>
        </div>
      </div>
      <Modal open={isOpen} size='mini' className='idv-modal'>
        <Modal.Header className='idv-modal-header'>
          <div className='idv-modal-title'>
            <Header>Insured Declared Value(IDV)</Header>
            <IDVToolTip />
          </div>
          <Header className='idv-modal-amount' color='green'>
            <Icon size='tiny' name='rupee' />
            <b>{rupeeFormatter(idvValue)}</b>
          </Header>
        </Modal.Header>
        <Modal.Content className='idv-modal-content'>
          <Form>
            <Form.Field>
              <Radio
                label='Lowest IDV'
                name='idv-group'
                id='idv-min'
                value={idvMin}
                checked={idvValue === idvMin}
                onChange={(e, { value }) => handleChange(value)}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Maximum IDV'
                name='idv-group'
                id='idv-max'
                value={idvMax}
                checked={idvValue === idvMax}
                onChange={(e, { value }) => handleChange(value)}
              />
            </Form.Field>
            <Form.Field>
              <Radio
                label='Choose Your IDV'
                name='idv-group'
                id='idv-user'
                value={mean([idvMin, idvMax])}
                checked={idvValue < idvMax && idvValue > idvMin}
                onChange={(e, { value }) => handleChange(value)}
              />
            </Form.Field>
            <SliderWithTooltip
              defaultValue={idvValue}
              value={idvValue}
              id='idv-slider'
              tipFormatter={formatAmount}
              marks={marks}
              tipProps={{ placement: 'top' }}
              min={idvMin}
              max={idvMax}
              step={step}
              onChange={(value) => handleChange(value)}
            />
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button id='idv-update' basic secondary onClick={handleSubmit}>Update IDV</Button>
          <Button id='idv-close' basic onClick={() => setOpen(false)}>Close</Button>
        </Modal.Actions>
      </Modal>
    </>
  )
}

export default IdvFilter
