
import { prepareFilters, reduceRootStateDraft, reduceRootStateUpdated, checkAndFinishDraft } from './modelHelpers'
import { checkForBadRequest } from '../../errors'
import { configureForDisplay } from './helpers/productHelper'
import { updateEnquiryToDb } from '../../services/commonService'
import { configureFilters } from './helpers/filterHelper'

/**
   * This function first gets all the filter variables and calls configureFilters where products are appended
   * with filter config, then the response is passed to configureForDisplay where product's data,
   * description and premiums changes according to the product config appended in that product.
   * The updated product and filterConfif is then passed to reducer
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setFilters = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType'], payload)
  const { insuranceType, filterChoices } = payload
  const reducedRootState = reduceRootStateDraft(draftRootState, insuranceType)
  const { insuranceConfigureDraft, insuranceProductsDraft, insuranceFiltersDraft, insuranceEnquiryDraft } = reducedRootState
  insuranceProductsDraft.flattenedProducts = []
  prepareFilters({
    filterChoices,
    insuranceType
  }, {
    insuranceFilters: insuranceFiltersDraft,
    insuranceEnquiry: insuranceEnquiryDraft
  })
  const { productConfig: { filterConfig } } = insuranceConfigureDraft
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  // Need to refresh insuranceProducts list and then do a dispatch
  const products = insuranceProductsDraft.plainProducts
  const { features, payouts, featureContent } = insuranceProductsDraft
  for (let prod = 0; prod < products.length; prod++) {
    const configuredProduct = configureFilters(
      products[prod],
      insuranceFiltersDraft.filterData,
      filterConfig.filterPriority
    )
    if (configuredProduct.filter.fullMatch) {
      try {
        const { formData, productData } = insuranceEnquiryDraft
        const flatProduct = await configureForDisplay(
          configuredProduct,
          formData,
          productData,
          paymentOptions,
          features,
          payouts,
          featureContent
        )
        insuranceProductsDraft.flattenedProducts.push(flatProduct)
      } catch (err) {
        throw err
      }
    }
  }
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceEnquiryUpdated, insuranceFiltersUpdated, insuranceProductsUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  // Write to db
  updateEnquiryToDb(
    insuranceEnquiryUpdated,
    insuranceType,
    updatedRootState.insuranceEnquiry.enquiryId
  )
  dispatch.insuranceFilters.updateFilters({
    insuranceType,
    flattenedProducts: insuranceProductsUpdated.flattenedProducts,
    insuranceFilters: insuranceFiltersUpdated,
    insuranceEnquiry: insuranceEnquiryUpdated,
    formData: insuranceEnquiryUpdated.formData
  })
}

/**
   * This function sets the filterData to empty object and recentFilters to empty array. And it resets the
   * flattenedProducts to empty array. It also resets the filter values in product. Then configureForDisplay
   * is called to update the product values.
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const resetFilter = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType'], payload)
  const { insuranceType, filterKey } = payload
  let { insuranceFiltersDraft, insuranceEnquiryDraft, insuranceConfigureDraft, insuranceProductsDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  const { productConfig: { filterConfig } } = insuranceConfigureDraft
  const paymentOptions = insuranceConfigureDraft.uiConfig.productListing.paymentOptionsPriority
  if (filterKey === 'all') {
    insuranceEnquiryDraft.productData.paymentFrequency = 'M'
    insuranceEnquiryDraft.productData.paymentOption = 'regular'
    insuranceFiltersDraft = {
      filterData: {},
      recentFilters: []
    }
  } else {
    delete insuranceFiltersDraft.filterData[filterKey]
  }
  insuranceProductsDraft.flattenedProducts = []
  const products = insuranceProductsDraft.plainProducts
  for (let ix = 0; ix < products.length; ix++) {
    let product = products[ix]
    product.filter = {
      visible: true,
      fullMatch: true
    }
    if (filterKey !== 'all') {
      // Reconfigure all filters for this product
      product = configureFilters(product, insuranceFiltersDraft.filterData, filterConfig.filterPriority)
    }
    try {
      const { productData, formData } = insuranceEnquiryDraft
      product = await configureForDisplay(
        product,
        formData,
        productData,
        paymentOptions
      )
      insuranceProductsDraft.flattenedProducts.push(product)
    } catch (err) {
      throw err
    }
  }
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceEnquiryUpdated, insuranceProductsUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)

  updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  dispatch.insuranceFilters.resetFilters({
    insuranceType,
    flattenedProducts: insuranceProductsUpdated.flattenedProducts,
    insuranceFiltersDraft,
    formData: insuranceEnquiryUpdated.formData
  })
}
export {
  setFilters,
  resetFilter
}
