import { useRef, useEffect, useState } from 'react'
import PolicyNature from './PolicyNature'
import { List, Segment, ListItem } from 'semantic-ui-react'
import { Subtitle2, H6 } from 'components/Header'

import ProductImageAndNameWithDescription from '../ProductImageAndNameWithDescription'
import SumAssuredAndStatus from '../SumAssuredAndStatus'

import '../dashBoard.scss'
import 'components/css/loader.scss'
import TableComponent from 'components/TableComponent'

const UserApplicationsListing = (props) => {
  const {
    isLoggedIn,
    loadApplications,
    loadingApplications,
    loadingStatus,
    loadingModal,
    applications,
    getFormStepStatus,
    continueHandler,
    getStatusHandler,
    updateSelectedApplicationNumber,
    count
  } = props
  const selectorItemData = { data: applications, count }
  const [pageRow, setPageRow] = useState(10)
  const [pageChange, setPageChange] = useState(0)
  const [skipPage, setSkipPage] = useState(0)

  const previousLoading = useRef(false)
  useEffect(() => {
    if (isLoggedIn) {
      loadApplications({ skip: skipPage, limit: pageRow })
    }
  },
  // eslint-disable-next-line
  [pageChange, pageRow])

  const renderTable = () => {
    return (
      <TableComponent
        {...props}
        selectorItemData={selectorItemData}
        pageRow={pageRow}
        pageChange={pageChange}
        setSkipPage={setSkipPage}
        setPageRow={setPageRow}
        setPageChange={setPageChange}
      />
    )
  }

  useEffect(() => {
    previousLoading.current = loadingApplications || loadingStatus || loadingModal
  }, [loadingApplications, loadingStatus, loadingModal])
  let loading
  let content
  if (!(previousLoading.current)) {
    loading = (
      <div className='loaderOuter applicationLoader' id='loading'>
        <div className='ball-spin-fade-loader'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  } else {
    content = applications.length === 0
      ? (
        <div className='noOngoingApplications'>
          <Subtitle2 color='secondary'>No Ongoing Applications</Subtitle2>
        </div>
        )
      : (
        <>
          <List>
            {applications.map((application, index) => {
              const formStepStatus = getFormStepStatus(application.applicationNumber)
              const applicationCompleted = formStepStatus.find(ele => !ele.completed)
              return (
                <div key={index}>
                  <ListItem className='listingItems'>
                    <ProductImageAndNameWithDescription data={application} />
                    <SumAssuredAndStatus title='Application' data={application} />
                    {
                  application.insuranceType === 'health' &&
                      (
                        <PolicyNature title='Application' data={application} />
                      )
                  }
                    <div className='applicationDetailsButton'>
                      {['SC092', 'SC093', 'SC094', 'SC095'].includes(application.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode)
                        ? (
                          <div className='continueButton w-100'>
                            <Subtitle2 onClick={async () => getStatusHandler(application.applicationNumber)} color='secondary' className='subtitle2Btn'>
                              GET STATUS
                            </Subtitle2>
                          </div>
                          )
                        : (
                          <div className={!applicationCompleted ? 'continueButtonDisable' : 'continueButton w-100'}>
                            <Subtitle2 onClick={async () => await continueHandler(application.applicationNumber, application.finanalyticsId)} color='secondary' className='subtitle2Btn'>
                              CONTINUE
                            </Subtitle2>
                          </div>
                          )}
                      <div className='w-100' onClick={() => updateSelectedApplicationNumber({ applicationNumber: application.applicationNumber })}>
                        <Subtitle2 color='secondary' className='subtitle2Btn'>
                          DETAILS
                        </Subtitle2>
                      </div>
                    </div>
                    {/* ----- Button Part ---- */}
                  </ListItem>
                  <hr hidden={index + 1 === applications.length} />
                </div>
              )
            }
            )}
          </List>
          {(renderTable())}
        </>
        )
  }
  return (
    <Segment className={(!(previousLoading.current)) ? 'userApplication loaderParent' : 'userApplication'}>
      <H6 color='primaryLight' className='h6' content='Your Applications' />
      {loading}
      {content}
    </Segment>
  )
}

export default UserApplicationsListing
