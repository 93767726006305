
import { isNotDefined, isNotEmpty } from 'utils'
import produce from 'immer'
import { modifyFilters, effectsHandler } from './modelHelpers'

import { setFilters, resetFilter } from './insuranceFiltersEffects'

/**
   * Modifies the filter with recentFilters and filterData
   *
   * @param {*} state
   * @param {*} insuranceType
   * @param {*} insuranceFilters
   * @returns object
   */
const buildFilterState = (state, insuranceType, insuranceFilters) => {
  if (isNotDefined(insuranceFilters) || !isNotEmpty(insuranceFilters)) {
    return state
  }
  const currentFilter = state[insuranceType]
  // Cleaning insuranceFilters
  state[insuranceType] = produce(insuranceFilters, (draft) => {
    modifyFilters(currentFilter, draft)
  })
  return state
}
const insuranceFilters = {
  name: 'insuranceFilters',
  state: {
    term: {
      filterData: {},
      recentFilters: []
    },
    car: {
      filterData: {},
      recentFilters: []
    },
    bike: {
      filterData: {},
      recentFilters: []
    },
    health: {
      filterData: {},
      recentFilters: []
    },
    annuity: {
      filterData: {},
      recentFilters: []
    }
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Gets the filterData  and recentFilters
     *
     * @returns object
     */
    getFilters () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceFilters, insuranceType) => insuranceFilters[insuranceType]
      )
    }
  }),
  reducers: {

    /**
     * Calls buildFilterState function where filterData and recentFilters are built
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceFilters }
     * @returns object
     */
    updateFilters (state, { insuranceType, insuranceFilters, formData }) {
      return buildFilterState(state, insuranceType, insuranceFilters, formData)
    },

    /**
     * Calls buildFilterState function where filterData and recentFilters are built
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceFilters }
     * @returns object
     */
    resetFilters (state, { insuranceType, insuranceFilters, formData }) {
      return buildFilterState(state, insuranceType, insuranceFilters, formData)
    },

    /**
     * Calls buildFilterState function where filterData and recentFilters are built
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceFilters }
     * @returns object
     */
    'insuranceProducts/updateProducts' (state, { insuranceType, insuranceFilters }) {
      return buildFilterState(state, insuranceType, insuranceFilters)
    },

    /**
     * Calls buildFilterState function where filterData and recentFilters are built
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceFilters }
     * @returns object
     */
    'insuranceEnquiry/updateEnquiry' (state, { insuranceType, insuranceFilters }) {
      return buildFilterState(state, insuranceType, insuranceFilters)
    }
  },
  effects: (dispatch) => ({
    setFilters: effectsHandler(dispatch, setFilters),
    resetFilter: effectsHandler(dispatch, resetFilter)
  })
}
export default insuranceFilters
