import { Header } from 'semantic-ui-react'

const WrapContent = ({ children, ...restProps }) => {
  return (
    <Header className='noStyle' {...restProps}>
      {children}
    </Header>
  )
}

export default WrapContent
