/* eslint camelcase: 0 */

import {
  isNotDefined,
  isNotEmpty,
  expandRules,
  formatFields,
  calculate,
  rupeeFormatter,
  isArray
} from 'utils'
import { cloneDeep } from 'lodash'
import produce from 'immer'
import { configureFilters } from './filterHelper'
import Engine from 'json-rules-engine'
import { execute } from 'json-schemaform-rules'
import { CustomErrors } from '../../../errors'
import extraActions from './extraActions'
import { schema, uiSchema } from '../../../templates/paymentOptionSchema'
import {
  incomeIncreaseSchema,
  incomePeriodSchema,
  isaSchema,
  incomePeriodYearSchema,
  incomeInputSchema,
  iaSchema,
  acpSchema,
  defRopSchema,
  ropSchema
} from '../../../templates/payoutSchemaTemplates'
import { getMatchingPaymentOption } from '../modelHelpers'
import { getStore } from '../../../store'
// FIXME: Seperate Actions from rules. Make it independednt and usable anywhere
const pfs = {
  A: 'Yearly',
  H: 'Half Yearly',
  Q: 'Quarterly',
  M: 'Monthly',
  S: 'Single'
}

/**
   * This function checks for whole life product by checking the feature category id. If it's 36 then
   * that product is added to the whole life response
   *
   * @param {*} product
   * @returns
   */
const isProductWholeLife = (product) => {
  const isWholeLife = product.productOption.featureCategoryIds.filter(
    (fc) => fc.categoryId === 36
  )
  return isWholeLife.length > 0
}

const isProductFixedTerm = (product) => { // function used to check for fixed term product by checking the feature category id.
  const isFixedTerm = product.productOption.featureCategoryIds.filter(
    (fc) => fc.categoryId === 257
  )

  return isFixedTerm.length > 0
}

/**
   * This function validates the product by the checking the values with min and max values. Basic thing
   * is all the min and max values are used to check the input value. If the input value falls in the range
   * of min and max value then the product is said to valid by changing the valid variable to true
   * NOTE: Don't know how calculations are done
   *
   * @param {*} product
   * @param {*} formData
   * @param {*} productData
   * @param {*} currentPptOption
   * @returns
   */
const checkIfProductValidByPptOption = (
  product,
  formData,
  productData,
  currentPptOption,
  paymentOptions
) => {
  const { productOption } = product
  const isWholeLife = isProductWholeLife(product)
  const { age, sumAssured } = formData
  const { coverTerm, ppt, paymentFrequency } = productData
  if (isNotDefined(productData.paymentOption)) { return false }
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption, paymentOptions)
  const currentPaymentOption = paymentOption !== currentPptOption ? paymentOption : currentPptOption

  const pptOpt = productOption.pptOptions[currentPaymentOption]
  if (isNotDefined(pptOpt)) {
    return false
  }
  let valid = true
  valid = valid && age <= pptOpt.age.maxAge && age >= pptOpt.age.minAge
  if (!isWholeLife) {
    valid =
      valid &&
      coverTerm <= pptOpt.term.maxTerm &&
      coverTerm >= pptOpt.term.minTerm
  }
  if (!isNotDefined(paymentFrequency)) {
    valid = valid && pptOpt.paymentFrequency.indexOf(paymentFrequency) > -1
  }
  if (pptOpt.sumAssuredRange.saMin > 0) {
    valid = valid && sumAssured >= pptOpt.sumAssuredRange.saMin
  }
  if (pptOpt.sumAssuredRange.saMax > 0) {
    valid = valid && sumAssured <= pptOpt.sumAssuredRange.saMax
  }
  if (paymentOption === 'RP' && !isWholeLife) {
    valid =
      valid && coverTerm <= pptOpt.ppt.maxPpt && coverTerm >= pptOpt.ppt.minPpt
  }
  if (!isNotDefined(ppt) && paymentOption !== 'SP' && !isWholeLife) {
    if (!isNotDefined(pptOpt.ppt.maxPpt) && !isNotDefined(pptOpt.ppt.minPpt)) {
      valid = valid & (ppt >= pptOpt.ppt.minPpt)
      valid = valid & (ppt <= pptOpt.ppt.maxPpt)
    }
  }
  if (isNotDefined(pptOpt.premiums) || !isNotEmpty(pptOpt.premiums)) {
    valid = valid && false
  }
  // FIXME: We need to also filter the product list for maturityAge min and max
  // Check if maturityAge satisfies for the product.
  // FIXME: We need to check if each featureCategoryIds that are marked as paid has
  // quotation against it
  // FIXME: Change this
  // if (paymentOption !== 'SP' && productOption.riders.length > 0) {
  //   if (isNotDefined(pptOpt.riderPremiums) || !isNotEmpty(pptOpt.riderPremiums)) {
  //     valid = valid && false
  //   }
  // }
  return valid
}
const checkIfProductValidByPptOptionAnnuity = (
  product,
  formData,
  productData,
  currentPptOption,
  paymentOptions
) => {
  const { productOption } = product
  const { pAge } = formData
  const { paymentFrequency } = productData
  if (isNotDefined(productData.paymentOption)) { return false }
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption, paymentOptions)
  const currentPaymentOption = paymentOption !== currentPptOption ? paymentOption : currentPptOption
  const pptOpt = productOption.pptOptions[currentPaymentOption]
  if (isNotDefined(pptOpt)) {
    return false
  }
  let valid = true
  valid = valid && pAge <= pptOpt.age.maxAge && pAge >= pptOpt.age.minAge
  if (!isNotDefined(paymentFrequency)) {
    valid = valid && pptOpt.paymentFrequency.indexOf(paymentFrequency) > -1
  }

  if (isNotDefined(pptOpt.premiums) || !isNotEmpty(pptOpt.premiums)) {
    valid = false
  }
  return valid
}

const checkIfProductValidByPptOptionGeneral = (
  product,
  formData,
  productData,
  currentPptOption,
  paymentOptions
) => {
  const { productOption } = product
  const { paymentFrequency } = productData
  if (isNotDefined(productData.paymentOption)) { return false }
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption, paymentOptions)
  const currentPaymentOption = paymentOption !== currentPptOption ? paymentOption : currentPptOption
  const pptOpt = productOption.pptOptions[currentPaymentOption]

  if (isNotDefined(pptOpt)) {
    return false
  }
  let valid = true

  if (!isNotDefined(paymentFrequency)) {
    valid = valid && pptOpt.paymentFrequency.indexOf(paymentFrequency) > -1
  }
  if (pptOpt.idvRange.idvMin > 0) {
    valid = valid && pptOpt.idv >= pptOpt.idvRange.idvMin
  }
  if (pptOpt.idvRange.idvMax > 0) {
    valid = valid && pptOpt.idv <= pptOpt.idvRange.idvMax
  }
  return valid
}

const checkIfProductValidByPptOptionHealth = (
  product,
  formData,
  productData,
  currentPptOption,
  paymentOptions
) => {
  const { productOption } = product
  const { paymentFrequency } = productData
  if (isNotDefined(productData.paymentOption)) { return false }
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption, paymentOptions)
  const currentPaymentOption = paymentOption !== currentPptOption ? paymentOption : currentPptOption
  const pptOpt = productOption.pptOptions[currentPaymentOption]

  if (isNotDefined(pptOpt)) {
    return false
  }
  const valid = true
  return valid
}

/**
   * This function checks the validity of the product by passing each paymentOption to the
   * checkIfProductValidByPptOption function.
   *
   * @param {*} product
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const checkIfProductValid = (product, formData, productData, paymentOptions) => {
  if (isNotDefined(product)) {
    return
  }
  const checkIfProductValidByPptOptionFunc = {
    term: checkIfProductValidByPptOption,
    car: checkIfProductValidByPptOptionGeneral,
    bike: checkIfProductValidByPptOptionGeneral,
    annuity: checkIfProductValidByPptOptionAnnuity,
    health: checkIfProductValidByPptOptionHealth
  }
  const { productOption } = product
  // const {pptOptions} = productOption
  const isProductValid = Object.keys(productOption.pptOptions).map(
    (paymentOptionKey) => {
      const checkIfProductValidByPptOptionFuncCall = checkIfProductValidByPptOptionFunc[formData.insuranceType]
      return checkIfProductValidByPptOptionFuncCall(
        product,
        formData,
        productData,
        paymentOptionKey,
        paymentOptions
      )
    }
  )
  return isProductValid.reduce((valid, v) => valid || v, true)
}

/**
   * This function changes the structure of product to product options, which basically flattenedProducts
   * structure
   *
   * @param {*} product
   * @returns
   */
const convertProductToProductOptions = (product) => {
  const flatProducts = []
  if (!isNotDefined(product)) {
    for (let po = 0; po < product.productOptions.length; po++) {
      const productOption = product.productOptions[po]
      const flatProduct = Object.assign({}, product)
      // remove all product Options and add this one
      delete flatProduct.productOptions
      flatProduct.productOption = Object.assign({}, productOption)
      flatProducts.push(flatProduct)
    }
  }
  return flatProducts
}

/**
   * This function first checks the validity of the product by calling checkIfProductValid and
   * calls the configure display function to set all the display values. If product is valid then
   * visible and fullMatch keys are set to true.
   *
   * @param {*} products
   * @param {*} formData
   * @param {*} productData
   * @param {*} filters
   * @param {*} filterConfig
   * @returns
   */
const flattenProducts = (
  products,
  formData,
  productData,
  filters,
  filterConfig,
  paymentOptions
) => {
  if (isNotDefined(products)) {
    return null
  }
  const flattenedProducts = []
  for (let r = 0; r < products.length; r++) {
    let flatProduct = products[r]
    if (checkIfProductValid(flatProduct, formData, productData, paymentOptions)) {
      // configure product for filter
      flatProduct.filter = {
        visible: true,
        fullMatch: true
      }
      // Configure filter will modify product.filter inline.
      flatProduct = configureFilters(
        flatProduct,
        filters.filterData,
        filterConfig.filterPriority
      )
      flattenedProducts.push(flatProduct)
    }
  }

  return flattenedProducts
}

/**
   * This function builds the display values for product using preparePayoutTerms, getRiders, buildRiderFormData,
   * getRiderSchema, getFeatures and getBenefits functions
   *
   * @param {*} flatProduct
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const configureForDisplay = async (flatProduct, formData, productData, paymentOptions) => {
  const paymentOption = getMatchingPaymentOption(flatProduct, productData.paymentOption, paymentOptions)
  flatProduct.productOption.activePayoutTerms = await preparePayoutTerms(
    flatProduct,
    formData,
    productData
  )
  flatProduct.productOption.activeRiders = await getRiders(
    flatProduct,
    formData,
    productData,
    paymentOption
  )
  flatProduct.productOption.activeRiders.forEach((rider) => {
    rider.riderFormData = buildRiderFormData(
      flatProduct,
      rider,
      formData,
      productData
    )
    rider.riderSchemaKey = getSchemaKeyForRider(flatProduct, rider.insurerId)
    rider.riderSchema = getRiderSchema(
      flatProduct,
      rider,
      formData,
      productData,
      paymentOption
    )
    if (rider.hasRiderSchemaUpdated) {
      if (rider.riderSchema.formData.riderSumAssured > rider.riderSchema.schema.properties.riderSumAssured.maximum) {
        rider.riderSchema.formData.riderSumAssured = 0
        rider.riderSumAssured = 0
        rider.riderFormData.riderSumAssured = 0
        rider.riderFormData.sumAssured = 0
        rider.currentPremium.premium = 0
      }
    }
    // validated rider for checked or unchecked riders
    let riderValid = true
    if (
      isNotDefined(rider.currentPremium.premium) ||
      rider.currentPremium.premium === 0 || isNaN(rider.currentPremium.premium)
    ) {
      riderValid = false
    }
    if (!isNotDefined(rider.riderFormData.include) && rider.riderFormData.include) {
      riderValid = true
    }
    rider.riderValid = riderValid
  })

  // Configure features here. All the paidFeatures will already be set by the rider
  // Each rider will anyway call getFeatures
  flatProduct.productOption.featureCategoryIds = await getFeatures(
    flatProduct,
    productData
  )
  flatProduct.productOption.benefits = getBenefits(flatProduct)
  return flatProduct
}

/**
   * Gets premium for frequency
   *
   * @param {*} product
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const getPremium = (product, formData, productData) => {
  const { paymentFrequency } = productData
  const pptOption = getPptOptionForEnquiry(product, formData, productData)
  if (!isNotDefined(pptOption)) {
    const { premiums } = pptOption
    const resultPremium = premiums.find(
      (pre) => pre.frequency === paymentFrequency
    )
    // FIXME: use immer
    return resultPremium
  } else {
    return {}
  }
}

/**
   * Gets the payment option
   *
   * @param {*} product
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const getPptOptionForEnquiry = (product, formData, productData, paymentOptions) => {
  const {
    productOption: { pptOptions }
  } = product
  // let paymentOption = productData.paymentOption || 'RP'
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption || 'RP', paymentOptions)
  // const formDataPpt = productData.ppt
  const pptOption = pptOptions[paymentOption]
  // .filter((ppto) => ppto.paymentOption === paymentOption)
  // .find((ppto) => {
  //   if (paymentOption === 'single') {
  //   // no need to check for max and min ppt
  //     return true
  //   }
  //   const { ppt: { min_ppt, max_ppt } } = ppto[`${paymentOption}Pay`]
  //   if (!isNotDefined(min_ppt) && !isNotDefined(max_ppt)) {
  //     if (formDataPpt >= min_ppt && formDataPpt <= max_ppt) {
  //       return true
  //     }
  //   } else {
  //     return false
  //   }
  // })
  return pptOption
}

/**
   * Gets the payment frequency based on frequency value
   *
   * @param {*} frequencies
   * @param {*} frequency
   * @returns
   */
const getPaymentFrequency = (frequencies, frequency) => {
  if (isArray(frequencies)) {
    return frequencies.find((pf) => pf.value === frequency)
  }
  return frequencies.value === frequency && frequencies
}

/**
   * NOTE: Don't know much about enquiry rules used here
   * This function is not used anywhere
   *
   * @param {*} enquiry
   * @param {*} enquiryRules
   * @returns
   */
const chosenProductEnquiryRules = async (enquiry, enquiryRules) => {
  const enquiryEngine = new Engine(expandRules(enquiryRules), {})
  let disabledFeatures
  try {
    const events = await enquiryEngine.run(enquiry)
    events.forEach((event) => {
      if (event.type === 'disableFeature') {
        disabledFeatures = event.params
      }
      execute(event, {}, {}, enquiry, extraActions)
    })
  } catch (err) {
    // CODE: enquiryRuleEngineError
    throw new CustomErrors.RuleEngineError(
      'smartCovrApi:productHelper:chosenProductEnquiryRules',
      {
        message: err.message,
        code: err.code,
        stack: err.stack
      }
    )
  }
  return { enquiry, disabledFeatures }
}

/**
   * NOTE: Don't know much about product option rules used here
   * This function creates and engine object with poSelectionRules and then changes the nextAction, askForRiders
   * and chosenProductOption keys in the events.
   *
   * @param {*} data
   * @param {*} poSelectionRules
   * @returns
   */
// There should be only one selected product option.
// Event types are nextAction, chosenProductOption and askForRiders
const chosenProductOptionRules = async (data, poSelectionRules) => {
  const enquiryEngine = new Engine(expandRules(poSelectionRules), {})
  let productOptionActions = {}
  try {
    const events = await enquiryEngine.run(data)
    if (events.length === 0) {
    } else if (events.length > 0) {
      productOptionActions = events.reduce((ov, event) => {
        if (event.type === 'chosenProductOption') {
          ov.chosenProductOption = event.params.value
        } else if (event.type === 'askForRiders') {
          ov.askForRiders = true
        } else if (event.type === 'nextAction') {
          ov.nextAction = event.params.value
        }
        return ov
      }, {})
    }
  } catch (err) {
    throw new CustomErrors.RuleEngineError(
      'smartCovrApi:productHelper:chosenProductOptionRules',
      {
        message: err.message,
        code: err.code,
        stack: err.stack
      }
    )
  }
  return productOptionActions
}
// const resetEnquiryForProduct = (product, enquiry) => {
//   const featureCategoryKeys = product.productOptions.featureCategoryIds.map(fcId => ({
//     key: fcId.input.schema.key,
//     properties: fcId.properties
//   }))
//   Object.keys(enquiry).forEach(key => {
//     if (!featureCategoryKeys.has()) {
//       resetEnquiry[key]
//     }
//   })
// }

/**
   * This function builts the descriptions using formatFields function then if payoutTerm has
   * displayRules key then using Engine it runs the rules on dynamic fields.
   *
   * @param {*} payoutTerm
   * @returns
   */
const formatPayoutTerm = async (payoutTerm) => {
  // First format the dynamic fields

  let { descriptions, dynamicFields, displayFields } = payoutTerm
  try {
    descriptions = formatFields(dynamicFields, descriptions, displayFields)
  } catch (err) {
    console.log('Inside Catch = ', err)
  }
  // next run the payout rules engine for rules
  if (payoutTerm.hasOwnProperty('displayRules') && payoutTerm.length > 0) {
    const displayEngine = new Engine(expandRules(payoutTerm.displayRules), {})
    try {
      const displayEvents = await displayEngine.run(dynamicFields)
      displayEvents.forEach((event) => {
        // apply client rules on dynamicFields
        execute(event, {}, {}, descriptions)
      })
    } catch (err) {
      throw new CustomErrors.RuleEngineError(
        'smartCovrApi:productHelper:formatPayoutTerm',
        {
          message: err.message,
          code: err.code,
          stack: err.stack
        }
      )
    }
  }
  Object.assign(payoutTerm, descriptions)
  if (!isNotDefined(displayFields)) {
    displayFields.forEach((ds) => {
      ds.title = formatFields(dynamicFields, ds.title, displayFields)
    })
  }
  return payoutTerm
}

/**
   * This function returns the formattedPayout terms by calling formatPayoutTerm function on each
   * payout term
   *
   * @param {*} product
   * @param {*} userFormData
   * @param {*} productData
   * @returns
   */
const preparePayoutTerms = async (product, userFormData, productData) => {
  // configure the payoutTerms.
  // NOTE that a product can have more than one payoutTerm. We will default pick up the first
  // one for display on card. And then in the review page, provide all options
  if (Object.prototype.hasOwnProperty.call(product.productOption, 'payoutTerms')) {
    const orderedPayoutTerms = []
    const payoutTerms = product.productOption.payoutTerms
    for (let r = 0; r < payoutTerms.length; r++) {
      const payoutTerm = payoutTerms[r]
      payoutTerm.dynamicFields.sumAssured = userFormData?.sumAssured
      // const { calculationRules, payoutRules } = payoutTerm
      // First is payoutRules that is specific for that product
      // if (!isNotDefined(payoutRules) && isNotEmpty(payoutRules)) {
      //   const engine = new Engine(expandRules(payoutRules), {})
      //   try {
      //     const events = await engine.run({ formData: payoutTerm.dynamicFields })
      //     events.forEach(event => {
      //     // apply client rules on dynamicFields
      //       execute(event, {}, {}, payoutTerm.dynamicFields)
      //     })
      //   } catch (err) {
      //     throw new CustomErrors.RuleEngineError('smartCovrApi:productHelper:preparePayoutTerms', {
      //       message: err.message,
      //       code: err.code,
      //       stack: err.stack
      //     })
      //   }
      // }
      // // CalculationRules depend on results of payoutRules. So dont merge them
      // // Then the calculationRules that is generic for all payouts / features
      // if (!isNotDefined(calculationRules) && calculationRules.length > 0) {
      //   const calcEngine = new Engine(expandRules(calculationRules), {})
      //   try {
      //     const calcEvents = await calcEngine.run({ data: payoutTerm.dynamicFields })
      //     calcEvents.forEach((event) => {
      //       execute(event, {}, {}, payoutTerm.dynamicFields)
      //     })
      //   } catch (err) {
      //     throw new CustomErrors.RuleEngineError('smartCovrApi:productHelper:preparePayoutTerms', {
      //       message: err.message,
      //       code: err.code,
      //       stack: err.stack
      //     })
      //   }
      // }
      const formattedPayoutTerm = await formatPayoutTerm(payoutTerm)
      orderedPayoutTerms.push(formattedPayoutTerm)
    }
    return orderedPayoutTerms
  }
  return undefined
}

/**
   * This function builds the descriptions by calling the formatFields function. Then if feature has
   * displayRules key then it runs the engine and applies rules on dynamicFields
   *
   * @param {*} feature
   * @param {*} dynamicFields
   * @returns
   */
const getFormattedFeature = async (feature, dynamicFields) => {
  dynamicFields = dynamicFields || feature.dynamicFields
  let { descriptions, displayFields } = feature
  if (!isNotDefined(displayFields) && isNotEmpty(displayFields)) {
    descriptions = formatFields(dynamicFields, descriptions, displayFields)
  }
  if (
    feature.hasOwnProperty('displayRules') &&
    feature.displayRules.length > 0
  ) {
    const engine = new Engine(expandRules(feature.displayRules), {})
    try {
      const events = await engine.run({ data: dynamicFields })
      events.forEach((event) => {
        // apply client rules on dynamicFields
        execute(event, {}, {}, descriptions)
      })
    } catch (err) {
      throw new CustomErrors.RuleEngineError(
        'smartCovrApi:productHelper:getFormattedFeature',
        {
          message: err.message,
          code: err.code,
          stack: err.stack
        }
      )
    }
  }
  Object.assign(feature, descriptions)
  return feature
}

/**
   * This function first gets the formatted feature in which descriptions are built along with
   * all the display values and then it gets the features based on the pricing
   *
   * @param {*} product
   * @param {*} productData
   * @returns
   */
const getFeatures = async (product, productData) => {
  const { featureCategoryIds } = product.productOption
  const features = []
  try {
    for (const featureCategoryId of featureCategoryIds) {
      const feature = await getFormattedFeature(featureCategoryId)
      // if this feature has a schema and its not a rider
      if (isNotDefined(feature.insureId) && feature.pricing === 'available') {
        // assuming only features linked to riders have insurerId
        if (isNotEmpty(feature.input) && isNotEmpty(feature.input.schema)) {
          feature.input = getFeatureSchema(
            featureCategoryId,
            undefined,
            undefined,
            productData[feature.schemaKey]
          )
        }
      }
      features.push(feature)
    }
  } catch (err) {
    console.log(err)
    throw err
  }
  return features
}

/**
   * This function finds the feature for a rider using riderInsurerId
   *
   * @param {*} product
   * @param {*} riderInsrerId
   * @returns
   */
const getMatchingFeatureForRider = (product, riderInsrerId) => {
  return product.productOption.featureCategoryIds.find(
    (fc) => fc.insurerId === riderInsrerId
  )
}

/**
   * This function calls getMatchingFeatureForRider function to return schemaKey
   *
   * @param {*} product
   * @param {*} riderInsrerId
   * @returns
   */
const getSchemaKeyForRider = (product, riderInsrerId) => {
  const matchingFeature = getMatchingFeatureForRider(product, riderInsrerId)
  if (isNotDefined(matchingFeature.schemaKey)) {
    return null
  } else {
    return matchingFeature.schemaKey
  }
}

/**
   * This function gets the pptOption and then calculates the rider premium
   * premium = (riderSumAssured / 1000) * perThousand * (1 - discount) * rp.discountFactor
   * premiumWithGst = (riderSumAssured / 1000) * perThousandWithGst * (1 - discount) * rp.discountFactor
   * NOTE: Don't knbow much about the calculations used here
   *
   * @param {*} product
   * @param {*} riderInsurerId
   * @param {*} riderSumAssured
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const getRiderPremiums = (
  product,
  riderInsurerId,
  riderSumAssured,
  formData,
  productData,
  dataForRider,
  paymentOptions
) => {
  const pptOption = getPptOptionForEnquiry(product, formData, productData, paymentOptions)
  let riderPremiums
  if (pptOption) {
    riderPremiums = pptOption.riderPremiums.find(
      (rp) => rp.insurerId === riderInsurerId
    )
  }
  if (isNotDefined(riderPremiums)) {
    return
  }
  // FIXME: Applying discount for riderPremiums ..
  let { discount, riderPremium } = riderPremiums
  const features = product.productOption.featureCategoryIds
  const tempArr = []
  features.forEach(item => {
    if (item.hasRiderSchemaUpdated) {
      tempArr.push(item.insurerId)
    }
  })
  const modifiedPremiums = riderPremium.map((rp) => {
    discount = discount || 0
    let perThousand
    let perThousandWithGst
    // const perThousand = Math.round(rp.perThousand * 100) / 100
    // const perThousandWithGst = Math.round(rp.perThousandWithGst * 100) / 100
    if (tempArr.includes(riderInsurerId)) {
      rp.premiumOptions.forEach(rps => {
        if (rps.pt === dataForRider.riderPt) {
          rps.options.forEach(opt => {
            if (opt.ppt === dataForRider.riderPpt) {
              perThousand = opt.perThousand
              perThousandWithGst = opt.perThousandWithGst
            }
          })
        }
      })
    } else {
      discount = discount || 0
      perThousand = rp.perThousand
      perThousandWithGst = rp.perThousandWithGst
    }
    const premium = Math.round(
      (riderSumAssured / 1000) *
      perThousand *
      (1 - discount) *
      rp.discountFactor
    )
    const premiumWithGst = Math.round(
      (riderSumAssured / 1000) *
      perThousandWithGst *
      (1 - discount) *
      rp.discountFactor
    )
    return {
      ...rp,
      premium,
      gst: premiumWithGst - premium,
      premiumWithGst
    }
  })
  return modifiedPremiums
}

/**
   * This function builds the rider object by creating formattedFeature, riderData, premiums
   * and annual premiums.
   *
   * @param {*} product
   * @param {*} formData
   * @param {*} productData
   * @param {*} riderInsurerId
   * @param {*} feature
   * @param {*} dataForRider
   * @returns
   */
const getRider = async (
  product,
  riderInsurerId,
  feature,
  dataForRider,
  formData,
  productData,
  paymentOption
) => {
  const { riders, pptOptions } = product.productOption
  const rider = riders.find((ri) => ri.insurerId === riderInsurerId)
  const { age, insuranceType } = formData
  const { coverTerm, paymentFrequency } = productData
  const riderKey = getSchemaKeyForRider(product, riderInsurerId)
  const riderData = pptOptions[paymentOption].riderPremiums.find(
    (rp) => rp.insurerId === riderInsurerId
  )
  // Rider not supported.
  if (isNotDefined(rider) || isNotDefined(riderData)) {
    return null
  }
  // No premium for this rider.
  if (
    isNotDefined(riderData.riderPremium) ||
    riderData.riderPremium.length === 0
  ) {
    return null
  }
  const { calculations } = feature.input
  let riderSumAssured
  // However if there is a calculation for the rider, do it
  if (!isNotDefined(calculations) && isNotEmpty(calculations)) {
    const calcData = Object.assign({}, feature.dynamicFields, dataForRider)
    const riderSaCalculation = {}
    calculations.forEach((calc) => {
      const result = Object.keys(calc)[0]
      riderSaCalculation[result] = Math.round(
        calculate(calc[result].format({ [riderKey]: calcData }))
      )
    })
    riderSumAssured = riderSaCalculation.riderSumAssured
    // assign all calculation data to dynamicFields for formatting
    Object.keys(riderSaCalculation).forEach((ky) => {
      // except for riderSumAssured
      if (ky !== riderSumAssured) {
        feature.dynamicFields[ky] = riderSaCalculation[ky]
      }
    })
  } else if (!isNotDefined(dataForRider.riderSumAssured)) {
    riderSumAssured = dataForRider.riderSumAssured
  } else {
    // Usually for Waiver of Premium
    riderSumAssured = dataForRider.sumAssured
  }

  let hasRiderPaidWithAlreadyCalculatedPremium = false
  if (!feature.input.hasOwnProperty('schema') || !feature.input.schema.hasOwnProperty('properties')) {
    hasRiderPaidWithAlreadyCalculatedPremium = true
  }
  let hasRiderPtandRiderPpt = false
  if (feature.hasRiderSchemaUpdated) {
    hasRiderPtandRiderPpt = true
  }
  let hasPremiumWillNotShown = false
  if (['car', 'bike', 'health'].includes(insuranceType)) {
    hasPremiumWillNotShown = true
  }

  // to set maxPt and maxPpt value for ci/ib
  let maxPt = 0
  let maxPpt = 0

  if (feature.hasRiderSchemaUpdated && (dataForRider.riderPt === 0 && dataForRider.riderPpt === 0)) {
    if (hasRiderPtandRiderPpt) {
      riderData.riderPremium.forEach(rp => {
        if (rp.hasOwnProperty('premiumOptions')) {
          rp.premiumOptions.forEach(obj => {
            if (obj.pt > maxPt) {
              maxPt = obj.pt
            }
          })
        }
      })
      dataForRider.riderPt = maxPt

      riderData.riderPremium.forEach(rp => {
        if (rp.hasOwnProperty('premiumOptions')) {
          rp.premiumOptions.forEach(obj => {
            if (obj.pt === maxPt) {
              obj.options.forEach(opt => {
                if (opt.ppt > maxPpt) {
                  maxPpt = opt.ppt
                }
              })
            }
          })
        }
      })
      dataForRider.riderPpt = maxPpt
    }
  }
  if (feature.hasRiderSchemaUpdated && (dataForRider.riderPt !== 0 || dataForRider.riderPpt !== 0)) {
    if (hasRiderPtandRiderPpt) {
      const tempArr = []
      riderData.riderPremium.forEach(rp => {
        if (rp.hasOwnProperty('premiumOptions')) {
          rp.premiumOptions.forEach(obj => {
            tempArr.push(obj.pt)
            if (tempArr.includes(dataForRider.riderPt)) {
              maxPt = dataForRider.riderPt
              maxPpt = dataForRider.riderPpt
            } else {
              maxPt = Math.max(obj.pt)
            }
          })
        }
      })
    }
  }
  let currentPremium
  if (paymentOption === 'single') {
    currentPremium = riderData.riderPremium.find((rp) => rp.frequency === 'S')
  } else {
    currentPremium = riderData.riderPremium.find(
      (rp) => {
        if (rp.frequency === paymentFrequency) {
          if (Object.prototype.hasOwnProperty.call(rp, 'premiumOptions')) { // if rp contains premiumOptions then continue
            rp.premiumOptions.forEach(rps => {
              if (dataForRider.riderPt === rps.pt) {
                rps.options.forEach(opt => {
                  if (dataForRider.riderPpt === opt.ppt) {
                    rp.premium = Math.round(opt.premium)
                    return rp
                  }
                })
              }
            })
          }
          return rp
        }
      }
    )
  }

  rider.pptOptions[paymentOption] = riderData
  let annualPremium
  if (paymentFrequency === 'S') {
    annualPremium = riderData.riderPremium.find((rp) => rp.frequency === 'S')
  } else {
    annualPremium = riderData.riderPremium.find((rp) => rp.frequency === 'A')
  }
  let formattedFeature
  try {
    if (!isNotDefined(age) && !isNotDefined(coverTerm)) {
      formattedFeature = await getFormattedFeature(
        feature,
        Object.assign({}, dataForRider, feature.dynamicFields, {
          age,
          coverTerm,
          sumAssured: dataForRider.sumAssured,
          riderSumAssured
        })
      )
    } else {
      formattedFeature = await getFormattedFeature(
        feature,
        Object.assign({}, dataForRider, feature.dynamicFields, {
          sumAssured: dataForRider.sumAssured,
          riderSumAssured
        })
      )
    }
  } catch (err) {
    console.log(err)
    throw err
  }

  let riderTerm
  if (coverTerm) {
    riderTerm = Math.min(riderData.term.maxTerm, coverTerm)
  }
  return Object.assign(
    {},
    {
      description: formattedFeature.description,
      descriptionStatic: formattedFeature.descriptionStatic,
      descriptionPiped: formattedFeature.descriptionPiped,
      heading: formattedFeature.heading,
      points: formattedFeature.points,
      premiums: riderData.riderPremium,
      pptOptions: rider.pptOptions,
      currentPremium,
      annualPremium,
      riderSumAssured,
      riderId: rider.riderId,
      riderInsurerId: rider.insurerId,
      riderUIN: rider.riderUIN && rider.riderUIN.UIN,
      insurerId: rider.insurerId,
      productDescription: rider.productDescription,
      riderBrochureUrl: rider.riderBrochureUrl,
      riderName: rider.riderName,
      shortName: rider.riderShortName,
      riderUrl: rider.riderUrl,
      categoryId: rider.categoryId,
      riderTerm,
      hasRiderSchemaUpdated: feature.hasRiderSchemaUpdated,
      hasRiderPaidWithAlreadyCalculatedPremium,
      maxRiderPt: maxPt,
      maxRiderPpt: maxPpt,
      hasPremiumWillNotShown
    }
  )
}

/**
   * Calls getRider function if feature is not "free", logic applied on all rider insurer ids. Before calling
   * it gets all the required parameters like dataForRider, riderInsurerId etc
   *
   * @param {*} product
   * @param {*} formData
   * @param {*} productData
   * @param {*} riderInsurerIds
   * @returns
   */
const getRiders = async (
  product,
  formData,
  productData,
  paymentOption,
  riderInsurerIds = []
) => {
  // const { paymentOption } = productData
  // Pick up rider ids that apply for that pptOption
  const allRiderInsurerIds = product.productOption.pptOptions[paymentOption].riderPremiums.map((rp) => rp.insurerId)
  if (riderInsurerIds.length === 0) {
    riderInsurerIds = allRiderInsurerIds
  }
  const activeRiders = []
  for (const riderInsurerId of allRiderInsurerIds) {
    const feature = getMatchingFeatureForRider(product, riderInsurerId)
    // No Pricing for Free riders
    if (feature.pricing !== 'free') {
      const riderSchemaKey = getSchemaKeyForRider(product, riderInsurerId)
      const dataForRider = Object.assign({}, productData[riderSchemaKey])
      if (formData.hasOwnProperty('sumAssured')) {
        dataForRider.sumAssured = formData.sumAssured
      }
      // FIXME: Sending both data for rider and product data for bug fix. Sort this out
      try {
        const rider = await getRider(
          product,
          riderInsurerId,
          feature,
          dataForRider,
          formData,
          productData,
          paymentOption
        )
        rider && activeRiders.push(rider)
      } catch (err) {
        console.log(err)
        throw err
      }
    }
  }
  return activeRiders
}

/**
   * This function builds the rider form data with riderSumAssured
   *
   * @param {*} product
   * @param {*} rider
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const buildRiderFormData = (product, rider, formData, productData) => {
  const enquiryDataKey = getSchemaKeyForRider(product, rider.insurerId)
  const riderFormData = Object.assign({}, productData[enquiryDataKey])
  const { sumAssured } = formData
  if (formData.insuranceType !== 'health' && !isNotDefined(sumAssured)) {
    riderFormData.sumAssured = sumAssured
  }
  // // In some cases, riderSumAssured is a calculated field and we need to populate it here.
  // So that in the UI rider value is shown
  if (formData.insuranceType !== 'health' && isNotDefined(riderFormData.riderSumAssured)) {
    riderFormData.riderSumAssured = rider.riderSumAssured
  }
  return riderFormData
}

// THIS FUCNTION IS NOT GETTING USED ANYWHERE
const getSumAssuredRange = (sumAssured, pptOption) => {
  let sumAssuredMin
  let sumAssuredMax
  const { sumAssuredRange } = pptOption
  if (sumAssuredRange.sa_min > 0) {
    sumAssuredMin = sumAssuredRange.sa_min
  } else if (sumAssuredRange.min_percent_of_sa) {
    sumAssuredMin = sumAssured * sumAssuredRange.min_percent_of_sa
  } else {
    sumAssuredMin = 0
  }
  if (sumAssuredRange.sa_max > 0) {
    sumAssuredMax = sumAssuredRange.sa_max
  } else if (sumAssuredRange.max_percent_of_sa) {
    sumAssuredMin = sumAssured * sumAssuredRange.max_percent_of_sa
  } else {
    sumAssuredMax = sumAssured
  }
  return {
    sumAssuredMin,
    sumAssuredMax
  }
}

/**
*
1. NB Comprehensive - 0,1 or 3
2. NB ThirdParty - 0 or 3
3. RO/UC Comprehensive/ThirdParty - 0,1
 */
const getSchemaForGen = (key, draftInput, pptOption) => {
  const newDraftInput = cloneDeep(draftInput)
  const { schema, uiSchema } = newDraftInput

  if (key === 'cpaYear' && isNotEmpty(pptOption[key])) {
    schema.properties[key] = {
      ...schema.properties[key],
      enum: pptOption[key],
      enumNames: pptOption[key].map(year => year <= 1 ? `${year} Year` : `${year} Years`),
      default: Math.max(...pptOption[key])
    }
  }
  return {
    uiSchema,
    schema
  }
}

const getSchemaForHealth = (key, draftInput, matchingFeature) => {
  const newDraftInput = cloneDeep(draftInput)
  const { schema, uiSchema } = newDraftInput
  const newSchema = {
    ...schema
  }
  const newUiSchema = {
    ...uiSchema,
    'ui:order': []
  }
  if (['hc', 'ci', 'icmi', 'pa'].includes(schema.key) && !isNotDefined(matchingFeature.dynamicFields.saRange)) {
    const riderSchema = {
      ...schema.properties[key],
      enum: matchingFeature.dynamicFields.saRange,
      enumNames: matchingFeature.dynamicFields.saRange.map(sa => `₹ ${rupeeFormatter(sa)}`)
    }
    if (!isNotDefined(matchingFeature.dynamicFields.members)) {
      matchingFeature.dynamicFields.members.forEach(relation => {
        newSchema.properties[relation] = {
          type: 'object'
        }
        newSchema.properties[relation].properties = {
          ...newSchema.properties[relation].properties,
          [key]: { ...riderSchema }
        }
        newUiSchema[relation] = {
          [key]: { ...uiSchema[key] }
        }
        newUiSchema['ui:order'].push(relation)
      })
      delete newSchema.properties[key]
      delete newUiSchema[key]
      return {
        newUiSchema,
        newSchema
      }
    } else {
      schema.properties[key] = riderSchema
    }
  }

  if (['cd', 'ru', 'ursi', 'sncb'].includes(schema.key) && !isNotDefined(matchingFeature.dynamicFields.members)) {
    matchingFeature.dynamicFields.members.forEach(relation => {
      newSchema.properties[relation] = {
        type: 'object',
        ...newSchema.properties[relation]
      }
      newSchema.properties[relation].properties = {
        ...newSchema.properties[relation].properties,
        [key]: { ...schema.properties[key] }
      }
      newUiSchema[relation] = {
        ...newUiSchema[relation],
        [key]: { ...uiSchema[key] }
      }
      newUiSchema['ui:order'].push(relation)
    })
    delete newSchema.properties[key]
    delete newUiSchema[key]
    delete newSchema.required
    return {
      newSchema,
      newUiSchema
    }
  }

  return {
    uiSchema,
    schema
  }
}

/**
   * This function creates the feature schema, rules and formData with some calculations. This also builds
   * min and max values for schema fields.
   * Maximum values is calculated by choosing the min values between  pptOption.saMax and value2
   * value2 = value1 / (perThousand * paymentFrequency])) * 1000
   * value1 = (maxPercentOfBase * premium) / 100
   * NOTE: Don't know much about calculations used here
   *
   * @param {*} matchingFeature
   * @param {*} pptOption
   * @param {*} sumAssured
   * @param {*} featureValues
   * @param {*} productPptOption
   * @param {*} productData
   * @param {*} riderModals
   * @returns
   */
const getFeatureSchema = (
  matchingFeature,
  pptOption,
  userFormData,
  featureValues,
  productPptOption,
  productData,
  riderModals
) => {
  const draftInput = JSON.parse(JSON.stringify(matchingFeature.input))
  // Object.assign({}, matchingFeature.input)
  const formData = {}
  if (isNotDefined(matchingFeature.input.schema) || isNotDefined(matchingFeature.input.schema.properties)) {
    if (!isNotDefined(matchingFeature.input.schema) && !isNotDefined(matchingFeature.input.schema.key) && isNotDefined(featureValues[matchingFeature.input.schema.key])) {
      formData[matchingFeature.input.schema.key] = featureValues[matchingFeature.input.schema.key]
    }
    return
  }
  Object.keys(matchingFeature.input.schema.properties).map((ky) => {
    if (!isNotDefined(featureValues)) {
      formData[ky] = featureValues[ky]
      if (ky === 'riderSumAssured' && !isNotDefined(pptOption)) {
        // const riderSaRange = getSumAssuredRange(sumAssured, pptOption)
        draftInput.schema.properties[ky].minimum = pptOption.saMin ?? draftInput.schema.properties[ky].minimum
        if (isNotEmpty(productPptOption) && isNotEmpty(productData)) {
          if (productPptOption.hasOwnProperty('defaultPayoutTerm') && productPptOption.hasOwnProperty('premiums')) {
            const productPremiums = getPremiumForPayoutTerm(
              productData,
              productPptOption.premiums[
                `POT_${productPptOption.defaultPayoutTerm}`
              ],
              productPptOption.defaultPayoutTerm
            )
            const premium = productPremiums.find(
              (pre) => pre.frequency === productData.paymentFrequency
            )
            const riderPremium = pptOption.riderPremium.find(
              (riderPre) => riderPre.frequency === productData.paymentFrequency
            )
            const value1 = (pptOption.maxPercentOfBase * premium.premium) / 100
            const value2 =
              Math.round(
                value1 /
                (riderPremium.perThousand *
                  riderModals[productData.paymentFrequency])
              ) * 1000
            draftInput.schema.properties[ky].maximum = Math.min(
              pptOption.saMax,
              value2
            )
          }
        } else {
          draftInput.schema.properties[ky].maximum = pptOption.saMax ?? draftInput.schema.properties[ky].maximum
        }
      } else if (isNotEmpty(matchingFeature.dynamicFields)) {
        draftInput.schema.properties[ky].minimum =
          matchingFeature.dynamicFields[
            draftInput.schema.properties[ky].minimum
          ] ?? draftInput.schema.properties[ky].minimum
        draftInput.schema.properties[ky].maximum =
          matchingFeature.dynamicFields[
            draftInput.schema.properties[ky].maximum
          ] ?? draftInput.schema.properties[ky].maximum
        // only non hidden fields are required
        draftInput.schema.required = Object.keys(draftInput.uiSchema).filter(
          (ky) => {
            return (
              ky !== 'ui:order' &&
              draftInput.uiSchema[ky]['ui:widget'] !== 'hidden'
            )
          }
        )
      }
      if (['car', 'bike'].includes(userFormData.insuranceType)) {
        const { schema, uiSchema } = getSchemaForGen(ky, draftInput, pptOption)
        draftInput.schema = schema
        draftInput.uiSchema = uiSchema
      }
      if (userFormData.insuranceType === 'health') {
        const { newSchema, newUiSchema, uiSchema, schema } = getSchemaForHealth(ky, draftInput, matchingFeature)
        draftInput.schema = newSchema ?? schema
        draftInput.uiSchema = newUiSchema ?? uiSchema
      }
    }
    draftInput.formData = formData
    draftInput.rules = draftInput.rules || []
  })
  return draftInput
}

const getFeatureSchema_V2 = (
  matchingFeature,
  pptOption,
  userFormData,
  featureValues,
  productPptOption,
  productData,
  riderModals,
  riderSchemaKey,
  rider,
  product
) => {
  const { maxRiderPt, maxRiderPpt } = rider
  const draftInput = JSON.parse(JSON.stringify(matchingFeature.input))
  // Object.assign({}, matchingFeature.input)
  const formData = {}
  Object.keys(matchingFeature.input.schema.properties).map((ky) => {
    if (!isNotDefined(featureValues[ky])) {
      formData[ky] = featureValues[ky]
      if (ky === 'riderSumAssured' && !isNotDefined(pptOption)) {
        // const riderSaRange = getSumAssuredRange(sumAssured, pptOption)
        draftInput.schema.properties[ky].minimum = pptOption.saMin
        if (isNotEmpty(productPptOption) && isNotEmpty(productData)) {
          const productPremiums = getPremiumForPayoutTerm(
            productData,
            productPptOption.premiums[`POT_${productPptOption.defaultPayoutTerm}`],
            productPptOption.defaultPayoutTerm
          )
          const premium = productPremiums.find(
            (pre) => pre.frequency === productData.paymentFrequency
          )
          let totalRiderPremium = 0
          product.productOption.activeRiders.forEach(element => {
            if (!element.hasRiderSchemaUpdated) {
              if (element.currentPremium.premium > 0) {
                totalRiderPremium = totalRiderPremium + element.currentPremium.premium
              }
            }
          })
          const riderPremium = pptOption.riderPremium.find(
            (riderPre) => riderPre.frequency === productData.paymentFrequency
          )
          // changes
          let perThousand
          riderPremium.premiumOptions.forEach(obj => {
            if (maxRiderPt === obj.pt) {
              obj.options.forEach(opt => {
                if (maxRiderPpt === opt.ppt) {
                  perThousand = opt.perThousand
                }
              })
            }
          })

          const value1 = (pptOption.maxPercentOfBase * (premium.premium + totalRiderPremium)) / 100
          const value2 =
            Math.round(
              value1 /
              (perThousand *
                riderModals[productData.paymentFrequency])
            ) * 1000
          draftInput.schema.properties[ky].maximum = Math.min(
            pptOption.saMax,
            value2
          )
        } else {
          draftInput.schema.properties[ky].maximum = pptOption.saMax
        }
      }
      if (ky === 'riderPt') {
        const riderPtEnum = []
        const riderPtEnumNames = []

        pptOption.riderPremium.map(premiumObj => {
          premiumObj.premiumOptions.map(obj => {
            if (!riderPtEnum.includes(obj.pt)) {
              riderPtEnum.push(obj.pt)

              riderPtEnumNames.push(`${obj.pt} years`)
            }
          })
        })
        draftInput.schema.properties[ky].enum = riderPtEnum
        draftInput.schema.properties[ky].enumNames = riderPtEnumNames
        draftInput.schema.properties[ky].default = maxRiderPt
      }
      if (ky === 'riderPpt') {
        const riderPptEnum = []
        const riderPptEnumNames = []
        pptOption.riderPremium.map(premiumObj => {
          premiumObj.premiumOptions.map(obj => {
            if (obj.pt === maxRiderPt) { // to show only eligible ppt for a pt
              obj.options.map(pptObj => {
                if (!riderPptEnum.includes(pptObj.ppt)) {
                  riderPptEnum.push(pptObj.ppt)

                  riderPptEnumNames.push(`${pptObj.ppt} years`)
                }
              })
            }
          })
        })
        draftInput.schema.properties[ky].enum = riderPptEnum
        draftInput.schema.properties[ky].enumNames = riderPptEnumNames
        draftInput.schema.properties[ky].default = maxRiderPpt
      }
    }
    draftInput.formData = formData
    draftInput.rules = draftInput.rules || []
  })
  return draftInput
}

/**
   * This function builds the schema, uiSchema, formData and rules for rider by calling the getFeatureSchema
   * function
   *
   * @param {*} product
   * @param {*} rider
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const getRiderSchema = (product, rider, formData, productData, paymentOption) => {
  // const { sumAssured } = formData
  // const { paymentOption } = productData

  if (isNotDefined(rider)) {
    return {
      schema: {},
      uiSchema: {},
      fromData: {},
      rules: []
    }
  }
  const pptOption = rider.pptOptions[paymentOption]

  const productPptOption = product.productOption.pptOptions[paymentOption]
  const riderSchemaKey = getSchemaKeyForRider(product, rider.insurerId)
  if (isNotDefined(pptOption) && isNotDefined(riderSchemaKey)) {
    return {
      schema: {},
      uiSchema: {},
      fromData: {},
      rules: []
    }
  } else {
    const matchingFeature = getMatchingFeatureForRider(product, rider.insurerId)

    if (rider.hasRiderSchemaUpdated) {
      return getFeatureSchema_V2(
        matchingFeature,
        pptOption,
        formData,
        productData[riderSchemaKey],
        productPptOption,
        productData,
        product.riderModals,
        riderSchemaKey,
        rider,
        product
      )
    } else {
      return getFeatureSchema(
        matchingFeature,
        pptOption,
        formData,
        productData[riderSchemaKey],
        productPptOption,
        productData,
        product.riderModals
      )
    }
  }
}

/**
   * This function returns the riderSumAssured after calculation. Calculation is function is imported from
   * utils library to calculate the riderSumAssured
   * NOTE: Don't know much about the calculations
   *
   * @param {*} product
   * @param {*} riderInsurerId
   * @param {*} feature
   * @param {*} dataForRider
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const getRiderSumAssured = ({
  product,
  riderInsurerId,
  feature,
  dataForRider,
  formData,
  productData,
  paymentOptions
}) => {
  const rider = product.productOption.riders.find(
    (ri) => ri.insurerId === riderInsurerId
  )
  // const { paymentOption } = productData
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption, paymentOptions)
  const pptOption = rider.pptOptions[paymentOption]
  if (isNotDefined(rider) || isNotDefined(pptOption)) {
    return null
  }
  const {
    calculations,
    schema: { key }
  } = feature.input
  let riderSumAssured
  // However if there is a calculation for the rider, do it
  if (!isNotDefined(calculations) && isNotEmpty(calculations)) {
    const riderKey = getSchemaKeyForRider(product, rider.insurerId)
    const calcData = Object.assign({}, feature.dynamicFields, dataForRider)
    const riderSaCalculation = {}
    calculations.forEach((calc) => {
      const result = Object.keys(calc)[0]
      riderSaCalculation[result] =
        Math.round(
          calculate(calc[result].format({ [riderKey]: calcData })) * 100
        ) / 100
    })
    riderSumAssured = riderSaCalculation.riderSumAssured
  } else if (!isNotDefined(dataForRider.riderSumAssured)) {
    riderSumAssured = dataForRider.riderSumAssured
  } else {
    // Usually for Waiver of Premium
    riderSumAssured = dataForRider.sumAssured
  }
  return { riderSumAssured, key }
}

/**
   * This function calls getRiderPremiums function and returns the current premium based on the
   * payment frequency
   *
   * @param {*} product
   * @param {*} riderInsurerId
   * @param {*} riderSumAssured
   * @param {*} formData
   * @param {*} productData
   * @returns
   */
const calcRiderPremium = (
  product,
  riderInsurerId,
  riderSumAssured,
  formData,
  productData,
  dataForRider,
  paymentOptions
) => {
  const { paymentFrequency } = productData
  const riderPremiums = getRiderPremiums(
    product,
    riderInsurerId,
    riderSumAssured,
    formData,
    productData,
    dataForRider,
    paymentOptions
  )
  const currentPremium = riderPremiums.find(
    (rp) => rp.frequency === paymentFrequency
  )
  return currentPremium
}

/**
   * Builds the content for each benefit. There is a special condition of categoryId 5 in which if
   * client is online itemDescriptionOnline is used and if it's agent then itemDescriptionAgent is
   * used
   *
   * @param {*} product
   * @returns
   */
const getBenefits = (product) => {
  const { benefitCategoryIds } = product
  const { configure } = getStore().getState()
  const { apiman } = configure
  return benefitCategoryIds.map((benefit) => {
    if (benefit.categoryId === 5) {
      const client = apiman.clientId // config.get('apiman.clientId')
      benefit.description = benefit.dynamicFields.itemDescription
      if (isNotDefined(benefit.description)) {
        if (client === 'online') {
          benefit.description = benefit.dynamicFields.itemDescriptionOnline
        } else if (client === 'agent') {
          benefit.description = benefit.dynamicFields.itemDescriptionAgent
        }
      }
    }
    benefit.heading = benefit.dynamicFields.heading
    benefit.descriptionStatic = benefit.dynamicFields.itemDescriptionStatic
    benefit.points = null
    return benefit
  })
}

/**
   * Return the productName, shortName and productOptionName
   *
   * @param {*} product
   * @returns
   */
const getProductName = (product) => {
  const { productOption } = product
  return {
    productName: product.productName,
    shortName: product.shortName,
    productOptionName: productOption.productOptionBasics.productOptionName
  }
}

/**
   * This function builds paymentOptionSchema by preparing the structure of the schema properties and
   * adding the values for enum, enumNames, min and max values from pptOptions paramer and currectFormData,
   * along with the schema imported from '../../../templates/paymentOptionSchema'
   *
   * @param {*} pptOptions
   * @param {*} currentFormData
   * @param {*} currentProductData
   * @returns
   */
const buildPaymentOptionSchema = (
  pptOptions,
  currentFormData,
  currentProductData
) => {
  const oneOff = {
    properties: {
      paymentOption: {
        enum: []
      },
      paymentFrequency: {
        title: 'Please select the payment frequency',
        type: 'string',
        enum: [],
        enumNames: []
      }
    },
    required: ['paymentFrequency']
  }
  const paymentOptionSchema = JSON.parse(JSON.stringify(schema))
  // Only Limited has a problem.Lets fill it at the last.
  // for now, we need to figure if the limited is a range or selection of values
  const { paymentOption, coverTerm, paymentFrequency } = currentProductData
  const paymentFormData = {
    paymentOption,
    ppt: coverTerm,
    paymentFrequency
  }
  const properties = paymentOptionSchema.properties.paymentOption
  const dependencies = paymentOptionSchema.dependencies.paymentOption.oneOf
  const pptOptionKeys = Object.keys(pptOptions)
  // Need to sort for limited Pay.
  let sortedpptOptionKeys = []
  const lpPptOptionKeys = pptOptionKeys.filter((pk) => pk.startsWith('L')).sort((lp1, lp2) => {
    return parseInt(lp1.replace('L', '')) - parseInt(lp2.replace('L', ''))
  })
  if (pptOptionKeys.includes('SP')) {
    sortedpptOptionKeys.push('SP')
  }
  sortedpptOptionKeys = sortedpptOptionKeys.concat(lpPptOptionKeys)
  const taKey = pptOptionKeys.find((key) => key.startsWith('TA'))
  if (taKey) {
    sortedpptOptionKeys.push(taKey)
  }
  if (pptOptionKeys.includes('RP')) {
    sortedpptOptionKeys.push('RP')
  }
  sortedpptOptionKeys.forEach((paymentOption) => {
    const { paymentFrequency, ppt, term } = pptOptions[paymentOption]
    if (paymentOption === 'RP') {
      // FIXME: Hack for whole life. We need to have till age
      let thisCoverTerm
      if (
        term.minTerm === term.maxTerm &&
        term.minTerm === currentProductData.coverTerm
      ) {
        // this is whole life
        thisCoverTerm = ppt.maxPpt
      } else {
        thisCoverTerm = currentProductData.coverTerm
      }
      properties.enum.push(paymentOption)
      properties.enumNames.push(`Pay for the next ${thisCoverTerm} years`)
      if (currentFormData.insuranceType !== 'health') {
        oneOff.properties.paymentOption.enum.push(paymentOption)
        oneOff.properties.paymentFrequency.enum = paymentFrequency
        oneOff.properties.paymentFrequency.enumNames = paymentFrequency.map(
          (pf) => pfs[pf]
        )
        dependencies.push(oneOff)
      }
    } else if (paymentOption === 'SP') {
      properties.enum.push(paymentOption)
      properties.enumNames.push('Pay all premiums in one go')
    } else if (paymentOption.startsWith('TA')) {
      const { maxPpt } = ppt
      properties.enum.push(paymentOption)
      properties.enumNames.push(
        `Pay till you are ${currentFormData.age + maxPpt} years`
      )
      oneOff.properties.paymentOption.enum.push(paymentOption)
      oneOff.properties.paymentFrequency.enum = paymentFrequency
      oneOff.properties.paymentFrequency.enumNames = paymentFrequency.map(
        (pf) => pfs[pf]
      )
      dependencies.push(oneOff)
    } else if (paymentOption.startsWith('L')) {
      const { maxPpt } = ppt
      // if (min_ppt === max_ppt) {
      properties.enumNames.push(`Pay for the next ${maxPpt} years only`)
      properties.enum.push(paymentOption)
      oneOff.properties.paymentOption.enum.push(paymentOption)
      // } else if (max_ppt > min_ppt) {
      //   isLimitedRange = true
      //   properties.enumNames.push('Choose the period myself')
      //   properties.enum.push(`${paymentOption}:range`)
      //   oneOff.properties.paymentOption.enum.push(`${paymentOption}:range`)
      //   // FIXME: Allow user to select.
      //   oneOff.properties.ppt = {
      //     title: `Enter Payment period (${min_ppt} years to ${max_ppt} years)`,
      //     type: 'number',
      //     minumum: min_ppt,
      //     maximum: max_ppt,
      //     enum: range(min_ppt, max_ppt + 1),
      //     enumNames: range(min_ppt, max_ppt + 1).map(r => `${r} Years`)
      //   }
      //      }
      oneOff.properties.paymentFrequency.enum = paymentFrequency
      oneOff.properties.paymentFrequency.enumNames = paymentFrequency.map(
        (pf) => pfs[pf]
      )
      dependencies.push(oneOff)
    }
  })
  return {
    schema: paymentOptionSchema,
    uiSchema,
    paymentFormData
  }
}

/**
   * This function calculates premium discounts by applying calculations
   * NOTE: Don't have much idea about the calculations
   *
   * @param {*} premium
   * @param {*} modals
   * @param {*} GST
   * @param {*} paymentFrequency
   * @param {*} isRider
   * @returns
   */
const applyModalDiscounting = (
  premium,
  modals,
  GST,
  paymentFrequency,
  isRider
) => {
  modals.S = 1
  const discountedPremiums = []
  if (!paymentFrequency.includes('S')) {
    paymentFrequency = paymentFrequency.concat('S')
  }
  paymentFrequency.forEach((frequency) => {
    const discountedPremium = {
      frequency,
      discountFactor: modals[frequency],
      frequencyDisplay: pfs[frequency]
    }
    if (isRider) {
      discountedPremium.perThousand = premium * modals[frequency]
      discountedPremium.perThousandWithGst =
        premium * modals[frequency] * (1 + GST)
    } else {
      discountedPremium.premium =
        Math.round(premium * modals[frequency] * 100) / 100
      discountedPremium.premiumWithGst =
        Math.round(premium * modals[frequency] * (1 + GST) * 100) / 100
    }
    discountedPremiums.push(discountedPremium)
  })

  return discountedPremiums
}
const applyModalDiscounting_V2 = (
  premium,
  modals,
  GST,
  paymentFrequency,
  isRider
) => {
  modals.S = 1
  const discountedPremiums = []
  if (!paymentFrequency.includes('S')) {
    paymentFrequency = paymentFrequency.concat('S')
  }
  paymentFrequency.forEach((frequency) => {
    const discountedPremium = {
      frequency,
      discountFactor: modals[frequency],
      frequencyDisplay: pfs[frequency]
    }
    const temp = cloneDeep(premium)
    discountedPremium.premiumOptions = temp.map((premiumObject) => {
      premiumObject.options.map(opt => {
        opt.premium = Math.round(opt.premium * modals[frequency] * 100) / 100
        opt.premiumWithGst = Math.round(opt.premium * modals[frequency] * (1 + GST) * 100) / 100
        const perThousandWithGst = opt.perThousand * (1 + GST / 100)
        opt.perThousandWithGst = perThousandWithGst
        return opt
      })
      return premiumObject
    })
    discountedPremiums.push(discountedPremium)
  })
  return discountedPremiums
}

const applyModalDiscountingForAnnuity = (
  premium,
  payoutModals,
  GST,
  product,
  productData,
  payoutFrequency,
  formData,
  paymentModals
) => {
  const discountedPremiums = []
  if (!payoutFrequency.includes('S')) {
    payoutFrequency = payoutFrequency.concat('S')
  }
  payoutFrequency.forEach((frequency) => {
    const discountedPremium = {
      frequency,
      discountFactor: payoutModals[frequency],
      frequencyDisplay: pfs[frequency]
    }
    discountedPremium.annuityPayout =
      Math.round(premium.ap * payoutModals[frequency] * 100) / 100
    discountedPremium.purchasePrice = Math.round(premium.pp)
    discountedPremiums.push(discountedPremium)
  })
  return discountedPremiums
}

/**
   * This function merges the quotes data to the product metadata
   *
   * @param {*} productForInstitution
   * @param {*} premiums
   * @param {*} formData
   * @param {*} productData
   * @param {*} features
   * @param {*} payouts
   * @param {*} featureContent
   * @returns
   */
// NEED IMMER HERE to build new objects
const insertQuoteIntoProducts = (
  productForInstitution,
  premiums,
  formData,
  productData,
  features,
  payouts,
  featureContent
) => {
  const { age, insuranceType } = formData
  const { coverTerm } = productData
  const productsWithPreium = []
  const {
    insurerId,
    modals,
    riderModals,
    GST,
    payoutModals,
    paymentModals,
    ...productOptionsData
  } = premiums
  if (isNotDefined(productForInstitution)) {
    return [] // to check if productForInstitution is defined #to fix flickering bug.
  }
  const relevantProducts = productForInstitution.filter(
    (p) => p.insurerId === insurerId
  )
  relevantProducts.forEach((product) => {
    const { pptOptionsTemplate, productOption } = product
    const productOptionInsurerId = productOption.insurerId
    if (premiums.hasOwnProperty(productOptionInsurerId)) {
      // Array of premiums for different payment & payoutOptions
      const {
        featureCategoryIds,
        payoutTerms,
        pptOptions,
        calculatedCoverTerm,
        eligibleCoverUpto
      } = productOptionsData[productOptionInsurerId]
      // pptOptions is not an array build the product here.
      // Object.assign(product.productOption.pptOptions, pptOptions)
      product.productOption.featureCategoryIds = featureCategoryIds.map(
        (fcId) => {
          const {
            categoryId,
            pricing,
            insurerId,
            schemaKey,
            ...dynamicFields
          } = fcId
          const featureType = features.find(
            (fe) => fe.categoryId === categoryId
          )
          const feature = Object.assign(
            {},
            featureType,
            featureContent[`CATID_${categoryId}`]
          )
          feature.dynamicFields = dynamicFields
          feature.categoryId = categoryId
          feature.pricing = pricing
          feature.insurerId = insurerId
          feature.schemaKey = schemaKey

          if (!isNotDefined(feature.input?.schema) && Object.prototype.hasOwnProperty.call(feature.input.schema, 'properties') && Object.prototype.hasOwnProperty.call(feature.input.schema.properties, 'riderPt')) {
            feature.hasRiderSchemaUpdated = true
          } else {
            feature.hasRiderSchemaUpdated = false
          }
          return feature
        }
      )
      if (!isNotDefined(payoutTerms)) {
        product.productOption.payoutTerms = payoutTerms.map((payoutTerm) => {
          const { categoryId, pricing, ...dynamicFields } = payoutTerm
          const payoutType = payouts.find(
            (pt) => pt.categoryId === payoutTerm.categoryId
          )
          payoutTerm = Object.assign(
            {},
            payoutType,
            featureContent[`CATID_${categoryId}`]
          )
          payoutTerm.dynamicFields = dynamicFields
          payoutTerm.pricing = pricing
          return payoutTerm
        })
      }
      // FIXME: UI uses productOptionId in most places. So copy insurerId in productOptionId
      product.productOption.productOptionId = product.productOption.insurerId
      product.productOption.riders = product.productOption.riders && product.productOption.riders
        .filter((ri) => {
          const matchingFeature = featureCategoryIds.find(
            (fc) => fc.insurerId === ri.insurerId
          )
          return !isNotDefined(matchingFeature)
        })
        .map((ri) => {
          // If this rider is eligible, then there will be an entry in feature categoryids.
          ri.riderId = ri.insurerId
          return ri
        })
      // Array of rider premiums for different payoutOptions
      // Use the template provided and build each applicable pptOption.
      Object.keys(pptOptions).forEach((key) => {
        const pptOption = pptOptions[key]
        const { paymentOption } = pptOption
        const pptOptionTemplate = pptOptionsTemplate[paymentOption]
        if (paymentOption === 'single') {
          pptOption.display = pptOptionTemplate.display
        } else if (paymentOption === 'regular') {
          pptOption.display = pptOptionTemplate.display.format({ coverTerm })
        } else if (paymentOption === 'limited') {
          pptOption.display = pptOptionTemplate.display.format({
            ppt: pptOption.term.maxTerm
          })
        } else if (paymentOption === 'tillage') {
          pptOption.display = pptOptionTemplate.display.format({
            taAge: age + pptOption.ppt.maxPpt
          })
        }
        // For Car and Bike
        if (pptOption.hasOwnProperty('totalPremium')) {
          let { riderPremiums, paymentFrequency } = pptOption
          // pptOption.premium = applyModalDiscounting(pptOption.premium, modals, GST, paymentFrequency, false)
          const discountedPremiums = []
          if (!paymentFrequency.includes('S')) {
            paymentFrequency = paymentFrequency.concat('S')
          }
          paymentFrequency.forEach((frequency) => {
            const discountedPremium = {
              frequency,
              discountFactor: modals[frequency],
              frequencyDisplay: pfs[frequency],
              premium: pptOption.totalPremium,
              premiumWithGst: pptOption.totalPremiumWithGst
            }
            discountedPremiums.push(discountedPremium)
          })
          pptOption.premium = discountedPremiums
          if (!isNotDefined(riderPremiums)) {
            riderPremiums.forEach(rp => {
              const discountedPremiums = []
              if (!paymentFrequency.includes('S')) {
                paymentFrequency = paymentFrequency.concat('S')
              }
              paymentFrequency.forEach((frequency) => {
                const discountedPremium = {
                  frequency,
                  discountFactor: modals[frequency],
                  frequencyDisplay: pfs[frequency],
                  premium: rp.riderPremium.premium,
                  premiumWithGst: rp.riderPremium.premium
                }
                discountedPremiums.push(discountedPremium)
              })
              rp.riderPremium = discountedPremiums
              // rp.riderPremium = applyModalDiscounting(rp.riderPremium, modals, GST, paymentFrequency, false)
            })
          }
          product.productOption.pptOptions[key] = pptOption
        }
        //  For Term and Health
        // This pptOption needs to be supported
        if (['term', 'health'].includes(insuranceType) && pptOption.hasOwnProperty('premiums')) {
          const { premiums, riderPremiums, paymentFrequency } = pptOption
          // Premiums are organized by payout terms.
          if (!isNotDefined(payoutTerms)) {
            payoutTerms.forEach((pot) => {
              const premiumKey = `POT_${pot.categoryId}`
              if (premiums.hasOwnProperty(premiumKey)) {
                const premium = premiums[premiumKey]
                if (isArray(premium)) {
                  premium.forEach((pr) => {
                    pr.premium = applyModalDiscounting(
                      pr.premium,
                      modals,
                      GST,
                      paymentFrequency,
                      false
                    )
                  })
                } else {
                  premiums[premiumKey] = applyModalDiscounting(
                    premiums[premiumKey],
                    modals,
                    GST,
                    paymentFrequency,
                    false
                  )
                }
              }
            })
          }
          if (!isNotDefined(riderPremiums)) {
            const activeFeatures = product.productOption.featureCategoryIds
            const filteredActiveFeatures = []
            activeFeatures.forEach(feature => {
              if (feature.hasRiderSchemaUpdated) {
                filteredActiveFeatures.push(feature)
              }
            })
            const tempArr = filteredActiveFeatures.map(feature => feature.insurerId)
            riderPremiums.forEach((rp) => {
              if (tempArr.includes(rp.insurerId)) {
                rp.discount = rp.discount || 0
                rp.riderPremium = applyModalDiscounting_V2(
                  rp.riderPremium,
                  riderModals,
                  GST,
                  paymentFrequency,
                  false
                )
              } else {
                // Doing this first as we will overwrite it later
                const perThousand = rp.riderPremium.perThousand
                rp.discount = rp.riderPremium.discount || 0
                rp.riderPremium = applyModalDiscounting(
                  rp.riderPremium.premium,
                  riderModals,
                  GST,
                  paymentFrequency,
                  false
                )
                const perThousandWithGst = perThousand * (1 + GST / 100)
                rp.riderPremium.forEach((rp) => {
                  rp.perThousand = perThousand
                  rp.perThousandWithGst = perThousandWithGst
                })
              }
            })
          }
          product.productOption.pptOptions[key] = pptOption
        }
        //  For Annuity
        if (pptOption.hasOwnProperty('annuityPayout') && pptOption.hasOwnProperty('purchasePrice')) {
          const { premiums, payoutFrequency } = pptOption
          const { defRop, rop, acp, ia } = productData
          // Premiums are organized by payout terms.
          payoutTerms.forEach((pot) => {
            const premiumKey = `POT_${pot.categoryId}`
            if (premiums.hasOwnProperty(premiumKey)) {
              const premium = premiums[premiumKey]
              if (isArray(premium)) {
                premium.forEach((pr) => {
                  if (pr.defRop === defRop.period || pr.acp === acp.period || pr.rop === rop.percent || pr.ia === ia.percent) {
                    pr.premium = applyModalDiscountingForAnnuity(
                      pr,
                      payoutModals,
                      GST,
                      product,
                      productData,
                      payoutFrequency,
                      formData,
                      paymentModals
                    )
                  }
                })
              } else {
                premiums[premiumKey] = applyModalDiscountingForAnnuity(
                  premiums[premiumKey],
                  payoutModals,
                  GST,
                  product,
                  productData,
                  payoutFrequency,
                  formData,
                  paymentModals)
              }
            }
          })
          product.productOption.pptOptions[key] = pptOption
        }
      })
      product.riderModals = riderModals
      if (insuranceType === 'term' && isProductFixedTerm(product)) {
        product.productOption.calculatedCoverTerm = calculatedCoverTerm
        product.productOption.eligibleCoverUpto = eligibleCoverUpto
      }
      productsWithPreium.push(product)
    }
  })
  return productsWithPreium
}

/**
   * This function gets the premium for payoutTerm. Logic to return premium for some payout term is
   * different for each defaultPayoutTerm
   *
   * @param {*} productData
   * @param {*} premiumForPayoutTerm
   * @param {*} defaultPayoutTerm
   * @returns
   */
const getPremiumForPayoutTerm = (
  productData,
  premiumForPayoutTerm,
  defaultPayoutTerm
) => {
  let premiumItem
  // Payout is income, increasing income, isa or a combination of thereof
  const {
    incomePeriod,
    incomeIncreasePercentage,
    percentOfSumAssured,
    isaPercent
  } = productData.incomePayout
  if (defaultPayoutTerm === 16) {
    // Plain vanilla payoutTerm
    return premiumForPayoutTerm
  } else if (defaultPayoutTerm === 18) {
    // Plain vanilla payoutTerm
    return premiumForPayoutTerm
  } else if (defaultPayoutTerm === 46) {
    // Increasing sum assured
    premiumItem = premiumForPayoutTerm.find(
      (pfPot) => pfPot.isa === productData.isa.isaPercent
    )
    if (isNotDefined(premiumItem)) {
      premiumItem = premiumForPayoutTerm[0]
    }
  } else if (defaultPayoutTerm === 17) {
    // Lumpsum + income
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.period === incomePeriod &&
        pfPot.percentOfSumAssured === percentOfSumAssured
      )
    })
    if (isNotDefined(premiumItem)) {
      const filteredPremiumItem = premiumForPayoutTerm.filter((pfPot) => {
        return pfPot.period === incomePeriod
      })
      if (filteredPremiumItem.length > 0) {
        premiumItem = filteredPremiumItem[0]
      } else {
        premiumItem = premiumForPayoutTerm[0]
      }
    }
  } else if (defaultPayoutTerm === 14) {
    // Lumpsum + increasing income
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.period === incomePeriod &&
        pfPot.percentOfSumAssured === percentOfSumAssured &&
        pfPot.incomeIncreasePercentage === incomeIncreasePercentage
      )
    })
    if (isNotDefined(premiumItem)) {
      const filteredPremiumItem = premiumForPayoutTerm.filter((pfPot) => {
        return (
          pfPot.period === incomePeriod &&
          pfPot.incomeIncreasePercentage === incomeIncreasePercentage
        )
      })
      if (filteredPremiumItem.length > 0) {
        premiumItem = filteredPremiumItem[0]
      } else {
        premiumItem = premiumForPayoutTerm[0]
      }
    }
  } else if (defaultPayoutTerm === 154) {
    // increasing sum assured with income
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.period === incomePeriod &&
        pfPot.percentOfSumAssured === percentOfSumAssured &&
        pfPot.isa === isaPercent
      )
    })
    if (isNotDefined(premiumItem)) {
      const filteredPremiumItem = premiumForPayoutTerm.filter((pfPot) => {
        return pfPot.period === incomePeriod && pfPot.isa === isaPercent
      })
      if (filteredPremiumItem.length > 0) {
        premiumItem = filteredPremiumItem[0]
      } else {
        premiumItem = premiumForPayoutTerm[0]
      }
    }
  } else if (defaultPayoutTerm === 161) {
    // increasing sum assured with increasing income
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.period === incomePeriod &&
        pfPot.percentOfSumAssured === percentOfSumAssured &&
        pfPot.isa === isaPercent &&
        pfPot.incomeIncreasePercentage === incomeIncreasePercentage
      )
    })
    if (isNotDefined(premiumItem)) {
      if (isNotDefined(premiumItem)) {
        const filteredPremiumItem = premiumForPayoutTerm.filter((pfPot) => {
          return (
            pfPot.period === incomePeriod &&
            pfPot.isa === isaPercent &&
            pfPot.incomeIncreasePercentage === incomeIncreasePercentage
          )
        })
        if (filteredPremiumItem.length > 0) {
          premiumItem = filteredPremiumItem[0]
        } else {
          premiumItem = premiumForPayoutTerm[0]
        }
      }
    }
  }
  return premiumItem.premium
}
const getPremiumForPayoutTermForAnnuity = (
  productData,
  premiumForPayoutTerm,
  defaultPayoutTerm
) => {
  let premiumItem
  // Payout is income, increasing income, isa or a combination of thereof
  const { defRop, ia, acp, rop } = productData

  if (defaultPayoutTerm === 300 || defaultPayoutTerm === 305 || defaultPayoutTerm === 306 || defaultPayoutTerm === 307 || defaultPayoutTerm === 310 || defaultPayoutTerm === 311) {
    return (
      premiumForPayoutTerm
    )
  } else if (defaultPayoutTerm === 303) {
    premiumItem = premiumForPayoutTerm
  } else if (defaultPayoutTerm === 309) {
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.defRop === defRop.period
      )
    })
  } else if (defaultPayoutTerm === 301 || defaultPayoutTerm === 308) {
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.ia === ia.percent
      )
    })
  } else if (defaultPayoutTerm === 302) {
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.acp === acp.period
      )
    })
  } else if (defaultPayoutTerm === 304) {
    premiumItem = premiumForPayoutTerm.find((pfPot) => {
      return (
        pfPot.rop === rop.percent
      )
    })
  }
  return premiumItem
}

/**
   * This function prepares the payout schema and uiSchema based on categoryId. Each categoryId has it's
   * own values to use to create the schema. For teh categoryId other than 46 enum and enumNames are
   * created on basis of existance of 'income' key in ip.
   *
   * @param {*} payoutTerm
   * @param {*} formData
   * @returns
   */
const getPayoutSchema = (payoutTerm, formData) => {
  const { categoryId, dynamicFields } = payoutTerm
  let schemas = {
    schema: {
      type: 'object',
      properties: {}
    },
    uiSchema: {
      'ui:order': []
    }
  }
  const { sumAssured, hasEdit, hasIncomeEdit } = dynamicFields
  if (!hasEdit) {
    return {}
  }
  if (categoryId === 46) {
    const isaNames = dynamicFields.isaRange.map(
      (isa) => `${isa} Percent annually`
    )
    schemas = getIsaSchema(schemas, dynamicFields.isaRange, isaNames, formData)
  } else if (categoryId === 309) {
    const defRopNames = dynamicFields.defRopRange.map(
      (def) => `${def} Period annually`
    )
    schemas = getDefRopSchema(schemas, dynamicFields.defRopRange, defRopNames, formData)
  } else if (categoryId === 302) {
    const acpNames = dynamicFields.acpRange.map(
      (def) => `${def} Period annually`
    )
    schemas = getAcpSchema(schemas, dynamicFields.acpRange, acpNames, formData)
  } else if (categoryId === 301 || categoryId === 308) {
    const iaNames = dynamicFields.iaRange.map(
      (def) => `${def} Percent annually`
    )
    schemas = getIaSchema(schemas, dynamicFields.iaRange, iaNames, formData)
  } else if (categoryId === 304) {
    const ropNames = dynamicFields.ropRange.map(
      (def) => `${def} Percent annually`
    )
    schemas = getRopSchema(schemas, dynamicFields.ropRange, ropNames, formData)
  } else {
    const {
      isaRange,
      incomePeriodYears,
      incomeIncreasePercentageRange
    } = dynamicFields
    const incomePeriodYearEnum = []
    const incomePeriodYearEnumNames = []
    const periodEnum = []
    const periodEnumNames = []
    let isaEnumNames
    let incomeIncreaseEnumNames = []
    // Either there is incomeEnum or there is no income enum
    incomePeriodYears && incomePeriodYears.forEach((ip) => {
      if (hasIncomeEdit) {
        // Only if I can edit income, then period is valid
        // Income is editable. So only period schema
        periodEnum.push(ip.period)
        periodEnumNames.push(`Income for ${ip.period} Years`)
      } else {
        let income
        if (ip.hasOwnProperty('income')) {
          income = ip.income
        } else {
          income = Math.round((ip.percentOfSumAssured * sumAssured) / 100)
          // ip.income = income - ask
        }
        // This is combination. So push the stringify JSON. We can parse later
        incomePeriodYearEnum.push(JSON.stringify(ip))
        if ([154, 161].indexOf(categoryId) > -1) {
          incomePeriodYearEnumNames.push(
            `Increasing income of ${ip.percentOfSumAssured}% for the next ${ip.period} years`
          )
        } else {
          incomePeriodYearEnumNames.push(
            `Income of Rs. ${income} for next ${ip.period} years`
          )
        }
      }
    })
    if (
      !isNotDefined(incomeIncreasePercentageRange) &&
      incomeIncreasePercentageRange.length > 0
    ) {
      incomeIncreaseEnumNames = incomeIncreasePercentageRange.map(
        (iip) => `${iip} Perecent`
      )
    }
    if (!isNotDefined(isaRange) && isaRange.length > 0) {
      isaEnumNames = dynamicFields.isaRange.map(
        (isa) => `${isa} Percent annually`
      )
    }
    // By default categoryId 17 is lumpsum plus income

    if (categoryId === 17) {
      ;[schemas, formData] = getIncomeSchema(
        schemas,
        hasIncomeEdit,
        incomePeriodYearEnum,
        incomePeriodYearEnumNames,
        periodEnum,
        periodEnumNames,
        formData
      )
    } else if (categoryId === 14) {
      ;[schemas, formData] = getIncomeSchema(
        schemas,
        hasIncomeEdit,
        incomePeriodYearEnum,
        incomePeriodYearEnumNames,
        periodEnum,
        periodEnumNames,
        formData
      )
      if (
        !isNotDefined(incomeIncreasePercentageRange) &&
        incomeIncreasePercentageRange.length > 0
      ) {
        schemas = getIncomeIncreaseSchema(
          schemas,
          incomeIncreasePercentageRange,
          incomeIncreaseEnumNames,
          formData
        )
      }
    } else if (categoryId === 154) {
      ;[schemas, formData] = getIncomeSchema(
        schemas,
        hasIncomeEdit,
        incomePeriodYearEnum,
        incomePeriodYearEnumNames,
        periodEnum,
        periodEnumNames,
        formData
      )
      if (!isNotDefined(isaRange) && isaRange.length > 0) {
        schemas = getIsaSchema(schemas, isaRange, isaEnumNames, formData)
      }
    } else if (categoryId === 161) {
      ;[schemas, formData] = getIncomeSchema(
        schemas,
        hasIncomeEdit,
        incomePeriodYearEnum,
        incomePeriodYearEnumNames,
        periodEnum,
        periodEnumNames,
        formData
      )
      if (incomeIncreasePercentageRange.length > 0) {
        schemas = getIncomeIncreaseSchema(
          schemas,
          incomeIncreasePercentageRange,
          incomeIncreaseEnumNames,
          formData
        )
      }
      if (!isNotDefined(isaRange) && isaRange.length > 0) {
        schemas = getIsaSchema(schemas, isaRange, isaEnumNames, formData)
      }
    }
  }
  schemas.uiSchema['ui:order'] = Object.keys(schemas.schema.properties)
  return { schemas, formData }
}

/**
   * This function is used to build IsaSchema
   *
   * @param {*} schemas
   * @param {*} isaRange
   * @param {*} isaEnumNames
   * @param {*} formData
   * @returns
   */
const getIsaSchema = (schemas, isaRange, isaEnumNames, formData) => {
  if (isaRange.length > 0) {
    schemas.schema.properties = Object.assign(
      schemas.schema.properties,
      isaSchema
    )
    schemas.schema.properties.isaPercent.enum = isaRange
    schemas.schema.properties.isaPercent.enumNames = isaEnumNames
    schemas.schema.properties.isaPercent.default = formData.isaPercent
  }
  return schemas
}

// Ia Schema
const getIaSchema = (schemas, iaRange, iaEnumNames, formData) => {
  if (iaRange.length > 0) {
    schemas.schema.properties = Object.assign(
      schemas.schema.properties,
      iaSchema
    )
    schemas.schema.properties.percent.enum = iaRange
    schemas.schema.properties.percent.enumNames = iaEnumNames
    // schemas.schema.properties.isaPercent.default = formData.isaPercent
  }
  return schemas
}

// DefRop Schema
const getDefRopSchema = (schemas, defRopRange, defRopEnumNames, formData) => {
  if (defRopRange.length > 0) {
    schemas.schema.properties = Object.assign(
      schemas.schema.properties,
      defRopSchema
    )
    schemas.schema.properties.period.enum = defRopRange
    schemas.schema.properties.period.enumNames = defRopEnumNames
    // schemas.schema.properties.isaPercent.default = formData.isaPercent
  }
  return schemas
}

// Rop Schema
const getRopSchema = (schemas, ropRange, ropEnumNames, formData) => {
  if (ropRange.length > 0) {
    schemas.schema.properties = Object.assign(
      schemas.schema.properties,
      ropSchema
    )
    schemas.schema.properties.percent.enum = ropRange
    schemas.schema.properties.percent.enumNames = ropEnumNames
    // schemas.schema.properties.isaPercent.default = formData.isaPercent
  }
  return schemas
}

// Acp Schema
const getAcpSchema = (schemas, acpRange, acpEnumNames, formData) => {
  if (acpRange.length > 0) {
    schemas.schema.properties = Object.assign(
      schemas.schema.properties,
      acpSchema
    )
    schemas.schema.properties.period.enum = acpRange
    schemas.schema.properties.period.enumNames = acpEnumNames
    // schemas.schema.properties.isaPercent.default = formData.isaPercent
  }
  return schemas
}
/**
   * This function is used to build IncomeIncrease schema
   *
   * @param {*} schemas
   * @param {*} incomeIncreasePercentageRange
   * @param {*} incomeIncreaseEnumNames
   * @param {*} formData
   * @returns
   */
const getIncomeIncreaseSchema = (
  schemas,
  incomeIncreasePercentageRange,
  incomeIncreaseEnumNames,
  formData
) => {
  schemas.schema.properties = Object.assign(
    schemas.schema.properties,
    incomeIncreaseSchema
  )
  schemas.schema.properties.incomeIncreasePercentage.enum = incomeIncreasePercentageRange
  schemas.schema.properties.incomeIncreasePercentage.enumNames = incomeIncreaseEnumNames
  schemas.schema.properties.incomeIncreasePercentage.default =
    formData.incomeIncreasePercentage
  return schemas
}

/**
   * This function is used to create incomeSchema. default values are selected based on the existance
   * of 'precentOfSumAssured' in incomePeriodYearEnum. First block if code updates the values based
   * on the updated formData and second, else block, updates the values with the values of parameters
   *
   * @param {*} schemas
   * @param {*} hasIncomeEdit
   * @param {*} incomePeriodYearEnum
   * @param {*} incomePeriodYearEnumNames
   * @param {*} periodEnum
   * @param {*} periodEnumNames
   * @param {*} formData
   * @returns
   */
const getIncomeSchema = (
  schemas,
  hasIncomeEdit,
  incomePeriodYearEnum,
  incomePeriodYearEnumNames,
  periodEnum,
  periodEnumNames,
  formData
) => {
  const { schema, uiSchema } = schemas
  let updatedFormData = formData
  if (!hasIncomeEdit) {
    // income period years schema
    let defaultValue
    updatedFormData = produce(formData, (draft) => {
      if (incomePeriodYearEnum.indexOf('percentOfSumAssured')) {
        defaultValue = JSON.stringify({
          period: formData.incomePeriod,
          percentOfSumAssured: formData.percentOfSumAssured
        })
        draft.incomePeriodYear = defaultValue
      } else {
        defaultValue = JSON.stringify({
          period: formData.incomePeriod,
          income: formData.income
        })
        draft.incomePeriodYear = defaultValue
      }
    })
    schema.properties = Object.assign(
      schema.properties,
      JSON.parse(JSON.stringify(incomePeriodYearSchema))
    )
    schema.properties.incomePeriodYear.enum = incomePeriodYearEnum
    schema.properties.incomePeriodYear.enumNames = incomePeriodYearEnumNames
    schema.properties.incomePeriodYear.default = defaultValue
  } else {
    // Income schema and income period schema
    schema.properties = Object.assign(
      schema.properties,
      JSON.parse(JSON.stringify(incomeInputSchema))
    )
    schema.properties = Object.assign(
      schema.properties,
      JSON.parse(JSON.stringify(incomePeriodSchema))
    )
    schema.properties.incomePeriod.enum = periodEnum
    schema.properties.incomePeriod.enumNames = periodEnumNames
    schema.properties.incomePeriod.default = formData.incomePeriod
    uiSchema.income = {
      'ui:widget': 'AmountWidget'
    }
  }
  return [schemas, updatedFormData]
}
export {
  isProductWholeLife,
  isProductFixedTerm,
  flattenProducts,
  convertProductToProductOptions,
  configureFilters,
  getPaymentFrequency,
  getFeatures,
  getBenefits,
  getSchemaKeyForRider,
  getProductName,
  calcRiderPremium,
  getRiderSumAssured,
  getPremium,
  getPptOptionForEnquiry,
  chosenProductEnquiryRules,
  chosenProductOptionRules,
  configureForDisplay,
  formatPayoutTerm,
  buildPaymentOptionSchema,
  insertQuoteIntoProducts,
  getPremiumForPayoutTerm,
  getPayoutSchema,
  checkIfProductValidByPptOption,
  checkIfProductValidByPptOptionGeneral,
  checkIfProductValidByPptOptionHealth,
  getFormattedFeature,
  checkIfProductValidByPptOptionAnnuity,
  getPremiumForPayoutTermForAnnuity
}
