import { unescape } from 'lodash'
import OnboardAccordion from './OnboardAccordion'

const OnboardFaq = ({ title, childrens }) => {
  return (
    <section className='main3'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 mb-4 mt-2 text-center'>
            <h2>{unescape(title)}</h2>
          </div>
        </div>
        <div className='accordion' id='faq-accordion'>
          {childrens.map((child, i) => {
            const id = `accordion-content-${i}`
            return <OnboardAccordion id={id} key={`accordion-${i}`} {...child} />
          })}
        </div>
      </div>
    </section>
  )
}

export default OnboardFaq
