import { useState } from 'react'
import SelectButtonWidget from './SelectButtonWidget'
import LabelField from '../components/LabelField'
import './SelectWidget.scss'

/**
 * This Widget is not used, Please use Instead MakeModelField as a ui:field property
 */
const MakeModelWidget = (props) => {
  const data = props.value && JSON.parse(props.value)
  const [state, setState] = useState({ make: data?.make, model: data?.model, variant: data?.variant, fuelType: data?.fuelType, manufacturingYear: data?.manufacturingYear })
  const { formContext: { vehiclePurchaseDate, makes, fuelTypes, variants } } = props
  const buildMakeData = () => {
    const makeData = []
    makes.forEach(ele => {
      const exist = makeData.findIndex(data => data.id === ele.makeId)
      if (exist === -1) {
        makeData.push({ id: ele.makeId, label: ele.makeName, value: ele.makeId })
      }
    })
    return makeData
  }

  const buildModelData = (make) => {
    const modelData = []
    const allModels = makes.filter(ele => ele.makeId === make)
    allModels.forEach(ele => {
      const exist = modelData.findIndex(data => data.id === ele.modelId)
      if (exist === -1) {
        modelData.push({ id: ele.modelId, label: ele.modelName, value: ele.modelId })
      }
    })
    return modelData
  }

  const buildFuelTypeData = (model) => {
    const allVariants = variants.filter(ele => ele.modelId === model)
    const fuelTypeData = []
    const fuelTypeIds = []
    allVariants.forEach(ele => {
      const exist = fuelTypeData.findIndex(ele => ele.id === ele.fuelTypeId)
      if (!fuelTypeIds.includes(ele.fuelTypeId)) {
        fuelTypeIds.push(ele.fuelTypeId)
        if (exist === -1) {
          const fuelType = fuelTypes.find(fuelType => fuelType.fuelTypeId === ele.fuelTypeId)
          fuelTypeData.push({ id: ele.fuelTypeId, label: fuelType.display, value: ele.fuelTypeId })
        }
      }
    })
    return fuelTypeData
  }

  const buildVariantData = (fuelType, model) => {
    const allVariants = variants.filter(ele => ele.modelId === model && ele.fuelTypeId === fuelType)
    return allVariants.map(ele => {
      return { id: ele.variantId, label: ele.variantName, value: ele.variantId }
    })
  }

  const buildManufacturingYearsData = () => {
    const purchaseYear = vehiclePurchaseDate ? parseInt(new Date(vehiclePurchaseDate).getFullYear()) : parseInt(new Date().getFullYear())
    const years = 3
    const manufacturingYears = []
    for (let index = 0; index < years; index++) {
      manufacturingYears.push({ value: purchaseYear - index, label: `${purchaseYear - index}` })
    }
    return manufacturingYears
  }

  const handleOnItemSelect = (year) => {
    const { onChange } = props
    setState({ ...state, manufacturingYear: year })
    onChange(JSON.stringify({ ...state, manufacturingYear: year }))
  }

  return (
    <div>
      <SelectButtonWidget
        id='make'
        label='make'
        placeholder='Select Make'
        options={{ enumOptions: buildMakeData() }}
        className='selectButtonWidget'
        value={state.make ?? ''}
        schema={{}}
        onChange={(value) => setState({ make: value, model: undefined, fuelType: undefined, variant: undefined, manufacturingYear: undefined })}
      />

      <LabelField
        className='control-label customLabel'
        htmlFor='model'
        title='Select Model'
      />
      <SelectButtonWidget
        id='model'
        label='model'
        disabled={!state.make}
        placeholder='Select Model'
        className='selectButtonWidget'
        options={{ enumOptions: buildModelData(state.make) }}
        value={state.model ?? ''}
        schema={{}}
        onChange={(value) => setState({ make: state.make, model: value, fuelType: undefined, variant: undefined, manufacturingYear: undefined })}
      />
      <LabelField
        className='control-label customLabel'
        htmlFor='fuelType'
        title='Select Fuel Type'
      />
      <SelectButtonWidget
        id='fuelType'
        label='fuelType'
        disabled={!state.model}
        placeholder='Select a fuel type'
        className='selectButtonWidget'
        options={{ enumOptions: buildFuelTypeData(state.model) }}
        value={state.fuelType ?? ''}
        schema={{}}
        onChange={(value) => setState({ make: state.make, model: state.model, fuelType: value, variant: undefined, manufacturingYear: undefined })}
      />
      <LabelField
        className='control-label customLabel'
        htmlFor='variant'
        title='Select Variant'
      />
      <SelectButtonWidget
        id='variant'
        label='variant'
        disabled={!state.fuelType}
        placeholder='Select a variant'
        className='selectButtonWidget'
        options={{ enumOptions: buildVariantData(state.fuelType, state.model) }}
        value={state.variant ?? ''}
        schema={{}}
        onChange={(value) => setState({ make: state.make, model: state.model, fuelType: state.fuelType, variant: value, manufacturingYear: undefined })}
      />
      <LabelField
        className='control-label customLabel'
        htmlFor='manufacturingYear'
        title='Select Manufacturing Year'
      />
      <SelectButtonWidget
        id='manufacturingYear'
        label='manufacturingYear'
        disabled={!state.variant}
        placeholder='Select a manufacturing year'
        options={{ enumOptions: buildManufacturingYearsData() }}
        value={state.manufacturingYear ?? ''}
        schema={{}}
        onChange={(value) => handleOnItemSelect(value)}
      />
    </div>
  )
}

export default MakeModelWidget
