import customer from './customer'
import configure from './configure'
import userAgent from './userAgent'
import errors from './errors'
import applications from './applications'
import policies from './policies'
import distributorReport from './distributorReport'
import claimsService from './claims/claimsConfigure'
export default {
  customer,
  configure,
  userAgent,
  errors,
  applications,
  policies,
  distributorReport,
  claimsService
}
