import { Header } from 'components/Header'

const Caption = (props) => {
  const { bold, children, ...restProps } = props
  const element = bold ? <strong>{children}</strong> : children
  return (
    <Header size='tiny' {...restProps}>
      {element}
    </Header>
  )
}

export default Caption
