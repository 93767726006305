import { generateOtp, getClaimHoldings, getClaimsSchema, postIntimation, trackClaims, validateOtp, postRegistration } from '../../services/claimsService'

// Create an effect function to fetch claims schema data
const getSchemaForClaims = async (dispatch, query) => {
  try {
    const schema = await getClaimsSchema({ query })

    // Extract the insuranceType from the query
    const { insuranceType } = query

    // Dispatch an action to store the schema data in your reducer
    dispatch.claimsService.getSchema({ insuranceType, ...schema })
    return schema
  } catch (err) {
    if (err) {
      console.error('Error fetching claims schema:', err)
    }
  }
}

const postClaimIntimation = async (dispatch, data) => {
  try {
    const intimation = await postIntimation(data)

    // Extract the insuranceType from the query
    const { enqueryData, queryParams } = data
    const { insuranceType } = queryParams

    // Dispatch an action to store the schema data in your reducer
    dispatch.claimsService.claimIntimation({ insuranceType, ...intimation })
  } catch (err) {
    if (err) {
      console.error('Error in claim Intimation:', err)
    }
  }
}

const trackClaimsEffect = async (dispatch, query) => {
  try {
    const response = await trackClaims({ query })

    // Dispatch an action to store the schema data in your reducer
    dispatch.claimsService.getDataForClaimStatus(response)
  } catch (err) {
    if (err) {
      console.error('Error fetching claims status:', err)
    }
  }
}
const getClaimHoldingsEffect = async (dispatch, query) => {
  const { insuranceType } = query
  try {
    const response = await getClaimHoldings(query)
    const { claimApplications, count } = response
    dispatch.claimsService.storeApplicationsData({ modifiedApplications: claimApplications, count: count, insuranceType })
  } catch (err) {
    if (err) throw err
  }
}

const getOtpForIntimation = async (dispatch, data) => {
  try {
    const otp = await generateOtp(data)

    // Extract the insuranceType from the query
    const { queryParams } = data
    const { insuranceType } = queryParams

    // Dispatch an action to store the schema data in your reducer
    dispatch.claimsService.OtpForIntimation({ insuranceType, ...otp })
  } catch (err) {
    if (err) {
      console.error('Error in Otp Generation:', err)
    }
  }
}

const validateOtpForIntimation = async (dispatch, data) => {
  try {
    const otp = await validateOtp(data)

    // Extract the insuranceType from the query
    const { queryParams } = data
    const { insuranceType } = queryParams

    // Dispatch an action to store the schema data in your reducer
    dispatch.claimsService.OtpForIntimation({ insuranceType, ...otp })
  } catch (err) {
    if (err) {
      console.error('Error in Otp Validation:', err)
    }
  }
}

const postClaimRegistrationEffect = async (dispatch, query) => {
  try {
    const registration = await postRegistration(query)
    const { queryParams } = query
    const { insuranceType } = queryParams
    dispatch.claimsService.claimRegistration({ insuranceType, ...registration })
  } catch (err) {
    if (err) {
      console.error('Error in claim Registration', err)
    }
  }
}
export { getSchemaForClaims, postClaimIntimation, trackClaimsEffect, getClaimHoldingsEffect, getOtpForIntimation, validateOtpForIntimation, postClaimRegistrationEffect }
