import { buildCommonImagePath } from 'components/buildImagePath'
import TooltipComponent from './TooltipComponent'
import { Header } from 'components/Header'

const ValidationErrorPopUp = (props) => {
  const { errorMessage } = props
  return (
    <TooltipComponent id='error-message' icon={buildCommonImagePath('error-white.png')} customIconStyle={{ width: 24, height: 24 }}>
      <Header color='red'>
        {errorMessage}
      </Header>
    </TooltipComponent>
  )
}
export default ValidationErrorPopUp
