import SelectButtonWidget from '../widgets/SelectButtonWidget'
import '../widgets/SelectWidget.scss'
import CustomFieldWrapper from '../components/CustomFieldWrapper'

const InspectionAddressField = (props) => {
  const { formData, errorSchema, schema, uiSchema, onChange, formContext } = props
  const { cities, states, locations } = formContext
  const buildStateData = () => {
    const stateData = []
    states.forEach(ele => {
      const exist = stateData.findIndex(data => data.id === ele.Code)
      if (exist === -1) {
        stateData.push({ id: ele.Code, label: ele.StateName, value: ele.Code })
      }
    })
    return stateData
  }

  const buildCityData = (state) => {
    const cityData = []
    const allCities = cities.filter(ele => ele.StateId === state)
    allCities.forEach(ele => {
      const exist = cityData.findIndex(data => data.id === ele.CityId)
      if (exist === -1) {
        cityData.push({ id: ele.CityId, label: ele.CityName, value: ele.CityId })
      }
    })
    return cityData
  }

  const buildLocationData = (city) => {
    const locationData = []
    const allLocations = locations.filter(ele => ele.CityId === city)
    allLocations.forEach(ele => {
      const exist = locationData.findIndex(data => data.id === ele.LocationId)
      if (exist === -1) {
        locationData.push({ id: ele.LocationId, label: ele.LocationName, value: ele.LocationId })
      }
    })
    return locationData
  }

  const Element = ({ inputKey, ...restProps }) => {
    const widgetProps = {
      id: inputKey,
      className: 'selectButtonWidget',
      value: formData?.[inputKey] ?? '',
      disabled: schema.disabled ?? false,
      schema: schema.properties[inputKey],
      placeholder: uiSchema[inputKey]['ui:placeholder'],
      required: schema.required.indexOf(inputKey) > -1,
      ...restProps
    }
    return (
      <CustomFieldWrapper
        schema={schema.properties[inputKey]}
        uiSchema={uiSchema[inputKey]}
        errorSchema={errorSchema}
      >
        <SelectButtonWidget {...widgetProps} />
      </CustomFieldWrapper>
    )
  }

  return (
    <>
      <Element
        inputKey='state'
        options={{ enumOptions: buildStateData() }}
        onChange={(value) => onChange({ ...formData, state: value, city: undefined, location: undefined })}
      />
      <Element
        inputKey='city'
        options={{ enumOptions: buildCityData(formData?.state) }}
        onChange={(value) => onChange({ ...formData, city: value, location: undefined })}

      />
      <Element
        inputKey='location'
        options={{ enumOptions: buildLocationData(formData?.city) }}
        onChange={(value) => onChange({ ...formData, location: value })}

      />
    </>
  )
}

export default InspectionAddressField
