import { Button, Container, Header, Segment } from 'semantic-ui-react'
import { isNotDefined } from 'utils/lib/basic'
import format from 'string-format'
import 'components/css/inspectionSummary.scss'
import 'font-awesome/css/font-awesome.min.css'

const InspectionSummary = (props) => {
  const { redirectToHoldings, redirectToProductListing, configure, inspectionDetails, selectedProductDetails } = props
  const inspectionMethod = inspectionDetails.inspectionMethod
  const productName = selectedProductDetails.productName
  const inspectionMessage = !isNotDefined(configure.booking.inspectionMessage) && configure.booking.inspectionMessage?.[inspectionMethod]
  return (
    <div className='inspectionSummary'>
      <Header as='h5' className='heading' size='medium'>
        QUOTE ID : {selectedProductDetails.quoteId}
      </Header>
      <Header size='tiny' className='subtitle'>BREAK-IN ID: {selectedProductDetails.breakInId}</Header>
      <Segment padded className='description-card'>
        <Container fluid className='description-message'>
          <div className='alert-icon'>
            <i className='fa fa-bullhorn' />
          </div>
          {inspectionMessage && <Header size='small'>{format(inspectionMessage, { productName })}</Header>}
          {!inspectionMessage && <Header size='small'>Thank you for choosing {productName}. Your inspection request has been sucessfully submitted</Header>}
        </Container>
        <Container fluid className='button-container'>
          <Button primary onClick={redirectToHoldings}>Go To Holdings</Button>
          <Button basic onClick={redirectToProductListing}>Buy New Products</Button>
        </Container>
      </Segment>
    </div>
  )
}

export default InspectionSummary
