import FilterMenuWebTerm from './FilterMenuWebTerm'
import FilterMenuWebAuto from '../auto/FilterMenuWebAuto'
import FilterMenuWebAnnuity from './FilterMenuWebAnnuity'
import FilterMenuWebHealth from './FilterMenuWebHealth'

const FilterMapper = {
  term: FilterMenuWebTerm,
  car: FilterMenuWebAuto,
  bike: FilterMenuWebAuto,
  annuity: FilterMenuWebAnnuity,
  health: FilterMenuWebHealth
}

const FilterMenuWeb = (props) => {
  const { insuranceType } = props
  const FilterMenuDisplay = FilterMapper[insuranceType]
  return (
    <div className='filterMenuContainer'>
      <FilterMenuDisplay {...props} />
    </div>
  )
}

export default FilterMenuWeb

/** FILTER MENU CODE COMMENTED !!! NOT USED
 *
    filterOrder.map((filterKey, index) => {
      const config = filterMenuConfig[filterKey]
      let defaultValue
      let value
      const dataValue = (filters.filterData[config.key] && filters.filterData[config.key][0].value)
      let options = config.values.map((cfKey) => {
        return {
          key: cfKey.value,
          text: <Header sub>{cfKey.display}</Header>,
          value: cfKey.value
        }
      })
      if (config.key === 'paymentOption') {
        defaultValue = productData.paymentOption
      }
      value = dataValue || defaultValue
      return (
        <div
          key={`${index}select`}
          className='dropDownContainer flexContainer'
        >
          <Dropdown
            selectOnBlur={false}
            disabled={disabled}
            placeholder={config.title}
            id={config.key}
            options={options}
            onChange={(e, data) => this.onFilterChange(data, config, value)}
            value={value}
          />
          <div className='labelHolder'>
            {dataValue && <Label
              color='blue'
              size='mini'
              className='changeLabel'
              onClick={() => resetFilter(filterKey)}
            >
              {'REMOVE'}
            </Label>}
          </div>
        </div>
      )
    })
  }
  <Menu.Item className='removeAll' id='remove_all'>
    <Header
      id='removeFilters'
      sub
      onClick={() => resetFilter('all')}
      color={(hasFilter) ? 'blue' : 'grey'}
    >
      {'Remove All'}
    </Header>
  </Menu.Item>
***/
