const errors = {
  name: 'errors',
  state: {
    currentError: {}
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Returns currentError from the state
     *
     * @returns
     */
    getCurrentError () {
      return slice(errors => errors.currentError)
    }
  }),
  reducers: {

    /**
     * Sets currentError in the state
     *
     * @param {*} state
     * @param {*} currentError
     * @returns object
     */
    setNewError (state, currentError) {
      state.currentError = currentError
      return state
    },

    /**
     * Sets empty object in the currentError in the state
     *
     * @param {*} state
     * @returns object
     */
    cleanErrors (state) {
      state.currentError = {}
      return state
    }
  },
  effects: (dispatch) => ({

    /**
     * Calls setNewError dispatch
     *
     * @param {*} currentError
     * @param {*} rootState
     */
    setCurrentError (currentError, rootState) {
      dispatch.errors.setNewError(currentError)
    },

    /**
     * Sets the current error usinf reducers based on the values of errorType and name
     *
     * @param {*} error
     * @param {*} rootState
     */
    setHooksError (error, rootState) {
      if (error.errorType === 'CustomError') {
        dispatch.errors.setCurrentError({ errorName: 'uiApiError', code: error.code })
      } else if (error.errorType === 'ServerApiError') {
        if (error.name === 'TokenExpiredError') {
          dispatch.errors.setCurrentError({ errorName: 'clientSessionExpired', code: error.code })
        } else if (error.data.message === 'Network Error') {
          dispatch.errors.setCurrentError({ errorName: 'networkError', code: error.code })
        } else {
          dispatch.errors.setCurrentError({ errorName: error.name, code: error.code, message: error.data.message })
        }
      } else if (error.errorType === 'FieldNotFound') {
        dispatch.errors.setCurrentError({ errorName: 'incorrectFieldsPassed', code: error.code })
      } else if (error.errorType === 'FieldInvalid') {
        dispatch.errors.setCurrentError({ errorName: 'invalidFieldPassed', code: error.code })
      } else {
        // FIXME: some general error here
        dispatch.errors.setCurrentError({ errorName: 'uiApiError', code: error.code })
      }
    }
  })

}
export default errors
