import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { adopt } from 'react-adopt'
import { DataAccessContext } from 'components/context'
// import { H6, Form, Button } from 'components/uiComponents'
import { onboardComposer } from 'components/onboardElements'
import {
  calculateRecommendations,
  isNotEmpty,
  find,
  isNotDefined,
  getErrorsById,
  getCustomErrorMessage
} from 'utils'
import { validateFormData } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'
/* eslint react/jsx-handler-names: "off" */

const OnboardFormForHDFC = props => {
  const { device, configure, insuranceType } = props
  const [formData, setFormData] = useState(props.formData)
  // FIXME: to remove soon !!
  const [errors, setErrors] = useState([])
  useEffect(() => {
    if (formData !== props.formData) {
      setFormData(props.formData)
    }
  },
  // eslint-disable-next-line
  [props.formData])

  const handleChange = change => {
    setFormData(
      produce(formData, draft => {
        draft = Object.assign(draft, change)
        if (isNotEmpty(errors)) {
          const validationErrors = validateFormData({
            insuranceType,
            formData: draft
          })
          setErrors(validationErrors.errors)
        }
      })
    )
  }
  const getErrorMessageByKey = key => {
    const { steps } = props.configure.onboard.inlineForm
    const config = find(steps, step => step.key === key)
    let validationErrors = []
    let title
    if (!isNotDefined(config) && !isNotDefined(config.errorDisplay)) {
      title = config.errorDisplay.title
      const { secondaryField } = config.errorDisplay
      validationErrors = getErrorsById(errors, key)
      if (
        isNotEmpty(secondaryField) &&
        isNotEmpty(validationErrors) &&
        validationErrors[0].keyword !== 'required'
      ) {
        const errorList = getErrorsById(errors, secondaryField.key)
        if (isNotEmpty(errorList)) {
          validationErrors = errorList
          title = secondaryField.title
        }
      }
    }
    if (isNotEmpty(validationErrors)) {
      if (key === 'gender') {
        return 'Invalid Gender'
      }
      return getCustomErrorMessage(title, validationErrors[0])
    }
    return null
  }

  const calculateAge = dob => {
    const diffMs = Date.now() - new Date(dob).getTime()
    const ageDt = new Date(diffMs)
    return Math.abs(ageDt.getUTCFullYear() - 1970)
  }

  const onSubmit = () => {
    const { onSubmit, configure, insuranceType } = props
    const onboardConfig = configure.onboard
    const { steps } = onboardConfig.termInsuranceSteps
    // FIXME: to remove calculate reco from here
    const recommendedFormData = calculateRecommendations(formData, steps)
    const { sumAssured, coverTerm } = recommendedFormData
    const age = calculateAge(formData.dateOfBirth)
    const newFormData = {
      ...formData,
      age,
      coverTerm,
      sumAssured: Math.round(sumAssured)
    }
    const validationErrors = validateFormData({
      insuranceType,
      formData: newFormData
    })
    const arrayOfErrors = validationErrors.errors
    if (isNotEmpty(arrayOfErrors)) {
      setErrors(arrayOfErrors)
    } else {
      onSubmit(newFormData)
    }
  }
  const onboardConfig = configure.onboard
  const { steps } = onboardConfig.termInsuranceSteps
  const schema = onboardConfig.onboardSchema.schema
  // const inlineSteps = onboardConfig.inlineForm.steps
  const ComponentMap = onboardComposer({
    steps,
    handleChange,
    formType: 'hdfcForm',
    schema,
    formData,
    validationErrors: errors,
    id: 'inline_form',
    getErrorMessageByKey,
    device,
    insuranceType,
    productData: props.productData,
    hdfcLandingPage: true
  })
  const formContent = (
    <div className='main4' style={{ backgroundColor: props.bgColor }}>
      <div className='form-container'>
        <div className='container'>
          <div className='col-md-12 col-sm-12 col-xs-12'>
            <div className='container'>
              <div className='category_slider1' id='contact'>
                <div className='row'>
                  <div className='col-lg-12 col-md-12'>
                    <form
                      className='form-inline contactForm'
                      encType='multipart/form-data'
                      // method=''
                      // action=''
                      // role='form'
                      data-toggle='validator'
                      noValidate
                    >
                      <div className='form schedule-assessment'>
                        <div className='row margin-top-l'>
                          {ComponentMap.dateOfBirth}
                          {ComponentMap.gender}
                          {ComponentMap.income}
                          {ComponentMap.mobile}
                        </div>
                        <div className='row margin-top-l'>
                          {ComponentMap.pincode}
                          {/* <!-- close col--> */}
                          {ComponentMap.cType}
                        </div>
                        <div className='row'>
                          <div className='form-group col-md-12'>
                            <div className=' auto checkbox'>
                              <label>
                                <input
                                  onChange={event => {
                                    if (event.target.checked) {
                                      handleChange({ mobileDnd: true })
                                    } else {
                                      handleChange({ mobileDnd: false })
                                    }
                                  }}
                                  name='remember'
                                  id='monileDnd'
                                  type='checkbox'
                                  checked={formData.mobileDnd}
                                  style={{ height: 15 }}
                                />
                                I authorize HDFC Securities to call me. This
                                will override your existing registry in DNCR.
                              </label>
                            </div>
                            <input
                              id='getQuotesBtn'
                              disabled={!formData.mobileDnd}
                              onClick={() => onSubmit(formData)}
                              type='button'
                              className='submit-btn text-center'
                              value='Get Quotes'
                            />
                          </div>
                        </div>
                        {/* <!-- close row-->  */}

                        {/* <!-- close row */}

                        {/* <button type='submit' className='submit-btn text-center' /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
  /* const formContent = (
    <div className='formWrapper'>
      <Form noValidate>
        <H6 className='rowItems flexContainer'>
          <div className='flexItems'>
            {ComponentMap.dateOfBirth}
          </div>
          <div className='flexItems'>
            {ComponentMap.gender}
          </div>
        </H6>
        <H6 className='rowItems flexContainer'>
          <div className='flexItems'>
            {ComponentMap.income}
          </div>
          <div className='flexItems'>
            {ComponentMap.cType}
          </div>
        </H6>
        <div className='rowItems'>
          {ComponentMap.mobile}
        </div>
        {/* {inlineSteps.map((step, index) => {
          const { key, displayText } = step
          let element = null
          if (displayText) {
            const displayStrings = step.displayText.split(`{${key}}`)
            element = <H5>
              {displayStrings[0]}
              {ComponentMap[key]}
              {displayStrings[1]}
            </H5>
          } else {
            element = ComponentMap[key]
          }
          return (
            <React.Fragment
              key={index}
            >
              {element}
              {device === 'mobile' && <MobileErrorDisplay
                errorMessage={getErrorMessageByKey(key)}
                id={key}
              />}
            </React.Fragment>
          )
        })}
      </Form>
    </div>
  ) */
  return formContent
  // return (
  //   <div id='onboardInlineDiv' className={`hdfcform-outer ${device}`}>
  //     <div id='hdfcform' className={`hdfcform ${device}`}>
  //       {device === 'mobile' && formContent}
  //       {device !== 'mobile' && (
  //         <div className='flexContainer'>{formContent}</div>
  //       )}
  //       <div className='proceedButtonContainer'>
  //         <Button
  //           id={'proceed_button'}
  //           onClick={e => onSubmit(formData)}
  //           primary
  //           content={'GET QUOTES'}
  //         />
  //       </div>
  //     </div>
  //   </div>
  // )
}
OnboardFormForHDFC.propTypes = {
  device: PropTypes.string,
  configure: PropTypes.object.isRequired,
  formData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
}
const WithContext = adopt({
  dataAccess: <DataAccessContext.Consumer />
})
const WrapperComponent = props => (
  <WithContext>
    {({ dataAccess }) => (
      <OnboardFormForHDFC
        {...props}
        onSubmit={dataAccess.onSubmit}
        formType={dataAccess.formType}
        device={dataAccess.device}
        formData={dataAccess.formData}
        configure={dataAccess.configure}
        insuranceType={dataAccess.insuranceType}
      />
    )}
  </WithContext>
)
export default WrapperComponent
