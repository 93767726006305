import { Component } from 'react'
import { Grid } from 'semantic-ui-react'
import { isArray, indexOf } from 'utils'
import FormHint from './FormHint'
class FormHintInline extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hint: null,
      elementId: null
    }
  }

  render () {
    const { id, showerror, rawErrors, device } = this.props
    const title = this.props.title || 'This'
    const { hint, elementId } = this.state
    let draw = false
    if (showerror) {
      draw = true
    } else {
      if (elementId) {
        if (isArray(id)) {
          if (indexOf(id, elementId) > -1) {
            if (hint) draw = true
          }
        } else {
          if (id === elementId) {
            if (hint) draw = true
          }
        }
      }
    }
    const renderContent = draw
      ? <FormHint
        id={id}
        showerror={showerror}
        title={title}
        rawErrors={rawErrors}
        hint={hint}
      />
      : null
    return (
      (device === 'mobile')
        ? draw
          ? <Grid.Column width={6} verticalAlign='bottom'>
          {renderContent}
        </Grid.Column>
          : null
        : <Grid.Column width={6} verticalAlign='bottom'>
          {renderContent}
        </Grid.Column>
    )
  }
}
export default FormHintInline
