import { PureComponent } from 'react'
import { List } from 'semantic-ui-react'
import { Body2, H6, Subtitle2 } from 'components/Header'
import PayoutTerm from './PayoutTerm'
export class PayoutTerms extends PureComponent {
  drawPayoutTermItems (usage) {
    const {
      product: { payoutTerms, productOptionId, coverTerm, defaultPayoutTerm },
      device
    } = this.props

    // if usage === basic, show only the default payoutType(s), else show all
    return payoutTerms
      .filter(
        (pot) => usage !== 'basic' || pot.categoryId === defaultPayoutTerm
      )
      .map((payoutTerm, ix) => {
        const payoutProps = {
          ...this.props,
          index: ix,
          productOptionId,
          coverTerm,
          payoutTerm
        }
        return (
          <div key={`${ix}_payoutterm`} className={device === 'mobile' ? 'listItemsMobilePayment' : ''}>
            <PayoutTerm childKey={`${ix}_payoutterm`} {...payoutProps} />
          </div>
        )
      })
  }

  render () {
    const { contentType, view, id, device } = this.props
    const isReview = contentType === 'review'
    const isDetail = contentType === 'detail'
    if (isReview) {
      if (device === 'mobile') {
        return (
          <>
            <Body2 className='tabDesc'>
              Please select the payment type and proceed
            </Body2>
            {this.drawPayoutTermItems()}
          </>
        )
      }
      return (
        <div className='outer web' id={`${id}_payoutTerms`}>
          <div className='inner'>
            <H6 color='primaryLight' className='reviewSections'>
              Payout
            </H6>
            {this.drawPayoutTermItems()}
          </div>
        </div>
      )
    }
    if (isDetail) {
      return (
        <div id={`${id}_payoutTerms`}>
          <H6 color='primaryLight'>Payout Terms</H6>
          <List>{this.drawPayoutTermItems('basic')}</List>
        </div>
      )
    } else if (view === 'cardView') {
      return (
        <div className='payoutTerms' id={`${id}_payoutTerms`}>
          <Subtitle2 color='secondary'>Payout Terms</Subtitle2>
          <>{this.drawPayoutTermItems('basic')}</>
        </div>
      )
    } else {
      return <div id={`${id}_payoutTerms`}>{this.drawPayoutTermItems()}</div>
    }
  }
}

export default PayoutTerms
