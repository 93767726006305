import { useState } from 'react'
import SelectButtonWidget from './SelectButtonWidget'
import './SelectWidget.scss'

const InsuranceCompanyWidget = (props) => {
  const [insuranceCompany, setInsuranceCompany] = useState(props.value)
  const { formContext: { insurerNames }, onChange, placeholder } = props

  const buildInsurerNameData = () => {
    const insurerNamesData = []
    insurerNames.forEach(ele => {
      const exist = insurerNamesData.findIndex(data => data.id === ele.institutionId)
      if (exist === -1) {
        insurerNamesData.push({ id: ele.id, label: ele.insurerName, value: ele.institutionId })
      }
    })
    return insurerNamesData
  }

  const handleOnItemSelect = (currentInsurerName) => {
    setInsuranceCompany(currentInsurerName)
    onChange(currentInsurerName)
  }

  return (
    <SelectButtonWidget
      id='insuranceCompany'
      label='insuranceCompany'
      placeholder={placeholder}
      className='selectButtonWidget'
      options={{ enumOptions: buildInsurerNameData() }}
      value={insuranceCompany ?? ''}
      schema={{}}
      onChange={(value) => handleOnItemSelect(value)}
    />
  )
}

export default InsuranceCompanyWidget
