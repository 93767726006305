import { productComposer } from '../product'
import { Divider, Button } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import 'components/css/mobileKnowMore.scss'

const MobileKnowMore = (props) => {
  const { itemProps, onBack, expanded } = props
  const ComponentMap = productComposer({
    ...itemProps,
    contentType: 'detail',
    expanded
  })
  const { insuranceType } = itemProps
  const includedInsuranceTypes = ['term', 'annuity']
  return (
    <div
      className='mobileKnowMore'
    >
      <div className='flexContainer primaryDetails'>
        <div className='leftColumn'>
          {ComponentMap.InsurerWithLogo}
        </div>
        <div className='rightColumn flexContainer'>
          {insuranceType !== 'annuity' ? ComponentMap.Premium : ComponentMap.AnnuityPayout}
          {ComponentMap.Review}
        </div>
      </div>
      <div className='otherDetails'>
        <div className='flexContainer otherDetailsSection'>
          <div className='leftColumn'>
            {insuranceType !== 'annuity' ? ComponentMap.ClaimsSettled : ComponentMap.PurchasePrice}
          </div>
          <div className='rightColumn'>
            {ComponentMap.ProductBrochure}
          </div>
        </div>
        <Divider />
        {insuranceType === 'annuity' &&
          <>
            <div className='otherDetailsSection'>
              <H6 color='primaryLight'>
                Product Details
              </H6>
              <div className='flexContainer'>
                <div className='leftColumn'>
                  {(ComponentMap.PayoutFrequency)}
                </div>
                <div className='rightColumn'>
                  {ComponentMap.AnnuityPayout}
                </div>
              </div>
            </div>
            <Divider />
          </>}
        {includedInsuranceTypes.includes(insuranceType) && (
          <>
            <div className='otherDetailsSection'>
              {ComponentMap.PayoutTerms}
            </div>
            <Divider />
          </>
        )}
        {insuranceType !== 'annuity' &&
          <>
            <div className='otherDetailsSection'>
              {ComponentMap.Features}
            </div>
            <Divider />
          </>}
        <div className='otherDetailsSection'>
          {ComponentMap.Benefits}
        </div>
        <div className='otherDetailsSection'>
          {ComponentMap.Review}
          <Button
            transparent
            id='knowMoreBack'
            content='BACK'
            onClick={onBack}
          />
        </div>
      </div>
    </div>
  )
}
export default MobileKnowMore
