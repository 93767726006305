import { isNotDefined } from 'utils/lib/basic'
import SelectButtonWidget from './SelectButtonWidget'
import './SelectWidget.scss'

const CountryWidget = (props) => {
  const { formContext, value, placeholder, options, schema, onChange } = props

  const buildCountryOptions = () => {
    const { countries } = formContext
    const { nri } = options
    if ((!isNotDefined(nri) && nri) || isNotDefined(nri)) {
      return countries.map((country) => {
        return { key: country.countryShortCode, label: country.countryName, value: country.countryName }
      })
    }
    return [{ value: 'India', label: 'India' }]
  }

  return (
    <SelectButtonWidget
      id='country'
      label='country'
      placeholder={placeholder}
      options={{ enumOptions: buildCountryOptions() }}
      className='selectButtonWidget'
      value={value}
      schema={schema}
      onChange={onChange}
    />
  )
}

export default CountryWidget
