
const schema = {
  title: '',
  description: '',
  type: 'object',
  properties: {
    paymentOption: {
      title: 'How do you want to pay?',
      type: 'string',
      enum: [

      ],
      enumNames: [

      ]
    }
  },
  required: [
    'paymentOption'
  ],
  dependencies: {
    paymentOption: {
      oneOf: [
        {
          properties: {
            paymentOption: {
              enum: [
                'SP'
              ]
            }
          }
        }
      ]
    }
  }
}
const uiSchema = {
  paymentOption: {
    'ui:widget': 'SelectButtonWidget',
    className: 'primary',
    'ui:options': {
    }
  },
  ppt: {
    'ui:widget': 'AgeWidget',
    className: 'secondary field',
    'ui:options': {
      displayText: 'Years'
    }
  },
  paymentFrequency: {
    'ui:widget': 'SelectOptionButton',
    className: 'secondary field',
    'ui:options': {}
  }
}
export {
  schema,
  uiSchema
}
