import React, { useContext } from 'react'

const StoreContext = React.createContext()

export function useAppContext () {
  const context = useContext(StoreContext)
  if (!context) {
    throw new Error('You need to wrap with StoreContextProvider.')
  }
  const store = context.getState()
  return store
}
export default StoreContext
