import { connect } from 'react-redux'

import UserApplications from './UserApplications'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    applications: models.applications.getApplications,
    selectedApplicationNumber: models.applications.getSelectedApplicationNumber,
    selectedApplicationNumberForPolicy: models.policies.getSelectedPolicy,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    count: models.applications.getApplicationsCount
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  const { selectedApplicationNumber, selectedApplicationNumberForPolicy } = selectorData
  const { getApplicationById } = select.applications
  const { getPolicyById } = select.policies
  const selectedApplication = getApplicationById(state, {
    insuranceType,
    applicationNumber: selectedApplicationNumber
  })
  const selectedPolicyData = getPolicyById(state, {
    insuranceType,
    id: selectedApplicationNumberForPolicy
  })
  return {
    ...ownProps,
    ...selectorData,
    loadingApplications: state.loading.effects.applications.loadApplications,
    loadingStatus: state.loading.effects.applications.getStatus,
    selectedApplication,
    selectedPolicyData
  }
}

const mapDistpatchProps = (dispatch, ownProps) => {
  const {
    setContinueForm,
    insuranceType,
    store: { select, getState }
  } = ownProps
  return {
    getStatus: (applicationNumber) => {
      const query = {
        insuranceType,
        applicationNumber
      }
      return dispatch.applications.getStatus(query)
    },
    buildEnquiry: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.applications.buildEnquiry(query)
    },
    getBookingFormType: (applicationNumber) =>
      select.applications.getBookingFormType(getState(), {
        insuranceType,
        applicationNumber
      }),
    getFormStepStatus: (applicationNumber) =>
      select.applications.getFormStepStatus(getState(), {
        insuranceType,
        applicationNumber
      }),
    loadApplications: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.applications.loadApplications(query)
    },
    updateSelectedApplicationNumber: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.applications.updateSelectedApplicationNumber(query)
    },
    getSmartCovrStatus: (applicationNumber) =>
      select.applications.getSmartCovrStatus(getState(), {
        insuranceType,
        applicationNumber
      }),
    setContinueForm
  }
}

const UserApplicationsContainer = connect(
  mapStateToProps,
  mapDistpatchProps
)(UserApplications)
export default UserApplicationsContainer
