import { PureComponent } from 'react'
import { UpdateContext } from 'components/context'
import { adopt } from 'react-adopt'
import HealthInsuranceSingleForm from 'components/health/HealthInsuranceSingleForm'
import { Form, Header, Icon, Menu, Modal } from 'semantic-ui-react'
import { isNotDefined } from 'utils'

import PaymentFrequency from './PaymentFrequncy'

class FilterMenuWebHealth extends PureComponent {
  constructor (_props) {
    super(_props)
    this.state = {
      open: false
    }
    this.setFormData = this.setFormData.bind(this)
  }

  setFormData (newFormData) {
    this.setState({
      open: false
    }, this.props.setFormData(newFormData))
  }

  render () {
    const { open, id } = this.state
    const { formData, ComponentMap, onFilterChange, filters, disableEdit, configure, insuranceType, productData: { paymentFrequency } } = this.props
    let currentPaymentFrequency = paymentFrequency
    if (filters && !isNotDefined(filters.filterData.paymentFrequency)) {
      currentPaymentFrequency = filters.filterData.paymentFrequency[0].value
    }
    return (
      <Menu fluid>
        <Menu.Item>
          <Form>
            <Header sub className='mt-0'> Cover Term</Header>
            {ComponentMap.coverTermHealth}
          </Form>
        </Menu.Item>
        {
          formData.policyType !== 'multiIndividual' && (
            <Menu.Item>
              <Form>
                <Header sub className='mt-0'> Sum Assured</Header>
                {ComponentMap.healthSumAssured}
              </Form>
            </Menu.Item>
          )
        }
        {
          !disableEdit && (
            <Menu.Item className='healthPersonalDetails'>
              <Modal
                className='editMemberDetailModal'
                open={open}
              >
                <HealthInsuranceSingleForm
                  id={id}
                  schema={configure.onboard.editMembersSchema.properties[formData.policyType]}
                  {...this.props}
                  listing
                  onBack={() => {
                    this.setState({
                      open: false
                    })
                  }}
                  setFormData={this.setFormData}
                />
              </Modal>
              <span className='editMembersDetails' id='moreFilters' onClick={() => this.setState({ open: true })}>Add / Remove and Edit Member Details <Icon name='pencil' /></span>
              <PaymentFrequency onFilterChange={onFilterChange} configure={configure} currentFrequency={currentPaymentFrequency} insuranceType={insuranceType} />
            </Menu.Item>
          )
        }
        {
          !disableEdit && (
            <Menu.Item>
              <Form>
                {ComponentMap.moreFilters}
              </Form>
            </Menu.Item>
          )
        }
      </Menu>
    )
  }
}

const WithContext = adopt({
  update: <UpdateContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ update }) => {
        return (
          <FilterMenuWebHealth
            {...props}
            setFormData={update.setOnboardFormData}
          />
        )
      }
    }
  </WithContext>
)
export default WrapperComponent
