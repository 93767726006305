import React from 'react'
import JsonSchemaForm from '../jsonSchemaForms/jsonschema-form-semantic-ui'
import LabelField from '../jsonSchemaForms/components/LabelField'
import FormHint from '../jsonSchemaForms/components/FormHint'
import applyRules from 'components/RenderJsonSchemaForms'
import Engine from 'json-rules-engine'
import {
  isNotDefined,
  expandRules,
  find,
  isArray
} from 'utils'
import widgets from '../jsonSchemaForms/widgets'
import FieldRow from 'components/jsonSchemaForms/components/FieldRow'
import { Grid } from 'semantic-ui-react'
import { get } from 'lodash'
import './JSONSchemaOnboard.scss'

export default class JsonSchemaOnboardForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      liveValidate: props.liveValidate
    }
    this.fieldTemplate = this.fieldTemplate.bind(this)
    this.objectTemplate = this.objectTemplate.bind(this)
    this.transformErrors = this.transformErrors.bind(this)
    this.focusElement = null
  }

  objectTemplate (props) {
    let displayElements
    const { schema, properties, uiSchema } = props
    const rowElements = []
    if (uiSchema['ui:widget'] && uiSchema['ui:widget'] === 'hidden') {
      return null
    }
    // Array of arrays for each row
    if (props.uiSchema.classNames && props.uiSchema.classNames.indexOf('group') > -1) {
      Object.keys(schema.properties).forEach((ky) => {
        const row = uiSchema[ky].row
        const property = find(properties, (pr) => pr.content.key === ky)
        if (property) {
          if (isArray(rowElements[row])) {
            rowElements[row].push(property)
          } else {
            rowElements[row] = []
            rowElements[row].push(property)
          }
        } else {
          console.log(`${ky} in uiSchema not in main schema`)
        }
      })
      displayElements = rowElements.map((elements) =>
        elements.map((element) => element.content)
      )
    } else {
      const row = Object.keys(schema.properties).reduce((acc, prop) => {
        const property = find(properties, (pr) => pr.content.key === prop)
        if (!isNotDefined(uiSchema[prop]?.className) && uiSchema[prop]?.className.indexOf('group') > -1) {
          const rowCount = uiSchema[prop].row
          acc[rowCount] = acc[rowCount] || []
          acc[rowCount].push(property)
        } else {
          acc['-1'] = acc['-1'] || []
          acc['-1'].push(property)
        }
        return acc
      }, {})
      displayElements = Object.keys(row).map((r, ix) => {
        const elements = row[r]
        const classes = r < 0 ? 'column' : 'row'
        return (
          <div className={classes} key={ix}>
            {elements.map((el) => el.content)}
          </div>
        )
      })
    }
    return (
      <Grid stackable columns='equal' id={props.idSchema.$id}>
        {displayElements}
      </Grid>

    )
  }

  fieldTemplate (props) {
    const { id, classNames, label, required, children, rawHelp, uiSchema } = props
    const { noLabel } = this.props
    let className = `${classNames} ${uiSchema.className} ${id}`
    if (props.hidden) {
      return null
    }
    let showerror = false
    if (!isNotDefined(props.rawErrors)) {
      showerror = props.rawErrors.length > 0
    }

    const labelField = !noLabel && label && (
      <LabelField
        className='control-label'
        htmlFor={id}
        title={label}
        required={required}
      />
    )

    let hideTitle = false
    if (className && className.indexOf('no-title') > -1) {
      hideTitle = true
    }
    if (uiSchema.className && uiSchema.className.indexOf('field') > -1) {
      className = classNames + ' primaryrows'
      if (id === 'root') {
        className = className + ' rootField'
      }
      if (uiSchema.className && uiSchema.className.indexOf('custom') > -1) {
        return children
      }
      const options = props.uiSchema['ui:options']
      let icon, displayText
      if (!isNotDefined(options)) {
        icon = options.icon
        displayText = options.displayText
      }
      return (
        <Grid.Column className={props.uiSchema.className} id={id}>
          <FieldRow
            key={id}
            displayText={displayText}
            icon={icon}
          >
            {!hideTitle && labelField}
            {children}
            {showerror && <FormHint id={id} showerror={showerror} rawErrors={props.rawErrors} />}
            {rawHelp && <small className='form-text text-muted'>{rawHelp}</small>}
          </FieldRow>
        </Grid.Column>
      )
    } else {
      return (
        <Grid.Row className={className}>
          {!hideTitle && labelField}
          {children}
          <FormHint id={id} showerror={showerror} rawErrors={props.rawErrors} />
          {rawHelp && <small className='form-text text-muted'>{rawHelp}</small>}
        </Grid.Row>
      )
    }
  }

  transformErrors (errors) {
    const { schema } = this.props
    let errorArr = errors
    if (this.props.transformErrors) {
      errorArr = this.props.transformErrors(errorArr)
    }
    errorArr.forEach(error => {
      const selectedProperty = get(schema, `properties${error.property}`)
      if (error.name === 'isNotEmpty') {
        error.message = `${selectedProperty.title} is a required field.`
      } else if (error.name === 'smartcovr-dateValidation') {
        error.message = `${selectedProperty.title} is not a valid date.`
      }
    })
    return errorArr
  }

  render () {
    const { liveValidate } = this.state
    const {
      schema,
      formData,
      uiSchema,
      rules,
      onSubmit,
      onChange,
      onFocus,
      onError,
      className,
      fields,
      onValidate,
      ...formProps
    } = this.props
    const expandedRules = expandRules(rules)
    const FormWithConditionals = applyRules(
      schema,
      uiSchema,
      expandedRules,
      Engine
    )(JsonSchemaForm)
    return (
      <FormWithConditionals
        className={`jsonSchemaForm ${className}`}
        FieldTemplate={this.fieldTemplate}
        ObjectFieldTemplate={this.objectTemplate}
        schema={schema}
        uiSchema={uiSchema}
        onSubmit={onSubmit}
        onChange={onChange}
        onError={onError}
        onFocus={onFocus}
        fields={fields}
        formData={formData}
        widgets={widgets}
        liveValidate={liveValidate}
        noHtml5Validate
        showErrorList={false}
        validate={onValidate}
        transformErrors={this.transformErrors}
        {...formProps}
      >
        {this.props.children}
      </FormWithConditionals>
    )
  }
}
