import { Component } from 'react'
import { Button, Icon, Grid } from 'semantic-ui-react'
import FlatSegment from 'components/FlatSegment'
import { JsonSchemaValidation, JsonSchemaSingleFormNew } from 'components/jsonSchemaForms'
import PropTypes from 'prop-types'
import RcSteps from 'components/RcSteps'
import { isNotDefined } from 'utils'
import HeaderField from './components/HeaderField'
import produce from 'immer'
// import {formatNumber} from '../../utils'
/* eslint react/jsx-handler-names: "off" */

class JsonSchemaMultiPageForm extends Component {
  constructor (props) {
    super(props)
    this.formData = props.formData
    this.complete = false
    this.getFormButtons = this.getFormButtons.bind(this)
    this.getCurrentStep = this.getCurrentStep.bind(this)
    this.previousStep = this.previousStep.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.buildStepsStatusBarProps = this.buildStepsStatusBarProps.bind(this)
    this.getStepsStatusBar = this.getStepsStatusBar.bind(this)
    this.onValidate = this.onValidate.bind(this)
    this.onReviewEdit = this.onReviewEdit.bind(this)
    this.intermediateStepDone = this.intermediateStepDone.bind(this)
    this.isPreveousButtonClicked = false
  }

  // componentDidMount () {
  //   this.props.history.listen((location, action) => {
  //     const currentStep = this.props.enquiry.getBookingStep()
  //     if (location.pathname === '/select-product' && action === 'POP' && currentStep > 0) {
  //       this.props.onPreviousStep(currentStep)
  //       this.props.history.push('/booking/pre-payment')
  //     }
  //   })
  // }
  initialiseIntermediateStepIndex () {
    const {
      configure,
      bookingType,
      formData: { insured }
    } = this.props
    let stepsConfig = configure.booking.intermediateSteps[bookingType][insured]
    if (isNotDefined(stepsConfig)) {
      stepsConfig = configure.booking.intermediateSteps[bookingType].default
    }
    return stepsConfig.map(() => false)
  }

  intermediateStepDone (index) {
    const newState = produce(this.state, draft => {
      draft.intermediateSteps[index] = true
    })
    this.setState(newState)
  }

  componentDidUpdate () {
    const { enquiryLoading, currentStep, onDone, applicableStep } = this.props
    if (
      currentStep === this.props.formSteps.length - 1 &&
      !enquiryLoading &&
      this.complete
    ) {
      this.complete = false
      onDone(applicableStep)
    }
  }

  compomentWillRecieveProps (_nextProps) {
    if (isNotDefined(this.formData)) {
      this.formData = this.props.formData
    }
  }

  onValidate (formData, errors) {
    if (errors.panDetails && !isNotDefined(formData.jointPanDetails) && formData.panDetails?.panNo === formData.jointPanDetails?.panNo) {
      errors.panDetails.panNo.addError(': Primary And Secondary insured should be different')
      errors.jointPanDetails.panNo.addError(': Primary And Secondary insured should be different')
    }
    this.formData = formData
    const currentStep = this.props.currentStep
    this.props.setLiveValidate()
    const mobileData = formData.mobile ?? formData.propMobile
    const mobileError = JsonSchemaValidation.validateMobileNumber(mobileData, this.props.countries)
    if (mobileError && errors.mobile) {
      errors.mobile.addError(mobileError)
    } else if (mobileError && errors.propMobile) {
      errors.propMobile.addError(mobileError)
    }
    if (this.props.formSteps[currentStep].schemaName === 'nomineeSchema') {
      if (isNotDefined(formData.nominees) || formData.nominees.length === 0) {
        errors.nominees.addError('Minimum 1 nominee required')
        if (isNotDefined(formData.nominees)) {
          this.formData.nominees = []
        }
      } else {
        let percentSum = 0
        formData.nominees.forEach(
          (k) => (percentSum = percentSum + k.percentage)
        )
        if (percentSum !== 100) {
          errors.nominees.addError(
            'Sum of percentages should be exactly equal to 100'
          )
        }
      }
    }
    return errors
  }

  shouldStepChange (newStep) {
    if (newStep < 0) {
      return false
    }
    if (newStep > this.props.formSteps.length - 1) {
      return false
    }
    return true
  }

  nextStep (formData) {
    // validation of formData to be done
    const {
      currentStep,
      institutionId,
      setApplicationFormData,
      applicableStep,
      bookingStepIndex
    } = this.props
    this.formData = formData
    let nextStep = currentStep
    if (currentStep < this.props.formSteps.length - 1) {
      nextStep = nextStep + 1
    }
    let nextBookingStepIndex = bookingStepIndex
    if (currentStep === this.props.formSteps.length - 1) {
      this.complete = true
      nextBookingStepIndex = bookingStepIndex + 1
    }
    setApplicationFormData({
      currentStep: nextStep,
      applicationFormData: formData,
      institutionId,
      stepDetails: applicableStep,
      bookingStepIndex: nextBookingStepIndex
    })
  }

  previousStep (e) {
    const {
      currentStep,
      institutionId,
      setApplicationFormData,
      bookingStepIndex,
      multiSteps,
      applicableStep
    } = this.props
    e.preventDefault()
    if (currentStep === 0) {
      if (bookingStepIndex === 0) {
        this.props.redirectToListing()
      } else {
        if (bookingStepIndex > 0) {
          // validation of formData to be done
          setApplicationFormData({
            bookingStepIndex: bookingStepIndex - 1,
            institutionId,
            currentStep: multiSteps[bookingStepIndex - 1].steps.length - 1,
            stepDetails: applicableStep
          })
        }
      }
    } else {
      if (currentStep > 0) {
        // validation of formData to be done
        if (this.shouldStepChange(currentStep - 1)) {
          setApplicationFormData({
            currentStep: currentStep - 1,
            institutionId,
            stepDetails: applicableStep
          })
        }
      }
    }
  }

  onReviewEdit (formData) {
    const { setApplicationFormData, institutionId, applicableStep } = this.props
    this.formData = formData
    setApplicationFormData({
      applicationFormData: formData,
      institutionId,
      stepDetails: applicableStep
    })
  }

  onSubmit (data) {
    const currentStep = this.props.currentStep
    const { formData } = data
    if (currentStep < this.props.formSteps.length) {
      this.onChange(data)
      this.nextStep(formData)
    }
  }

  onChange (formData) {
    this.formData = formData.formData
  }

  getFormButtons () {
    const { currentStep, bookingStepIndex, multiSteps, formSteps } = this.props
    let buttonText = 'Save & Proceed'
    if (bookingStepIndex === multiSteps.length - 1 && currentStep === formSteps - 1) {
      buttonText = 'Proceed to Pay'
    }
    const buttons = (
      <>
        <Button
          id={currentStep === formSteps.length - 1
            ? 'doneButton'
            : 'nextButton'}
          type='submit'
          content={buttonText}
          secondary
        />
        <Button
          id='backButton'
          content='BACK'
          basic
          onClick={e => this.previousStep(e)}
        />
      </>
    )
    return (
      <div className='formButtons'>
        {formSteps.length > 0 ? buttons : null}
      </div>
    )
  }

  getCurrentStep () {
    const formButtons = this.getFormButtons()
    const { device, validateFlags, currentStep, bookingStepIndex } = this.props
    const currentStepSchema = this.props.formSteps[currentStep]
    const formData = this.formData
    window.scrollTo(0, 0)
    return (
      <JsonSchemaSingleFormNew
        schema={currentStepSchema.schema}
        uiSchema={currentStepSchema.uiSchema}
        formContext={{
          ...currentStepSchema.formContext,
          device,
          countries: this.props.countries
        }}
        className='single-step-form'
        onSubmit={this.onSubmit}
        onChange={this.onChange}
        onValidate={this.onValidate}
        formData={formData}
        rules={currentStepSchema.rules}
        liveValidate={validateFlags[bookingStepIndex][currentStep]}
        device={device}
      >
        {formButtons}
      </JsonSchemaSingleFormNew>
    )
  }

  buildStepsStatusBarProps () {
    const currentStep = this.props.currentStep
    const stepsProps = this.props.formSteps.map(step => ({
      key: step.schemaName,
      icon: <Icon name={step.icon} />,
      title: step.title,
      active: false,
      completed: false
    }))
    stepsProps.forEach((sp, ix) => {
      if (ix < currentStep) {
        sp.active = false
        sp.completed = true
      } else if (ix === currentStep) {
        sp.active = true
        sp.completed = false
      } else {
        sp.active = false
        sp.completed = false
      }
    })
    return stepsProps
  }

  getStepsStatusBar (stepsBarDescription) {
    const { device, formSteps, bookingStepIndex } = this.props
    if (device !== 'mobile' && formSteps.length > 1) {
      const stepsProps = this.buildStepsStatusBarProps(stepsBarDescription)
      if (stepsProps.length > 0) {
        return (
          <>
            <RcSteps
              hideTitle={this.props.device === 'mobile'}
              steps={stepsProps}
              bookingStepIndex={bookingStepIndex}
            />
          </>
        )
      }
    }
    return null
  }

  drawGrid () {
    const thisStep = this.getCurrentStep()
    return (
      <Grid>
        <Grid.Row columns={1} divided>
          <Grid.Column>
            <Grid centered>
              <Grid.Row columns={1}>
                <Grid.Column>{thisStep}</Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }

  render () {
    const getStepsStatusBar = this.getStepsStatusBar()
    const currentStep = this.props.currentStep
    const { device, stepDetais, payment } = this.props
    const segmentFlag =
      device === 'mobile' &&
      this.props.formSteps[currentStep].schemaName === 'reviewForm'

    return (
      <div>
        {!payment && (
          <HeaderField
            as='h5'
            color='primaryLight'
            title={stepDetais.title}
            description={stepDetais.desc}
          />
        )}
        {getStepsStatusBar}
        {/* <Transition
          visible={!updateBookingLoading}
          animation={'scale'}
          duration={!updateBookingLoading ? 300 : 50}
        > */}
        <div>
          {!segmentFlag
            ? <FlatSegment>{this.drawGrid()}</FlatSegment>
            : this.drawGrid()}
        </div>
        {/* </Transition> */}
      </div>
    )
  }
}
JsonSchemaMultiPageForm.propTypes = {
  formSteps: PropTypes.array.isRequired,
  // onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  formData: PropTypes.object
  // previousStep: PropTypes.func.isRequired,
  // nextStep: PropTypes.func.isRequired
}

export default JsonSchemaMultiPageForm
