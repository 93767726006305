import { Component } from 'react'
import { ErrorContext, errorInitialState } from 'components/context/ErrorContext'
import produce from 'immer'
import ErrorScreen from 'components/errorScreens/ErrorScreen'
import config from '../config.js'
import { connect } from 'react-redux'
import { isNotEmpty } from 'utils'
import getStore from '../'
import { isNotDefined } from 'utils/lib/basic'
/* eslint react/jsx-handler-names: "off" */
class ErrorBoundary extends Component {
  constructor (props) {
    super(props)
    this.state = {
      ...errorInitialState,
      newErrorLoading: props.newErrorLoading
    }
    this.errorProps = {
      handleError: this.handleError.bind(this)
    }
    this.redirectToOnboard = this.redirectToOnboard.bind(this)
    this.redirectToPath = this.redirectToPath.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  static getDerivedStateFromProps (nextProps, state) {
    const { newErrorLoading, error, cleanErrors } = nextProps
    if (state.newErrorLoading !== newErrorLoading) {
      if (isNotEmpty(error)) {
        const newState = produce(state, (draft) => {
          draft.hasError = true
          draft.error = error
          draft.newErrorLoading = newErrorLoading
        })
        cleanErrors()
        return newState
      }
    }
    return state
  }

  componentDidUpdate (prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false, error: null })
    }
  }

  componentDidCatch (error, _info) {
    this.setState(produce(this.state, draft => {
      draft.hasError = true
      draft.error = error
    }))
  }

  redirectToPath (redirectPath, backPath) {
    const { insuranceType, redirectToPath } = this.props
    redirectToPath(redirectPath, `/${insuranceType}/${backPath}`)
  }

  redirectToOnboard () {
    const { redirectToPath } = this.props
    redirectToPath('onboard')
  }

  handleError (error) {
    if (error.errorName === 'InvalidEnquiry') {
      this.redirectToOnboard()
    } else {
      this.setState(produce(this.state, draft => {
        draft.hasError = true
        draft.error = error
      }))
    }
  }

  onClick () {
    window.location.reload()
  }

  render () {
    const { configure } = this.props
    const { hasError, error } = this.state
    if (hasError) {
      if (error.errorName === 'InvalidEnquiry') {
        this.handleError(error)
      }
      if (error.errorName === 'InvalidProductOptionId') {
        const currentInsuranceType = getStore().getState().insuranceEnquiry.currentInsuranceType
        const formData = getStore().getState().insuranceEnquiry[currentInsuranceType].formData
        if (['car', 'bike'].includes(currentInsuranceType) && !isNotDefined(formData.businessOption) && formData.businessOption === 'renewal') {
          this.redirectToPath('renew-policy', 'onboard')
        } else {
          this.redirectToPath('select-product', 'onboard')
        }
      }
      let errorConfig = {}
      let errorKey
      if (['uiApiError', 'incorrectFieldsPassed', 'invalidFieldPassed'].indexOf(error.errorName) > -1) {
        errorKey = 'somethingWentWrong'
      } else if (['networkError'].indexOf(error.errorName) > -1) {
        errorKey = 'networkError'
      } else if (error.errorName === 'clientSessionExpired') {
        errorKey = 'clientSessionExpired'
      }
      if (isNotEmpty(configure)) {
        errorConfig = configure.common.errorScreens[errorKey]
      } else {
        errorConfig = config[errorKey]
      }
      if (!isNotEmpty(errorConfig)) {
        errorConfig = config.defaultErrorConfig
      }
      return <ErrorScreen fullScreen {...errorConfig} onClick={this.onClick} />
    }
    return (
      <ErrorContext.Provider value={{ ...this.errorProps, error }}>
        {this.props.children}
      </ErrorContext.Provider>
    )
  }
}
const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    error: models.errors.getCurrentError
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    ...ownProps,
    ...selectorData,
    newErrorLoading: state.loading.effects.errors.setCurrentError
  }
}
const mapDispatchToProps = (dispatch, ownProps) => {
  const { insuranceType } = ownProps
  return {
    cleanErrors: () => {
      return dispatch.errors.cleanErrors({
        insuranceType
      })
    }
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)
