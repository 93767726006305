import mfProducts from './mfProducts'
import mfConfigure from './mfConfigure'
// import mfUuser from './mfUuser'
import mfSell from './mfSell'
import mfBuy from './mfBuy'

export default {
  mfProducts,
  mfConfigure,
  mfSell,
  mfBuy
}
