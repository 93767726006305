// @flow

import { useEffect } from 'react'
import { Button } from 'semantic-ui-react'
import { H5, Body2, Subtitle1 } from 'components/Header'
import 'components/css/intermediateScreen.scss'
import { isNotDefined } from 'utils/lib/basic'
const IntermediateScreen = (props) => {
  const { bookingStepIndex, multiSteps, applicableStep, intermediateStepDone, onBack, bookingType } = props
  const isSisStep = !isNotDefined(applicableStep.action) && applicableStep.action === 'sis'
  const { title, description, buttonText } = applicableStep
  useEffect(() => {
    if (multiSteps.length === 1) {
      intermediateStepDone(bookingStepIndex)
    }
  },
  // eslint-disable-next-line
  [])

  const renderStepDisplay = () => {
    if (multiSteps.length > 0) {
      return (
        multiSteps.map((multiStep, ix) => {
          let className = ''
          if (multiStep.stepIndex < bookingStepIndex) {
            className = 'is-done'
          } else if (multiStep.stepIndex === bookingStepIndex) {
            className = 'current'
          }
          return (
            <li key={ix} className={`StepProgress-item ${className}`}>
              <Subtitle1>
                {multiStep.title}
              </Subtitle1>
              <Body2>
                {multiStep.desc}
              </Body2>
            </li>
          )
        })
      )
    } else if (bookingType === 'postPaymentSteps' && multiSteps.length === 0) {
      return (
        <li className='StepProgress-item'>
          <Subtitle1>
            Download Policy
          </Subtitle1>
          <Body2>
            Continue Downloading your policy document
          </Body2>
        </li>
      )
    }
  }
  return (
    <div className='intermediateStep'>
      <H5>
        {title}
      </H5>
      <Body2>
        {description}
      </Body2>
      <div className='wrapper'>
        <ul className='StepProgress'>
          {renderStepDisplay()}
        </ul>
      </div>
      <Button
        secondary
        id={`${bookingType}-${bookingStepIndex}`}
        onClick={() => {
          intermediateStepDone(bookingStepIndex)
        }}
      >
        {buttonText}
      </Button>
      {!isSisStep && (
        <Button
          id='backButton'
          content='BACK'
          basic
          onClick={(e) => onBack(e)}
        />
      )}
    </div>
  )
}

export default IntermediateScreen
