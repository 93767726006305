import { connect } from 'react-redux'
import { ViewRegisteredClaims } from './components/claims'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    distributorId: models.configure.getDistributorId,
    customerDetails: models.customer.getCustomerDetails,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    // availableProducts: models.configure.getAvailableProducts,
    claimApplications: models.claimsService.getApplications,
    count: models.claimsService.getApplicationsCount,
    selectedApplicationNumber: models.claimsService.getSelectedApplicationNumber
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  const { selectedApplicationNumber } = selectorData
  const { getApplicationById } = select.claimsService
  const selectedApplication = getApplicationById(state, {
    insuranceType,
    applicationNumber: selectedApplicationNumber
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...ownProps,
    ...selectorData,
    insuranceType,
    store: ownProps.store,
    selectedApplication,
    ldng: state.loading.effects.claimsService.getClaimHoldings
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const {
    insuranceType
  } = ownProps
  return {
    loadApplications: (data) => {
      const query = {
        insuranceType,
        id: 'holdings',
        ...data
      }
      return dispatch.claimsService.getClaimHoldings(query)
    },
    updateSelectedApplicationNumber: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.claimsService.updateSelectedApplicationNumber(query)
    }
    // effects go here
    // selectors go here
  }
}

const RegisteredClaimsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewRegisteredClaims)
RegisteredClaimsContainer.path = 'registered-claims'
export default RegisteredClaimsContainer
