import { PureComponent } from 'react'
import { Statistic, Icon, Header } from 'semantic-ui-react'
import { rupeeFormatter, isNotEmpty } from 'utils'
import DataLoader from 'components/DataLoader'
import { LoadingContext } from 'components/context'
class PurchasePrice extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { index: 0 }
    this.handleOnChange = this.handleOnChange.bind(this)
  }

  handleOnChange (e, data) {
    this.setState({ index: data.value })
  }

  render () {
    const { product, id, device, className } = this.props
    const loading = this.context
    let premiumLoading = false
    if (isNotEmpty(loading)) {
      premiumLoading = loading.status
    }
    const amountWithLabels = function amountWithLabels (amount) {
      const crore = 10000000
      const lakh = 100000
      const tho = 1000
      if (amount > crore) {
        return {
          amount: (amount / crore).toFixed(2),
          label: ' C'
        }
      } else if (amount >= lakh) {
        return {
          amount: (amount / lakh).toFixed(2),
          label: ' L'
        }
      } else {
        return {
          amount: (amount / tho).toFixed(0),
          label: ' K'
        }
      }
    }
    const purchasePrice = amountWithLabels(product.purchasePrice)
    const purchaseAmount = purchasePrice.amount
    const purchaseLabel = purchasePrice.label

    if (device === 'mobile') {
      return (
        <Statistic textAlign='right' color='green' className={`alignLeft ${className}`}>
          <Header sub color='black'>Purchase Price</Header>
          <DataLoader
            loading={premiumLoading}
          >
            <Statistic.Value id={`${id}-premiumAmount`}>
              <Icon size='small' name='rupee' />
              {rupeeFormatter(purchaseAmount)}{purchaseLabel}
            </Statistic.Value>
          </DataLoader>
        </Statistic>
      )
    }
    return (
      <Statistic color='green' className={`alignLeft productPremium ${className}`}>
        <Statistic.Label>Purchase Price</Statistic.Label>
        <DataLoader
          loading={premiumLoading}
        >
          <Statistic.Value id={`${id}-premiumAmount`}>
            <Icon size='small' name='rupee' />
            {rupeeFormatter(purchaseAmount)}{purchaseLabel}
          </Statistic.Value>
        </DataLoader>
      </Statistic>
    )
  }
}
PurchasePrice.contextType = LoadingContext
export default PurchasePrice
