import { isNotDefined, isNotEmpty } from 'utils'
import {
  effectsHandler
} from './modelHelpers'
import produce from 'immer'
import { validateEnquiry, populateMaxMinDates } from './helpers/enquiryHelper'
import { setEnquiryData, setEnquiry, loadEnquiryFromDb, setApplicationFormData, setChosenProductWithApplicationData, updateEnquiryWithPolicyDetails } from './insuranceEnquiryEffects'

/**
   * This function creates the enquiry with enquiryId and insurance type
   *
   * @param {*} state
   * @param {*} insuranceType
   * @param {*} insuranceEnquiry
   * @param {*} enquiryId
   * @returns object
   */
const buildEnquiryState = (state, insuranceType, insuranceEnquiry, enquiryId) => {
  enquiryId = enquiryId || state.enquiryId
  const currentInsuranceType = insuranceType || state.currentInsuranceType
  if (isNotDefined(enquiryId) || enquiryId.length === 0) {
    console.log('enquiry model does not have id')
  }
  if (!isNotDefined(insuranceEnquiry)) {
    Object.keys(insuranceEnquiry).forEach(key => {
      state[insuranceType][key] = insuranceEnquiry[key]
    })
    state.enquiryId = enquiryId
    state.currentInsuranceType = currentInsuranceType
  }
  return state
}
// insuranceEnquiry object and its state
// get defaults from insuranceConfigure
const insuranceEnquiry = {
  name: 'insuranceEnquiry',
  state: {
    enquiryId: '',
    term: {
      formData: {
      },
      productData: {},
      chosenProduct: {},
      application: {
        // [institutionId]: {
        //   applicationFormData: {},
        //   currentStep: 0,
        //   maxSteps: 0
        // }
      },
      dropOff: ''
    },
    car: {
      formData: {},
      productData: {},
      chosenProduct: {},
      application: {}
    },
    bike: {
      formData: {},
      productData: {},
      chosenProduct: {},
      application: {}
    },
    health: {
      formData: {},
      filterData: {},
      productData: {},
      chosenProduct: {},
      application: {}
    },
    annuity: {
      formData: {},
      productData: {},
      chosenProduct: {},
      application: {}
    }
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Gets form data from the state
     *
     * @returns
     */
    // chcek if produce is required
    getFormData () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceEnquiry, insuranceType) => produce(insuranceEnquiry[insuranceType].formData, (fd) => fd)
      )
    },
    /**
     * Gets filter data from the state
     *
     * @returns
     */
    getFilterData () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceEnquiry, insuranceType) => produce(insuranceEnquiry[insuranceType].filterData, (fd) => fd)
      )
    },
    /**
     * Gets validation result for formdata
     *
     * @returns
     */
    checkFormDataValidity () {
      return createSelector(
        slice,
        (state, { insuranceType }) => ({
          insuranceType,
          insuranceConfigure: state.insuranceConfigure[insuranceType]
        }),
        (insuranceEnquiry, { insuranceConfigure, insuranceType }) => {
          const enquirySchema = produce(insuranceConfigure.productConfig.validation.schema, (draftSchema) => {
            const { age } = insuranceConfigure.productConfig.validation
            return (age && insuranceType === 'term') ? populateMaxMinDates(draftSchema, age.min, age.max) : draftSchema
          })
          if (!['car', 'bike'].includes(insuranceType)) {
            const enquiryValidations = validateEnquiry(enquirySchema, insuranceEnquiry[insuranceType].formData)
            return enquiryValidations.valid
          } else {
            return false
          }
        }
      )
    },

    /**
     * gets product data from the state
     *
     * @returns
     */
    getProductData () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceEnquiry, insuranceType) => produce(insuranceEnquiry[insuranceType].productData, (pd) => pd)
      )
    },

    /**
     * Get payment frequency from the state
     *
     * @returns
     */
    getPaymentFrequency () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceEnquiry, insuranceType) => insuranceEnquiry[insuranceType].productData.paymentFrequency
      )
    },

    /**
     * Gets chosenProduct metadata
     *
     * @returns
     */
    // Get the selected product data stored in enquiry
    getChosenProductMetadata () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceEnquiry, insuranceType) => {
          if (isNotEmpty(insuranceEnquiry[insuranceType].chosenProduct)) {
            return produce(insuranceEnquiry[insuranceType].chosenProduct, (sp) => {
              delete sp.productData
            })
          } else {
            return {}
          }
        })
    },

    /**
     * Gets applicationData from enquiry
     *
     * @returns
     */
    getApplicationData () {
      return createSelector(
        slice,
        (state, { insuranceType, institutionId }) => ({
          insuranceType,
          institutionId
        }),
        (insuranceEnquiry, { institutionId, insuranceType }) => {
          if (!isNotEmpty(insuranceEnquiry[insuranceType].chosenProduct)) {
            // FIXME: Need to throw error here
            return {}
          }
          // FIXME: Can check if the chosenProduct institutionId is same as passed
          return (
            insuranceEnquiry[insuranceType].application[institutionId] || {}
          )
        }
      )
    },

    /**
     * Returns dataMismatch data
     *
     * @returns
     */
    getUserMismatchError () {
      return createSelector(
        slice,
        (state, { insuranceType }) => insuranceType,
        (insuranceEnquiry, insuranceType) => {
          const dataMismatch = insuranceEnquiry[insuranceType].dataMismatch
          if (isNotEmpty(dataMismatch)) {
            return dataMismatch
          } else {
            return {}
          }
        }
      )
    }
  }),
  reducers: {

    /**
     * Sets chosenProduct to the state
     *
     * @param {*} state
     * @param {*} { enquiryChosenProduct, insuranceType }
     * @returns
     */
    'insuranceProducts/updateChosenProduct' (state, { enquiryChosenProduct, insuranceType }) {
      if (isNotEmpty(enquiryChosenProduct)) {
        if (!state[insuranceType].hasOwnProperty('chosenProduct')) {
          state[insuranceType].chosenProduct = {}
        }
        if (isNotEmpty(enquiryChosenProduct)) {
          state[insuranceType].chosenProduct = Object.assign(state[insuranceType].chosenProduct, enquiryChosenProduct)
        }
      }
      return state
    },

    setDropOffForAgent (state, { insuranceType, dropOff }) {
      state[insuranceType].dropOff = dropOff
    },

    /**
     * Updates the enquiry
     *
     * @param {*} state
     * @param {*} { enquiryId, insuranceType, insuranceEnquiry }
     * @returns
     */
    // If only insuranceEnquiry has to be updated
    updateEnquiry (state, { enquiryId, insuranceType, insuranceEnquiry }) {
      return buildEnquiryState(state, insuranceType, insuranceEnquiry, enquiryId)
    },

    /**
     * Calls buildEnquiryState to update the enquiry with new data
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceEnquiry, enquiryId }
     * @returns
     */
    'insuranceConfigure/updateConfigData' (state, { insuranceType, insuranceEnquiry, enquiryId }) {
      return buildEnquiryState(state, insuranceType, insuranceEnquiry, enquiryId)
    },

    /**
     * Calls buildEnquiryState to update the enquiry with new data
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceEnquiry, enquiryId }
     * @returns
     */
    'insuranceFilters/resetFilters' (state, { insuranceType, insuranceEnquiry, enquiryId }) {
      return buildEnquiryState(state, insuranceType, insuranceEnquiry, enquiryId)
    },

    /**
     * Calls buildEnquiryState to update the enquiry with new data
     *
     * @param {*} state
     * @param {*} { insuranceType, insuranceEnquiry, enquiryId }
     * @returns
     */
    'insuranceFilters/updateFilters' (state, { insuranceType, insuranceEnquiry, enquiryId }) {
      return buildEnquiryState(state, insuranceType, insuranceEnquiry, enquiryId)
    },

    /**
     * This function sets application to empty object or with the data if it exists, chosenproduct and
     * dataMismatch data
     *
     * @param {*} state
     * @param {*} { insuranceType, application, chosenProduct, institutionId, dataMismatch }
     * @returns
     */
    // FIXME: Why does application not have institutionId in enquiry
    'insuranceConfigure/updateBookingConfiguration' (state, { insuranceType, application, chosenProduct, institutionId, dataMismatch }) {
      if (!state[insuranceType].hasOwnProperty('application')) {
        state[insuranceType].application = {}
      }
      state[insuranceType].application[institutionId] = application[institutionId]
      if (isNotEmpty(chosenProduct)) {
        state[insuranceType].chosenProduct = chosenProduct
      }
      state[insuranceType].dataMismatch = dataMismatch
      return state
    },

    /**
     * Updates the chosenProduct in the state
     *
     * @param {*} state
     * @param {*} { insuranceType, chosenProduct }
     * @returns object
     */
    updateChosenProductWithApplicationData (state, { insuranceType, chosenProduct }) {
    // FIXME: To be removed soon
      if (!isNotDefined(chosenProduct)) {
        if (isNotEmpty(state[insuranceType].chosenProduct)) {
          Object.assign(state[insuranceType].chosenProduct, chosenProduct)
        }
      }
      return state
    },

    /**
     * Removes the chosen product
     *
     * @param {*} state
     * @param {*} { insuranceType }
     * @returns object
     */
    'insuranceProducts/removeChosenProduct' (state, { insuranceType }) {
      if (!isNotDefined(state[insuranceType].chosenProduct)) {
        state[insuranceType].chosenProduct = {}
        const productData = state[insuranceType].productData
        productData.paymentOption = insuranceType === 'term' ? 'RP' : productData.paymentOption
        if (productData.paymentFrequency === 'S') {
          productData.paymentFrequency = insuranceType === 'term' ? 'M' : productData.paymentFrequency
        }
      }
      return state
    },

    /**
     * updates the application data
     *
     * @param {*} state
     * @param {*} { insuranceType, application, institutionId }
     * @returns
     */
    updateApplicationData (state, { insuranceType, application, institutionId }) {
      state[insuranceType].application[institutionId] = application
      return state
    }
  },
  effects: (dispatch) => ({
    loadEnquiryFromDb: effectsHandler(dispatch, loadEnquiryFromDb),
    setEnquiryData: effectsHandler(dispatch, setEnquiryData),
    setEnquiry: effectsHandler(dispatch, setEnquiry),
    // FIXME: Why do we need this
    setApplicationFormData: effectsHandler(dispatch, setApplicationFormData),
    setChosenProductWithApplicationData: effectsHandler(dispatch, setChosenProductWithApplicationData),
    updateEnquiryWithPolicyDetails: effectsHandler(dispatch, updateEnquiryWithPolicyDetails)
  })
}
export default insuranceEnquiry
