import { useState } from 'react'
import LabelField from '../components/LabelField'
import FormHint from '../components/FormHint'
import SelectButtonWidget from '../widgets/SelectButtonWidget'
import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'
import '../widgets/SelectWidget.scss'

const getError = (key, errorSchema) => {
  return errorSchema[key]?.__errors
}

const buildStatusData = (options) => {
  const { enum: enumValues, enumNames } = options
  return enumValues.map((en, i) => {
    return {
      key: `${en}-${i}`,
      value: en,
      label: enumNames[i]
    }
  })
}

const PreviousPolicyStatusWithDateField = (props) => {
  const { formData, schema, uiSchema, errorSchema, idSchema } = props
  const [state, setState] = useState(initialize())
  const [dateRange, setDateRange] = useState(initializeDateRange())

  function initialize () {
    if (formData.previousPolicyStatus && formData.previousPolicyEndDate) {
      return { previousPolicyEndDate: formData.previousPolicyEndDate, previousPolicyStatus: formData.previousPolicyStatus }
    }
    return { previousPolicyStatus: undefined, previousPolicyEndDate: undefined }
  }

  function initializeDateRange () {
    const minDate = new Date().setFullYear(new Date().getFullYear() - 100)
    const maxDate = new Date().setFullYear(new Date().getFullYear() + 100)
    return { minDate, maxDate }
  }

  const handleOnItemChange = (data) => {
    const { onChange } = props
    let minDate = new Date().setDate(new Date().getDate() - 0)
    let maxDate = new Date().setDate(new Date().getDate() + undefined)
    if (data.previousPolicyStatus === 'expiredLessThan45Days') {
      minDate = new Date().setDate(new Date().getDate() - 45)
      maxDate = new Date().setDate(new Date().getDate())
    } else if (data.previousPolicyStatus === 'expiredBetween45To90Days') {
      minDate = new Date().setDate(new Date().getDate() - 90)
      maxDate = new Date().setDate(new Date().getDate() - 45)
    } else if (data.previousPolicyStatus === 'expiredMoreThan90Days') {
      minDate = new Date().setDate(new Date().getDate() - undefined)
      maxDate = new Date().setDate(new Date().getDate() - 90)
    }
    setDateRange({ minDate, maxDate })
    setState({ ...data })
    onChange({ ...data })
  }

  const { properties } = schema
  const { previousPolicyStatus, previousPolicyEndDate } = uiSchema
  return (
    <>
      <div>
        <SelectButtonWidget
          id={idSchema.previousPolicyStatus.$id}
          placeholder={previousPolicyStatus?.['ui:placeholder']}
          options={{ enumOptions: buildStatusData(properties.previousPolicyStatus) }}
          className='selectButtonWidget'
          value={state.previousPolicyStatus ?? undefined}
          schema={properties.previousPolicyStatus}
          onChange={(value) => handleOnItemChange({ previousPolicyStatus: value, previousPolicyEndDate: undefined })}
        />
        <FormHint
          id={`${idSchema.previousPolicyStatus.$id}-error`}
          showerror={errorSchema?.previousPolicyStatus?.__errors.length > 0}
          rawErrors={getError('previousPolicyStatus', errorSchema)}
        />
      </div>
      <div>
        <LabelField
          className='control-label mt-2'
          htmlFor='label'
          title={properties.previousPolicyEndDate?.title}
        />
        <DatePicker
          id={idSchema.previousPolicyEndDate.$id}
          showMonthDropdown
          showYearDropdown
          dropdownMode='select'
          dateFormat='dd-MM-yyyy'
          placeholderText={previousPolicyEndDate?.['ui:placeholder']}
          selected={state.previousPolicyEndDate ? new Date(state.previousPolicyEndDate) : undefined}
          onChange={date => {
            handleOnItemChange({ ...state, previousPolicyEndDate: new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0] })
          }}
          wrapperClassName='calendar-input-wrapper'
          minDate={dateRange.minDate}
          maxDate={dateRange.maxDate}
          customInput={
            <InputMask
              mask='99-99-9999'
              id='previousPolicyEndDate-input'
            />
      }
        />
        <FormHint
          id={`${idSchema.previousPolicyEndDate.$id}-error`}
          showerror={errorSchema?.previousPolicyEndDate?.__errors.length > 0}
          rawErrors={getError('previousPolicyEndDate', errorSchema)}
        />
      </div>
    </>
  )
}

export default PreviousPolicyStatusWithDateField
