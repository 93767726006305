
import screens from './screens'
import { isNotDefined } from 'utils'
import { getStore } from 'smartcovr_api/src/store'
// This has to come from routes
// FIXME: Comeback here and change to path defined in each component.
const getIndexRoutes = (config) => {
  const { routeConfig, startingRoute } = config
  const { configure } = getStore().getState()
  const { availableProducts } = configure
  const productType = availableProducts[0]
  const indexRoutes = Object.keys(screens).map(screen => {
    const Component = screens[screen]
    const path = Component.path
    const params = Component.params
    const getPath = () => {
      let newPath = ''
      if (isNotDefined(path) && isNotDefined(params)) {
        newPath = `${startingRoute}`
      } else if (!isNotDefined(path) && isNotDefined(params)) {
        if (path === '/') {
          newPath = `${startingRoute}`
        } else if (path === 'claim-landing') {
          newPath = `/${path}`
        } else {
          newPath = `${startingRoute}:insuranceType/${path}`
        }
      } else if (!isNotDefined(path) && !isNotDefined(params)) {
        newPath = `${startingRoute}:insuranceType/${path}/${params}`
      }
      return newPath
    }
    return ({
      path: getPath(),
      // path: (isNotDefined(params)) ? `${startingRoute}:insuranceType/${path}` : `${startingRoute}:insuranceType/${path}/${params}`,
      title: routeConfig[path],
      id: path ?? '/',
      component: Component
    })
  })
  indexRoutes.push({
    redirect: true,
    path: `${startingRoute}dashboard`,
    to: `${startingRoute}${productType}/dashboard`
  })
  indexRoutes.push({
    redirect: true,
    path: `${startingRoute}customer-holdings`,
    to: `${startingRoute}${productType}/customer-holdings`
  })
  indexRoutes.push({
    redirect: true,
    path: `${startingRoute}claim-intimation`,
    to: `${startingRoute}${productType}/claim-intimation`
  })
  indexRoutes.push({
    redirect: true,
    path: `${startingRoute}claim-registration`,
    to: `${startingRoute}${productType}/claim-registration`
  })
  return indexRoutes
}
export default getIndexRoutes
