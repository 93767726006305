import { useEffect, useState } from 'react'
import ApplicationListing from './ApplicationListing'
import './style.scss'
import ApplicationDetails from './ApplicationDetails'
export default function ViewRegisteredClaims (props) {
  const { insuranceType, ldng, loadApplications, isLoggedIn, count, claimApplications, selectedApplication, updateSelectedApplicationNumber, redirectToPath } = props
  const [applicationStatus, setApplicationStatus] = useState(null)

  // On Load checks If not logged in redirected to the onboard page
  useEffect(() => {
    if (!isLoggedIn) {
      redirectToPath('/')
    }
  },
  // eslint-disable-next-line
    [isLoggedIn])

  const onClickDetailsButton = (data) => {
    setApplicationStatus(true)
    updateSelectedApplicationNumber(data)
  }

  return (
    <>
    {!applicationStatus
      ? <ApplicationListing
    isLoggedIn={isLoggedIn}
    count={count}
    loadApplications={loadApplications}
    loadingApplications={ldng}
    applications={claimApplications}
    updateSelectedApplicationNumber={onClickDetailsButton}
    redirectToPath={redirectToPath}
    insuranceType={insuranceType}
  />
      : <ApplicationDetails applications={selectedApplication} setApplicationStatus={setApplicationStatus} />
  }

    </>
  )
}
