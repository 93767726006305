import InfiniteCalendar from 'react-infinite-calendar'
import 'components/css/infiniteCalendar.scss'
const InfiniteCalendarComponent = (props) => {
  // date string in mm-dd-yyyy
  const { value, minDate, maxDate, className } = props
  let selectedDate = false
  let display = 'years'
  if (value) {
    selectedDate = new Date(value)
    display = 'days'
  }
  const maximumDate = maxDate && new Date(maxDate)
  const minimumDate = minDate && new Date(minDate)
  return (
    <InfiniteCalendar
      // width={400}
      className={className}
      height={600}
      display={display}
      selected={selectedDate}
      min={minimumDate}
      max={maximumDate}
      minDate={minimumDate}
      maxDate={maximumDate}
      onSelect={(date) => {
        const elements = document.getElementsByClassName('Cal__Years__list')
        if (elements === null || elements.length === 0) {
          props.onChange(date)
        }
      }}
    />
  )
}
export default InfiniteCalendarComponent
