import { useState } from 'react'
import InputMask from 'react-input-mask'

const ChassisNumberWidget = (props) => {
  const {
    id,
    placeholder,
    onChange,
    schema
  } = props
  const { maxLength } = schema
  const [chassisNumber, setChassisNumber] = useState(props.value)

  const onChassisNumberChange = (chassisNumberValue) => {
    setChassisNumber(chassisNumberValue)
    return onChange(chassisNumberValue)
  }

  return (
    <InputMask
      id={id}
      value={chassisNumber}
      onChange={(event) => {
        event.preventDefault()
        const value = event.target.value.replace(/[^a-z0-9]/gi, '')
        onChassisNumberChange(value.toUpperCase())
      }}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  )
}

export default ChassisNumberWidget
