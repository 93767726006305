import { useContext, useEffect, useRef, useState } from 'react'
import { Button } from 'semantic-ui-react'
import { DataAccessContext } from 'components/context'
import { JsonSchemaOnboardForm, JsonSchemaValidation, JsonSchemaHelper } from 'components/jsonSchemaForms'
import { ArrayField } from 'components/jsonSchemaForms/jsonschema-form-semantic-ui/src/components/fields'
import { isNotDefined, isNotEmpty } from 'utils/lib/basic'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'
import produce from 'immer'
import './healthOnboarding.scss'

const HealthOnboardingForm = (_props) => {
  const {
    device,
    configure,
    onSubmit: parentOnSubmit,
    countries,
    ...restProps
  } = useContext(DataAccessContext)
  const {
    onboard: {
      onboardSchema: { schema, uiSchema }
    }
  } = configure

  const [formData, setFormData] = useState(restProps.formData)
  const [newSchema, setNewSchema] = useState(schema)
  const [newUiSchema, setNewUiSchema] = useState(uiSchema)
  const customFocus = useRef(null)

  useEffect(() => {
    let newDraftSchema
    if (!isNotDefined(formData.members)) {
      if (formData.members.length <= 2) {
        newDraftSchema = produce(newUiSchema, draft => {
          draft.members['ui:options'].removable = false
        })
        setNewUiSchema(newDraftSchema)
      } else {
        newDraftSchema = produce(newUiSchema, draft => {
          draft.members['ui:options'].removable = true
        })
        setNewUiSchema(newDraftSchema)
      }
    }
  }, [formData.members, newUiSchema])

  useEffect(() => {
    if (customFocus.current) {
      customFocus.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [formData])

  const handleSubmit = async ({ formData: userFormData }) => {
    if (['familyFloater', 'individual'].includes(formData.policyType)) {
      userFormData.members.forEach(data => {
        delete data.sumAssured
      })
    }
    if (!isNotDefined(userFormData.members)) {
      userFormData.members.forEach(data => {
        data.age = JsonSchemaValidation.calculateAge(data.dateOfBirth)
      })
    }
    const newFormData = { ...formData, ...userFormData }
    const omittedFormData = JsonSchemaHelper.omitExtraData(newFormData)
    parentOnSubmit(omittedFormData)
  }
  const handleChange = (e) => {
    const { formData } = e
    if (['members', 'dob'].includes(e.key)) {
      formData.members.forEach(data => {
        if (data.relation === 'Self') {
          data.gender = formData.gender
          data.dateOfBirth = formData.dob
          setFormData(formData)
        }
      })
    }
    if (e.key === 'policyType') {
      let member = { dateOfBirth: undefined, gender: undefined, relation: undefined, sumAssured: undefined }
      if (['individual'].includes(formData.policyType)) {
        member = { ...formData.members[0] }
        formData.members = [member]
        setFormData(formData)
        setNewUiSchema(uiSchema)
      } else if (['familyFloater'].includes(formData.policyType) && formData.members.length > 6) {
        const newFormData = formData
        newFormData.members = formData.members.splice(0, 6)
        setFormData(newFormData)
      } else if (formData.members.length < 2) {
        formData.members.push(member)
        setFormData(formData)
        setNewUiSchema(uiSchema)
      }
    }
    if (e.key === 'members') {
      let newDraftUiSchema = newUiSchema
      const newDraftSchema = newSchema
      if (formData.members.length <= 2) {
        newDraftUiSchema = produce(newUiSchema, draft => {
          draft.members['ui:options'].removable = false
        })
        setNewUiSchema(newDraftUiSchema)
      } else {
        newDraftUiSchema = produce(newUiSchema, draft => {
          draft.members['ui:options'].removable = true
        })
        setNewUiSchema(newDraftUiSchema)
      }
      if (formData.policyType === 'familyFloater') {
        setFormData({ ...formData, policyType: 'familyFloater' })
      } else if (formData.policyType === 'multiIndividual') {
        setFormData({ ...formData, policyType: 'multiIndividual' })
      }
      setNewSchema(newDraftSchema)
    }
  }

  const onValidate = (formData, errors) => {
    const { validateDOB, validateEmail, validateMobileNumber, validatePincode, validateMemberDetails, validateFullName, validateMobileDndError } = JsonSchemaValidation
    const dobError = validateDOB(formData, schema, 'dob')
    if (dobError) {
      errors.dob.addError(dobError)
    }
    const mobileError = validateMobileNumber(formData.mobile, countries)
    if (mobileError) {
      errors.mobile.addError(mobileError)
    }
    const emailError = validateEmail(formData, 'email')
    if (emailError) {
      errors.email.addError(emailError)
    }
    const pincodeError = validatePincode(formData, 'pincode')
    if (pincodeError) {
      errors.pincode.addError(pincodeError)
    }
    const membersErrors = validateMemberDetails(formData, 'members')
    membersErrors.forEach((key, value) => {
      if (key !== '') {
        errors.members[value].dateOfBirth.addError(key)
      }
    })
    const fullNameError = validateFullName(formData, 'fullName')
    if (fullNameError) {
      errors.fullName.addError(fullNameError)
    }
    const mobileDndError = validateMobileDndError(formData, 'mobileDnd')
    if (mobileDndError) {
      errors.mobileDnd.addError(mobileDndError)
    }
    return errors
  }

  const transformErrors = (errors) => {
    if (isNotEmpty(errors)) {
      const newErrors = errors.filter(error => !['oneOf', 'enum'].includes(error.name))
      newErrors.forEach((error) => {
        const currentProperty = error.params?.missingProperty
        let selectedSchema
        if (currentProperty) {
          selectedSchema = JsonSchemaHelper.extractNestedObject(schema, currentProperty, currentProperty)
        }
        let title = 'This'
        if (selectedSchema && selectedSchema[currentProperty]?.title && selectedSchema[currentProperty]?.title.trim().length !== 0) {
          title = selectedSchema[currentProperty].title
        }
        error.message = getCustomErrorMessage(title, error)
      })
      return newErrors
    }
    return errors
  }

  return (
    <div className='main4'>
      <div className='form-container'>
        <div ref={customFocus} className='bg_onboard_form_card_health shadow-lg rounded'>
          <JsonSchemaOnboardForm
            className='onboardingForm'
            schema={newSchema}
            uiSchema={newUiSchema}
            onSubmit={handleSubmit}
            onChange={handleChange}
            formData={formData}
            fields={{ ArrayField }}
            rules={[]}
            liveValidate={false}
            validate={onValidate}
            transformErrors={transformErrors}
            formContext={{
              device,
              countries
            }}
          >
            <Button
              id='getQuotes_button'
              className='get_button'
              secondary
              content='Get Quotes'
            />
          </JsonSchemaOnboardForm>
        </div>
      </div>
    </div>
  )
}
export default HealthOnboardingForm
