import { useState } from 'react'
import InputMask from 'react-input-mask'

const AlphaNumericInputWidget = (props) => {
  const {
    id,
    placeholder,
    onChange,
    disabled,
    schema,
    readonly
  } = props
  const { minLength, maxLength } = schema
  const [value, setValue] = useState(props.value)

  const onHandleChange = (inputValue) => {
    setValue(inputValue)
    return onChange(inputValue)
  }

  return (
    <InputMask
      id={id}
      value={value ?? ''}
      onChange={(event) => {
        event.preventDefault()
        const value = event.target.value.replace(/[^a-z0-9]/gi, '')
        onHandleChange(value.toUpperCase())
      }}
      readOnly={readonly}
      disabled={disabled}
      placeholder={placeholder}
      maxLength={maxLength}
      minLength={minLength}
    />
  )
}

export default AlphaNumericInputWidget
