import { buildDistributorAndInsuranceTypeImagePath } from 'components/buildImagePath'

const OnboardCardDivider = ({ className, wowDelay }) => {
  const cls = className ?? 'col-lg-1 col-md-1 col-sm-12 text-center wow zoomIn'
  const delay = wowDelay ?? '600ms'
  return (
    <div className={cls} data-wow-delay={delay}>
      <img
        className='vertical-divider img-fluid m-auto'
        alt='arrow'
        src={buildDistributorAndInsuranceTypeImagePath('vertical-divider.jpg')}
      />
      <img
        className='horizontal-divider img-fluid'
        alt='140x140'
        src={buildDistributorAndInsuranceTypeImagePath('horizontal-divider.jpg')}
      />
    </div>
  )
}

export default OnboardCardDivider
