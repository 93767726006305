import React, { Component, Fragment } from 'react'
import {
  List,
  Card,
  Grid,
  Button,
  Transition
} from 'semantic-ui-react'
import {
  H6,
  Body2,
  Caption,
  H5
} from 'components/Header'

import PropTypes from 'prop-types'
import {
  isNotDefined,
  indexOf,
  isArray,
  isObject,
  // split,
  rupeeFormatter
} from 'utils'
import moment from 'moment'
import { JsonSchemaValidation, JsonSchemaSingleFormNew } from 'components/jsonSchemaForms'
import { isEmpty } from 'lodash'

/* eslint react/jsx-handler-names: 'off' */
const nameFields = ['title', 'firstName', 'middleName', 'lastName']
const style = {
  cardStyle: {
    marginTop: '1em',
    marginBottom: '1em'
  },
  address: {
    display: 'inline-grid'
  },
  submit: {
    marginTop: '2em'
  }
}
class FormReview extends Component {
  constructor (props) {
    super(props)
    // this state has 3steps, review, payment and edit
    this.state = {
      visible: true,
      currentView: 'review',
      currentEditStep: undefined, // used when currentStep is edit.
      annuitant: undefined
    }
    this.onEditStepClick = this.onEditStepClick.bind(this)
    this.onEditSubmit = this.onEditSubmit.bind(this)
    this.onCancel = this.onCancel.bind(this)
    this.onValidate = this.onValidate.bind(this)
  }

  combineName (data, prop) {
    let name = ''
    nameFields.forEach(ele => {
      let val
      if (
        !isNotDefined(prop.properties[ele]) &&
        !isNotDefined(prop.properties[ele].enum) &&
        !isNotDefined(data[ele])
      ) {
        val = prop.properties[ele].enumNames[indexOf(prop.properties[ele].enum, data[ele])]
      } else {
        val = !isNotDefined(data[ele]) ? ' ' + data[ele] : ''
      }
      name = name + val
    })
    return name
  }

  displayAddress (formDataObj, schemaObj) {
    const { insuranceType, applicationType } = this.props.formData
    return (
      <div style={style.address}>
        {Object.keys(schemaObj.properties).map((schemaKey, ix) => {
          if (formDataObj[schemaKey] === 'no') return ''
          if (!isNotDefined(schemaObj.properties[schemaKey].enum)) {
            return this.selectValuePrint(schemaObj.properties[schemaKey], formDataObj[schemaKey])
          }
          if (schemaKey === 'csp') {
            const csp = formDataObj[schemaKey]
            let city = csp.city
            let state = csp.state
            const stateSchema = schemaObj.properties[schemaKey].properties.state
            if (!isNotDefined(stateSchema.enum)) {
              state = this.selectValuePrint(stateSchema, state)
            } else if (formDataObj[schemaKey]?.stateDisplay) {
              state = formDataObj[schemaKey].stateDisplay
            }
            let formContext
            if (insuranceType === 'annuity' && applicationType === 'jl') {
              formContext = this.props.reviewFormSteps.primary[0].formContext
            } else {
              formContext = this.props.reviewFormSteps[0].formContext
            }
            if (!isNotDefined(formContext.cities)) {
              const result = formContext.cities.find(ele => ele.value === city)
              if (!isNotDefined(result)) {
                city = result.display
              }
            } else if (formDataObj[schemaKey]?.cityDisplay) {
              city = formDataObj[schemaKey].cityDisplay
            }
            return (
              <Fragment key={`addresss-${ix}`}>
                <span>
                  {`${city}, `}
                  <br />
                </span>
                <span>
                  {`${state}, `}
                  <br />
                </span>
                <span>
                  {csp.pincode}
                  <br />
                </span>
              </Fragment>
            )
          } else {
            const value = formDataObj[schemaKey]
            return (
              <div key={`addressDetail-${ix}`}>
                {value}
              </div>
            )
          }
        })}
      </div>
    )
  }

  displayInspectionAddress (formDataObj, schemaObj) {
    return (
      <div>
        {Object.keys(schemaObj.properties).map((schemaKey, ix) => {
          if (formDataObj[schemaKey] === 'self') return ''
          if (schemaKey === 'csl') {
            const { city: citySchema, state: stateSchema, location: locationSchema } = schemaObj.properties[schemaKey].properties
            const csl = formDataObj[schemaKey]
            let city = csl.city
            let state = csl.state
            let location = csl.location

            const formContext = this.props.reviewFormSteps[0].formContext
            if (!isNotDefined(stateSchema.enum)) { // if state Schema has enum and enumNames
              state = this.selectValuePrint(stateSchema, state)
            } else if (formDataObj[schemaKey]?.stateDisplay) { // if stateSchema has stateDisplay key
              state = formDataObj[schemaKey].stateDisplay
            } else if (!isNotDefined(formContext.states)) { // if formContext has state response
              const result = formContext.states.find(ele => ele.Code === state)
              if (!isNotDefined(result)) {
                state = result.StateName
              }
            }

            if (!isNotDefined(citySchema.enum)) { // if city Schema has enum and enumNames
              city = this.selectValuePrint(citySchema, city)
            } else if (formDataObj[schemaKey]?.cityDisplay) { // if citySchema has cityDisplay key
              city = formDataObj[schemaKey].cityDisplay
            } else if (!isNotDefined(formContext.cities)) { // if formContext has cities response
              const result = formContext.cities.find(ele => ele.CityId === city)
              if (!isNotDefined(result)) {
                city = result.CityName
              }
            }

            if (!isNotDefined(locationSchema.enum)) { // if location Schema has enum and enumNames
              location = this.selectValuePrint(locationSchema, location)
            } else if (formDataObj[schemaKey]?.locationDisplay) { // if locationSchema has locationDisplay key
              location = formDataObj[schemaKey].locationDisplay
            } else if (!isNotDefined(formContext.locations)) { // if formContext has locations response
              const result = formContext.locations.find(ele => ele.LocationId === location)
              if (!isNotDefined(result)) {
                location = result.LocationName
              }
            }

            return (
              <Fragment key={`addresss-${ix}`}>
                <Body2 className='listItemContent' color='black'>
                  {stateSchema.title}:
                  <span className='displayValue'>
                    {state}
                  </span>
                </Body2>
                <Body2 className='listItemContent' color='black'>
                  {citySchema.title}:
                  <span className='displayValue'>
                    {city}
                  </span>
                </Body2>
                <Body2 className='listItemContent' color='black'>
                  {locationSchema.title}:
                  <span className='displayValue'>
                    {location}
                  </span>
                </Body2>
              </Fragment>
            )
          }
          return null
        })}
      </div>
    )
  }

  displayPolicyDetails (formDataObj, schemaObj) {
    return (
      <div style={style.address}>
        {Object.keys(schemaObj.properties).map((schemaKey, ix) => {
          const title = schemaObj.properties[schemaKey].title
          if (!isNotDefined(schemaObj.properties[schemaKey].enum)) {
            const value = this.selectValuePrint(schemaObj.properties[schemaKey], formDataObj[schemaKey])
            return (
              <div key={`policyDetail-${ix}`} className='my-1'>
                <small>{title}: </small>
                {value}
              </div>
            )
          }
          const value = formDataObj[schemaKey]
          return (
            <div key={`policyDetail-${ix}`} className='my-1'>
              <small>{title}: </small>
              {value}
            </div>
          )
        })}
      </div>
    )
  }

  displayMemberDetails (formDataObj, _schemaObj) {
    return (
      <tbody>
        <tr>
          <td>{formDataObj.relation}</td>
          <td>{formDataObj.dateOfBirth}</td>
        </tr>
      </tbody>
    )
  }

  printObject (formData, schemaObj, ky) {
    const formDataObj = formData[ky]
    let flag = false
    if (ky === 'occupation') {
      let occupation = this.selectValuePrint(
        schemaObj.properties.occupationClass,
        formData[ky].occupationClass
      )
      if (occupation === 'Others') {
        occupation = formData[ky].occupationClassOther
        return (
          <div className='flexContainer' key={`${ky}_item`}>
            <Body2 className='listItemContent' color='black'>
              {schemaObj.title} :
              <span className='displayValue'>{occupation}</span>
            </Body2>
          </div>
        )
      }
    }
    if (typeof ky === 'string' && /permanentaddress/ig.test(ky)) {
      return (
        <div className='flexContainer' key={`${ky}_item`}>
          <Body2 className='listItemContent' color='black'>
            {schemaObj.title} :
            <span className='displayValue'>
              {this.displayAddress(formDataObj, schemaObj)}
            </span>
          </Body2>
        </div>
      )
    }
    if (typeof ky === 'string' && /currentaddress/ig.test(ky)) {
      return (
        <div className='flexContainer' key={`${ky}_item`}>
          <Body2 className='listItemContent' color='black'>
            {schemaObj.title}:
            <span className='displayValue'>
              {Object.keys(schemaObj.properties).map(mkey => {
                if (formDataObj[mkey] === 'yes') {
                  return schemaObj.properties[mkey].title
                } else if (formDataObj[mkey] === 'no') {
                  return this.displayAddress(
                    formDataObj,
                    schemaObj.dependencies[mkey].oneOf[1]
                  )
                }
                return null
              })}
            </span>
          </Body2>
        </div>
      )
    }
    if (typeof ky === 'string' && /inspectionDetails/ig.test(ky)) {
      return (
        <div className='flexContainer flex-column' key={`${ky}_item`}>
          {Object.keys(schemaObj.properties).map(mkey => {
            return (
              <React.Fragment key={mkey}>
                <Body2 className='listItemContent' color='black'>
                  {schemaObj.properties[mkey].title}:
                  <span className='displayValue'>
                    {this.selectValuePrint(schemaObj.properties[mkey], formDataObj[mkey])}
                  </span>
                </Body2>
                {formDataObj[mkey] === 'surveyor' && this.displayInspectionAddress(
                  formDataObj,
                  schemaObj.dependencies[mkey].oneOf[1]
                )}
              </React.Fragment>
            )
          })}

        </div>
      )
    }
    if (typeof ky === 'string' && /policyDetails/ig.test(ky) && !isEmpty(formData[ky])) {
      return (
        <div className='flexContainer' key={`${ky}_item`}>
          <Body2 className='listItemContent' color='black'>
            {schemaObj.title} :
            <span className='displayValue'>
              {this.displayPolicyDetails(formDataObj, schemaObj)}
            </span>
          </Body2>
        </div>
      )
    }
    if (typeof ky === 'string' && /memberDetails1/ig.test(ky)) {
      return (
        <Body2 key={`${ky}_item`} color='black'>
          <table className='ui celled table'>
            <thead>
              <tr>
                <th>RELATION</th>
                <th>DOB</th>
              </tr>
            </thead>
            {
            formData.members.map((member, ix) => {
              return (
                <Fragment key={`member-${ix}`}>
                  {this.displayMemberDetails(member, schemaObj)}
                </Fragment>
              )
            })
          }
          </table>

        </Body2>
      )
    }
    Object.keys(formDataObj).forEach(key => {
      if (!isNotDefined(formDataObj[key])) flag = true
      if (ky.includes('memberDetails')) flag = false
    })
    if (flag) {
      return (
        <div className='flexContainer' key={`${ky}_item`}>
          <Body2 className='listItemContent' color='black'>
            {schemaObj.title}:
            <span className='displayValue'>
              {
              Object.keys(schemaObj.properties).map(mkey => {
                if (!isNotDefined(schemaObj.properties[mkey].enum)) {
                  return this.selectValuePrint(
                    schemaObj.properties[mkey],
                    formDataObj[mkey]
                  )
                } else {
                  if (typeof ky === 'string' && /panDetails/ig.test(ky)) {
                    if (mkey === 'panNo') {
                      return formDataObj[mkey]
                    }
                  } else {
                    return formDataObj[mkey]
                  }
                }
                return null
              }).join(' ')
            }
            </span>
          </Body2>
        </div>
      )
    }
    return null
  }

  displayAge (arrayelement, properties) {
    let ageDisplay = ''
    if (properties.age) {
      ageDisplay = (
        <span className='displayValue'>
          {arrayelement.age + ' Years'}
        </span>
      )
    }
    return ageDisplay
  }

  printArray (formDataObj, ky, prop) {
    const properties = prop.items.properties
    if (ky === 'nominees') {
      let appointeeProperties
      if (properties?.appointee) {
        appointeeProperties = properties.appointee?.dependencies.isMinor.oneOf[0].properties
      }
      return formDataObj.map((arrayelement, j) => {
        const percent = `${arrayelement.percentage}%`
        return (
          <List.Item key={`${j}item`}>
            <List.Content floated='right'>
              <Body2 color='black' content={percent} />
            </List.Content>
            <Body2 className='listItemContent' color='black'>
              {this.combineName(arrayelement.fullName, properties.fullName)} :{' '}
              {this.displayAge(arrayelement, properties)}
              <span className='displayValue'>
                {this.selectValuePrint(
                  properties.relationship,
                  arrayelement.relationship
                )}
              </span>
            </Body2>
            {(arrayelement?.appointee && arrayelement.appointee.isMinor === 'yes') && (
              <>
                <Caption className='listItemContent' color='black'>
                  Appointee Name :
                  <span className='displayValue'>
                    {this.combineName(
                      arrayelement.appointee.fullName,
                      appointeeProperties.fullName
                    )}
                  </span>
                </Caption>
                <Caption className='listItemContent' color='black'>
                  Appointee Relation :
                  <span className='displayValue'>
                    {this.selectValuePrint(
                      appointeeProperties.relationship,
                      arrayelement.appointee.relationship
                    )}
                  </span>
                </Caption>
              </>
            )}
          </List.Item>
        )
      })
    }
    if (ky === 'appointee') {
      return formDataObj.map((arrayelement, j) => (
        <div className='flexContainer' key={`${j}_item`}>
          <Body2 className='listItemContent' color='black'>
            {this.combineName(arrayelement.fullName, properties.fullName)}
          </Body2>
        </div>
      ))
    }
  }

  selectValuePrint (schemaObj, value) {
    return schemaObj.enumNames[indexOf(schemaObj.enum, value)]
  }

  listItem (schema, formData) {
    return (
      <List>
        {Object.keys(schema.properties).map((key, index) => {
          const property = schema.properties[key]
          const currentFormdata = formData[key]
          if (!isNotDefined(currentFormdata)) {
            if (!isObject(currentFormdata)) {
              let displayValue = formData[key]
              if (!isNotDefined(property.enum)) {
                displayValue = this.selectValuePrint(property, displayValue)
              }
              if (/dateOfBirth/ig.test(key)) {
                displayValue = moment(displayValue).format('Do MMMM YYYY')
              }
              if (key === 'qualificationAnnualIncome') {
                displayValue = `${rupeeFormatter(displayValue)}`
              }
              if (['propMobile', 'mobile'].includes(key)) {
                const { code, number } = JSON.parse(displayValue)
                displayValue = `${code}${number}`
              }
              return (
                <div className='flexContainer' key={`${index}_item`}>
                  <Body2 className='listItemContent' color='black'>
                    {property.title} : <span className='displayValue'>{displayValue}</span>
                  </Body2>
                </div>
              )
            } // Print value properties
            if (isArray(currentFormdata)) {
              return this.printArray(currentFormdata, key, property)
            } // Print array property

            if (/completeName/ig.test(key) || /fatherName/ig.test(key)) {
              return (
                <div className='flexContainer' key={`${index}_item`}>
                  <Body2 className='listItemContent' color='black'>
                    {property.title} :
                    <span className='displayValue'>
                      {this.combineName(formData[key], property)}
                    </span>
                  </Body2>
                </div>
              )
            } // Print Name property

            // Print Object property
            return this.printObject(formData, property, key)
          }
          return null
        })}
      </List>
    )
  }

  drawColumn (formStep, formData, currentStep, annuitant) {
    const { step, stepName } = formStep
    const id = stepName?.split(' ').join('_')
    return (
      <Grid.Column stretched key={`${currentStep}col`}>
        <Card
          className='reviewCard'
          id={`${id}Card`}
          style={this.props.device === 'mobile' ? null : style.cardStyle}
          fluid
          header={
            <div className='d-flex align-items-center justify-content-between'>
              <H6 className='mb-0' content={formStep.schema.title} />
              <Button
                basic
                id={`${id}EditIcon`}
                content='EDIT'
                size='tiny'
                onClick={() => this.onEditStepClick(step, currentStep, annuitant)}
              />
            </div>
          }
          description={this.listItem(formStep.schema, formData)}
        />
      </Grid.Column>
    )
  }

  onEditStepClick (step, stepIndex, annuitant) {
    this.setState({ visible: false })
    setTimeout(() => {
      this.setState({
        currentView: 'edit',
        currentEditStep: stepIndex,
        visible: true,
        annuitant
      })
    }, 500)
  }

  onCancel () {
    this.setState({
      currentView: 'review'
    })
  }

  onEditSubmit (formData) {
    this.setState({ visible: false })
    setTimeout(() => {
      this.setState(
        {
          currentView: 'review',
          visible: true
        },
        () => this.props.onReviewEdit(formData.formData)
      )
    }, 500)
  }

  onValidate (formData, errors) {
    const mobileData = formData.mobile ?? formData.propMobile
    const mobileError = JsonSchemaValidation.validateMobileNumber(mobileData, this.props.countries)
    if (mobileError && errors.mobile) {
      errors.mobile.addError(mobileError)
    } else if (mobileError && errors.propMobile) {
      errors.propMobile.addError(mobileError)
    }
    if (errors.panDetails && formData.panDetails?.panNo === formData.jointPanDetails?.panNo) {
      errors.panDetails.panNo.addError(': Primary And Secondary insured should be different')
      errors.jointPanDetails.panNo.addError(': Primary And Secondary insured should be different')
    }
    return errors
  }

  displayFunc () {
    const {
      reviewFormSteps,
      device,
      nextBookingStep,
      previousBookingStep,
      applicableStep
    } = this.props
    const { formData } = this.props
    const { insuranceType, applicationType } = formData
    const { currentView, currentEditStep, annuitant } = this.state
    if (currentView === 'edit') {
      // Need to build items needed or edit form
      let currentStepSchema
      if (insuranceType === 'annuity' && applicationType === 'jl') {
        currentStepSchema = reviewFormSteps[annuitant][currentEditStep]
      } else {
        currentStepSchema = reviewFormSteps[currentEditStep]
      }
      return (
        <JsonSchemaSingleFormNew
          schema={currentStepSchema.schema}
          uiSchema={currentStepSchema.uiSchema}
          formContext={{
            ...currentStepSchema.formContext,
            countries: this.props.countries
          }}
          onSubmit={this.onEditSubmit}
          onCancel={this.onCancel}
          formData={formData}
          onValidate={this.onValidate}
          rules={currentStepSchema.rules}
          liveValidate
        >
          <div>
            <Button
              id='updateDetails'
              type='submit'
              content='UPDATE'
              secondary
            />
            <Button
              id='cancelButton'
              content='CANCEL'
              basic
              onClick={_e => this.onCancel()}
            />
          </div>
        </JsonSchemaSingleFormNew>
      )
    } else {
      const { title, desc } = applicableStep
      return (
        <div id='reviewPage'>
          <H5>{title}</H5>
          <Body2>{desc}</Body2>
          <Grid
            columns={device === 'mobile' ? 1 : 2}
            stackable={device === 'mobile'}
          >
            {(insuranceType === 'annuity' && applicationType === 'jl')
              ? (
                <>
                  <Body2 className='mt-5'><H6>Primary Annuitant</H6></Body2>
                  <Grid.Row>
                    {reviewFormSteps.primary.map((formStep, currentStep) => this.drawColumn(formStep, formData, currentStep, 'primary')
                    )}
                  </Grid.Row>
                  <Body2 className='mt-5'><H6>Secondary Annuitant</H6></Body2>
                  <Grid.Row>
                    {reviewFormSteps.secondary.map((formStep, currentStep) => this.drawColumn(formStep, formData, currentStep, 'secondary')
                    )}
                  </Grid.Row>
                </>
                )
              : (
                <Grid.Row>
                  {reviewFormSteps.map((formStep, currentStep) =>
                    this.drawColumn(formStep, formData, currentStep))}
                </Grid.Row>
                )}
          </Grid>
          <Button
            style={style.submit}
            id='reviewSubmit'
            secondary
            content={['car', 'bike'].includes(formData.insuranceType) ? 'Show Policy Summary' : 'Save & Proceed'}
            onClick={nextBookingStep}
          />
          <Button
            id='backButton'
            content='Back'
            basic
            onClick={previousBookingStep}
          />
        </div>
      )
    }
  }

  render () {
    const { visible } = this.state
    return (
      <Transition visible={visible} animation='scale' duration={500}>
        <div>{this.displayFunc()}</div>
      </Transition>
    )
  }
}
FormReview.propTypes = {
  formData: PropTypes.object.isRequired,
  // currentStep: PropTypes.number.isRequired,
  // editStep: PropTypes.func.isRequired,
  reviewFormSteps: PropTypes.array.isRequired
}
export default FormReview
