import { PureComponent } from 'react'
import { Modal, Menu } from 'semantic-ui-react'
import { Subtitle2, Caption } from 'components/Header'
import { UpdateContext } from 'components/context'
import { adopt } from 'react-adopt'
import AnnuityInsuranceSingleForm from 'components/annuity/AnnuityInsuranceSingleForm'
class PersonalDetailsHeaderAnnuity extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      id: ''
    }
    this.openModal = this.openModal.bind(this)
    this.setFormData = this.setFormData.bind(this)
  }

  openModal (id) {
    if (isNaN(this.props.formData.purchasePrice) && isNaN(this.props.formData.annuityPayout)) {
      return
    }
    if (isNaN(this.props.formData.purchasePrice) || isNaN(this.props.formData.annuityPayout)) {
      return
    }
    this.setState({
      open: true,
      id
    })
  }

  setFormData (newFormData) {
    this.setState({
      open: false
    }, this.props.setFormData(newFormData))
  }

  render () {
    const { formData } = this.props
    const { pAge, pGender, sAge, sGender, applicationType } = formData
    const { open, id } = this.state
    const primaryId = 'primaryEditBtn'
    const secondaryId = 'secondaryEditBtn'
    const ageGender = (age, gender) => (
      <span>
        <span id='gender'>
          {(gender === 'male') ? 'Male' : 'Female'}
        </span>
        {' , '}
        <span id='age'>{age}</span>
        {' Years old '}
      </span>
    )
    return (
      <>
        <Modal
          className='editUserDetailsModal'
          open={open}
        >
          <AnnuityInsuranceSingleForm
            id={id}
            {...this.props}
            listing
            editId={id}
            onBack={() => {
              this.setState({
                open: false
              })
            }}
            setFormData={this.setFormData}
          />
        </Modal>
        <Menu.Item className='annuityPersonalDetails'>
          <div className='flexContainer'>
            <Subtitle2 color='primaryLight'>
              Personal Details
            </Subtitle2>
            {applicationType === 'jl'
              ? false
              : true && (
                <span className='editPersonalDetails' id={primaryId} onClick={(e) => this.openModal(e.target.id)}>EDIT</span>)}
          </div>
          <div className='flexContainer'>
            <Caption>
              {
              applicationType === 'jl' && (
                <span>
                  Primary Annuitant
                  <br />
                </span>
              )
              }
              {ageGender(pAge, pGender)}
            </Caption>
            {
              (applicationType === 'jl') && (
                <span className='editPersonalDetailsAnnuity' id={primaryId} onClick={(e) => this.openModal(e.target.id)}>EDIT</span>
              )
            }
          </div>
          {
            (applicationType === 'jl') && (
              <div className='flexContainer'>
                <Caption>
                  Secondary Annuitant <br />
                  {ageGender(sAge, sGender)}
                </Caption>
                <span className='editPersonalDetails' id={secondaryId} onClick={(e) => this.openModal(e.target.id)}>EDIT</span>
              </div>
            )
          }
        </Menu.Item>
      </>
    )
  }
}

const WithContext = adopt({
  update: <UpdateContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ update }) => {
        return (
          <PersonalDetailsHeaderAnnuity
            {...props}
            setFormData={update.setOnboardFormData}
          />
        )
      }
    }
  </WithContext>
)
export default WrapperComponent
