import 'components/css/dividedSegmentGreySeperator.scss'
const DividedSegmentGreySeperator = (props) => {
  const { leftSegment, rightSegment } = props
  return (
    <div className='dividedSegment flexContainer'>
      <div className='leftSegment'>
        {leftSegment}
      </div>
      <div className='rightSegment'>
        {rightSegment}
      </div>
    </div>
  )
}
export default DividedSegmentGreySeperator
