import { Icon } from 'semantic-ui-react'
import { useEffect, useState } from 'react'
import { amountWithLabels } from 'utils/lib/basic'
import { scaleBackAmount } from 'utils/lib/calc'
function HealthSumAssured ({ handleChange, id, sumAssured, configure: { common: { amountLabelOptions, sumAssuredRange: { lacks, crores, thousands } } } }) {
  const labelOptionsMapper = {
    k: {
      label: 'k',
      options: thousands,
      text: 'Thousands'
    },
    l: {
      label: 'l',
      options: lacks,
      text: 'Lakhs'
    },
    c: {
      label: 'c',
      options: crores,
      text: 'Crores'
    }
  }
  const [selectedLabel, setSelectedLabel] = useState(labelOptionsMapper.l.label)
  const [valueOptions, setValueOptions] = useState(lacks)
  const [value, setValue] = useState('')
  const [maxSumAssured, setMaxSumAssured] = useState(5000000000)
  useEffect(() => {
    setValueOptions(labelOptionsMapper[selectedLabel].options)
    setValue(labelOptionsMapper[selectedLabel].options[0].value)
    onChangeHandler(value, selectedLabel)
  }, [selectedLabel, value])  // eslint-disable-line
  useEffect(() => {
    const { amount, label } = amountWithLabels(sumAssured)
    for (const [, value] of Object.entries(labelOptionsMapper)) {
      if (value.text === label) {
        setSelectedLabel(value.label)
        setValueOptions(value.options)
      }
    }
    setValue(Number(amount))
  }, [])  // eslint-disable-line
  const onChangeHandler = (value, label) => {
    let maxSumAssured
    const maxIndex = labelOptionsMapper[label].options.indexOf(Number(value)) + 1
    if (maxIndex === labelOptionsMapper[label].options.length) {
      const nextArrayLabel = Object.keys(labelOptionsMapper)[Object.keys(labelOptionsMapper).indexOf(label) + 1]
      selectedLabel === labelOptionsMapper.c.label
        ? maxSumAssured = scaleBackAmount(labelOptionsMapper[label].options[maxIndex - 1], labelOptionsMapper[label].label)
        : maxSumAssured = scaleBackAmount(labelOptionsMapper[nextArrayLabel].options[0], nextArrayLabel)
    } else {
      maxSumAssured = scaleBackAmount(labelOptionsMapper[label].options[maxIndex], label)
    }
    setMaxSumAssured(maxSumAssured)
    if (labelOptionsMapper[selectedLabel].options.includes(parseInt(value))) {
      setValue(value)
    } else {
      setValue(labelOptionsMapper[selectedLabel].options[0])
    }
  }

  return (
    <div id='sumAssured-container' className='d-flex flex-row align-items-center'>
      <div>
        <span>
          <select id='sumAssured-values' style={{ borderRadius: '0px', borderRight: 'none' }} value={value} onChange={e => setValue(e.target.value)}>
            {
              valueOptions.map((item, index) => {
                return (
                  <option key={index} value={item}>{item}</option>
                )
              })
            }
          </select>
        </span>
      </div>
      <div>
        <span>
          <select id='sumAssured-labels' style={{ borderRadius: 0 }} value={selectedLabel} onChange={(e) => setSelectedLabel(e.target.value)}>
            {
              amountLabelOptions?.map((item, index) => {
                return (
                  <option key={index} value={item.value}>{item.text}</option>
                )
              })
            }
          </select>
        </span>
      </div>
      <Icon
        id='sumAssuredTickBtn'
        className='ms-2 updateIcon secondary'
        circular
        name='check'
        onClick={() => handleChange({ sumAssured: scaleBackAmount(value, selectedLabel), maxSumAssured }, id, true)}
      />
    </div>
  )
}

export default HealthSumAssured
