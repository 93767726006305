
import { Input } from 'semantic-ui-react'

const PercentWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    placeholder
  } = props
  // Remove labels to make it look just like Bootstrap.
  return (
    <Input
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={(_e, { value }) => props.onChange(value.length === 0 ? options.emptyValue : parseInt(value))}
      format='##'
      disabled={disabled}
      readOnly={readonly}
      label={{ basic: true, content: 'Years' }}
      labelPosition='right'
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}

    />
  )
}

export default PercentWidget
