
import { Component } from 'react'
import JsonSchemaEasyForm from 'components/jsonSchemaForms/JsonSchemaEasyForm'
import { Button } from 'semantic-ui-react'
import produce from 'immer'
import { isNotDefined, has } from 'utils'
import './editAddOnObjectTemplate.css'

/* eslint react/jsx-handler-names: "off" */
class EditAddOns extends Component {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onCancel = this.onCancel.bind(this)

    this.state = {
      focusElement: undefined
    }
  }

  shouldComponentUpdate (nextProps, _nextState) {
    if (nextProps.formData !== this.props.formData) {
      return true
    }
    return false
  }

  onSubmit (data) {
    const isRiderSumAssuredSchemaExist = Object.keys(data.schema.properties).includes('riderSumAssured')
    const updatedFormData = produce(data.formData, (draft) => {
      // set sum assured to zero if its undefined
      if (isRiderSumAssuredSchemaExist && has(draft, 'riderSumAssured') && isNotDefined(draft.riderSumAssured)) {
        draft.riderSumAssured = 0
      }
      return draft
    })
    this.props.onSubmit(updatedFormData)
  }

  onCancel (riderId) {
    this.props.onCancel(this.props.formData, riderId)
  }

  onFocus (data) {
    this.focusId = data
  }

  render () {
    const {
      customValidationMessage,
      schema,
      uiSchema,
      rules,
      formData,
      onError,
      noLabel,
      contentType,
      submitText,
      cancelText,
      ...formProps
    } = this.props

    const objectTemplate = (props) => {
      if (contentType === 'review') {
        const contentSchema = props.properties.map(p => p.content.props.schema)
        const hasChildProperties = contentSchema.findIndex(schema => Object.prototype.hasOwnProperty.call(schema, 'properties')) > -1
        const className = hasChildProperties ? 'parent-property' : 'child-property'
        return (
          <div className={className}>
            {props.properties.map((element, idx) => <div key={idx + 'field'} className='property-wrapper childTemp'>{element.content}</div>)}
          </div>
        )
      } else {
        return (
          <div>
            {props.properties.map((element, idx) => <div key={idx + 'field'} className='property-wrapper child'>{element.content}</div>)}
          </div>
        )
      }
    }

    // FIXME: Junk code. Need to remove asap
    const updatedSchema = produce(schema, draft => {
      if (!isNotDefined(draft.required)) {
        const sumAssuredIndex = draft.required.indexOf('riderSumAssured')
        if (sumAssuredIndex > -1) {
          draft.required.splice(sumAssuredIndex, 1)
        }
      }
    })
    const customValidation = function () {
      if (!isNotDefined(customValidationMessage)) {
        const requiredKeys = Object.keys(schema.properties)
        if (!isNotDefined(customValidationMessage.message) && schema.properties[requiredKeys[0]].maximum === 0 && schema.properties[requiredKeys[0]].minimum === 0) {
          return customValidationMessage.message
        }
      }
    }
    return (
      <div id='editRider'>
        <JsonSchemaEasyForm
          {...formProps}
          customValidation={customValidation}
          schema={updatedSchema}
          uiSchema={uiSchema}
          formData={formData}
          objectTemplate={objectTemplate}
          onSubmit={this.onSubmit}
          rules={rules}
          onError={onError}
          onFocus={this.onFocus}
          noLabel={noLabel}
          liveValidate
        >
          {this.props.children || (
            <div className='editAddonsButtonGroup'>
              <Button
                id='saveRiderButton'
                type='submit'
                content={submitText || 'SAVE'}
                secondary
              />
              <Button
                className='riderCancel'
                basic
                id='riderCancel'
                onClick={this.onCancel}
                content={cancelText || 'Cancel'}
              />
            </div>
          )}
        </JsonSchemaEasyForm>
      </div>
    )
  }
}
export default EditAddOns
