import { useState } from 'react'
import { Button, Modal } from 'semantic-ui-react'
import { Body2 } from 'components/Header'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import LabelField from 'components/jsonSchemaForms/components/LabelField'
import Calendar from 'react-calendar'
import { isNotDefined } from 'utils/lib/basic'
import 'react-calendar/dist/Calendar.css'
import './ModalWidget.scss'
function addYear (date, count) {
  const maxDate = new Date(date.setFullYear(date.getFullYear() + count))
  return maxDate
}

function addDays (days) {
  return new Date(new Date().setDate(new Date().getDate() + days))
}

function addDaysOnExistingData (date, days) {
  return new Date(date.setDate(date.getDate() + days))
}

const ModalCalendarWidget = (props) => {
  const { formContext, options } = props
  const previousPolicyDetails = formContext?.previousPolicyDetails
  const previousPolicyType = formContext?.previousPolicyType
  const { modalOpen, handleModalClose } = formContext
  const [dateState, setDateState] = useState(initialize())
  const [modalOpenClose, setModalOpenClose] = useState(modalOpen)

  function initialize () {
    let value = new Date()
    const thirdPartyExist = !isNotDefined(previousPolicyType) && previousPolicyType === 'thirdParty'
    if (!isNotDefined(previousPolicyDetails) && thirdPartyExist) {
      value = new Date(previousPolicyDetails.previousPolicyEndDate)
    } else if (props.value && !thirdPartyExist) {
      value = new Date(props.value)
    }
    return value
  }

  const dateRange = () => {
    let minDate = new Date()
    let maxDate = addYear(new Date(), options.maxYear ?? 3)
    maxDate = addDaysOnExistingData(maxDate, -1)
    if (!isNotDefined(previousPolicyDetails) && previousPolicyType === 'thirdParty') {
      const { previousPolicyStatus } = previousPolicyDetails
      if (previousPolicyStatus === 'expiredLessThan45Days') {
        minDate = addDays(-45)
        maxDate = addDays(0)
      } else if (previousPolicyStatus === 'expiredBetween45To90Days') {
        minDate = addDays(-90)
        maxDate = addDays(-45)
      } else if (previousPolicyStatus === 'expiredMoreThan90Days') {
        minDate = addDays(undefined)
        maxDate = addDays(-90)
      }
    }
    return { minDate, maxDate }
  }

  const changeDate = (e) => {
    setDateState(e)
  }

  const handleSubmit = () => {
    // converted Date from IST format to YYYY--MM-DD format
    props.onChange(dateState.toLocaleDateString('fr-CA').replace(/\//g, '-'))
    setModalOpenClose(!modalOpenClose)
  }

  return (
    <Modal className='modalWidget' open={modalOpenClose}>
      <div className='modal_close_btn'>
        <Button basic onClick={handleModalClose}>
          <HighlightOffIcon fontSize='large' />
        </Button>
      </div>
      <Modal.Content>
        <Body2>
          <LabelField
            htmlFor='thirdPartyPolicyEndDate'
            className='control-label font-weight-bold h5 primaryLight'
            title='Third Party Policy End Date'
          />
        </Body2>
        <div className='mx-2'>
          <Calendar
            className='mx-auto'
            value={dateState}
            minDate={dateRange().minDate}
            maxDate={dateRange().maxDate}
            onChange={changeDate}
          />
        </div>
        {/* converted Date from IST format to DD-MM-YYYY format */}
        <p>Current selected date is <b>{dateState.toLocaleDateString('en-GB').replace(/\//g, '-')}</b></p>
        <Button className='mb-4' secondary onClick={handleSubmit}>Submit</Button>
      </Modal.Content>
    </Modal>
  )
}

export default ModalCalendarWidget
