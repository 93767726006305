const incomeInputSchema = {
  income: {
    title: 'How much income will you need?',
    type: 'integer'
  }
}
const incomePeriodYearSchema = {
  incomePeriodYear: {
    title: 'Select Income and Period',

    type: 'string',
    enum: [],
    enumNames: []
  }
}
const isaSchema = {
  isaPercent: {
    title: 'Select Annual increase in sum assured percentage',

    type: 'integer',
    enum: [],
    enumNames: []
  }
}
const iaSchema = {
  percent: {
    title: 'Select Annual increase in increasing percentage',
    type: 'integer',
    enum: [],
    enumNames: []
  }
}
const defRopSchema = {
  period: {
    title: 'Select Deferrment period',
    type: 'integer',
    enum: [],
    enumNames: []
  }
}
const ropSchema = {
  percent: {
    title: 'Select Return of purchase price',
    type: 'integer',
    enum: [],
    enumNames: []
  }
}
const acpSchema = {
  period: {
    title: 'Select Annuity for a certain period',
    type: 'integer',
    enum: [],
    enumNames: []
  }
}
const incomePeriodSchema = {
  incomePeriod: {
    title: 'Select income period in years',

    type: 'integer',
    enum: [],
    enumNames: []
  }
}
const incomeIncreaseSchema = {
  incomeIncreasePercentage: {
    title: 'Select annual income increase percent (of base sum assured)',
    type: 'integer',
    enum: [],
    enumNames: []
  }
}
module.exports = {
  incomeIncreaseSchema,
  incomePeriodSchema,
  isaSchema,
  incomePeriodYearSchema,
  incomeInputSchema,
  iaSchema,
  defRopSchema,
  acpSchema,
  ropSchema
}
