import { StyledEngineProvider } from '@mui/material/styles'
import { useState } from 'react'
import { JsonSchemaHelper, JsonSchemaOnboardForm } from 'components/jsonSchemaForms'
import { claimsSchema } from '../schema'
import { getSchemaForCurrentStep, getUISchemaForCurrentStep } from './myHelper'
import { Button, Modal } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import './claimRegistration.scss'
import steps from './steps.json'

const ClaimRegistration = (props) => {
  const { insuranceType, claimIntimation, claimRegistration } = props
  const registrationNo = claimRegistration?.registrationNo
  const [selectedPath, setSelectedPath] = useState('')
  const [currentStep, setCurrentStep] = useState(-1)
  const [uploadStatus, setUploadStatus] = useState(false)
  const [submitStatus, setSubmitStatus] = useState(false)
  const uploadSuccess = 'File Uploaded Successfully'
  const submitSuccess = `Claim Submitted Successfully, Claim Registration Number ${registrationNo}`
  const NotificationModal = (props) => {
    const { show, handleClose, message } = props
    return (
      <div>
        <Modal
          open={show}
          size='small'
        >
          <Modal.Content>
            <H6>{message}</H6>
            <Button content='OK' primary onClick={handleClose} />
          </Modal.Content>
        </Modal>
      </div>)
  }
  const handleUploadClose = () => {
    setUploadStatus(false)
  }
  const handleSubmitClose = () => {
    setSubmitStatus(false)
    props.redirectToPath('/')
  }

  const { claimApplicationForm: { uiSchema, schema } } = claimsSchema
  const currentSchema = getSchemaForCurrentStep(schema, steps, selectedPath, currentStep)
  const currentUiSchema = getUISchemaForCurrentStep(uiSchema, steps, selectedPath, currentStep)
  const handleBack = (e) => {
    e.preventDefault()
    if (currentStep > -1) {
      setCurrentStep(currentStep - 1)
    }
  }

  const bottomButtons = () => {
    const isButtonWidget = JsonSchemaHelper.checkButtonWidget(uiSchema)
    if (!isButtonWidget) {
      const label = currentStep === -1 ? 'Next' : currentStep === 0 ? 'Save' : currentStep === 1 ? 'Submit' : 'save'
      const id = label.split(' ').join('-') + '-btn'
      return (
        <div className='ms-3 mb-3 mt-4'>
          <Button id={id} secondary content={label} />
          {currentStep >= -1 && (
            <Button id='bottom-back-btn' onClick={handleBack} basic>
              BACK
            </Button>
          )}
        </div>
      )
    } else {
      return <></> // Render nothing if it's a button widget
    }
  }

  const transformErrors = (errors) => {
    const errorMappings = [
      { name: 'required', property: '.claimType', message: 'Please select a Claim Type' },
      { name: 'required', property: '.claimReason', message: 'Please select a Claim Reason' }
    ]
    return errors.map(error => {
      const { property } = error
      const mappedError = errorMappings.find(mapping => mapping.property === property)
      if (mappedError) {
        return {
          ...error,
          message: mappedError.message
        }
      }
      return error
    })
  }

  const onValidate = (formData, errors) => {
    if (formData.claimRegistration) {
      const claimTypeError = 'Please Select Claim Type'
      const claimReasonError = 'Please Select Claim Reason'
      formData.claimRegistration.claimType ?? errors.claimRegistration.claimType.addError(claimTypeError)
      formData.claimRegistration.claimReason ?? errors.claimRegistration.claimReason.addError(claimReasonError)
    }
    return errors
  }

  const handleSubmit = (e) => {
    if (currentStep === -1) {
      setSelectedPath(e.formData.claimReason)
      setCurrentStep(currentStep + 1)
    } else if (currentStep === 0) {
      const { insuranceCompany, policyNumber, intimationNo, lifeAssuredName, proposerName, claimReportingPersonName, claimType, lifeAssuredDateOfBirth, eventDate, eventCauses, nomineeName, claimantName, lifeAssuredRelationship, claimantEmail, claimantMobileNo, claimantAddress, country, state, district, pinCode, city, distributorId, iamId } = claimIntimation
      const enquiryBody = {
        insuranceCompany,
        policyNumber,
        intimationNo,
        lifeAssuredName,
        proposerName,
        claimReportingPersonName,
        insuranceType,
        claimType,
        lifeAssuredDateOfBirth,
        eventDate,
        eventCauses,
        nomineeName,
        claimantName,
        lifeAssuredRelationship,
        claimantEmail,
        claimantMobileNo,
        claimantAddress,
        country,
        state,
        district,
        pinCode,
        city,
        distributorId,
        iamId
      }
      const queryParams = { insuranceType, action: 'registration' }

      const query = { enquiryData: enquiryBody, queryParams }
      props.postRegistration(query)
      setSubmitStatus(true)
    }
  }

  return (
    <StyledEngineProvider injectFirst>
      <div className='claimRegistrationForm'>
        <div className='bg_claim_registration_card'>
          <JsonSchemaOnboardForm
            schema={currentSchema}
            uiSchema={currentUiSchema}
            rules={[]}
            onSubmit={handleSubmit}
            onValidate={onValidate}
            transformErrors={transformErrors}
            liveValidate={false}
          >
            {bottomButtons()}
          </JsonSchemaOnboardForm>
        </div>
      </div>
      <NotificationModal show={uploadStatus} handleClose={handleUploadClose} message={uploadSuccess} />
    {registrationNo && (<NotificationModal show={submitStatus} handleClose={handleSubmitClose} message={submitSuccess} />)}
    </StyledEngineProvider>
  )
}

export default ClaimRegistration
