import { useEffect, useRef } from 'react'
import { Icon, Segment } from 'semantic-ui-react'
import { Caption, H5, H6, Subtitle1, Subtitle2 } from 'components/Header'
import dayjs from 'dayjs'
import { formatAmount } from 'utils/lib/calc'
import { isNotEmpty } from 'utils'

import CircularProgressbarComponent from './CircularProgressbarComponent'
import PolicyDetailsMainPageTabs from './PolicyDetailsMainPageTabs'

import './policyTabs.scss'
import './policyDetailsMainPage.scss'
import { PolicyDetails } from './PolicyDetails'

const PolicyDetailsMainPage = (props) => {
  const {
    policy,
    loadPolicyDetails,
    loadingPolicyDetails,
    device
  } = props
  const previousLoading = useRef(false)

  useEffect(() => {
    loadPolicyDetails({ id: policy.application.applicationNumber })
    window.scrollTo(0, 0)
  },
  // eslint-disable-next-line
  [])

  useEffect(() => {
    previousLoading.current = loadingPolicyDetails
  }, [loadingPolicyDetails])

  const policyHeader = (issuanceDate, productBrochure) => {
    const style = device === 'mobile' ? { marginRight: '0.5rem', flex: '1' } : {}
    return (
      <div className='titleAndDownloadBrochure'>
        <div style={style}>
          <H5 color='primaryLight' content={policy.productDetails.productName} />
          <Subtitle1 className='subtitle2' color='secondary'>{issuanceDate}</Subtitle1>
        </div>
        <div className='downloadLinks'>
          <Subtitle2 color='secondary' as='a' target='_blank' className='d-flex gap-1'>
            <Icon name='download' />
            POLICY SUMMARY
          </Subtitle2>
          <Subtitle2 color='secondary' as='a' target='_blank' className='d-flex gap-1' href={productBrochure}>
            <Icon name='download' />
            PRODUCT BROCHURE
          </Subtitle2>
        </div>
      </div>
    )
  }

  const policyAssuredAmount = (policy) => {
    let amtText = { amtDisplay: '0', amtLabelDisplay: 'Thousands' }
    let label
    if (policy.sumAssured) {
      label = 'Sum Assured'
      amtText = formatAmount(policy.sumAssured)
    } else if (policy.idv && policy.idv > 0) {
      label = 'Insured Declared Value'
      amtText = formatAmount(policy.idv)
    }
    const { amtDisplay, amtLabelDisplay } = amtText
    return label && (
      <div className='sumAssured'>
        <Caption className='caption'>{label}</Caption>
        {/* <Icon color='green' name='icon-rupees' /> */}
        <H5 color='green'>{`${amtDisplay} ${amtLabelDisplay}`}</H5>
      </div>
    )
  }

  let loading
  let content
  if (!(previousLoading.current && !loadingPolicyDetails)) {
    loading = (
      <div className='loaderOuter applicationLoader' id='loading'>
        <div className='ball-spin-fade-loader'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  } else {
    const productBrochure = policy.productDetails.productBrochureUrl
    let issuanceDate
    if (isNotEmpty(policy.policyIssuanceDate)) {
      issuanceDate = `Insurance Issued on ${dayjs(policy.policyIssuanceDate).format('ll')}`
    } else if (isNotEmpty(policy.policyStartDate)) {
      issuanceDate = `Insurance Issued on ${dayjs(policy.policyStartDate).format('ll')}`
    } else {
      issuanceDate = 'Issuance date not available'
    }
    content = (
      <>
        {policyHeader(issuanceDate, productBrochure)}
        <div className='block'>
          {policyAssuredAmount(policy)}
          {
            policy.insuranceType !== 'annuity' && (
              <div className='premiumData'>
                {(policy.previousPremiums) && (
                  <div className='lastPremiumDate'>
                    <H6>Last Premium</H6>
                    <Subtitle2
                      className='subtitle2'
                      color='secondary'
                    >
                      {(policy.previousPremiums.length > 0) ? `${dayjs(policy.previousPremiums[0].dueDate).format('ll')}` : 'No Previous Premium'}
                    </Subtitle2>
                  </div>)}
                {policy.paymentSchedule && (
                  <div className='numberOfPremiums' style={{ width: 88, height: 88 }}>
                    <CircularProgressbarComponent
                      policy={policy}
                    />
                  </div>)}
                {policy.upcomingPremiums && (
                  <div className='nextPremiumDate'>
                    <H6>Next Premium</H6>
                    <Subtitle2
                      className='subtitle2'
                      color='secondary'
                    >
                      {(policy.upcomingPremiums.length > 0) ? `${dayjs(policy.upcomingPremiums[0].dueDate).format('ll')}` : 'No Upcoming Premium'}
                    </Subtitle2>
                  </div>)}
              </div>
            )
          }
        </div>
        {policy.upcomingPremiums
          ? <PolicyDetailsMainPageTabs
              policy={policy}
              device={device}
            />
          : (
            <div className='tab-content'>
              <PolicyDetails policy={policy} device={device} />
            </div>
            )}
      </>
    )
  }
  return (
    <Segment className={(!(previousLoading.current && !loadingPolicyDetails)) ? 'policyDetailsMainPageSegment loaderParent' : 'policyDetailsMainPageSegment'} style={{ minHeight: '20rem' }}>
      {loading}
      {content}
    </Segment>
  )
}

export default PolicyDetailsMainPage
