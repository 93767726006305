import { Icon } from 'semantic-ui-react'
import RightSegment from './RightSegment'
const style = {
  segmentGroupOuter: {
    border: 0,
    borderRadius: 0,
    background: '#ffffff'
  },
  segmentGroup: {
    border: 0,
    borderRadius: 0
  },
  fieldRow: {
    paddingRight: 0
  }
}
const FieldRowInline = (props) => {
  const { icon, displayText, className, children } = props
  const segmentWidth = (icon || displayText) ? '90%' : '100%'
  return (
    <div
      style={style.fieldRow}
      className={className}
    >
      <div style={{ width: segmentWidth }}>
        {children}
      </div>
      {(icon)
        ? <RightSegment>
          <Icon size='large' name={icon} />
          </RightSegment>
        : (displayText)
            ? <RightSegment>
              <div><b>{displayText}</b></div>
              </RightSegment>
            : null}
    </div>
  )
}
export default FieldRowInline
