import { PureComponent } from 'react'
import { LoadingContext } from 'components/context'
import Description from '../../Description'
class DetailRider extends PureComponent {
  render () {
    const {
      rider
    } = this.props
    return (
      <Description description={rider.description} heading={rider.heading} points={rider.points} />
    )
  }
}
DetailRider.contextType = LoadingContext
export default DetailRider
