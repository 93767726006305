import { useState, useEffect } from 'react'
import { Search, Input, Grid } from 'semantic-ui-react'
import { isNotEmpty } from 'utils'

import LabelField from '../components/LabelField'
import debounce from 'lodash/debounce'
import PropTypes from 'prop-types'
import { getCityAndState } from 'smartcovr_api/src/insuranceApi'
import TextareaWidget from './TextareaWidget'
import InputWidget from './InputWidget'
import SelectButtonWidget from './SelectButtonWidget'
import FieldRowInline from '../components/FieldRowInline'
const AddressWidget = (props) => {
  const { value } = props
  const data = value && JSON.parse(value)
  const [address, setAddress] = useState(data?.addressLine1)
  const [landmark, setLandmark] = useState(data?.landmark)
  const [selectedPincode, setSelectedPincode] = useState(data?.csp.pincode)
  const [selectedCity, setSelectedCity] = useState(data?.csp.city)
  const [selectedState, setSelectedState] = useState(data?.csp.state)
  const [selectedCountry, setSelectedCountry] = useState(data?.country)
  const [country] = useState([{ id: '1', label: 'India', value: 'IND' }])

  const [results, setResults] = useState([])
  const [showNoResults, setShowNoResults] = useState(false)

  // initialize state with the initial pincode
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await getCityAndState(selectedPincode)
        if (isNotEmpty(data)) {
          setResults(
            data.map((item, ix) => {
              return {
                title: selectedPincode,
                description: JSON.stringify({ city: item.city, state: item.state }),
                data: item,
                id: item.pincode,
                key: `${item.pincode}_${ix}`
              }
            })
          )
        } else {
          setError(true)
          setResults([])
          setShowNoResults(true)
        }
      } catch (err) {
        setResults([])
        setError(true)
        setShowNoResults(true)
      }
    }

    if (
      !error &&
      results.length === 0 &&
      typeof selectedPincode === 'string' &&
      selectedPincode.length === 6
    ) {
      fetchResults()
    }
  },
  // eslint-disable-next-line
  [results])

  useEffect(() => {
    if (
      isNotEmpty(address) &&
      isNotEmpty(landmark) &&
      isNotEmpty(country) &&
      isNotEmpty(selectedCity) &&
      isNotEmpty(selectedState) &&
      isNotEmpty(selectedPincode)
    ) {
      const formData = {
        addressLine1: address,
        landmark,
        country: selectedCountry,
        csp: {
          city: selectedCity,
          state: selectedState,
          pincode: selectedPincode.toString()
        }
      }
      props.onChange(JSON.stringify(formData))
    }
  })

  function handleSearchChange (_e, { value }) {
    console.log(value)
    if (value.length <= 6) {
      setSelectedPincode(value.toString())
      setResults([])
      setShowNoResults(false)
      setError(false)
    }
  }

  function handleResultSelect (_e, { result: { data } }) {
    setSelectedPincode(data.pincode)
    setSelectedCity(data.city)
    setSelectedState(data.state)
  }

  function displayFields () {
    return (
      results.length > 0 && (
        <Grid>
          <Grid.Row className='flex-nowrap'>
            <FieldRowInline>
              <LabelField
                className='control-label mt-2 pl-3 text-dark'
                htmlFor='city'
                title='City'
              />
              <Input value={selectedCity ?? ''} placeholder='City' readOnly />
            </FieldRowInline>
            <FieldRowInline>
              <LabelField
                className='control-label mt-2 pl-3 text-dark'
                htmlFor='state'
                title='State'
              />
              <Input value={selectedState ?? ''} placeholder='State' readOnly />
            </FieldRowInline>
          </Grid.Row>
        </Grid>
      )
    )
  }

  const renderer = ({ title, description }) => {
    let descriptionEl = ''
    if (description) {
      const { city, state } = JSON.parse(description)
      descriptionEl = (
        <div className='description'>
          City: {city} <br />
          State: {state}
        </div>
      )
    }
    return (
      <div className='content'>
        {title && <div className='title'>Pincode: {title}</div>}
        {descriptionEl}
      </div>
    )
  }
  return (
    <div className='pl-3 mt-3'>
      <LabelField
        className='control-label d-block customLabel text-dark'
        htmlFor='address'
        title='Address'
      />
      <TextareaWidget
        id='address'
        value={address}
        className='border-0'
        placeholder='Address'
        options={{ maxLength: 250, rows: 3 }}
        onChange={(value) => setAddress(value)}
      />
      <LabelField
        className='control-label customLabel text-dark'
        htmlFor='landmark'
        title='Landmark'
      />
      <InputWidget
        id='landmark'
        value={landmark}
        options={{ inputType: 'text' }}
        placeholder='Landmark'
        onChange={(value) => setLandmark(value)}
      />
      <LabelField
        className='control-label customLabel text-dark'
        htmlFor='country'
        placeholder='Country'
        title='Country'
      />
      <SelectButtonWidget
        options={{ enumOptions: country }}
        value={selectedCountry}
        onChange={(value) => setSelectedCountry(value)}
        readOnly
        schema={{}}
      />
      <LabelField
        className='control-label customLabel text-dark'
        htmlFor='pincode'
        title='Pincode'
      />
      <Search
        id='root_pincode'
        as={Input}
        placeholder='Pincode'
        resultRenderer={renderer}
        showNoResults={showNoResults}
        onResultSelect={handleResultSelect}
        onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
        results={results}
        value={selectedPincode}
        noResultsMessage={error ? 'Oops! Enter pincode again.' : 'Invalid pincode'}
      />
      {displayFields()}
    </div>
  )
}

AddressWidget.propTypes = {
  onChange: PropTypes.func.isRequired,
  uiSchema: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired
}

export default AddressWidget

// <CustomFieldWrapper
//   schema={schema}
//   uiSchema={uiSchema}
// >
// </CustomFieldWrapper>
