
import { useState, useEffect } from 'react'
import { Search, Input, Grid } from 'semantic-ui-react'
import { isNotDefined, isNotEmpty } from 'utils'

import LabelField from '../components/LabelField'
import debounce from 'lodash/debounce'
import CustomFieldWrapper from '../components/CustomFieldWrapper'
import PropTypes from 'prop-types'
import { getCityAndState } from 'smartcovr_api/src/insuranceApi'
import FieldRowInline from '../components/FieldRowInline'
const GenPincodeField = (props) => {
  const [results, setResults] = useState([])
  const [showNoResults, setShowNoResults] = useState(false)
  const { schema, formData, uiSchema, errorSchema } = props
  const [value, setValue] = useState(formData && formData.pincode)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchResults = async () => {
      try {
        const data = await getCityAndState(value)
        if (isNotEmpty(data)) {
          setResults(data.map((item, ix) => {
            return {
              title: value,
              description: JSON.stringify({ city: item.city, state: item.state }),
              data: item,
              id: item.pincode,
              key: `${item.pincode}_${ix}`
            }
          }))
        } else {
          setResults([])
          setShowNoResults(true)
        }
      } catch (err) {
        setResults([])
        setError(true)
        setShowNoResults(true)
      }
    }
    // call api only if formdata doesn't have pincode and complete pincode is filled
    if (!(formData && formData.pincode) && value && value.length === 6) {
      fetchResults()
    }
  }, [formData, value])

  function handleSearchChange (_e, { value }) {
    if (value.length <= 6) {
      setValue(value.toString())
      setResults([])
      setShowNoResults(false)
      setError(false)
      props.onChange({})
    }
  }
  const placeholder = uiSchema.pincode['ui:placeholder']

  function handleResultSelect (e, { result }) {
    setValue(result.data.pincode)
    const formData = {
      pincode: result.data.pincode.toString(),
      city: result.data.city,
      state: result.data.state
    }
    props.onChange(formData)
  }

  function displayFields () {
    return (
      !isNotDefined(formData.pincode) && (
        <Grid>
          <Grid.Row className='flex-nowrap'>
            <FieldRowInline>
              <LabelField
                className='control-label mt-2 pl-3 secondary'
                title={schema.properties.city.title}
              />
              <Input className='pl-0' value={formData.city ?? ''} placeholder={uiSchema.city['ui:placeholder']} readOnly />
            </FieldRowInline>
            <FieldRowInline>
              <LabelField
                title={schema.properties.state.title}
                className='control-label mt-2 pl-3 secondary'
              />
              <Input className='pl-0' value={formData.state ?? ''} placeholder={uiSchema.state['ui:placeholder']} readOnly />
            </FieldRowInline>
          </Grid.Row>
        </Grid>
      )
    )
  }

  const renderer = ({ title, description }) => {
    let descriptionEl = ''
    if (description) {
      const { city, state } = JSON.parse(description)
      descriptionEl = (
        <div className='description'>
          City: {city} <br />
          State: {state}
        </div>
      )
    }
    return (
      <div className='content'>
        {title && <div className='title'>Pincode: {title}</div>}
        {descriptionEl}
      </div>
    )
  }

  return (
    <CustomFieldWrapper
      schema={schema}
      uiSchema={uiSchema}
      errorSchema={errorSchema}
    >
      <Search
        id='root_pincode'
        as={Input}
        type='number'
        resultRenderer={renderer}
        placeholder={placeholder}
        showNoResults={showNoResults}
        onResultSelect={handleResultSelect}
        onSearchChange={debounce(handleSearchChange, 500, { leading: true })}
        results={results}
        value={value}
        noResultsMessage={error ? 'Oops! Enter pincode again.' : 'Invalid pincode'}
      />
      {(isNotEmpty(formData))
        ? displayFields()
        : null}
    </CustomFieldWrapper>
  )
}

GenPincodeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  uiSchema: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired
}

export default GenPincodeField
