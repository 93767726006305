import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'

export default function TrackClaimModal (props) {
  const { setClaimType, setFormStep } = props

  const handleChangeRadio = (e) => {
    setClaimType(e.target.value)
    setFormStep(2)
  }

  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'gray' }}>
      <Box width="50%" p={2} bgcolor="white" boxShadow={3} borderRadius={4}>
        <FormControl component="fieldset">
          <Typography variant="h6" gutterBottom>
            Choose Option
          </Typography>
          <RadioGroup row>
            <FormControlLabel value="Current" control={<Radio />} label="Current Claim Status" onClick={handleChangeRadio}/>
            <FormControlLabel value="Old" control={<Radio />} label="Old Settled or Rejected Claims" onClick={handleChangeRadio}/>
          </RadioGroup>
        </FormControl>
      </Box>
    </div>
    </>
  )
}
