import ProcessPaymentContainer from './ProcessPaymentContainer'
import PropTypes from 'prop-types'
import queryString from 'query-string'
// FIXME: Process Payment to be completed
const Screen = (props) => {
  const searchQuery = queryString.parse(window.location.href.split('?')[1])
  return (
    <ProcessPaymentContainer
      {...props}
      msg={{
        msg: searchQuery.msg,
        authStatus: searchQuery.AuthStatus
      }}
      enquiryId={props.enquiryId}
    />
  )
}

Screen.propTypes = {
  location: PropTypes.object
}
Screen.path = 'booking/process-payment/:id'
export default Screen
