import { useEffect, useState } from 'react'
import { Button, Card, Checkbox, Grid, List, Modal } from 'semantic-ui-react'
import { Body2 } from 'components/Header'
import { isNotDefined } from 'utils/lib/basic'
import TooltipComponent from 'components/TooltipComponent'
import 'font-awesome/css/font-awesome.min.css'
import './cardWidget.scss'

const styles = {
  contentStyle: {
    width: 250,
    fontSize: 14,
    opacity: 1,
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
  },
  position: {
    width: '30%',
    minWidth: '18rem'
  },
  image: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    display: 'unset'
  }
}

const InspectionMaodal = ({ inspectionModal, setInspectionModal }) => {
  return (
    <Modal style={styles.position} open={inspectionModal}>
      <Modal.Header>Note</Modal.Header>
      <Modal.Content style={styles.modalContent}>
        <Body2 color='grey'>
          Your policy has been upgraded, vehicle inspection is required.
        </Body2>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setInspectionModal(false)}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const CardWidget = (props) => {
  const {
    id,
    value,
    disabled,
    onBlur,
    onFocus,
    options,
    required,
    formContext,
    onChange
  } = props
  const { enumOptions } = options
  const { insuranceType, policyTypeDetails, policyOption } = formContext

  useEffect(() => {
    if (policyOption === 'renewExistingPolicy' && ['thirdParty', 'standalone'].includes(previousPolicyType) && value === 'comprehensive') {
      setInspectionModal(true)
    }
  }, [value])  // eslint-disable-line
  let radioOptions
  if (!isNotDefined(policyTypeDetails)) {
    radioOptions = enumOptions.map(({ value }, i) => {
      const content = policyTypeDetails[value]
      const { title, shortDescription, description, includes, excludes } = content
      return { key: i, value, title, shortDescription, description, includes, excludes }
    })
  } else {
    radioOptions = enumOptions.map(({ value, label }, i) => {
      return { key: i, value, title: label }
    })
  }
  let disabledValue = disabled
  if (!disabledValue) {
    disabledValue = options.disabled
  }

  const previousPolicyType = formContext?.previousPolicyType
  const [inspectionModal, setInspectionModal] = useState(false)

  const renderLabel = (title) => (
    <label>
      <div id='card-label'>{title}</div>
    </label>
  )
  const renderModal = () => {
    if (policyOption === 'renewExistingPolicy' && ['thirdParty', 'standalone'].includes(previousPolicyType) && value === 'comprehensive') {
      return (
        <InspectionMaodal {...{ inspectionModal, setInspectionModal }} />
      )
    }
  }
  return (
    <Grid stackable>
      <Grid.Row columns={radioOptions.length}>
        {insuranceType === 'car' && renderModal()}
        {radioOptions.map((content, index) => {
          const { title, shortDescription, description, includes, excludes } = content
          const selected = content.value === value
          return (
            <Grid.Column key={index}>
              <Card className={`cardWidget${selected ? ' selected' : ''}`}>
                <Card.Content>
                  {selected &&
                    <div className='chip' title='selected'>
                      <i className='fa fa-check' />
                    </div>}
                  <Checkbox
                    id={`${id}-${content.value}`}
                    key={`checkbox${index}`}
                    radio
                    label={renderLabel(title)}
                    name={`${id}_radioGroup`}
                    className={`cardWidgetBtn${shortDescription ? ' position-absolute' : ''}`}
                    value={content.value}
                    checked={selected}
                    onChange={(e, { value }) => onChange(value)}
                    disabled={(options.enumDisabled || [])?.some(v => v === content.value) || disabledValue}
                    required={required}
                    onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
                    onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
                  />
                  <TooltipComponent id={content.value} contentStyle={styles.contentStyle} customIconStyle={styles.image}>
                    {description}
                  </TooltipComponent>
                  {shortDescription && <Card.Description>{shortDescription}</Card.Description>}
                </Card.Content>
                {includes &&
                  <Card.Content extra>
                    <List>
                      {includes.map((include, idx) => (
                        <List.Item key={`include-feature-${idx}`}>
                          <List.Icon>
                            <i className='fa fa-check text-success' />
                          </List.Icon>
                          <List.Content>{include}</List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </Card.Content>}
                {excludes &&
                  <Card.Content extra>
                    <List>
                      {excludes.map((exclude, idx) => (
                        <List.Item key={`exclude-feature-${idx}`}>
                          <List.Icon>
                            <i className='fa fa-close text-danger' />
                          </List.Icon>
                          <List.Content>{exclude}</List.Content>
                        </List.Item>
                      ))}
                    </List>
                  </Card.Content>}
              </Card>
            </Grid.Column>
          )
        })}
      </Grid.Row>
    </Grid>
  )
}
export default CardWidget
