import { Menu } from 'semantic-ui-react'

const FilterMenuWebAuto = (props) => {
  const { ComponentMap, formData, disableEdit } = props
  return (
    <Menu fluid>
      <Menu.Item>
        {ComponentMap.policyTerm}
      </Menu.Item>
      {(formData.policyType !== 'thirdParty') && (
        <Menu.Item>
          {ComponentMap.idv}
        </Menu.Item>
      )}
      {!disableEdit && (
        <Menu.Item>
          {ComponentMap.policyType}
        </Menu.Item>
      )}
    </Menu>
  )
}

export default FilterMenuWebAuto
