import React from 'react'

const dataAccessInitialState = {
  device: 'web',
  formType: 'inline',
  view: 'cardView'
}

const DataAccessContext = React.createContext(dataAccessInitialState)
export {
  DataAccessContext,
  dataAccessInitialState
}
