import { PureComponent } from 'react'
import { Button } from 'semantic-ui-react'
import { adopt } from 'react-adopt'
import { DataAccessContext } from 'components/context'
import PropTypes from 'prop-types'
import JsonSchemaEasyForm from 'components/jsonSchemaForms/JsonSchemaEasyForm'
import produce from 'immer'
import { validateFormData } from 'smartcovr_api/src/insuranceApi'
import { getErrorMessageByKey } from 'uiCommonUtils'
import {
  isNotEmpty

} from 'utils'
import { get } from 'lodash'
import { isNotDefined } from 'utils/lib/basic'
import { getCustomErrorMessage } from 'utils/lib/customValidationErrors'
class SingleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      schema: this.props.configure.onboard.filterSchema.schema,
      formData: this.props.formData
    }
    this.onClick = this.onClick.bind(this)
    this.onChangeData = this.onChangeData.bind(this)
    this.transformErrors = this.transformErrors.bind(this)
  }

  calculateAge (dateOfBirth) {
    const diffMs = Date.now() - new Date(dateOfBirth).getTime()
    const ageDt = new Date(diffMs)
    return Math.abs(ageDt.getUTCFullYear() - 1970)
  }

  /**
    * validate form data on submit
    *
    * @memberof SingleFormxs
    */
  onClick ({ formData }) {
    const { setFormData } = this.props
    const newFormData = produce(formData, draft => {
      draft.pAge = this.calculateAge(draft.pDob)
      draft.sAge = this.calculateAge(draft.sDob)
    })
    setFormData(newFormData, 'basicDetails')
  }

  onChangeData (formData, errors) {
    const { insuranceType, schema, configure } = this.props
    const validationErrors = validateFormData({
      insuranceType,
      formData,
      schemaVal: schema
    })

    const newErrors = produce(errors, draft => {
      if (isNotEmpty(validationErrors) && isNotEmpty(validationErrors.errors) && validationErrors.errors[0].dataPath === '.pDob') {
        const errorMessage = getErrorMessageByKey('dateOfBirth', configure.onboard.inlineForm, validationErrors)
        if (errorMessage != null) { draft.pDob.addError(errorMessage) }
      }
      if (isNotEmpty(validationErrors) && isNotEmpty(validationErrors.errors) && validationErrors.errors[0].dataPath === '.sDob') {
        const errorMessage = getErrorMessageByKey('dateOfBirth', configure.onboard.inlineForm, validationErrors)
        if (errorMessage != null) { draft.sDob.addError(errorMessage) }
      }
    })
    return newErrors
  }

  componentDidMount () {
    // Typical usage (don't forget to compare props):
    const { id, configure: { onboard: { filterSchema: { schema } } } } = this.props
    if (id === 'primaryEditBtn') {
      this.setState({ schema: schema.properties.primary })
    } else if (id === 'secondaryEditBtn' || id === 'listing_SecondaryDetails' || id === 'listing') {
      this.setState({ schema: schema.properties.secondary })
    }
  }

  transformErrors (errors) {
    const schema = this.state.schema
    if (isNotEmpty(errors)) {
      // eslint-disable-next-line array-callback-return
      errors.map(error => {
        const selectedSchema = get(schema, `properties${error.property}`)
        if (error.name === 'smartcovr-dateValidation') {
          const ageRangeMessage = `Age should be ${
            selectedSchema.minAge
              ? `greater than ${selectedSchema.minAge} years`
              : ''
          } ${selectedSchema.minAge && selectedSchema.maxAge ? 'and ' : ''}${
            selectedSchema.maxAge
              ? `lesser than ${selectedSchema.maxAge} years`
              : ''
          }.`
          if (selectedSchema.minAge && selectedSchema.minAge === 30) {
            error.message = ageRangeMessage
          } else {
            error.message = 'Invalid Date.'
          }
        } else if (error.name === 'maximum' && error.params.limit === 0 && this.props.customValidation) {
          const message = this.props.customValidation()
          if (!isNotDefined(message)) {
            error.message = message
          }
        } else {
          let title = 'This'
          if (selectedSchema) {
            title = selectedSchema.title
          }
          error.message = getCustomErrorMessage(title, error)
        }
      })
    }
    return errors
  }

  render () {
    const { listing, formData, configure, insuranceType, editId } = this.props
    const { uiSchema } = configure.onboard.filterSchema
    return (
      <JsonSchemaEasyForm
        schema={this.state.schema}
        uiSchema={uiSchema}
        onSubmit={this.onClick}
        onCancel={this.props.onBack}
        formData={formData}
        onChangeData={this.onChangeData}
        rules={[]}
        liveValidate
        listing
        insuranceType={insuranceType}
        editId={editId}
        transformErrors={this.transformErrors}
      >
        <div className='submitButtons'>
          <Button id='viewProductsButton' secondary content={listing ? 'UPDATE' : 'PROCEED'} />
          {listing && <Button basic onClick={this.props.onBack}> BACK </Button>}
        </div>
      </JsonSchemaEasyForm>

    )
  }
}

const WithContext = adopt({
  dataAccess: <DataAccessContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ dataAccess }) => {
        return (
          <SingleForm
            {...props}
            formType={dataAccess.formType}
            device={dataAccess.device}
            setFormData={props.setFormData}
          />
        )
      }
    }
  </WithContext>
)
WrapperComponent.propTypes = {
  setFormData: PropTypes.func.isRequired,
  onBack: PropTypes.func
}
export default WrapperComponent
