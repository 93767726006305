import { Caption } from 'components/Header'

const GstDisclaimer = (props) => {
  const { gstDisclaimer } = props
  return (
    <div>
      <Caption className='subtitle'>
        {gstDisclaimer}
      </Caption>
    </div>
  )
}
export default GstDisclaimer
