import { Subtitle1 } from 'components/Header'
import dayjs from 'dayjs'

const PolicyPremiumContent = ({ data }) => {
  return (
    <>
      <div className='premiumDetails'>
        <Subtitle1 className='subtitle1'>
          {dayjs(data.dueDate).format('ll')}
        </Subtitle1>
      </div>
    </>
  )
}

export default PolicyPremiumContent
