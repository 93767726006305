import JsonSchemaEasyForm from 'components/jsonSchemaForms/JsonSchemaEasyForm'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { Button } from 'semantic-ui-react'
import { H6, Subtitle1 } from 'components/Header'
import { isNotDefined } from 'utils'
import DividedSegmentGreySeperator from 'components/DividedSegmentGreySeperator'

const PaymentOptionConfigure = (props) => {
  const { onPaymentChange, paymentOptionSchema, paymentOptionSubmit, config, ...formProps } = props
  const { schema, uiSchema, paymentFormData } = paymentOptionSchema
  const { heading, description } = config
  if (isNotDefined(paymentOptionSchema)) {
    return (<div />)
  }
  return (

    <DividedSegmentGreySeperator
      leftSegment={(
        <>
          <H6 color='primaryLight' content={heading} />
          <Subtitle1 color='grey'>
            <DisplayHtmlString htmlContent={description} />
          </Subtitle1>
          <div className='inner'>
            <JsonSchemaEasyForm
              {...formProps}
              schema={schema}
              uiSchema={uiSchema}
              formData={paymentFormData}
              onChange={onPaymentChange}
              rules={[]}
            >
              <div style={{ display: 'none' }} />
            </JsonSchemaEasyForm>
          </div>
          <Button
            id='save'
            type='submit'
            content='PROCEED'
            primary
            onClick={paymentOptionSubmit}
          />
        </>
      )}
      rightSegment={(
        <div />
      )}
    />
  )
}
export default PaymentOptionConfigure
