import { useState } from 'react'
import { Menu, Image } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import { isNotEmpty } from 'utils'
import { buildCommonImagePath } from 'components/buildImagePath'
import FilterMenuMobileTerm from './FilterMenuMobileTerm'
import FilterMenuMobileAuto from '../auto/FilterMenuMobileAuto'
import FilterMenuMobileAnnuity from './FilterMenuMobileAnnuity'
import FilterMenuMobileHealth from './filterMenuMobileHealth'

const FilterMapper = {
  term: FilterMenuMobileTerm,
  car: FilterMenuMobileAuto,
  bike: FilterMenuMobileAuto,
  annuity: FilterMenuMobileAnnuity,
  health: FilterMenuMobileHealth
}
// FIXME: filterChangeHandler and onDone are not implemented as filterChoices in smartcovr api takes single key

const FilterMenuMobile = (props) => {
  const [currentFilterData, setCurrentFilterData] = useState({})
  const { onFilterChange, resetFilter } = props

  // eslint-disable-next-line
  function filterChangeHandler (filterData) {
    setCurrentFilterData(...currentFilterData, filterData)
    if (filterData.value.length === 0) {
      resetFilter(filterData.key)
    } else {
      onFilterChange(filterData)
    }
  }
  // eslint-disable-next-line
  function onDone () {
    onFilterChange(currentFilterData)
  }

  function handleSidebarHide () {
    const { handleSidebarHide } = props
    setCurrentFilterData({})
    handleSidebarHide()
  }

  function handleResetFilter () {
    const { resetFilter } = props
    setCurrentFilterData({})
    resetFilter('all')
  }
  const { filters, insuranceType } = props
  const FilterMenuDisplay = FilterMapper[insuranceType]
  const filterAdded = isNotEmpty(currentFilterData) // when Changes are made in the current state.
  const hasFilter = isNotEmpty(filters.filterData) // if filter is applied
  const mobileClassName = `filterContentMobile ${insuranceType ?? ''}MobileFilterMenu`
  return (
    <Menu borderless fluid vertical className={mobileClassName}>
      <div className='filterHeader flexContainer alignFlexItems'>
        {!filterAdded && (
          <>
            <div className='filterBack flexContainer alignFlexItems' onClick={handleSidebarHide}>
              <Image src={buildCommonImagePath('white-drop-left-icon.png')} />
            </div>
            <H6 color='white' className='filterText'>Filter</H6>
            {hasFilter && (
              <H6 color='white' className='doneText' onClick={handleResetFilter}>CLEAR ALL</H6>
            )}
          </>
        )}
        {filterAdded && (
          <>
            <div className='filterClose flexContainer alignFlexItems' onClick={handleSidebarHide}>
              <Image src={buildCommonImagePath('white-cross-icon.png')} />
            </div>
            <H6 color='white' className='doneText' onClick={handleSidebarHide}>DONE</H6>
          </>
        )}
      </div>
      <FilterMenuDisplay {...props} />
    </Menu>
  )
}

export default FilterMenuMobile
