import { useState } from 'react'
import { TaskTwoTone, DeleteOutlineTwoTone } from '@mui/icons-material'
import './fileUploadWidget.scss'
function FileUploadWidget () {
  // const [file, setFile] = useState()
  const [flag, setFlag] = useState(true)
  // const { formContext: { setUploadStatus } } = props

  const handleFileChange = (e) => {
    if (e.target.files) {
      // setFile(e.target.files[0])
      // setUploadStatus(true)
      setFlag(false)
    }
  }

  if (flag) {
    return (
      <div className='fileUpload'>
        <label className='ui huge floated button'>
          Click to upload files here
          &nbsp;
        <input type="file" onChange={handleFileChange} className='inputfile' />
        <i className="ui upload icon"></i>
        </label>
      </div>
    )
  } else {
    return (
      <div className='fileUpload'>
        <label className='ui huge floated button'>
        <TaskTwoTone />
          File Uploaded Successfully
          &nbsp;
        <input type="file" onChange={handleFileChange} className='inputfile' />
        <DeleteOutlineTwoTone />
        </label>
      </div>
    )
  }
}

export default FileUploadWidget
