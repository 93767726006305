import NumberFormat from 'react-number-format'
import FormInput from 'components/FormInput'

const NumberFormatWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    onChange,
    placeholder,
    options
  } = props
  const format = options ? options.format : undefined
  const styles = options.styles
  return (
    <NumberFormat
      id={(!id) ? 'numberFormatWidget' : id}
      placeholder={placeholder}
      format={format}
      mask={' '}
      value={value}
      customInput={FormInput}
      disabled={disabled}
      readOnly={readonly}
      onValueChange={({ value }) => onChange(value, props, options)}
      style={styles}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
      options={options}
    />
  )
}

export default NumberFormatWidget
