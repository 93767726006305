
import { connect } from 'react-redux'
import PaymentGateway from './components/PaymentGateway'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  return {
    ...ownProps,
    insuranceType
  }
}

const PaymentGatewayContainer = connect(mapStateToProps)(PaymentGateway)
PaymentGatewayContainer.path = 'payment-gateway'
export default PaymentGatewayContainer
