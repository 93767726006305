import './polyfills'
import makeDebug from 'debug'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { unregister } from './registerServiceWorker'
import setUpStore from 'smartcovr_api'
import ErrorScreen from 'components/errorScreens/ErrorScreen'
import { PersistGate, getPersistor } from 'smartcovr_api/src/store'
import { StoreContext } from 'components/context'
import App from './App'
import config from './config'

let store
const nodeEnv = process.env // eslint-disable-line no-undef, camelcase
const debug = makeDebug('index')

// registerServiceWorker()
unregister()

debug(`client starting. Built for ${nodeEnv} env.`)

const RootComponent = ({ store }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={getPersistor()}>
        <StoreContext.Provider value={store}>
          <App store={store} />
        </StoreContext.Provider>
      </PersistGate>
    </Provider>
  )
}

async function initialize () {
  const rootEl = document.getElementById('root')
  const root = createRoot(rootEl)
  try {
    store = await setUpStore(['insurance'])
    root.render(<RootComponent store={store} />)
  } catch (err) {
    root.render(<ErrorScreen fullScreen {...config.networkError} onClick={() => window.location.reload()} />)
  }
}
initialize()
  .catch(e => {
    console.log(e.message)
  })
const getStore = () => {
  return store
}
export default getStore
