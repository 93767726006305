import { ServerApiError } from '../errors'
import { getFileNameWithExtension, downloadFileFromBlob } from '../commonUtils'
import { isNotEmpty } from 'utils'
import { getService } from './index'

const downloadReportFormBlob = (serviceUrl, filterValues, distributorId, insuranceType) => {
  const fileNameWithExtension = getFileNameWithExtension(filterValues.fileName, filterValues.fileType)
  delete filterValues.fileName
  downloadFileFromBlob(serviceUrl, filterValues, fileNameWithExtension, distributorId, insuranceType)
}

const getTotalLeadsReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { totalLeadsReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(totalLeadsReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await totalLeadsReportService.create(filterValues, { query: { insuranceType } })
      // const response = await totalLeadsReportService.create(filterValues)
      // const response = mockData.totalLeads[insuranceType]
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getTotalLeadsReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getFormSubmissionPendingReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { formSubmissionPendingReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(formSubmissionPendingReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await formSubmissionPendingReportService.create(filterValues, { query: { insuranceType } })
      // const response = await formSubmissionPendingReportService.create(filterValues)
      // const response = mockData.formSubmissionPending
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getFormSubmissionPendingReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getSubmittedApplicationReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { submittedApplicationReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(submittedApplicationReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await submittedApplicationReportService.create(filterValues, { query: { insuranceType } })
      // const response = await submittedApplicationReportService.create(filterValues)
      // const response = mockData.formSubmissionPending
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getSubmittedApplicationReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getPendingApplicationReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { pendingApplicationReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(pendingApplicationReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await pendingApplicationReportService.create(filterValues, { query: { insuranceType } })
      // const response = await pendingApplicationReportService.create(filterValues)
      // const response = mockData.pendingApplications
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getPendingApplicationReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getSmsDeatilsReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { smsDetailsReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(smsDetailsReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await smsDetailsReportService.create(filterValues, { query: { insuranceType } })
      // const response = await smsDetailsReportService.create(filterValues)
      // const response = mockData.mailingDatails
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getSmsDeatilsReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getEmailDeatilsReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { emailDetailsReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(emailDetailsReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await emailDetailsReportService.create(filterValues, { query: { insuranceType } })
      // const response = await emailDetailsReportService.create(filterValues)
      // const response = mockData.mailingDatails[insuranceType]
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getEmailDeatilsReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getDropOffReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { dropOffReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(dropOffReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await dropOffReportService.create(filterValues, { query: { insuranceType } })
      // const response = await dropOffReportService.create(filterValues)
      // const response = mockData.dropOffStage[insuranceType]
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getDropOffReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const getConversionReport = async (filterValues, distributorId, insuranceType) => {
  let response
  try {
    const { conversionReportService } = getService(insuranceType)
    if (isNotEmpty(filterValues.fileType)) {
      downloadReportFormBlob(conversionReportService.base, filterValues, distributorId, insuranceType)
    } else {
      response = await conversionReportService.create(filterValues, { query: { insuranceType } })
      // const response = await conversionReportService.create(filterValues)
      // const response = mockData.converionReport[insuranceType]
      return response
    }
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetReportsForDistributorError('smartCovrApi:reportService:getConversionReport', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
export {
  getTotalLeadsReport,
  getFormSubmissionPendingReport,
  getSubmittedApplicationReport,
  getPendingApplicationReport,
  getSmsDeatilsReport,
  getEmailDeatilsReport,
  getDropOffReport,
  getConversionReport
}
