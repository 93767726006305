import { isProposerSelfCheck, buildFormSteps, transformFormData, runRulesAndUpdateSchema } from './helpers/bookingHelper'
import { getProductConfiguration, getBookingConfiguration, getProposerRelations, fetchBookingConfiguration } from '../../services/insuranceService'
import { loadEnquiry, updateEnquiryToDb } from '../../services/commonService'

import { isNotDefined, isNotEmpty } from 'utils'
import { checkForBadRequest } from '../../errors'
import { reduceRootStateDraft, reduceRootStateUpdated, checkAndFinishDraft } from './modelHelpers'

/**
   * This function is used to get the booking configuration. The variable "beforeBooking" is used switch
   * between two apis. If beforeBooking is true then bookingCreate api is called and if it's false then
   * booking get is called. beforeBooking is true when this function is called before booking screen
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const loadBookingConfiguration = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'distributorId', 'institutionId', 'productInsurerId'], payload)
  const {
    insuranceType,
    distributorId,
    institutionId,
    productInsurerId,
    resetSteps,
    bookingType,
    beforeBooking,
    device
  } = payload
  const { insuranceEnquiryDraft, insuranceConfigureDraft, insuranceProductsDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  if (isNotDefined(insuranceConfigureDraft.booking)) {
    insuranceConfigureDraft.booking = {}
  }
  if (isNotDefined(insuranceConfigureDraft.booking[institutionId])) {
    insuranceConfigureDraft.booking[institutionId] = {}
  }
  let applicationForm
  let dataMismatch
  let bookingUiConfig = insuranceConfigureDraft.uiConfig.booking
  let { bookingConfig } = insuranceConfigureDraft.booking[institutionId]
  if (isNotDefined(bookingConfig) || !isNotEmpty(bookingConfig)) {
    try {
      const newEnquiry = insuranceEnquiryDraft
      newEnquiry.enquiryId = draftRootState.insuranceEnquiry.enquiryId
      let bookingConfiguration
      if (beforeBooking) {
        bookingConfiguration = await getBookingConfiguration({
          insuranceType,
          distributorId,
          institutionId,
          insurerId: productInsurerId,
          enquiry: newEnquiry
        })
      } else {
        const applicationNumber = draftRootState.insuranceEnquiry[insuranceType].application[institutionId].applicationFormData.selectedProduct.applicationNumber
        bookingConfiguration = await fetchBookingConfiguration({
          insuranceType,
          distributorId,
          institutionId,
          insurerId: productInsurerId,
          applicationNumber,
          enquiry: newEnquiry
        })
      }
      dataMismatch = bookingConfiguration.dataMismatch
      if (!isNotDefined(bookingConfiguration.bookingConfig.uiConfig)) {
        bookingUiConfig = bookingConfiguration.bookingConfig.uiConfig.booking
      }
      if (!isNotEmpty(dataMismatch)) {
        bookingConfig = bookingConfiguration.bookingConfig
        if (bookingType !== 'postPaymentSteps') {
          applicationForm = bookingConfiguration.applicationForm
        } else {
          applicationForm = (insuranceEnquiryDraft.application[institutionId] && insuranceEnquiryDraft.application[institutionId].applicationFormData) || {}
        }
        const updatedBookingSchema = await runRulesAndUpdateSchema(bookingConfig.formSchema, applicationForm)
        bookingConfig.formSchema.schema = updatedBookingSchema.schema
        bookingConfig.formSchema.uiSchema = updatedBookingSchema.uiSchema
        applicationForm = updatedBookingSchema.formData
      }
    } catch (err) {
      throw err
    }
  }

  let completedStepIndex = -1
  let allSteps
  // This code block changes the bookingStepIndex to the next step of last completedSteps. So that
  // user can be redirected when "continue button" is clicked in customer holdings
  if (!beforeBooking) {
    const completedSteps = insuranceEnquiryDraft.application[institutionId].applicationFormData.completedSteps
    // const completedSteps = ['insuredDetails']
    if (completedSteps && completedSteps.length > 0) {
      const rootState = {
        insuranceConfigure: {},
        insuranceProducts: {}
      }
      rootState.insuranceConfigure[insuranceType] = {
        ...insuranceConfigureDraft
      }
      rootState.insuranceProducts[insuranceType] = {
        ...insuranceProductsDraft
      }
      const isProposerSelf = isProposerSelfCheck(rootState, insuranceType, institutionId)

      const formData = insuranceEnquiryDraft.application[institutionId].applicationFormData
      if (!isNotEmpty(bookingConfig)) {
        return []
      }
      const { formSchema } = bookingConfig
      allSteps = buildFormSteps(bookingUiConfig, formSchema, institutionId, device, formData, bookingType, isProposerSelf)

      completedStepIndex = allSteps.findIndex(ele => ele.displayKey === completedSteps[completedSteps.length - 1])
      if (['SC003', 'SC097'].includes(insuranceEnquiryDraft.application[institutionId].applicationFormData.applicationStatusData.smartcovrStatusCode)) {
        completedStepIndex = completedStepIndex - 1
      }
      insuranceEnquiryDraft.application[institutionId].bookingStepIndex = completedStepIndex + 1
      insuranceEnquiryDraft.application[institutionId].currentStep = 0
    }
  }
  // transformFormData practically modifies the enquiryDraft. Returned value might not be used
  // insuranceEnquiryDraft modified inline
  transformFormData(insuranceEnquiryDraft, {
    institutionId,
    resetSteps,
    applicationForm
  })
  // FIXME: Is there a need to write application to db for any specific reason
  // updateEnquiryToDb(newEnquiry, insuranceType, insuranceEnquiry.enquiryId)
  if (completedStepIndex !== -1) {
    insuranceEnquiryDraft.application[institutionId].bookingStepIndex = completedStepIndex + 1
  }
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draftRootState ends here
  const { insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  try {
    await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  } catch (err) {
    throw err // suitable error thrown from the called function
  }

  dispatch.insuranceEnquiry.updateEnquiry({ insuranceType, insuranceEnquiry: insuranceEnquiryUpdated })
  dispatch.insuranceConfigure.updateBookingConfiguration({
    insuranceType,
    institutionId,
    productInsurerId,
    application: insuranceEnquiryUpdated.application,
    chosenProduct: insuranceEnquiryUpdated.chosenProduct,
    bookingConfig,
    dataMismatch
  })
  // ---------Calling booking API to update the completedSteps at the start of post payment steps
  const { currentStep, bookingStepIndex } = insuranceEnquiryUpdated.application[institutionId]
  if (currentStep === 0 && bookingStepIndex === 0 && bookingType === 'postPaymentSteps') {
    dispatch.insuranceEnquiry.setApplicationFormData({
      applicationFormData: insuranceEnquiryUpdated.application[institutionId].applicationFormData,
      insuranceType,
      currentStep: 0,
      institutionId,
      bookingStepIndex: 0,
      stepDetails: allSteps[0],
      device,
      bookingType
    })
  }
  // ----------------
}

/**
   * This function gets the product configuration and enquiry and passes it in the updateConfigData reducer
   * Then effect loadChosenProducts of insuranceProducts model is call to fetch the quotes.
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setUiConfig = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'enquiryId', 'uiConfig'], payload)
  // reset - when set to true, all products are loaded irrespetive of the fact that chosenProduct is there in enquiry
  const { insuranceType, enquiryId, uiConfig, reset, smartCovrConfig } = payload
  const { distributorId } = draftRootState.configure
  if (isNotDefined(distributorId)) {
    throw new Error('distributor ID not set')
  }
  // Gets productConfig as well as insurer config
  let config, insuranceEnquiry
  try {
    config = await getProductConfiguration({ distributorId, insuranceType })
    insuranceEnquiry = await loadEnquiry(enquiryId, { distributorId, productType: insuranceType })
  } catch (err) {
    throw err
  }
  const { productConfig, issuerProductConfig } = config
  // Dispatch an action that is a reducer
  await dispatch.insuranceConfigure.updateConfigData({ uiConfig, smartCovrConfig, productConfig, issuerProductConfig, insuranceType, insuranceEnquiry, enquiryId })
  // now that we have productConfig, we can also load insurance products rightaway
  // dispatch an action that is an effect

  // after getting enquiry, check if enquiry has chosenProduct. if it does, we load productData only for that product else we load productData for all
  const chosenProductMetaData = insuranceEnquiry.chosenProduct
  if (!reset && isNotEmpty(chosenProductMetaData)) {
    const { productInsurerId } = chosenProductMetaData
    dispatch.insuranceProducts.loadChosenProducts({ insuranceType, productInsurerId })
  } else {
    dispatch.insuranceProducts.loadProducts({ insuranceType })
  }
  dispatch.insuranceProducts.loadFeatures({ insuranceType })
}

/**
   * This function gets the proposal relations and passes into the reducer
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const loadProposerRelations = async (dispatch, payload, draftRootState) => {
  try {
    checkForBadRequest(['insuranceType', 'institutionId', 'productInsurerId'], payload)
    const { insuranceType, institutionId, productInsurerId } = payload
    const { insuranceConfigureDraft } = reduceRootStateDraft(draftRootState, insuranceType)
    let proposerRelations = {}
    if (isNotDefined(insuranceConfigureDraft.proposerRelations) || isNotDefined(insuranceConfigureDraft.proposerRelations[institutionId]) || isNotDefined(insuranceConfigureDraft.proposerRelations[institutionId][productInsurerId])) {
      proposerRelations = await getProposerRelations({ insuranceType, institutionId, productInsurerId })
    }
    dispatch.insuranceConfigure.updateProposerRelations({ insuranceType, institutionId, proposerRelations, productInsurerId })
  } catch (err) {
    throw err
  }
}
export {
  setUiConfig,
  loadBookingConfiguration,
  loadProposerRelations
}
