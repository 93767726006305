
import { PreviousPremiumsForPolicy } from './PreviousPremiumsForPolicy'
import { UpcomingPremiumsForPolicy } from './UpcomingPremiumsForPolicy'

export const PolicyUpdates = (props) => {
  const { policy } = props
  return (
    <div className='policyUpdatesTabContent'>
      <UpcomingPremiumsForPolicy
        upcomingPremiums={policy.upcomingPremiums}
      />
      <PreviousPremiumsForPolicy
        previousPremiums={policy.previousPremiums}
      />
    </div>
  )
}
