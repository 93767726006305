import { useState } from 'react'
import { Subtitle2 } from 'components/Header'

import { PolicyUpdates } from './PolicyUpdates'
import { PolicyDetails } from './PolicyDetails'
import HeadAndBody from '../HeadAndBody'

const PolicyDetailsMainPageTabs = (props) => {
  const { policy, device } = props
  const policyDetails = (
    <PolicyDetails
      policy={policy}
      device={device}
    />
  )

  const policyUpdates = (
    <PolicyUpdates
      policy={policy}
    />
  )
  const data = [
    {
      id: '1',
      tabTitle: 'Policy Details',
      tabContent: policyDetails
    },
    {
      id: '2',
      tabTitle: 'Policy Updates',
      tabContent: policyUpdates
    }
  ]

  const [visibleTab, setVisibleTab] = useState(data[0].id)
  const listTitles = data.map((item, index) =>
    <li key={index} onClick={() => setVisibleTab(item.id)} className={visibleTab === item.id ? 'tab-title tab-title--active' : 'tab-title'}><Subtitle2 className='title'>{item.tabTitle}</Subtitle2></li>
  )

  const listContent = data.map((item, index) =>
    <div key={index} style={visibleTab === item.id ? {} : { display: 'none' }}>{item.tabContent}</div>
  )
  return (
    <>
      {
        policy.insuranceType !== 'annuity'
          ? (
            <div className='tabs'>
              <ul className='tabs-titles'>
                {listTitles}
              </ul>
              <div className='tab-content'>
                {listContent}
              </div>
            </div>
            )
          : (
            <div className='tab-content'>
              <HeadAndBody head='Policy Details' />
              <div style={{ marginTop: 15 }}>
                {listContent}
              </div>
            </div>
            )
      }
    </>
  )
}

export default PolicyDetailsMainPageTabs
