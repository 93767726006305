import { effectsHandler } from './insurance/modelHelpers'
import { loadPolicies, loadUpcomingPremiums, loadPaidPremiums, loadPolicyDetails } from './policiesEffects'
import { isNotEmpty } from 'utils'

const insurancePolicies = {
  name: 'policies',
  state: {
    term: {
      policies: [
        // {
        //   policyName: 'test',
        //   policyNumber: '',
        //   coverAmount: '',
        //   productId: '',
        //   policyTerm: '',
        //   policyDesscription: '',
        //   policyStatus: '',
        //   policyDateIssued: '',
        //   riders: [
        //     {
        //       categoryId: ''
        //     }
        //   ],
        //   lastPremiumDate: '',
        //   upcomingPremiumDate: '',
        //   illustrationLink: '',
        //   totalPremiums: '',
        //   totalPremiumsPaid: '',
        //   benefits: []
        // }
      ],
      allUpcomingPremiums: [
        // {
        //   productName: '',
        //   productOptionName: '',
        //   dueDate: '',
        //   numberOfDaysRemaining: '',
        //   policyNumber: '',
        //   premiumAmount: ''
        // }
      ],
      paidPremiums: [
        // {
        //   policyNumber: '',
        //   date: '',
        //   policyName: '',
        //   status: '',
        //   premiumAmount: ''
        // }
      ],
      selectedApplicationNumberForPolicy: ''
    },
    car: {
      policies: [],
      allUpcomingPremiums: [],
      paidPremiums: [],
      selectedApplicationNumberForPolicy: ''
    },
    bike: {
      policies: [],
      allUpcomingPremiums: [],
      paidPremiums: [],
      selectedApplicationNumberForPolicy: ''
    },
    health: {
      policies: [],
      allUpcomingPremiums: [],
      paidPremiums: [],
      selectedApplicationNumberForPolicy: ''
    },
    annuity: {
      policies: [],
      allUpcomingPremiums: [],
      paidPremiums: [],
      selectedApplicationNumberForPolicy: ''
    }
  },
  selectors: (slice, createSelector) => ({

    /**
     * Return the value of selectedApplicationNumberForPolicy from the state
     *
     * @returns string
     */
    getSelectedPolicy () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (policies, insuranceType) => policies[insuranceType].selectedApplicationNumberForPolicy
      )
    },

    /**
     * Counts the number of policies which are enrolled and calculates the total sumassured of
     * these policies
     *
     * @returns object
     */
    getSumAssuredAndCountOfPolicies () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => {
          return rootState.insuranceConfigure[insuranceType]
        },
        (rootState, { insuranceType }) => insuranceType,
        (policies, insuranceConfigure, insuranceType) => {
          let count = 0
          let totalCover = 0
          if (isNotEmpty(insuranceConfigure) && isNotEmpty(policies[insuranceType].policies)) {
            const { enrolledPolicyStatuses } = insuranceConfigure.uiConfig.customerHoldings
            policies[insuranceType].policies.forEach(pol => {
              if (enrolledPolicyStatuses.includes(pol.application.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode)) {
                count++
                totalCover += ['health', 'term'].includes(insuranceType) ? pol.sumAssured : pol.idv
              }
            })
          }
          return { totalNumberOfPolicies: count, totalCover }
        }
      )
    },

    /**
     * Gets all the policies
     *
     * @returns array
     */
    getPolicies () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (policies, insuranceType) => {
          return policies[insuranceType].policies
        }
      )
    },

    /**
     * Returns of Policies count
     *
     * @returns number
     */
    getPoliciesCount () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (policies, insuranceType) => {
          return policies[insuranceType].policiesCount
        }
      )
    },
    /**
     * Gets single policy by applicationNumber
     *
     * @returns object
     */
    getPolicyById () {
      return createSelector(
        slice,
        (rootState, { insuranceType, id }) => ({
          insuranceType,
          id
        }),
        (policies, { insuranceType, id }) => {
          return policies[insuranceType].policies.find(pol => pol.application.applicationNumber === id)
        }
      )
    },

    /**
     * Gets all the upcoming premiums
     *
     * @returns array
     */
    getAllUpcomingPremiums () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (policies, insuranceType) => {
          if (policies[insuranceType].allUpcomingPremiums.length === 0) {
            return []
          }
          return policies[insuranceType].allUpcomingPremiums.sort((a, b) => a.numberOfDaysRemaining - b.numberOfDaysRemaining).slice(0, 4)
        }
      )
    },

    /**
     * gets all paid premiums by policy number
     *
     * @returns array
     */
    getPaidPremiumsByPolicyNumber () {
      return createSelector(
        slice,
        (rootState, { insuranceType, policyNumber }) => ({
          insuranceType,
          policyNumber
        }),
        (policies, { insuranceType, policyNumber }) => {
          return policies[insuranceType].paidPremiums.filter(pol => pol.policyNumber === policyNumber).sort((a, b) => b.date - a.date)
        }
      )
    }
  }),
  reducers: {

    /**
     * Sets modified policies in the state
     *
     * @param {*} state
     * @param {*} { modifiedPolicies, insuranceType }
     * @returns object
     */
    storePoliciesData (state, { modifiedPolicies, count, insuranceType }) {
      state[insuranceType].policies = modifiedPolicies
      state[insuranceType].policiesCount = count
      return state
    },

    /**
     * Sets allUpcomingPremiums in the state
     *
     * @param {*} state
     * @param {*} { allUpcomingPremiums, insuranceType }
     * @returns object
     */
    storeAllUpcomongPremiumsData (state, { allUpcomingPremiums, insuranceType }) {
      state[insuranceType].allUpcomingPremiums = allUpcomingPremiums
      return state
    },

    /**
     * sets paidPremiums in the state
     *
     * @param {*} state
     * @param {*} { paidPremiums, insuranceType }
     * @returns object
     */
    storePaidPremiumsData (state, { paidPremiums, insuranceType }) {
      state[insuranceType].paidPremiums = paidPremiums
      return state
    },

    /**
     * sets selectedApplicationNumberForPolicy in the state
     *
     * @param {*} state
     * @param {*} { id, insuranceType }
     * @returns object
     */
    updateSelectedPolicy (state, { id, insuranceType }) {
      state[insuranceType].selectedApplicationNumberForPolicy = id
      return state
    }
  },
  effects: (dispatch) => ({
    loadPolicies: effectsHandler(dispatch, loadPolicies),
    loadPolicyDetails: effectsHandler(dispatch, loadPolicyDetails),
    loadUpcomingPremiums: effectsHandler(dispatch, loadUpcomingPremiums),
    loadPaidPremiums: effectsHandler(dispatch, loadPaidPremiums)
  })
}

export default insurancePolicies
