import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { AppBar, Drawer, Box, Toolbar, List, Divider, IconButton, ListItem, ListItemText } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import makeStyles from '@mui/styles/makeStyles'
import { Image, Menu } from 'semantic-ui-react'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { buildDistributorImagePath } from './buildImagePath'
import './MUIDrawer.scss'
import variable from 'assets/styles/variables.scss'

function MUIDrawer ({ onLogout, onItemChangedListener, selectedItem, device, labelExtractor, headerChildren, listMapper }) {
  const [open, setOpen] = React.useState(false)
  const theme = useTheme()
  const drawerWidth = 240
  const logBtnPadding = device === 'mobile' ? '0.05rem 0.4rem' : '0.25rem 1rem'
  const useStyle = makeStyles((themeObj) => ({
    root: {
      display: 'flex'
    },
    appBar: {
      backgroundColor: variable.primary
    },
    menuButton: {
      color: '#fff'
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0
    },
    drawerPaper: {
      width: drawerWidth
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: themeObj.spacing(0, 1),
      // necessary for content to be below app bar
      ...themeObj.mixins.toolbar,
      justifyContent: headerChildren ? 'space-between' : 'flex-end'
    },
    textSize: {
      fontSize: '0.3rem',
      backgroundColor: '#ffeaa7'
    },
    logoutBtn: {
      color: '#fff',
      padding: logBtnPadding,
      borderRadius: '10px',
      textTransform: 'upperCase',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'white',
        color: variable.primaryColor
      }
    },
    logo: {
      flexGrow: 1
    },
    logoimage: {
      maxWidth: '100%',
      display: 'block',
      height: 'auto'
    }
  }
  ))

  const classes = useStyle()
  const headerLogo = (
    <Menu.Item>
      <Image className={clsx(classes.logoimage)} src={buildDistributorImagePath('smartcovr-logo.png')} />
    </Menu.Item>
  )

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const buildlistItems = () => {
    return listMapper.map((item, index) => {
      const label = labelExtractor(item)
      const key = label.split(' ').join('_') + index
      return (
        <ListItem
          button
          key={key}
          selected={selectedItem === item}
          onClick={() => {
            onItemChangedListener(item)
            handleDrawerClose()
          }}
        >
          <ListItemText primary={label} />
        </ListItem>
      )
    })
  }

  return (
    <>
      <AppBar
        component='nav'
        position='sticky'
        className={clsx(classes.appBar)}
      >
        <Toolbar sx={{ gap: '1rem' }}>
          <IconButton
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className={clsx(classes.menuButton, open && classes.hide)}
            size='large'
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }}>
            {headerLogo}
          </Box>
          <span className={classes.logoutBtn} onClick={() => onLogout()}>Logout</span>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant='temporary'
        open={open}
        onClose={handleDrawerClose}
        classes={{ paper: classes.drawerPaper }}
        ModalProps={{
          keepMounted: true // Better open performance for mobile.
        }}
      >
        <div className={classes.drawerHeader}>
          {headerChildren ?? headerLogo}
          <IconButton onClick={handleDrawerClose} size='large'>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {buildlistItems()}
        </List>
      </Drawer>
    </>
  )
}

MUIDrawer.defaultProps = {
  listMapper: [],
  labelExtractor: (item) => item,
  headerChildren: null
}

MUIDrawer.propTypes = {
  onItemChangedListener: PropTypes.func.isRequired,
  selectedItem: PropTypes.any,
  device: PropTypes.string.isRequired,
  labelExtractor: PropTypes.func.isRequired,
  headerChildren: PropTypes.node,
  listMapper: PropTypes.array.isRequired,
  onLogout: PropTypes.func.isRequired
}
export default MUIDrawer
