
import { useState, useEffect } from 'react'
import { Modal, Button, Image } from 'semantic-ui-react'
import FetchDataLoader from 'components/FetchDataLoader'
import Loading from 'components/Loading'
import { Body2, H6 } from 'components/Header'
import { useInitialDataLoader } from 'components/customHooks'
import { isNotEmpty } from 'utils/lib/basic'
import { sendDropOffLink } from 'smartcovr_api/src/insuranceApi'

const style = {
  position: {
    width: '30%',
    minWidth: 300
  },
  modalContent: { textAlign: 'center' },
  image: {
    margin: 'auto'
  },
  body: {
    marginBottom: 40
  }
}
const ProcessPayment = (props) => {
  const [status, setStatus] = useState('')
  const {
    insuranceType,
    bookingConfigLoaded,
    enquiry: { chosenProduct, application },
    distributorId
  } = props
  const { loadingConfigure, configure } = useInitialDataLoader(insuranceType, props.enquiryId, props.distributorId)

  const updateStatus = async () => {
    if (bookingConfigLoaded) {
      await props.resetBookingIndices(props.chosenProductMetaData)
    }
    setStatus(props.msg.authStatus)
  }
  useEffect(() => {
    if (!loadingConfigure) {
      updateStatus()
    }
  },
  // eslint-disable-next-line
  [ loadingConfigure, bookingConfigLoaded ])

  useEffect(() => {
    const eventHandler = (ev) => {
      ev.preventDefault()
      if (isNotEmpty(chosenProduct)) {
        sendDropOffLink({
          bookingType: 'postPaymentSteps',
          applicationFormData: application[chosenProduct.institutionId].applicationFormData,
          distributorId
        })
      }

      return null
    }
    window.addEventListener('unload', eventHandler)
    return () => window.removeEventListener('unload', eventHandler)
  }, [chosenProduct, distributorId, application])

  const redirectToPath = (path, payment) => {
    const { redirectToPath } = props
    redirectToPath(
      path,
      undefined,
      payment
    )
  }
  if (loadingConfigure) {
    return <Loading open />
  }
  if (status) {
    const { title, color, subtitle, buttonText, redirectPath, imgUrl } = configure.processPayment.popupMessages[status]
    const payment = status === 'success'
    const redirectPathUrl = redirectPath.includes(insuranceType) ? redirectPath.replace(`/${insuranceType}/`, '') : redirectPath
    return (
      <Modal style={style.position} open>
        <Modal.Content style={style.modalContent}>
          <Image style={style.image} size='mini' src={imgUrl} />
          <H6 color={color}>
            {title}
          </H6>
          <Body2 style={style.body} color='grey'>
            {subtitle}
          </Body2>
          <Button primary onClick={() => redirectToPath(redirectPathUrl, payment)}> {buttonText} </Button>
        </Modal.Content>
      </Modal>
    )
  }
  return (
    <FetchDataLoader message='Redirecting to Post Payment...' />
  )
}
export default ProcessPayment
