import { useState, useContext } from 'react'
import { Form, Modal, Button } from 'semantic-ui-react'
import Calendar from 'react-calendar'
import produce from 'immer'
import { isNotDefined } from 'utils/lib/basic'
import { Subtitle2, Body2 } from 'components/Header'
import FormDropdown from 'components/FormDropdown'
import { UpdateContext } from 'components/context'
import { getResetValueForKey } from 'smartcovr_api/src/insuranceApi'
import { omit } from 'lodash'
import './PolicyTypeFilter.scss'

const styles = {
  contentStyle: {
    width: 250,
    fontSize: 14,
    opacity: 1,
    boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
  },
  position: {
    width: '30%',
    minWidth: '18rem'
  },
  image: {
    position: 'absolute',
    right: '10px',
    top: '10px',
    cursor: 'pointer',
    display: 'unset'
  }
}

const buildSelectOptions = (policyTypeSchema) => {
  return policyTypeSchema?.enum.map((prop, i) => {
    const enumNames = policyTypeSchema.enumNames
    return { key: `${prop + i}`, value: prop, text: enumNames[i] }
  })
}

function addYear (date, count) {
  return new Date(date.setFullYear(date.getFullYear() + count))
}

function addDays (days) {
  return new Date(new Date().setDate(new Date().getDate() + days))
}

function addDaysOnExistingData (date, days) {
  return new Date(date.setDate(date.getDate() + days))
}

const resetProductData = (productData, formData) => {
  const { insuranceType, policyOption, customerType, policyType } = formData
  const newProductData = { ...productData }
  if (!isNotDefined(productData)) {
    Object.keys(productData).forEach(key => {
      if (typeof productData[key] === 'object') {
        let keyData = getResetValueForKey(key, insuranceType)
        if (key === '126' && insuranceType === 'car') { // to make sure personal accident owner driver is defaulted to 3, when user changes policyType, since it is a mandatory cover
          keyData = { cpaYear: 3, include: true }
        } else if (key === '126' && insuranceType === 'bike') {
          keyData = { cpaYear: 5, include: true }
        }
        if (key === '126' && policyOption === 'renewExistingPolicy' && formData.businessOption === 'rollover') {
          keyData = { cpaYear: 1, include: true }
        }
        if (customerType === 'corporate' || policyType === 'standalone') {
          keyData = { ...keyData, include: false }
        }
        Object.assign(newProductData, { [key]: keyData })
      }
    })
  }
  return newProductData
}

const CalendarModal = ({ open, setOpen, formData, productData, setPolicyTypeValue, policyTypeValue, id }) => {
  const previousPolicyEndDate = formData?.previousPolicyEndDate
  const previousPolicyType = formData?.previousPolicyType
  const [date, setDate] = useState(initialize())
  const { setOnboardFormData } = useContext(UpdateContext)

  function initialize () {
    let value = new Date()
    const thirdPartyExist = !isNotDefined(previousPolicyType) && previousPolicyType === 'thirdParty'
    if (!isNotDefined(previousPolicyEndDate) && thirdPartyExist) {
      value = new Date(previousPolicyEndDate)
    } else if (formData.thirdPartyPolicyEndDate && !thirdPartyExist) {
      value = new Date(formData.thirdPartyPolicyEndDate)
    }
    return value
  }

  const dateRange = () => {
    let minDate = new Date()
    let maxYear = 3
    if (formData.insuranceType === 'bike') {
      maxYear = 5
    }
    let maxDate = addYear(new Date(), maxYear)
    maxDate = addDaysOnExistingData(maxDate, -1)
    if (!isNotDefined(previousPolicyEndDate) && previousPolicyType === 'thirdParty') {
      const { previousPolicyStatus } = formData
      if (previousPolicyStatus === 'expiredLessThan45Days') {
        minDate = addDays(-45)
        maxDate = addDays(0)
      } else if (previousPolicyStatus === 'expiredBetween45To90Days') {
        minDate = addDays(-90)
        maxDate = addDays(-45)
      } else if (previousPolicyStatus === 'expiredMoreThan90Days') {
        minDate = addDays(undefined)
        maxDate = addDays(-90)
      }
    }
    return { minDate, maxDate }
  }

  const handleSubmit = () => {
    const newProductData = resetProductData(productData, formData)
    const newFormData = produce(formData, draft => {
      draft.policyType = policyTypeValue
      draft.thirdPartyPolicyEndDate = date.toLocaleDateString('fr-CA').replace(/\//g, '-')
      if (!isNotDefined(draft.previousPolicyType) && draft.previousPolicyType === 'thirdParty') {
        draft.previousPolicyEndDate = date.toLocaleDateString('fr-CA').replace(/\//g, '-')
      }
    })
    setOnboardFormData({ formData: newFormData, productData: newProductData }, id)
    setOpen(false)
  }
  const handleClose = () => {
    if (formData.policyType !== policyTypeValue) {
      setPolicyTypeValue(formData.policyType)
    }
    setOpen(false)
  }
  return (
    <Modal className='modalContainer' open={open}>
      <Modal.Header>Third Party Policy End Date</Modal.Header>
      <Modal.Content>
        <Calendar
          id={`${id}-calendar`}
          className='mx-auto'
          value={date}
          minDate={dateRange().minDate}
          maxDate={dateRange().maxDate}
          onChange={(value) => setDate(value)}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button id='thirdParty-submit' primary onClick={handleSubmit}>Submit</Button>
        <Button id='thirdParty-close' basic onClick={handleClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  )
}

const InspectionMaodal = ({ inspectionModal, setInspectionModal }) => {
  return (
    <Modal style={styles.position} open={inspectionModal}>
      <Modal.Header>Note</Modal.Header>
      <Modal.Content style={styles.modalContent}>
        <Body2 color='grey'>
          Your policy has been upgraded, vehicle inspection is required.
        </Body2>
      </Modal.Content>
      <Modal.Actions>
        <Button secondary onClick={() => setInspectionModal(false)}>
          Continue
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

const PolicyTypeFilter = (props) => {
  const { formData, productData, className, disabled, id, onboardSchema, insuranceType } = props
  const policyTypeSchema = onboardSchema.properties?.policyType
  const { policyType } = formData
  const [open, setOpen] = useState(false)
  const [policyTypeValue, setPolicyTypeValue] = useState(policyType)
  const { setOnboardFormData } = useContext(UpdateContext)
  const [inspectionModal, setInspectionModal] = useState(true)

  const handleChange = (_e, { value }) => {
    if (policyType === value && value !== 'standalone') return
    const newProductData = resetProductData(productData, formData)
    setPolicyTypeValue(value)
    if (formData.policyOption === 'renewExistingPolicy' && ['thirdParty', 'standalone'].includes(formData.previousPolicyType) && value === 'comprehensive') {
      setInspectionModal(true)
    }
    if (value === 'standalone') {
      setOpen(true)
    } else {
      const newFormData = produce(omit(formData, 'thirdPartyPolicyEndDate'), draft => {
        draft.policyType = value
        if (value === 'thirdParty') {
          draft.idv = 0
        }
      })
      setOnboardFormData({ formData: newFormData, productData: newProductData }, id)
    }
  }

  const renderInspectionModal = () => {
    if (formData.policyOption === 'renewExistingPolicy' && ['thirdParty', 'standalone'].includes(formData.previousPolicyType) && policyTypeValue === 'comprehensive') {
      return (
        <InspectionMaodal {...{ inspectionModal, setInspectionModal }} />
      )
    }
  }
  return (
    <div>
      {insuranceType === 'car' && renderInspectionModal()}
      <Subtitle2 color='primaryLight'>Change Policy Type</Subtitle2>
      <Form.Field>
        <FormDropdown
          id={`${id}Dropdown`}
          className={className}
          icon='dropdown'
          placeholder={policyTypeValue}
          options={buildSelectOptions(policyTypeSchema)}
          value={policyTypeValue}
          disabled={disabled}
          fluid
          onChange={handleChange}
        />
      </Form.Field>
      {open && <CalendarModal open={open} id={id} setPolicyTypeValue={setPolicyTypeValue} policyTypeValue={policyTypeValue} productData={productData} formData={formData} setOpen={setOpen} />}
    </div>
  )
}

export default PolicyTypeFilter
