import { isNotDefined, isNotEmpty, round } from 'utils'
import dayjs from 'dayjs'
import ajv from 'smartcovr_ajv'

const LAKH = 100000
const CRORE = 10000000
const THOU = 1000

/**
   * This function changes the amount to display values
   *
   * @param {*} amountDisplay
   * @param {*} amtLabel
   * @returns
   */
export const scaleBackAmount = (amountDisplay, amtLabel) => {
  let amount
  if (isNotDefined(amtLabel)) {
    return amountDisplay
  }
  if (amtLabel === 'l') {
    amount = amountDisplay * LAKH
  } else if (amtLabel === 'c') {
    amount = amountDisplay * CRORE
  } else {
    amount = amountDisplay * THOU
  }
  return amount
}

/**
   * This function populates the default values of enquiry
   *
   * @param {*} enquiry
   * @returns
   */
export const populateDefaults = (enquiry) => {
  // populate this with default values to allow all calculations
  // How do we know eheter en
  const {
    productData: { incomePayout },
    formData: { sumAssured }
  } = enquiry
  if (!isNotDefined(sumAssured)) {
    if (!isNotDefined(incomePayout) && isNotEmpty(incomePayout)) {
      if (isNotDefined(incomePayout.income) || incomePayout.income === -1) {
        incomePayout.income =
          (incomePayout.percentOfSumAssured * sumAssured) / 100
        if (incomePayout.income > 100000) {
          incomePayout.income = round(incomePayout.income, -3)
        } else {
          incomePayout.income = round(incomePayout.income, -2)
        }
      }
      incomePayout.lumpsumAmount =
        (incomePayout.lumpsumPercentage * sumAssured) / 100
      incomePayout.remainingAmount =
        (incomePayout.remainingPercentage * sumAssured) / 100
    }
  }
  if (isNotDefined(enquiry.productData.ppt)) {
    if (enquiry.productData.paymentOption === 'regular') {
      enquiry.productData.ppt = enquiry.productData.coverTerm
    } else if (enquiry.productData.paymentOption === 'single') {
      enquiry.productData.ppt = 1
    }
  }
}

// This function is not used anywhere
const between = (factValue, jsonValue) => {
  return jsonValue <= factValue.max && jsonValue >= jsonValue.min
}

/**
   * this function adds mac and min dates in dateofBirth property
   *
   * @param {*} schema
   * @param {*} minAge
   * @param {*} maxAge
   * @returns
   */
export const populateMaxMinDates = (schema, minAge, maxAge) => {
  if (schema.id === 'annuityInsurance') {
    schema.properties.pDob.formatMaximum = dayjs()
      .subtract(minAge, 'year')
      .format('YYYY-MM-DD')
    schema.properties.pDob.formatMinimum = dayjs()
      .subtract(maxAge, 'year')
      .format('YYYY-MM-DD')
    schema.dependencies.investFor.oneOf[1].properties.sDob.formatMaximum = dayjs()
      .subtract(minAge, 'year')
      .format('YYYY-MM-DD')
    schema.dependencies.investFor.oneOf[1].properties.sDob.formatMinimum = dayjs()
      .subtract(maxAge, 'year')
      .format('YYYY-MM-DD')
  } else {
    schema.properties.dateOfBirth.formatMaximum = dayjs()
      .subtract(minAge, 'year')
      .format('YYYY-MM-DD')
    schema.properties.dateOfBirth.formatMinimum = dayjs()
      .subtract(maxAge, 'year')
      .format('YYYY-MM-DD')
  }
  return schema
}

/**
   * This function uses AJV and validates the formdata using schema
   *
   * @param {*} schema
   * @param {*} formData
   * @returns
   */
export const validateEnquiry = (schema, formData) => {
  // Allow formData to have additional properties
  const validate = ajv.compile(schema)
  const valid = validate(formData)
  return {
    valid,
    errors: validate.errors
  }
}
