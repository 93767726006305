
import { Input } from 'semantic-ui-react'
import NumberFormat from 'react-number-format'
import { rupeeFormatter } from 'utils'

const AmountWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    placeholder,
    schema
  } = props
  const typeOfField = schema.type
  const { minLength, maxLength } = schema

  // Remove labels to make it look just like Bootstrap.
  return (
    <NumberFormat
      id={id}
      placeholder={placeholder}
      thousandSeparator=','
      decimalSeparator='.'
      decimalScale={(typeOfField === 'number') ? 2 : 0}
      fixedDecimalScale={typeOfField === 'number'}
      value={value}
      onValueChange={({ value }) => props.onChange(value.length === 0 ? options.emptyValue : parseInt(value))}
      customInput={Input}
      format={rupeeFormatter}
      icon='rupee'
      iconPosition='left'
      disabled={disabled}
      readOnly={readonly}
      maxLength={maxLength}
      minLength={minLength}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
    />
  )
}

export default AmountWidget
