import { useEffect, useRef } from 'react'
import { HashRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Responsive from 'components/Responsive/Responsive'
import FetchDataLoader from 'components/FetchDataLoader'
import { getEnquiryId, getRedirectPath, getParamsStringFromUrl } from 'smartcovr_api/src/insuranceApi'
import { useAction, useGlobalState, useSelector, useTheme } from 'components/customHooks'
import Router from './Router'
import 'assets/styles/styles.scss'

const App = ({ store }) => {
  const isLoggedIn = useSelector((select, state) => select.customer.isCustomerLoggedIn(state, {}))
  const theme = useSelector((_select, state) => state.configure.theme)
  const initialize = useAction(dispatch => dispatch.customer.initialize)
  const loading = useGlobalState(state => state.loading.effects.customer.initialize)
  // test comment, remove me later
  const isLoaded = useRef(false)
  const { setTheme } = useTheme()
  useEffect(() => {
    if (!isLoaded.current) {
      initialize({ Modernizr: window.Modernizr })
      setTheme(theme)
      isLoaded.current = true
    }
  }, []) // eslint-disable-line
  if (loading || !isLoaded.current) {
    return (
      <FetchDataLoader message='Authenticating User ...' />
    )
  }
  const enquiryId = getEnquiryId()
  const queryParams = getParamsStringFromUrl()
  let route = '/'
  if (isLoggedIn) {
    // Check if there is a state and redirect URL to be handled. This will be set in the cookie 'redirect'
    // pick up the details from the cookie
    route = getRedirectPath() ?? '/'
  }

  const routeProps = {
    route,
    store,
    enquiryId,
    search: `?${queryParams}`
  }
  return (
    <Responsive>
      {({ device, screen }) => (
        <HashRouter>
          <Router {...routeProps} device={device} screen={screen} />
        </HashRouter>
      )}
    </Responsive>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired
}

export default App
