// import FormInput from 'components/FormInput'
// import moment from 'moment'
import { isNotEmpty } from 'utils'
import InputMask from 'react-input-mask'

// FIXME: following code to be uploaded to utils
// const convertToStandardDate = inputDate => {
//   // date converted from dd/mm/yyyy to yyyy-mm-dd
//   if (isNotEmpty(inputDate)) {
//     const tempArray = inputDate.split('/')
//     const dd = tempArray[0]
//     const mm = tempArray[1]
//     const yyyy = tempArray[2]
//     return `${isNotEmpty(yyyy) ? yyyy : ''}-${isNotEmpty(mm) ? mm : ''}-${
//       isNotEmpty(dd) ? dd : ''
//     }`
//   }
//   return ''
// }

// const convertToHumanizedDate = formattedDate => {
//   // date converted from yyyy-mm-dd to dd/mm/yyyy
//   if (isNotEmpty(formattedDate)) {
//     const tempArray = formattedDate.split('-')
//     const yyyy = tempArray[0]
//     const mm = tempArray[1]
//     const dd = tempArray[2]
//     return `${isNotEmpty(dd) ? dd : ''}${
//       (dd && dd.length === 2) || isNotEmpty(mm) ? '/' : ''
//     }${isNotEmpty(mm) ? mm : ''}${
//       (mm && mm.length === 2) || isNotEmpty(yyyy) ? '/' : ''
//     }${isNotEmpty(yyyy) ? yyyy : ''}`
//   }
//   return ''
// }

const convertToStandardDate = (inputDate) => {
  // date converted from dd/mm/yyyy to yyyy-mm-dd
  if (isNotEmpty(inputDate)) {
    const tempArray = inputDate.split('/')
    const dd = tempArray[0]
    const mm = tempArray[1]
    const yyyy = tempArray[2]
    return `${isNotEmpty(yyyy) ? yyyy : ''}-${isNotEmpty(mm) ? mm : ''}-${isNotEmpty(dd) ? dd : ''}`
  }
  return inputDate
}

const convertToHumanizedDate = (formattedDate) => {
  // date converted from yyyy-mm-dd to dd/mm/yyyy
  if (isNotEmpty(formattedDate)) {
    const tempArray = formattedDate.split('-')
    const yyyy = tempArray[0]
    const mm = tempArray[1]
    const dd = tempArray[2]
    return `${isNotEmpty(dd) ? dd : ''}${((dd && dd.length === 2) || isNotEmpty(mm)) ? '/' : ''}${isNotEmpty(mm) ? mm : ''}${((mm && mm.length === 2) || isNotEmpty(yyyy)) ? '/' : ''}${isNotEmpty(yyyy) ? yyyy : ''}`
  }
  return formattedDate
}
const DateWidget = props => {
  const {
    id,
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    ...inputProps
  } = props
  // Remove labels
  inputProps.label = null
  const date = new Date()
  if (!options.isMinor) {
    date.setFullYear(date.getFullYear() - 18)
  }
  // const maxDate = moment(date).format('YYYY-MM-DD')
  return (
    <InputMask
      mask='99/99/9999'
      value={convertToHumanizedDate(value) ?? ''}
      placeholder='DD/MM/YYYY'
      id={!id ? 'dateWidget' : id}
      disabled={disabled}
      readOnly={readonly}
      focus={autofocus}
      onChange={event => props.onChange(convertToStandardDate(event.target.value))}
      onBlur={onBlur && (event => onBlur(id, convertToStandardDate(event.target.value)))}
      onFocus={onFocus && (event => onFocus(id, convertToStandardDate(event.target.value)))}
      options={options}
    />
  )
}
export default DateWidget
