import { getDistributorProducts } from '../services/insuranceService'
const configure = {
  name: 'configure',
  state: {},
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Gets distributorId from the state
     *
     * @returns
     */
    getDistributorId () {
      return slice(configure => configure.distributorId)
    },

    getAvailableProducts () {
      return slice(configure => configure.availableProducts)
    },

    getCountriesMaster () {
      return slice(configure => configure.countries)
    },

    getOwnCustomerLogin () {
      return slice(configure => configure.ownCustomerLogin)
    },

    /**
     * Gets uploadUrl from the state
     *
     * @returns
     */
    getUploadUrl () {
      return slice((configure) => configure.uploadUrl)
    },

    /**
     * Gets fileUrl from the state
     *
     * @returns
     */
    getFileUrl () {
      return slice((configure) => configure.fileUrl)
    },

    /**
     * Gets schemaDisplay from the state
     *
     * @returns
     */
    getSchemaDisplay () {
      return slice(configure => configure.schemaDisplay)
    },

    /**
     * Gets filterDisplay from the state
     *
     * @returns
     */
    getFilterDisplay () {
      return slice(configure => configure.filterDisplay)
    },

    /**
     * Gets scCookie from the state
     *
     * @returns
     */
    getCookieConfig () {
      return slice(configure => configure.scCookie)
    },

    /**
     * Gets auth from the state
     *
     * @returns
     */
    getAuthConfig () {
      return slice(configure => configure.auth)
    }
  }),
  reducers: {

    /**
     * Sets authConfig in the state
     *
     * @param {*} state
     * @param {*} { authConfig }
     * @returns object
     */
    setAuthConfig (state, { authConfig }) {
      state.auth = authConfig
      return state
    },

    setDistributorProducts (state, { availableProducts }) {
      state.availableProducts = availableProducts
      return state
    }
  },
  effects: (dispatch) => ({

    /**
     * Calls the setAuthConfig reducer with authConfig
     *
     * @param {*} payload
     * @param {*} rootState
     */
    async fetchAuthConfiguration (payload, rootState) {
      let authConfig
      dispatch.customer.setAuthConfig({ authConfig })
    },

    // No longer Used.
    // Since availableProducts was required on many pages,so made a direct axios call to api inside setupStore file.
    async getDistributorProducts (payload, rootState) {
      const { insuranceType } = payload
      const distributorId = rootState.configure.distributorId
      const distributorConfig = await getDistributorProducts({ insuranceType, distributorId })
      dispatch.configure.setDistributorProducts({ availableProducts: distributorConfig })
    }
  })
}
export default configure
