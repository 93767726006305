import { useRef, useEffect } from 'react'
import { Grid, Segment, Button } from 'semantic-ui-react'
import { Subtitle1, H6, Caption, Subtitle2, Body2 } from 'components/Header'
import { rupeeFormatter, isNotDefined } from 'utils'
import dayjs from 'dayjs'

import '../dashBoard.scss'
import 'components/css/loader.scss'

const PremiumColumn = (props) => {
  const { dueDate, productName, status, productOptionName, amount, color, premiumPaymentUrl } = props
  return (
    <Grid.Column>
      <div className='flexContainer'>
        <div className={`${color}-beforeDot`} />
        <div className='premiumsDataContent'>
          <Caption color='dullGrey' content={dueDate} />
          <Subtitle1 className='subtitleStyle' color='black' content={productName} />
          <Body2>{productOptionName}</Body2>
          <Subtitle2
            className={`${color}-premiumStatusMessage`}
            content={status}
          />
        </div>
        <div className='payButton'>
          <Button
            secondary
            className='uiBtn'
            content={<span className='amount'>{`PAY ₹${amount}`}</span>}
            onClick={() => window.open(premiumPaymentUrl)}
          />
        </div>
      </div>
    </Grid.Column>
  )
}
const AllUpcomingPremiums = (props) => {
  const { loadingPolicies, allUpcomingPremiums, selectedApplication, selectedPolicyData, screen } = props
  const previousLoading = useRef(false)

  useEffect(() => {
    previousLoading.current = loadingPolicies
  }, [loadingPolicies])

  let loading
  let content

  if (!(previousLoading.current && !loadingPolicies)) {
    loading = (
      <div className='loaderOuter applicationLoader' id='loading'>
        <div className='ball-spin-fade-loader'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  } else if (allUpcomingPremiums.length === 0) {
    content = (
      <div className='noUpcomingPremium'>
        <Subtitle2 color='secondary'>No Upcoming Premium</Subtitle2>
      </div>
    )
  } else {
    content = (
      <Grid columns={['tablet', 'mobile'].includes(screen) ? 1 : 2}>
        <Grid.Row>
          {
              allUpcomingPremiums.map((element, index) => {
                const { dueDate, productName, premiumAmount, productOptionName, numberOfDaysRemaining, color, premiumPaymentUrl } = element
                return (
                  <PremiumColumn
                    key={index}
                    dueDate={dayjs(dueDate).format('ll')}
                    productName={productName}
                    status={`Premium due in ${numberOfDaysRemaining} days`}
                    amount={rupeeFormatter(Math.round(premiumAmount))}
                    color={color}
                    productOptionName={productOptionName}
                    premiumPaymentUrl={premiumPaymentUrl}
                  />
                )
              })
            }
        </Grid.Row>
      </Grid>
    )
  }
  if ((!isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)) || (isNotDefined(selectedApplication) && !isNotDefined(selectedPolicyData))) {
    return null
  } else if (isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)) {
    return (
      <Segment className={(!(previousLoading.current && !loadingPolicies)) ? 'allUpcomingPremiums loaderParent' : 'allUpcomingPremiums'}>
        <H6 color='primaryLight' className='h6' content='Upcoming Premiums' />
        {loading}
        {content}
      </Segment>
    )
  }
}
export default AllUpcomingPremiums
