import { useEffect } from 'react'
import Loading from 'components/Loading'
import { tptloginredirect, tptcustomerloginredirect } from 'smartcovr_api/src/insuranceApi'
import { useNavigate } from 'react-router-dom'

const TptRedirectHandler = (props) => {
  const { enquiryId, distributorId, insuranceType, ownCustomerLogin } = props
  const navigate = useNavigate()
  const redirectToPathFn = (path, state) => {
    navigate(path, {
      replace: true,
      state
    })
  }
  useEffect(() => {
    if (!ownCustomerLogin) {
      tptcustomerloginredirect(enquiryId, distributorId, insuranceType)
    } else {
      tptloginredirect(redirectToPathFn)
    }
  },
  []) // eslint-disable-line
  return <Loading open />
}

TptRedirectHandler.path = 'tpt-handler'
export default TptRedirectHandler
