import { H6 } from 'components/Header'
export const StepHeader = (props) => {
  const { id, stepHeader, content } = props
  return (
    <H6
      color='purple'
      id={id}
      style={stepHeader}
      content={content}
      textAlign='center'
    />
  )
}
export default StepHeader
