import { useEffect } from 'react'
import Loading from 'components/Loading'
import { keycloakTptloginredirect } from 'smartcovr_api/src/models/insurance/plainapi/clientApi'
import { connect } from 'react-redux'

const KeycloakTptRedirectHandler = (props) => {
  useEffect(() => {
    const { insuranceType, distributorId, enquiryId } = props
    keycloakTptloginredirect({ enquiryId, distributorId, insuranceType, redirectToPath: 'dashboard' })
  },
  // eslint-disable-next-line
  [])
  return (<>
    <Loading open />
  </>)
}

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    distributorId: models.configure.getDistributorId
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...selectorData,
    routeProps: ownProps,
    insuranceType
  }
}
const KeycloakTptRedirectContainer = connect(mapStateToProps)(KeycloakTptRedirectHandler)

KeycloakTptRedirectContainer.path = 'keycloak-tpt-handler'
export default KeycloakTptRedirectContainer
