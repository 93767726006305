import { H6, Header } from 'components/Header'
import { Textfit } from 'react-textfit'

const Insurer = (props) => {
  const { product, id } = props
  const { productName: { shortName, productOptionName } } = product
  return (
    <H6 color='primaryLight'>
      {shortName}
      <Header.Subheader id={`${id}-productOptionName`} className='secondary'>
        <Textfit
          max={18}
          mode='single'
          forceSingleModeWidth={false}
        >
          {productOptionName}
        </Textfit>
      </Header.Subheader>
    </H6>
  )
}

export default Insurer
