import { Statistic, Image } from 'semantic-ui-react'
import { buildCommonImagePath } from 'components/buildImagePath'
import Tooltip from 'rc-tooltip'
import DisplayDescription from 'components/DisplayDescription'
import 'rc-tooltip/assets/bootstrap_white.css'
const overlayStyle = {
  width: 250,
  fontSize: 14,
  opacity: 1,
  boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)',
  position: 'fixed'
}
const ClaimsSettled = (props) => {
  const { claimsSettled, id, claimsSource, claimsLastUpdated } = props
  return (
    <Statistic color='green' className='alignLeft'>
      <Statistic.Label>Claims Settled</Statistic.Label>
      <Statistic.Value id={`${id}_claimSettled`} className='claimSettled'>
        {`${claimsSettled}% `}
        <Tooltip
          style={{ filer: 'none' }}
          placement='right'
          overlay={
            <div>
              {/* <div><strong>{'Data as of'}</strong></div> */}
              <DisplayDescription
                description={`Source : ${claimsSource}| Last Updated on : ${claimsLastUpdated || 'N/A'}`}
              />
            </div>
          }
          overlayStyle={overlayStyle}
          arrowContent={<div className='rc-tooltip-arrow-inner' />}
        >
          <Image className='size12' src={buildCommonImagePath('more-info-icon.png')} />
        </Tooltip>
      </Statistic.Value>
    </Statistic>
  )
}

export default ClaimsSettled
