import { List, Image } from 'semantic-ui-react'
import { Caption } from 'components/Header'
import { buildCommonImagePath } from 'components/buildImagePath'
import DisplayDescription from 'components/DisplayDescription'
import TooltipComponent from './TooltipComponent'

const CheckedListItem = (props) => {
  const { heading, description, points, id, index, device, style } = props
  return (
    <List.Item
      className='benefitsListItem'
      key={index}
      id={id}
      style={style}
    >
      <Image style={{ width: 14 }} src={buildCommonImagePath('check-grey-icon.png')} />
      <Caption className='riderCaption'>
        {heading.trim()}
      </Caption>
      {device !== 'mobile' && (
        <TooltipComponent disable={device === 'mobile'}>
          <strong>{heading}</strong>
          <DisplayDescription
            description={description}
            points={points}
          />
        </TooltipComponent>
      )}
    </List.Item>
  )
}
export default CheckedListItem
