import { H4 } from 'components/Header'

import AllUpcomingPremiumsContainer from './allUpcomingPremiums/AllUpcomingPremiumsContainer'
import EnrolledMessageContainer from './enrolledMessage/EnrolledMessageContainer'
import UserPoliciesContainer from './userPolicies/UserPoliciesContainer'
import UserApplicationsContainer from './userApplications/UserApplicationsContainer'
import DashboardBackButtonContainer from './dashboardBackButton/DashboardBackButtonContainer'
import BrowseMoreProductsButtonContainer from './browseMoreProducts/BrowseMoreProductsButtonContainer'

import './dashBoard.scss'
import { capitalize, isNotEmpty } from 'utils/lib/basic'

const CustomerHoldingDashboard = props => {
  const {
    title,
    customerDetails,
    setContinueForm,
    insuranceType,
    store,
    location,
    redirectToPath,
    screen,
    device
  } = props

  const loadComponent = () => {
    return (
      <>
        <UserApplicationsContainer
          {...{ store, insuranceType, redirectToPath, device, screen }}
          setContinueForm={setContinueForm}
        />
        <UserPoliciesContainer {...{ store, insuranceType, device }} />
        <AllUpcomingPremiumsContainer
          {...{ store, insuranceType, screen, device }}
        />
        <BrowseMoreProductsButtonContainer
          {...{ store, insuranceType, redirectToPath, location }}
        />
      </>
    )
  }
  const modifiedCustomerDetails = Object.assign({}, customerDetails)
  if (isNotEmpty(modifiedCustomerDetails)) {
    modifiedCustomerDetails.firstName = capitalize(modifiedCustomerDetails?.firstName)
  }
  return (
    <>
      <div>
        <div className='customerScreenContainer'>
          {device === 'mobile' && (
            <div className='mobileBackButtonContainerDiv'>
              <DashboardBackButtonContainer
                {...{ store, insuranceType, device, className: 'mobileDashBoardButton' }}
              />
            </div>
          )}
          <div className={device === 'mobile' ? 'mobileTitleAndBtn' : 'titleAndBtn'}>
            <H4 color='white' className='text'>{title.format(modifiedCustomerDetails)}</H4>
            {device === 'web' && (
              <DashboardBackButtonContainer
                {...{ store, insuranceType }}
              />
            )}
          </div>
          <div className={device === 'mobile' ? 'mobilesubtitleText' : 'subtitleText'}>
            <EnrolledMessageContainer
              {...{ store, insuranceType }}
            />
          </div>
        </div>
        <div className={device === 'mobile' ? 'mobileContentHolder' : 'contentHolder'}>
          {loadComponent()}
        </div>
      </div>
    </>
  )
}

export default CustomerHoldingDashboard
