
import { PureComponent } from 'react'
import { Modal, Button } from 'semantic-ui-react'
import { Body2, H6 } from './Header'

const style = {
  heading: {
    margin: '0 0 10px'
  },
  position: {
    width: '30%',
    minWidth: 300
  },
  modalContent: { textAlign: 'center' },
  image: {
    margin: 'auto'
  },
  body: {
    marginBottom: 16
  }
}
class Login extends PureComponent {
  render () {
    const { configType, redirectionHandlerName } = this.props
    return (
      <Modal style={style.position} open>
        <Modal.Content style={style.modalContent}>
          <H6 color='primaryLight'>
            Authentication Required !
          </H6>
          <Body2 style={style.body} color='grey'>
            Login to proceed
          </Body2>
          <Button secondary id='loginBtn' onClick={() => this.props.redirectToLogin({ configType, redirectionHandlerName })}> Login </Button>
        </Modal.Content>
      </Modal>
    )
  }
}

export default Login
