import InputMask from 'react-input-mask'
const InputMaskWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    onChange,
    placeholder,
    options
  } = props
  const mask = options ? options.mask : undefined
  const inputProps = {
    maskChar: ' ',
    placeholder,
    value,
    onChange: (event) => onChange(event.target.value),
    onBlur: onBlur && ((event) => onBlur(id, event.target.value)),
    onFocus: onFocus && ((event) => onFocus(id, event.target.value)),
    id: (!id) ? 'inputMaskWidget' : id,
    readonly,
    disabled
  }
  return (
    <InputMask
      mask={mask}
      {...inputProps}
    />
  )
}

export default InputMaskWidget
