import { useState } from 'react'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { H6, Subtitle1 } from 'components/Header'
import PayoutTerm from './PayoutTerm'
const PayoutTermsConfigure = props => {
  const [payoutTerm] = useState(-1)
  const {
    config,
    product: { payoutTerms, productOptionId, coverTerm }
  } = props
  const { heading, description } = config
  if (payoutTerm > -1) {
    return (
      <>
        <H6 color='primaryLight' content={heading} />
        <Subtitle1 color='grey'>
          <DisplayHtmlString htmlContent={description} />
        </Subtitle1>
        {payoutTerms.map((payoutTerm, ix) => {
          const payoutProps = {
            index: ix,
            payoutTerms,
            productOptionId,
            coverTerm,
            payoutTerm
          }
          return (
            <PayoutTerm
              key={`${ix}_payoutterm`}
              childKey={`${ix}_payoutterm`}
              {...this.props}
              {...payoutProps}
              productOptionConfigFlag
            />
          )
        })}
      </>
    )
  }
  return (
    <>
      <H6 color='primaryLight' content={heading} />
      <Subtitle1 color='grey'>
        <DisplayHtmlString htmlContent={description} />
      </Subtitle1>
      {payoutTerms.map((payoutTerm, ix) => {
        const payoutProps = {
          index: ix,
          payoutTerms,
          productOptionId,
          coverTerm,
          payoutTerm
        }
        return (
          <PayoutTerm
            key={`${ix}_payoutterm`}
            childKey={`${ix}_payoutterm`}
            {...this.props}
            {...payoutProps}
            productOptionConfigFlag
          />
        )
      })}
    </>
  )
}
export default PayoutTermsConfigure
