import { connect } from 'react-redux'

import UserPolicies from './UserPolicies'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType, store: { select } } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    policies: models.policies.getPolicies,
    selectedApplicationNumber: models.applications.getSelectedApplicationNumber,
    selectedApplicationNumberForPolicy: models.policies.getSelectedPolicy,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    count: models.policies.getPoliciesCount
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  const { selectedApplicationNumber, selectedApplicationNumberForPolicy } = selectorData
  const { getApplicationById } = select.applications
  const { getPolicyById } = select.policies
  const selectedApplication = getApplicationById(state, {
    insuranceType,
    applicationNumber: selectedApplicationNumber
  })
  const selectedPolicyData = getPolicyById(state, {
    insuranceType,
    id: selectedApplicationNumberForPolicy
  })
  return {
    ...ownProps,
    ...selectorData,
    loadingPolicies: state.loading.effects.policies.loadPolicies,
    loadingPolicyDetails: state.loading.effects.policies.loadPolicyDetails,
    selectedApplication,
    selectedPolicyData
  }
}

const mapDistpatchProps = (dispatch, ownProps) => {
  const {
    insuranceType
  } = ownProps
  return {
    loadPolicies: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.policies.loadPolicies(query)
    },
    loadPolicyDetails: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.policies.loadPolicyDetails(query)
    },
    updateSelectedPolicy: data => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.policies.updateSelectedPolicy(query)
    }
  }
}

const UserPoliciesContainer = connect(
  mapStateToProps,
  mapDistpatchProps
)(UserPolicies)
export default UserPoliciesContainer
