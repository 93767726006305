import { Transition, Card, Grid, List } from 'semantic-ui-react'
import { H6 } from 'components/Header'

const DetailedProduct = (props) => {
  const { ComponentMap, transition, onShow, insuranceType } = props
  return (
    <Card fluid className='detailedView'>
      <Card.Content>
        <div className='leftColumn'>
          {ComponentMap.InsurerWithLogo}
        </div>
        <div className='rightColumn flexContainer'>
          {ComponentMap.ProductBrochure}
        </div>
      </Card.Content>
      <Card.Content>
        <Grid divided columns={3} stackable style={{ height: 540 }}>
          <Grid.Row>
            <Grid.Column>
              <H6 color='primaryLight'>
                Product Details
              </H6>
              <List>
                <List.Item className='flexItems'>
                  <List.Content className='twoItemsFlex'>
                    {insuranceType !== 'annuity' ? (ComponentMap.Premium) : (ComponentMap.PurchasePrice)}
                  </List.Content>
                  <List.Content className='twoItemsFlex'>
                    {insuranceType !== 'annuity' ? (ComponentMap.ClaimsSettled) : (ComponentMap.AnnuityPayout)}
                  </List.Content>
                </List.Item>
                <List.Item className='flexItems'>
                  <List.Content className='twoItemsFlex'>
                    {insuranceType !== 'annuity' ? (ComponentMap.Cover) : (ComponentMap.PayoutFrequency)}
                  </List.Content>
                  <List.Content className='twoItemsFlex'>
                    {insuranceType !== 'annuity' && ComponentMap.CoverPeriod}
                  </List.Content>
                </List.Item>
              </List>
              <div className='pegToBottom1'>
                {ComponentMap.Review}
                {ComponentMap.CloseModal}
              </div>
            </Grid.Column>
            <Transition
              visible={(transition > 0)}
              onShow={() => onShow(2)}
              animation='slide right'
              duration={200}
            >
              <Grid.Column className='scrollableColumn'>
                <div className='outerDivStyle'>
                  <div className='innerDivStyle'>
                    {['term', 'annuity'].includes(insuranceType) && ComponentMap.PayoutTerms}
                    {insuranceType !== 'annuity' && ComponentMap.Features}
                    {ComponentMap.Benefits}
                  </div>
                </div>
              </Grid.Column>
            </Transition>
            <Transition
              visible={(transition > 1)}
              onShow={() => onShow(3)}
              animation='slide right'
              duration={200}
            >
              <Grid.Column className='scrollableColumn'>
                <div className='outerDivStyle'>
                  <div className='innerDivStyle'>
                    {insuranceType !== 'annuity' && ComponentMap.Riders}
                  </div>
                </div>
              </Grid.Column>
            </Transition>
          </Grid.Row>
        </Grid>
      </Card.Content>
    </Card>
  )
}
export default DetailedProduct
