import { PureComponent } from 'react'
import { Button } from 'semantic-ui-react'
import { Tabs, Tab, TabPanel, TabList } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import './DisplayTabs.scss'
/* eslint react/jsx-handler-names: "off" */
class DisplayTabs extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedIndex: 0
    }
    this.next = this.next.bind(this)
    this.back = this.back.bind(this)
  }

  next () {
    const { selectedIndex } = this.state
    const { tabList } = this.props
    if (selectedIndex < tabList.length - 1) {
      this.setState({
        selectedIndex: selectedIndex + 1
      })
    }
  }

  back () {
    const { selectedIndex } = this.state
    const { onBack } = this.props
    if (selectedIndex > 0) {
      this.setState({
        selectedIndex: selectedIndex - 1
      })
    } else {
      onBack()
    }
  }

  render () {
    const { selectedIndex } = this.state
    const { tabList, bookButton, backButton } = this.props
    return (
      <Tabs className='display__tabs' selectedIndex={selectedIndex} onSelect={() => false}>
        <TabList className='display__tabs-list'>
          {tabList.map((tab, idx) => <Tab key={`tab-${idx}`}>{tab.title}</Tab>)}
        </TabList>
        {tabList.map((tab, index) => (
          <TabPanel key={`tabpanel-${index}`} className='tabContent'>
            <div className='tabDisplayContent'>
              {tab.display}
            </div>
            <div className='tabButtons'>
              {(index < (tabList.length - 1)) && (
                <Button
                  id='nextButton'
                  type='submit'
                  content='PROCEED'
                  secondary
                  onClick={this.next}
                />
              )}
              {(index === (tabList.length - 1)) && bookButton}
              {(index === 0) && backButton}
              {(index > 0) && (
                <Button
                  id='backButton'
                  content='BACK'
                  basic
                  onClick={this.back}
                />
              )}
            </div>
          </TabPanel>
        )
        )}
      </Tabs>
    )
  }
}
export default DisplayTabs
