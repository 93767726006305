import { useState } from 'react'
import { isNotDefined } from 'utils'
import InputWidget from './InputWidget'

const RegistrationNumberWidget = (props) => {
  const { id, schema, value, placeholder, onChange } = props
  const [vehicleRegistrationNumber, setRegistrationNumber] = useState(value)
  const { policyOption } = props.formContext
  const onRegistrationNumberChange = (maskValue) => {
    const withoutMaskedValue = maskValue.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
    if (!isNotDefined(withoutMaskedValue) && withoutMaskedValue.length > 6 && withoutMaskedValue.length <= 11) {
      const maskedValue = maskValue.toUpperCase().replace(/^([a-zA-Z]{2})(\d{1,2})([a-zA-Z]{1,3})(\d{3,4})$/g, '$1-$2-$3-$4')
      setRegistrationNumber(maskedValue)
      return onChange(maskedValue)
    }
    setRegistrationNumber(withoutMaskedValue)
    return onChange(withoutMaskedValue)
  }
  return (
    <>
      {policyOption === 'renewExistingPolicy' && <div className='small text-muted font-italic'>Please Enter Registration Number as per the base policy</div>}
      <InputWidget
        id={id}
        value={vehicleRegistrationNumber ?? ''}
        options={{ inputType: 'text' }}
        onChange={(text) => onRegistrationNumberChange(text)}
        schema={schema}
        placeholder={placeholder}
      />
    </>
  )
}

export default RegistrationNumberWidget
