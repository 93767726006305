import DatePicker from 'react-datepicker'
import InputMask from 'react-input-mask'
import { isNotDefined } from 'utils'
import 'react-datepicker/dist/react-datepicker.css'
import './customDateWidget.scss'
import dayjs from 'dayjs'

const type = {
  m: 'month',
  d: 'day',
  y: 'year'
}
function calculateDateRange (option, givenDate) {
  const newDate = givenDate ? new Date(givenDate) : new Date()
  if (isNotDefined(option)) return newDate.setDate(newDate.getDate() + undefined)
  let date
  const value = parseInt(option)
  const key = option.toString().slice(-1)
  if (!isNotDefined(type[key])) {
    date = dayjs(newDate).add(value, type[key]).valueOf()
  } else {
    date = newDate.setDate(newDate.getDate() + option)
  }
  return date
}

function addDaysOnExistingData (date, days) {
  const newDate = new Date(date)
  return newDate.setDate(newDate.getDate() + days)
}

const VehicleDateWidget = props => {
  const {
    id,
    value,
    options,
    formContext,
    placeholder,
    ...inputProps
  } = props
  inputProps.label = null
  let minDate = new Date().setFullYear(new Date().getFullYear() - 100)
  let maxDate = new Date().setFullYear(new Date().getFullYear() + 100)
  if (!isNotDefined(options.min) && !isNotDefined(options.max)) {
    minDate = new Date().setDate(new Date().getDate() - options.min)
    maxDate = new Date().setDate(new Date().getDate() + options.max)
  }
  if (formContext.policyOption && !isNotDefined(options[formContext.policyOption])) {
    const { min, max } = options[formContext.policyOption]
    if (id.includes('thirdPartyPolicyStartDate')) {
      const { minOffset, maxOffset } = options[formContext.policyOption]
      const { thirdPartyPolicyEndDate } = formContext
      minDate = calculateDateRange(min, thirdPartyPolicyEndDate)
      maxDate = calculateDateRange(max, thirdPartyPolicyEndDate)
      maxDate = addDaysOnExistingData(maxDate, maxOffset ?? 0)
      minDate = addDaysOnExistingData(minDate, minOffset ?? 0)
    } else {
      minDate = calculateDateRange(min)
      maxDate = calculateDateRange(max)
    }
  }
  return (
    <DatePicker
      id={!id ? 'customDateWidget' : id}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      dateFormat='dd-MM-yyyy'
      placeholderText={placeholder}
      selected={value ? new Date(value) : undefined}
      onChange={date => {
        props.onChange(new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString().split('T')[0])
      }}
      wrapperClassName='calendar-input-wrapper'
      minDate={minDate}
      maxDate={maxDate}
      customInput={
        <InputMask
          mask='99-99-9999'
          id={!id ? 'customDateWidget' : id}
        />
      }
    />
  )
}

export default VehicleDateWidget
