const extraActions = {
  enableFeature: (params, schema, uiSchema, data) => {
    // No schema and uiSchema here. We are getting formData
    data.enabledCategoryIds = params.value
    return data
  },
  disableFeature: (params, schema, uiSchema, data) => {
    data.disabledCategoryIds = params.value
    return data
  },
  chosenProductOption: (params, schema, uiSchema, data) => {
    data.chosenProductOption = params
    return data
  }
}
export default extraActions
