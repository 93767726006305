
import { Input, TextArea } from 'semantic-ui-react'

const TextareaWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    placeholder,
    ...inputProps
  } = props
  inputProps.type = options.inputType || inputProps.type || 'text'
  const schema = props.schema || {}
  let maxLength, pattern
  if (options) {
    pattern = options.pattern
    maxLength = schema.maxLength || options.maxLength
  }
  const onKeyPress = (event) => {
    const keyCode = event.keyCode || event.which
    const keyValue = String.fromCharCode(keyCode)
    const re = new RegExp(pattern)
    if (!re.test(keyValue)) {
      event.preventDefault()
    }
  }
  // Remove labels
  inputProps.label = null
  return (
    <TextArea
      maxLength={maxLength}
      id={(!id) ? 'textAreaWidget' : id}
      as={Input}
      value={value === null ? '' : value}
      placeholder={placeholder}
      disabled={disabled}
      readOnly={readonly}
      autoFocus={autofocus}
      rows={options.rows}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
      onChange={(e, { value }) => props.onChange(value)}
      pattern={pattern}
      onKeyPress={onKeyPress}
    />
  )
}
export default TextareaWidget
