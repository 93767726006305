import NumberFormat from 'react-number-format'
import FormInput from 'components/FormInput'

const InternationalMobileWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    onChange,
    options,
    placeholder
  } = props
  const format = options && options.format

  return (
    <NumberFormat
      id={id}
      placeholder={placeholder}
      format={format ? `${format} ##### #####` : '+91 ##### #####'}
      mask='_'
      value={value}
      customInput={FormInput}
      disabled={disabled}
      readOnly={readonly}
      onValueChange={({ value }) => {
        onChange(value, props, options)
      }}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
    />
  )
}

export default InternationalMobileWidget
