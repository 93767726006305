import { Image } from 'semantic-ui-react'
import { Body1, Body2, Caption } from 'components/Header'
import DisplayHtmlString from 'components/DisplayHtmlString'
import { buildProductImagePath } from 'components/buildImagePath'

const ProductImageAndNameWithDescription = ({ data }) => {
  return (
    <div className='productImageAndNameWithProductOptionName'>
      <div className='outerdiv'>
        <div className='productImage'>
          <Image className='productImg' src={buildProductImagePath(data.productDetails.imageUrl)} />
        </div>
        <div className='productNameAndDesc'>
          <Body1>
            {data.productDetails.productName}
          </Body1>
          <Body2>
            <DisplayHtmlString htmlContent={data.productDetails.productOptionName} />
          </Body2>
          <Caption>
            {data.applicationNumber || data.policyNumber}
          </Caption>
        </div>
      </div>
    </div>
  )
}

export default ProductImageAndNameWithDescription
