import React from 'react'
import { Menu } from 'semantic-ui-react'
import { Header, Subtitle2, Caption } from 'components/Header'

function PaymentFrequency (props) {
  const { currentFrequency, onFilterChange, configure, device, insuranceType } = props
  const isAnnuity = insuranceType === 'annuity'
  let frequencies = configure?.common?.paymentFrequencies?.map(pf => {
    if (insuranceType === 'health' && pf.value !== 'A') {
      return {
        ...pf,
        disabled: true
      }
    } else {
      return pf
    }
  })
  let productDataKey = 'paymentFrequency'
  if (isAnnuity) {
    frequencies = configure?.common?.payoutFrequencies
    productDataKey = 'payoutFrequency'
  }
  const heading = productDataKey === 'paymentFrequency' ? 'Payment Frequency' : 'Payout Frequency'
  const handleClick = (value) => {
    const key = (value === 'single') ? 'payoutOption' : isAnnuity ? 'payoutFrequency' : productDataKey
    onFilterChange({ key, value: [{ value }] })
  }
  if (device === 'mobile' && insuranceType !== 'term') {
    return (
      <Menu.Item className='filterMenuContainer'>
        <Header>
          {heading}
        </Header>
        {
          frequencies.filter(pf => pf.value !== 'S').map((pf, ix) => (
            <React.Fragment key={`filt${ix}`}>
              <Header
                id={`${pf.value}`}
                as={(currentFrequency === pf.value) ? 'span' : 'a'}
                onClick={() => {
                  if (!pf.disabled) {
                    const key = (pf.value === 'single') ? 'payoutOption' : productDataKey
                    onFilterChange({ key, value: [{ value: pf.value }] })
                  }
                }}
                className={`ui tiny circular compact button ${(currentFrequency === pf.value) ? 'mobile-selected' : 'edit'}`}
              >
                {pf.display}
              </Header>
            </React.Fragment>
          ))}
      </Menu.Item>
    )
  }

  return (
    <div className='filterMenuContainer'>
      <Subtitle2 color='primaryLight'>
        {heading}
      </Subtitle2>
      <Caption>
        {
          frequencies.filter(pf => pf.value !== 'S').map((pf, ix) => (
            <React.Fragment key={`filt${ix}`}>
              <Header.Content
                id={`${pf.value}`}
                as={(currentFrequency === pf.value) ? 'span' : 'a'}
                onClick={() => {
                  if (!pf.disabled) {
                    handleClick(pf.value)
                  }
                }}
                className={(currentFrequency === pf.value) ? 'selected' : 'edit'}
              >
                {pf.display}
              </Header.Content>
              {(ix < frequencies.length - 2) && <span className='paymentFrequencySeperator'>{' |'}</span>}
            </React.Fragment>
          ))}
      </Caption>
    </div>
  )
}

export default PaymentFrequency
