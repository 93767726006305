import axios from 'axios'
import format from 'string-format'
import jwtDecode from 'jwt-decode'
import { createStore } from './store'
import { feathersApp, createHookToAddAuthHeaders } from './feathers'
import { isNotEmpty } from 'utils'
import { ServerApiError } from './errors'
import { fetchConfig, getDistributorProductTypes } from './config'

const env = process.env.NODE_ENV
let authTokenObjectResponse = {}

// initialize feathers here
/**
   * Gets the auth token
   *
   * @returns object
   */
const getAuthToken = async (config) => {
  const authData = `${config.apiman.clientId}:${config.apiman.clientSecret}`
  const url = format(config.apiman.iamUrl, { realm: config.apiman.realm })
  const headers = {
    'content-type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${window.btoa(authData)}`
  }
  try {
    const response = await axios({
      url,
      method: 'POST',
      data: `grant_type=${config.apiman.grantType}`,
      headers
    })
    return response.data
  } catch (err) {
    // FIXME: Error handling here
    console.log(err.message)
    throw err
  }
}

/**
   * If auth token is expired it gets new auth token and updates authTokenObj
   *
   * @param {*} authTokenObj
   * @returns
   */
const updateAuthTokenWithRefreshToken = async (authTokenObj) => {
  if (authTokenObj.access_token && env === 'production') {
    const { exp } = jwtDecode(authTokenObj.access_token)
    const tokenExpired = Date.now() >= exp * 1000
    if (tokenExpired) {
      // check if the refresh_token has also expired.
      // if yes, error is thrown and UI catches the error.
      const decodedRefreshToken = jwtDecode(authTokenObj.refresh_token)
      const refreshTokenExpired = Date.now() >= decodedRefreshToken.exp * 1000
      if (refreshTokenExpired) {
        throw new ServerApiError.TokenExpiredError('setUpStore:addAuthHeadersToApp:updateAuthTokenWithRefreshToken', {
          message: 'Refresh token expired. Client authentication failed.'
        })
      }
      const config = await fetchConfig()
      const authData = `${config.apiman.clientId}:${config.apiman.clientSecret}`
      const url = format(config.apiman.iamUrl, { realm: config.apiman.realm })
      const headers = {
        'content-type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${window.btoa(authData)}`
      }
      try {
        const response = await axios({
          url,
          method: 'POST',
          data: `grant_type=refresh_token&refresh_token=${authTokenObj.refresh_token}`,
          headers
        })
        const authResponse = response.data
        const decodedAuthToken = jwtDecode(authResponse.access_token)
        authTokenObj.access_token = authResponse.access_token || 'dummy'
        authTokenObj.distributorId = decodedAuthToken.distributorId
        if (authResponse.refresh_token) {
          authTokenObj.refresh_token = authResponse.refresh_token
        }
        setAuthTokenObject({ access_token: authResponse.access_token, refresh_token: authResponse.refresh_token })
      } catch (err) {
        // FIXME: Error handling here
        console.log(err.message)
        throw err
      }
    }
  }
}

/**
   * This function adds headers (Authorization, distributorid, client) to the in the context
   *
   * @param {*} context
   * @param {*} authTokenObject
   * @returns object
   */
const addAuthHeadersToApp = async (context, authTokenObject) => {
  // before making any api call, check if token has expired
  // if token has expired, get a new access_token using the refresh_token
  // use this for subsequent calls
  await updateAuthTokenWithRefreshToken(authTokenObject)
  const { apikey, client, distributorId } = authTokenObject
  const headers = {
    Authorization: `Bearer ${authTokenObject.access_token}`,
    distributorid: distributorId,
    client
  }
  if (isNotEmpty(context.params.headers)) {
    Object.assign(context.params.headers, headers)
  } else {
    context.params.headers = headers
  }
  if (isNotEmpty(context.params.query)) {
    context.params.query.apikey = apikey
  } else {
    context.params.query = {
      apikey
    }
  }
  return context
}

/**
   * This is a test function added only to check the number of times dropoff is called.
   * Remove after testing on UAT.
   *
   * @param {*} reqType
   * @returns
   */
const testCounter = (reqType) => {
  // FIXME: Added only to check the number of times dropoff is called. Remove after testing on UAT
  let count = window.localStorage.getItem(reqType)
  if (!count) {
    count = 0
  }
  window.localStorage.setItem(reqType, ++count)
}

const requestWrapper = {
  // FIXME: need to use navigator or some other logic as synchronous http requests are deprecated.
  /**
   * This function makes XMLHttpRequest
   *
   * @param {*} method
   * @param {*} url
   * @param {*} data
   * @returns object
   */
  makeSyncHttpRequest: async function ({ method, url, data }) {
    await updateAuthTokenWithRefreshToken(this.authTokenObject)
    const client = new window.XMLHttpRequest()
    client.open(method, url, false)
    client.setRequestHeader('Content-Type', 'application/json')
    client.setRequestHeader('distributorId', this.authTokenObject.distributorId)
    client.setRequestHeader('client', this.authTokenObject.client)
    client.setRequestHeader('Authorization', `Bearer ${this.authTokenObject.access_token}`)
    testCounter('XHR')
    return client.send(JSON.stringify(data))
  },

  /**
   * This function is for analytics and diagnostics that send data to a
   * server before the document is unloaded.
   * For more details refer to navigator.sendBeacon() documentation in javascript
   *
   * @param {*} url
   * @param {*} data
   * @param {*} headers
   * @returns
   */
  makeBeaconRequest: function ({ url, data, headers = {} }) {
    const headerObj = {
      ...headers,
      distributorId: this.authTokenObject.distributorId,
      client: this.authTokenObject.client,
      Authorization: `Bearer ${this.authTokenObject.access_token}`
    }
    const blob = new window.Blob([JSON.stringify(data)], headerObj)
    testCounter('Beacon')
    navigator.sendBeacon(url, blob)
  },

  /**
   * This function makes the fetch request for a url with the fixed headers as
   * Content-Type, distributorId, client and Authorization
   *
   * @param {*} method
   * @param {*} url
   * @param {*} data
   * @returns
   */
  makeFetchRequest: function ({ method, url, data }) {
    testCounter('Fetch')
    return window.fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        distributorId: this.authTokenObject.distributorId,
        client: this.authTokenObject.client,
        Authorization: `Bearer ${this.authTokenObject.access_token}`
      },
      body: JSON.stringify(data),
      keepalive: true
    })
  }
}

/**
   * This function calls createStore function with storeTypes and initialState and before that it
   * appeneds some keys to authTokenObject. These auth headers are then added to hooks
   *
   * @param {*} storeTypes
   * @returns object
   */
const setUpStore = async (storeTypes) => {
  // first we will create the store
  let distributorId
  let authResponse = {}
  const config = await fetchConfig()
  feathersApp(config)
  const authTokenObject = {}
  // authTokenObject remains same throughout, wherever access_token and related data is to be set in headers.
  // only the reference is passed and the same is updated
  try {
    const host = window.location.host
    if (env === 'production') {
      authResponse = await getAuthToken(config)
      distributorId = jwtDecode(authResponse.access_token).distributorId
    } else {
      distributorId = config.distributorId ?? 'IN-9820'
      // distributorId = 'IN-9999739'
    }
    const availableProducts = await getDistributorProductTypes(config, authResponse.access_token, distributorId)
    authTokenObject.access_token = authResponse.access_token || 'dummy'
    authTokenObject.refresh_token = authResponse.refresh_token
    authTokenObject.distributorId = distributorId
    authTokenObject.client = config.apiman.clientId
    authTokenObject.apikey = config.apiman.apikey

    requestWrapper.authTokenObject = authTokenObject
    setAuthTokenObject({ access_token: authResponse.access_token, refresh_token: authResponse.refresh_token })
    createHookToAddAuthHeaders((context) => addAuthHeadersToApp(context, authTokenObject))

    // Now that we are connected to keycloak, add the hooks
    // verify if client host is same as the domain
    const initialState = {
      configure: {
        host,
        ...config,
        distributorId,
        availableProducts
      }
    }
    const store = createStore(storeTypes, initialState)
    return store
  } catch (err) {
    // set error in store so that UI can display
    console.log(err)
    throw err
  }
}

const setAuthTokenObject = (authObject) => {
  authTokenObjectResponse = authObject
}

const getAuthTokenObject = () => {
  return authTokenObjectResponse
}

export {
  setUpStore,
  requestWrapper,
  updateAuthTokenWithRefreshToken,
  getAuthTokenObject
}
