import { validateDateRange } from 'screens/components/distributorDashboard/distributorDashboardHelper'
import JsonSchemaEasyForm from './JsonSchemaEasyForm'
class DashboardJsonSchemaForm extends JsonSchemaEasyForm {
  onValidate (formData, errors) {
    if (formData.dateFrom && formData.dateTo) {
      if (validateDateRange(formData.dateFrom, formData.dateTo)) {
        errors.dateFrom.addError('Invalid Range')
        errors.dateTo.addError('Invalid Range')
      }
    }
    return errors
  }
}

export default DashboardJsonSchemaForm
