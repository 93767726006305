import { Modal } from 'semantic-ui-react'
import 'components/css/loader.scss'

const Loading = (props) => {
  const { open } = props
  return (
    <Modal dimmer='inverted' open={open} basic>
      <div className='loaderOuter' id='loading'>
        <div className='ball-pulse-sync'>
          <div />
          <div />
          <div />
        </div>
      </div>
    </Modal>
  )
}
export default Loading
