import { PureComponent } from 'react'
import { Icon, List, Statistic } from 'semantic-ui-react'
import { Header, Caption, H6, Body2, Subtitle1 } from 'components/Header'
import DisplayHtmlString from 'components/DisplayHtmlString'
import EditAddOns from '../../EditAddOns'
import { rupeeFormatter, isNotDefined } from 'utils'
import { calculateRiderPremium } from 'smartcovr_api/src/insuranceApi'
import produce from 'immer'
import { getSchema } from 'screens/helper/productHelper'
// FIXME: ADD RIDER, REMOVE RIDER are all literals to be removed to constjs
/* eslint no-useless-catch: "off" */
/* eslint no-prototype-builtins: "off" */
/* eslint react/jsx-handler-names: "off" */
class RiderPopUp extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      errors: [],
      currentPremium: props.currentPremium,
      newRiderFormData: props.rider.riderFormData,
      schema: props.schema
    }
    this.onChange = this.onChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.setState(produce(this.state, draft => {
      if (newProps.currentPremium !== this.props.currentPremium) {
        draft.currentPremium = newProps.currentPremium
      }
    }))
  }

  async onChange (formData) {
    const { rider: { riderId, riderFormData }, feature, productOptionId, insuranceType, productData, product, rider } = this.props

    // When riderPt is changed new schema is created to show eligible maximum riderPpt
    if (formData.key === 'riderPt') {
      const newSchema = getSchema(rider, product, productData, formData.formData.riderPt, formData.formData.riderPpt, formData.formData.riderSumAssured)
      this.setState(produce(this.state, draft => {
        draft.schema = newSchema.schema
        draft.newRiderFormData.riderPt = formData.formData.riderPt
        draft.newRiderFormData.riderPpt = 0
        draft.newRiderFormData.riderSumAssured = formData.formData.riderSumAssured
      }))
    }
    if (formData.key === 'riderPpt') {
      const newSchema = getSchema(rider, product, productData, formData.formData.riderPt, formData.formData.riderPpt, formData.formData.riderSumAssured)
      this.setState(produce(this.state, draft => {
        draft.schema = newSchema.schema
        draft.newRiderFormData.riderPt = formData.formData.riderPt
        draft.newRiderFormData.riderPpt = formData.formData.riderPpt
        draft.newRiderFormData.riderSumAssured = formData.formData.riderSumAssured
      }))
    }
    if (formData.errors.length === 0) {
      const dataForRider = Object.assign({}, riderFormData, formData.formData)
      dataForRider.percentOfBase = Math.round(dataForRider.riderSumAssured * 100 / dataForRider.sumAssured, 2)
      let currentPremium
      if (insuranceType === 'term') {
        try {
          currentPremium = await calculateRiderPremium({
            insuranceType,
            riderId,
            feature,
            dataForRider,
            productOptionId,
            formData: this.props.formData,
            productData,
            updateEnquiry: true
          })
        } catch (err) {
          throw err
        }
        this.setState(produce(this.state, draft => {
          draft.currentPremium = currentPremium
        }))
      } else {
        this.setState(produce(this.state, draft => {
          draft.currentPremium.perThousand = 0
          draft.currentPremium.perThousandWithGst = 0
          draft.currentPremium.premium = 0
          draft.currentPremium.premiumWithGst = 0
        }))
      }
    }
  }

  render () {
    const { currentPremium } = this.state
    const {
      rider,
      schema,
      uiSchema,
      rules,
      onSubmit,
      device,
      onCancel,
      contentType,
      checked,
      customValidationMessage,
      product // eslint-disable-line
    } = this.props
    const { riderFormData, riderSchema } = rider
    if (schema.properties.hasOwnProperty('riderSumAssured')) {
      if (riderFormData.riderSumAssured > riderSchema.schema.properties.riderSumAssured.maximum) {
        this.setState(produce(this.state, draft => {
          draft.newRiderFormData.riderSumAssured = 0
        }))
      }
    }

    // To set default maximum values for riderPt and riderPpt
    if ((this.state.newRiderFormData.riderPt === undefined || this.state.newRiderFormData.riderPt === 0)) {
      if (schema.properties.hasOwnProperty('riderPt') && schema.properties.hasOwnProperty('riderPpt')) {
        this.setState(produce(this.state, draft => {
          draft.newRiderFormData.riderPt = schema.properties.riderPt.default
          draft.newRiderFormData.riderPpt = schema.properties.riderPpt.default
        }))
      }
    }
    // Rider for review in mobile view
    if (device === 'mobile') {
      return (
        <>
          <Subtitle1>
            {rider.heading}
          </Subtitle1>
          <Body2 color='grey'>
            <DisplayHtmlString htmlContent={rider.description} />
          </Body2>
          <Caption className='clickableLinks'>
            <b onClick={() => this.setState({ reviewEdit: true })}> Know More </b>
            &nbsp; &nbsp;
            <b> Download Brochure </b>
          </Caption>
          <Statistic color='green'>
            <Statistic.Label>{`Rider ${currentPremium.frequencyDisplay} Premium`}</Statistic.Label>
            <Statistic.Value>
              <Icon color='green' size='small' name='rupee' />
              <span ref={this.premiumRef}>
                {(currentPremium.premium > 0) ? `${rupeeFormatter(currentPremium.premium)}/-` : '-'}
              </span>
            </Statistic.Value>
          </Statistic>
          <EditAddOns
            customValidationMessage={customValidationMessage}
            schema={this.state.schema}
            uiSchema={uiSchema}
            formData={riderFormData}
            rules={rules}
            contentType={contentType}
            onSubmit={onSubmit}
            onChange={this.onChange}
            submitText='Save'
            onCancel={onCancel}
            size='medium'
          />
        </>
      )
    }
    let displayContent
    let buttonText = 'UPDATE RIDER'
    if (this.props.insuranceType !== 'health' && (isNotDefined(currentPremium?.premium) || isNaN(currentPremium?.premium) || currentPremium?.premium === 0)) {
      buttonText = 'REMOVE RIDER'
    }
    if (!checked) {
      buttonText = 'ADD RIDER'
    }
    if (contentType === 'review') {
      displayContent = (
        <>
          {/* <Statistic color='green'>
          <Statistic.Label color='secondary'>{`Rider ${currentPremium.frequencyDisplay} Cost`}</Statistic.Label>
          {
            <Statistic.Value>
              <Icon size='small' name='rupee' />
              {`${Math.round(currentPremium.perThousand * 100) / 100} per`} <Icon size='small' name='rupee' /> {`1000`}
            </Statistic.Value>
          }
        </Statistic> */}
          {!rider.hasPremiumWillNotShown && (
            <Statistic color='green'>
              <Statistic.Label color='secondary'>{`Rider ${currentPremium.frequencyDisplay} Premium`}</Statistic.Label>
              <Statistic.Value>
                <Icon size='small' name='rupee' />
                <span ref={this.premiumRef}>
                  {(currentPremium.premium > 0) ? `${rupeeFormatter(currentPremium.premium)}/-` : '-'}
                </span>
              </Statistic.Value>
            </Statistic>
          )}

          {(!rider.hasRiderSchemaUpdated && !rider.hasPremiumWillNotShown) && (
            <Statistic color='green'>
              <Statistic.Label color='secondary'>Rider Term</Statistic.Label>
              <Statistic.Value>
                {`${rider.riderTerm} Years`}
              </Statistic.Value>
            </Statistic>
          )}
        </>
      )
    } else {
      displayContent = (
        <>
          {!rider.hasPremiumWillNotShown && (
            <Header sub color='primaryLight'>
              {`Rider ${currentPremium.frequencyDisplay} Premium`}
            </Header>
          )}
          {!rider.hasPremiumWillNotShown && (
            <H6 color='green'>
              <div>
                <Icon color='green' name='rupee' size='small' />
                <span>
                  {(currentPremium.premium && currentPremium.premium > 0)
                    ? `${rupeeFormatter(currentPremium.premium)}`
                    : '-'}
                </span>
              </div>
            </H6>
          )}
        </>
      )
    }
    return (
      <List.Content className='riderEdit'>
        {displayContent}
        <EditAddOns
          customValidationMessage={customValidationMessage}
          schema={this.state.schema}
          uiSchema={uiSchema}
          formData={this.state.newRiderFormData}
          contentType={contentType}
          rules={rules}
          onSubmit={onSubmit}
          onChange={this.onChange}
          submitText={buttonText}
          onCancel={onCancel}
          size='medium'
        />
      </List.Content>
    )
  }
}
export default RiderPopUp
