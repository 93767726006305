import { connect } from 'react-redux'
import ClaimRegistration from './components/claims/claimRegistration/ClaimRegistration'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    distributorId: models.configure.getDistributorId,
    customerDetails: models.customer.getCustomerDetails,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    availableProducts: models.configure.getAvailableProducts
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...ownProps,
    ...selectorData,
    insuranceType,
    schema: state.claimsService.term,
    claimIntimation: state.claimsService.claimIntimation[insuranceType],
    registration: state.loading.effects.claimsService.postClaimRegistration,
    claimRegistration: state.claimsService.claimRegistration[insuranceType],
    store: ownProps.store
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    // effects go here
    // selectors go here
    postRegistration: (query) => {
      return dispatch.claimsService.postClaimRegistration(query)
    }
  }
}

const ClaimRegistrationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClaimRegistration)
ClaimRegistrationContainer.path = 'claim-registration'
export default ClaimRegistrationContainer
