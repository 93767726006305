import { useEffect } from 'react'
import {
  useGlobalState,
  useAction,
  useSelector
} from './useReduxFunctions'
import { isNotEmpty } from 'utils'

// here useInitialDataLoader is a custom hook which sets initial data and returns appropriate loading
export default function useLoadProposerRelations (insuranceType, chosenProductMetadata) {
  const loadProposerRelationsLoading = useGlobalState(state => state.loading.effects.insuranceConfigure.loadProposerRelations)
  const loadProposerRelations = useAction(dispatch => dispatch.insuranceConfigure.loadProposerRelations)
  const proposerRelations = useSelector((select, state) => select.insuranceConfigure.getProposerRelations(state, {
    insuranceType
  }))
  const setHooksError = useAction(dispatch => dispatch.errors.setHooksError)
  useEffect(() => {
    try {
      if (isNotEmpty(chosenProductMetadata) && !isNotEmpty(proposerRelations) && !loadProposerRelationsLoading) {
        loadProposerRelations({
          insuranceType,
          institutionId: chosenProductMetadata.institutionId,
          productInsurerId: chosenProductMetadata.productInsurerId
        })
      }
    } catch (err) {
      setHooksError(err)
    }
  },
  [chosenProductMetadata, loadProposerRelationsLoading]) // eslint-disable-line

  return { loadProposerRelationsLoading, proposerRelations }
}
