import { effectsHandler } from './insurance/modelHelpers'
import { isNotEmpty } from 'utils'
import { loadReportForTotalLeads, loadReportForCompletedPaymentButformSubmissionPending, loadReportForSubmittedApplication, loadReportForPendingApplication, loadReportForMailingDetails, loadReportForSmsDetails, loadReportForDropOffStageReport, loadReportForConversionReport } from './distributorReportEffects'

const filterValues = {
  skip: 0,
  limit: 10,
  order: 'desc',
  customerType: 'all',
  fileType: ''
}

const distributorReport = {
  name: 'distributorReport',
  state: {
    term: {
      totalLeads: {
        filterValues
      },
      completedPaymentbutFormSubmissionPending: {
        filterValues
      },
      submittedApplication: {
        filterValues
      },
      pendingApplication: {
        filterValues
      },
      mailingDetails: {
        filterValues
      },
      smsDetails: {
        filterValues
      },
      dropOffStage: {
        filterValues
      },
      conversionReport: {
        filterValues
      }
    },
    car: {
      totalLeads: {
        filterValues
      },
      mailingDetails: {
        filterValues
      },
      smsDetails: {
        filterValues
      },
      dropOffStage: {
        filterValues
      },
      conversionReport: {
        filterValues
      }
    },
    bike: {
      totalLeads: {
        filterValues
      },
      mailingDetails: {
        filterValues
      },
      smsDetails: {
        filterValues
      },
      dropOffStage: {
        filterValues
      },
      conversionReport: {
        filterValues
      }
    },
    health: {
      totalLeads: {
        filterValues
      },
      pendingApplication: {
        filterValues
      },
      completedPaymentbutFormSubmissionPending: {
        filterValues
      },
      submittedApplication: {
        filterValues
      },
      mailingDetails: {
        filterValues
      },
      smsDetails: {
        filterValues
      },
      dropOffStage: {
        filterValues
      },
      conversionReport: {
        filterValues
      }
    },
    annuity: {
      totalLeads: {
        filterValues
      },
      completedPaymentbutFormSubmissionPending: {
        filterValues
      },
      submittedApplication: {
        filterValues
      },
      pendingApplication: {
        filterValues
      },
      mailingDetails: {
        filterValues
      },
      smsDetails: {
        filterValues
      },
      dropOffStage: {
        filterValues
      },
      conversionReport: {
        filterValues
      }
    }
  },
  selectors: (slice, createSelector, hasProps) => ({
    getReportForTotalLeads () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { totalLeads } = distributorReport[insuranceType]
          if (isNotEmpty(totalLeads)) {
            return totalLeads
          } else {
            return {}
          }
        }
      )
    },
    getReportForCompletedPaymentButformSubmissionPending () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { completedPaymentbutFormSubmissionPending } = distributorReport[insuranceType]
          if (isNotEmpty(completedPaymentbutFormSubmissionPending)) {
            return completedPaymentbutFormSubmissionPending
          } else {
            return {}
          }
        }
      )
    },
    getReportForSubmittedApplication () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { submittedApplication } = distributorReport[insuranceType]
          if (isNotEmpty(submittedApplication)) {
            return submittedApplication
          } else {
            return {}
          }
        }
      )
    },
    getReportForPendingApplication () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { pendingApplication } = distributorReport[insuranceType]
          if (isNotEmpty(pendingApplication)) {
            return pendingApplication
          } else {
            return {}
          }
        }
      )
    },
    getReportForMailingDetails () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { mailingDetails } = distributorReport[insuranceType]
          if (isNotEmpty(mailingDetails)) {
            return mailingDetails
          } else {
            return {}
          }
        }
      )
    },
    getReportForSmsDetails () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { smsDetails } = distributorReport[insuranceType]
          if (isNotEmpty(smsDetails)) {
            return smsDetails
          } else {
            return {}
          }
        }
      )
    },
    getReportForDropOffStageReport () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { dropOffStage } = distributorReport[insuranceType]
          if (isNotEmpty(dropOffStage)) {
            return dropOffStage
          } else {
            return {}
          }
        }
      )
    },
    getReportForConversionReport () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (distributorReport, insuranceType) => {
          const { conversionReport } = distributorReport[insuranceType]
          if (isNotEmpty(conversionReport)) {
            return conversionReport
          } else {
            return {}
          }
        }
      )
    }
  }),
  reducers: {
    storeTotalLeadsReport (state, { totalLeads, insuranceType }) {
      state[insuranceType].totalLeads = totalLeads
      return state
    },
    storeReportOfCompletedPaymentButFormSubmissionPending (state, { completedPaymentbutFormSubmissionPending, insuranceType }) {
      state[insuranceType].completedPaymentbutFormSubmissionPending = completedPaymentbutFormSubmissionPending
      return state
    },
    storeReportOfSubmittedApplication (state, { submittedApplication, insuranceType }) {
      state[insuranceType].submittedApplication = submittedApplication
      return state
    },
    storeReportOfPendingApplication (state, { pendingApplication, insuranceType }) {
      state[insuranceType].pendingApplication = pendingApplication
      return state
    },
    storeReportOfMailingDetails (state, { mailingDetails, insuranceType }) {
      state[insuranceType].mailingDetails = mailingDetails
      return state
    },
    storeReportOfSmsDetails (state, { smsDetails, insuranceType }) {
      state[insuranceType].smsDetails = smsDetails
      return state
    },
    storeReportOfDropOffStage (state, { dropOffStage, insuranceType }) {
      state[insuranceType].dropOffStage = dropOffStage
      return state
    },
    storeConversionReport (state, { conversionReport, insuranceType }) {
      state[insuranceType].conversionReport = conversionReport
      return state
    }
  },
  effects: (dispatch) => ({
    loadReportForTotalLeads: effectsHandler(dispatch, loadReportForTotalLeads),
    loadReportForCompletedPaymentButformSubmissionPending: effectsHandler(dispatch, loadReportForCompletedPaymentButformSubmissionPending),
    loadReportForSubmittedApplication: effectsHandler(dispatch, loadReportForSubmittedApplication),
    loadReportForPendingApplication: effectsHandler(dispatch, loadReportForPendingApplication),
    loadReportForMailingDetails: effectsHandler(dispatch, loadReportForMailingDetails),
    loadReportForSmsDetails: effectsHandler(dispatch, loadReportForSmsDetails),
    loadReportForDropOffStageReport: effectsHandler(dispatch, loadReportForDropOffStageReport),
    loadReportForConversionReport: effectsHandler(dispatch, loadReportForConversionReport
    )
  })
}

export default distributorReport
