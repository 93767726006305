const authPaths = (process.env.REACT_APP_LOGIN_REQUIRED === 'no')
  ? []
  : [
    // 'select-product'
    // 'productopt-config',
    // 'product-config',
      'booking/pre-payment',
      'payment-summary',
      'booking/post-payment',
      'process-payment',
      'dashboard',
      'claim-intimation',
      'track-claims',
      'registered-claims',
      'claim-landing'
    ]
const config = {
  startingRoute: '/',
  authPaths,
  routeConfig: {
    '/': 'Landing',
    'select-feature': 'Feature Listing',
    'select-product': 'Select Insurnace Product',
    'productopt-config': 'Configure Product Option',
    'product-config': 'Configure Insurance Product',
    'booking/pre-payment': 'Insurance Booking',
    'booking/post-payment': 'Complete Insurance Booking',
    'process-payment': 'Make Payment',
    dashboard: 'Distributor Report Dashboard',
    onboard: 'Term Life Insurnce'
  },
  defaultErrorConfig: {
    title: 'Something Went Wrong!',
    description: "Sorry but it seems that our system is facing some issue, you can try reloading the page or visit <a href='/'>home</a> page",
    buttonText: 'RELOAD PAGE',
    buttonImgPath: 're-load-icon.png',
    imagePath: 'em-something-went-wrong.png',
    animation: 'shake animated'
  },
  pageNotFound: {
    title: 'Page Not Found!',
    description: 'It seems we are unable to find the page that your looking for, please do come back later',
    buttonText: 'REDIRECT TO HOME',
    buttonImgPath: 'home-icon.png',
    imagePath: 'em-page-not-found.png',
    animation: 'shake animated'
  },
  clientSessionExpired: {
    title: 'Session Expired!',
    description: 'Due to inactivity for a long time, your session has expired. Please reload to resume your activity',
    buttonText: 'RELOAD',
    buttonImgPath: 'user-icon.png',
    imagePath: 'em-session-expired.png',
    animation: 'shake animated'
  },
  networkError: {
    title: 'Check your Internet Connection!',
    description: "Sorry but it seems like you don't have an active internet connection, Please check your internet connection and try again",
    buttonText: 'Try Again',
    buttonImgPath: 're-load-icon.png',
    imagePath: 'em-could-not-connect.png',
    animation: 'shake animated'
  },
  cdnPath: 'https://cdn.smartcovr.io',
  configTypeForKeyCloakLogin: 'employeeAuth',
  configTypeForHsecLogin: 'auth',
  redirectHandlerForHsecLogin: 'tpt-handler',
  redirectHandlerForKeyCloakLogin: 'keycloak-tpt-handler'
}

export default config
