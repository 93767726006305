
import { isNotDefined, isNotNumber } from 'utils'
import produce from 'immer'
import ajv from 'smartcovr_ajv'
import { getMatchingPaymentOption } from 'smartcovr_api/src/models/insurance/modelHelpers'
import { isArray, isNotEmpty } from 'utils/lib/basic'
import { getPremiumForPayoutTerm } from 'smartcovr_api/src/models/insurance/helpers/productHelper'

const getSchema = (rider, product, productData, selectedRiderPt = 0, selectedRiderPpt = 0) => {
  const { riderInsurerId } = rider
  const { riderModals, paymentOptions, productOption } = product
  const { pptOptions } = productOption
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption, paymentOptions)
  const pptOption = rider.pptOptions[paymentOption]
  const matchingFeature = product.features.find(fc => fc.insurerId === riderInsurerId)
  const draftInput = JSON.parse(JSON.stringify(matchingFeature.input))
  const productPptOption = pptOptions[paymentOption]
  const featureValues = productData[matchingFeature.schemaKey]
  const riderData = pptOptions[paymentOption].riderPremiums.find((rp) => rp.insurerId === riderInsurerId)
  const formData = {}
  Object.keys(matchingFeature.input.schema.properties).forEach((ky) => {
    if (!isNotDefined(featureValues[ky])) {
      formData[ky] = featureValues[ky]
      if (ky === 'riderSumAssured' && !isNotDefined(pptOption)) {
        draftInput.schema.properties[ky].minimum = pptOption.saMin
        if (isNotEmpty(productPptOption) && isNotEmpty(productData)) {
          const productPremiums = getPremiumForPayoutTerm(
            productData,
            productPptOption.premiums[`POT_${productPptOption.defaultPayoutTerm}`],
            productPptOption.defaultPayoutTerm
          )
          let premium
          if (isArray(productPremiums)) {
            premium = productPremiums.find(
              (pre) => pre.frequency === productData.paymentFrequency
            )
          } else {
            premium = productPremiums
          }
          let totalRiderPremium = 0
          product.riders.forEach(element => {
            if (!element.hasRiderSchemaUpdated) {
              if (element.currentPremium.premium > 0) {
                totalRiderPremium = totalRiderPremium + element.currentPremium.premium
              }
            }
          })
          const riderPremium = pptOption.riderPremium.find(
            (riderPre) => riderPre.frequency === productData.paymentFrequency
          )
          let perThousand
          let maxPpt = 0
          riderData.riderPremium.forEach(rp => {
            if (Object.prototype.hasOwnProperty.call(rp, 'premiumOptions')) {
              rp.premiumOptions.forEach(obj => {
                if (obj.pt === selectedRiderPt) {
                  obj.options.forEach(opt => {
                    if (opt.ppt > maxPpt) {
                      maxPpt = opt.ppt
                    }
                  })
                }
              })
            }
          })

          riderPremium.premiumOptions.forEach(obj => {
            if (selectedRiderPt === obj.pt) {
              obj.options.forEach(opt => {
                if (selectedRiderPpt === opt.ppt) {
                  perThousand = opt.perThousand
                }
              })
            }
          })
          const value1 = (pptOption.maxPercentOfBase * (premium.premium + totalRiderPremium)) / 100
          const value2 = Math.round(value1 /
            (perThousand * riderModals[productData.paymentFrequency])) * 1000
          draftInput.schema.properties[ky].maximum = Math.min(pptOption.saMax, value2)
        } else {
          draftInput.schema.properties[ky].maximum = pptOption.saMax
        }
      }
      if (ky === 'riderPt') {
        const riderPtEnum = []
        const riderPtEnumNames = []

        pptOption.riderPremium.forEach(premiumObj => {
          premiumObj.premiumOptions.forEach(obj => {
            if (!riderPtEnum.includes(obj.pt)) {
              riderPtEnum.push(obj.pt)
              riderPtEnumNames.push(`${obj.pt} years`)
            }
          })
        })
        draftInput.schema.properties[ky].enum = riderPtEnum
        draftInput.schema.properties[ky].enumNames = riderPtEnumNames
      }
      if (ky === 'riderPpt') {
        const riderPptEnum = []
        const riderPptEnumNames = []
        pptOption.riderPremium.forEach(premiumObj => {
          premiumObj.premiumOptions.forEach(obj => {
            if (obj.pt === selectedRiderPt) { // to show only eligible ppt for a pt
              obj.options.forEach(pptObj => {
                if (!riderPptEnum.includes(pptObj.ppt)) {
                  riderPptEnum.push(pptObj.ppt)
                  riderPptEnumNames.push(`${pptObj.ppt} years`)
                }
              })
            }
          })
        })
        draftInput.schema.properties[ky].enum = riderPptEnum
        draftInput.schema.properties[ky].enumNames = riderPptEnumNames
      }
    }
    draftInput.formData = formData
    draftInput.rules = draftInput.rules || []
  })
  return draftInput
}

const termDisplayData = (product, formData, productData, paymentOptions, paymentFrequencies) => {
  const { sumAssured, age } = formData
  const { coverTerm } = productData
  const features = product.productOption.featureCategoryIds
  const pptOption = getPptOptionForEnquiry(product, productData, paymentOptions)
  const eligibleCoverUpto = product.productOption.eligibleCoverUpto
  const payoutTerms = product.productOption.payoutTerms
  const maxCoverTerm = getMaxCoverage(product, formData, productData, paymentOptions)
  const wholeLife = features.findIndex((feature) => feature.categoryId === 36) > -1
  const fixedTerm = features.findIndex((feature) => feature.categoryId === 257) > -1
  const defaultPayoutTerm = pptOption.defaultPayoutTerm
  const pptIncrement = pptOption.ppt.pptIncrement
  const productPremium = getPremium(product, productData, paymentOptions)
  const riders = updateRidersWithProductData(product.productOption.riders, productData, product)
  const riderPremiumWithGst = riders.reduce((sum, ri) => sum + ri.currentPremium.premiumWithGst, 0)
  const riderPremiums = riders.reduce((sum, ri) => {
    const premium = ri.currentPremium.premium
    if (isNotNumber(premium)) {
      return sum
    }
    return sum + premium
  }, 0)
  const totalPremium = productPremium.premium + riderPremiums
  const totalPremiumWithGst = productPremium.premiumWithGst + riderPremiumWithGst
  const paymentFrequency = getPaymentFrequency(
    paymentFrequencies,
    productData.paymentFrequency ?? 'S'
  )
  const validateSumAssured = (data) => {
    let maximum = pptOption.sumAssuredRange.saMax
    if (maximum === 0) {
      maximum = undefined
    }
    const schema = {
      type: 'object',
      properties: {
        sumAssured: {
          title: 'sumAssured',
          type: 'number',
          minimum: pptOption.sumAssuredRange.saMin,
          maximum
        }
      }
    }
    const validate = ajv.compile(schema)
    const valid = validate(data)
    return {
      valid,
      errors: validate.errors
    }
  }
  return {
    sumAssured,
    age,
    coverTerm,
    defaultPayoutTerm,
    pptIncrement,
    eligibleCoverUpto,
    payoutTerms,
    ...maxCoverTerm,
    wholeLife,
    fixedTerm,
    riders,
    productPremium,
    totalPremium,
    totalPremiumWithGst,
    riderPremiums,
    paymentFrequency,
    validateSumAssured
  }
}

// Get Min and Max IDv values from the IDV Range
const getMinMax = (idvRange) => {
  let range = { idvMin: 0, idvMax: 0 }
  if (idvRange.length > 0) {
    range = {
      idvMin: Math.min(...idvRange.map(idv => JSON.parse(idv).idvMin)),
      idvMax: Math.max(...idvRange.map(idv => JSON.parse(idv).idvMax))
    }
  }
  return range
}

// Extracting IDVRange and PolicyTerm for filter menu in car, bike Insurance
const extractPptOptionData = (pptOptions, paymentOption) => {
  let idvRange = []
  let policyTerm = 0
  const pptOpt = pptOptions[paymentOption]
  const idvRangeObj = pptOpt.idvRange
  if (idvRangeObj.idvMin > 0 && idvRangeObj.idvMax > 0) {
    idvRange = [...new Set([...idvRange, JSON.stringify(idvRangeObj)])]
  }
  policyTerm = pptOpt.policyTerm
  return { policyTerm, idvRange }
}

// pptOptions parameter is all the products, pptOptions from getPptOption selector for the Product Lisitng page, passed to FilterMenu, but on the productConfig page it is a single product.pptOptions returned from the getDispalyData function here in this file, received from the productConfig page as onboardComposer props.
function getProductData (pptOptions, productData, isProductConfigHandle = false, insuranceType = 'car') {
  if (['car', 'bike'].includes(insuranceType)) {
    const { paymentOption } = productData
    const idvRangeArr = []
    let policyTermValue = 0
    if (isProductConfigHandle) {
      const { idvRange, policyTerm } = extractPptOptionData(pptOptions, paymentOption)
      idvRangeArr.push(...idvRange)
      policyTermValue = policyTerm
    } else {
      let extractPptOptions = pptOptions
      if (!Array.isArray(pptOptions)) {
        extractPptOptions = Object.keys(pptOptions).reduce((pptOptionArr, key) => {
          pptOptionArr.push(...pptOptions[key])
          return pptOptionArr
        }, [])
      }
      extractPptOptions.forEach((obj) => {
        const { idvRange, policyTerm } = extractPptOptionData(obj.pptOptions, paymentOption)
        idvRangeArr.push(...idvRange)
        policyTermValue = policyTerm
      })
    }
    return { idvRange: getMinMax(idvRangeArr), policyTerm: policyTermValue }
  }
}

const autoDisplayData = (product, formData, productData, paymentOptions, paymentFrequencies) => {
  const { paymentOption } = productData
  const pptOptions = product.productOption.pptOptions[paymentOption]
  const idv = pptOptions.idv
  const policyTerm = pptOptions.policyTerm
  const productPremium = getPremium(product, productData, paymentOptions)
  const riders = updateRidersWithProductData(product.productOption.riders, productData, product)
  const policyOption = formData.policyOption
  let formDataPolicy = { policyOption }
  if (policyOption === 'renewExistingPolicy') {
    formDataPolicy = { ...formDataPolicy, businessOption: formData.businessOption }
  }
  const paymentFrequency = getPaymentFrequency(
    paymentFrequencies,
    productData.paymentFrequency ?? 'S'
  )
  const riderPremiums = riders.reduce((sum, ri) => {
    const premium = ri.currentPremium.premium
    if (isNotNumber(premium)) {
      return sum
    }
    return sum + premium
  }, 0)
  const totalPremium = productPremium.premium
  const totalPremiumWithGst = productPremium.premiumWithGst
  let breakInData = {}
  if (formData.insuranceType === 'car' && !isNotDefined(pptOptions.isBreakIn)) {
    breakInData = {
      isBreakIn: pptOptions.isBreakIn
    }
  }
  return {
    idv,
    policyTerm,
    riders,
    productPremium,
    totalPremium,
    totalPremiumWithGst,
    riderPremiums,
    paymentFrequency,
    ...formDataPolicy,
    ...breakInData
  }
}

const annuityDisplayData = (product, formData, productData, paymentOptions, payoutFrequencies) => {
  const payoutTerms = product.productOption.payoutTerms
  const pptOption = getPptOptionForEnquiry(product, productData, paymentOptions)
  const productPremium = getPremiumAnnuity(product, productData, paymentOptions)
  const payoutFrequency = getPayoutFrequency(
    payoutFrequencies,
    productData.payoutFrequency ?? 'S'
  )
  const defaultPayoutTerm = pptOption.defaultPayoutTerm
  let premiumKey
  payoutTerms.forEach((pot) => {
    premiumKey = `POT_${pot.categoryId}`
  })

  const validatePurchasePrice = (data) => {
    let minimum
    if (typeof (pptOption.purchasePriceRange.ppMin) === 'number') {
      minimum = pptOption.purchasePriceRange.ppMin
    }
    if (isArray(pptOption.purchasePriceRange.ppMin)) {
      const ppMinValue = pptOption.purchasePriceRange.ppMin.sort((a, b) => a.ppMin - b.ppMin)
      minimum = ppMinValue[0].ppMin
    }
    let maximum = pptOption.purchasePriceRange.ppMax
    if (maximum === 0) {
      maximum = undefined
    }
    const schema = {
      type: 'object',
      properties: {
        purchasePrice: {
          title: 'purchasePrice',
          type: 'number',
          minimum,
          maximum
        }
      }
    }
    const validate = ajv.compile(schema)
    const valid = validate(data)
    return {
      valid,
      errors: validate.errors
    }
  }
  const validateAnnuityPayout = (data) => {
    let maximum = pptOption.annuityPayoutRange.apMax
    if (maximum === 0) {
      maximum = undefined
    }
    const schema = {
      type: 'object',
      properties: {
        annuityPayout: {
          title: 'annuityPayout',
          type: 'number',
          minimum: pptOption.annuityPayoutRange.apMin,
          maximum
        }
      }
    }
    const validate = ajv.compile(schema)
    const valid = validate(data)
    return {
      valid,
      errors: validate.errors
    }
  }

  return {
    minAge: pptOption.age.minAge,
    maxAge: pptOption.age.maxAge,
    annuityPayout: productPremium.annuityPayout,
    purchasePrice: productPremium.purchasePrice,
    purchasePriceRange: pptOption.purchasePriceRange.ppMin,
    annuityPayoutRange: pptOption.annuityPayoutRange.apMin,
    premiumKey,
    pptOption,
    payoutTerms,
    defaultPayoutTerm,
    validateAnnuityPayout,
    validatePurchasePrice,
    productPremium,
    payoutFrequency,
    ...payoutTerms[0]?.dynamicFields
  }
}

const healthDisplayData = (product, formData, productData, paymentOptions, paymentFrequencies) => {
  const { paymentOption } = productData
  const { sumAssured } = formData
  const term = product.productOption.pptOptions[paymentOption].term
  const productPremium = product.productOption.pptOptions[paymentOption].premiums
  const riders = updateRidersWithProductData(product.productOption.riders, productData, product)
  const paymentFrequency = getPaymentFrequency(
    paymentFrequencies,
    productData.paymentFrequency ?? 'S'
  )
  const riderPremiums = riders.reduce((sum, ri) => {
    const premium = ri.currentPremium.premium
    if (isNotNumber(premium)) {
      return sum
    }
    return sum + premium
  }, 0)
  const totalPremium = productPremium
  const totalPremiumWithGst = productPremium
  return {
    sumAssured,
    term,
    riders,
    productPremium,
    totalPremium,
    totalPremiumWithGst,
    riderPremiums,
    paymentFrequency
  }
}

const getDisplayData = ({ product, formData, productData, paymentFrequencies, paymentOptions, payoutFrequencies }) => {
  const displayData = {
    term: termDisplayData,
    car: autoDisplayData,
    bike: autoDisplayData,
    annuity: annuityDisplayData,
    health: healthDisplayData
  }
  if (!isNotEmpty(product)) {
    return {}
  }
  const { insuranceType } = formData
  const { paymentOption, ppt } = productData
  const displayDataFun = displayData[insuranceType]
  const frequencies = insuranceType === 'annuity' ? payoutFrequencies : paymentFrequencies
  const data = displayDataFun(product, formData, productData, paymentOptions, frequencies)
  const productOptionId = product.productOption.productOptionId
  const pptOption = getPptOptionForEnquiry(product, productData, paymentOptions)
  const features = product.productOption.featureCategoryIds
  const benefits = product.productOption.benefits
  const institutionId = product.finanalyticsId
  const productName = getProductName(product)
  const imagePath = product.imageUrl
  return {
    ...data,
    ppt,
    paymentOption,
    minPpt: pptOption.ppt.minPpt,
    maxPpt: pptOption.ppt.maxPpt,
    features,
    benefits,
    institutionId,
    productName,
    productOptionId,
    imagePath,
    productId: product.productId,
    insurerId: product.productOption.insurerId,
    productInsurerId: product.insurerId,
    productBrochureUrl: product.productBrochureUrl,
    claimsSettled: product.institution.claimsSettled,
    claimsLastUpdated: product.institution.claimsLastUpdated,
    claimsSource: product.institution.claimsSource,
    pptOptions: product.productOption.pptOptions,
    riderModals: product.riderModals,
    paymentOptions
  }
}

const getProductPremium = ({ product, productData }) => {
  const productPremium = getPremium(product, productData)
  const riders = updateRidersWithProductData(product.productOption.riders, productData, product)
  const riderPremiums = riders.reduce((sum, ri) => sum + ri.currentPremium.premium, 0)
  return productPremium.premium + riderPremiums
}

const updateRidersWithProductData = (riders, productData, product) => {
  return riders.map((rider) => {
    const riderSchemaKey = getSchemaKeyForRider(product, rider.insurerId)
    return produce(rider, draft => {
      Object.keys(productData[riderSchemaKey]).forEach(key => {
        const value = rider[key] || productData[riderSchemaKey][key]
        draft.riderFormData[key] = value
      })
    })
  })
}

const getPaymentFrequency = (frequencies, frequency) => {
  if (isArray(frequencies)) {
    return frequencies.find((pf) => pf.value === frequency)
  }
  return frequencies.value === frequency && frequencies
}
const getPayoutFrequency = (frequencies, frequency) => {
  if (isArray(frequencies)) {
    return frequencies.find((pf) => pf.value === frequency)
  }
  return frequencies.value === frequency && frequencies
}

const getPremium = (product, productData, paymentOptions) => {
  const { paymentFrequency } = productData
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption || 'RP', paymentOptions)
  const pptOption = getPptOptionForEnquiry(product, productData, paymentOptions)
  if (!isNotDefined(pptOption)) {
    const { premiums, defaultPayoutTerm } = pptOption
    let resultPremium
    if (Object.prototype.hasOwnProperty.call(pptOption, 'premiums')) {
      if (paymentOption === 'SP') {
        resultPremium = premiums[`POT_${defaultPayoutTerm}`].find((pre) => pre.frequency === 'S')
      } else {
        resultPremium = premiums[`POT_${defaultPayoutTerm}`].find((pre) => pre.frequency === paymentFrequency)
      }
    } else {
      resultPremium = pptOption.premium.find((pre) => pre.frequency === 'S')
    }
    // FIXME: use immer
    return produce(resultPremium, () => {})
  } else {
    return {}
  }
}
const getPremiumAnnuity = (product, productData, paymentOptions) => {
  const { payoutFrequency } = productData
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption || 'RP', paymentOptions)
  const pptOption = getPptOptionForEnquiry(product, productData, paymentOptions)
  if (!isNotDefined(pptOption)) {
    const { premiums, defaultPayoutTerm } = pptOption
    let resultPremium
    if (Object.prototype.hasOwnProperty.call(pptOption, 'premiums')) {
      if (paymentOption === 'SP') {
        if (premiums[`POT_${defaultPayoutTerm}`] !== undefined && isArray(premiums[`POT_${defaultPayoutTerm}`])) {
          resultPremium = premiums[`POT_${defaultPayoutTerm}`].find((pre) => pre.frequency === payoutFrequency)
        } else {
          if (premiums[`POT_${defaultPayoutTerm}`] !== undefined && premiums[`POT_${defaultPayoutTerm}`].premium) {
            resultPremium = premiums[`POT_${defaultPayoutTerm}`].premium.find((pre) => pre.frequency === payoutFrequency)
          } else {
            resultPremium = premiums[`POT_${defaultPayoutTerm}`]
            if (resultPremium !== undefined && resultPremium.purchasePrice !== undefined) {
              resultPremium.purchasePrice = premiums[`POT_${defaultPayoutTerm}`].purchasePrice
              resultPremium.annuityPayout = premiums[`POT_${defaultPayoutTerm}`].annuityPayout

              delete premiums[`POT_${defaultPayoutTerm}`].pp
              delete premiums[`POT_${defaultPayoutTerm}`].ap
            } else if (resultPremium === undefined) {
              resultPremium = {
                annuityPayout: 0,
                purchasePrice: 0
              }
            }
          }
        }
      }
    } else {
      resultPremium = pptOption.premium.find((pre) => pre.frequency === 'S')
    }
    // FIXME: use immer
    return produce(resultPremium, () => {})
  } else {
    return {}
  }
}

const getPptOptionForEnquiry = (product, productData, paymentOptions) => {
  const { productOption: { pptOptions } } = product
  const paymentOption = getMatchingPaymentOption(product, productData.paymentOption || 'RP', paymentOptions)
  const pptOption = pptOptions[paymentOption]
  return pptOption
}

const getMatchingFeatureForRider = (product, riderInsurerId) => {
  return product.productOption.featureCategoryIds.find(fc => fc.insurerId === riderInsurerId)
}

const getSchemaKeyForRider = (product, riderInsurerId) => {
  const matchingFeature = getMatchingFeatureForRider(product, riderInsurerId)
  if (isNotDefined(matchingFeature.input)) {
    return null
  } else {
    if (isNotDefined(matchingFeature.input.schema)) {
      return null
    } else {
      return matchingFeature.input.schema.key
    }
  }
}
const getMaxCoverage = (product, formData, productData, paymentOptions) => {
  const pptOption = getPptOptionForEnquiry(product, productData, paymentOptions)
  if (!pptOption) {
    console.log('herer')
  }
  const { term, age } = pptOption
  const maxCoverage = (formData.age + term.maxTerm > age.maxMatAge) ? age.maxMatAge - formData.age : term.maxTerm
  return {
    maxCoverage,
    ...term,
    ...age
  }
}

const getProductName = (product) => {
  const { productOption } = product
  return {
    productName: product.productName,
    shortName: product.shortName,
    productOptionName: productOption.productOptionBasics.productOptionName
  }
}

export {
  getDisplayData,
  getProductPremium,
  getProductData,
  getSchema
}
