import { FeathersError } from '@feathersjs/errors'
class FieldNotFound extends FeathersError {
  constructor (message, data) {
    super(message, data)
    this.errorType = 'FieldNotFound'
  }
}
class InsuranceTypeNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'insuranceType not provided'
    super(errorMessage, 'InsuranceTypeNotFound', 406, 'not-acceptable', data)
  }
}
class DistributorIdNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'distributorId not provided'
    super(errorMessage, 'DistributorIdNotFound', 406, 'not-acceptable', data)
  }
}
class EnquiryIdNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'enquiryId not provided'
    super(errorMessage, 'EnquiryIdNotFound', 406, 'not-acceptable', data)
  }
}
class EnquiryNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'enquiry not provided'
    super(errorMessage, 'EnquiryNotFound', 406, 'not-acceptable', data)
  }
}
class FormDataNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'formData not provided'
    super(errorMessage, 'FormDataNotFound', 406, 'not-acceptable', data)
  }
}
class ProductOptionIdNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'productOptionId not provided'
    super(errorMessage, 'ProductOptionIdNotFound', 406, 'not-acceptable', data)
  }
}
class ProductIdNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'productId not provided'
    super(errorMessage, 'ProductIdNotFound', 406, 'not-acceptable', data)
  }
}
class InstitutionIdNotfound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'institutionId not provided'
    super(errorMessage, 'InstitutionIdNotfound', 406, 'not-acceptable', data)
  }
}
class UiConfigNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'uiConfig not provided'
    super(errorMessage, 'UiConfigNotFound', 406, 'not-acceptable', data)
  }
}
class DataNotFound extends FieldNotFound {
  constructor (message, data) {
    const errorMessage = message || 'data is not provided'
    super(errorMessage, 'DataNotFound', 406, 'not-acceptable', data)
  }
}
export default {
  insuranceType: InsuranceTypeNotFound,
  distributorId: DistributorIdNotFound,
  enquiryId: EnquiryIdNotFound,
  EnquiryIdNotFound,
  enquiry: EnquiryNotFound,
  formData: FormDataNotFound,
  productOptionId: ProductOptionIdNotFound,
  productId: ProductIdNotFound,
  institutionId: InstitutionIdNotfound,
  uiConfig: UiConfigNotFound,
  Default: FieldNotFound,
  Data: DataNotFound
}
