
import { Card, Checkbox, Form } from 'semantic-ui-react'
import selfImage from './self-inspection.png'
import surveyorImage from './surveyor-insoection.png'
import './InspectionMethodWidget.scss'

const images = {
  self: selfImage,
  surveyor: surveyorImage
}
const InspectionMethodWidget = (props) => {
  const {
    id,
    value,
    disabled,
    onBlur,
    onFocus,
    options,
    required,
    onChange,
    readonly
  } = props
  const { enumOptions } = options
  const radioOptions = enumOptions.map(({ value, label }, i) => (
    { key: i, value, text: label }
  ))
  let disabledValue = disabled
  if (!disabledValue) {
    disabledValue = options.disabled
  }

  const renderLabel = (title) => (
    <label>
      <div id='card-label'>{title}</div>
    </label>
  )

  return (
    <div>
      <Form.Field id={`${id}-field`}>
        {radioOptions.map((radio, index) => {
          const { text, value: radioValue } = radio
          const selected = radioValue === value
          return (
            <Card key={index} className={`inspectionMethodWidget${selected ? ' selected' : ''}`}>
              <Card.Content>
                {selected &&
                  <div className='chip' title='selected'>
                    <i className='fa fa-check' />
                  </div>}
                <img src={images[radioValue]} alt='self inspection' />
                <Checkbox
                  id={`${id}-${radioValue}`}
                  key={`checkbox${index}`}
                  radio
                  label={renderLabel(text)}
                  name={`${id}_radioGroup`}
                  className='cardWidgetBtn'
                  value={radioValue}
                  checked={selected}
                  onChange={(_e, { value }) => onChange(value)}
                  disabled={(options.enumDisabled || [])?.some(v => v === radioValue) || disabledValue}
                  required={required}
                  readOnly={readonly}
                  onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
                  onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
                />
              </Card.Content>
            </Card>
          )
        })}
      </Form.Field>
    </div>
  )
}
export default InspectionMethodWidget
