import { useEffect } from 'react'
import { Image } from 'semantic-ui-react'
import Loading from 'components/Loading'
import { productOptionComposer } from './product/productOptionComposer'
import { getDisplayData, getProductPremium } from '../helper/productHelper'
import { StickyContainer, Sticky } from 'react-sticky'
import { ErrorContext } from 'components/context/ErrorContext'
import RcSteps from 'components/RcSteps'
// import 'components/css/poConfig.scss'
import { buildCommonImagePath } from 'components/buildImagePath'
import TransitionGroup from 'react-transition-group/TransitionGroup'
import TransitionComponent from 'components/TransitionComponent'
import { isNotEmpty } from 'utils'
import { useInitialDataLoader, useSetFormData, useSetChosenProduct } from 'components/customHooks'
const ProductOptionConfig = (props) => {
  const {
    insuranceType
  } = props
  const { loadingConfigure, configure } = useInitialDataLoader(insuranceType, props.enquiryId, props.distributorId)
  const [{ loadingSetFormData }, setFormData] = useSetFormData(insuranceType, loadingConfigure)
  const [chosenProductLoading, setChosenProduct] = useSetChosenProduct(insuranceType, loadingConfigure, loadingSetFormData)
  useEffect(() => {
    setFormData({ formData: {} }, true)
    setChosenProduct({})
  },
  // eslint-disable-next-line
  [ ])
  useEffect(() => {
    if (props.featureForAction.askForRiders) {
      const { routeProps: { location: { pathname, state } }, redirectToPath } = props
      const backPath = pathname
      let path = `/${insuranceType}/select-product` // backward skip
      if (isNotEmpty(state) && state.backpath.includes('select-product')) {
        path = `/${insuranceType}/product-config` // forward skip
      }
      redirectToPath(
        path,
        backPath
      )
    }
  },
  // eslint-disable-next-line
  [ props.featureForAction.askForRiders ])

  const onBack = () => {
    const { routeProps, redirectToPath } = props
    const backPath = routeProps.location.pathname
    const path = `/${insuranceType}/select-product`
    redirectToPath(
      path,
      backPath
    )
  }
  const onRiderChange = async (data) => {
    const { setProductData } = props
    setProductData(data)
  }
  const onFeatureChange = (schemaKey, data) => {
    const { chosenProductMetadata: { productId, productOptionId }, setFeatureChoiceForChosenProduct } = props
    setFeatureChoiceForChosenProduct({
      schemaKey,
      data,
      productId,
      productOptionId
    })
  }
  const {
    chosenProduct,
    nextProduct,
    featureForAction: {
      selectedFeature,
      selectedRider,
      productDataPath,
      spProductData,
      askForRiders
    },
    formData,
    productData,
    device,
    loading
  } = props
  if (loadingConfigure) {
    return <Loading id='loading_config' open />
  }
  if (loadingSetFormData) {
    return <Loading id='loading_products' open />
  }
  if (chosenProductLoading || !isNotEmpty(chosenProduct)) {
    return <Loading id='loading_selected_product' open />
  }
  if (askForRiders) {
    return <Loading id='loading_for_riders' open />
  }
  const product = getDisplayData({
    product: chosenProduct,
    formData,
    productData,
    paymentFrequencies: configure.common.paymentFrequencies,
    paymentOptions: configure.productListing.paymentOptionsPriority
  })
  const featureAmount = getProductPremium({ product: nextProduct, formData, productData }) - product.totalPremium
  // FIXME: Need product Option Composer
  const ComponentMap = productOptionComposer({
    product,
    onFeatureChange,
    onRiderChange,
    onBack,
    onNext: props.setChosenProduct,
    formData,
    device,
    spProductData,
    productData,
    selectedFeature,
    selectedRider,
    productDataPath,
    loading,
    contentType: 'poreview',
    className: 'flexItem',
    featureAmount,
    config: configure.productOptionConfig
  })
  const productOptionId = product.productOptionId
  // convert the following to list
  const { progressBarSteps } = configure.productOptionConfig
  return (
    <StickyContainer
      className='productOptionConfig'
      id={`${productOptionId}_productoption_config`}
    >
      <Sticky topOffset={-80}>
        {
          ({
            style,
            isSticky
          }) => {
            const className = `${isSticky && 'sticky'} poHeader`
            return (
              <div
                style={style}
                className={className}
              >
                <RcSteps steps={progressBarSteps} />
              </div>
            )
          }
        }
      </Sticky>
      <div className='flexContainer alignFlexItems poContainer'>
        <Image size='mini' src={buildCommonImagePath('sc-slider-left.png')} />
        <TransitionGroup className='flexContainer'>
          <TransitionComponent duration={500} keyId={`productOptionId-${product.productOptionId}_featureCategoryId-${selectedFeature.categoryId}`}>
            <div>
              <div className='poContent'>
                <div className='poContentHeader flexContainer'>
                  {ComponentMap.InsurerWithLogo}
                  {ComponentMap.ProductBrochure}
                  {ComponentMap.Premium}
                </div>
                {ComponentMap.Feature}
              </div>
            </div>
          </TransitionComponent>
        </TransitionGroup>
        <Image size='mini' src={buildCommonImagePath('sc-slider-right.png')} />
      </div>
    </StickyContainer>
  )
}
ProductOptionConfig.contextType = ErrorContext
export default ProductOptionConfig
