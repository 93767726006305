import { useRef, useEffect, useState } from 'react'
import { List, Segment, ListItem } from 'semantic-ui-react'
import { Subtitle2, H6, Body1, Caption } from 'components/Header'
import SumAssuredAndStatus from './SumAssuredAndStatus'
import ProductImageAndNameWithDescription from './ProductImageAndNameWithDescription'

// import '../dashBoard.scss'
import 'components/css/loader.scss'
import TableComponent from 'components/TableComponent'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

function ApplicationListing (props) {
  const {
    isLoggedIn,
    loadApplications,
    loadingApplications,
    redirectToPath,
    insuranceType,
    // loadingStatus,
    // loadingModal,
    applications,
    // getFormStepStatus,
    // continueHandler,
    // getStatusHandler,
    updateSelectedApplicationNumber,
    count
  } = props
  const selectorItemData = { data: applications, count }
  const [pageRow, setPageRow] = useState(10)
  const [pageChange, setPageChange] = useState(0)
  const [skipPage, setSkipPage] = useState(0)
  const navigate = useNavigate()

  const previousLoading = useRef(false)
  useEffect(() => {
    if (isLoggedIn) {
      loadApplications({ skip: skipPage, limit: pageRow, iamId: '3a771af5-dcc9-4365-b5b9-00204da219d4' })
    }
  },
  // eslint-disable-next-line
  [pageChange, pageRow])

  const configureDate = (date) => {
    const dateObject = new Date(date)
    const day = dateObject.getUTCDate().toString().padStart(2, '0')
    const month = (dateObject.getUTCMonth() + 1).toString().padStart(2, '0')
    const year = dateObject.getUTCFullYear()
    return `${day}/${month}/${year}`
  }

  const renderTable = () => {
    return (
      <TableComponent
        {...props}
        selectorItemData={selectorItemData}
        pageRow={pageRow}
        pageChange={pageChange}
        setSkipPage={setSkipPage}
        setPageRow={setPageRow}
        setPageChange={setPageChange}
      />
    )
  }

  const handleBack = () => {
    navigate(-1)
  }

  useEffect(() => {
    previousLoading.current = loadingApplications
  }, [loadingApplications])
  let loading
  let content
  if (!(previousLoading.current)) {
    loading = (
      <div className='loaderOuter applicationLoader' id='loading'>
        <div className='ball-spin-fade-loader'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  } else {
    content = applications.length === 0
      ? (
            <div className='noOngoingApplications'>
              <Subtitle2 color='secondary'>No Ongoing Applications</Subtitle2>
            </div>
        )
      : (
            <>
              <List>
                {applications.map((application, index) => {
                  const applicationStatus = application.claimStatus
                  return (
                    <div key={index}>
                      <ListItem className='listingItems'>
                        <ProductImageAndNameWithDescription data={application} />
                        <SumAssuredAndStatus data={application} />
                        <div className='secondHalf'>
                        <div className='sumAssuredValue'>
                          <Caption>Patient Name</Caption>
                          <Body1 className='body1'>{application.claimantName}</Body1>
                        </div>
                        <div className='Status'>
                        <Caption>Registration Date</Caption>
                          <Body1>{configureDate(application.claimRegistrationDate)}</Body1>
                        </div>
                        </div>
                        <div className='applicationDetailsButton'>
                        <div className={applicationStatus === 'Settled' ? 'continueButtonDisable' : 'continueButton w-100'}>
                          <Subtitle2 onClick={() => redirectToPath(`/${insuranceType}/claim-registration`)} color='secondary' className='subtitle2Btn'>
                            Continue
                          </Subtitle2>
                        </div>
                          <div className='w-100' onClick={() => updateSelectedApplicationNumber({ applicationNumber: application.intimationNo })}>
                            <Subtitle2 color='secondary' className='subtitle2Btn'>
                              VIEW
                            </Subtitle2>
                          </div>
                        </div>
                        {/* ----- Button Part ---- */}
                      </ListItem>
                      <hr hidden={index + 1 === applications.length} />
                    </div>
                  )
                }
                )}
              </List>
              {(renderTable())}
            </>
        )
  }
  return (
    <>
        <Segment className={(!(previousLoading.current)) ? 'userApplication loaderParent' : 'userApplication'}>
          <H6 color='primaryLight' className='h6' content='Your Applications' />
          {loading}
          {content}
        </Segment>
        <Button id='bottom-back-btn' className='mx-2' variant='contained' onClick={handleBack} >
              BACK
        </Button>
    </>
  )
}

export default ApplicationListing
