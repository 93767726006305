import { Component } from 'react'
import { Image } from 'semantic-ui-react'
import { Caption } from 'components/Header'
import { isNotDefined } from 'utils'
import { buildCommonImagePath } from 'components/buildImagePath'
class FormHint extends Component {
  drawField (subheading, image, isError, id) {
    return (
      <Caption
        className='errorHolder error'
        id={`${id}_error`}
        color={isError && 'red'}
      >
        <Image src={buildCommonImagePath(image)} className='size10' />
        {subheading}
      </Caption>
    )
  }

  errorHolder () {
    return (
      <Caption className='errorHolder' />
    )
  }

  render () {
    const { showerror, rawErrors, hint, id } = this.props
    const title = this.props.title || ''
    if (isNotDefined(hint) && !showerror && (id !== 'root')) {
      return this.errorHolder()
    }
    return (
      <>
        {hint
          ? this.drawField(hint, 'help-icon.png', false)
          : null}
        {showerror ? this.drawField(`${title} ${rawErrors[0]}`, 'error-red.png', true, id) : null}
      </>
    )
  }
}
export default FormHint
