import { dependency } from '../schema'
export const getSchemaForCurrentStep = (schema, steps, selectedPath, currentStep) => {
  const stepInfo = steps[selectedPath]?.steps[currentStep]
  const fieldsForStepInfo = stepInfo?.fields || []
  const properties = {}
  const requiredFields = []
  if (stepInfo && currentStep === 0) {
    fieldsForStepInfo.forEach((field) => {
      properties[field] = schema.properties[field]
    })
    requiredFields.push(...Object.keys(properties))
    const dependencies = JSON.parse(JSON.stringify(dependency))
    return {
      type: 'object',
      title: 'Upload Documents',
      properties,
      dependencies
    }
  }
  // default claimRegistration property
  properties.claimType = schema.properties.claimType
  properties.claimReason = schema.properties.claimReason
  requiredFields.push(...Object.keys(properties))
  const dependencies = JSON.parse(JSON.stringify(dependency))
  return {
    type: 'object',
    title: 'Claim Registration',
    properties,
    required: [...requiredFields],
    dependencies
  }
}

export const getUISchemaForCurrentStep = (UiSchema, steps, selectedPath, currentStep) => {
  const stepInfo = steps[selectedPath]?.steps[currentStep]
  const fieldsForStepInfo = stepInfo?.fields || []
  const uiSchema = {}

  if (stepInfo) {
    fieldsForStepInfo.forEach((field) => {
      uiSchema[field] = UiSchema[field]
    })
  } else {
    // default claimRegistration property
    uiSchema.claimRegistration = UiSchema.claimRegistration
  }

  return uiSchema
}
