import { connect } from 'react-redux'
import CustomerHoldings from './components/CustomerHoldings'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    distributorId: models.configure.getDistributorId,
    customerDetails: models.customer.getCustomerDetails,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    availableProducts: models.configure.getAvailableProducts
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    // loading is given seperately because if we build loading object and pass, it will be considered as different prop everytime
    ...ownProps,
    ...selectorData,
    loadingFeatures: state.loading.effects.applications.loadFeatures,
    loadingProductData: state.loading.effects.insuranceProducts.loadProducts,
    loadingEnquiry:
      state.loading.effects.insuranceEnquiry.updateEnquiryWithPolicyDetails,
    insuranceType,
    store: ownProps.store
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: dispatch.customer.logout
  }
}
const CustomerHoldingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerHoldings)
CustomerHoldingsContainer.path = 'customer-holdings'
export default CustomerHoldingsContainer
