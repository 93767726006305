
import { Grid } from 'semantic-ui-react'
import { isNotDefined } from 'utils'
import { productComposer } from '../product/bookingIndex'
import 'components/css/policySummary.css'
import GstDisclaimer from 'components/GstDisclaimer/GstDisclaimer'
const PolicySummary = (props) => {
  const { termPolicySummaryDisplay } = props
  const { gstDisclaimer } = props
  const ComponentMap = productComposer({
    ...props
  })
  if (isNotDefined(termPolicySummaryDisplay)) {
    return <div />
  }
  const elements = termPolicySummaryDisplay.map((elements, ix) => {
    // FIXME: shouldnt be styled this way
    const componentStyle = {}
    if (ix > 0 && (termPolicySummaryDisplay[ix - 1].length > 1) && (elements.length > 1)) {
      componentStyle.paddingTop = 0
    }
    return (
      <Grid.Row columns='equal' className='policySummary' style={componentStyle} key={`polsum${ix}`}>
        {
          elements.map((element, ix) => {
            const Element = ComponentMap[element]
            return (
              <Grid.Column key={`elem${ix}`}>
                <Element {...props} />
              </Grid.Column>
            )
          })
        }
      </Grid.Row>
    )
  })
  return (
    <Grid stackable columns='equal'>
      {elements}
      {gstDisclaimer && <GstDisclaimer gstDisclaimer={gstDisclaimer} />}
    </Grid>
  )
}

export default PolicySummary
