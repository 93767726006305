import DashboardDownload from './DashboardDownload'
/* eslint react/jsx-handler-names: "off" */
const DownloadOptions = (props) => {
  const downloadButtonArray = [
    { buttonTitle: 'PDF', buttonVal: 'pdf' },
    { buttonTitle: 'XLS', buttonVal: 'xls' },
    { buttonTitle: 'CSV', buttonVal: 'csv' }
  ]
  const { fileName, displayRange, selectedRangeValue, setSelectedRangeValue } = props

  return (
    <div className='downloadRangeContainer'>
      <div className='dataRange'>
        <label>
          Choose Data Range:
        </label>
        <select
          name='downloadReportRange'
          id='downloadReportRange'
          value={selectedRangeValue}
          onChange={e => setSelectedRangeValue(e.target.value)}
          size='3'
          style={{ maxHeight: '60px', scrollMarginBottom: '2em' }}
        >
          {displayRange().map(x => (
            <option key={`range-${x.value}`} value={x.value}>
              {x.text}
            </option>
          )
          )}
        </select>
      </div>
      <div className='downloadOptions'>
        {downloadButtonArray.map(ele => (
          <DashboardDownload
            key={ele.buttonVal}
            skipValue={selectedRangeValue}
            onClick={props.downloadClick}
            buttonVal={ele.buttonVal}
            fileName={fileName}
            buttonTitle={ele.buttonTitle}
          />
        ))}
      </div>
    </div>
  )
}
export default DownloadOptions
