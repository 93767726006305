
import produce from 'immer'
import { isEmpty, isNotEmpty, isNotDefined, has, findIndex, isArray } from 'utils'
import {

  appendProductsToList,
  buildToTemplate,
  effectsHandler
} from './modelHelpers'
import productSchema from './productSchema'
import displayTemplate from '../../templates/displayTemplate.json'
import {
  getPremiumForPayoutTerm,
  getPremiumForPayoutTermForAnnuity
} from './helpers/productHelper'
import {
  setFeatureChoiceForChosenProduct,
  setChosenProduct,
  loadProducts,
  loadChosenProducts,
  loadFeatures,
  updateProductsWithQuotes,
  resetChosenProduct,
  updateChosenProductData,
  changeChosenProduct,
  setChosenProductForBooking
} from './insuranceProductsEffects'

/*
  chosenProduct = {
    spProductData,
    productOptionId,
    productId,
    productOptionInsurerId,
    nextProductOptionInsurerId,
    actions: {
      nextAction: {
        categoryId: 12,
        productDataPath: 'path'
      },
      askForRiders: true / false
    }
  }
*/
// Product object and its state
// FIXME: Ordering insuranceProducts
/* DO this while writing selectors
  // Order insuranceProducts according to filter
  if (isNotEmpty(insuranceEnquiry.getFilter())) {
    flattenedProducts = flattenedProducts.sort((fp1, fp2) => fp2.filter.rank - fp1.fp2.filter.rank)
  } else {
    flattenedProducts = flattenedProducts.sort((p1, p2) => p1.getPremium().premium - p2.getPremium().premium)
  }
*/

/**
   * Builds the plainProducts and normalizedProducts from flattenedProducts. Then it builds the issuerProucts
   * from plainProducts.
   *
   * @param {*} state
   * @param {*} insuranceType
   * @param {*} flattenedProducts
   * @param {*} productInsurerId
   * @param {*} institutionId
   * @returns object
   */
const buildProductState = (state, insuranceType, flattenedProducts, productInsurerId, institutionId, formData) => {
  if (isArray(flattenedProducts)) {
    // Immerise plainProducts so that selectProduct always works
    const plainProducts = appendProductsToList(state[insuranceType].plainProducts, flattenedProducts, productInsurerId)
    state[insuranceType].plainProducts = plainProducts
    const normalizedProducts = state[insuranceType].normalizedProducts
    if (insuranceType === 'annuity') {
      const { applicationType } = formData
      state[insuranceType].normalizedProducts = buildToTemplate(displayTemplate.displayOrder[insuranceType][applicationType], plainProducts, institutionId, normalizedProducts, formData)
    } else if (['car', 'bike', 'health'].includes(insuranceType)) {
      const { policyType } = formData
      state[insuranceType].normalizedProducts = buildToTemplate(displayTemplate.displayOrder[insuranceType][policyType], plainProducts, institutionId, normalizedProducts, formData)
    } else {
      state[insuranceType].normalizedProducts = buildToTemplate(displayTemplate.displayOrder[insuranceType], plainProducts, institutionId, normalizedProducts, formData)
    }
    // to show title header only for available products
    Object.keys(state[insuranceType].normalizedProducts).map(item => {
      state[insuranceType].normalizedProducts[item].items.map(perItem => {
        perItem.choices.map(choice => {
          return state[insuranceType].plainProducts.find(data => {
            if (data.productOption.insurerId === choice.productOptionId) {
              state[insuranceType].normalizedProducts[item].shouldTitleDisplay = true
              return data
            }
          })
        })
      })
    })
  }
  // build the issuers product mapping for ease of use
  // FIXME: not required
  state[insuranceType].issuerProducts = state[insuranceType].plainProducts.reduce((obj, pp) => {
    const { finanalyticsId, insurerId, productId } = pp
    if (has(obj, pp.finanalyticsId)) {
      if (findIndex(obj[finanalyticsId], (o) => o.insurerId === insurerId) === -1) {
        obj[finanalyticsId].push({ insurerId, productId })
      }
    } else {
      obj[finanalyticsId] = [{ insurerId, productId }]
    }
    return obj
  }, {})
  return state
}
const insuranceProducts = {
  name: 'insuranceProducts',
  state: {
    term: {
      features: [],
      payouts: [],
      products: {}, // {'institutionId': [Products]} // contains product details and no quote information
      // Array of flattened insuranceProducts. Each product is one product-productOption combo
      plainProducts: [],
      // See productSchema to normalize product according to schema
      normalizedProducts: {
      },
      // The selected product by the user
      chosenProduct: {
      },
      // This is the product UI schema - normalized schema
      productSchema,
      // List of issuers and its issuerIds.
      // {'in-rere': [{productId: 1223, insurerId: 'ded'}]}
      // This is different from issuers from config. Config contains all issuers
      // this contains issuers for the current insuranceProducts after filter etd.
      issuerProducts: {

      }
    },
    car: {
      features: [],
      payouts: [],
      products: {},
      plainProducts: [],
      normalizedProducts: {},
      chosenProduct: {},
      issuerProducts: {}
    },
    bike: {
      features: [],
      payouts: [],
      products: {},
      plainProducts: [],
      normalizedProducts: {},
      chosenProduct: {},
      issuerProducts: {}
    },
    health: {
      features: [],
      payouts: [],
      products: {},
      plainProducts: [],
      normalizedProducts: {},
      chosenProduct: {},
      issuerProducts: {}
    },
    annuity: {
      features: [],
      payouts: [],
      products: {},
      plainProducts: [],
      normalizedProducts: {},
      chosenProduct: {},
      issuerProducts: {}
    }
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Checks if plainProducts is built or not
     *
     * @returns boolean
     */
    isPartialLoadComplete () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, insuranceType) => {
          return insuranceProducts[insuranceType].plainProducts.length > 0
        }
      )
    },

    /**
     * Gets product by id. Product is first restructured with activeRiders, activePayoutTerms and
     * pptOptions
     *
     *
     * @returns object
     */
    getProductById () {
      return createSelector(
        slice,
        (rootState, { insurerId, productOptionId, insuranceType }) => ({
          productOptionId,
          insuranceType,
          insurerId,
          enquiry: rootState.insuranceEnquiry[insuranceType]
        }),
        (insuranceProducts, { productOptionId, insurerId, insuranceType, enquiry }) => {
          // ProductOptionId and insurerId are the same. We will phase out productOptionId in favor of insurerId
          if (isNotDefined(insurerId)) {
            insurerId = productOptionId
          }
          if (!isEmpty(insuranceProducts)) {
            const product = insuranceProducts[insuranceType].plainProducts.find(po => po.productOption.insurerId === insurerId)
            if (isNotDefined(product)) {
              return {}
            }
            const { displaySpecs } = displayTemplate
            const newProduct = produce(product, (draft) => {
              // First replace riders with activeRiders ( built in configureForDisplay)
              delete draft.productOption.riders
              draft.productOption.riders = draft.productOption.activeRiders
              delete draft.productOption.activeRiders
              // do the same for payout terms
              delete draft.productOption.payoutTerms
              if (!isNotDefined(draft.productOption.activePayoutTerms)) {
                draft.productOption.payoutTerms = draft.productOption.activePayoutTerms
              }
              delete draft.productOption.activePayoutTerms
              const { pptOptions } = draft.productOption
              Object.keys(pptOptions).forEach(paymentOption => {
                const pptOption = pptOptions[paymentOption]
                if (!isNotDefined(displaySpecs[insurerId])) {
                  if (!isNotDefined(displaySpecs[insurerId].defaultPayoutTerm) && displaySpecs[insurerId].defaultPayoutTerm.toString().length > 0) {
                    pptOption.defaultPayoutTerm = displaySpecs[insurerId].defaultPayoutTerm || pptOption.defaultPayoutTerm
                  }
                }
                if (pptOption.hasOwnProperty('defaultPayoutTerm')) {
                  const { defaultPayoutTerm } = pptOption
                  if (pptOption.hasOwnProperty('annuityPayout') && pptOption.hasOwnProperty('purchasePrice')) {
                    pptOption.premiums[`POT_${defaultPayoutTerm}`] = getPremiumForPayoutTermForAnnuity(
                      enquiry.productData,
                      pptOption.premiums[`POT_${defaultPayoutTerm}`],
                      defaultPayoutTerm
                    )
                  } else {
                    pptOption.premiums[`POT_${defaultPayoutTerm}`] = getPremiumForPayoutTerm(
                      enquiry.productData,
                      pptOption.premiums[`POT_${defaultPayoutTerm}`],
                      defaultPayoutTerm
                    )
                  }
                }
              })
            })
            return newProduct
          } else {
            return {}
          }
        }
      )
    },

    /**
     * Gets product by insurerId. Product is first restructured with activeRiders, activePayoutTerms
     *
     * @returns object
     */
    getProductMetaDataByInsurerId () {
      return createSelector(
        slice,
        (rootState, { insurerId }) => insurerId,
        (rootState, { insuranceType }) => insuranceType,
        (rootState, { instituionId }) => instituionId,
        (insuranceProducts, insurerId, insuranceType, instituionId) => {
          if (!isEmpty(insuranceProducts)) {
            const institutionProducts = insuranceProducts[insuranceType].products[instituionId]
            if (!isNotEmpty(institutionProducts)) {
              return {}
            }
            const product = institutionProducts.find(po => po.productOption.insurerId === insurerId)
            if (isNotDefined(product)) {
              return {}
            }
            const newProduct = produce(product, (draft) => {
              // First replace riders with activeRiders ( built in configureForDisplay)
              delete draft.productOption.riders
              draft.productOption.riders = draft.productOption.activeRiders
              delete draft.productOption.activeRiders
              // do the same for payout terms
              delete draft.productOption.payoutTerms
              draft.productOption.payoutTerms = draft.productOption.activePayoutTerms
              delete draft.productOption.activePayoutTerms
            })
            return newProduct
          } else {
            return {}
          }
        }
      )
    },

    /**
     * Gets data in normalizedProducts
     *
     * @param {*} models
     * @returns
     */
    getAllNormalizedProducts (models) {
      // FIXME: Normalizer not working here. Just hardcoding for UI purposes
      return createSelector(
        slice,
        (rootState, { insuranceType }) => {
          return rootState.insuranceConfigure[insuranceType].productConfig
        },
        (rootState, { insuranceType }) => insuranceType,
        (rootState) => !rootState.loading.effects.insuranceEnquiry.setEnquiryData,
        (insuranceProducts, productConfig, insuranceType, setEnquiryDone) => {
          if (isNotDefined(productConfig) || isEmpty(insuranceProducts[insuranceType].plainProducts)) {
            return {}
          } else {
            return insuranceProducts[insuranceType].normalizedProducts
          }
        })
    },

    /**
     * Gets all plainProducts by filtering grouping with institutionId
     *
     * @param {*} models
     * @returns
     */
    getAllProducts (models) {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => {
          return rootState.insuranceConfigure[insuranceType].productConfig
        },
        (rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, productConfig, insuranceType) => {
          if (isEmpty(insuranceProducts[insuranceType].plainProducts)) {
            return {}
          } else {
            const { instituteOrder } = productConfig
            const allProducts = {}
            const plainProducts = insuranceProducts[insuranceType].plainProducts
            instituteOrder.forEach((institutionId) => {
              allProducts[institutionId] = plainProducts
                .filter(po => po.finanalyticsId === institutionId)
                .map(po => ({
                  pptOptions: po.productOption.pptOptions, // Added pptOptions to use products in filterMenu
                  instituionId: po.finanalyticsId,
                  productOptionId: po.productOption.productOptionId,
                  features: po.productOption.featureCategoryIds
                }))
            })
            return allProducts
          }
        })
    },

    /**
     * Gets the chosenProduct from the state. spProductData is deleted from the chosenProduct before
     * it's returned
     *
     * @returns
     */
    getProductSelectionDetails () {
      // FIXME: to be modified !! used only for product option config page !! change this later !
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, insuranceType) => {
          const chosenProduct = insuranceProducts[insuranceType].chosenProduct
          if (isNotEmpty(chosenProduct)) {
            const { chosenProductOptionsDetails } = chosenProduct
            return produce(chosenProductOptionsDetails, (draft) => {
              // delete productData and return rest of the product details
              draft.forEach(po => {
                delete po.spProductData
              })
            })
            // if (!isNotDefined(chosenProductOption)) {
            //   return {
            //     productOptionInsurerId: chosenProductOption.productOptionInsurerId,
            //     productOptionId: chosenProductOption.productOptionId,
            //     productId: chosenProductOption.productId,
            //     instituionId: chosenProductOption.instituionId,
            //     nextProductOptionInsurerId: chosenProductOption.nextProductOptionInsurerId
            //   }
            // } else {
            //   return {}
            // }
          } else {
            return {}
          }
        })
    },

    /**
     * Gets chosenProduct with activeRiders, activePayoutTerms and pptOptions from the state
     *
     * @returns
     */
    getChosenProduct () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (rootState, { insurerId }) => insurerId,
        (rootState, { insuranceType }) => rootState.insuranceEnquiry[insuranceType],
        (insuranceProducts, insuranceType, insurerId, enquiry) => {
          const { chosenProduct } = insuranceProducts[insuranceType]
          if (!isNotEmpty(chosenProduct)) {
            return {}
          }
          const chosenProductInsurerId = insurerId || chosenProduct.chosenProductInsurerId
          if (!isNotDefined(chosenProductInsurerId)) {
            const productOptionDetails = chosenProduct.chosenProductOptionsDetails.find(po => po.productOptionInsurerId === chosenProductInsurerId)
            // find the selected product details
            // const chosenProductObject = insuranceProducts[insuranceType].plainProducts.find(p => p.productOption.productOptionId === chosenProductInsurerId)
            const { displaySpecs } = displayTemplate
            if (!isNotDefined(productOptionDetails) && isNotEmpty(chosenProduct.product)) {
              const newProduct = produce(chosenProduct.product, (draft) => {
                draft.spFormData = chosenProduct.chosenProductFormData
                draft.spProductData = productOptionDetails.spProductData
                // First replace riders with activeRiders ( built in configureForDisplay)
                delete draft.productOption.riders
                draft.productOption.riders = draft.productOption.activeRiders
                delete draft.productOption.activeRiders
                // do the same for payout terms
                delete draft.productOption.payoutTerms
                draft.productOption.payoutTerms = draft.productOption.activePayoutTerms
                delete draft.productOption.activePayoutTerms
                delete draft.productOption.activePayoutTerms
                const { pptOptions } = draft.productOption
                Object.keys(pptOptions).forEach(paymentOption => {
                  const pptOption = pptOptions[paymentOption]
                  if (!isNotDefined(displaySpecs[chosenProductInsurerId])) {
                    // what does this do ?
                    if (!isNotDefined(displaySpecs[chosenProductInsurerId].defaultPayoutTerm) && displaySpecs[chosenProductInsurerId].defaultPayoutTerm.toString().length > 0) {
                      pptOption.defaultPayoutTerm = draft.spProductData.payoutType || displaySpecs[insurerId].defaultPayoutTerm || pptOption.defaultPayoutTerm
                    }
                  }
                  if (pptOption.hasOwnProperty('defaultPayoutTerm') || draft.spProductData.payoutType) {
                    const defaultPayoutTerm = draft.spProductData.payoutType || pptOption.defaultPayoutTerm
                    if (pptOption.hasOwnProperty('annuityPayout') && pptOption.hasOwnProperty('purchasePrice')) {
                      pptOption.premiums[`POT_${defaultPayoutTerm}`] = getPremiumForPayoutTermForAnnuity(
                        draft.spProductData,
                        pptOption.premiums[`POT_${defaultPayoutTerm}`],
                        defaultPayoutTerm
                      )
                    } else {
                      pptOption.premiums[`POT_${defaultPayoutTerm}`] = getPremiumForPayoutTerm(
                        draft.spProductData,
                        pptOption.premiums[`POT_${defaultPayoutTerm}`],
                        defaultPayoutTerm
                      )
                    }
                    // Imortant to reset the default payout term in pptOption
                    pptOption.defaultPayoutTerm = defaultPayoutTerm
                  }
                })
              })
              return newProduct
            } else {
              return {}
            }
          } else {
            return {}
          }
        })
    },

    /**
     * Gets selectedFeature, selectedRider, productDataPath, askForRiders and spProductData
     * from the state
     *
     * @returns
     */
    getFeatureForAction () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, insuranceType) => {
          const { chosenProduct, plainProducts } = insuranceProducts[insuranceType]
          if (!isNotEmpty(chosenProduct)) {
            return {}
          }
          const { actions, spProductData, nextProductOptionInsurerId } = chosenProduct
          const { nextAction, askForRiders } = actions

          if (askForRiders) {
            return {
              selectedFeature: {},
              selectedRider: {},
              productDataPath: '',
              askForRiders,
              spProductData
            }
            // Then this is askForRiders
          } else {
            if (isNotDefined(actions) || !isNotEmpty(actions)) {
              console.log('here')
            }
            const { categoryId, productDataPath } = nextAction
            const chosenProduct = plainProducts.find(p => p.productOption.insurerId === nextProductOptionInsurerId)
            const selectedFeature = chosenProduct.productOption.featureCategoryIds.find(fc => fc.categoryId === categoryId)
            const riderInsurerId = selectedFeature.insurerId
            const selectedRider = chosenProduct.productOption.activeRiders.find(rider => rider.insurerId === riderInsurerId)
            return {
              selectedFeature,
              selectedRider,
              productDataPath,
              askForRiders: false,
              spProductData
            }
          }
        }
      )
    },

    getFeatures () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, insuranceType) => {
          const { features } = insuranceProducts[insuranceType]
          return features
        }
      )
    },

    getPptOptions () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => rootState.insuranceConfigure[insuranceType].productConfig,
        (_rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, productConfig, insuranceType) => {
          if (isEmpty(insuranceProducts[insuranceType].plainProducts)) {
            return {}
          } else {
            const { instituteOrder } = productConfig
            const pptOptions = {}
            const plainProducts = insuranceProducts[insuranceType].plainProducts
            instituteOrder.forEach((institutionId) => {
              pptOptions[institutionId] = plainProducts
                .filter(po => po.finanalyticsId === institutionId)
                .map(po => ({
                  pptOptions: po.productOption.pptOptions
                }))
            })
            return pptOptions
          }
        }
      )
    },

    /**
     * Gets selectedFeature
     * from the state
     *
     * @returns
     */
    getSelectedFeatures () {
      return createSelector(
        slice,
        (rootState, { insuranceType }) => insuranceType,
        (insuranceProducts, insuranceType) => {
          return insuranceProducts[insuranceType].selectedFeatures
        }
      )
    }
  }),
  reducers: {

    /**
     * sets issuerProducts in the state
     *
     * @param {*} state
     * @param {*} { productConfig, insuranceType }
     * @returns object
     */
    'insuranceConfigure/updateConfigData' (state, { productConfig, insuranceType }) {
      if (!isNotDefined(productConfig)) {
        state[insuranceType].issuerProducts = productConfig.insurers
      }
      return state
    },

    /**
     * sets features, payouts and featureContent in the state
     *
     * @param {*} state
     * @param {*} { features, payouts, featureContent, insuranceType }
     * @returns object
     */
    storeFeaturesData (state, { features, payouts, featureContent, insuranceType }) {
      state[insuranceType].features = features
      state[insuranceType].payouts = payouts
      state[insuranceType].featureContent = featureContent
      return state
    },

    /**
     * Sets products to the state
     *
     * @param {*} state
     * @param {*} { products, insuranceType }
     * @returns object
     */
    // To store products display data only. Nothing related to quotes.
    storeProductsData (state, { products, insuranceType }) {
      state[insuranceType].products = products
      return state
    },

    /**
     * Calls the function buildProductState and builds plainProducts and normalizedProducts
     * in the state
     *
     * @param {*} state
     * @param {*} { insuranceType, flattenedProducts, insurerId, institutionId }
     * @returns object
     */
    loadQuotedProducts (state, { insuranceType, flattenedProducts, insurerId, institutionId, formData }) {
      if (!isNotDefined(flattenedProducts)) {
        return buildProductState(state, insuranceType, flattenedProducts, insurerId, institutionId, formData)
      }
      return state
    },

    /**
     * Sets chosenProduct in the state
     *
     * @param {*} state
     * @param {*} { insuranceType, chosenProduct }
     * @returns object
     */
    updateChosenProduct (state, { insuranceType, chosenProduct }) {
      state[insuranceType].chosenProduct = chosenProduct
      return state
    },

    /**
     * Resets the chosenProduct by setting it to empty object in the state
     *
     * @param {*} state
     * @param {*} { insuranceType }
     * @returns object
     */
    removeChosenProduct (state, { insuranceType }) {
      state[insuranceType].chosenProduct = {}
      return state
    },

    /**
     * Sets enabledFeatures in the state
     *
     * @param {*} state
     * @param {*} { insuranceType, enabledFeatures }
     * @returns object
     */
    'insuranceEnquiry/updatedEnabledFeatures' (state, { insuranceType, enabledFeatures }) {
      state[insuranceType].chosenProduct.enabledFeatures = enabledFeatures
      return state
    },

    /**
     * Resets the filter
     *
     * @param {*} state
     * @param {*} { insuranceType, flattenedProducts, insurerId, institutionId }
     * @returns object
     */
    'insuranceFilters/resetFilters' (state, { insuranceType, flattenedProducts, insurerId, institutionId }) {
      if (!isNotDefined(flattenedProducts)) {
        return buildProductState(state, insuranceType, flattenedProducts, insurerId, institutionId)
      }
      return state
    },

    /**
     * Updates the filter
     *
     * @param {*} state
     * @param {*} { insuranceType, flattenedProducts, insurerId, institutionId }
     * @returns object
     */
    'insuranceFilters/updateFilters' (state, { insuranceType, flattenedProducts, insurerId, institutionId, formData }) {
      if (!isNotDefined(flattenedProducts)) {
        return buildProductState(state, insuranceType, flattenedProducts, insurerId, institutionId, formData)
      }
      return state
    },
    /**
     * sets selected features in the state
     *
     * @param {*} state
     * @param {*} { features, insuranceType }
     * @returns object
     */
    storeSelectedFeaturesData (state, { selectedFeatures, insuranceType }) {
      state[insuranceType].selectedFeatures = selectedFeatures
      return state
    }
  },
  effects: function (dispatch) {
    const modelName = this.name
    return {
      resetChosenProduct: effectsHandler(dispatch, resetChosenProduct),
      setFeatureChoiceForChosenProduct: effectsHandler(dispatch, setFeatureChoiceForChosenProduct),
      setChosenProduct: effectsHandler(dispatch, setChosenProduct),
      loadProducts: effectsHandler(dispatch, loadProducts),
      loadChosenProducts: effectsHandler(dispatch, loadChosenProducts),
      loadFeatures: effectsHandler(dispatch, loadFeatures),
      updateProductsWithQuotes: effectsHandler(dispatch, updateProductsWithQuotes, modelName),
      updateChosenProductData: effectsHandler(dispatch, updateChosenProductData),
      changeChosenProduct: effectsHandler(dispatch, changeChosenProduct),
      setChosenProductForBooking: effectsHandler(dispatch, setChosenProductForBooking)
    }
  }
}

export default insuranceProducts
