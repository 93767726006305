import { useState } from 'react'
import { Search, Input } from 'semantic-ui-react'
import { Subtitle1 } from 'components/Header'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import { isNotDefined, escapeRegExp } from 'utils'

const RegistrationCityWidget = (props) => {
  const {
    formContext,
    onChange,
    placeholder
  } = props

  const buildRtoData = (rtos) => {
    return rtos.map(ele => ({ id: ele.rtoId, title: ele.name }))
  }

  const source = buildRtoData(formContext.rtos)

  const initialiseInputValue = () => {
    if (isNotDefined(props.value)) {
      return ''
    } else {
      const selectedData = source.find(ele => ele.id === props.value)
      return selectedData.title
    }
  }

  const [results, setResults] = useState([])
  const [inputValue, setInputValue] = useState(initialiseInputValue())
  const [isLoading, setIsLoading] = useState(false)

  const handleResultSelect = (_e, { result }) => {
    setInputValue(result.title)
    onChange(result.id)
  }

  const resultRenderer = ({ title }) => <Subtitle1>{title}</Subtitle1>

  const handleSearchChange = (_e, { value }) => {
    setIsLoading(true)
    setInputValue(value)

    setTimeout(() => {
      if (value.length < 1) {
        setResults([])
        return setIsLoading(false)
      }

      const re = new RegExp(escapeRegExp(value), 'i')

      const test = filter(source, (result) => {
        return re.test(result.title)
      })
      setIsLoading(false)
      setResults(test)
    }, 0)
  }

  return (
    <Search
      loading={isLoading}
      as={Input}
      fluid
      placeholder={placeholder}
      onResultSelect={handleResultSelect}
      onSearchChange={debounce(handleSearchChange, 300, { leading: true })}
      results={results}
      value={inputValue}
      resultRenderer={resultRenderer}
    />
  )
}

export default RegistrationCityWidget
