
import { connect } from 'react-redux'
import { ProductConfig } from './components'
import { getUiConfig } from 'smartcovr_api/src/insuranceApi'
const bookingType = 'prePaymentSteps'

const mapStateToProps = (state, ownProps) => {
  const { insuranceType } = ownProps
  const selection = ownProps.store.select(models => ({
    configure: models.insuranceConfigure.getUiConfig,
    smartConfigure: models.insuranceConfigure.getSmartConfig,
    chosenProduct: models.insuranceProducts.getChosenProduct,
    proposerRelations: models.insuranceConfigure.getProposerRelations,
    applicationData: models.insuranceEnquiry.getApplicationData,
    distributorId: models.configure.getDistributorId,
    productSelectionDetails: models.insuranceProducts.getProductSelectionDetails,
    customerDetais: models.customer.getCustomerDetails,
    isLoggedIn: models.customer.isCustomerLoggedIn,
    filters: models.insuranceFilters.getFilters,
    pptOptions: models.insuranceProducts.getPptOptions
  }))
  const selectorData = selection(state, {
    insuranceType
  })
  return {
    enquiryLoading: state.loading.models.insuranceEnquiry,
    configureLoading: state.loading.models.insuranceConfigure,
    loadProposerRelationsLoading: state.loading.effects.insuranceConfigure.loadProposerRelations,
    productDataLoading: state.loading.effects.insuranceProducts.updateChosenProductData,
    initialChosenProductLoading: state.loading.effects.insuranceProducts.setChosenProduct,
    changeChosenProductLoading: state.loading.effects.insuranceProducts.changeChosenProduct,
    setChosenProductForBookingLoading: state.loading.effects.insuranceProducts.setChosenProductForBooking,
    productLoading: state.loading.models.insuranceProducts,
    ...selectorData,
    productData: selectorData.chosenProduct.spProductData,
    formData: selectorData.chosenProduct.spFormData,
    routeProps: ownProps,
    insuranceType,
    iamId: selectorData.customerDetais.iamId,
    bookingType
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const { insuranceType } = ownProps
  return {
    changeChosenProduct: (insurerId) => {
      return dispatch.insuranceProducts.changeChosenProduct({
        insuranceType,
        insurerId
      })
    },
    setDropOff: (insuranceType, dropOff) => {
      return dispatch.insuranceEnquiry.setDropOffForAgent({
        insuranceType,
        dropOff
      })
    },
    setFilterData: (filterChoices) => {
      return dispatch.insuranceFilters.setFilters({
        filterChoices,
        insuranceType
      })
    },
    setChosenProduct: ({ productOptionId, productId, institutionId }) => {
      return dispatch.insuranceProducts.setChosenProduct({
        insuranceType,
        productOptionId,
        productId,
        institutionId,
        quoteType: 'listing'
      })
    },
    resetChosenProduct: () => {
      return dispatch.insuranceProducts.resetChosenProduct({
        insuranceType
      })
    },
    setProductData: ({ productData, formData, insurerId, institutionId, productOptionInsurerId, schemaKey }) => {
      return dispatch.insuranceProducts.updateChosenProductData({
        insuranceType,
        insurerId,
        institutionId,
        productData,
        productOptionInsurerId,
        formData,
        schemaKey
      })
    },
    setApplicationFormData: ({ applicationFormData, institutionId }) => {
      dispatch.insuranceEnquiry.setApplicationFormData({
        applicationFormData,
        insuranceType,
        currentStep: 0,
        institutionId,
        bookingStepIndex: 0,
        device: ownProps.device,
        bookingType
      })
    },
    getUiConfig: (distributorId) => {
      const query = {
        insuranceType,
        distributorId
      }
      return getUiConfig(query)
    },
    setUiConfig: (data) => {
      const query = {
        insuranceType,
        ...data
      }
      return dispatch.insuranceConfigure.setUiConfig(query)
    },
    loadProposerRelations: (institutionId, productInsurerId) => {
      dispatch.insuranceConfigure.loadProposerRelations({
        insuranceType,
        institutionId,
        productInsurerId
      })
    },
    setPayoutType: (payoutType) => {
      dispatch.insuranceEnquiry.setChosenProductWithApplicationData({
        insuranceType: ownProps.insuranceType,
        chosenProductParams: { payoutType }
      })
    },
    setChosenProductForBooking: () => {
      return dispatch.insuranceProducts.setChosenProductForBooking({
        insuranceType
      })
    },
    resetBookingConfig: () => {
      return dispatch.insuranceConfigure.resetBookingConfig({
        insuranceType
      })
    }
  }
}
const ProductConfigContainer = connect(mapStateToProps, mapDispatchToProps)(ProductConfig)
ProductConfigContainer.path = 'product-config'
export default ProductConfigContainer
