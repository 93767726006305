import { Subtitle1 } from 'components/Header'
import { formatAmount } from 'utils/lib/calc'

const EnrolledMessage = (props) => {
  const { getSumAssuredAndCountOfPolicies, insuranceType } = props
  const policyCount = getSumAssuredAndCountOfPolicies.totalNumberOfPolicies
  if (policyCount > 0) {
    const { amtDisplay, amtLabelDisplay } = formatAmount(Math.round(getSumAssuredAndCountOfPolicies.totalCover))
    return (
      <Subtitle1 color='white'>
        {
          insuranceType === 'annuity'
            ? (
            `You have ${policyCount} ${policyCount === 1 ? 'annuity policy' : 'annuity policies'} enrolled`
              )
            : (
            `You have a sum cover of ₹${amtDisplay.toFixed(2)} ${amtLabelDisplay} with ${policyCount} ${policyCount === 1 ? 'policy' : 'policies'} enrolled`
              )
        }
      </Subtitle1>
    )
  } else {
    return (
      <Subtitle1 color='white'>
        You have no policies enrolled
      </Subtitle1>
    )
  }
}

export default EnrolledMessage
