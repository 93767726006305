import { getFileBlob } from 'smartcovr_api/src/commonUtils'
import {
  isNotEmpty,
  find,
  isNotDefined,
  getErrorsById,
  getCustomErrorMessage
} from 'utils'
const downloadFile = async (url, filename) => {
  const blob = await getFileBlob(url)
  if (navigator.appVersion.toString().indexOf('.NET') > 0) {
    window.navigator.msSaveBlob(blob, filename)
  } else {
    const a = document.createElement('a')
    a.href = URL.createObjectURL(blob)
    a.setAttribute('download', filename)
    a.setAttribute('target', '_blank')
    a.click()
  }
}
const getErrorMessageByKey = (key, inlineForm, errors) => {
  const { steps } = inlineForm
  const config = find(steps, step => step.key === key)
  let validationErrors = []
  let title
  if (!isNotDefined(config) && !isNotDefined(config.errorDisplay)) {
    title = config.errorDisplay.title
    const { secondaryField } = config.errorDisplay
    validationErrors = getErrorsById(errors, key)
    if (
      isNotEmpty(secondaryField) &&
        isNotEmpty(validationErrors) && validationErrors.errors !== undefined && validationErrors.errors !== null && validationErrors.errors.length !== 0 &&
        validationErrors.errors[0].keyword !== 'required'
    ) {
      const errorList = getErrorsById(errors, secondaryField.key)
      if (isNotEmpty(errorList)) {
        validationErrors = errorList
        // title = secondaryField.title
      }
    }
  }
  if (isNotEmpty(validationErrors) && validationErrors.errors !== undefined && validationErrors.errors !== null && validationErrors.errors.length !== 0) {
    if (key === 'gender') {
      return 'Invalid Gender'
    }
    return getCustomErrorMessage(title, validationErrors.errors[0])
  }
  return null
}

export {
  downloadFile,
  getErrorMessageByKey
}
