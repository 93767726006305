import { Icon } from 'semantic-ui-react'
import { Subtitle2, Header } from 'components/Header'

const ApplicationStepAndFlag = ({
  ApplicationStep,
  StepDescription,
  Stepflag,
  device
}) => {
  if (device === 'mobile') {
    return (
      <div>
        <div className='stepsName'>
          <Subtitle2>
            {ApplicationStep}
            <Header.Subheader>{StepDescription}</Header.Subheader>
          </Subtitle2>
        </div>
        <div className='statusFlag'>
          <div className='statusFlagIcon'>
            {Stepflag === true
              ? (
                <Icon size='large' color='green' name='check' />
                )
              : (
                <Icon size='large' color='red' name='remove' />
                )}
          </div>
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='stepsName'>
        <Subtitle2>
          {ApplicationStep}
          <Header.Subheader>{StepDescription}</Header.Subheader>
        </Subtitle2>
      </div>
      <div className='statusFlag'>
        <div className='statusFlagIcon'>
          {Stepflag === true
            ? (
              <Icon size='small' color='green' name='check' />
              )
            : (
              <Icon size='small' color='red' name='remove' />
              )}
        </div>
        <div className='statusFlagText'>
          <span>{Stepflag === true ? 'Complete' : 'Incomplete'}</span>
        </div>
      </div>
    </div>
  )
}

export default ApplicationStepAndFlag
