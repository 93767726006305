import { updateEnquiryToDb, loadEnquiry } from '../../services/commonService'
import { updateApplicationDataToBooking } from '../../services/insuranceService'
import { checkForBadRequest } from '../../errors'
import { reduceRootStateDraft, reduceRootStateUpdated, prepareEnquiry, checkAndFinishDraft } from './modelHelpers'
import { runRulesAndUpdateSchema, isProposerSelfCheck, buildFormSteps } from './helpers/bookingHelper'
import FieldInvalid from '../../errors/FieldInvalid'
import { isNotDefined, isNotEmpty, calculateRecommendations } from 'utils'

/**
   * This function sets the enquiry with formdata(with the validation errors) and product data
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setEnquiryData = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType'], payload)
  const { formData, filterData, productDataFragment, insuranceType, all, shouldCalculateRecommendations, typeOfQuote } = payload
  const mutatedRootState = reduceRootStateDraft(draftRootState, insuranceType)
  const { insuranceEnquiryDraft, insuranceProductsDraft } = mutatedRootState
  // dataMismatch is set during booking create. for KYC customer, when customer data != declared data
  if (isNotEmpty(insuranceEnquiryDraft.dataMismatch)) {
    insuranceEnquiryDraft.dataMismatch = {}
  }
  const oldFormData = Object.assign({}, insuranceEnquiryDraft.formData)
  const { plainProducts } = insuranceProductsDraft
  let newFormData = formData
  const updatedFormData = Object.assign({}, oldFormData, newFormData)
  let newProductDataFragment = {}
  if (isNotEmpty(productDataFragment)) {
    newProductDataFragment = Object.assign(newProductDataFragment, productDataFragment)
  }
  // populate formData with reco values only if there are no products
  if (insuranceType === 'term' && ((!isNotEmpty(plainProducts) && isNotEmpty(formData)) || shouldCalculateRecommendations)) {
    const recommendedFormData = calculateRecommendations(updatedFormData)
    const { sumAssured, coverTerm } = recommendedFormData
    newFormData = Object.assign({}, newFormData, { sumAssured })
    newProductDataFragment.coverTerm = coverTerm
  }
  const { enquiryValidations } = prepareEnquiry({
    formData: newFormData,
    filterData,
    productDataFragment: newProductDataFragment,
    insuranceType
  }, mutatedRootState)
  // Need to refresh insuranceProducts list and then do a dispatch
  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft ends here
  const { insuranceEnquiryUpdated, insuranceFiltersUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  if (enquiryValidations.valid) {
    await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
    await dispatch.insuranceEnquiry.updateEnquiry({ insuranceType, insuranceEnquiry: insuranceEnquiryUpdated, insuranceFilters: insuranceFiltersUpdated })
    dispatch.insuranceProducts.updateProductsWithQuotes({ insuranceType, typeOfQuote, currentFormData: oldFormData, all })
  } else {
    // FIXME: Enquiry validations will give array of errors. Use that.
    throw new FieldInvalid.InvalidEnquiry()
  }
}

/***
   * This function sets only enquiry with formdata
   *
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setEnquiry = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType'], payload)
  const { formData, insuranceType } = payload
  const mutatedRootState = reduceRootStateDraft(draftRootState, insuranceType)
  const { insuranceEnquiryDraft } = mutatedRootState
  insuranceEnquiryDraft.formData = Object.assign({}, formData)

  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft ends here
  const { insuranceEnquiryUpdated, insuranceFiltersUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  await dispatch.insuranceEnquiry.updateEnquiry({ insuranceType, insuranceEnquiry: insuranceEnquiryUpdated, insuranceFilters: insuranceFiltersUpdated })
}

/**
   * Fetches the enquiry by id
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const loadEnquiryFromDb = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'enquiryId'], payload)
  const { insuranceType, enquiryId } = payload
  const { distributorId } = draftRootState.configure
  if (isNotDefined(distributorId)) {
    throw new Error('distributor ID not set')
  }
  let insuranceEnquiry
  try {
    insuranceEnquiry = await loadEnquiry(enquiryId, { distributorId, productType: insuranceType })
  } catch (err) {
    throw err
  }
  dispatch.insuranceEnquiry.updateEnquiry({ enquiryId, insuranceEnquiry, insuranceType })
}

/**
   * This function modifies the applicationFormData and then sets it in the enquiry
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setApplicationFormData = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['insuranceType', 'institutionId'], payload)
  const { applicationFormData, insuranceType, currentStep, bookingStepIndex, institutionId, device, bookingType } = payload
  const { insuranceEnquiryDraft, insuranceConfigureDraft, insuranceProductsDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  // draft state starts
  if (!insuranceEnquiryDraft.application.hasOwnProperty(institutionId)) {
    insuranceEnquiryDraft.application[institutionId] = {
      applicationFormData: {}
    }
  }
  if (isNotDefined(insuranceEnquiryDraft.application[institutionId].applicationFormData)) {
    insuranceEnquiryDraft.application[institutionId].applicationFormData = {}
  }
  const application = insuranceEnquiryDraft.application[institutionId]
  if (isNotEmpty(applicationFormData)) {
    Object.assign(application.applicationFormData, { ...applicationFormData, completedSteps: application.applicationFormData.completedSteps })
  }
  if (!isNotDefined(currentStep)) {
    application.currentStep = currentStep
  }
  let action
  let previousStep = false
  const stepDetails = payload.stepDetails || {}
  const { stepType, displayKey } = stepDetails
  // Adding completed steps details

  // Check and fill steps
  // This code block gets all the steps that are supposed to be completed and appends it in the
  // completedSteps array. This way when post-payment page is visited, the array is filled with all
  // the completed steps of pre-payment
  if (application.applicationFormData.completedSteps) {
    const rootState = {
      insuranceConfigure: {},
      insuranceProducts: {}
    }
    rootState.insuranceConfigure[insuranceType] = {
      ...insuranceConfigureDraft
    }
    rootState.insuranceProducts[insuranceType] = {
      ...insuranceProductsDraft
    }
    const isProposerSelf = isProposerSelfCheck(rootState, insuranceType, institutionId)

    const formData = insuranceEnquiryDraft.application[institutionId].applicationFormData
    let bookingUiConfig = insuranceConfigureDraft.uiConfig.booking
    const { formSchema } = insuranceConfigureDraft.booking[institutionId].bookingConfig
    if (!isNotDefined(insuranceConfigureDraft.booking[institutionId].bookingConfig.uiConfig)) {
      bookingUiConfig = insuranceConfigureDraft.booking[institutionId].bookingConfig.uiConfig.booking
    }
    let prePaymentSteps, postPaymentSteps
    const prePaymentDisplaySteps = []
    const postPaymentDisplaySteps = []
    if (bookingType === 'postPaymentSteps') {
      prePaymentSteps = buildFormSteps(bookingUiConfig, formSchema, institutionId, device, formData, 'prePaymentSteps', isProposerSelf)
      prePaymentSteps.forEach(ele => {
        if (ele.stepType !== 'info') {
          prePaymentDisplaySteps.push(ele.displayKey)
        }
      })
      postPaymentSteps = buildFormSteps(bookingUiConfig, formSchema, institutionId, device, formData, 'postPaymentSteps', isProposerSelf)
      const pos = postPaymentSteps.findIndex(ele => ele.displayKey === payload.stepDetails.displayKey)
      postPaymentSteps.filter((ele, index) => index < pos).forEach(ele => {
        if (ele.stepType !== 'info') {
          postPaymentDisplaySteps.push(ele.displayKey)
        }
      })
      application.applicationFormData.completedSteps = [...prePaymentDisplaySteps, ...postPaymentDisplaySteps]
    } else {
      prePaymentSteps = buildFormSteps(bookingUiConfig, formSchema, institutionId, device, formData, 'prePaymentSteps', isProposerSelf)
      const pos = prePaymentSteps.findIndex(ele => ele.displayKey === payload.stepDetails.displayKey)
      // splice and remove extra steps
      // remove info steps
      // add
      prePaymentSteps.filter((ele, index) => index < pos).forEach(ele => {
        if (ele.stepType !== 'info') {
          prePaymentDisplaySteps.push(ele.displayKey)
        }
      })
      application.applicationFormData.completedSteps = prePaymentDisplaySteps
    }
  }

  // Get current key and delete all the steps after that feature. A logic to reset the completed step
  if (application.applicationFormData.completedSteps && application.applicationFormData.completedSteps.includes(displayKey)) {
    const position = application.applicationFormData.completedSteps.findIndex(ele => ele === displayKey)
    application.applicationFormData.completedSteps = application.applicationFormData.completedSteps.splice(0, position)
  }

  // it's used handle completedSteps when back is clicked
  if (bookingStepIndex < application.bookingStepIndex && stepType !== 'info' &&
   !isNotDefined(application.applicationFormData.completedSteps) &&
    isNotEmpty(application.applicationFormData.completedSteps)) {
    application.applicationFormData.completedSteps.pop()
  }

  // Add completed steps
  if (stepType !== 'info' && application.bookingStepIndex + 1 === payload.bookingStepIndex) {
    if (!application.applicationFormData.completedSteps) {
      application.applicationFormData.completedSteps = []
    }
    application.applicationFormData.completedSteps.push(stepDetails.displayKey)
  }
  // following code does not work. was written to make sure api call is not made in case user goes back
  if (!isNotDefined(bookingStepIndex)) {
    if (bookingStepIndex > application.bookingStepIndex) {
      action = stepDetails.action
    } else if (bookingStepIndex < application.bookingStepIndex) {
      previousStep = true
    } else if (currentStep < application.currentStep) {
      previousStep = true
    }
    application.bookingStepIndex = bookingStepIndex
  } else if (currentStep < application.currentStep) {
    previousStep = true
  }
  insuranceEnquiryDraft.application[institutionId] = application
  // following code to update booking date on each step
  const { chosenProduct } = insuranceEnquiryDraft
  const enquiryId = draftRootState.insuranceEnquiry.enquiryId
  const query = {
    insuranceType,
    institutionId,
    insurerId: chosenProduct.productInsurerId,
    enquiry: insuranceEnquiryDraft,
    enquiryId,
    action
  }
  query.enquiry.enquiryId = enquiryId
  // proper error thrown in the called function
  // FIXME: this is a hack right here!!!!!!!
  let processInfoStep = stepType !== 'info'
  // However if there is action in info step, then execute action
  if ((stepType === 'info' && !isNotDefined(action)) ||
  (stepType === 'info' && isNotDefined(action) && bookingType === 'postPaymentSteps' && (bookingStepIndex === 1 || bookingStepIndex === 0) && currentStep === 0)) {
    processInfoStep = true
  }
  const { bookingConfig } = insuranceConfigureDraft.booking[institutionId]

  // following code is to trim trailing spaces from all fields of applicationFormData before performing an action
  // if (isNotDefined(action)) {
  //   const applicationFormDataCopy = insuranceEnquiryDraft.application[institutionId].applicationFormData
  //   const iterateAndUpdateObject = (obj, parentObject, currentKey) => {
  //     Object.keys(obj).forEach((key) => {
  //       if (isObject(obj[key])) {
  //         iterateAndUpdateObject(obj[key], obj, key)
  //       } else if (isString(obj[key])) {
  //         if (Object.isFrozen(obj)) {
  //           parentObject[currentKey] = produce(parentObject[currentKey], (draft) => {
  //             draft[key] = draft[key].trim()
  //           })
  //         } else {
  //           obj[key] = obj[key].trim()
  //         }
  //       }
  //     })
  //   }
  //   iterateAndUpdateObject(applicationFormDataCopy)
  // }

  if (!previousStep && !isNotDefined(stepType) && processInfoStep) {
    try {
      const applicationForm = await updateApplicationDataToBooking(query)
      const updatedBookingSchema = await runRulesAndUpdateSchema(bookingConfig.formSchema, applicationForm)
      bookingConfig.formSchema.schema = updatedBookingSchema.schema
      bookingConfig.formSchema.uiSchema = updatedBookingSchema.uiSchema
      application.applicationFormData = updatedBookingSchema.formData
    } catch (err) {
      throw err
    }
  }

  const updatedRootState = checkAndFinishDraft(draftRootState)
  const { insuranceEnquiryUpdated, insuranceConfigureUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  // draft state ends

  await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  dispatch.insuranceEnquiry.updateApplicationData({ insuranceType, application: insuranceEnquiryUpdated.application[institutionId], institutionId, bookingConfig: insuranceConfigureUpdated.booking[institutionId].bookingConfig })
}

/**
   * This products updates the chosenProduct and updates it in the enquiry and then calls the reducer
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const setChosenProductWithApplicationData = async (dispatch, payload, draftRootState) => {
  checkForBadRequest(['chosenProductParams', 'insuranceType'], payload)
  const { insuranceType, chosenProductParams } = payload
  // FIXME: To be removed soon
  const { insuranceEnquiryDraft } = reduceRootStateDraft(draftRootState, insuranceType)
  const { chosenProduct } = insuranceEnquiryDraft
  const updatedChosenProduct = Object.assign({}, chosenProduct, chosenProductParams)
  insuranceEnquiryDraft.chosenProduct = updatedChosenProduct

  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft state ends here
  const { insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  await updateEnquiryToDb(insuranceEnquiryUpdated, insuranceType, updatedRootState.insuranceEnquiry.enquiryId)
  dispatch.insuranceEnquiry.updateChosenProductWithApplicationData({ insuranceType, chosenProduct: chosenProductParams })
}

/**
   * This function populates the formData, application and chosenProduct in the enquiry and calls the
   * reducer
   *
   * @param {*} dispatch
   * @param {*} payload
   * @param {*} draftRootState
   * @returns
   */
const updateEnquiryWithPolicyDetails = (dispatch, payload, draftRootState) => {
  const { policy: { Product, Product: { manufacturerId, insurerId, productOptionInsurerId }, application } } = payload
  const insuranceType = Product.insuranceType.toLowerCase()
  const { insuranceEnquiryDraft, customerDraft: { customer: { gender, iamId } } } = reduceRootStateDraft(draftRootState, insuranceType)
  const applicationFormData = application.applicationFormData || {}
  // populate formData
  // FIXME: what is the logic to populate formdata
  const { formData, productData } = insuranceEnquiryDraft
  formData.dateOfBirth = applicationFormData.dateOfBirth
  formData.age = applicationFormData.age
  formData.gender = gender
  formData.mobile = applicationFormData.mobile
  formData.income = applicationFormData.income
  formData.sumAssured = applicationFormData.sumAssured
  formData.coverTerm = applicationFormData.coverTerm
  productData.coverTerm = applicationFormData.coverTerm
  formData.pincode = applicationFormData.pincode || applicationFormData.permanentAddress.csp.pincode

  // populate applicationFormData
  insuranceEnquiryDraft.application = {
    [manufacturerId]: {
      applicationFormData,
      bookingStepIndex: 0,
      currentStep: 0
    }
  }

  // populate chosenProduct
  insuranceEnquiryDraft.chosenProduct = {
    institutionId: manufacturerId,
    productId: insurerId,
    productInsurerId: insurerId,
    productOptionInsurerId,
    productOptionId: productOptionInsurerId
  }

  const updatedRootState = checkAndFinishDraft(draftRootState)
  // draft ends here
  const { insuranceEnquiryUpdated } = reduceRootStateUpdated(updatedRootState, insuranceType)
  dispatch.insuranceEnquiry.updateEnquiry({ insuranceType, enquiryId: iamId, insuranceEnquiry: insuranceEnquiryUpdated })
}
export {
  setEnquiryData,
  setEnquiry,
  loadEnquiryFromDb,
  setApplicationFormData,
  setChosenProductWithApplicationData,
  updateEnquiryWithPolicyDetails
}
