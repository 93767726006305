import { CircularProgressbarWithChildren } from 'react-circular-progressbar'

import 'react-circular-progressbar/dist/styles.css'

export const CircularProgressbarComponent = (props) => {
  const { policy } = props
  const value = policy.previousPremiums.length
  return (
    <CircularProgressbarWithChildren
      value={value}
      minValue={0}
      maxValue={policy.paymentSchedule.length}
      styles={{
        path: {
          strokeWidth: 6,
          strokeLinecap: 'round',
          stroke: '#6FC955'
        },
        trail: {
          strokeWidth: 4,
          stroke: '#9b9b9b'
        }
      }}
    // styles={buildStyles({
    //   // This is in units relative to the 100x100px
    //   // SVG viewbox.
    //   // strokeLinecap: 10,
    //   trailColor: '#9b9b9b',
    //   pathColor: '#00c853',
    //   textSize: '14px'
    // })}
    >
      {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
      <div style={{ fontSize: 10, fontWeight: 'bold', color: '#283593' }}>
        <span>
          PREVIOUS <br />
          PREMIUM
        </span>
      </div>
      <div>
        <span
          style={{ fontSize: 14, color: 'rgb(var(--secondary))' }}
        >
          <strong>
            {policy.insuranceType === 'term' ? `${policy.previousPremiums.length}/${policy.paymentSchedule.length}` : policy.premium}
          </strong>
        </span>
      </div>
    </CircularProgressbarWithChildren>
  )
}

export default CircularProgressbarComponent
