import { useInitialDataLoader, useSetChosenProduct, useSetFormData } from 'components/customHooks'
import Loading from 'components/Loading'
import { useEffect, useRef, useState } from 'react'
import { isNotEmpty } from 'utils'
import FetchingProducts from 'components/infoScreens/FetchingProducts'
import AutoModifyCover from './auto/AutoModifyCover'
import { sendDropOffLink } from 'smartcovr_api/src/insuranceApi'
import NoProductsForUser from 'components/infoScreens/NoProductsForUser'

const AutoRenewDetails = (props) => {
  const { device, insuranceType, enquiryId, distributorId, formData, productData, products, chosenProduct, productDataLoading, productsLoading, iamId } = props
  const { loadingConfigure, configure } = useInitialDataLoader(insuranceType, enquiryId, distributorId)
  const [, setFormData] = useSetFormData(insuranceType, loadingConfigure)
  const [chosenProductLoading, setChosenProduct] = useSetChosenProduct(insuranceType, loadingConfigure)

  const [proceed, setProceed] = useState(false)

  const componentRef = useRef({ redirect: false, dropOffFired: false })
  useEffect(() => {
    setFormData({ formData: {} }, true)
    const { resetChosenProduct } = props
    resetChosenProduct()
  },
  []) // eslint-disable-line

  useEffect(() => {
    const eventHandler = (ev) => {
      ev.preventDefault()
      if (isNotEmpty(formData) && !componentRef.current.dropOffFired) {
        componentRef.current.dropOffFired = true
        sendDropOffLink({
          applicationFormData: { ...formData, iamId },
          path: 'select-product',
          distributorId
        })
      }
      return null
    }
    window.addEventListener('unload', eventHandler)
    return () => window.removeEventListener('unload', eventHandler)
  },
  // eslint-disable-next-line
  [ formData, distributorId ])

  useEffect(() => {
    if (componentRef.current.redirect && isNotEmpty(chosenProduct)) {
      const { redirectToPath } = props
      componentRef.current.redirect = false
      const path = 'product-config'
      if (proceed) {
        setProceed(false)
        props.setChosenProductData({
          productData: chosenProduct.spProductData,
          insurerId: chosenProduct.insurerId,
          institutionId: chosenProduct.finanalyticsId
        })
      }
      redirectToPath(path)
    }
  },
  [chosenProduct]) // eslint-disable-line
  const handleBuyNow = async ({ productOptionId, productId, institutionId, insurerId }) => {
    componentRef.current.redirect = true
    setChosenProduct({ productOptionId, productId, institutionId, productOptionInsurerId: insurerId })
    setProceed(true)
  }

  const redirectToOnboard = () => {
    props.redirectToPath('onboard')
  }

  if (loadingConfigure || productDataLoading || chosenProductLoading || productsLoading) {
    return <Loading id='loading_listing_config' open />
  }
  if (!isNotEmpty(products)) {
    const noProductsProps = configure.common.infoScreens.noProducts
    return <NoProductsForUser {...noProductsProps} onClick={redirectToOnboard} />
  }
  if (!props.isPartialLoadComplete) {
    const loadingConfig = {
      title: 'Getting Product Details',
      description: 'We are fetching your product based on the policy details provided',
      imagePath: 'sc-loading-products.png',
      animation: 'bounce animated infinite'
    }
    return <FetchingProducts {...loadingConfig} />
  }

  let allProducts = []
  if (products) {
    Object.keys(products).forEach(key => {
      allProducts = allProducts.concat(products[key])
    })
  }
  return (
    <div className='container pt-4'>
      <AutoModifyCover {...{ device, configure, allProducts, formData, productData, insuranceType, handleBuyNow }} />
    </div>
  )
}

export default AutoRenewDetails
