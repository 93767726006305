
import { PureComponent } from 'react'
import { StickyContainer, Sticky } from 'react-sticky'
import 'components/css/containerStyles.scss'
// following styles are used for sticky
const styles = {
  sticky: {
    top: '4em' // $headerHeight - value
  },
  default: {
    paddingTop: '1rem'
  }
}
class SecondaryStickyHeader extends PureComponent {
  render () {
    const { id, stickyContent, children } = this.props
    return (
      <div className='outerContainer' id={id}>
        <StickyContainer className='mainDisplay' id='web_view'>
          <Sticky topOffset={-80}>
            {
                ({
                  style,
                  isSticky
                }) => {
                  const componentStyle = isSticky
                    ? { ...style, ...styles.default, ...styles.sticky }
                    : { ...style, ...styles.default }
                  return (
                    <div
                      style={componentStyle}
                      className='menuContainer'
                    >
                      {stickyContent}
                    </div>
                  )
                }
              }
          </Sticky>
          {children}
        </StickyContainer>
      </div>
    )
  }
}

export default SecondaryStickyHeader
