import { getStore } from '../store'

const getService = () => {
  const env = process.env.NODE_ENV
  const { configure } = getStore().getState()
  const metaDataService = env === 'production' ? `metadata/${configure.apiVersion}` : 'metadata'
  return { metaDataService }
}
const getCarMakeModelService = async (app, id) => {
  const makeModel = 'carMakeModel'
  try {
    if (id === 'all') {
      return await app.service('metadata').get(makeModel)
    } else {
      const params = {
        query: {
          modelId: id
        }
      }
      return await app.service('metadata').get('carMakeModel', params)
    }
  } catch (error) {
    console.log('error in getAllMakeModels: ', error)
  }
}

const getMakeModelService = async (app, id, insuranceType) => {
  const { metaDataService } = getService()
  const makeModel = `${insuranceType}MakeModel`
  try {
    if (id === 'all') {
      return await app.service(metaDataService).get(makeModel)
    } else {
      const params = {
        query: {
          modelId: id
        }
      }
      return await app.service(metaDataService).get('carMakeModel', params)
    }
  } catch (error) {
    console.log('error in getAllMakeModels: ', error)
  }
}

const getBikeMakeModelService = async (app, id) => {
  try {
    if (id === 'all') {
      return await app.service('metadata').get('bikeMakeModel')
    } else {
      const params = {
        query: {
          modelId: id
        }
      }
      return await app.service('metadata').get('bikeMakeModel', params)
    }
  } catch (error) {
    console.log('error in getAllMakeModels: ', error)
  }
}

const getCommonFuelTypeService = async (app, id) => {
  const { metaDataService } = getService()
  try {
    if (id === 'all') {
      return await app.service(metaDataService).get('commonFuelType')
    } else {
      const params = {
        query: {
          fuelTypeId: id
        }
      }
      return await app.service(metaDataService).get('commonFuelType', params)
    }
  } catch (error) {
    console.log('error in getAllFuelTypes: ', error)
  }
}

const getRtoService = async (app, id) => {
  const { metaDataService } = getService()
  try {
    if (id === 'all') {
      return await app.service(metaDataService).get('rto')
    } else {
      const params = {
        query: {
          rtoId: id
        }
      }
      return await app.service(metaDataService).get('rto', params)
    }
  } catch (error) {
    console.log('error in getAllRtos: ', error)
  }
}

const getVariantService = async (app, id, insuranceType) => {
  const { metaDataService } = getService()
  const variant = `${insuranceType}Variant`
  try {
    if (id === 'all') {
      return await app.service(metaDataService).get(variant)
    } else {
      const params = {
        query: {
          variantId: id
        }
      }
      return await app.service(metaDataService).get('carVariant', params)
    }
  } catch (error) {
    console.log('error in getAllVariants: ', error)
  }
}

const getCarVariantService = async (app, id) => {
  const variant = 'carVariant'
  try {
    if (id === 'all') {
      return await app.service('metadata').get(variant)
    } else {
      const params = {
        query: {
          variantId: id
        }
      }
      return await app.service('metadata').get('carVariant', params)
    }
  } catch (error) {
    console.log('error in getAllVariants: ', error)
  }
}
const getBikeVariantService = async (app, id) => {
  try {
    if (id === 'all') {
      return await app.service('metadata').get('bikeVariant')
    } else {
      const params = {
        query: {
          variantId: id
        }
      }
      return await app.service('metadata').get('bikeVariant', params)
    }
  } catch (error) {
    console.log('error in getAllVariants: ', error)
  }
}

const getInsurerNames = async (app) => {
  const { metaDataService } = getService()
  let response
  try {
    response = await app.service(metaDataService).get('institutionIds')
  } catch (err) {
    console.log('Error in getting all Insurer Companies :', err)
  }
  return response
}

export {
  getCarMakeModelService,
  getMakeModelService,
  getBikeMakeModelService,
  getCommonFuelTypeService,
  getRtoService,
  getCarVariantService,
  getVariantService,
  getBikeVariantService,
  getInsurerNames
}
