import { useInitialDataLoader } from 'components/customHooks'
import { StyledEngineProvider } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { JsonSchemaHelper, JsonSchemaOnboardForm, JsonSchemaValidation } from 'components/jsonSchemaForms'
import { claimsSchema } from './schema'
import { getSchemaForCurrentStep, getUISchemaForCurrentStep } from './myHelper'
import { Button, Modal } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import './claimIntimation.scss'
import steps from './steps.json'

const ClaimIntimation = (props) => {
  const { countries, insuranceType, distributorId, getOtp, otp } = props
  useInitialDataLoader(
    insuranceType,
    props.enquiryId,
    props.distributorId
  )
  const [formData, setFormData] = useState({})
  const [modalRedirectShow, setModalRedirectShow] = useState(false)
  const [selectedPath, setSelectedPath] = useState('')
  const [currentStep, setCurrentStep] = useState(-1)
  const [show, setShow] = useState(false)
  const [verifyShow, setVerifyShow] = useState(false)
  const [successModal, setSuccesModal] = useState(false)
  const [registerModalShow, setRegisterModalShow] = useState(false)
  const [showResendLink, setShowResendLink] = useState(false)

  const message = 'You will recieve a One-Time Password(OTP) for verification on your mobile number'
  const successMessage = 'Claim Intimation Submitted Successfully!!'
  const verifyMessage = 'OTP verified Successfully'
  const messageForRegister = 'Continue with Claim Registration?'
  const messageForIntimation = 'Continue with Claim Intimation?'
  const query = { insuranceType }

  useEffect(() => {
    props.getSchemaForClaims(query)
  }, [])

  useEffect(() => {
    if (props.claimIntimation[insuranceType]?.intimationNo) {
      setSuccesModal(true)
    }
  }, [props.claimIntimation[insuranceType]?.intimationNo])

  const handleClose = () => {
    setShow(false)
    setCurrentStep(currentStep + 1)
  }

  const handleSuccessModalClose = () => {
    setSuccesModal(false)
    setRegisterModalShow(true)
  }

  const handleVerifyClose = () => {
    setVerifyShow(false)
    setModalRedirectShow(true)
  }

  const NotificationModal = (props) => {
    const { show, handleClose, message, intimationNo } = props
    return (
      <div>
      <Modal
          open={show}
          size='small'
        >
          <Modal.Content>
          <H6>{message}</H6>
          <H6>{intimationNo}</H6>
          <Button content='OK' primary onClick={handleClose}/>
          </Modal.Content>
        </Modal>
    </div>)
  }

  const handleRedirectToLanding = () => {
    setModalRedirectShow(false)
    props.redirectToPath('/')
  }

  const handleRedirectToclaimIntimationDetail = () => {
    setModalRedirectShow(false)
    // setFormData({
    //   ...formData,
    //   claimIntimatationDetail: {
    //     ...initialFormDataForClaimIntimationDetail
    //   }
    // })
    setCurrentStep(currentStep + 1)
  }

  const handleRedirectToRegistration = () => {
    props.redirectToPath('claim-registration')
  }

  const ModalToRedirect = (props) => {
    const { show, handleRedirectToLanding, handleRedirectToPath, message } = props
    return (
      <div className="">
      <Modal
          open={show}
          size='small'
        >
          <Modal.Content className=''>
          <H6>{message}</H6>
          <Button content='Yes' primary onClick={handleRedirectToPath}/>
          <Button content='No' primary onClick={handleRedirectToLanding}/>
          </Modal.Content>
        </Modal>
    </div>)
  }

  const { claimApplicationForm: { uiSchema, schema } } = props.schema.schema || claimsSchema
  const currentSchema = getSchemaForCurrentStep(schema, steps, selectedPath, currentStep)
  const currentUiSchema = getUISchemaForCurrentStep(uiSchema, steps, selectedPath, currentStep, uiSchema)

  const handleBack = (e) => {
    e.preventDefault()
    if (currentStep > -1) {
      setCurrentStep(currentStep - 1)
    }
    if (showResendLink === true) {
      setShowResendLink(false)
    }
  }

  const bottomButtons = () => {
    const isButtonWidget = JsonSchemaHelper.checkButtonWidget(uiSchema)

    if (!isButtonWidget) {
      // const totalLength = steps?.[selectedPath]?.steps.length
      // const isLastStep = totalLength - 1
      const label = currentStep === -1 ? 'Next' : currentStep === 0 ? 'Send Otp' : currentStep === 1 ? 'Verify' : 'save'

      const id = label.split(' ').join('-') + '-btn'

      // const handleClick = isLastStep ? handleSave : handleSubmit

      return (
        <div className='ms-3 mb-3 mt-4'>
          {currentStep === 1 ? null : <Button id={id} secondary content={label} />}
          {currentStep > -1 && (currentStep === 1
            ? null
            : <Button id='bottom-back-btn' onClick={handleBack} basic>
              BACK
            </Button>
          )}
        </div>
      )
    } else {
      return <></> // Render nothing if it's a button widget
    }
  }

  const handleSubmit = (formData) => {
    const distributorId = props.distributorId
    const queryParams = { insuranceType, distributorId }
    setFormData(formData.formData)

    if (currentStep === -1) {
      setSelectedPath(formData.formData.claimIntimatedBy)
      setCurrentStep(currentStep + 1)
    } else if (currentStep === 0) {
      const objForOtpGeneration = {}
      if (formData.formData.verifyPolicyNo) {
        objForOtpGeneration.policyNumber = formData.formData.verifyPolicyNo
      } else if (formData.formData.verifyRegisteredMobileNo) {
        const mobileNo = JSON.parse(formData.formData.verifyRegisteredMobileNo)
        objForOtpGeneration.mobile = mobileNo.number
      } else if (formData.formData.intimatorDetails.intimatorContactNumber) {
        const contactNumber = JSON.parse(formData.formData.intimatorDetails.intimatorContactNumber)
        objForOtpGeneration.mobile = contactNumber.number
      }
      const otpProps = { enquiryData: objForOtpGeneration, queryParams }
      props.getOtp(otpProps)
      setShow(true)
    } else if (currentStep === 2) {
      const { insuranceCompany, policyNumber, nameOfLifeAssured, nameOfProposer, claimReportingPersonName, claimType, dateOfBirthOfLifeAssured, dateOfEvent, causeOfEvent, nameOfNominee, nameOfclaimant, relationshipWithLifeAssured, emailIdOfClaimant, mobileNoOfClaimant, addressOfClaimant, country, district, csp } = formData.formData.claimIntimatationDetail
      const enquiryBody = {
        insuranceCompany,
        policyNumber,
        lifeAssuredName: nameOfLifeAssured,
        proposerName: nameOfProposer,
        claimReportingPersonName,
        insuranceType,
        claimType,
        lifeAssuredDateOfBirth: dateOfBirthOfLifeAssured,
        eventDate: dateOfEvent,
        eventCauses: causeOfEvent,
        nomineeName: nameOfNominee,
        claimantName: nameOfclaimant,
        lifeAssuredRelationship: relationshipWithLifeAssured,
        claimantEmail: emailIdOfClaimant,
        claimantMobileNo: mobileNoOfClaimant,
        claimantAddress: addressOfClaimant,
        country,
        state: csp.stateDisplay,
        district,
        pinCode: csp.pincode,
        city: csp.cityDisplay,
        distributorId: props.distributorId,
        iamId: props.customerDetails.iamId
      }

      const queryParams = { insuranceType, action: 'intimation' }

      const data = { enquiryData: enquiryBody, queryParams }

      props.postIntimation(data)
      // setSuccesModal(true)
    }
  }

  const transformErrors = (errors) => {
    const filteredErrors = errors.filter(error => error.name !== 'oneOf')

    return filteredErrors.map(error => {
      if (error) {
        return {
          ...error,
          message: error.params?.missingProperty ? `${error.params?.missingProperty} ${error.message}` : error.message
        }
      }

      return error
    })
  }

  const onValidate = (formData, errors) => {
    const { validateMobileNumber, validateEmail, validateFullName, validateDobOfLifeAssured, validateRelationshipWithLifeAssured } = JsonSchemaValidation
    const verifyRigisteredMobileNoError = validateMobileNumber(formData.verifyRegisteredMobileNo, countries)
    const intimatorEmailIdError = validateEmail(formData, 'intimatorEmailId')
    const intimatorContactNumberError = validateMobileNumber(formData?.intimatorDetails?.intimatorContactNumber, countries)
    const intimatorNameError = validateFullName(formData, 'intimatorName')
    const claimIntimatationDetailNameOfLifeAssuredError = validateFullName(formData, 'nameOfLifeAssured')
    const claimIntimatationDetailNameOfProposerError = validateFullName(formData, 'nameOfProposer')
    const claimIntimatationDetailClaimReportingPersonNameError = validateFullName(formData, 'claimReportingPersonName')
    const claimIntimatationDetailDateOfBirthOfLifeAssuredError = validateDobOfLifeAssured(formData, schema, 'dateOfBirthOfLifeAssured')
    const claimIntimatationDetailNameOfNomineeError = validateFullName(formData, 'nameOfNominee')
    const claimIntimatationDetailNameOfClaimantError = validateFullName(formData, 'nameOfclaimant')
    const claimIntimatationDetailEmailOfClaimantError = validateEmail(formData, 'emailIdOfClaimant')
    const claimIntimatationDetailRelationshipWithLifeAssuredError = validateRelationshipWithLifeAssured(formData,
      props.schema.schema || claimsSchema, 'relationshipWithLifeAssured')
    const claimIntimatationDetailMobileNumberOfClaimantError = validateMobileNumber(formData?.claimIntimatationDetail?.mobileNoOfClaimant, countries)
    if (verifyRigisteredMobileNoError) {
      if (errors.verifyRegisteredMobileNo) {
        errors.verifyRegisteredMobileNo.addError(verifyRigisteredMobileNoError)
      }
    }
    if (intimatorEmailIdError) {
      if (errors.intimatorDetails) {
        errors.intimatorDetails.intimatorEmailId.addError(intimatorEmailIdError)
      }
    }
    if (intimatorNameError) {
      if (errors.intimatorDetails) {
        errors.intimatorDetails.intimatorName.addError(intimatorNameError)
      }
    }
    if (intimatorContactNumberError) {
      if (errors.intimatorDetails) {
        errors.intimatorDetails.intimatorContactNumber.addError(intimatorContactNumberError)
      }
    }
    if (claimIntimatationDetailNameOfLifeAssuredError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.nameOfLifeAssured.addError(claimIntimatationDetailNameOfLifeAssuredError)
      }
    }
    if (claimIntimatationDetailNameOfProposerError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.nameOfProposer.addError(claimIntimatationDetailNameOfProposerError)
      }
    }
    if (claimIntimatationDetailClaimReportingPersonNameError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.claimReportingPersonName.addError(claimIntimatationDetailClaimReportingPersonNameError)
      }
    }
    if (claimIntimatationDetailClaimReportingPersonNameError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.claimReportingPersonName.addError(claimIntimatationDetailClaimReportingPersonNameError)
      }
    }
    if (claimIntimatationDetailDateOfBirthOfLifeAssuredError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.dateOfBirthOfLifeAssured.addError(claimIntimatationDetailDateOfBirthOfLifeAssuredError)
      }
    }
    if (claimIntimatationDetailNameOfNomineeError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.nameOfNominee.addError(claimIntimatationDetailNameOfNomineeError)
      }
    }
    if (claimIntimatationDetailNameOfClaimantError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.nameOfclaimant.addError(claimIntimatationDetailNameOfClaimantError)
      }
    }
    if (claimIntimatationDetailEmailOfClaimantError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.emailIdOfClaimant.addError(claimIntimatationDetailEmailOfClaimantError)
      }
    }
    if (claimIntimatationDetailMobileNumberOfClaimantError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.mobileNoOfClaimant.addError(claimIntimatationDetailMobileNumberOfClaimantError)
      }
    }
    if (claimIntimatationDetailRelationshipWithLifeAssuredError) {
      if (errors.claimIntimatationDetail) {
        errors.claimIntimatationDetail.relationshipWithLifeAssured.addError(claimIntimatationDetailRelationshipWithLifeAssuredError)
      }
    }
    return errors
  }

  return (
    <StyledEngineProvider injectFirst>
      <div className='claimIntimationForm'>
        <div className='bg_claim_intimation_card'>
      <JsonSchemaOnboardForm
      schema={currentSchema}
      uiSchema={currentUiSchema}
      rules={[]}
      onSubmit={handleSubmit}
      formData={formData}
      transformErrors={transformErrors}
      onValidate={onValidate}
      liveValidate={false}
      formContext={{
        countries,
        formData,
        insuranceType,
        distributorId,
        getOtp,
        otp,
        showResendLink,
        setShowResendLink,
        setVerifyShow
      }}
     >
      {bottomButtons()}
    </JsonSchemaOnboardForm>
    </div>
    </div>
    <ModalToRedirect show={registerModalShow} message={messageForRegister} handleRedirectToLanding= {handleRedirectToLanding} handleRedirectToPath={handleRedirectToRegistration} />
    { props.otp[insuranceType] && (<NotificationModal show={show} handleClose={handleClose} message={message}/>)}
    <NotificationModal show={successModal} handleClose={handleSuccessModalClose} message={successMessage} intimationNo={`Claim Intimation No. : ${props.claimIntimation[insuranceType]?.intimationNo}`}/>
    <NotificationModal show={verifyShow} handleClose={handleVerifyClose} message={verifyMessage}/>
    <ModalToRedirect show={modalRedirectShow} message={messageForIntimation} handleRedirectToLanding={handleRedirectToLanding} handleRedirectToPath={handleRedirectToclaimIntimationDetail} />
    </StyledEngineProvider>
  )
}
export default ClaimIntimation
