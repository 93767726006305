
import { PureComponent } from 'react'
import { productComposer } from '../product'
import { Transition, Card, Modal, List, Label } from 'semantic-ui-react'
import { isNotEmpty } from 'utils'
import CompactCardView from './CompactCardView'
import { getDisplayData } from '../../helper/productHelper'
import { connect } from 'react-redux'
import { adopt } from 'react-adopt'
import { DataAccessContext, UpdateContext, LoadingContext } from 'components/context'
import DetailedProduct from './DetailedProduct'
import CardViewMobile from './CardViewMobile'
import getStore from '../../../index'
import { isNotDefined } from 'utils/lib/basic'

class CardView extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      editRiderId: '',
      showProduct: false,
      transition: -1 // For modal view transition
    }
    this.onModalClose = this.onModalClose.bind(this)
    this.onClickExpand = this.onClickExpand.bind(this)
    this.addRider = this.addRider.bind(this)
    this.onRiderChange = this.onRiderChange.bind(this)
    this.closeRiderEdit = this.closeRiderEdit.bind(this)
  }

  /**
   *
   *  this is called to open editor for rider change
   * @param {*} riderId
   * @memberof CardView
   */
  addRider (riderId) {
    this.setState({
      editRiderId: riderId
    })
  }

  /**
   * called on save of rider change
   *
   * @param {*} changeValue
   * @param {*} selected
   * @param {*} productCharacteristicId
   * @memberof CardView
   */
  onRiderChange ({ change, riderId, productCharacteristicId }) {
    const changeValue = {
      ...change,
      riderId
    }
    this.setState({
      editRiderId: ''
    }, () => this.props.onRiderChange(changeValue, productCharacteristicId))
  }

  closeRiderEdit () {
    this.setState({
      editRiderId: ''
    })
  }

  /**
   * Called when info icon is clicked, to show the detailed view of product.
   * @memberof CardView
   */
  onClickExpand () {
    const { onKnowMore, productOptionId } = this.props
    onKnowMore(productOptionId)
  }

  /**
   * Called to close detailed view.
   * @memberof CardView
   */
  onModalClose () {
    const { onKnowMore } = this.props
    onKnowMore(-1)
  }

  onBook (productOptionId) {
    this.props.onBook(productOptionId)
  }

  detailedView (ComponentMap) {
    const { transition } = this.state
    return (
      <DetailedProduct
        insuranceType={this.props.insuranceType}
        transition={transition}
        ComponentMap={ComponentMap}
        onShow={(transition) => this.setState({ transition })}
      />
    )
  }

  /**
   *
   *
   * @param {object} product
   * @param {number} index
   * @returns {reactObject} - returns Transition, Modal, Grid and List
   * @memberof CardView
   */
  modalView (ComponentMap, index) {
    const { device, productOptionId, expandedProduct } = this.props
    const expandCard = expandedProduct === productOptionId
    if (device === 'mobile') {
      return null
    }
    return (
      <Transition
        visible={expandCard}
        onHide={() => this.setState({ transition: -1 })}
        onShow={() => this.setState({ transition: 1 })}
        animation='scale'
        duration={200}
      >
        <Modal
          id='knowMoreModal'
          open={expandCard}
          dimmer
          size='large'
        >
          {this.detailedView(ComponentMap, index)}
        </Modal>
      </Transition>
    )
  }

  drawListCard (index) {
    const { product, device, onBook, expandedProduct, productOptionId, onReview, productData, formData, configure, insuranceType, loading, handleChange } = this.props
    const { editRiderId } = this.state
    const listingDisplay = `${insuranceType}ListingDisplay`
    const { view } = configure.productListing[listingDisplay]
    const expandCard = expandedProduct === productOptionId
    let contentType = ''
    if (expandCard) {
      contentType = 'detail'
    }
    // FIXME: use memoized function to call getDisplayData
    const itemProps = {
      product: getDisplayData({
        product,
        formData,
        productData,
        paymentFrequencies: configure.common.paymentFrequencies,
        paymentOptions: configure.productListing.paymentOptionsPriority,
        payoutFrequencies: configure.common.payoutFrequencies
      }),
      onReview,
      onClickExpand: this.onClickExpand,
      onModalClose: this.onModalClose,
      formData,
      view,
      device,
      actualProduct: product,
      productData,
      closeRiderEdit: this.closeRiderEdit,
      index,
      editRiderId,
      addRider: this.addRider,
      onRiderChange: this.onRiderChange,
      contentType,
      onBook,
      insuranceType,
      loading,
      handleChange,
      configure
    }
    const ComponentMap = productComposer({
      ...itemProps
    })
    if (device === 'mobile') {
      return (
        <CardViewMobile
          id={`productOptionId_${productOptionId}`}
          ComponentMap={ComponentMap}
          itemProps={itemProps}
          product={product}
        />
      )
    }
    const mobileKnowMore = device === 'mobile' && expandCard
    if (mobileKnowMore) {
      return (
        <CompactCardView
          id={`productOptionId_${productOptionId}`}
          ComponentMap={ComponentMap}
          contentType='detail'
        />
      )
    }
    let className = 'productCard'
    if (editRiderId.length > 0) {
      className = `${className} editable`
    }
    const dynamicFields = () => {
      if (insuranceType === 'annuity') {
        return (
          <>
            {ComponentMap.DeferredPeriod}
            {ComponentMap.RopPercent}
            {ComponentMap.AcpPeriod}
            {ComponentMap.IaPercent}
          </>
        )
      }
    }
    return (
      <Card
        id={`productOptionId_${productOptionId}`}
        className={className}
        key={`card${index}`}
        data-cy={`${ComponentMap.productOptionId}-card`}
      >
        {this.modalView(ComponentMap, index)}
        {ComponentMap.LogoImage}
        {/* is full match label to be shown ??? */}
        {
          (isNotEmpty(ComponentMap.filter) && ComponentMap.isFullMatch && ComponentMap.labelVisibilty)
            ? <Label as='a' color='red' ribbon>Full Match</Label>
            : null
        }
        {ComponentMap.Insurer}
        {dynamicFields()}
        <List className={insuranceType === 'annuity' ? 'flex-grow-1' : ''}>
          <List.Item className='flexItems justify-content-center'>
            <List.Content className='twoItemsFlex'>
              <Card.Description textAlign='center'>
                {insuranceType === 'annuity' ? (ComponentMap.PurchasePrice) : (ComponentMap.Premium)}
              </Card.Description>
            </List.Content>
            {insuranceType === 'term' && (
              <List.Content className='twoItemsFlex'>
                <Card.Description textAlign='center'>
                  {ComponentMap.CoverPeriod}
                  {['car', 'bike'].includes(insuranceType) && formData.policyType !== 'thirdParty' && ComponentMap.Cover}
                </Card.Description>
              </List.Content>
            )}
            {insuranceType === 'annuity' && (
              <List.Content className='twoItemsFlex'>
                <Card.Description textAlign='center'>
                  {ComponentMap.AnnuityPayout}
                </Card.Description>
              </List.Content>
            )}
            {['car', 'bike', 'health'].includes(insuranceType) && formData.policyType !== 'thirdParty' && (
              <List.Content className='twoItemsFlex'>
                <Card.Description textAlign='center'>
                  {ComponentMap.Cover}
                </Card.Description>
              </List.Content>
            )}
          </List.Item>
        </List>
        {insuranceType !== 'annuity' && ComponentMap.FeaturesAndRiders}
        {insuranceType === 'term' && ComponentMap.PayoutTerms}
        <div className='pegToBottom'>
          {ComponentMap.Review}
          {ComponentMap.ExpandButton}
        </div>
      </Card>
    )
  }

  render () {
    const { product, index, configure } = this.props
    const shouldTitleDisplay = this.props?.schema?.shouldTitleDisplay
    const isFullMatch = product.filter && product.filter.fullMatch
    const filterProductVisibility = configure.productListing.filterConfig.productVisibility

    let showProduct = false
    if (filterProductVisibility === 'showFullyMatched' && isFullMatch) {
      showProduct = true
    } else if (filterProductVisibility === 'showAll') {
      showProduct = true
    }
    if (!isNotDefined(shouldTitleDisplay) && shouldTitleDisplay) {
      this.props.updateHideShowTitle(showProduct)
    }
    return showProduct && this.drawListCard(index)
  }
}
const CardViewComponent = connect((state, props) => {
  const { productOptionId, insuranceType } = props
  const store = getStore()
  const selection = store.select(models => ({
    product: models.insuranceProducts.getProductById,
    productData: models.insuranceEnquiry.getProductData
  }))
  const selectordata = selection(state, { productOptionId, insuranceType })
  return {
    ...props,
    ...selectordata
  }
})(CardView)
const WithContext = adopt({
  dataAccess: <DataAccessContext.Consumer />,
  update: <UpdateContext.Consumer />,
  loading: <LoadingContext.Consumer />
})
const WrapperComponent = (props) => (
  <WithContext>
    {
      ({ dataAccess, update, loading }) => {
        const { device, configure, formData, insuranceType } = dataAccess
        const { onReview, onRiderChange, handleChange } = update
        return (
          <CardViewComponent
            {...props}
            onReview={onReview}
            device={device}
            configure={configure}
            formData={formData}
            onRiderChange={onRiderChange}
            insuranceType={insuranceType}
            loading={loading}
            handleChange={handleChange}
          />

        )
      }
    }
  </WithContext>
)
export default WrapperComponent
