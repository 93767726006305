import { authenticateUser, logoutUser, logoutKeycloakUser } from '../services/commonService'
// import { getCustomerHoldings } from '../services/insuranceService'
import { getFingerPrint, getQueryParams, getCookie } from '../commonUtils'
import { isNotDefined, isNotEmpty } from 'utils'
import jwtDecode from 'jwt-decode'
const customer = {
  name: 'customer',
  state: {
    loggedIn: false,
    keycloakUserLoggedIn: false,
    customerDetails: {},
    mfs: [],
    policies: [],
    fdrs: [],
    loans: [],
    stocks: []
  },
  selectors: (slice, createSelector, hasProps) => ({

    /**
     * Returns the value of loggedIn from the state
     *
     * @returns boolean
     */
    isCustomerLoggedIn () {
      return slice(customer => customer.loggedIn || false)
    },
    isDistributorLoggedIn () {
      return slice(customer => customer.keycloakUserLoggedIn || false)
    },
    // getCustomerHoldingsByType () {
    //   return createSelector(
    //     slice,
    //     (state, { holdingType }) => holdingType,
    //     (customer, holdingType) => {
    //       const holdings = customer[holdingType]
    //       if (isNotDefined(holdings)) {
    //         return []
    //       }
    //       return holdings
    //     }
    //   )
    // },

    /**
     * Return customer details from the state
     *
     * @returns object
     */
    getCustomerDetails () {
      return slice(customer => {
        const customerDetails = customer.customer
        return isNotEmpty(customerDetails) ? customerDetails : {}
      })
    }
  }),
  reducers: {

    /**
     * Sets the customerDetails in the state
     *
     * @param {*} state
     * @param {*} { customerDetails, holdings }
     * @returns object
     */
    updateCustomer (state, { customerDetails, holdings }) {
      state.customerDetails = customerDetails
      return state
    },

    /**
     * Sets customer object in the state along with loggedIn value as true that is if customer
     * object is not empty
     *
     * @param {*} state
     * @param {*} { customer }
     * @returns object
     */
    updateCustomerData (state, { customer }) {
      if (isNotEmpty(customer)) {
        state.customer = customer
        if (customer.role === 'employee') {
          state.keycloakUserLoggedIn = true
        } else {
          state.loggedIn = true
        }
      }
      return state
    },

    /**
     * Sets customer object in the state along with loggedIn value as true that is if customer
     * object is not empty and it sets initialized to true in the state
     *
     * @param {*} state
     * @param {*} { customer, authenticated }
     * @returns object
     */
    initializeCustomer (state, { customer, authenticated }) {
      state.customer = customer
      if (isNotEmpty(getCookie('oa-token'))) {
        if (jwtDecode(getCookie('oa-token'))?.role === 'employee') {
          state.keycloakUserLoggedIn = true
        } else {
          state.loggedIn = true
        }
      }
      state.initialized = true
      return state
    },

    /**
     * Sets loggedIn and customer values to false and empty object respectively
     *
     * @param {*} state
     * @param {*} payload
     * @returns object
     */
    removeCustomer (state, payload) {
      state.loggedIn = false
      state.keycloakUserLoggedIn = false
      state.customer = {}
      return state
    }
    // updateCustomerHoldings (state, { holdings }) {
    //   state.policies = holdings
    //   // FIXME: what is this holding type deal???
    //   // Object.keys(holdings).forEach(holdingType => {
    //   //   // FIXME: Dont know the holding types. Need to get supported holding types form config
    //   //   state[holdingType] = holdings[holdingType]
    //   // })
    //   return state
    // }
  },
  effects: (dispatch) => ({

    /**
     * Calls authenticateUser function and calls the dispatch updateCustomer with the response
     *
     * @param {*} payload
     * @param {*} rootState
     */
    async authenticate (payload, rootState) {
      const customer = await authenticateUser()
      dispatch.customer.updateCustomer({ customer })
    },
    // async loadCustomerHoldings (payload, rootState) {
    //   const { distributorId } = payload
    //   const { iamId } = rootState.customer.customer
    //   const holdings = await getCustomerHoldings({ distributorId, iamId })
    //   dispatch.customer.updateCustomerHoldings({ holdings })
    // },
    // Call only one at the begining

    /**
     * When initialized is not true authenticateUser function is called which gives customer as response.
     * Fingerprint and componets is obtained for the same.
     * Then all the data is passed in initializeCustomer reducer
     *
     * @param {*} { Modernizr }
     * @param {*} rootState
     */
    async initialize ({ Modernizr, reIntialize = false }, rootState) {
      const searchQuery = getQueryParams()
      // already called. no more calling
      if (rootState.customer.initialized && !reIntialize) {
        return
      }
      const { configure } = rootState
      const { customer } = await authenticateUser(searchQuery, configure?.redirectCookie)
      const { fingerPrint, components } = await getFingerPrint()
      let userAgent = components.find(ele => ele.key === 'userAgent')
      if (!isNotDefined(userAgent)) {
        userAgent = userAgent.value
      }
      // const customer = {
      //   gender: 'male',
      //   iamId: '3a771af5-dcc9-4365-b5b9-00204da219d4', // Default for all Insurance Types
      //   firstName: 'LOCKHART'
      // }
      dispatch.customer.initializeCustomer({ customer, fingerPrint, components, Modernizr, userAgent })
    },
    async logout (payload, rootState) {
      if (!rootState.configure.ownCustomerLogin) {
        const data = {
          accessToken: getCookie('oa-token'),
          refreshToken: getCookie('refresh-token')
        }
        const queryParams = {
          configType: 'auth'
        }
        await logoutKeycloakUser(data, queryParams)
      } else {
        await logoutUser()
      }
      dispatch.customer.removeCustomer()
    },
    async keycloakLogout (payload, rootState) {
      const data = {
        accessToken: getCookie('oa-token'),
        refreshToken: getCookie('refresh-token')
      }
      const queryParams = {
        configType: 'employeeAuth'
      }
      await logoutKeycloakUser(data, queryParams)
      dispatch.customer.removeCustomer()
    }
  })

}
export default customer
