
import { PureComponent } from 'react'
import { Form } from 'semantic-ui-react'
import { Caption } from 'components/Header'
import StyledInput from './StyledInput'
import ValidationErrorPopUp from 'components/ValidationErrorPopUp'
import { isNotNumber, isNotEmpty, scaleBackAmount, formatAmount } from 'utils'

const amountLabelOptions = [
  { key: 'lakh', text: 'Lakhs', value: 'l' },
  { key: 'crore', text: 'Crores', value: 'c' }
]

class Income extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      incomeUnit: 'L'
    }
    this.handleLabelChange = this.handleLabelChange.bind(this)
  }

  getIncome (income) {
    if (isNotNumber(income)) {
      return {
        amount: undefined,
        formattedAmount: undefined,
        amtDisplay: undefined,
        amtLabel: 'l'
      }
    } else {
      return formatAmount(income)
    }
  }

  handleLabelChange (saLabel) {
    const { handleChange, reviewHandleChange, formType } = this.props
    const { incomeValue } = this.state
    if (formType === 'review') {
      reviewHandleChange({
        income: {
          value: parseFloat(incomeValue),
          label: saLabel
        }
      }, 1)
    } else {
      handleChange({
        income: {
          value: parseFloat(incomeValue),
          label: saLabel
        }
      }, 1)
    }
  }

  handleChange (amountDisplay, amtLabel) {
    let value = scaleBackAmount(amountDisplay, amtLabel)
    if (value === 0) {
      value = undefined
    }
    this.props.handleChange({ income: parseFloat(value) }, amountDisplay ? amountDisplay.length : 0)
  }

  render () {
    const { label, id, formType, validationErrors, hdfcLandingPage, handleChange, income, getErrorMessageByKey, device } = this.props
    const { amtLabel, amtDisplay } = this.getIncome(income)
    const value = (amtDisplay === 0) ? undefined : amtDisplay
    const { incomeUnit } = this.state
    const errorMessage = getErrorMessageByKey('income')
    const error = isNotEmpty(errorMessage)
    if (hdfcLandingPage) {
      const incomeFactor = incomeUnit === 'L'
        ? 100000
        : 10000000
      return (
        <div id='income' className={`form-group col-lg-3 col-md-6 ${error && 'has-error'}`}>
          {error && (
            <div className='help-block with-errors'>
              <ul className='list-unstyled'>
                <li>{errorMessage}</li>
              </ul>
            </div>
          )}
          <div className='input-group w-100'>
            <input
              value={income ? (income / incomeFactor) : ''}
              type='number'
              className='form-control'
              placeholder='Annual Income'
              maxLength='4'
              autoComplete='off'
              required='required'
              data-error='Please enter annual income.'
              onChange={(event) => {
                if (event.target.value.length <= 4) {
                  let calcIncome = event.target.value * incomeFactor
                  if (calcIncome === 0) {
                    calcIncome = undefined
                  }
                  handleChange({ income: calcIncome }, 'income')
                }
              }}
            />
            <div className='input-group-append'>
              <select
                value={incomeUnit}
                id='annualincomedropdown'
                className='incomeselected'
                onChange={(event) => {
                  if (event.target.value !== incomeUnit) {
                    let value = income
                    if (event.target.value === 'L') {
                      value = income / 100
                    } else {
                      value = income * 100
                    }
                    this.setState({
                      incomeUnit: event.target.value
                    })
                    handleChange({ income: value }, 'income')
                  }
                }}
              >
                <option value='L'>Lakhs</option>
                <option value='C'>Crores</option>
              </select>
            </div>
          </div>
        </div>

      )
    }
    if (formType === 'single') {
      return (
        <div className='fieldDiv' id={id}>
          <Form.Field error={error}>
            <label>{label}</label>
            <StyledInput
              handleChange={(value) => this.handleChange(value, amtLabel)}
              key={`key${id}`}
              id='income'
              value={value}
              label={label}
              incLabel={amtLabel}
              options={amountLabelOptions}
              handleLabelChange={(amtLabel) => this.handleChange(amtDisplay, amtLabel)}
              dropdown='true'
              styleSelector='basicStyledcontainer single'
              includeDecimal
              placeholder='Enter Income'
              formType={formType}
            />
            {
              error ? <Caption id='income_error' color='red' className='errorMessage'>{validationErrors[0].message}</Caption> : <div className='noError' />
            }
          </Form.Field>
        </div>
      )
    }
    return (
      <>
        <Form.Field className='fieldWidth income' id={id}>
          <StyledInput
            error={error}
            className='centered'
            handleChange={(value) => this.handleChange(value, amtLabel)}
            id='income'
            value={value || ''}
            label={label}
            incLabel={amtLabel}
            options={amountLabelOptions}
            handleLabelChange={(amtLabel) => this.handleChange(amtDisplay, amtLabel)}
            dropdown='true'
            styleSelector='basicStyledcontainer step income'
            includeDecimal
            placeholder='Anual Income'
          />
        </Form.Field>
        {error && (device !== 'mobile') && (device !== 'mobile') && <ValidationErrorPopUp errorMessage={errorMessage} />}
      </>
    )
  }
}

export default Income
