import { Statistic } from 'semantic-ui-react'

const StatisticPeriod = (props) => {
  const { className, textAlign, label, data, id, labelColor } = props
  const classNames = `${className ?? ''} secondary`
  return (
    <Statistic textAlign={textAlign} className={classNames}>
      <Statistic.Label color={labelColor}>{label}</Statistic.Label>
      <Statistic.Value id={id}>
        {`${data} Years`}
      </Statistic.Value>
    </Statistic>
  )
}
export default StatisticPeriod
