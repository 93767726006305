import DisplayHtmlString from 'components/DisplayHtmlString'
import { Caption, Header, Subtitle2 } from 'components/Header'

const HeadAndBody = (data) => {
  return (
    <>
      <div className='productBenefitsData'>
        <Header color='primaryLight' className='titleHeader'>
          {data.head}
        </Header>
        {data.body?.map((pb, index) => (
          <div className='pbData' key={index}>
            <Subtitle2>{pb.heading}</Subtitle2>
            <Caption className='pbDescription'><DisplayHtmlString htmlContent={pb.description || pb.descriptionStatic} /></Caption>
          </div>
        ))}
      </div>
    </>
  )
}
export default HeadAndBody
