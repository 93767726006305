import { useRef, useEffect, useState } from 'react'
import PolicyNature from '../userApplications/PolicyNature'
import { List, Segment, ListItem } from 'semantic-ui-react'
import { Subtitle2, H6 } from 'components/Header'

import ProductImageAndNameWithDescription from '../ProductImageAndNameWithDescription'
import SumAssuredAndStatus from '../SumAssuredAndStatus'

import '../dashBoard.scss'
import 'components/css/loader.scss'
import TableComponent from 'components/TableComponent'

const UserPoliciesListing = (props) => {
  const {
    isLoggedIn,
    loadPolicies,
    loadingPolicies,
    policies,
    updateSelectedPolicy,
    count
  } = props

  const previousLoading = useRef(false)
  const selectorItemData = { data: policies, count }
  const [pageRow, setPageRow] = useState(10)
  const [pageChange, setPageChange] = useState(0)
  const [skipPage, setSkipPage] = useState(0)
  useEffect(() => {
    if (isLoggedIn) {
      loadPolicies({ skip: skipPage, limit: pageRow })
    }
  },
  // eslint-disable-next-line
  [pageChange, pageRow])

  const renderTable = () => {
    return (
      <TableComponent
        {...props}
        selectorItemData={selectorItemData}
        pageRow={pageRow}
        pageChange={pageChange}
        setSkipPage={setSkipPage}
        setPageRow={setPageRow}
        setPageChange={setPageChange}
      />
    )
  }
  useEffect(() => {
    previousLoading.current = loadingPolicies
  }, [loadingPolicies])
  let loading
  let content
  if (!(previousLoading.current && !loadingPolicies)) {
    loading = (
      <div className='loaderOuter applicationLoader' id='loading'>
        <div className='ball-spin-fade-loader'>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    )
  } else {
    content = (policies.length === 0)
      ? (
        <div className='noOngoingPolicies'>
          <Subtitle2 color='secondary'>No Ongoing Policy</Subtitle2>
        </div>
        )
      : (
        <>
          <List>
            {policies.map((policy, index) => {
              return (
                <div key={index}>
                  <ListItem className='listingItems'>
                    <ProductImageAndNameWithDescription data={policy} />
                    <SumAssuredAndStatus title='Policy' data={policy} />
                    {
                    policy.insuranceType === 'health' &&
                      (
                        <PolicyNature title='Policy' data={policy} />
                      )
                    }
                    {/* ----- Button Part ---- */}
                    <div className='policyDetailsButton'>
                      <Subtitle2 color='secondary' className='subtitle2Btn'>
                        <div
                          className='iconDiv'
                          onClick={() => updateSelectedPolicy({ id: policy.application.applicationNumber })}
                        >
                          DETAILS
                        </div>
                      </Subtitle2>
                    </div>
                    {/* ----- Button Part ---- */}
                  </ListItem>
                  <hr hidden={index + 1 === policies.length} />
                </div>
              )
            }
            )}
          </List>
          {(renderTable())}
        </>
        )
  }
  return (
    <Segment className={(!(previousLoading.current && !loadingPolicies)) ? 'userPolicies loaderParent' : 'userPolicies'}>
      <H6 color='primaryLight' className='h6' content='Your Policies' />
      {loading}
      {content}
    </Segment>
  )
}

export default UserPoliciesListing
