import { isNotDefined } from 'utils'

import UserPoliciesListing from './UserPoliciesListing'
import PolicyDetailsMainPage from './PolicyDetailsMainPage'

const UserPolicies = props => {
  const {
    policies,
    loadPolicies,
    loadingPolicies,
    isLoggedIn,
    selectedApplication,
    selectedPolicyData,
    loadPolicyDetails,
    updateSelectedPolicy,
    loadingPolicyDetails,
    count,
    device
  } = props

  if (!isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)) {
    return null
  } else if (isNotDefined(selectedApplication) && !isNotDefined(selectedPolicyData)) {
    return (
      <PolicyDetailsMainPage
        policy={selectedPolicyData}
        device={device}
        loadPolicyDetails={loadPolicyDetails}
        loadingPolicyDetails={loadingPolicyDetails}
      />
    )
  } else if (isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)) {
    return (
      <UserPoliciesListing
        isLoggedIn={isLoggedIn}
        count={count}
        loadPolicies={loadPolicies}
        loadingPolicies={loadingPolicies}
        policies={policies}
        updateSelectedPolicy={updateSelectedPolicy}
      />
    )
  }
}
export default UserPolicies
