import { useState, useCallback, useMemo } from 'react'
import { Accordion, Button, ButtonGroup, Divider, Icon, Modal } from 'semantic-ui-react'
import { Header, Subtitle2 } from 'components/Header'
import { isEqual, omit } from 'lodash'
import MoreFilterComponents from './MoreFilterComponents'
import './MoreFilters.scss'

function MoreFilters ({ handleChange, id, smartConfigure: { productListing: { filterConfig: { filterMenuConfig } } }, filterData: { filterOptions }, device }) {
  const [activeIndex, setActiveIndex] = useState(-1)
  const [open, setOpen] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(Object.keys(filterMenuConfig)[0])
  const [filters, setFilters] = useState(filterOptions)
  const [prevFilters, setPrevFilters] = useState(filterOptions)

  const clickHandler = useCallback((e, { value, index }) => {
    if (device === 'mobile') {
      setSelectedFilter(value)
      setActiveIndex(index)
    } else {
      setSelectedFilter(e.target.value)
    }
  }, [device])

  const onApply = () => {
    setOpen(false)
    setPrevFilters(filters)
    handleChange(filters, id, true)
  }

  const onCancel = useCallback(() => {
    setFilters(prevFilters)
    setOpen(false)
  }, [prevFilters])

  const onReset = () => {
    const resetFilters = { ...filters }
    filterMenuConfig[selectedFilter].options.forEach(option => {
      const { value } = option
      const { key, type } = filterMenuConfig[selectedFilter]
      if (type === 'checkbox') {
        resetFilters[value] = { include: false }
      } else if (type === 'radio') {
        const isNoPreference = /No Preference/ig.test(option.label)
        if (isNoPreference) {
          const reset = { ...filters[key], ...value ?? {} }
          resetFilters[key] = reset
        }
      }
    })
    setFilters(resetFilters)
  }

  const handleChipDelete = useCallback((key, type, reset) => {
    setFilters(prevState => {
      if (type === 'checkbox') {
        return { ...prevState, [key]: { include: false } }
      } else if (type === 'radio') {
        return { ...prevState, [key]: reset }
      }
      return prevState
    })
  }, [setFilters])

  const renderChips = useMemo(() => {
    const chipContainers = []
    Object.keys(filterMenuConfig).forEach((filter) => {
      const { key, options, type } = filterMenuConfig[filter]
      if (type === 'checkbox') {
        options.forEach((opt) => {
          const { label, value } = opt
          if (filters[value].include === true) {
            chipContainers.push(
              <div className='chip-container' key={value}>
                <span>{label}</span>
                <i className='fa fa-times remove-icon' onClick={() => handleChipDelete(value, type)} />
              </div>
            )
          }
        })
      } else if (type === 'radio') {
        const selectedOption = options.find(opt => isEqual(omit(filters[key], 'searchKey'), opt.value))
        const noPreference = options.find(opt => /No preference/ig.test(opt.label))
        const reset = { ...filters[key], ...noPreference.value ?? {} }
        if (selectedOption?.label && !/No preference/ig.test(selectedOption.label)) {
          chipContainers.push(
            <div className='chip-container' key={key}>
              <span>{selectedOption.label}</span>
              <i className='fa fa-times remove-icon' onClick={() => handleChipDelete(key, type, reset)} />
            </div>
          )
        }
      }
    })
    return chipContainers
  }, [filters, filterMenuConfig])

  return (
    <div>
      {device === 'mobile'
        ? (
          <Header role='button' color='black' sub onClick={() => setOpen(true)}>
            More Filters &nbsp;
            <Icon name='filter' />
          </Header>
          )
        : (
          <div role='button' className='gap-2 d-flex flex-column align-items-center' onClick={() => setOpen(true)}>
            <Subtitle2 color='secondary' sub>
              Filters
            </Subtitle2>
            <Icon name='filter' className='secondary' />
          </div>
          )}
      <Modal
        title='Select Filters'
        id='more-filters-modal'
        open={open}
        className='more-filters-modal'
      >
        <div className='filter-menu-container'>
          <Modal.Header className='w-100'>
            <div className='p-1 d-flex justify-content-between w-100'>
              <Subtitle2>Select Filter</Subtitle2>
              <Button basic icon onClick={onCancel} title='Close'>
                <Icon name='close' size='large' />
              </Button>
            </div>
            <span className='flex-wrap d-flex'>
              {renderChips}
            </span>
          </Modal.Header>
          <Divider className='m-0 w-100' />
          {(device === 'mobile')
            ? (
              <Modal.Content className='d-flex justify-content-between flex-column flex-md-row w-100 flex-grow-1'>
                <Accordion styled>
                  {Object.keys(filterMenuConfig).map((filter, index) => {
                    const { key, label } = filterMenuConfig[filter]
                    return (
                      <div key={index}>
                        <Accordion.Title value={key} index={index} active={index === activeIndex} onClick={clickHandler}>
                          <Icon name='dropdown' />
                          {label}
                        </Accordion.Title>
                        <Accordion.Content active={index === activeIndex}>
                          <MoreFilterComponents activeFilter={filterMenuConfig[selectedFilter]} filter={filters} setFilters={setFilters} />
                        </Accordion.Content>
                      </div>
                    )
                  })}
                </Accordion>
              </Modal.Content>
              )
            : (
              <Modal.Content className='d-flex flex-column flex-md-row w-100 flex-grow-1'>
                <ButtonGroup vertical className='filter-button-left'>
                  {Object.keys(filterMenuConfig).map((filter) => {
                    const { key, label } = filterMenuConfig[filter]
                    return (
                      <Button value={key} onClick={clickHandler} key={key}>
                        {label}
                      </Button>
                    )
                  })}
                </ButtonGroup>
                <div className='align-center'>
                  <MoreFilterComponents activeFilter={filterMenuConfig[selectedFilter]} filter={filters} setFilters={setFilters} />
                </div>
              </Modal.Content>)}

          <Divider className='m-0 w-100' />
          <Modal.Actions className='m-2'>
            <Button onClick={onReset}>Reset</Button>
            <Button primary onClick={onApply}>Apply</Button>
          </Modal.Actions>
        </div>
      </Modal>
    </div>
  )
}

export default MoreFilters
