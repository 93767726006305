import { PureComponent } from 'react'
import CardView from './CardView'
import { isNotDefined } from 'utils'
import { Card } from 'semantic-ui-react'
import { Subtitle2 } from 'components/Header'
import CardViewSkeleton from './CardViewSkeleton'
import CardViewSkeletonMobile from './CardViewSkeletonMobile'
// import { isUndefined } from 'lodash'
const columnCount = 3
class SchemaRowDisplay extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      displayTitle: true
    }
  }

  updateHideShowTitle (showTitle) {
    this.setState({
      displayTitle: showTitle
    })
  }

  render () {
    const {
      schema: { title, items, shouldTitleDisplay },
      expandedProduct,
      device,
      insuranceType,
      // eslint-disable-next-line
      products
    } = this.props
    const SkeletonComponent = (device === 'mobile')
      ? CardViewSkeletonMobile
      : CardViewSkeleton
    const skeletonElements = []
    let existingItems = 0
    if (!isNotDefined(items)) {
      existingItems = items.length
    }
    for (let c = 0; c < columnCount - existingItems; c++) {
      skeletonElements.push(<SkeletonComponent />)
    }
    // eslint-disable-next-line
    // let insurerIdItem 
    // eslint-disable-next-line
    // items.map(item => {
    //   if (!isUndefined(item.insurerId)) {
    //     insurerIdItem = item.insurerId
    //   }
    // })

    return (
      <>
        {(!isNotDefined(shouldTitleDisplay) && shouldTitleDisplay) && <Subtitle2 color='black' className='productCategoryTitle' content={title} />}
        <Card.Group stackable centered className='productCards'>
          {
            items.map((product, index) => {
              if (!isNotDefined(product.productLoaded) && !product.productLoaded) {
                return <SkeletonComponent key={index} insuranceType={insuranceType} />
              }
              if (product.productOptionId && (expandedProduct === -1 || expandedProduct === product.productOptionId)) {
                return (
                  <CardView
                    {...this.props}
                    key={`cardview_${index}`}
                    index={index}
                    updateHideShowTitle={e => this.updateHideShowTitle(e)}
                    productOptionId={product.productOptionId}
                    expandedProduct={expandedProduct}
                  />
                )
              } else {
                return null
              }
            })
          }
          {/* {
            isNotDefined(expandedProduct) && (device !== 'mobile') && skeletonElements
          } */}
          {
            isNotDefined(expandedProduct) && (device === 'mobile') && <SkeletonComponent />
          }
        </Card.Group>
      </>
    )
  }
}

export default SchemaRowDisplay
