import { Button } from 'semantic-ui-react'
import { isNotDefined } from 'utils'
import '../dashBoard.scss'

const DashboardBackButton = (props) => {
  const {
    updateSelectedPolicy,
    updateSelectedApplicationNumber,
    selectedApplication,
    selectedPolicyData,
    device,
    className
  } = props
  const onClickHandler = () => {
    updateSelectedPolicy({ id: '' })
    updateSelectedApplicationNumber({ applicationNumber: '' })
  }
  return (
    <div
      className={device === 'mobile' ? className : ''}
      onClick={onClickHandler}
      hidden={isNotDefined(selectedApplication) && isNotDefined(selectedPolicyData)}
    >
      <Button basic inverted content='Back to DashBoard' />
    </div>
  )
}

export default DashboardBackButton
