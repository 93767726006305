import { Header } from 'components/Header'

const Subtitle2 = ({ children, ...restProps }) => {
  return (
    <Header size='large' {...restProps}>
      {children}
    </Header>
  )
}

export default Subtitle2
