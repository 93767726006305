import { List, Image } from 'semantic-ui-react'
import { H6, Caption, Subtitle2, Body2 } from 'components/Header'
import Description from '../../Description'
import DisplayDescription from 'components/DisplayDescription'
import Tooltip from 'rc-tooltip' // FIXME: use tooltipcomponent
import 'rc-tooltip/assets/bootstrap_white.css'
import Feature from './Feature'
import { buildCommonImagePath } from 'components/buildImagePath'
const overlayStyle = {
  width: 250,
  fontSize: 14,
  opacity: 1,
  boxShadow: '0px 2px 4px 0px rgba(34, 36, 38, 0.12)'
}
const Features = (props) => {
  const { features, contentType, view, productOptionId, device } = props
  const featuresDisplay = features.map((feature, ix) => {
    if (Object.prototype.hasOwnProperty.call(feature, 'riderId') && feature.riderId.length === -1) {
      if (contentType === 'detail') {
        return (
          <Feature
            view={view}
            contentType={contentType}
            productOptionId={productOptionId}
            feature={feature}
            key={`${ix}_feature`}
            id={`${productOptionId}-features${ix}`}
          />
        )
      } else {
        const { heading, description, points } = feature
        return (
          <List.Item className='benefitsListItem' key={ix + 'benefits'}>
            <Image size='size16' src={buildCommonImagePath('check-grey-icon.png')} />
            <Caption className='riderCaption'>
              {heading.trim()}
            </Caption>
            <Tooltip
              disable={device === 'mobile'}
              placement='right'
              overlay={
                <div>
                  <div><strong>{heading}</strong></div>
                  <DisplayDescription
                    description={description}
                    points={points}
                  />
                </div>
              }
              overlayStyle={overlayStyle}
              arrowContent={<div className='rc-tooltip-arrow-inner' />}
            >
              <Image size='size12' src={buildCommonImagePath('more-info-icon.png')} />
            </Tooltip>
          </List.Item>
        )
      }
    } else {
      if (feature.pricing === 'free') {
        return (
          <Feature
            view={view}
            contentType={contentType}
            productOptionId={productOptionId}
            feature={feature}
            key={`${ix}_feature`}
            id={`${productOptionId}-features${ix}`}
          />
        )
      } else {
        return null
      }
    }
  })
  if (contentType === 'detail') {
    const filteredFeature = features.filter((feature) => feature.pricing !== 'paid')
    return (
      <div>
        <H6 color='primaryLight'>
          Features
        </H6>
        <List>
          {
            filteredFeature.length > 0
              ? filteredFeature.map((feature, ix) => (
                <Description description={feature.description} heading={feature.heading} points={feature.points} key={ix} />
              ))
              : <Body2>No features found for this product</Body2>
          }
        </List>
      </div>
    )
  }
  return (
    <div>
      <Subtitle2 color='secondary'>
        Features
      </Subtitle2>
      <List>
        {featuresDisplay}
      </List>
    </div>
  )
}

export default Features
