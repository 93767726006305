import DashboardJsonSchemaForm from 'components/jsonSchemaForms/DashboardJsonSchemaForm'
import './Dashboard.scss'
import { Button } from 'semantic-ui-react'

export const DashboardFilter = (props) => {
  const { count } = props
  const objectTemplate = (props) => {
    const { properties } = props

    const mappedFields = properties.filter((element) => !['object', 'array'].includes(element.content.props.schema.type))
    return (
      <>
        <div className='datefilter'>
          {mappedFields.filter((ele) => ele.name.includes('date')).map((element) => {
            const key = element.name + Math.round(1000 * Math.random())
            return (
              <div key={key} className='property-wrapper'>
                {element.content}
              </div>
            )
          })}
        </div>
        <div className='dropDownField'>
          {mappedFields.filter((ele) => !ele.name.includes('date')).map((element) => {
            const key = element.name + Math.round(10000 * Math.random())
            return (
              <div key={key} className='property-wrapper'>
                {element.content}
              </div>
            )
          })}
        </div>
      </>
    )
  }
  const renderRecordCount = () => {
    let label = (count && count > 0 && count < 2) ? `Record Count: ${count}` : 'No Records'
    label = count && count > 1 ? `Records Count: ${count}` : label
    return <span><b>{label}</b></span>
  }
  return (
    <DashboardJsonSchemaForm
      onChange={props.onChange}
      formData={props.formData}
      onSubmit={props.onSubmit}
      objectTemplate={objectTemplate}
      rules={props.rules}
      uiSchema={props.uiSchema}
      schema={props.schema}
      className='dashboardForm'
    >
      <div className='filterbtnAndCountLabel'>
        <Button
          ref={props.buttonRef}
          secondary
          content='Submit'
        />
        {renderRecordCount()}
      </div>
    </DashboardJsonSchemaForm>
  )
}
