
import FormInput from 'components/FormInput'

const AgeWidget = (props) => {
  const {
    id,
    value,
    readonly,
    disabled,
    onBlur,
    onFocus,
    options,
    placeholder
  } = props
  return (
    <FormInput
      placeholder={placeholder}
      value={value}
      onChange={(e, { value }) => props.onChange(value.length === 0 ? options.emptyValue : parseInt(value))}
      disabled={disabled}
      readOnly={readonly}
      id={id}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
      options={options}
   />
  )
}
export default AgeWidget
