import { Header } from 'components/Header'

const H6 = ({ children, ...restProps }) => {
  return (
    <Header as='h6' {...restProps}>
      {children}
    </Header>
  )
}

export default H6
