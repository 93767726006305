import { Icon } from 'semantic-ui-react'
import { Caption, Body1, Body2 } from 'components/Header'
import { isNotDefined } from 'utils'
import { formatAmount } from 'utils/lib/calc'

const SumAssuredAndStatus = ({ data, title }) => {
  let status = 'No Status'
  let expiringIn = ''
  let smartCovrCode
  if (title === 'Policy') {
    smartCovrCode = data.application.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode
  } else {
    smartCovrCode = data.ApplicationStatuses[0].SmartcovrStatus.smartcovrCode
  }
  if (!isNotDefined(data.expiringIn) && ['SC005', 'SC092', 'SC093', 'SC094', 'SC095'].includes(smartCovrCode)) {
    if (data.expiringIn <= 0) {
      expiringIn = `${title} Expired ${Math.abs(data.expiringIn)} days ago`
    } else {
      expiringIn = `${title} Expiring in ${data.expiringIn} days`
    }
  }
  const payoutFrequency = data.productData?.payoutFrequency

  let label
  if (payoutFrequency === 'A' || payoutFrequency === 'S') {
    label = ' / Year'
  } else if (payoutFrequency === 'H') {
    label = ' / Half Year'
  } else if (payoutFrequency === 'Q') {
    label = ' / Quarter'
  } else if (payoutFrequency === 'M') {
    label = ' / Month'
  }

  let amtDisplay
  let amtLabelDisplay
  let caption
  if (['term', 'health'].includes(data.insuranceType)) {
    caption = 'Sum Assured'
    amtDisplay = formatAmount(data.sumAssured).amtDisplay
    amtLabelDisplay = formatAmount(data.sumAssured).amtLabelDisplay
  } else if (data.insuranceType === 'annuity') {
    caption = 'Annuity Payout'

    const amountWithLabels = (amount) => {
      const crore = 10000000
      const lakh = 100000
      const tho = 1000
      if (amount > crore) {
        return {
          amount: (amount / crore).toFixed(2),
          label: ' C'
        }
      } else if (amount >= lakh) {
        return {
          amount: (amount / lakh).toFixed(2),
          label: ' L'
        }
      } else {
        return {
          amount: (amount / tho).toFixed(0),
          label: ' K'
        }
      }
    }
    const annuityPayout = amountWithLabels(data.AnnuityApplication.annuityPayout)
    amtDisplay = annuityPayout.amount
    amtLabelDisplay = annuityPayout.label
  } else if (['car', 'bike'].includes(data.insuranceType)) {
    caption = 'IDV'
    amtDisplay = formatAmount(!isNotDefined(data.AutoApplication) ? data.AutoApplication.idv : data.idv).amtDisplay
    amtLabelDisplay = formatAmount(!isNotDefined(data.AutoApplication) ? data.AutoApplication.idv : data.idv).amtLabelDisplay
    if (amtDisplay === 0 || amtDisplay === null) {
      amtDisplay = 'N/A'
      amtLabelDisplay = ''
    } else {
      amtDisplay = amtDisplay.toFixed(2)
    }
  }
  if (!isNotDefined(data.ApplicationStatuses) && data.ApplicationStatuses[0] !== undefined) {
    status = data.ApplicationStatuses[0].SmartcovrStatus.smartcovrDisplayText
  }
  if (!isNotDefined(data.application) && !isNotDefined(data.application.ApplicationStatuses) && data.application.ApplicationStatuses[0] !== undefined) {
    status = data.application.ApplicationStatuses[0].SmartcovrStatus.smartcovrDisplayText
  }

  return (
    <div className='secondHalf'>
      <div className='sumAssuredValue'>
        <Caption>
          {caption}
        </Caption>
        <Body1 color='green' className='body1'>

          {amtDisplay !== 'N/A' && <Icon size='small' name='rupee' />}
          {
            data.insuranceType === 'annuity'
              ? (
                <span>{`${amtDisplay} ${amtLabelDisplay}${label}`}</span>
                )
              : (<span>{`${amtDisplay} ${amtLabelDisplay}`}</span>)
          }

        </Body1>
      </div>
      <div className='Status'>
        <Caption>
          Status
        </Caption>
        <Body1 color='yellow'>
          {status}
        </Body1>
        <Body2 color='red'>
          {expiringIn}
        </Body2>
      </div>
    </div>
  )
}

export default SumAssuredAndStatus
