import { PureComponent } from 'react'
import { List } from 'semantic-ui-react'
import CheckBoxListItem from 'components/CheckBoxListItem'
import EditAddOns from '../../EditAddOns'

class ListingIncludedAddons extends PureComponent {
  render () {
    const {
      rider,
      schema,
      uiSchema,
      riderFormData,
      rules,
      productOptionId,
      checked,
      heading,
      description,
      shortName,
      points,
      riderLoading,
      childKey,
      device,
      isEdit,
      onSubmit,
      onCancel,
      insuranceType,
      updatePopUp
    } = this.props
    const displayAmount = 'Included'
    // FIXME: Move the popupContent to seperate class.
    const popUpContent = (
      <List.Content className='riderEdit'>
        <EditAddOns
          schema={schema}
          uiSchema={uiSchema}
          formData={riderFormData}
          rules={rules}
          onSubmit={onSubmit}
          submitText='UPDATE ADDON'
          onCancel={onCancel}
          size='medium'
        />
      </List.Content>
    )
    const listItemPopUp = {
      isEdit,
      editable: true,
      onCheckBoxClick: () => {},
      onHeaderClick: updatePopUp,
      heading,
      description,
      shortName,
      points,
      displayAmount,
      id: rider.riderId,
      productOptionId,
      checked,
      loading: riderLoading,
      popUpContent,
      childKey,
      device,
      insuranceType
    }
    return (
      <CheckBoxListItem {...listItemPopUp} />
    )
  }
}
export default ListingIncludedAddons
