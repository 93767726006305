import { getStore } from '../store'

/**
   * Creates the errorData object
   *
   * @param {*} err
   * @param {*} state
   * @param {*} action
   * @param {*} dispatch
   * @returns
   */
const errorHandler = (err, state, action, _dispatch) => {
  // Log error to server. Error Push
  const errorData = {
    message: err.message,
    stack: err.stack,
    data: {
      action,
      state
    }
  }
  // send errors to server.
  console.log(errorData)
}

/**
   * Gets store object and sets the payload by calling setCurrentError reducer
   *
   * @param {*} payload
   * @returns
   */
const dispatchError = (payload) => {
  const store = getStore()
  store.dispatch.errors.setCurrentError(payload)
}

/**
   * Sets the type of error in the state based on the value of errorType
   *
   * @param {*} store
   * @returns error object
   */
const errorMiddleware = store => next => async action => {
  try {
    return await next(action)
  } catch (err) {
    errorHandler(err, store.getState, action)
    if (err.errorType === 'CustomError') {
      dispatchError({ errorName: 'uiApiError', code: err.code })
    } else if (err.errorType === 'ServerApiError') {
      if (err.name === 'TokenExpiredError') {
        dispatchError({ errorName: 'clientSessionExpired', code: err.code })
      } else if (err.data.message === 'Network Error') {
        dispatchError({ errorName: 'networkError', code: err.code })
      } else {
        dispatchError({ errorName: err.name, code: err.code })
      }
    } else if (err.errorType === 'FieldNotFound') {
      dispatchError({ errorName: 'incorrectFieldsPassed', code: err.code })
    } else if (err.errorType === 'FieldInvalid') {
      if (err.name === 'InvalidEnquiry') {
        dispatchError({ errorName: err.name, code: err.code })
      } else if (err.name === 'InvalidProductOptionId') {
        dispatchError({ errorName: err.name, code: err.code })
      } else {
        dispatchError({ errorName: 'invalidFieldPassed', code: err.code })
      }
    } else {
      // FIXME: Default condition
      dispatchError({ errorName: 'uiApiError', code: err.code })
    }
    return err
  }
}

export default errorMiddleware
