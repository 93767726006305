import GeneralInfoScreen from './GeneralInfoScreen'

const FetchingProducts = (props) => {
  return (
    <GeneralInfoScreen
      className='fetchingProducts'
      {...props}
    />
  )
}
export default FetchingProducts
