import { isNotDefined, isNotEmpty } from 'utils'
import { ServerApiError } from '../errors'
import FieldNotFound from '../errors/FieldNotFound'
import { requestWrapper } from '../setUpStore'
import { getService } from './index'

const { setCookie, getRedirectFromLocalState } = require('../commonUtils')

const getProductsForInstitution = async ({ institutionId, insuranceType, distributorId }) => {
  const query = {
    institutionId,
    insuranceType,
    distributorId
  }
  try {
    const { institutionService } = getService(insuranceType)
    return await institutionService.get('products', { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetProductsError('smartCovrApi:commonService:getProductsForInstitution', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}
const loadEnquiry = async (enquiryId, query) => {
  let response
  try {
    const { enquiryService } = getService(query.productType)
    response = await enquiryService.get(enquiryId, { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.LoadEnquiryError('smartCovrApi:commonService:loadEnquiry', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const makeTptTokenRequest = async (requestData, queryParams) => {
  let response
  try {
    const { tptTokenService } = getService()
    response = await tptTokenService.create(requestData, { query: queryParams })
    setCookie('oa-token', response.accessToken, null, null, true)
    setCookie('refresh-token', response.refreshToken, null, null, true)
    window.location.href = decodeURIComponent(requestData.redirectTo)
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.LoginError('smartCovrApi:commonService:Tpttoken', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

const makeTptLoginRedirectRequest = async (queryParams, redirectToPathFn) => {
  let response
  try {
    const { productType: insuranceType } = queryParams
    const { tptLoginRedirect } = getService()
    response = await tptLoginRedirect.create({}, { query: queryParams })
    setCookie('oa-token', response.accessToken, null, null, true)
    setCookie('redirectTo', response.redirectTo, null, null, true)
    redirectToPathFn(`/${insuranceType}/tptloginredirect-handler`, { backpath: `/${insuranceType}/tpt-handler` })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.LoginError('smartCovrApi:commonService:TptLoginRedirect', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

const makeTptLoginCustomerRedirectRequest = async (requestData, queryParams) => {
  let response
  try {
    const { tptCustomerTokenService } = getService()
    response = await tptCustomerTokenService.create(requestData, { query: queryParams })
    setCookie('oa-token', response.accessToken, null, null, true)
    setCookie('redirectTo', response.redirectTo, null, null, true)
    setCookie('refresh-token', response.refreshToken, null, null, true)
    window.location.href = decodeURIComponent(requestData.redirectTo)
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.LoginError('smartCovrApi:commonService:TptLoginRedirect', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

const updateEnquiryToDb = async (enquiry, productType, enquiryId) => {
  const query = { productType }
  if (!isNotDefined(enquiryId) && enquiryId.length > 0) {
    let response
    try {
      const { enquiryService } = getService(productType)
      response = await enquiryService.update(enquiryId, enquiry, { query })
    } catch (err) {
      if (err.errorType === 'ServerApiError') {
        throw err
      }
      throw new ServerApiError.UpdateEnquiryToDbError('smartCovrApi:commonService:updateEnquiryToDb', {
        message: err.message,
        code: err.code,
        stack: err.stack
      })
    }
    return response
  }
  throw new FieldNotFound.EnquiryIdNotFound()
}
const getDistributorConfigByType = async ({ configType, distributorId }) => {
  const query = {
    configType,
    distributorId
  }
  let response
  try {
    const { configureService } = getService()
    response = await configureService.get('distributor', { query })
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetDistributorConfigurationError('smartCovrApi:commonService:getDistributorConfigByType', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}
const sendMail = (data, mailType) => {
  const { mailerService } = getService()
  return mailerService.create(
    data,
    {
      query: {
        mailType
      }
    }
  )
    .then((response) => {
      return Promise.resolve(response)
    })
    .catch(err => {
      console.log(err)
      return Promise.reject(err)
    })
}
const getFileFromFileStore = async (fileUrl) => {
  const query = {
    fileUrl
  }
  let response
  try {
    const { uploadService } = getService()
    response = await uploadService.get('file', { query })
  } catch (err) {
    console.log(err)
    throw err
  }
  return response
}

const uploadFile = async (data, params) => {
  try {
    const { uploadService } = getService()
    return await uploadService.create(data, { query: { params } })
  } catch (err) {
    console.log(err)
    throw err
  }
}
const logoutKeycloakUser = async (data, queryParams) => {
  try {
    const { keycloakService } = getService()
    await keycloakService.create(data, { query: queryParams })
    logoutUser()
  } catch (err) {
    console.log(err)
    throw err
  }
}
const authenticateUser = async (searchQuery = {}, redirectCookie = {}) => {
  const { app } = getService()
  try {
    const response = await app.authenticate()
    const { user, exp } = response
    window.localStorage.setItem('exp', exp)
    let redirectTo = '/'
    if (isNotEmpty(searchQuery)) {
      redirectTo = getRedirectFromLocalState(searchQuery)
    }
    const { name, maxAge, path } = redirectCookie
    setCookie(name, redirectTo, maxAge, path, true)
    return {
      customer: user
    }
  } catch (err) {
    await app.logout()
    return {
      customer: {}
    }
  }
}
const logoutUser = async () => {
  // check if we have to delete explicitly
  const { app } = getService()
  await app.logout()
}
const getCustomer = async ({ iamId, role, distributorId }) => {
  const query = {
    distributorId,
    role
  }
  let customer
  try {
    const { customerService } = getService()
    customer = await customerService.get(iamId, { query })
    return customer
  } catch (err) {
    if (err.errorType === 'ServerApiError') {
      throw err
    }
    throw new ServerApiError.GetCustomerError('smartCovrApi:commonService:getCustomer', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
}

const tptCustomerPost = async ({ enquiryId, distributorId, insuranceType }) => {
  const query = {
    enquiryId,
    distributorId
  }
  const randomNumber = Math.floor(Math.random() * 100000000)
  let response
  const data = {
    KYCRSP_RETCODE: 'S',
    KYCRSP_RETMSG: 'Success',
    KYCRSP_DOB: '02/02/1990',
    KYCRSP_RETERRNUM: '0',
    KYCRSP_MESG_SEQ: '12345',
    KYCRSP_CLIENT_ID: randomNumber,
    KYCRSP_CLIENT_NAME: 'DHIRJ K KUNDER',
    KYCRSP_FATHER_NAME: 'K',
    KYCRSP_ADDRESS1: '707, Sai Prasad Co Hsg Soc',
    KYCRSP_ADDRESS2: 'NA C Saiwadi',
    KYCRSP_ADDRESS3: 'Andheri East',
    KYCRSP_CITY: 'Mumbai',
    KYCRSP_STATE: 'Maharashtra',
    KYCRSP_COUNTRY: 'India',
    KYCRSP_PINCODE: '400069',
    KYCRSP_PANNO: 'FKRIP5525N',
    KYCRSP_PHONE1: '26870270',
    KYCRSP_PHONE2: '26820720',
    KYCRSP_MOBILE1: '9773195671',
    KYCRSP_MOBILE2: '',
    KYCRSP_EMAIL_TO: `abc${randomNumber}@hotmail.com`,
    KYCRSP_EMAIL_CC: '',
    KYCRSP_EMAIL_BCC: '',
    KYCRSP_ISD1: '091',
    KYCRSP_ISD12: '091',
    KYCRSP_STD1: '022',
    KYCRSP_STD2: '022',
    KYCRSP_BANK_ACC: '8641567891',
    KYCRSP_DP_DETAILS: 'NSDL^IN300126^45476578',
    KYCRSP_OCCUPATION: 'Private Sector',
    KYCRSP_OCCUPATION_REASON: '',
    KYCRSP_EMAILRELATION: '1',
    KYCRSP_MOBILERELATION: '1',
    KYCRSP_EMAILDECLARATIONFLAG: '1',
    KYCRSP_MOBILEDECLARATIONFLAG: 'N',
    KYCRSP_GSTIN: '',
    KYCRSP_AadharNumber: '962424513698',
    KYCRSP_INCOME_RANGE: '',
    KYCRSP_GENDER: 'Male',
    KYCRSP_MARITAL_STATUS: 'Married',
    KYCRSP_TITLE: 'Mr.',
    KYCRSP_MOTHER_NAME: 'SHAKUNTALA KUNDER',
    KYCRSP_SPOUSE_NAME: 'SWATI KUNDER',
    KYCRSP_CKYCNO: '12345678901234'
  }
  try {
    const { tptCustomerService } = getService()
    response = await tptCustomerService.create(data, { query }) // eslint-disable-line
  } catch (err) {
    throw new Error('smartCovrApi:insuranceService:tptCustomerPost', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  // Form is returned, this form is then displayed on UI. Once this form is rendered action is called
  // and user is redirected to the url written in action
  return `<form id="login-form-id" action="http://localhost:3032/tptloginredirect" method="post">
  <input type="hidden" name="enquiryId" value="${enquiryId}">
  <input type="hidden" name="distributorId" value="${distributorId}">
  <input type="hidden" name="insuranceType" value="${insuranceType}">
  <input type="hidden" name="customerNumber" value="${randomNumber}">
  <input type="hidden" name="client" value="online">
  </form>`
}
const getDocumentUrlForUser = (_payload) => {
  return 'https://tataaia.com/pdf/protection-solutions/sampoorna_raksha.pdf'
}

const verifyPanDetails = async (panData) => {
  const { firstName, middleName, lastName, panNo, distributorId } = panData
  try {
    const { verifyService } = getService()
    return await verifyService.create({
      firstName,
      middleName,
      lastName,
      panNo
    }, {
      query: {
        distributorId,
        verifyType: 'pan'
      }
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const sendApplicationOtp = async (data) => {
  const { distributorId, applicationFormData } = data
  try {
    const { verifyService } = getService()
    return await verifyService.create({
      applicationFormData
    }, {
      query: {
        distributorId,
        verifyType: 'otp'
      }
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}
const verifyApplicationOtp = async (data) => {
  const { otp, otpKey, otpGenDate, otpSentDate, distributorId, applicationFormData } = data
  try {
    const { verifyService } = getService()
    return await verifyService.update(null, {
      otp,
      otpKey,
      otpGenDate,
      otpSentDate,
      applicationFormData
    }, {
      query: {
        distributorId,
        verifyType: 'otp'
      }
    })
  } catch (err) {
    console.log(err)
    throw err
  }
}

const sendDropOffNotification = ({ dropOffType, applicationFormData, distributorId, enquiryId, configure }) => {
  try {
    const data = {
      applicationFormData,
      enquiryId
    }
    const { verifyServiceUrl } = configure
    const buildUrl = `${verifyServiceUrl}${(process.env.NODE_ENV === 'development') ? '?' : '&'}distributorId=${distributorId}&dropOffType=${dropOffType}&verifyType=dropoff&id=lol`
    // const browserSupportsKeepalive = 'keepalive' in new window.Request('')
    //   if (browser && browser.name === 'Chrome' && parseInt(browser.version.charAt(0)) < 8) {
    //     // condition for chrome versions < 80
    //     // Send a Synchronous AJAX !!!
    //     requestWrapper.makeFetchRequest({ method: 'POST', url: buildUrl, data })
    //   } else if ('fetch' in window && browserSupportsKeepalive) {
    //     requestWrapper.makeFetchRequest({ method: 'POST', url: buildUrl, data })
    //   } else {
    //   // Send a Synchronous AJAX !!!
    // requestWrapper.makeFetchRequest({ method: 'POST', url: buildUrl, data })
    // }
    requestWrapper.makeFetchRequest({ method: 'POST', url: buildUrl, data })
  } catch (err) {
    console.log('Dropoff Failed')
    console.log(err)
  }
}

const fetchPincodeData = async ({ pincode, insurerId, insuranceType }) => {
  let response
  try {
    const { metadataService } = getService(insuranceType)
    response = await metadataService.get('pincode', {
      query: {
        pincode,
        insurerId,
        insuranceType
      }
    })
  } catch (err) {
    throw new ServerApiError.GetPincodeDataError('smartCovrApi:commonService:fetchPincodeData', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

/**
 *
 * @param {object} { pincode, insuranceType }
 * @return {object} response
 */
const fetchCityAndState = async ({ pincode, insuranceType }) => {
  let response
  try {
    const { metadataService } = getService()
    response = await metadataService.get('pincode', {
      query: {
        pincode,
        insuranceType
      }
    })
  } catch (err) {
    throw new ServerApiError.GetPincodeDataError('smartCovrApi:commonService:fetchCityAndState', {
      message: err.message,
      code: err.code,
      stack: err.stack
    })
  }
  return response
}

const getAllApplications = async ({ insuranceType, distributorId, iamId, skip, limit }) => {
  let response
  try {
    const { applicationService } = getService(insuranceType)
    response = await applicationService.get('all', {
      query: { insuranceType, distributorId, iamId, skip, limit }
    })
  } catch (err) {
    // change this
    console.log('err: ', err)
  }
  return response
}

// Not getting used anymore
const getApplicationData = async ({ insuranceType, distributorId, applicationNumber }) => {
  let response
  try {
    // Need to use the real API
    const { applicationService } = getService(insuranceType)
    response = await applicationService.get(applicationNumber, {
      query: { insuranceType, distributorId }
    })
  } catch (err) {
    // change this
    console.log('err: ', err)
  }
  return response
}

/**
 *
 * @param {object} { insuranceType, distributorId, applicationNumber, getStatus }
 * @return {object} response
 */
const getApplicationStatus = async ({ insuranceType, distributorId, applicationNumber, getStatus }) => {
  let response
  try {
    const { applicationService } = getService(insuranceType)
    response = await applicationService.get(applicationNumber, {
      query: { insuranceType, distributorId, getStatus }
    })
  } catch (err) {
    console.log('err: ', err)
  }
  return response
}

// Not implemented
const getAllUpcomingPremiums = async () => {
  let response
  try {
    // Need to use the real API
    // response = await policyService.get('upcomingPremiums', {
    //   query: { insuranceType, distributorId, iamId }
    // })
    // response = mockData.upcomingPremiums
  } catch (err) {
    // change this
    console.log('err: ', err)
  }
  return response
}

// Not implemented
const getAllPaidPremiums = async () => {
  let response
  try {
    // Need to use the real API
    // response = await policyService.get('paidPremiums', {
    //   query: { insuranceType, distributorId, iamId }
    // })
    // response = mockData.paidPremiums
  } catch (err) {
    // change this
    console.log('err: ', err)
  }
  return response
}

export {
  loadEnquiry,
  updateEnquiryToDb,
  uploadFile,
  sendMail,
  getFileFromFileStore,
  getProductsForInstitution,
  tptCustomerPost,
  getCustomer,
  getDocumentUrlForUser,
  authenticateUser,
  getDistributorConfigByType,
  logoutUser,
  verifyPanDetails,
  verifyApplicationOtp,
  sendApplicationOtp,
  sendDropOffNotification,
  fetchPincodeData,
  fetchCityAndState,
  getAllApplications,
  getApplicationData,
  getApplicationStatus,
  getAllUpcomingPremiums,
  getAllPaidPremiums,
  logoutKeycloakUser,
  makeTptTokenRequest,
  makeTptLoginRedirectRequest,
  makeTptLoginCustomerRedirectRequest
}
