import { Component } from 'react'
import { Modal, Icon, List, Button } from 'semantic-ui-react'
import { Header, Subtitle1, Subtitle2 } from 'components/Header'
const style = {
  position: {
    width: '30%',
    minWidth: 300
  },
  headerColor: { color: '#9e9c9c' },
  modalContent: { textAlign: 'center' },
  innerDiv: { margin: 24 },
  headererror: { color: '#fe1300' },
  errorDiv: { textAlign: 'center' }
}

const headerDone = (heading, subheading) => (
  <Subtitle1 color='grey'>
    <Icon circular name='ok' size='large' color='green' />
    <Header.Content>
      {heading}
      <Header.Subheader>
        {subheading}
      </Header.Subheader>
    </Header.Content>
  </Subtitle1>
)
const headerSpinner = (heading, subheading) => (
  <Subtitle1 color='grey'>
    <Icon loading name='repeat' size='big' color='green' />
    <Header.Content style={style.headerColor}>
      {heading}
      <Header.Subheader>
        {subheading}
      </Header.Subheader>
    </Header.Content>
  </Subtitle1>
)
// const headerGrey = (heading, subheading) => <Subtitle1 color='grey' >
//   <Icon size='large' />
//   <Header.Content style={style.headerColor}>
//     {heading}
//     <Header.Subheader>
//       {subheading}
//     </Header.Subheader>
//   </Header.Content>
// </Subtitle1>
const headerError = (generateSiS) => (
  <div style={style.errorDiv}>
    <Subtitle2 color='grey' size='medium'>
      <Header.Content style={style.headererror}>
        SIS Document Generation Failed
      </Header.Content>
    </Subtitle2>
    <Button primary onClick={generateSiS}>Try Again </Button>
  </div>
)

class OnSubmitModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sidLoader: props.open,
      emailLoader: false,
      emailData: null,
      delay: false
    }
    this.delay = this.delay.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open === false && nextProps.open !== this.props.open) {
      this.setState({ sidLoader: false, emailLoader: true, delay: true }, this.delay(1000))
    }
  }

  delay (time) {
    setTimeout(() => {
      this.sendMail()
    }, time)
  }

  // FIXME: Email Body Hardcoded
  sendMail (_fileName, _formData) {
    this.setState({
      emailLoader: false,
      emailData: {},
      delay: false
    })
  }

  render () {
    const { sidLoader, emailLoader, delay } = this.state
    const { fileError } = this.props
    let open = false
    if (fileError) {
      open = true
    } else {
      open = sidLoader || emailLoader || delay
    }
    return (
      <Modal style={style.position} open={open}>
        <Modal.Content style={style.modalContent}>
          <div style={style.innerDiv}>
            {(!fileError)
              ? (
                <List>
                  <List.Item>
                    {sidLoader && headerSpinner('Preparing SID')} {!sidLoader && headerDone('SID Prepared')}
                  </List.Item>
                  {/* <List.Item>
                  {!emailLoader && sidLoader && headerGrey('Sending Email')} {emailLoader && !sidLoader && headerSpinner('Sending Email') } {!sidLoader && !emailLoader && headerDone('Email Sent')}
                </List.Item> */}
                </List>
                )
              : headerError(this.props.generateSiS)}
          </div>
        </Modal.Content>
      </Modal>
    )
  }
}
export default OnSubmitModal
