import React from 'react'

const errorInitialState = {
  error: undefined,
  hasError: false
}
const ErrorContext = React.createContext()

export {
  ErrorContext,
  errorInitialState
}
