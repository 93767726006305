
import NumberFormat from 'react-number-format'
import { Form, Input } from 'semantic-ui-react'
import { H6 } from 'components/Header'
import ValidationErrorPopUp from 'components/ValidationErrorPopUp'
import { isNotEmpty } from 'utils'

const PincodeField = (props) => {
  const { pincode, handleChange, id, getErrorMessageByKey, device, hdfcLandingPage } = props
  const errorMessage = getErrorMessageByKey('pincode')
  const error = isNotEmpty(errorMessage)
  if (hdfcLandingPage) {
    return (
      <div id='pincode' className={`form-group col-lg-3 col-md-6 ${error && 'has-error'}`}>
        {error && (
          <div className='help-block with-errors'>
            <ul className='list-unstyled'>
              <li>{errorMessage}</li>
            </ul>
          </div>
        )}
        <input
          onChange={(event) => {
            if (event.target.value.length <= 6) {
              handleChange({ pincode: event.target.value }, 'pincode')
            }
          }} value={pincode ?? ''} name='pincode' className='form-control' id='pincode' placeholder='Pincode' type='number' maxLength='6' pattern='[0-9]{6}' autoComplete='off' required='required' data-error='Please enter your pincode'
        />
      </div>
    )
  }
  return (
    <H6 className='pincode'>
      <Form.Field id={id}>
        <NumberFormat
          autoComplete='off'
          className={`${error ? 'error centered' : 'centered'}`}
          error={error}
          id='pincode'
          placeholder='123 456'
          format='### ###'
          mask={' '}
          value={pincode}
          customInput={Input}
          onValueChange={({ value }) => handleChange({ pincode: value }, value.length)}
        />
      </Form.Field>
      {error && (device !== 'pincode') && <ValidationErrorPopUp errorMessage={errorMessage} />}
    </H6>

  )
}
export default PincodeField
