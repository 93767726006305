
import FormInput from 'components/FormInput'

const InputWidget = (props) => {
  const {
    id,
    className,
    value,
    readonly,
    disabled,
    autofocus,
    onBlur,
    onFocus,
    options,
    placeholder,
    ...inputProps
  } = props
  const schema = props.schema || {}
  inputProps.type = options.inputType || inputProps.type || 'text'
  // Remove labels
  return (
    <FormInput
      maxLength={schema.maxLength}
      id={(!id) ? 'inputWidget' : id}
      className={className}
      placeholder={placeholder}
      {...inputProps}
      value={value ?? ''}
      disabled={disabled}
      readOnly={readonly}
      focus={autofocus}
      onChange={(_e, { value }) => props.onChange(value)}
      onBlur={onBlur && ((event) => onBlur(id, event.target.value))}
      onFocus={onFocus && ((event) => onFocus(id, event.target.value))}
      options={options}
    />
  )
}
export default InputWidget
