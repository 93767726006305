import { schema } from 'normalizr'
/*
{
  entities: {
    categories: {
      [categorykey]: {
        ...categoryDetails,
        subCategories: [subCategoryKeys]
      }
    },
    subCategories: {
      [subCategoryKey]: {
        ...subCategoryValues
      }
    }
  },
  result: [categoryKeys]

}
*/
const subCategory = new schema.Entity('subCategories', {}, { idAttribute: 'key' })

const category = new schema.Entity('categories', {
  subCategories: [subCategory]
}, { idAttribute: 'key' })
const listingSchema = new schema.Array(category)

export {
  listingSchema
}
