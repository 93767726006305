import selectPlugin from '@rematch/select'
import createLoadingPlugin from '@rematch/loading'
import updatedPlugin from '@rematch/updated'
import createRematchPersist, { getPersistor } from '@rematch/persist'
import immerPlugin from '@rematch/immer'
import { init } from '@rematch/core'
import { errorMiddleware } from '../middleware'
import { isNotDefined } from 'utils'
import { setAutoFreeze } from 'immer'
import { PersistGate } from 'redux-persist/integration/react'
import storage from 'redux-persist/lib/storage'
// import common models
import commonModels from '../models/index'
import insurance from '../models/insurance'
import mf from '../models/mf'
const appEnv = process.env.REACT_APP_BUILD_TYPE
const env = process.env.NODE_ENV
const envMode = env === 'development' ? env : appEnv

setAutoFreeze(false)

const updated = updatedPlugin()
const immer = immerPlugin()
const persistPlugin = createRematchPersist({
  key: `smartcovr-${envMode}`,
  storage,
  whitelist: ['configure'],
  version: 1
})
const loading = createLoadingPlugin()
let store
/**
   * returns store object
   *
   * @returns object
   */
const getStore = () => store
/**
   * This function creates storeOptions using all the models, plugins, immer, redux and middlewares.
   * Then this storeOption is passed to init function
   *
   * @param {*} storeTypes
   * @param {*} initialState
   * @returns store object
   */
function createStore (storeTypes, initialState) {
  if (isNotDefined(store)) {
    const storeOptions = {
      models: {
        ...commonModels
      },
      plugins: [
        selectPlugin(),
        loading,
        updated,
        persistPlugin,
        immer
      ],
      redux: {
        initialState,
        devtoolOptions: {
          name: `Smartcovr ${envMode}`
        },
        middlewares: [errorMiddleware]
      }
    }
    storeTypes.forEach(storeType => {
      if (storeType === 'insurance') {
        Object.assign(storeOptions.models, insurance)
      }
      if (storeType === 'mf') {
        Object.assign(storeOptions.models, mf)
      }
    })

    store = init({
      models: storeOptions.models,
      plugins: storeOptions.plugins,
      redux: storeOptions.redux
    })
  }
  return store
}
export {
  createStore,
  getStore,
  PersistGate,
  getPersistor
}
