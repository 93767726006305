import { Header } from 'components/Header'

const Subtitle1 = ({ children, ...restProps }) => {
  return (
    <Header size='huge' {...restProps}>
      {children}
    </Header>
  )
}

export default Subtitle1
