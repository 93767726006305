import { PureComponent } from 'react'
import { Body2 } from 'components/Header'
import Feature from './Feature'

class FeatureMobileReview extends PureComponent { // make stateless
  render () {
    const { product, contentType, view } = this.props
    const { features, productOptionId } = product

    const freeAddons = features.filter(feature => feature.pricing === 'free')
      .map((feature, index) => {
        return (
          <div key={index} className='listItemsMobile'>
            <Feature
              contentType={contentType}
              feature={feature}
              key={`${index}_feature`}
              view={view}
              productOptionId={productOptionId}
            />
          </div>
        )
      })
    return (
      <>
        <Body2 className='tabDesc'>
          Following addons are included in the plan with no additional cost
        </Body2>
        {freeAddons}
      </>
    )
  }
}
export default FeatureMobileReview
